import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TenderService } from '../../../../services/tender.service';
import { interval, Subject } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import {
  Entry,
  ITenderFreight,
  ITenderFreightParam,
  ITenderLocation,
  ITenderLocationRate,
  ITenderLocationRateGroup,
  ITenderDto
} from '../../../../services/models/pricing/tender.model';
import { ApiService } from '../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { faArrowRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { StorageService } from '../../../../services/storage.service';
import { PricingService } from '../../../../services/pricing.service';
import {
  ISelectRateGroup,
  ISelectRateGroupParam,
  RateTypes
} from '../../../../services/models/pricing/rates.model';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step-rate-export',
  templateUrl: './step-rate-export.component.html',
  styleUrls: ['./step-rate-export.component.scss']
})
export class StepRateExportComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faEdit;
  faArrowRight = faArrowRight;
  faInfo = faCircleQuestion;

  // Component Variables
  rateTypes = RateTypes;
  processEntry: Entry = null;
  checkerStarted: boolean = false;
  locationsLoading: boolean = false;
  freightLoading: boolean = false;
  // AIR Freight
  airPaginationData: IPaginationData<ITenderFreight>;
  airPage: IPage;
  // FCL Freight
  fclPaginationData: IPaginationData<ITenderFreight>;
  fclPage: IPage;
  // LCL Freight
  lclPaginationData: IPaginationData<ITenderFreight>;
  lclPage: IPage;
  // ROAD Freight
  roadPaginationData: IPaginationData<ITenderFreight>;
  roadPage: IPage;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  private timerUnsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;
  environment = environment;

  constructor(public tenderService: TenderService,
              public pricingService: PricingService,
              private api: ApiService,
              private storage: StorageService,
              private changeDetector: ChangeDetectorRef,
              private alertService: AlertService) { }

  ngOnInit() {
    this.loading = true;
    this.initVariables();
    let tender = this.tenderService.TenderView;
    if (tender) {
      this.loading = false;
      if (!tender.QuoteAcceptedDate || !tender.IsProcessed) {
        this.startTenderChecker();
      } else {
        this.loadCompletedTenderView(tender.Id)
      }
    } else {
      tender = this.tenderService.GetTenderFromStorage();
      if (tender && tender.Id) {
        this.loadCompletedTenderView(tender.Id);
      }
      this.loading = false;
    }

    interval(30000).pipe(takeUntil(this.timerUnsubscribe)).subscribe(() => {
      this.changeDetector.detectChanges();
    });

    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initVariables() {
    // Init pagination data
    const defaultPagination = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    };

    const defaultPage = {
      pageNumber: 1,
      pageSize: 15,
      batched: false
    };

    // AIR
    this.airPaginationData = {...defaultPagination};
    this.airPage = {...defaultPage};
    // FCL
    this.fclPaginationData = {...defaultPagination};
    this.fclPage = {...defaultPage};
    // LCL
    this.lclPaginationData = {...defaultPagination};
    this.lclPage = {...defaultPage};
    // Road
    this.roadPaginationData = {...defaultPagination};
    this.roadPage = {...defaultPage};
  }

  startTenderChecker() {
    this.processEntry = this.storage.getSessionItem('tender-process-start');

    if (this.processEntry && this.checkerStarted === false) {
      this.checkerStarted = true;
      // Check every 30 seconds if the tender has been completed, this is handy if the process tender has timed out.
      // The stored procedure SP_Tender_Process will continue to run, this checker will pick up when it is done and show the results.
      // This is to avoid hanging screen on a timeout.
      interval(30000).pipe(takeUntil(this.timerUnsubscribe)).subscribe(() => {
        const t = this.tenderService.TenderView;

        this.api.get('Tender/IsProcessed/' + t.Id).pipe(
          takeUntil(this.unsubscribe),
        ).subscribe({
          next: (data: boolean) => {
            if (data == true) {
              this.loadCompletedTenderView(t.Id);
            }
          },
          error: () => {
            this.alertService.error('An Error Occurred: CheckTender');
          }
        });
      });
    }
  }

  loadCompletedTenderView(tenderID: number) {
    if (this.loading === false) {
      // Set variable to show the final result is being loaded from the API
      // Only run if it is false, to avoid running twice as it is a heavy query
      this.loading = true;
      // Load tender view with final rate data
      this.api.get('Tender/FullTender/' + tenderID).pipe(
        takeUntil(this.unsubscribe)).subscribe({
        next: (data: ITenderDto) => {
          this.tenderService.TenderView = Object.assign({}, data);
          // Load services separately
          this.loadTenderServices();
          if (this.tenderService.TenderView.IsProcessed) {
            // Load first freight rate pages
            this.loadTenderFreights();
            this.loadLocationRateGroups();
            this.endTimer();
          }
          this.tenderService.StoreTender();
        },
        error: (error: Error) => {
          this.tenderService.LoadingTenderResults = false;
          this.storage.removeSessionItem('tender-process-start');
        }
      });
    }
  }

  loadTenderServices() {
    this.tenderService.LoadingTenderResults = true;

    this.api.get('TenderService/List/' + this.tenderService.TenderView.Id)
      .pipe(takeUntil(this.unsubscribe)
      ).subscribe({
      next: (data) => {
        if (data) {
          this.tenderService.TenderView.TenderServices = Object.assign([], data);
        }
        this.tenderService.LoadingTenderResults = false;
      },
      error: () => {
        this.tenderService.LoadingTenderResults = false;
      }
    });
  }

  endTimer() {
    // Remove timer start time from session storage
    this.storage.removeSessionItem('tender-process-start');
    this.processEntry = null;
    // End timer and interval checker
    this.timerUnsubscribe.next(null);
    this.timerUnsubscribe.complete();
  }

  canShowModality(modalityTypeID: number) {
    if (this.tenderService.TenderView) {
      const tm = this.tenderService.TenderView.TenderModalities;
      if (tm && tm.length > 0) {
        const hasModalityType = tm.filter((x) => x.ModalityTypeId === modalityTypeID);
        return hasModalityType.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //////////////////////////
  //// Location methods ////
  //////////////////////////
  loadLocationRateGroups() {
    this.locationsLoading = true;

    this.api.get(`TenderLocationRateGroup/List/${this.tenderService.TenderView.Id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ITenderLocationRateGroup[]) => {
        if (data) {
          this.tenderService.TenderView.TenderLocations.forEach(tl => {
            tl.TenderLocationRateGroups = data.filter(x => x.TenderLocationId == tl.Id);
          });
        }
        this.locationsLoading = false;
      },
      error: () => {
        this.alertService.warn('Unable to load location rate group data.');
        this.locationsLoading = false;
      }
    });
  }

  getOriginLocations(): ITenderLocation[] {
    if (this.tenderService.TenderView && this.tenderService.TenderView.TenderLocations) {
      return this.tenderService.TenderView.TenderLocations.filter((x) => x.IsOrigin === true).sort((a,b) => a.PartnerLocationName < b.PartnerLocationName ? -1 : 1);
    } else {
      return [];
    }
  }

  getDestinationLocations(): ITenderLocation[] {
    if (this.tenderService.TenderView && this.tenderService.TenderView.TenderLocations) {
      return this.tenderService.TenderView.TenderLocations.filter((x) => x.IsDestination === true).sort((a,b) => a.PartnerLocationName < b.PartnerLocationName ? -1 : 1);
    } else {
      return [];
    }
  }

  getLocationRateGroup(tenderLocation: ITenderLocation, modalityTypeID: number, IsDestination: boolean): ITenderLocationRateGroup {
    if (tenderLocation && tenderLocation.TenderLocationRateGroups) {
      const locationRateGroup = tenderLocation.TenderLocationRateGroups.find((x) =>
              x.ModalityTypeId === modalityTypeID &&
              x.IsDestination === IsDestination);

      if (locationRateGroup) {
        return locationRateGroup;
      }
    }

    return {} as ITenderLocationRateGroup;
  }

  // RateGroup methods //
  getRateGroupOptions(locationRateGroup: ITenderLocationRateGroup, location: ITenderLocation, portID: number, rateTypeID: number) {
    locationRateGroup.IsLoadingOptions = true;

    const param = {
      LocationID: location.CityId,
      LocationTypeID: 5, // City
      PortID: portID,
      RateTypeID: rateTypeID,
      TenderID: this.tenderService.TenderView.Id,
      RedSkyAgentID: null,
      Date: new Date(),
      Radius_1: 50, // Default
      Radius_2: 250, // Default
      MaximumDaysInvalid: 30, // Default
      MaximumRateGroup: 10 // Default
    } as ISelectRateGroupParam;

    this.api.post('Tender/LocationRateGroupOptions', param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data && data.length) {
          locationRateGroup.RateGroupOptions = Object.assign([] as ISelectRateGroup[], data);
        }
        locationRateGroup.IsLoadingOptions = false;
      },
      error: () => {
        locationRateGroup.IsLoadingOptions = false;
        return [];
      }
    });
  }

  updateTenderLocationRateGroupRates(locationRateGroup: ITenderLocationRateGroup) {
    this.loading = true;

    this.api.post(`TenderLocationRateGroup/UpdateRateGroup/${locationRateGroup.Id}/${locationRateGroup.RateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ITenderLocationRate[]) => {
        if (data) {
          locationRateGroup.TenderLocationRates = Object.assign([] as ITenderLocationRate[], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.warn('An error occurred while attempting to update the rates for the selected Provider.');
        this.loading = false;
      }
    });
  }

  /////////////////////////
  //// Freight methods ////
  /////////////////////////
  loadTenderFreights() {
    this.loading = true;

    this.tenderService.GetTenderFreights(this.tenderService.TenderView.Id).subscribe({
      next: (data) => {
        if (data) {
          this.tenderService.TenderView.TenderFreights = Object.assign([], data);
          this.loadAllFreightPages();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  canShowFreightModality(modalityTypeID: number) {
    if (this.tenderService.TenderView) {
      const tenderFreights = this.tenderService.TenderView.TenderFreights;
      if (tenderFreights && tenderFreights.length > 0) {
        const hasTenderFreightForModality = tenderFreights.filter((x) => x.ModalityTypeId === modalityTypeID);
        return hasTenderFreightForModality.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loadAllFreightPages() {
    // AIR
    if (this.canShowFreightModality(1)) {
      this.loadFreightRateGroups(1, 1, 15);
    }
    // FCL
    if (this.canShowFreightModality(2)) {
      this.loadFreightRateGroups(2, 1, 15);
    }
    // LCL
    if (this.canShowFreightModality(3)) {
      this.loadFreightRateGroups(3, 1, 15);
    }
    // Road
    if (this.canShowFreightModality(4)) {
      this.loadFreightRateGroups(4, 1, 15);
    }
  }

  checkFreight(modalityTypeID: number) {
    switch (modalityTypeID) {
      case 1:
        if (this.airPaginationData.DataSet.length <= 0) {
          this.loadFreightRateGroups(1, 1, 15);
        }
        break;
      case 2:
        if (this.fclPaginationData.DataSet.length <= 0) {
          this.loadFreightRateGroups(2, 1, 15);
        }
        break;
      case 3:
        if (this.lclPaginationData.DataSet.length <= 0) {
          this.loadFreightRateGroups(3, 1, 15);
        }
        break;
    }
  }

  loadFreightRateGroups(modalityTypeID: number, currentPage: number, rowCount: number) {
    this.freightLoading = true;

    const param = {
      Active: true,
      TenderId: this.tenderService.TenderView.Id,
      ModalityTypeId: modalityTypeID,
      CurrentPage: currentPage,
      RowCount: rowCount
    } as ITenderFreightParam;

    this.api.post('TenderFreight/ListPage', param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<ITenderFreight>) => {
        if (data) {
          switch (modalityTypeID) {
            case 1:
              this.airPaginationData = Object.assign([], data);
              break;
            case 2:
              this.fclPaginationData = Object.assign([], data);
              break;
            case 3:
              this.lclPaginationData = Object.assign([], data);
              break;
            case 4:
              this.roadPaginationData = Object.assign([], data);
              break;
          }
        }
        this.freightLoading = false;
      },
      error: () => {
        this.alertService.warn('Unable to load freight rate data.');
        this.freightLoading = false;
      }
    });
  }

  onPage(modalityTypeId: number, page: IPage, paginationData: IPaginationData<ITenderFreight>) {
    paginationData.CurrentPage = page.pageNumber;

    this.loadFreightRateGroups(modalityTypeId, page.pageNumber, page.pageSize);
  }
}




