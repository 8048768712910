import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  ITenderSingleLaneOverview,
  ITenderSingleLaneSearchParam
} from '../../../../services/models/pricing/tender.model';
import { Subject } from 'rxjs';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { TenderService } from '../../../../services/tender.service';
import { StorageService } from '../../../../services/storage.service';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { IAuthUser } from '../../../../services/models/auth.model';
import { PricingService } from '../../../../services/pricing.service';
import { environment } from 'src/environments/environment';
import { isDayGreaterThan, isDaySmallerThan, getDayDifference} from '../../../../shared/functions/datetime-functions';

@Component({
  selector: 'app-single-rate-overview',
  templateUrl: './single-rate-overview.component.html',
  styleUrls: ['./single-rate-overview.component.scss']
})
export class SingleRateOverviewComponent implements OnInit, OnDestroy {
  @Output() SingleRateTenderSelected: EventEmitter<ITenderSingleLaneOverview> = new EventEmitter();
  @Output() CreateNew: EventEmitter<boolean> = new EventEmitter();

  // Icons
  faView = faEye;

  // Component variables
  paginationData: IPaginationData<ITenderSingleLaneOverview>;
  searchParam: ITenderSingleLaneSearchParam;
  page: IPage;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;
  isDayGreaterThan = isDayGreaterThan;
  isDaySmallerThan = isDaySmallerThan;
  getDayDifference = getDayDifference;

  constructor(private authService: AdAuthService,
              public tenderService: TenderService,
              public pricingService: PricingService,
              public storage: StorageService) { }

  ngOnInit() {
    this.initiatePage();

    if (this.authService.CurrentUser) {
      this.searchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
      this.searchSingleRateTenders();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.searchParam.CompanyId = user.User.CompanyId;
      this.searchSingleRateTenders();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initiatePage() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    };

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };

    this.searchParam = {
      TenderName: null,
      CompanyId: null,
      CurrentPage: 1,
      RowCount: 15
    };
  }

  createNew() {
    this.CreateNew.emit(true);
  }

  searchSingleRateTenders() {
    this.loading = true;

    if (this.searchParam.TenderName && this.searchParam.TenderName.length <= 0) {
      this.searchParam.TenderName = null;
    }

    this.tenderService.SearchSingleLaneTenders(this.searchParam).subscribe({
      next: (data: IPaginationData<ITenderSingleLaneOverview>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  resetSearch() {
    this.searchParam.TenderName = null;
    this.searchParam.CurrentPage = 1;
    this.searchParam.RowCount = 15;
    this.resetPaginationData();
    this.searchSingleRateTenders();
  }

  resetPaginationData() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;
    this.paginationData.PageSize = this.searchParam.RowCount;
    this.setPage();
  }

  setPage() {
    this.page.pageNumber = this.paginationData.CurrentPage;
    this.page.pageSize = this.paginationData.PageSize;
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.searchParam.CurrentPage = page.pageNumber;
    this.searchParam.RowCount = page.pageSize;

    this.searchSingleRateTenders();
  }

  editTender(tender: ITenderSingleLaneOverview) {
    this.SingleRateTenderSelected.emit(tender);
  }
}
