<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Default Values
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-purple-outline-button"
            [disabled]="loading" (click)="newDefaultValue()">
      New Default Value +
    </button>
  </div>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mb-2">
      <div class="col-2 align-self-center">
        <b>Client direction</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(userClients$ | async) || (userClients$ | async).length <= 0"
                [(ngModel)]="selectedClient"
                (change)="setClientData()">
          <option [ngValue]="null" [disabled]="true" *ngIf="canViewAll">Select client direction...</option>
          <option *ngFor="let client of (userClients$ | async)"
                  [ngValue]="client">
            {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
          </option>
        </select>
      </div>
      <div class="col-2 offset-5 text-end">
        <button type="button"
                class="btn btn-sm btn-primary harmony-purple-button w-100"
                (click)="searchDefaultValues()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'HubMissingValues'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!------------------->
    <!----- Results ----->
    <!------------------->
    <div class="row mt-2 header-row">
      <div class="col-2 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="SourceClientName"
           data-order="desc">
        Source Client
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceClientName'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="TargetClientName"
           data-order="desc">
        Target Client
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SystemName"
           data-order="desc">
        System
        <fa-icon [hidden]="orderParam.OrderBy != 'SystemName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-3 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="ElementPath"
           data-order="asc">
        Path
        <fa-icon [hidden]="orderParam.OrderBy != 'ElementPath'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-3 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="Value"
           data-order="desc">
        Value
        <fa-icon [hidden]="orderParam.OrderBy != 'Value'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title text-end border-split-right">

      </div>
    </div>
    <ng-container *ngIf="!loading && paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let value of paginationData.DataSet">
        <div class="col-2 col-compress overflow-hidden">
          <b>{{ value.SourceClientName }}</b>
        </div>
        <div class="col-2 col-compress overflow-hidden">
          <b>{{ value.TargetClientName }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ value.SystemName }}
        </div>
        <div class="col-3 col-compress overflow-hidden" [title]="value.ElementPath ?? '-'">
          <small>{{ value.ElementPath ?? '-' }}</small>
        </div>
        <div class="col-3 col-compress overflow-hidden" [title]="value.Value ?? '-'">
          <small>{{ value.Value }}</small>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-outline-warning harmony-teal-outline-button me-1"
                  [title]="'Edit value'"
                  (click)="editValue(value)">
            <fa-icon [icon]="faEdit" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-danger"
                  [title]="'Ignore value mapping'"
                  (click)="deleteDefaultValue(value)">
            <fa-icon [icon]="faDelete" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!----- Loader ------>
    <!------------------->
    <ng-container *ngIf="loading">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!--- No Results ---->
    <!------------------->
    <ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
      <div class="row mt-4" *ngIf="!loading && (paginationData.DataSet && paginationData.DataSet.length === 0)">
        <div class="col-12 text-center harmony-grey-text">
          <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
        </div>
        <div class="col-12 text-center harmony-grey-text mt-1">
          <h4>No results...</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
