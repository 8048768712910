import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepLocationsAddModalComponent } from './step-locations-add-modal/step-locations-add-modal.component';
import { ApiService } from '../../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { Subject } from 'rxjs';
import { TenderService } from '../../../../services/tender.service';
import { takeUntil } from 'rxjs/operators';
import { ITenderLocation, ITenderPort } from '../../../../services/models/pricing/tender.model';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { environment } from '../../../../../environments/environment';
import { ICityDto, ICityFunctionDto } from '../../../../services/models/pricing/location.model';

@Component({
  selector: 'app-step-locations',
  templateUrl: './step-locations.component.html',
  styleUrls: ['./step-locations.component.scss']
})
export class StepLocationsComponent implements OnInit, OnDestroy {
  // Icons
  faBin = faTrashAlt;
  faInfo = faCircleQuestion;
  faEdit = faPen;

  // Variables
  locationLimit: number;

  // General Variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService,
              public tenderService: TenderService) { }

  ngOnInit() {
    this.locationLimit = environment.Pricing.TenderLocationLimit;
    this.loadLocations();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadLocations() {
    this.loading = true;

    const tenderId = this.tenderService.TenderView.Id;

    this.api.get('TenderLocation/List/' + tenderId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.tenderService.TenderView.TenderLocations = Object.assign([], data);
          this.tenderService.StoreTender();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getLocations(): ITenderLocation[] {
    if (this.tenderService.TenderView.TenderLocations && this.tenderService.TenderView.TenderLocations.length > 0) {
      return this.tenderService.TenderView.TenderLocations
        .sort((a, b) => a.PartnerLocationName > b.PartnerLocationName ? 1 : -1)
        .filter(x => x.Active == true);
    } else {
      return [];
    }
  }

  removeLocation(location: ITenderLocation) {
    // Find index of location object within array, and remove the item at that index using splice
    location.Active = false;
  }

  disableAddLocation() {
    return (this.tenderService.IsQuoteAccepted() ||
      this.tenderService.loading ||
      this.tenderService.TenderView.TenderLocations.length >= this.locationLimit);
  }

  addLocation() {
    // Open modal to add locations
    const modalRef = this.modalService.open(StepLocationsAddModalComponent);
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string[]) => {
      if (result) {
        // Check if TenderLocations variable has been initialised, otherwise init the variable
        if (!this.tenderService.TenderView.TenderLocations) {
          this.tenderService.TenderView.TenderLocations = [];
        }
        const limitReachedList = [];
        // For each line inserted into the textarea, add it to the list to be mapped
        result.forEach((location) => {
          if (this.tenderService.TenderView.TenderLocations.length >= environment.Pricing.TenderLocationLimit) {
            limitReachedList.push(location);
          } else {
            this.tenderService.loading = true;
            // Create new TenderLocation object and mark for saving
            const newLocation = this.addNewLocation(location);
            // Trigger Port and City auto searches for location
            this.findCity(newLocation, location);
          }
        });

        // Check if Locations have reached the limit and notify the user
        if (limitReachedList.length > 0) {
          alert(`You have reached the limit for locations, which is set at ${environment.Pricing.TenderLocationLimit}. A total of ${limitReachedList.length} locations have not been added to this tender.`);
        }

        // Check if Locations have reach Freight capacity and notify the user
        if (result.length > environment.Pricing.TenderLocationFreightCapacity) {
          alert(`Please note that Freight rates can only be provided through the online tool when working with ${environment.Pricing.TenderLocationFreightCapacity} or less locations. On the Freight step there will be an option to contact Harmony if you require Freight rates to be included in the tender.`);
        }
      }
    }, () => { });
  }

  addNewLocation(locationName: string) {
    if (locationName && locationName.length > 0) {
      // Create new TenderLocations object
      const newLocation: ITenderLocation = {
        Id: null,
        DateCreated: new Date(),
        DateModified: null,
        Active: true,
        TenderId: this.tenderService.TenderView.Id,
        PartnerLocationName: locationName,
        CityId: null,
        SeaPortCityFunctionId: null,
        AirportCityFunctionId: null,
        IsOrigin: true,
        IsDestination: true,

        City: null,
        SeaPort: null,
        AirPort: null,
        TenderLocationRateGroups: [],


        IsEdit: true,
        IsLoading: false,
      } as ITenderLocation;

      // Add to TenderLocations list
      this.tenderService.TenderView.TenderLocations.push(newLocation);

      return newLocation;
    } else {
      return null;
    }
  }

  findCity(location: ITenderLocation, locationName: string) {
    const cleanName = locationName
      .replaceAll('/', '')
      .replaceAll('&', '');

    this.api.get('TenderLocation/FindCity/' + cleanName).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (cityId: number) => {
        if (cityId && cityId > 0) {
          // Add to TenderLocations list
          location.CityId = cityId;
          this.setCity(location, cityId);
        } else {
          this.alertService.info('Unable to find exact city match for: ' + location.PartnerLocationName.toUpperCase() + ', please search manually.');
        }
        this.tenderService.loading = false;
      },
      error: () => {
        this.tenderService.loading = false;
        this.alertService.info('Unable to find exact city match for: ' + location.PartnerLocationName.toUpperCase() + ', please search manually.');
        this.tenderService.SaveTender();
      }
    });
  }

  setCity(location: ITenderLocation, cityId: number) {
    if (cityId && cityId > 0) {
      location.IsEdit = true;
      location.CityId = cityId;
      this.getPortByCityID(location, 4); // Set Airport
      this.getPortByCityID(location, 1); // Set Seaport
    } else {
      location.CityId = null;
      location.AirportCityFunctionId = null;
      location.AirPort = null;
      location.SeaPortCityFunctionId = null;
      location.SeaPort = null;
    }
  }

  selectAirPort(location: ITenderLocation, cityFunction: ICityFunctionDto) {
    location.AirportCityFunctionId = cityFunction?.Id;
    if (cityFunction != null) {
      location.AirPort = {
        Id: cityFunction.Id,
        DateCreated: cityFunction.DateCreated,
        DateModified: cityFunction.DateModified,
        Active: cityFunction.Active,
        CityId: cityFunction.CityId,
        FunctionTypeId: cityFunction.FunctionTypeId,
        Description: cityFunction.Description,
        Code: cityFunction.Code,
        City: {
          Id: cityFunction.CityId,
          Active: true,
          Name: cityFunction.CityName
        } as ICityDto
      } as ITenderPort;
    } else {
      location.AirPort = null;
    }
  }

  selectSeaPort(location: ITenderLocation, cityFunction: ICityFunctionDto) {
    location.SeaPortCityFunctionId = cityFunction?.Id;
    if (cityFunction != null) {
      location.SeaPort = {
        Id: cityFunction.Id,
        DateCreated: cityFunction.DateCreated,
        DateModified: cityFunction.DateModified,
        Active: cityFunction.Active,
        CityId: cityFunction.CityId,
        FunctionTypeId: cityFunction.FunctionTypeId,
        Description: cityFunction.Description,
        Code: cityFunction.Code,
        City: {
          Id: cityFunction.CityId,
          Active: true,
          Name: cityFunction.CityName
        } as ICityDto
      } as ITenderPort;
    } else {
      location.SeaPort = null;
    }
  }

  getPortByCityID(location: ITenderLocation, TypeID: number) {
    location.IsLoading = true;
    const param = {
      CityID: location.CityId,
      FunctionTypeID: TypeID,
      MaximumPorts: 1
    };

    this.api.post('TenderLocation/GetPortByCity', param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          // Apply port ID
          if (TypeID === 1) {
            location.SeaPortCityFunctionId = data[0].ID;
            location.SeaPort = null;
          } else {
            location.AirportCityFunctionId = data[0].ID;
            location.AirPort = null;
          }
          this.tenderService.StoreTender();
        }
        location.IsLoading = false;
      },
      error: () => {
        location.IsLoading = false;
        this.alertService.warn('Unable to retrieve port for: ' + location.PartnerLocationName.toUpperCase() + ', please search manually for the correct port.');
        this.tenderService.StoreTender();
      }
    });
  }

  isLocationValid(location: ITenderLocation) {
    return !!(location && location.SeaPortCityFunctionId && location.AirportCityFunctionId && location.CityId);
  }
}
