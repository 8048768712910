import { Component, OnInit } from '@angular/core';
import { faAngleRight, faCircleDot, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { IAuthUser } from '../../../services/models/auth.model';

@Component({
  selector: 'app-network-menu',
  templateUrl: './network-menu.component.html',
  styleUrls: ['./network-menu.component.scss']
})
export class NetworkMenuComponent implements OnInit {
  // Icons
  faNetwork = faProjectDiagram;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  memberSearch: boolean = false;
  memberProfile: boolean = false;
  memberUserManagement: boolean = false;
  training: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public auth: AdAuthService) { }

  ngOnInit(): void {
    if (this.auth.CurrentUser) {
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.setPermissions();
    });
  }

  setPermissions() {
    this.memberSearch = this.auth.CheckPermissionByCode(PermissionCodes.Network_MemberSearch);
    this.memberProfile = this.auth.CheckPermissionByCode(PermissionCodes.Network_EditMember);
    this.memberUserManagement = this.auth.CheckPermissionByCode(PermissionCodes.Network_MemberUserManagement);
    this.training = this.auth.CheckPermissionByCode(PermissionCodes.Network_Training);
  }
}
