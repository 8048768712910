import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IHubSourceMessageDebug, IHubSourceMessageOverview } from '../../../../services/models/hub/hub-messages.model';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-view-message-mapping-modal',
  templateUrl: './view-message-mapping-modal.component.html',
  styleUrls: ['./view-message-mapping-modal.component.scss']
})
export class ViewMessageMappingModalComponent {
  // Icons
  protected readonly faEmpty = faBoxOpen;

  // Variables
  public SourceMessage: IHubSourceMessageOverview;
  SourceMessageDebug: IHubSourceMessageDebug[] = [];
  FilteredSourceMessageDebug: IHubSourceMessageDebug[] = [];
  searchText: string;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean = false;
  environment = environment;

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    // Load default search
    this.loadSourceMessageDebugData();
  }

  loadSourceMessageDebugData() {
    this.loading = true;
    this.api.get('Hub/Message/Debug/' + this.SourceMessage.Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IHubSourceMessageDebug[]) => {
        if (data) {
          this.SourceMessageDebug = Object.assign([] as IHubSourceMessageDebug[], data);
          this.FilteredSourceMessageDebug = this.SourceMessageDebug;
        }
        this.loading = false;
      }, error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }


  searchDebug() {
    if (this.searchText && this.searchText.length > 0) {
      this.FilteredSourceMessageDebug = this.SourceMessageDebug.filter((x) =>
        x.srcElementID.toString().includes(this.searchText) ||
        x.trgElementID.toString().includes(this.searchText) ||
        x.srcPath.toLowerCase().includes(this.searchText.toLowerCase()) ||
        x.trgElementName.toLowerCase().includes(this.searchText.toLowerCase()) ||
        x.srcElementValue.toLowerCase().includes(this.searchText.toLowerCase()) ||
        x.trgElementValue.toLowerCase().includes(this.searchText.toLowerCase()) ||
        x.lookupTypeID.toString().toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.FilteredSourceMessageDebug = this.SourceMessageDebug;
    }
  }

  close() {
    this.activeModal.close();
  }
}
