import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IComponent, IModule, IRole } from '../../../services/models/user.model';
import { Subject } from 'rxjs';
import { ModuleModalComponent } from './module-modal/module-modal.component';
import { PermissionModalComponent } from './permission-modal/permission-modal.component';
import { RoleModalComponent } from './role-modal/role-modal.component';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionManagementComponent implements OnInit, OnDestroy {
  // Icons
  faEditIcon = faEdit;
  faDelete = faTrash;
  faRefresh = faSyncAlt;

  // Module variables
  modules$ = this.authService.Modules$.asObservable();
  modules: IModule[] = [];
  filteredModules: IModule[] = [];
  moduleSearchText: string = '';

  // Component variables
  components$ = this.authService.Components$.asObservable();
  components: IComponent[] = [];
  filteredComponents: IComponent[] = [];
  componentSearchParam = {
    searchText: '',
    moduleId: null
  };

  // Role variables
  roles$ = this.authService.Roles$.asObservable();
  roles: IRole[] = [];
  filteredRoles: IRole[] = [];
  roleSearchText: string;

  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(public authService: AdAuthService,
              private modalService: NgbModal,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.modules$.subscribe((data) => {
      this.modules = data;
      this.filterModules();
      this.ref.markForCheck();
    });

    this.components$.subscribe((data) => {
      this.components = data;
      this.filterPermissions();
      this.ref.markForCheck();
    });

    this.roles$.subscribe((data) => {
      this.roles = data;
      this.filterRoles();
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  identify(index, item) {
    return item.Name;
  }

  ////////////////////////
  /// Module Functions ///
  ////////////////////////
  filterModules() {
    if (this.moduleSearchText && this.moduleSearchText.length > 0) {
      this.filteredModules = this.modules.filter((m) => m.Name.toLocaleLowerCase().includes(this.moduleSearchText.toLocaleLowerCase()));
    } else {
      this.filteredModules = this.modules.slice();
    }
  }

  manageModule(moduleId: number = null, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ModuleModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ModuleId = moduleId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IModule) => {
      if (result) {
        this.authService.LoadModules();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  deleteModule(id: number) {
    const message = 'This action will mark a module and all components linked to that module as inactive.\nWould you like to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(answer => {
        if (answer) {
          this.authService.DeleteModule(id);
        }
      });
  }

  /////////////////////////////
  /// Permissions Functions ///
  /////////////////////////////
  filterPermissions() {
    if (this.componentSearchParam) {
      const text = this.componentSearchParam.searchText.toLocaleLowerCase();
      const moduleId = this.componentSearchParam.moduleId;
      this.filteredComponents = this.components.filter((m) =>
        (m.Name.toLocaleLowerCase().includes(text) ||
          m.Code.toLocaleLowerCase().includes(text)) &&
        (moduleId == null || m.ModuleId === moduleId)
      );
    } else {
      this.filteredComponents = this.components.slice();
    }
  }

  managePermission(componentId: number, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(PermissionModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ComponentId = componentId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IComponent) => {
      if (result) {
        this.authService.LoadComponents();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  deletePermission(id: number) {
    const message = 'Deleting the permission cannot be undone and will remove all references to it.\nWould you like to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe)
      ).subscribe(answer => {
      if (answer) {
        this.authService.DeleteComponent(id);
      }
    });
  }

  ///////////////////////////////////////
  /// Role and Role feature Functions ///
  ///////////////////////////////////////
  filterRoles() {
    if (this.roleSearchText && this.roleSearchText.length > 0) {
      this.filteredRoles = this.roles.filter((m) => m.Name.toLocaleLowerCase().includes(this.roleSearchText.toLocaleLowerCase()));
    } else {
      this.filteredRoles = this.roles.slice();
    }
  }

  manageRole(roleId: number, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(RoleModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.RoleId = roleId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IRole) => {
      if (result) {
        this.authService.LoadRoles();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  deleteRole(id: number) {
    const message = 'This action will delete the Role, Features and all users with this role assigned will no longer have access to it\'s permissions.\nWould you like to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(answer => {
        if (answer) {
          this.authService.DeleteRole(id);
        }
      });
  }
}
