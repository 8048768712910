import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IRateRequestActionLogDto,
  IRateRequestActionLogParam,
  IRateRequestDto
} from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-action-log-modal',
  templateUrl: './view-action-log-modal.component.html',
  styleUrls: ['./view-action-log-modal.component.scss']
})
export class ViewActionLogModalComponent implements OnInit, OnDestroy {
  // Icons

  // Variables
  public RateRequest: IRateRequestDto;
  ActionLogs: IRateRequestActionLogDto[] = [];

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(public activeModal: NgbActiveModal,
              private pricingService: PricingService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (this.RateRequest) {
      this.LoadActionLogs();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public LoadActionLogs() {
    this.loading = true;

    const param = {
      RateRequestId: this.RateRequest.Id,
      RateRequestActionTypeId: null,
      ActionValue: null,
      ActionComment: null,
      ActionByUserId: null
    } as IRateRequestActionLogParam;

    this.pricingService.GetRateRequestActionLog(param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestActionLogDto[]) => {
        if (data) {
          this.ActionLogs = Object.assign([], data);
        }
        this.loading = false;
      }, error: () => {
        this.alertService.warn('Unable to retrieve logs for this Rate Request ID: ' + this.RateRequest.Id);
        this.loading = false;
      }
    });
  }

  close() {
    this.activeModal.dismiss();
  }
}
