import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-dashboard-container-pricing',
  templateUrl: './dashboard-container-pricing.component.html',
  styleUrls: ['./dashboard-container-pricing.component.scss']
})
export class DashboardContainerPricingComponent implements OnInit {

  // Component variables


  // General variables


  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit(): void { }

}
