import { Component, OnInit } from '@angular/core';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
  // Icons
  faToHome = faHandPointLeft;

  constructor(public router: Router) { }

  ngOnInit() {
  }
}
