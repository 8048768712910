import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { ArticleService } from '../../../../services/article.service';
import {
  IArticleReviewRequest,
  IArticleReviewResponse,
  IArticleStatusLog
} from '../../../../services/models/news/article.model';
import { NewsRoutes } from '../../../../core/constants/routes';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import {
  ArticleReviewCommentModalComponent
} from '../article-review/article-review-comment-modal/article-review-comment-modal.component';

@Component({
  selector: 'app-article-change-review',
  templateUrl: './article-change-review.component.html',
  styleUrls: ['./article-change-review.component.scss']
})
export class ArticleChangeReviewComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faThumbsUp = faThumbsUp;
  protected readonly faThumbsDown = faThumbsDown;
  protected readonly faInfo = faCircleQuestion;

  // Route Parameter
  public articleId: number;
  // Component variables
  articleReview: IArticleReviewResponse = null;
  articleLogs: IArticleStatusLog[] = [];
  imageBaseUrl: string = '';

  // General variables
  protected readonly env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(private route: ActivatedRoute,
              private authService: AdAuthService,
              private alertService: AlertService,
              private articleService: ArticleService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.imageBaseUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/`

    if (this.authService.CurrentUser) {
      this.initArticle();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.initArticle();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  returnToOverview() {
    this.router.navigate([`${NewsRoutes.Article_Management}`])
      .then(() => {
      });
  }

  initArticle() {
    this.route.paramMap.subscribe(paramMap => {
      const articleId = Number(paramMap.get('articleId'));
      if (articleId && articleId > 0) {
        this.loading = true;
        this.articleId = articleId;
        this.articleService.getReview(articleId)
          .subscribe({
            next: (response: IArticleReviewResponse) => {
              this.loading = false;
              if (response != null && response.Article?.AuthorUserId == this.authService.CurrentUser.UserId) {
                switch (response.Article?.Status) {
                  case 'Published':
                    this.alertService.warn('The article has already been published. No action required');
                    this.returnToOverview();
                    break;
                  case 'Draft':
                    this.alertService.warn('Article is still in drafts');
                    this.returnToOverview();
                    break;
                  case 'Awaiting Approval':
                    this.alertService.warn('The article is still awaiting review');
                    this.returnToOverview();
                    break;
                  case 'Rejected':
                    this.alertService.warn('The article has been rejected and can no longer be actioned');
                    this.returnToOverview();
                    break;
                  case 'Change Requested':
                    this.articleReview = Object.assign({}, response);
                    this.loadArticleStatusLogs();
                    break;
                }
              } else {
                this.alertService.warn('Only the original author can action their own articles');
                this.returnToOverview();
              }
            },
            error: () => {
              this.alertService.error('Failed to retrieve article change request');
              this.returnToOverview();
              this.loading = false;
            }
          });
      } else {
        this.alertService.error('Article note found');
        this.returnToOverview();
      }
    })
  }

  loadArticleStatusLogs() {
    this.articleService.getArticleStatusLogs(this.articleId)
      .subscribe({
        next: (data) => {
          this.articleLogs = Object.assign([], data);
        }
      });
  }

  respondToRequest() {
    // Open modal to create a new user
    const modalRef = this.modalService.open(ArticleReviewCommentModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.ArticleId = this.articleId;
    modalRef.componentInstance.Title = 'Request for article to be reviewed again';
    modalRef.componentInstance.Message = `Do you wish to request that the article be reviewed again? <br><br> Please provide a reason below to proceed:`;
    // On modal close, read the result and apply logic
    modalRef.result.then((comment: string) => {
      if (comment && comment.length > 0) {
        const param = {
          ArticleId: this.articleReview.Article.ArticleId,
          Comment: comment
        } as IArticleReviewRequest;
        this.articleService.requestReApproval(param).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
          next: (response: IArticleReviewResponse) => {
            this.alertService.success('Request for re-approval sent successfully!');
            this.returnToOverview();
          },
          error: () => {
            this.alertService.error('Error: Request for re-approval failed');
          }
        });
      }
    });
  }

  // Author approval of suggested changes
  approveArticleChanges() {
    const date = formatDate(this.articleReview.Review.PublishDate, environment.FormattingStandards.ShortDateFormat, 'en_US');
    let message = `By approving you agree to the changes suggested by ${this.articleLogs[0].UserDisplayName}. \n They will be applied to the article which will be published and available on ${date}. \n\n Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(answer => {
          if (answer) {
            const successMessage = `Article is approved. It will be available on ${date}.`;
            return this.articleService.approveChanges(this.articleReview.Article.ArticleId)
              .pipe(
                tap(() => {
                  this.alertService.success(successMessage);
                  this.returnToOverview();
                }));
          }
        })
      ).subscribe();
  }

  rejectChanges() {
    // Open modal to create a new user
    const modalRef = this.modalService.open(ArticleReviewCommentModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.ArticleId = this.articleId;
    modalRef.componentInstance.Title = 'Reject suggested changes';
    modalRef.componentInstance.Message = `The article will be rejected. You will not be able to make further changes and it will not be published. <br><br> Please provide a comment to the reviewer below to proceed:`;
    // On modal close, read the result and apply logic
    modalRef.result.then((comment: string) => {
      if (comment && comment.length > 0) {
        const param = {
          ArticleId: this.articleReview.Article.ArticleId,
          Comment: comment
        } as IArticleReviewRequest;
        return this.articleService.reject(param).pipe(
          takeUntil(this.unsubscribe)
        ).subscribe({
          next: (response: IArticleReviewResponse) => {
            this.alertService.success('Article has been successfully rejected');
            this.returnToOverview();
          },
          error: () => {
            this.alertService.error('Error: Failed to reject article');
          }
        });
      }
    });
  }
}
