import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ApiService } from '../services/api.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { HarmonyReuseStrategy } from './guards/HarmonyReuseStrategy';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    CookiePolicyComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
    NgOptimizedImage
  ],
  exports: [
    CookiePolicyComponent
  ],
  providers: [
    ApiService,
    {provide: RouteReuseStrategy, useClass: HarmonyReuseStrategy}
  ]
})

export class CoreModule { }
