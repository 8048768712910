import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { faEdit, faFileText, faPlus, faTrash, faAdd, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { IBranchDto, IBranchResultDto, IBranchSearchDto} from '../../../../services/models/hse/branch.model';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AlertService } from '../../../../services/alert.service';
import { environment } from '../../../../../environments/environment';
import { Router} from "@angular/router";
import { SharedModule} from "../../../../shared/shared.module";
import { FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import { BranchAdminModalComponent} from "./branch-admin-modal/branch-admin-modal.component";
import { IPage, IPaginationData} from "../../../../shared/models/pagination-data.model";
import { IOrderParam} from "../../../../shared/directives/sort/order.directive";

@Component({
  selector: 'app-branch-admin',
  templateUrl: './branch-admin.component.html',
  styleUrls: ['./branch-admin.component.scss'],
  imports: [
    SharedModule,
    FontAwesomeModule,
    NgClass,
    NgIf,
    NgForOf,
    AsyncPipe,
    DatePipe
  ],
  standalone: true
})
export class BranchAdminComponent implements OnInit {

  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  faPlus = faPlus;
  faSortAsc = faSortDown;

  // Component Variables
  public readonly PermissionCodes = PermissionCodes;
  branch: IBranchResultDto;
  branches: IBranchDto[];
  public branchSearchParams: IBranchSearchDto = {
    SearchText: null,
    CompanyId: null,
    BranchId: null,
    CurrentPage: null,
    RowCount: null
  }
  orderParam: IOrderParam;

  //Roles/User rights
  isAdmin: boolean = false;

  // Shared Components
  loading: boolean;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  paginationData: IPaginationData<IBranchResultDto> = null;
  page: IPage;

  companies$ = this.companyService.ActiveMembers$.asObservable();
  environment = environment;

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              public authService: AdAuthService,
              private alertService: AlertService,
              public router: Router,
              private hseService: HseService) {
  }

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    } as IPaginationData<IBranchResultDto>;

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    this.branchSearchParams = {
      BranchId: null,
      CompanyId: null,
      SearchText: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    }

    this.orderParam = {
      OrderBy: 'Company.Name',
      OrderDirection: 'asc'
    } as IOrderParam;

    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.loadBranches();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.setPermissions();
        this.loadBranches();
      });
  }

  setPermissions() {
    this.isAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.branchSearchParams.CurrentPage = page.pageNumber;
    this.loadBranches();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.loadBranches();
  }

  createBranch() {

    const modalRef = this.modalService.open(BranchAdminModalComponent, {
      size: 'xl',
      backdrop: 'static'
    });

    modalRef.componentInstance.Branch = {
      Address1: null,
      Address2: "",
      CityId: 0,
      CompanyId: 0,
      CountryId: 0,
      Id: 0,
      Name: null,
      ZipCode: ""
    } as IBranchResultDto;

    // On modal close, read the result and apply logic
    modalRef.result.then((branch: IBranchResultDto) => {
      if(branch){
        this.alertService.info('Branch ' + branch.Name + ' successfully created.');
        this.loadBranches();
      }
    }, () => { });

  }

  searchBranches() {
    this.loadBranches()
  }

  openBranch(branch: IBranchResultDto) {

    const modalRef = this.modalService.open(BranchAdminModalComponent, {
      size: 'xl',
      backdrop: 'static'
    });
    modalRef.componentInstance.Branch = branch;

    // On modal close, read the result and apply logic
    modalRef.result.then((branch: IBranchResultDto) => {
        this.alertService.info('Branch ' + branch.Name + ' successfully updated.');
        this.loadBranches();
    }, () => { });

  }

  deleteBranch(branch: IBranchResultDto) {
    const message = 'Are you sure you want to delete this branch?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (branch != null) {
            this.loading = true;

            branch.Active = false;

            this.hseService.deleteBranch(branch.Id)
              .subscribe({
                next: () => {
                  this.alertService.info('Branch ' + branch.Name + ' successfully deleted.');
                  this.loadBranches();
                  this.loading = false;
                },
                error: () => {
                  this.loading = false;
                  this.alertService.error("An error occurred while deleting the Branch.");
                }
              });
          }
        }
      });
  }

  loadBranches() {
    this.loading = true;

    const p = this.page;
    const o = this.orderParam;

    this.hseService.getCompanyBranches(this.branchSearchParams, p, o)
      .subscribe({
        next: (data): void => {
          this.paginationData = Object.assign([], data);
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  resetSearch() {
    this.branchSearchParams.CompanyId = null;
    this.branchSearchParams.SearchText = null;
    this.loadBranches();
  }
}
