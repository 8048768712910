<!----------------------->
<!------- Loaders ------->
<!----------------------->
<div class="row" *ngIf="tenderService.TenderLoading">
  <div class="col-12 text-center pt-5 mt-5">
    <div class="rate-wizard-loader mt-5">
      <app-loader></app-loader>
    </div>
  </div>
  <div class="col-12 text-center pt-5">
    <p class="mt-4 harmony-grey-text" *ngIf="tenderService.TenderLoading">
      Loading data for
    </p>
    <h4 class="text-capitalize harmony-grey-text">
      {{ tenderToLoad?.Name }}
    </h4>
    <h4 class="fw-bold mt-4 harmony-grey-text" *ngIf="tenderService.ExportingRates">
      Your rates are being exported to excel. This may take a few minutes depending on the size of the tender.
    </h4>
  </div>
</div>
<!---------------------->
<!----- Header Bar ----->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header"
     *ngIf="tenderService.TenderLoading === false">
  <h1 class="portal-component-header">
    {{ tenderService.TenderView && tenderService.TenderView ? tenderService.TenderView.Name : 'Rate Tenders' }}
  </h1>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="btn-group" *ngIf="!tenderService.TenderView">
      <button type="button" class="btn btn-sm btn-outline-primary harmony-purple-outline-button" (click)="createNew()">+ New Tender</button>
    </div>
    <!----------------------->
    <!---- Excel Export ----->
    <!----------------------->
    <span *ngIf="tenderService.IsExpired()" class="harmony-grey-text pt-1 me-3">
        Tender expired, export disabled
    </span>
    <button type="submit"
            *ngIf="tenderService.IsQuoteAccepted() && !tenderService.IsExpired()"
            class="btn btn-sm btn-primary excel-export-btn me-2"
            (click)="exportRates()">
      Export to Excel&nbsp; <fa-icon [icon]="faExcel" size="sm"></fa-icon>
    </button>
    <div class="btn-group" *ngIf="tenderService.TenderView">
      <button type="button" class="btn btn-sm btn-primary harmony-teal-button" (click)="cancelCreateEdit()">Exit</button>
    </div>
  </div>
</div>
<!---------------------------------->
<!------ Rate Wizard Overview ------>
<!---------------------------------->
<ng-container *ngIf="!tenderService.TenderView && !tenderService.TenderLoading">
  <app-rate-wizard-overview (TenderSelected)="SetTender($event)"></app-rate-wizard-overview>
</ng-container>
<!------------------------->
<!------ Rate Wizard ------>
<!------------------------->
<ng-container *ngIf="tenderService.TenderView  && !tenderService.TenderLoading">
  <div class="container-fluid">
    <!----------------------->
    <!---- Wizard Steps ----->
    <!----------------------->
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item me-1">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 1, 'inactive-step': currentStep !== 1}">{{ getStepName(1) }}</a>
          </li>
          <li class="nav-item me-1">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 2, 'inactive-step': currentStep !== 2}">{{ getStepName(2) }}</a>
          </li>
          <li class="nav-item me-1">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 3, 'inactive-step': currentStep !== 3}">{{ getStepName(3) }}</a>
          </li>
          <li class="nav-item me-1">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 4, 'inactive-step': currentStep !== 4}">{{ getStepName(4) }}</a>
          </li>
          <li class="nav-item me-1">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 5, 'inactive-step': currentStep !== 5}">{{ getStepName(5) }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active-step': currentStep === 6 || currentStep === 7, 'inactive-step': currentStep !== 6 && currentStep !== 7}">{{ getStepName(6) }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!------------------------------>
    <!---- Wizard Step Content ----->
    <!------------------------------>
    <div class="row" id="step-1" *ngIf="currentStep === 1">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-create-tender></app-step-create-tender>
      </div>
    </div>
    <div class="row" id="step-2" *ngIf="currentStep === 2">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-locations></app-step-locations>
      </div>
    </div>
    <div class="row" id="step-3" *ngIf="currentStep === 3">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-modality></app-step-modality>
      </div>
    </div>
    <div class="row" id="step-4" *ngIf="currentStep === 4">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-freight></app-step-freight>
      </div>
    </div>
    <div class="row" id="step-5" *ngIf="currentStep === 5">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-services></app-step-services>
      </div>
    </div>
    <div class="row" id="step-6" *ngIf="currentStep === 6 || currentStep === 7">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <app-step-rate-export></app-step-rate-export>
      </div>
    </div>
    <div class="row step-action-bar shadow">
      <div class="col-12 text-end mt-2">
        <!----------------------->
        <!---- Loader Image ----->
        <!----------------------->
        <img *ngIf="tenderService.loading"
             class="position-relative me-3"
             ngSrc="../../../../assets/loaders/ball-triangle.svg"
             height="25"
             width="25" alt="loader"/>
        <!----------------------->
        <!---- Back Buttons ----->
        <!----------------------->
        <button type="submit"
                class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-2"
                [disabled]="tenderService.loading"
                *ngIf="currentStep > 1 && currentStep <= 5"
                (click)="prevStep()">
          <fa-icon [icon]="faLeftArrow" size="sm"></fa-icon>&nbsp; Previous
        </button>
        <button type="submit"
                class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-2"
                [disabled]="tenderService.loading"
                *ngIf="currentStep >= 6"
                (click)="leaveExportStep()">
          <fa-icon [icon]="faLeftArrow" size="sm"></fa-icon>&nbsp; Previous
        </button>
        <!----------------------->
        <!---- Save Button ------>
        <!----------------------->
        <button type="submit"
                *ngIf="canSaveStep()"
                [disabled]="tenderService.loading"
                [ngbTooltip]="'Save your current progress. Unsaved changes will be lost when exiting.'"
                class="btn btn-sm btn-primary harmony-purple-outline-button me-2" (click)="saveProgress()">
          Save
        </button>
        <!----------------------->
        <!---- Next Button ------>
        <!----------------------->
        <button type="submit"
                *ngIf="currentStep < 6 && !tenderService.IsQuoteAccepted()"
                class="btn btn-sm btn-primary harmony-purple-button me-2"
                [disabled]="!isStepValid() || tenderService.loading"
                (click)="nextStep()">
          {{ getNextStepName(currentStep) }}&nbsp; <fa-icon [icon]="faRightArrow" size="sm"></fa-icon>
        </button>
        <button type="submit"
                *ngIf="currentStep < 6 && tenderService.IsQuoteAccepted()"
                class="btn btn-sm btn-primary harmony-purple-button me-2"
                [disabled]="!isStepValid() || tenderService.loading"
                (click)="updateStep(currentStep + 1)">
          {{ getNextStepNamePostExport(currentStep) }}&nbsp; <fa-icon [icon]="faRightArrow" size="sm"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>

