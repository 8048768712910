import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  IRiskAssessmentAnswer,
  IRiskAssessmentGroup,
  IRiskAssessmentItem
} from '../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../core/ad-auth-service/ad-auth.service";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-risk-assessment-answer-modal',
  templateUrl: './risk-assessment-answer-modal.component.html',
  styleUrls: ['./risk-assessment-answer-modal.component.scss']
})
export class RiskAssessmentAnswerModalComponent {
  // Component Variables
  isValid: boolean = false;
  riskAssessmentAnswer: IRiskAssessmentAnswer;
  riskAssessmentItem: IRiskAssessmentItem;
  riskAssessmentGroup: IRiskAssessmentGroup;

  exposure$ = this.hseService.Exposure$.asObservable();
  likeliness$ = this.hseService.Likeliness$.asObservable();
  severity$ = this.hseService.Severity$.asObservable();
  influence$ = this.hseService.Influence$.asObservable();
  measure$ = this.hseService.Measure$.asObservable();
  levelOfMeasures$ = this.hseService.LevelOfMeasures$.asObservable();
  riskAssessmentAnswerStatus$ = this.hseService.RiskAssessmentAnswerStatus$.asObservable();

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) { }

  validate(): boolean {
    this.isValid = (this.riskAssessmentAnswer.ExposureValue != null &&
      this.riskAssessmentAnswer.Likeliness != null &&
      this.riskAssessmentAnswer.SeverityValue != null &&
      this.riskAssessmentAnswer.InfluenceValue != null &&
      (this.riskAssessmentAnswer.Legislation != null && this.riskAssessmentAnswer.Legislation.length > 0) &&
      this.riskAssessmentAnswer.MeasureValue != null &&
      this.riskAssessmentAnswer.LevelOfMeasuresId != null &&
      this.riskAssessmentAnswer.StatusId != null &&
      ((this.riskAssessmentAnswer.RemainingExposure == null && this.riskAssessmentAnswer.StatusId == 1) ||
      (this.riskAssessmentAnswer.RemainingExposure != null)) &&
      ((this.riskAssessmentAnswer.RemainingLikeliness == null && this.riskAssessmentAnswer.StatusId == 1) ||
      (this.riskAssessmentAnswer.RemainingLikeliness != null)) &&
      ((this.riskAssessmentAnswer.RemainingSeverity == null && this.riskAssessmentAnswer.StatusId == 1) ||
      (this.riskAssessmentAnswer.RemainingSeverity != null))
    );

    return this.isValid;
  }

  updateAnswer() {
     if (this.validate()) {
       this.riskAssessmentAnswer.LastModifiedByUserId = this.authService.CurrentUser.UserId;

       this.hseService.upsertRiskAssessmentAnswer(this.riskAssessmentAnswer)
         .subscribe({
           next: (data) => {
             this.riskAssessmentAnswer = Object.assign({}, data);
             this.alertService.info('Assessmentitem ' + this.riskAssessmentItem.ItemNumber + ' successfully saved.');
             this.runCheck();
           },
           error: () => {
             this.alertService.error('Failed to update risk assessment item.');
           }
         });
     } else {
       this.alertService.error('Please fill all required fields outlined red.');
     }
  }

  runCheck() {
    this.hseService.checkRiskAssessmentComplete(this.riskAssessmentAnswer.RiskAssessmentId)
      .subscribe({
        next: (response: boolean) => {
          if(response) {
            this.alertService.info('The assessment has been completed and set to Active');
          }
          this.close(this.riskAssessmentAnswer);
        },
        error: () => {
          this.alertService.warn('Failed checking the risk assessment.');
        }
      });
  }

  calculateIRL(){
    this.riskAssessmentAnswer.RiskLevel1 = Number((this.riskAssessmentAnswer.ExposureValue * this.riskAssessmentAnswer.Likeliness * this.riskAssessmentAnswer.SeverityValue).toFixed(2));
  }

  calculateRRL(){
    this.riskAssessmentAnswer.RemainingTotal = Number((this.riskAssessmentAnswer.RemainingExposure * this.riskAssessmentAnswer.RemainingLikeliness * this.riskAssessmentAnswer.RemainingSeverity).toFixed(2));
  }

  residualRisksIsEmpty(): boolean {
    return this.riskAssessmentAnswer.RemainingExposure == null
      || this.riskAssessmentAnswer.RemainingLikeliness == null
      || this.riskAssessmentAnswer.RemainingSeverity == null
  }

  setRiskLevelColor(riskLevel: number): string {
    if (riskLevel <= 20 && !this.residualRisksIsEmpty()) {
      return 'risk-level-green';
    }

    if (riskLevel > 20 && riskLevel <= 70){
      return 'risk-level-lightgreen';
    }

    if (riskLevel > 70 && riskLevel <= 200) {
      return 'risk-level-yellow';
    }

    if (riskLevel > 200 && riskLevel <= 400) {
      return 'risk-level-orange';
    }

    if (riskLevel > 400) {
      return 'risk-level-red';
    }
  }

  setResidualRiskLevelColor(riskLevel: number): string {
    if (riskLevel <= 20 && !this.residualRisksIsEmpty()) {
      return 'risk-level-green';
    }

    if (riskLevel > 20 && riskLevel <= 70){
      return 'risk-level-lightgreen';
    }

    if (riskLevel > 70 && riskLevel <= 200) {
      return 'risk-level-yellow';
    }

    if (riskLevel > 200 && riskLevel <= 400) {
      return 'risk-level-orange';
    }

    if (riskLevel > 400) {
      return 'risk-level-red';
    }
  }

  close(riskAssessmentAnswer: IRiskAssessmentAnswer) {
    this.activeModal.close(riskAssessmentAnswer);
  }

  protected readonly faInfo = faCircleQuestion;
}
