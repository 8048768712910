<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    Review Request
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p>
        Please provide a reason for declining this request:
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <textarea class="form-control form-control-sm"
                [rows]="4"
                [autofocus]="true"
                [(ngModel)]="CompanyRequest.Comment">
      </textarea>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-outline-danger w-50 text-center" (click)="decline()">Decline Request</button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="cancel()">Cancel</button>
</div>
