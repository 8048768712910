import { Component, OnDestroy, OnInit } from '@angular/core';
import { faBoxOpen, faEdit, faEye, faPlus, faSortDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import {
  ICityFunctionDto,
  ICityFunctionPageParam,
  ICityFunctionPageResult
} from '../../../../services/models/pricing/location.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { CityFunctionEditModalComponent } from './city-function-edit-modal/city-function-edit-modal.component';
import { IFunctionType } from '../../../../services/models/pricing/rates.model';
import { AlertService } from '../../../../services/alert.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-city-function-management',
  templateUrl: './city-function-management.component.html',
  styleUrls: ['./city-function-management.component.scss']
})
export class CityFunctionManagementComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faSortAsc = faSortDown;
  protected readonly faDelete = faTrash;
  protected readonly faRemove = faTrash;
  protected readonly faAdd = faPlus;

  // Component variables
  paginationData: IPaginationData<ICityFunctionPageResult>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: ICityFunctionPageParam;
  FunctionTypes$: BehaviorSubject<IFunctionType[]> = new BehaviorSubject<IFunctionType[]>([]);
  hasDeletePermission: boolean = false;

  // General Variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private auth: AdAuthService) {}

  ngOnInit() {
    this.loading = true;
    this.initPagination();
    // Load agents
    this.searchCityFunctions();
    // Set permissions
    this.hasDeletePermission = this.auth.CheckPermissionByCode(PermissionCodes.Admin_LocationDelete);
  }

  initPagination() {
    this.loadFunctionTypes();
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set filter
    this.searchParam = {
      SearchText: null,
      CountryId: null,
      StateId: null,
      CityId: null,
      Active: true,
      FunctionTypeId: null
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Description',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchFilter() {
    this.page.pageNumber = 1;
    this.searchCityFunctions();
  }

  resetSearch() {
    this.initPagination();
    this.searchFilter();
  }

  loadFunctionTypes() {
    this.loading = true;

    this.api.get('Lookup/FunctionTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.FunctionTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: LoadFunctionTypes');
        this.loading = false;
      }
    });
  }

  searchCityFunctions() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`CityFunction/List?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<ICityFunctionPageResult>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchCityFunctions();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchCityFunctions();
  }

  newCityFunction() {
    const modalRef = this.modalService.open(CityFunctionEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CityFunctionId = null;
    modalRef.componentInstance.FunctionTypes = this.FunctionTypes$.getValue();
    modalRef.result.then((result: ICityFunctionDto) => {
        this.searchCityFunctions();
      }, () => { }
    );
  }

  editCityFunction(cityFunction: ICityFunctionPageResult) {
    // Open modal to edit a user
    const modalRef = this.modalService.open(CityFunctionEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CityFunctionId = cityFunction.Id;
    modalRef.componentInstance.FunctionTypes = this.FunctionTypes$.getValue();
    // On modal close, read the result and refresh user list
    modalRef.result.then((result: ICityFunctionDto) => {
        this.searchCityFunctions();
      }, () => { }
    );
  }

  toggleCityFunction(cityFunction: ICityFunctionPageResult) {
    cityFunction.Active = !cityFunction.Active;
    this.api.get(`CityFunction/ToggleActive/${cityFunction.Id}/${cityFunction.Active}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('City Function record successfully updated!');
      }, error: () => {
        this.alertService.error('City Function record failed to update');
      }
    });
  }
}
