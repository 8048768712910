<div class="modal-header">
  <h4 class="modal-title">
    {{ this.ModuleId ? 'Editing Module ID: ' + this.ModuleId : 'New Module' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="module">
    <div class="row mt-1">
      <div class="col-3">
        <b>Name</b>
      </div>
      <div class="col-9" style="line-height: 11px;">
        <input type="text"
               [ngClass]="{'is-invalid': nameInvalid}"
               class="form-control"
               [(ngModel)]="module.Name">
        <small class="harmony-grey-text">Required. Minimum length of 3 characters.</small>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <b>Description</b>
      </div>
      <div class="col-9">
      <textarea type="text"
                class="form-control"
                [(ngModel)]="module.Description"></textarea>
      </div>
    </div>
    <div class="row mt-3" >
      <div class="col-3">
        <b>Linked Permissions</b>
      </div>
      <div class="col-9">
        <ng-container *ngIf="!module.Components || module.Components.length <= 0">
          {{ module.Id == null ? 'Save the new module before components can be linked.' : 'Module has no linked components...' }}
        </ng-container>
        <ng-container *ngIf="module.Components && module.Components.length > 0">
          <div class="row result-row" *ngFor='let component of module.Components; let i = index'>
            <div class="col-8 overflow-hidden ps-0">
              {{component.Name}}
            </div>
            <div class="col-3">
              <code>{{component.Code}}</code>
            </div>
            <div class="col-1 overflow-hidden" *ngIf="component.Description && component.Description.length > 0" [title]="component.Description">
              <fa-icon [icon]="faInfo" class="harmony-grey-text"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveModule()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

