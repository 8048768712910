import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-clients',
  templateUrl: './hub-clients.component.html',
  styleUrls: ['./hub-clients.component.scss']
})
export class HubClientsComponent {

}
