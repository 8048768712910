import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AzureService {

  constructor(private http: HttpClient) { }

  private static formatErrors(error: any) {
    return throwError(error.error);
  }

  resubmitToTargetDecision(param: any) {
    return this.http.post(
      environment.azureEndpoints.resubmitMessage,
      param).pipe(catchError(AzureService.formatErrors));
  }
}
