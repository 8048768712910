<div class="row mt-3">
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>Template</b>
          </div>
          <div class="col-8">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(emailTemplates$ | async)"
                    [(ngModel)]="searchParam.TemplateID">
              <option [ngValue]="null" selected>Select a template...</option>
              <option *ngFor="let template of (emailTemplates$ | async)" [ngValue]="template.Id">{{ template.DisplayName }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>Start Date From</b>
          </div>
          <div class="col-8">
            <app-date-picker [identifier]="'startDateFrom'" (onChange)="searchParam.FromStartDate = $event" [title]="'Select a start date'"></app-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>Data Source</b>
          </div>
          <div class="col-8">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(dataSources$ | async)"
                    [(ngModel)]="searchParam.DataSourceID">
              <option [ngValue]="null" selected>Select a data source...</option>
              <option *ngFor="let dataSource of (dataSources$ | async)" [ngValue]="dataSource.Id">{{ dataSource.Name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>End Date Before</b>
          </div>
          <div class="col-8">
            <app-date-picker [identifier]="'endDateTo'" (onChange)="this.searchParam.ToEndDate = $event" [title]="'Select an end date'"></app-date-picker>
          </div>
        </div>
      </div>
      <div class="col-4">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>Recurrence</b>
          </div>
          <div class="col-8">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(recurrenceTypes$ | async)"
                    [(ngModel)]="searchParam.RecurrenceTypeID">
              <option [ngValue]="null" selected>Select a recurrence type...</option>
              <option *ngFor="let type of (recurrenceTypes$ | async)" [ngValue]="type.Id">{{ type.Name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-4">

      </div>
      <div class="col-4">

      </div>
    </div>
    <hr class="my-1">
    <div class="row mt-1 mb-1">
      <div class="col-1 offset-9 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                (click)="searchEmailSchedules()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
  </div>
</div>
<div class="row mt-2 header-row">
  <div class="col-3 header-title pointer border-split-left"
       (appOrder)="orderSearch($event)"
       data-column="Name"
       data-order="asc">
    Name
    <fa-icon [hidden]="orderParam.OrderBy != 'Name'" [icon]="faSortDesc" style="bottom: -3px;"  class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Template.Name"
       data-order="asc">
    Template
    <fa-icon [hidden]="orderParam.OrderBy != 'Template.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DataSource.Name"
       data-order="asc">
    Source
    <fa-icon [hidden]="orderParam.OrderBy != 'DataSource.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="StartDate"
       data-order="asc">
    Start Date
    <fa-icon [hidden]="orderParam.OrderBy != 'StartDate'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="EndDate"
       data-order="asc">
    End Date
    <fa-icon [hidden]="orderParam.OrderBy != 'EndDate'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-center header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="ScheduleTime"
       data-order="asc">
    Run Time
    <fa-icon [hidden]="orderParam.OrderBy != 'ScheduleTime'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="RecurrenceType.Name"
       data-order="asc">
    Recurrence
    <fa-icon [hidden]="orderParam.OrderBy != 'RecurrenceType.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title border-split-right">

  </div>
</div>
<ng-container *ngIf="!loading">
  <div class="row result-row" *ngFor='let schedule of emailSchedules; let i = index'>
    <div class="col-3 overflow-hidden" [title]="schedule.Name">
      <b><small>{{ schedule.Name }}</small></b>
    </div>
    <div class="col-2 overflow-hidden" [title]="schedule.Template?.Name">
      <small>{{ schedule.Template?.Name }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      {{ schedule.DataSource.Name }}
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ schedule.StartDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <div class="col-1 overflow-hidden"
         [ngClass]="{'text-warning': getDayDifference(schedule.EndDate) <= 7, 'text-danger': getDayDifference(schedule.EndDate) <= 0}">
      <small>{{ schedule.EndDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <div class="col-1 text-center overflow-hidden">
      <b>{{ schedule.ScheduleTime }}</b>
    </div>
    <div class="col-1 overflow-hidden">
      {{ schedule.RecurrenceType?.Name }}
    </div>
    <div class="col-2 text-end pe-1">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
              [title]="'Run Schedule'"
              (click)="runSchedule(schedule)">
        <fa-icon [icon]="faTrigger" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm btn-primary harmony-purple-outline-button me-1"
              [title]="'Manage Schedule'"
              (click)="editSchedule(schedule.Id)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm btn-outline-danger"
              [title]="'Disable schedule'"
              *ngIf="schedule.Active == true"
              (click)="disableSchedule(schedule)">
        <fa-icon [icon]="faDisable" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm btn-outline-success"
              [title]="'Enable Schedule'"
              *ngIf="schedule.Active == false"
              (click)="enableSchedule(schedule)">
        <fa-icon [icon]="faAdd" size="sm"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
