<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Permission Management
  </h1>
  <div>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-1"
            (click)="manageModule(null, true)">
      + New Module
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-1"
            (click)="managePermission(null, true)">
      + New Permission
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button"
            (click)="manageRole(null, true)">
      + New Role
    </button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs" id="permissionsTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="module-tab" data-bs-toggle="tab" href="#module" role="tab" aria-controls="module" aria-selected="true">
          Modules
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="component-tab" data-bs-toggle="tab" href="#component" role="tab" aria-controls="component" aria-selected="true">
          Permissions
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="roles-tab" data-bs-toggle="tab" href="#roles" role="tab" aria-controls="roles" aria-selected="false">
          Roles
        </a>
      </li>
    </ul>
    <!-------------------------------->
    <!------ Tab Content Start ------->
    <!-------------------------------->
    <div class="tab-content" id="permissionsTabContent">
      <!------------------------------>
      <!------ Manage Modules -------->
      <!------------------------------>
      <div class="tab-pane fade show active mb-5" id="module" role="tabpanel" aria-labelledby="module-tab">
        <div class="row mt-2">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <input type="text"
                       class="form-control form-control-sm"
                       [(ngModel)]="moduleSearchText"
                       (keyup)="filterModules()"
                       [placeholder]="'Search modules by name...'">
              </div>
              <div class="col-8 text-end">
                <div class="row">
                  <div class="col-11 pt-1">
                    <b>Modules:</b> {{ filteredModules.length }}
                  </div>
                  <div class="col-1">
                    <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                            (click)="authService.LoadModules()">
                      <fa-icon [icon]="faRefresh"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-0">
        <div class="row mt-2 header-row">
          <div class="col-3 header-title border-split-left">
            Name
          </div>
          <div class="col-7 header-title">
            Description
          </div>
          <div class="col-2 header-title border-split-right">
            Date Created
          </div>
        </div>
        <div class="row result-row" *ngFor="let module of filteredModules; let i = index; trackBy: identify">
          <div class="col-3 overflow-hidden">
            <b>{{module.Name}}</b>
          </div>
          <div class="col-7 overflow-hidden">
            {{module.Description}}
          </div>
          <div class="col-1 overflow-hidden">
            <small>{{module.DateCreated | date: environment.FormattingStandards.ShortDateFormat}}</small>
          </div>
          <div class="col-1 text-end pe-0">
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    [title]="'Edit Module: ' + module.Name"
                    (click)="manageModule(module.Id, false)">
              <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger"
                    [title]="'Delete Module'"
                    (click)="deleteModule(module.Id)">
              <fa-icon [icon]="faDelete" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!------------------------------>
      <!----- Manage Permissions ----->
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="component" role="tabpanel" aria-labelledby="component-tab">
        <div class="row mt-2">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <select name="component-module"
                        class="form-select form-select-sm"
                        id="component-module"
                        [(ngModel)]="componentSearchParam.moduleId"
                        (ngModelChange)="filterPermissions()">
                  <option [ngValue]="null">All modules</option>
                  <option *ngFor="let module of modules" [ngValue]="module.Id">
                    {{module.Name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-4">
                <input type="text"
                       class="form-control form-control-sm"
                       [(ngModel)]="componentSearchParam.searchText"
                       (ngModelChange)="filterPermissions()"
                       [placeholder]="'Search components by name...'">
              </div>
              <div class="col-8 text-end">
                <div class="row">
                  <div class="col-11 pt-1">
                    <b>Permissions:</b> {{ filteredComponents.length }}
                  </div>
                  <div class="col-1">
                    <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                            (click)="authService.LoadComponents()">
                      <fa-icon [icon]="faRefresh"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-0">
        <div class="row mt-2 header-row">
          <div class="col-3 header-title border-split-left">
            Name
          </div>
          <div class="col-1 header-title">
            Module
          </div>
          <div class="col-1 header-title">
            Code
          </div>
          <div class="col-5 header-title">
            Description
          </div>
          <div class="col-2 header-title border-split-right">
            Date Created
          </div>
        </div>
        <div class="row result-row" *ngFor="let component of filteredComponents; let i = index; trackBy: identify">
          <div class="col-3 overflow-hidden">
            <b>{{ component.Name }}</b>
          </div>
          <div class="col-1 overflow-hidden">
            {{ component.Module.Name }}
          </div>
          <div class="col-1 overflow-hidden">
            <code>{{ component.Code }}</code>
          </div>
          <div class="col-5 overflow-hidden" [title]="component.Description">
            {{ component.Description }}
          </div>
          <div class="col-1 overflow-hidden">
            <small>{{ component.DateCreated | date: environment.FormattingStandards.ShortDateFormat }}</small>
          </div>
          <div class="col-1 text-end pe-0">
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    [title]="'Edit permission: ' + component.Name"
                    (click)="managePermission(component.Id, false)">
              <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger"
                    [title]="'Delete permission'"
                    (click)="deletePermission(component.Id)">
              <fa-icon [icon]="faDelete" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!------------------------------>
      <!------- Manage Roles --------->
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="roles" role="tabpanel" aria-labelledby="roles-tab">
        <div class="row mt-2">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <input type="text"
                       class="form-control form-control-sm"
                       [(ngModel)]="roleSearchText"
                       (ngModelChange)="filterRoles()"
                       [placeholder]="'Search roles by name...'">
              </div>
              <div class="col-8 text-end">
                <div class="row">
                  <div class="col-11 pt-1">
                    <b>Roles:</b> {{ filteredRoles.length }}
                  </div>
                  <div class="col-1">
                    <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                            (click)="authService.LoadRoles()">
                      <fa-icon [icon]="faRefresh"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-0">
        <div class="row mt-2 header-row">
          <div class="col-3 header-title border-split-left">
            Name
          </div>
          <div class="col-7 header-title">
            Description
          </div>
          <div class="col-2 header-title border-split-right">
            Date Created
          </div>
        </div>
        <div class="row result-row" *ngFor='let role of filteredRoles; let i = index'>
          <div class="col-3 overflow-hidden">
            <b>{{role.Name}}</b>
          </div>
          <div class="col-7 overflow-hidden">
            {{role.Description}}
          </div>
          <div class="col-1 overflow-hidden">
            <small>{{role.DateCreated | date: environment.FormattingStandards.ShortDateFormat}}</small>
          </div>
          <div class="col-1 text-end pe-0">
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    [title]="'Edit Role: ' + role.Name"
                    (click)="manageRole(role.Id, false)">
              <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger"
                    [title]="'Delete Role'"
                    (click)="deleteRole(role.Id)">
              <fa-icon [icon]="faDelete" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
