import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IAuthUser } from "../../../../services/models/auth.model";
import { PermissionCodes } from "../../../../core/constants/permission-codes";


@Component({
  selector: 'app-dashboard-container-hse',
  templateUrl: './dashboard-container-hse.component.html',
  styleUrls: ['./dashboard-container-hse.component.scss']
})
export class DashboardContainerHseComponent implements OnInit {

  // Permissions
  pulseChart: boolean = false;
  incidentImprovement: boolean = false;
  riskAssessment: boolean = false;
  environmentalAssessment: boolean = false;
  companyChart: boolean = false;
  documents: boolean = false;

  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.setPermissions();
    });
  }

  setPermissions() {
    this.incidentImprovement = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement);
    this.pulseChart = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart);
    this.riskAssessment = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment);
    this.environmentalAssessment = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment);
    this.companyChart = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart);
    this.documents = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Documents);
  }

}
