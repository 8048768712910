import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { ICompanyDetail, ICompanyDetailDefinition } from '../../../services/models/member.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  @Input()
  set propertyCategory(category: string) {
    if (category) {
      this.category$.next(category);
    }
  }
  @Input() viewOnly: boolean;
  @Input() companyId: number;
  @Input() branchId: number;
  @Input() autoSave: boolean;
  @Input() showInfo: boolean;
  @Output() OnChange: EventEmitter<ICompanyDetail[]> = new EventEmitter<ICompanyDetail[]>;

  // Icons
  faInfo = faCircleQuestion;

  // Component variables
  output: ICompanyDetail[] = [];
  companyDetailDefinitions: ICompanyDetailDefinition[] = [];
  companyDetails: ICompanyDetail[] = [];
  orderedCompanyDetails: ICompanyDetail[] = [];
  category$ = new BehaviorSubject<string>(null);

  constructor(private companyService: CompanyService) { }

  ngOnInit(): void {
    this.category$.subscribe(x => {
      this.getCompanyDetailsDefinition(x);
    })
  }

  getCompanyDetailsDefinition(category: string) {
    if (this.companyId || this.branchId) {
    this.companyService.getCompanyDetailDefinitionByCategory(category).subscribe(
      {
        next: (data) => {
          this.companyDetailDefinitions = Object.assign([], data);
          this.getCompanyDetails(category);
        }
      });
    } else {
      this.category$.next(category);
    }
  }

  getCompanyDetails(category: string) {
    this.companyService.getCompanyDetailByCategory(category, this.companyId, this.branchId).subscribe(
      {
        next: (data) => {
          this.companyDetails = Object.assign([], data);
          this.assignValues();
        }
      });
  }

  updateValue(index: number, event: any) {
    const detail = this.orderedCompanyDetails[index];
    detail.PropertyValue = event;
    this.autoSaveOrEmitOutput(detail);
  }

  autoSaveOrEmitOutput(detail: ICompanyDetail) {
    if (this.autoSave) {
      this.companyService.UpsertCompanyDetail(detail).subscribe();
    } else {
      if (this.output.indexOf(detail) > -1) {
        this.OnChange.emit(this.output);
      } else {
        this.output.push(detail);
        this.OnChange.emit(this.output);
      }
    }
  }

  assignValues() {
    this.companyDetailDefinitions.forEach(d => {
      let detail = this.companyDetails?.find(x => x.CompanyDetailDefinitionId == d.Id);
      if (!detail) {
        detail = {
          Active: true,
          CompanyId: this.companyId,
          BranchId: this.branchId ?? null,
          CompanyDetailDefinitionId: d.Id,
        } as ICompanyDetail;
      }
      this.orderedCompanyDetails.push(detail);
    });
  }
}
