import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IIncidentReportDto, IRegistrationType } from "../../../../services/models/hse/hse.model";
import { HseService } from "../../../../services/hse.service";
import { AlertService } from "../../../../services/alert.service";
import { HSERoutes } from "../../../../core/constants/routes";

@Component({
  selector: 'app-dashboard-card-incident-improvement',
  templateUrl: './dashboard-card-incident-improvement.component.html',
  styleUrls: ['./dashboard-card-incident-improvement.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardIncidentImprovementComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Component variables
  incidentReport: IIncidentReportDto;
  companyId: number = 1;
  registrationTypeId: number = 0;
  disciplineId: number = 0;
  filteredRegistrationTypes: IRegistrationType[];
  registrationType$ = this.hseService.RegistrationType$.asObservable();
  discipline$ = this.hseService.RegistrationDiscipline$.asObservable();

  // General variables
  loading: boolean = false;

  constructor(public router: Router,
              private hseService: HseService,
              private alertService: AlertService,
              public authService: AdAuthService) { }

  ngOnInit() {
    this.getIncidentReport();
  }

  getIncidentReport(){
    this.hseService.GetIncidentReportDashboard(this.registrationTypeId, this.disciplineId)
      .subscribe({
        next: (data) => {
          this.incidentReport = Object.assign([], data);
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Failed to get the incident report');
          this.loading = false;
        }
      });
  }

  viewIncidents(status: string) {
    if (status == null) {
      this.router.navigate([`${HSERoutes.Incident_Improvement}`]).then(() => {
      });
    } else {
      this.router.navigate([`${HSERoutes.Incident_Improvement}`, {status: status}])
        .then(() => {
        });
    }
  }

  filterItemsOfDiscipline(disciplineId: number) {
    if (this.hseService.RegistrationType != undefined && disciplineId != null) {
      this.filteredRegistrationTypes = this.hseService.RegistrationType.filter(x => x.DisciplineId == disciplineId);
    }

    this.getIncidentReport();
  }
}
