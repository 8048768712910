<div class="modal-header">
  <h4 class="modal-title">
    Review Rate Groups
  </h4>
</div>
<div class="modal-body" style="max-height: 30em; overflow-y: auto;">
  <div class="row mb-0">
    <div class="col-12">
      New Rate Groups: {{ newRateGroups }}
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      Existing Groups: {{ existingRateGroups }}
    </div>
  </div>
  <div class="row mb-0 mt-3 header-row">
    <div class="col-2 header-title border-split-left">
      Agent
    </div>
    <div class="col-2 header-title">
      Type
    </div>
    <div class="col-3 header-title">
      Origin
    </div>
    <div class="col-3 header-title">
      Destination
    </div>
    <div class="col-1 header-title">
      Services
    </div>
    <div class="col-1 header-title border-split-right">

    </div>
  </div>
  <div class="row" *ngIf="RateGroups && RateGroups.length === 0">
    <div class="col-12 text-center">
      No actions found.
    </div>
  </div>
  <div class="row result-row border-top" *ngFor='let rg of RateGroups; let i = index'>
    <div class="col-2 overflow-hidden">
      {{ rg.AgentName }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ rg.RateRequestTypeName }}
    </div>
    <div class="col-3 overflow-hidden">
      <small>
        <!-- Location types City, Country, State -->
        <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
        {{ rg.OriginLocationName ? rg.OriginLocationName : '-' }}
        <br>
        <!-- Location types Seaport or Airport -->
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        {{ rg.DeparturePortDescription ?  rg.DeparturePortDescription : '-' }}
      </small>
    </div>
    <div class="col-3 overflow-hidden">
      <small>
        <!-- Location types City, Country, State -->
        <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
        {{ rg.DestinationLocationName ? rg.DestinationLocationName : '-' }}
        <br>
        <!-- Location types Seaport or Airport -->
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        {{ rg.ArrivalPortDescription ?  rg.ArrivalPortDescription : '-' }}
      </small>
    </div>
    <div class="col-1 overflow-hidden">
      {{ rg.RateGroupServices.length }}
    </div>
    <div class="col-1 overflow-hidden">
      <small *ngIf="rg.ID">({{ rg.ID }})</small>
      <fa-icon class="text-warning"
               *ngIf="rg.ID == null || rg.ID == 0"
               [icon]="faAdd">
      </fa-icon>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="acceptAndSend()">Accept and Send</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="cancel()">Close</button>
</div>
