<div class="user-profile-wrapper">
  <div class="user-profile-background"></div>
  <!-- NAVIGATION -->
  <div class="row">
    <div class="col-3">
      <div class="profile-menu-wrapper">
        <ng-container *ngIf="userProfile">
          <div class="profile-picture-wrapper">
            <img *ngIf="picture" class="profile-picture" [src]="picture" alt="my-profile-picture">
            <img *ngIf="!picture"
                 class="profile-picture"
                 [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
                 alt="profile-picture">
          </div>
          <div class="row mt-2">
            <div class="col-8 offset-2 text-center">
              <app-manage-image [Container]="'userporfilepictures'"
                                [FilePrefix]="userProfile?.Id?.toString()"
                                [ButtonTitle]="'Change Profile Picture'"
                                [CurrentFileName]="userProfile.ProfilePicture"
                                (OnChange)="onProfilePictureChange($event)"
                                (OnDelete)="onProfilePictureDeleted()"></app-manage-image>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <div class="profile-header">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="harmony-blue-text fw-bold mb-0">
                      {{ userProfile.DisplayName }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <small class="user-job-title">
                      {{ userProfile.JobTitle }}
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <small class="company-name d-inline">
                      {{ userProfile.Company?.Name }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="align-items-center">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                  <button class="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-companies" type="button" role="tab" aria-controls="v-pills-companies" aria-selected="true">Linked Companies</button>
                  <button class="nav-link" id="v-pills-security-tab" data-bs-toggle="pill" data-bs-target="#v-pills-security" type="button" role="tab" aria-controls="v-pills-security" aria-selected="false">Privacy & Security</button>
<!--                  <button class="nav-link" id="v-pills-preferences-tab" data-bs-toggle="pill" data-bs-target="#v-pills-preferences" type="button" role="tab" aria-controls="v-pills-preferences" aria-selected="false">Preferences</button>-->
                  <button class="nav-link" id="v-pills-documents-tab" data-bs-toggle="pill" data-bs-target="#v-pills-documents" type="button" role="tab" aria-controls="v-pills-documents" aria-selected="false">My Documents</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!userProfile">
          <app-loader [Width]="50"></app-loader>
        </ng-container>
      </div>
    </div>
    <div class="col-9">
      <!-- CONTENT -->
      <div class="profile-content-wrapper">
        <div class="tab-content" id="v-pills-tabContent">
          <!-- PROFILE -->
          <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <ng-container *ngIf="user">
              <div class="row mt-3">
                <div class="col-12">
                  <p class="content-header mb-0">
                    Profile Information
                  </p>
                  <hr class="mb-2 mt-0">
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <b>Username (Email)</b>
                </div>
                <div class="col-8 my-1 ps-3">
                  {{ user.User?.Username }}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>Prefix</b>
                </div>
                <div class="col-8">
                  <select class="form-select d-inline form-select-sm"
                          name="prefix"
                          id="prefix"
                          [(ngModel)]="user.User.Prefix">
                    <option [value]="'Mr.'">Mr.</option>
                    <option [value]="'Ms.'">Ms.</option>
                    <option [value]="'Mx.'">Mx.</option>
                  </select>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>First name</b>
                </div>
                <div class="col-8">
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="user.User.FirstName">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>Last name</b>
                </div>
                <div class="col-8">
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="user.User.LastName">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>Job Title</b>
                </div>
                <div class="col-8">
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="user.User.JobTitle">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>Contact Number</b>
                </div>
                <div class="col-8">
                  <app-mobile-number [Number]="user.User.ContactNumber"
                                     [Max]="15"
                                     (OnChange)="setContactNumber($event)">
                  </app-mobile-number>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4">
                  <b>Default company</b>
                </div>
                <div class="col-8">
                  <select class="form-select form-select-sm pointer"
                          *ngIf="userCompanies?.length > 0"
                          [(ngModel)]="user.User.CompanyId"
                          (change)="setDefaultCompany()">
                    <option *ngFor="let company of userCompanies" [ngValue]="company.CompanyId">
                      {{ company.CompanyName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-4 offset-4">
                  <button type="button" class="btn btn-sm btn-primary harmony-purple-button w-100"
                          (click)="saveProfile()">
                    Save
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!user">
              <app-loader [Width]="50"></app-loader>
            </ng-container>
          </div>
          <!-- LINKED COMPANIES -->
          <div class="tab-pane fade" id="v-pills-companies" role="tabpanel" aria-labelledby="v-pills-companies-tab">
            <div class="row">
              <div class="col-6">
                <div class="row mt-3">
                  <div class="col-12">
                    <p class="content-header mb-0">Linked Companies</p>
                    <hr class="mb-2 mt-0">
                  </div>
                </div>
                <div class="row user-company-roles pointer"
                     *ngFor="let company of userCompanies"
                     (click)="selectUserCompany(company)">
                  <div class="col-12 col-compress align-self-center">
                    <div class="linked-company-name p-2 mb-1"  [ngClass]="{'active-lc': selectedCompany.CompanyId == company.CompanyId}">
                      <p class="mb-0">
                        {{ company.CompanyName }}
                        <small class="text-muted">
                          {{ company.CompanyId === user.User.CompanyId ? '(default)' : '' }}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row mt-3">
                  <div class="col-12">
                    <p class="content-header mb-0">My roles for <span class="fw-light">{{ selectedCompany?.CompanyName ?? '-' }}</span></p>
                    <hr class="mb-2 mt-0">
                  </div>
                </div>
                <div class="row" *ngFor="let userRole of activeRoles">
                  <div class="col-12">
                    <div class="lc-role-name p-2">
                      <p class="mb-0 fst-italic">
                        {{ userRole.RoleName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SECURITY -->
          <div class="tab-pane fade" id="v-pills-security" role="tabpanel" aria-labelledby="v-pills-security-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Privacy & Security</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 offset-4">
                <button class="btn btn-sm btn-outline-primary harmony-blue-outline-button w-100"
                        (click)="resetPassword()">
                  Reset Password
                </button>
              </div>
            </div>
          </div>
          <!-- PREFERENCES -->
          <div class="tab-pane fade" id="v-pills-preferences" role="tabpanel" aria-labelledby="v-pills-preferences-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Preferences</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
          </div>
          <!-- MY DOCUMENTS -->
          <div class="tab-pane fade" id="v-pills-documents" role="tabpanel" aria-labelledby="v-pills-documents-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">My Documents</p>
                <hr class="mb-2 mt-0">
                <div class="row mt-2 ps-0 pe-0">
                  <div class="col-12">
                    <app-document-list [DocumentUserId]="user.UserId"></app-document-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
