<div class="modal-header">
  <h4 class="modal-title">Retry Failed Rates</h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body">
  <app-loader *ngIf="loading"></app-loader>
  <div class="form-group">
    <div class="row mb-2">
      <div class="col-md-4">
        <label>Select Project</label>
      </div>
      <div class="col-md-8 text-end">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(projects$ | async)"
                [(ngModel)]="param.ProjectId">
          <option [ngValue]="null" selected>Select a project...</option>
          <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-4">
        <label>Process Amount</label>
      </div>
      <div class="col-md-8 text-end">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(projects$ | async)"
                [(ngModel)]="param.ProcessCount">
          <option [ngValue]="1">1</option>
          <option [ngValue]="10">10</option>
          <option [ngValue]="50">50</option>
          <option [ngValue]="100">100</option>
          <option [ngValue]="500">500</option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-4">
        <label>RateGroup ID</label>
      </div>
      <div class="col-md-8 text-end">
        <input type="text"
               numbersOnly="6.0"
               class="form-control form-control-sm"
               [(ngModel)]="param.RateGroupId">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <button type="button"
                [disabled]="loading || param.ProjectId == null || param.ProcessCount <= 0"
                class="btn btn-primary harmony-purple-button w-100"
                (click)="execute()">
          Run Retry
        </button>
      </div>
    </div>
    <div class="row" *ngIf="result">
      <div class="col-md-12">
        <span>{{ result }}</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary harmony-teal-button"
          [disabled]="loading"
          (click)="activeModal.close('Done')">
    Close
  </button>
</div>
