import { Component } from '@angular/core';

@Component({
  selector: 'app-services-client-services',
  templateUrl: './services-client-services.component.html',
  styleUrls: ['./services-client-services.component.scss']
})
export class ServicesClientServicesComponent {

}
