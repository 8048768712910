import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  windowWidth: string;
  opacityChange = 1;
  showSplash = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.opacityChange = 0;

      setTimeout(() => {
        this.showSplash = !this.showSplash;
      }, 300);
    }, 800);
  }
}
