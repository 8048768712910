import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import {HseService} from "../../../../services/hse.service";
import {AlertService} from "../../../../services/alert.service";
import {ICompanyChartReportDto} from "../../../../services/models/hse/hse.model";

@Component({
  selector: 'app-dashboard-card-company-chart',
  templateUrl: './dashboard-card-company-chart.component.html',
  styleUrls: ['./dashboard-card-company-chart.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardCompanyChartComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Component variables
  companyChartReportDto: ICompanyChartReportDto[];

  // General variables
  loading: boolean = false;

  constructor(public router: Router,
              private hseService: HseService,
              private alertService: AlertService,
              public authService: AdAuthService) { }

  ngOnInit() {
    this.getCompanyChartReport()
  }

  viewCompanyChart(ccId: number) {
    this.router.navigate(['hse/company-chart/' + ccId]).then(() => {});
  }

  getCompanyChartReport() {
    this.hseService.GetCompanyChartReportDashboard()
      .subscribe({
        next: (data) => {
          this.companyChartReportDto = Object.assign([], data);
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Failed to get the incident report');
          this.loading = false;
        }
      });
  }
}
