import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { LoadingPermissionsComponent } from './modules/authentication/loading-permissions/loading-permissions.component';
import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { UserProfileComponent } from './modules/admin/user-profile/user-profile.component';
import { AuthGuard } from './core/guards/auth-guard.guard';

const routes: Routes = [
  // Portal
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  // Admin
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  // Annual Review
  {
    path: 'review',
    loadChildren: () => import('./modules/annual-review/annual-review.module').then(m => m.AnnualReviewModule)
  },
  // HSE
  {
    path: 'hse',
    loadChildren: () => import('./modules/hse/hse.module').then(m => m.HseModule)
  },
  // Hub Admin
  {
    path: 'hub',
    loadChildren: () => import('./modules/hub/hub.module').then(m => m.HubModule)
  },
  // Network
  {
    path: 'network',
    loadChildren: () => import('./modules/network/network.module').then(m => m.NetworkModule)
  },
  // News
  {
    path: 'news',
    loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule)
  },
  // Pricing
  {
    path: 'pricing',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule)
  },
  // Apply Intranet Routes
  // Generic routes
  {
    path: 'loading-user',
    component: LoadingPermissionsComponent
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: false})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
