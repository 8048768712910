<div class="modal-header">
  <h4 class="modal-title">
    Editing value for ID: {{ValueListId}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.close(false)">

  </button>
</div>
<div class="modal-body" *ngIf="ValueListItem">
  <app-loader *ngIf="loading"></app-loader>
  <div class="row">
    <div class="col-3 align-self-center">
      <b>Current value</b>
    </div>
    <div class="col-9">
      {{ ValueListItem.Value }}
    </div>
  </div>
  <div class="row mt-3" *ngIf="createdBy">
    <div class="col-3 align-self-center">
      <b>Created by</b>
    </div>
    <div class="col-9">
      <small>
        {{ createdBy ? createdBy.DisplayName : '-' }} on {{ ValueListItem.CreatedOn ? (ValueListItem.CreatedOn | date:env.FormattingStandards.LongDateFormat) : '-' }}
      </small>
    </div>
  </div>
  <div class="row mt-1" *ngIf="modifiedBy">
    <div class="col-3 align-self-center">
      <b>Modified by</b>
    </div>
    <div class="col-9">
      <small>
        {{ modifiedBy ? modifiedBy.DisplayName : '-' }} on {{ ValueListItem.ModifiedOn ? (ValueListItem.ModifiedOn | date:env.FormattingStandards.LongDateFormat) : '-' }}
      </small>
    </div>
  </div>
  <hr>
  <div class="row mt-2">
    <div class="col-3 align-self-center">
      <b>New value</b>
    </div>
    <div class="col-9">
      <input type="text"
             class="form-control"
             [placeholder]="'Enter new value'"
             [autofocus]="true"
             [ngClass]="{'is-invalid': invalid && newValue?.length >= 0}"
             [(ngModel)]="newValue"
             [maxlength]="512">
    </div>
  </div>
  <div class="row mt-0">
    <div class="col-9 offset-3 mt-0">
      <small class="harmony-grey-text ms-0">{{newValue?.length ?? 0}}/512</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary harmony-purple-button"
          [disabled]="loading"
          (click)="updateValue()">
    Save
  </button>
  <button type="button"
          class="btn btn-primary harmony-teal-button"
          [disabled]="loading"
          (click)="close()">
    Close
  </button>
</div>
