<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row mt-1">
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Project</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(projects$ | async)"
                (change)="searchParam.RateRequestTypeId = null"
                [(ngModel)]="projectId">
          <option [ngValue]="null" selected>Select a project...</option>
          <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Request Type</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!projectId || !(rateRequestTypes$ | async)"
                [(ngModel)]="searchParam.RateRequestTypeId">
          <option [ngValue]="null" selected>Filter by request type...</option>
          <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                  [hidden]="rateRequestType.ProjectId != projectId"
                  [ngValue]="rateRequestType.Id">
            {{ rateRequestType.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Modality</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(modalityTypes$ | async)"
                [(ngModel)]="searchParam.ModalityTypeId">
          <option [ngValue]="null" selected>Filter by modality type...</option>
          <option *ngFor="let modalityType of (modalityTypes$ | async)" [ngValue]="modalityType.Id">{{ modalityType.Name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Source</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(rateSources$ | async)"
                [(ngModel)]="searchParam.RateSourceId">
          <option [ngValue]="null" selected>Filter by rate source...</option>
          <option *ngFor="let rateSource of (rateSources$ | async)" [ngValue]="rateSource.Id">{{ rateSource.Name }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Verdict</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(rateVerdicts$ | async)"
                [(ngModel)]="searchParam.RateVerdictId">
          <option [ngValue]="null" selected>Filter by rate verdict...</option>
          <option *ngFor="let rateVerdict of (rateVerdicts$ | async)" [ngValue]="rateVerdict.Id">{{ rateVerdict.Name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-2">
      <div class="col-4 overflow-hidden">
        <b>Is Origin</b>
      </div>
      <div class="col-8">
        <div class="form-check mb-0 form-switch form-check-inline">
          <input class="form-check-input me-3"
                 type="checkbox"
                 role="switch"
                 [id]="'is-origin'"
                 [checked]="searchParam.IsOrigin == true"
                 (change)="setIsOrigin($event)">
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Is Destination</b>
      </div>
      <div class="col-8">
        <div class="form-check mb-0 form-switch form-check-inline">
          <input class="form-check-input me-3"
                 type="checkbox"
                 role="switch"
                 [id]="'is-destination'"
                 [checked]="searchParam.IsDestination == true"
                 (change)="setIsDestination($event)">
        </div>
      </div>
    </div>
  </div>
</div>
<hr class="mt-2 mb-1">
<div class="row">
  <div class="col-4 offset-6 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-2"
            [title]="'Reset search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2 text-end ps-1">
    <button class="btn btn-sm btn-primary harmony-purple-button w-100"
            (click)="updateSearch()">
      Search
    </button>
  </div>
</div>
<hr class="mt-1 mb-0">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'RRTemplateManagement'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-2 col-compress header-title border-split-left"
       (appOrder)="orderSearch($event)"
       data-column="RateRequestType.Name"
       data-order="asc">
    Request Type
    <fa-icon [hidden]="orderParam.OrderBy != 'RateRequestType.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title"
       (appOrder)="orderSearch($event)"
       data-column="ModalityType.Name"
       data-order="asc">
    Modality
    <fa-icon [hidden]="orderParam.OrderBy != 'ModalityType.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Origin -->
  <div class="col-1 col-compress header-title text-center"
       (appOrder)="orderSearch($event)"
       data-column="IsOrigin"
       data-order="asc">
    Origin
    <fa-icon [hidden]="orderParam.OrderBy != 'IsOrigin'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Destination -->
  <div class="col-1 col-compress header-title text-center"
       (appOrder)="orderSearch($event)"
       data-column="IsDestination"
       data-order="asc">
    Destination
    <fa-icon [hidden]="orderParam.OrderBy != 'IsDestination'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Other -->
  <div class="col-1 col-compress header-title"
       (appOrder)="orderSearch($event)"
       data-column="RateSource.Name"
       data-order="asc">
    Source
    <fa-icon [hidden]="orderParam.OrderBy != 'RateSource.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title"
       (appOrder)="orderSearch($event)"
       data-column="RateVerdict.Name"
       data-order="asc">
    Verdict
    <fa-icon [hidden]="orderParam.OrderBy != 'RateVerdict.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title">
    Services
  </div>
  <div class="col-2 col-compress header-title"
       (appOrder)="orderSearch($event)"
       data-column="DateCreated"
       data-order="asc">
    Created
    <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-end col-compress header-title border-split-right">

  </div>
</div>
<!---------------------->
<!------- Results ------->
<!---------------------->
<ng-container *ngIf="!loading">
  <div class="row result-row" style="margin-bottom: -0.25rem;" *ngFor="let template of paginationData.DataSet; let i = index">
    <div class="col-2 col-compress overflow-hidden">
      <b>{{ template.RateRequestTypeName }}</b>
      <br>
      <small class="text-muted position-relative" style="top:-5px;">{{ template.Description ?? '-' }}</small>
    </div>
    <div class="col-1 col-compress overflow-hidden">
      {{ template.ModalityTypeName ?? '-' }}
    </div>
    <!-- Origin -->
    <div class="col-1 col-compress overflow-hidden text-center">
      <fa-icon [icon]="faCheck" [size]="'lg'" class="harmony-green-text m-1" *ngIf="template.IsOrigin"></fa-icon>
      <fa-icon [icon]="faFalse" [size]="'lg'" class="harmony-grey-text m-1" *ngIf="!template.IsOrigin"></fa-icon>
    </div>
    <!-- Destination -->
    <div class="col-1 col-compress overflow-hidden text-center">
      <fa-icon [icon]="faCheck" [size]="'lg'" class="harmony-green-text m-1" *ngIf="template.IsDestination"></fa-icon>
      <fa-icon [icon]="faFalse" [size]="'lg'" class="harmony-grey-text m-1" *ngIf="!template.IsDestination"></fa-icon>
    </div>
    <!-- Other -->
    <div class="col-1 col-compress overflow-hidden">
      {{ template.RateSourceName }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ template.RateVerdictName }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      {{ template.ServiceCount }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ template.DateCreated | date:environment.FormattingStandards.ShortDateFormat }}
    </div>
    <div class="col-1 col-compress text-end pe-1 pt-1">
      <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
              [title]="'Edit Request Template: ' + template.Id"
              (click)="manageTemplate(template)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm btn-outline-danger ms-1"
              [title]="'Delete Template: ' + template.Id"
              (click)="deleteTemplate(template)">
        <fa-icon [icon]="faDelete" size="sm"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="!paginationData && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>Select a project to begin</h4>
  </div>
</div>
<div class="row mt-4" *ngIf="paginationData && paginationData.DataSet.length === 0 && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No templates found, change filters and try again...</h4>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
<br>
<br>
