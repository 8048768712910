<div class="article-wrapper">
  <!----------------------->
  <!--- Article Found ----->
  <!----------------------->
  <ng-container *ngIf="article && !loading">
    <div class="row mb-5 mt-5">
      <div class="col-12 d-flex justify-content-center">
        <div class="article-image-wrapper">
          <div class="row" *ngIf="article.ImageReference; else noimage">
            <div class="col-12 text-center">
              <img class="article-image"
                   [src]="imageUrl"
                   [alt]="article.Title">
            </div>
          </div>
          <div class="row mt-5">
            <ng-container *ngIf="attachments?.length > 0">
              <div class="row">
                <div class="col-6 offset-6 text-end">
                  <h5 class="harmony-blue-text">
                    Additional files
                  </h5>
                  <hr class="my-1">
                </div>
              </div>
              <div *ngFor="let a of attachments; let i = index" class="row mb-1">
                <div class="col-11 text-end align-self-center overflow-hidden">
                  {{ a.Name }}
                </div>
                <div class="col-1">
                  <button (click)="downloadAttachment(a.Reference)"
                          class="btn btn-sm btn-primary harmony-teal-outline-button border-0 float-end"
                          type="button" [title]="'Download Attachment'">
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="article-content-wrapper">
          <div class="row text-start">
            <div class="col-12 px-5">
              <h3 class="harmony-teal-text fw-bold mb-0">
                {{ article.Title }}
              </h3>
            </div>
            <div class="col-12 px-5">
              <p class="article-publish-date">
                {{ article.PublishDate | date: env.FormattingStandards.ShortDateFormat }} -
                <small class="harmony-coral-link">
                  <a (click)="viewContactCard(article.AuthorUserId)">{{ article.Author ?? '' }}</a>
                </small>
              </p>
              <hr class="harmony-grey-text">
            </div>
          </div>
          <div class="article-body-wrapper">
            <div class="row pt-3">
              <div [innerHTML]="article.HtmlContent | noSanitize"
                   #converted
                   [ngClass]="{'d-none': article.SanitizeContent}">
              </div>
              <div class="col-12" [innerHTML]="sanitizeArticleContent(converted.innerText)"
                   *ngIf="article.SanitizeContent">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!----------------------->
  <!-- Article NOT Found -->
  <!----------------------->
  <ng-container *ngIf="!article && !loading">
    <div class="row mb-5 pb-5 mt-5 pt-5">
      <div class="col-12 col-lg-6 offset-lg-3 text-center">
        <h5 class="harmony-blue-text fw-bold">
          Article can not be found
        </h5>
        <div class="row mt-4">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faNews" size="2x"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noimage>
  <div class="row">
    <div class="col-12 text-center">
      <img class="article-image"
           [src]="placeholderImage"
           [alt]="article.Title">
    </div>
  </div>
</ng-template>
