import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAnswerDto } from '../../../../services/models/annual-review/annual-review-answer.model';

@Component({
  selector: 'app-view-answer-modal',
  templateUrl: './view-answer-modal.component.html',
  styleUrls: ['./view-answer-modal.component.scss']
})
export class ViewAnswerModalComponent implements OnInit {

  public answer: IAnswerDto;
  public isNew: false;
  public answerForm: UntypedFormGroup;
  public canEdit: boolean;

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (this.isNew) {
      this.createForm();
    } else {
      this.fillForm();
    }
  }

  createForm() {
    this.answerForm = this.formBuilder.group({
      answer: [this.answer.Value]
    });
  }

  fillForm() {
    this.createForm();
    this.answerForm.controls.answer.setValue(this.answer.Value);
  }

  update() {
    this.activeModal.close(this.answerForm.controls.answer.value);
  }

  close() {
    this.activeModal.dismiss();
  }
}
