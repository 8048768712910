import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { IFailedRateRetryParam } from '../../../../services/models/pricing/failed-rate.model';
import { PricingService } from '../../../../services/pricing.service';

@Component({
  selector: 'app-retry-failed-rates-modal',
  templateUrl: './retry-failed-rates-modal.component.html',
  styleUrls: ['./retry-failed-rates-modal.component.scss']
})
export class RetryFailedRatesModalComponent implements OnInit, OnDestroy {
  // Component variables
  param: IFailedRateRetryParam;

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();

  // General variables
  loading: boolean = false;
  success: boolean = true;
  result: any;

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private pricingService: PricingService,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.param = {
      ProjectId: null,
      ProcessCount: null,
      RateGroupId: null
    } as IFailedRateRetryParam;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  execute() {
    this.loading = true;
    if (this.param.ProjectId && this.param.ProcessCount && this.param.ProcessCount > 0) {
      this.api.post('FailedRates/RetryFailedRates', this.param).pipe(
        takeUntil(this.unsubscribe),
      ).subscribe(() => {
        this.success = true;
        this.result = 'Successfully processed.';
        this.loading = false;
      }, () => {
        this.success = true;
        this.loading = false;
        this.result = 'Something went wrong. Please try again.';
      });
    }
  }
}
