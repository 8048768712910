import { Component } from '@angular/core';

@Component({
  selector: 'app-services-compliance',
  templateUrl: './services-compliance.component.html',
  styleUrls: ['./services-compliance.component.scss']
})
export class ServicesComplianceComponent {

}
