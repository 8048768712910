import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-step-quote-accept',
  templateUrl: './quote-accept.component.html',
  styleUrls: ['./quote-accept.component.scss']
})
export class QuoteAcceptComponent implements OnInit {
  // Component variables
  loading: boolean = false;
  termsAccepted: boolean = false;
  quoteAccepted: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  disableSubmit() {
    return (!this.termsAccepted || !this.quoteAccepted) || this.loading;
  }

  close() {
    this.activeModal.dismiss();
  }

  decline() {
    this.activeModal.close(false);
  }

  accept() {
    this.activeModal.close(true);
  }
}
