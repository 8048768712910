import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  faArrowDown,
  faArrowRight,
  faEdit,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { TenderService } from '../../../../services/tender.service';
import {
  ISingleLaneTenderFreight,
  ISingleLaneTenderLocation,
  ISingleLaneTenderModality,
  ITenderCurrency,
  ITenderLocationRate,
  ITenderLocationRateGroup,
  ITenderSingleLaneParam,
  ITenderSingleLaneResult
} from '../../../../services/models/pricing/tender.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';
import { ICityFunctionDto } from '../../../../services/models/pricing/location.model';
import {
  IModalityType,
  ISelectRateGroup,
  ISelectRateGroupParam,
  IUnitType,
  RateTypes
} from '../../../../services/models/pricing/rates.model';
import { SingleLaneQuoteComponent } from '../single-lane-quote/single-lane-quote.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rate-calculator',
  templateUrl: './rate-calculator.component.html',
  styleUrls: ['./rate-calculator.component.scss']
})
export class RateCalculatorComponent implements OnInit, AfterViewChecked {
  @Input()
  set tenderID(id: number) {
    if (id && id > 0) {
      this.LoadSingleLaneTender(id);
    }
  }

  // Icons
  faBin = faTrashAlt;
  faAdd = faPlus;
  faRightArrow = faArrowRight;
  faDownArrow = faArrowDown;
  faEditIcon = faEdit;
  faInfo = faCircleQuestion;
  faCircleQuestion = faCircleQuestion;

  // Component variables
  originPortsLoading: boolean = false;
  destinationPortsLoading: boolean = false;
  newMode: ISingleLaneTenderModality;
  selectedModality: IModalityType = null;
  selectedUnitType: IUnitType = null;
  unitTypes: IUnitType[] = [];
  today: any = null;

  // Lookup data
  Currencies$ = this.pricingService.Currencies$.asObservable();
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();

  // General Variables
  rateTypes = RateTypes;
  loadingParam: boolean = false;
  loadingResult: boolean = false;
  showValidation: boolean = false;

  formatter = (result: any) => result.Name;

  constructor(public tenderService: TenderService,
              public pricingService: PricingService,
              private alertService: AlertService,
              private cdRef: ChangeDetectorRef,
              private modalService: NgbModal) { }

  ngOnInit() {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    } as any;
    this.resetMode();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public LoadSingleLaneTender(tenderId: number) {
    this.loadingParam = true;
    this.loadingResult = true;

    this.tenderService.LoadSingleLaneTenderParam(tenderId)
      .subscribe({
        next: (data: ITenderSingleLaneParam) => {
          this.tenderService.SingleLaneTenderParam = Object.assign({}, data);
          this.orderModalities();
          this.tenderService.LoadTenderCurrency(data.DefaultCurrencyId);
          this.loadingParam = false;
          // Get result, if it exists
          this.tenderService.LoadSingleLaneTender(tenderId).subscribe({
            next: (data: ITenderSingleLaneResult) => {
              if (data) {
                this.tenderService.SingleLaneTenderResult = Object.assign({}, data);
              }
              this.loadingResult = false;
            },
            error: () => {
              this.loadingResult = false;
            }
          });
        },
        error: () => {
          this.loadingParam = false;
        }
      }
    )
  }

  // Locations //

  setOriginCity(cityId: number) {
    this.tenderService.SingleLaneTenderParam.OriginCityId = cityId;
    this.getOriginPortsByCityID(cityId, true); // Set Airport
    this.getOriginPortsByCityID(cityId, false); // Set Seaport
  }

  getOriginPortsByCityID(cityId: number, isAirport: boolean) {
    this.originPortsLoading = true;

    this.tenderService.GetPortByCity(cityId, isAirport).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          // Apply port ID
          if (isAirport == false) {
            this.tenderService.SingleLaneTenderParam.OriginSeaPortId = data[0].ID;
          } else {
            this.tenderService.SingleLaneTenderParam.OriginAirPortId = data[0].ID;
          }
        }
        this.originPortsLoading = false;
      },
      error: () => {
        this.originPortsLoading = false;
        this.alertService.warn('Unable to auto-retrieve ports for ORIGIN location, please search manually for the correct port.');
      }
    });
  }

  setDestinationCity(cityId: number) {
    this.tenderService.SingleLaneTenderParam.DestinationCityId = cityId;
    this.getDestinationPortsByCityID(cityId, true); // Set Airport
    this.getDestinationPortsByCityID(cityId, false); // Set Seaport
  }

  getDestinationPortsByCityID(cityId: number, isAirport: boolean) {
    this.destinationPortsLoading = true;

    this.tenderService.GetPortByCity(cityId, isAirport).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          // Apply port ID
          if (isAirport == false) {
            this.tenderService.SingleLaneTenderParam.DestinationSeaPortId = data[0].ID;
          } else {
            this.tenderService.SingleLaneTenderParam.DestinationAirPortId = data[0].ID;
          }
        }
        this.destinationPortsLoading = false;
      },
      error: () => {
        this.destinationPortsLoading = false;
        this.alertService.warn('Unable to auto-retrieve ports for DESTINATION location, please search manually for the correct port.');
      }
    });
  }

  selectAirPort(isOrigin: boolean, cityFunction: ICityFunctionDto) {
    if (isOrigin) {
      this.tenderService.SingleLaneTenderParam.OriginAirPortId = cityFunction?.Id;
    } else {
      this.tenderService.SingleLaneTenderParam.DestinationAirPortId = cityFunction?.Id;
    }
  }

  selectSeaPort(isOrigin: boolean, cityFunction: ICityFunctionDto) {
    if (isOrigin) {
      this.tenderService.SingleLaneTenderParam.OriginSeaPortId = cityFunction?.Id;
    } else {
      this.tenderService.SingleLaneTenderParam.DestinationSeaPortId = cityFunction?.Id;
    }
  }

  compareCurrencyFn(c1: ITenderCurrency, c2: ITenderCurrency): boolean {
    return c1 && c2 ? c1.CurrencyId === c2.CurrencyId : c1 === c2;
  }

  onCurrencySelect() {
    if (this.tenderService.SelectedCurrency && this.tenderService.SelectedCurrency.CurrencyId) {
      this.tenderService.SingleLaneTenderParam.DefaultCurrencyId = this.tenderService.SelectedCurrency.CurrencyId;
    }
  }

  // Modalities //

  resetMode() {
    this.newMode = {
      Id: null,
      ModalityTypeId: null,
      ModalityTypeName: null,
      Quantity: null,
      UnitTypeId: null,
      UnitTypeName: null
    } as ISingleLaneTenderModality;

    this.selectedModality = null;
    this.selectedUnitType = null;
  }

  setMode() {
    this.newMode.ModalityTypeId = this.selectedModality.Id;
    this.newMode.ModalityTypeName = this.selectedModality.Name;
    this.newMode.UnitTypeId = null;
    this.newMode.UnitTypeName = null;
    // Set UnitType options
    const unitTypeFilter = this.pricingService.ModalityUnitTypes$.getValue().filter((m) => m.ModalityTypeID === this.newMode.ModalityTypeId).map(s => s.UnitTypeID_1);
    if (unitTypeFilter && unitTypeFilter.length > 0) {
      this.unitTypes = this.pricingService.UnitTypes$.getValue().filter(x => unitTypeFilter.includes(x.Id)).sort((a,b) => a.Name < b.Name ? -1 : 1);
    } else {
      this.unitTypes = [];
    }
  }

  setQuantityUnitType() {
    this.newMode.UnitTypeId = this.selectedUnitType.Id;
    this.newMode.UnitTypeName = this.selectedUnitType.Abbreviation + ': ' + this.selectedUnitType.Name;
  }

  canAddMode() {
    return !(this.newMode &&
      this.newMode.ModalityTypeId !== null &&
      this.newMode.UnitTypeId !== null &&
      this.newMode.Quantity !== null);
  }

  addMode() {
    // Check if TenderModalities variable has been initialised, otherwise init the variable
    if (!this.tenderService.SingleLaneTenderParam.TenderModalities) {
      this.tenderService.SingleLaneTenderParam.TenderModalities = [];
    }
    // Add the record to the official list
    this.tenderService.SingleLaneTenderParam.TenderModalities.push({...this.newMode});
    // Reset quantity
    this.newMode.Quantity = null;
    this.orderModalities();
  }

  removeMode(index: number) {
    this.tenderService.SingleLaneTenderParam.TenderModalities.splice(index, 1);
    this.orderModalities();
  }

  orderModalities() {
    if (this.tenderService.SingleLaneTenderParam.TenderModalities.length > 0) {
      this.tenderService.SingleLaneTenderParam.TenderModalities = this.tenderService.SingleLaneTenderParam.TenderModalities
        .sort((a,b) => a.Quantity < b.Quantity ? -1 : 1)
        .sort((a,b) => a.ModalityTypeName < b.ModalityTypeName ? -1 : 1);
    }
  }

  // Rates & Results //
  getRate() {
    if (this.tenderService.CanRequestSingleLaneRate()) {
      this.showValidation = false;
      if (this.tenderService.SingleLaneTenderParam.Id == null) {
        this.tenderService.SaveSingleLaneTender(this.tenderService.SingleLaneTenderParam)
          .subscribe({
            next: (data: ITenderSingleLaneResult) => {
              if (data.Id) {
                this.tenderService.LoadSingleLaneTenderParam(data.Id)
                  .subscribe({
                    next: (data: ITenderSingleLaneParam) => {
                      this.tenderService.SingleLaneTenderParam = Object.assign({}, data);
                      this.showQuoteModal();
                    }});
              } else {
                this.alertService.warn('An error has occurred while trying to save the data.');
              }
            },
            error: () => {
              this.loadingResult = false;
              this.alertService.warn('An error has occurred while trying to save the data.');
            }
          });
      } else {
        this.showQuoteModal();
      }
    } else {
      this.showValidation = true;
    }
  }

  showQuoteModal() {
    // Open modal to accept quote
    const modalRef = this.modalService.open(SingleLaneQuoteComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.LoadQuote(this.tenderService.SingleLaneTenderParam.Id, this.tenderService.SingleLaneTenderParam.DefaultCurrencyId);
    // On modal close, read the result and apply logic
    modalRef.result.then((result: any) => {
      if (result.IsAccepted === true) {
        this.loadingResult = true;
        // Update Tender status and step
        this.tenderService.SingleLaneTenderParam.QuoteAcceptedDate = new Date();
        this.tenderService.SingleLaneTenderParam.QuoteAmount = result.QuoteAmount;
        this.tenderService.SingleLaneTenderParam.TenderStatusId = 2; // Quote accepted status
        this.tenderService.SingleLaneTenderParam.TenderStepId = 7; // Export step. Final step.
        this.tenderService.SaveSingleLaneTender(this.tenderService.SingleLaneTenderParam)
          .subscribe({
            next: (data: ITenderSingleLaneResult) => {
              this.tenderService.ProcessSingleLaneTender(data.Id)
                .subscribe({
                  next: (result: ITenderSingleLaneResult) => {
                    this.tenderService.SingleLaneTenderResult = Object.assign({}, result);
                    this.loadingResult = false;
                  },
                  error: () => {
                    this.loadingResult = false;
                    this.alertService.warn('An error has occurred while trying to gather rates.');
                  }
                });
            },
            error: () => {
              this.loadingResult = false;
              this.alertService.warn('An error has occurred while trying to save the data.');
            }
          });
      } else {
        this.alertService.info('Tender has been declined. Rates cannot be provided if the quote has not been accepted.');
      }
    }, () => {
      this.alertService.warn('Rates cannot be provided if the quote have not been accepted.');
    });
  }

  hasMode(modalityTypeId: number) {
    return (this.tenderService.SingleLaneTenderParam.TenderModalities.find(x => x.ModalityTypeId == modalityTypeId) != null);
  }

  getLocationRateGroup(rateGroups: ITenderLocationRateGroup[], modalityTypeId: number) {
    return rateGroups?.find(x => x.ModalityTypeId == modalityTypeId);
  }

  getFreight(freights: ISingleLaneTenderFreight[], modalityTypeId: number) {
    return freights?.find(x => x.ModalityTypeId == modalityTypeId);
  }

  getTotalRate(tenderModality: ISingleLaneTenderModality) {
    const tender = this.tenderService.SingleLaneTenderResult;
    // Get Origin rate
    const originRateGroup = tender.OriginLocation?.TenderLocationRateGroups.find(x => x.ModalityTypeId == tenderModality.ModalityTypeId);
    const originRate = originRateGroup?.TenderLocationRates.find(x => x.TenderModalityId == tenderModality.Id);
    // Get Freight rate
    const freightLane = tender.TenderFreights.find(x => x.ModalityTypeId == tenderModality.ModalityTypeId);
    const freightRate = freightLane?.TenderFreightRateGroups.find(x => x.TenderModalityId == tenderModality.Id);
    // Get Destination rate
    const destinationRateGroup = tender.DestinationLocation?.TenderLocationRateGroups.find(x => x.ModalityTypeId == tenderModality.ModalityTypeId);
    const destinationRate = destinationRateGroup?.TenderLocationRates.find(x => x.TenderModalityId == tenderModality.Id);
    // Calculate total
    return (originRate ? originRate.TotalRate : 0) + (freightRate ? freightRate.TotalRate : 0) + (destinationRate ? destinationRate.TotalRate : 0);
  }

  // RateGroup methods //
  getRateGroupOptions(locationRateGroup: ITenderLocationRateGroup, location: ISingleLaneTenderLocation, portID: number) {
    locationRateGroup.IsLoadingOptions = true;

    const rateTypeID = this.getRateType(locationRateGroup.ModalityType.Name, location.IsOrigin);

    const param = {
      LocationID: location.CityId,
      LocationTypeID: 5, // City
      PortID: portID,
      RateTypeID: rateTypeID,
      TenderID: this.tenderService.SingleLaneTenderResult.Id,
      RedSkyAgentID: null,
      Date: new Date(),
      Radius_1: 50, // Default
      Radius_2: 250, // Default
      MaximumDaysInvalid: 30, // Default
      MaximumRateGroup: 10 // Default
    } as ISelectRateGroupParam;

    this.tenderService.GetRateGroupOptions(param).subscribe({
      next: (data) => {
        if (data && data.length) {
          locationRateGroup.RateGroupOptions = Object.assign([] as ISelectRateGroup[], data);
        }
        locationRateGroup.IsLoadingOptions = false;
      },
      error: () => {
        locationRateGroup.IsLoadingOptions = false;
        return [];
      }
    });
  }

  getRateType(mode: string, IsOrigin: boolean) {
    if (IsOrigin) {
      switch (mode.toLowerCase()) {
        case 'air':
          return this.rateTypes.AIROrigin;
        case 'fcl':
          return this.rateTypes.FCLOrigin;
        case 'lcl':
          return this.rateTypes.LCLOrigin;
        case 'road':
          return this.rateTypes.ROADOrigin;
        default: return null;
      }
    } else {
      switch (mode.toLowerCase()) {
        case 'air':
          return this.rateTypes.AIRDestination;
        case 'fcl':
          return this.rateTypes.FCLDestination;
        case 'lcl':
          return this.rateTypes.LCLDestination;
        case 'road':
          return this.rateTypes.ROADDestination;
        default: return null;
      }
    }
  }

  updateTenderLocationRateGroupRates(locationRateGroup: ITenderLocationRateGroup) {
    this.tenderService.UpdateTenderLocationRateGroupRates(locationRateGroup).subscribe({
      next: (data: ITenderLocationRate[]) => {
        if (data) {
          locationRateGroup.TenderLocationRates = Object.assign([] as ITenderLocationRate[], data);
        }
      },
      error: () => {
        this.alertService.warn('An error occurred while attempting to update the rates for the selected Provider.');
      }
    });
  }
}
