<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    Clone Rate Group: {{ cloneParam.RateGroupId }}
  </h4>
</div>
<div class="modal-body">
  <div class="row mt-2">
    <div class="col-3">
      <b>
        Valid From
      </b>
    </div>
    <div class="col-9">
      <app-date-picker [identifier]="'validFrom'"
                       (onChange)="cloneParam.ValidFrom = $event"
                       [value]="cloneParam.ValidFrom"
                       [minDate]="today"
                       [showError]="cloneParam.ValidFrom == null && invalid"
                       [placeholder]="'Select a from date'"></app-date-picker>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>
        Valid To
      </b>
    </div>
    <div class="col-9">
      <app-date-picker [identifier]="'validTo'"
                       (onChange)="cloneParam.ValidTo = $event"
                       [value]="cloneParam.ValidTo"
                       [minDate]="today"
                       [placeholder]="'Select a to date'"></app-date-picker>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>
        Rate Deviation
      </b>
    </div>
    <div class="col-9">
      <div class="input-group input-group-sm">
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'is-invalid': invalid && cloneParam.RateDeviation < 0 }"
          [(ngModel)]="rateDeviation"
          numbersOnly="2.2"
          placeholder="0" />
        <span class="input-group-text" id="percentage-add-on">%</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="loading" (click)="clone()">Clone</button>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="cancel()">Cancel</button>
</div>
