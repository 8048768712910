import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICityDto } from '../../../../../services/models/pricing/location.model';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../../services/api.service';
import { AlertService } from '../../../../../services/alert.service';

@Component({
  selector: 'app-city-edit-modal',
  templateUrl: './city-edit-modal.component.html',
  styleUrls: ['./city-edit-modal.component.scss']
})
export class CityEditModalComponent implements OnInit, OnDestroy {
  // Icons

  // Component variables
  public CityId: number;
  city: ICityDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit(): void {
    if (this.CityId != null && this.CityId > 0) {
      this.loadCity();
    } else {
      this.city = {
        Id: 0,
        Active: true,
        Name: null,
        LOCode: null,
        StateId: null,
        CountryId: null,
        Coordinates: null,
        InfoName: null
      } as ICityDto;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadCity() {
    this.loading = true;
    this.api.get('City/Single/' + this.CityId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ICityDto) => {
        if (data) {
          this.city = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  save() {
    if (this.isValid()) {
      this.loading = true;
      this.api.post('City/Update', this.city).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: ICityDto) => {
          if (data) {
            this.alertService.success('City record successfully updated!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    } else {
      this.alertService.warn('Unable to save. Please make sure all required fields are correctly populated.');
    }
  }

  isValid() {
    const c = this.city;

    return (c.Name && c.Name.length > 0) &&
      (c.InfoName && c.InfoName.length > 0) &&
      (c.NameWoDiacritics && c.NameWoDiacritics.length > 0) &&
      (c.CountryId && c.CountryId > 0);
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
