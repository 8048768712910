import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICountryDto } from '../../../../../services/models/pricing/location.model';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../services/alert.service';
import { ApiService } from '../../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-country-edit-modal',
  templateUrl: './country-edit-modal.component.html',
  styleUrls: ['./country-edit-modal.component.scss']
})
export class CountryEditModalComponent implements OnInit, OnDestroy {
  // Icons

  // Component variables
  public CountryId: number;
  country: ICountryDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit(): void {
    if (this.CountryId != null && this.CountryId > 0) {
      this.loadCountry();
    } else {
      this.country = {
        Id: 0,
        DateModified: null,
        Name: null,
        Iso2: null,
        Iso3: null,
        IsoNumber: null,
        IsEu: false,
        ContinentCode: null
      } as ICountryDto;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadCountry() {
    this.loading = true;
    this.api.get('Country/Single/' + this.CountryId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ICountryDto) => {
        if (data) {
          this.country = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  save() {
    if (this.isValid()) {
      this.loading = true;
      this.api.post('Country/Update', this.country).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: ICountryDto) => {
          if (data) {
            this.alertService.success('Country record successfully updated!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    } else {
      this.alertService.warn('Unable to save. Please make sure all required fields are correctly populated.');
    }
  }

  isValid() {
    const c = this.country;

    return (c.Name && c.Name.length > 0) &&
      (c.Iso2 && c.Iso2.length > 0) &&
      (c.Iso3 && c.Iso3.length > 0) &&
      (c.ContinentCode && c.ContinentCode.length > 0);
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
