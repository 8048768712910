import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompanyDetail } from '../../../../services/models/member.model';
import { CompanyService } from '../../../../services/company.service';

@Component({
  selector: 'app-pulse-chart-information',
  templateUrl: './pulse-chart-information.component.html',
  styleUrls: ['./pulse-chart-information.component.scss']
})
export class PulseChartInformationComponent {

  constructor(private activeModal: NgbActiveModal,
              private companyService: CompanyService) {
  }

  companyId: number;
  companyName: string;
  hasAccess: boolean = false;
  updateCompanyDetails: ICompanyDetail[] = [];

  close() {
    this.activeModal.close();
  }

  update() {
    if (this.updateCompanyDetails?.length > 0) {
      this.companyService.UpsertCompanyDetailList(this.updateCompanyDetails)
        .subscribe({
          next: () => {
            this.close();
          }
        });
    } else {
      this.close();
    }
  }
}
