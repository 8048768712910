import { Component,  OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { IComponent, IRole, IRoleFeature } from '../../../../services/models/user.model';
import { takeUntil } from 'rxjs/operators';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss']
})
export class RoleModalComponent implements OnInit {
  // Icons
  faAdd = faPlusSquare;
  faRemove = faMinusSquare;

  // Component Variables
  public RoleId: number;
  public IsNew: boolean;
  Role: IRole;
  modules$ = this.authService.Modules$.asObservable();
  components$ = this.authService.Components$.asObservable();
  roles$ = this.authService.Roles$.asObservable();
  components: IComponent[] = [];
  invalid: boolean = false;
  moduleId: number = null;
  activeModuleId: number = null;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.components$.subscribe(data => {
      this.components = data;
    });
    if (this.IsNew) {
      this.Role = {
        Id: null,
        DateCreated: new Date(),
        DateModified: null,
        Active: true,
        Name: null,
        Description: null,
        RoleFeatures: []
      } as IRole;
    } else {
      this.loadRole();
    }
  }

  loadRole() {
    this.loading = true;
    this.api.get(`Role/Single/${this.RoleId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRole) => {
        if (data) {
          this.Role = Object.assign({}, data);
        }
        this.loading = false;
      }, error: (error) => {
        this.alertService.error('Error occurred retrieving module data: ' + error);
        this.loading = false;
      }
    });
  }

  activeRoleFeatures(): IRoleFeature[] {
    if (this.Role.RoleFeatures) {
      return this.Role.RoleFeatures.filter((r) => (this.activeModuleId == null || r.Component.ModuleId == this.activeModuleId) && r.Active == true);
    }
    return [];
  }

  availableRoleFeatures(): IComponent[] {
    if (this.components) {
      return this.components.filter((c) => (this.moduleId == null || c.ModuleId == this.moduleId) && !this.hasComponent(c.Id));
    }
    return [];
  }

  removeRoleFeature(feature: IRoleFeature) {
    if (feature) {
      feature.Active = false;
      const index = this.Role.RoleFeatures.indexOf(feature);
      // Check if record has been previously saved in the DB, otherwise remove from list completely to avoid inserting an inactive record
      if (feature.Id != null) {
        feature.Active = false;
      } else {
        this.Role.RoleFeatures.splice(index, 1);
      }
    }
  }

  addRoleFeature(component: IComponent) {
    const newRoleFeature = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RoleId: this.Role.Id,
      ComponentId: component.Id,
      Component: component
    } as IRoleFeature;

    this.Role.RoleFeatures.push(newRoleFeature);
  }

  identify(index, item) {
    return item;
  }

  hasComponent(componentId: number): boolean {
    return this.Role.RoleFeatures.some((r) => r.ComponentId === componentId && r.Active == true);
  }

  saveRole() {
    if (this.canSave()) {
      this.loading = true;

      this.api.post('Role/Update', this.Role).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IRole) => {
          if (data) {
            this.alertService.success('Role update successful!');
            this.activeModal.close(this.Role);
          }
          this.loading = false;
        }, error: (error) => {
          this.alertService.error('Error occurred attempting to update the Role: ' + error);
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  canSave() {
    return !!(this.Role && this.Role.Name && this.Role.Name.length > 3 && this.Role.Description && this.Role.Description.length > 10);
  }

  close() {
    this.activeModal.close(null);
  }
}
