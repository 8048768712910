import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-lookup-data',
  templateUrl: './hub-lookup-data.component.html',
  styleUrls: ['./hub-lookup-data.component.scss']
})
export class HubLookupDataComponent {

}
