import { Component } from '@angular/core';

@Component({
  selector: 'app-email-template-overview',
  templateUrl: './email-template-overview.component.html',
  styleUrls: ['./email-template-overview.component.scss']
})
export class EmailTemplateOverviewComponent {

}
