import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  faBoxOpen,
  faCheckCircle,
  faCircleXmark,
  faEdit, faSortUp,
  faSyncAlt, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { RequestTemplateEditModalComponent } from './request-template-edit-modal/request-template-edit-modal.component';
import { environment } from 'src/environments/environment';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import {
  IRateRequestTemplateOverviewDto,
  IRateRequestTemplateSearchDto
} from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { ApiService } from '../../../../services/api.service';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-request-template-management',
  templateUrl: './request-template-management.component.html',
  styleUrls: ['./request-template-management.component.scss']
})
export class RequestTemplateManagementComponent implements OnInit {
// Icons
  faEditIcon = faEdit;
  faEmpty = faBoxOpen;
  faRefresh = faSyncAlt;
  faCheck = faCheckCircle;
  faFalse = faCircleXmark;
  faDelete = faTrash;

  // Component variables
  paginationData: IPaginationData<IRateRequestTemplateOverviewDto>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: IRateRequestTemplateSearchDto;
  projectId: number = null;

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();
  rateSources$ = this.pricingService.RateSources$.asObservable();
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();
  rateVerdicts$ = this.pricingService.RateVerdicts$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(public pricingService: PricingService,
              public authService: AdAuthService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit(): void {
    this.resetSearch();
    this.searchTemplates();
  }

  // Search bar functions
  updateSearch() {
    // Reset data
    this.paginationData.DataSet = [];
    this.paginationData.TotalPages = 1;
    this.paginationData.CurrentPage = 1;
    this.page.pageNumber = this.paginationData.CurrentPage;
    this.page.pageSize = this.paginationData.PageSize;
    this.searchTemplates();
  }

  resetSearch() {
    this.projectId = null;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };

    // Set default ordering
    this.orderParam = {
      OrderBy: 'DateCreated',
      OrderDirection: 'desc'
    } as IOrderParam;

    // Reset search param object
    this.searchParam = {
      RateRequestTypeId: null,
      ModalityTypeId: null,
      IsOrigin: null,
      IsDestination: null,
      RateSourceId: null,
      RateVerdictId: null
    } as IRateRequestTemplateSearchDto;
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchTemplates();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchTemplates();
  }

  setIsOrigin(event: any) {
    if (event.target.checked == false) {
      this.searchParam.IsOrigin = null;
    } else {
      this.searchParam.IsOrigin = true;
    }
  }

  setIsDestination(event: any) {
    if (event.target.checked == false) {
      this.searchParam.IsDestination = null;
    } else {
      this.searchParam.IsDestination = true;
    }
  }

  searchTemplates() {
    this.loading = true;
    const o = this.orderParam;
    const p = this.page;
    this.api.post(`RateRequestTemplate/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  manageTemplate(template: IRateRequestTemplateOverviewDto) {
    // Open modal to Add/Edit a Rate Request Template
    const modalRef = this.modalService.open(RequestTemplateEditModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.TemplateId = template.Id;
    modalRef.componentInstance.LoadRequestTemplate();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      this.searchTemplates();
    }, () => {
    });
  }

  deleteTemplate(template: IRateRequestTemplateOverviewDto) {
    const message = 'Are you sure you want to delete this template? This action cannot be undone.';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.loading = true;

          this.api.get(`RateRequestTemplate/Delete/${template.Id}`).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
            next: (data) => {
              if (data) {
                this.alertService.success('Template successfully deleted!');
                this.updateSearch();
              }
              this.loading = false;
            },
            error: () => {
              this.alertService.success('An error occurred while trying to delete the template.');
              this.loading = false;
            }
          });
        }
      });
  }

  protected readonly faSortDesc = faSortUp;
}
