import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IActiveCompany, IUsersSearchDto } from '../../../services/models/member.model';
import {
  faBoxOpen,
  faCheck,
  faPlus,
  faSortDown,
  faSyncAlt,
  faTrash,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import * as jwt_decode from 'jwt-decode';
import { MemberUserManagementModalComponent } from './member-user-management-modal/member-user-management-modal.component';
import { environment } from '../../../../environments/environment';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { FileService } from '../../../services/file.service';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { IModule, IUser, IUserAdminDto } from '../../../services/models/user.model';
import { IAuthUser } from '../../../services/models/auth.model';
import {IOrderParam} from "../../../shared/directives/sort/order.directive";

@Component({
  selector: 'app-user-management',
  templateUrl: './member-user-management.component.html',
  styleUrls: ['./member-user-management.component.scss']
})
export class MemberUserManagementComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faUserEdit;
  faRefresh = faSyncAlt;
  faRemove = faTrash;
  faAdd = faPlus;
  faEmpty = faBoxOpen;
  faRegistered = faCheck;
  faSortAsc = faSortDown;

  // Company variables
  companies: IActiveCompany[] = [];
  companyName: string = '-';

  // User variables
  paginationData: IPaginationData<IUserAdminDto>;
  searchParam: IUsersSearchDto;
  page: IPage;
  selectedUser: IAuthUser = null;
  orderParam: IOrderParam;

  // Lookups variables
  modules: IModule[];

  // General Variables
  loading: boolean = false;
  env = environment;
  defaultImage = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.ImagePlaceholderUrl;
  token: string;

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private authService: AdAuthService,
              private alertService: AlertService,
              private fileService: FileService) {
  }

  ngOnInit() {
    this.initPagination()
    this.initOrder();

    // Set filter
    this.searchParam = {
      SearchText: '',
      CompanyId: null,
      Active: true,
      RoleId: null
    };

    if (this.authService.CurrentUser) {
      this.searchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
      this.companyName = this.authService.CurrentUser.User.Company.Name;
      this.getUsers();
    } else {
      this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
        this.searchParam.CompanyId = user.User.CompanyId;
        this.companyName = user.User.Company.Name;
        this.getUsers();
      });
    }
  }

  initOrder(){
    this.orderParam = {
      OrderBy: 'Username',
      OrderDirection: 'asc'
    } as IOrderParam;
  }
  initPagination() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false,
    };
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getUsers() {
    this.loading = true;

    if (this.searchParam.SearchText && this.searchParam.SearchText.length <= 0) {
      this.searchParam.SearchText = null;
    }

    this.api.get(`User/GetPaginated?pageNumber=${this.page?.pageNumber}&pageSize=${this.page?.pageSize}&searchText=${this.searchParam.SearchText}&active=${this.searchParam.Active}&companyId=${this.searchParam.CompanyId}&orderBy=${this.orderParam.OrderBy}&order=${this.orderParam.OrderDirection}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data) => {
          this.paginationData = {...data};
          this.getToken();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  identify(index, item) {
    return item;
  }

  onPageChange(page: IPage) {
    this.page = page;
    this.getUsers();
  }

  getToken() {
    this.fileService.GetSasToken(this.env.ContainerNames.UserProfilePictures)
      .subscribe({
        next: (data) => {
          this.token = `?${data.Token}`;
        }
      });
  }

  openUserEditModal(user: IUserAdminDto) {
    this.loading = true;
    this.selectedUser = null;

    // Get the selected users Information
    this.authService.GetUserPermissions(user.Username).subscribe({
      next: (data) => {
        if (data) {
          const token = data.Token;
          // Decode JWT token data section and set the current user
          const decoded: any = jwt_decode.default(token);
          this.selectedUser = {
            UserId: +decoded.UserID,
            User: JSON.parse(decoded.User),
            UserRoles: JSON.parse(decoded.UserRoles),
            Permissions: JSON.parse(decoded.Permissions),
            UserCompanies: JSON.parse(decoded.UserCompanies)
          } as IAuthUser;
          // Set AD Status
          this.selectedUser.User.AzureRegistered = user.AzureRegistered;
          // Open modal to edit user
          const modalRef = this.modalService.open(MemberUserManagementModalComponent, {size: 'lg', backdrop: 'static'});
          modalRef.componentInstance.AuthUser = this.selectedUser;
          modalRef.componentInstance.IsNewUser = false;
          // On modal close, read the result and apply logic
          modalRef.result.then((result: string) => {
            if (result) {
              this.alertService.success(result);
              this.getUsers();
            }
          }, () => {
          });
        } else {
          this.alertService.error('Permission data for ' + user.DisplayName + ' could not be loaded.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Permission data for ' + user.DisplayName + ' could not be loaded.');
        this.loading = false;
      }
    });
  }

  openNewUserModal() {
    // Open modal to add new user
    const modalRef = this.modalService.open(MemberUserManagementModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNewUser = true;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.alertService.success(result);
        this.getUsers();
      }
    });
  }

  toggleUserActive(param: IUserAdminDto) {
    const message: string = param.Active == false ? 'Re-activate user?' : 'Mark user as inactive?';

    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          const today = new Date().toJSON();
          const user = {
            Id: param.Id,
            DateCreated: param.DateCreated,
            DateModified: today,
            Active: !param.Active,
            Username: param.Username,
            IntranetUserId: param.IntranetUserId,
            FirstName: param.FirstName,
            LastName: param.LastName,
            Email: param.Email,
            DisplayName: param.DisplayName,
            UserTypeId: param.UserTypeId,
            UserType: null,
            LoginAttempt: 0,
            IsVerified: param.IsVerified,
            CompanyId: param.CompanyId,
            ProfilePicture: param.ProfilePicture,
            Company: null
          } as IUser;

          this.api.put('User/UpdateUser', user).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
            next: (data) => {
              if (data) {
                this.alertService.success(user.DisplayName + ' updated successfully');
                this.getUsers();
              }
            },
            error: () => {
              this.alertService.success('Failed to update info for ' + user.DisplayName);
            }
          });
        }
      });
  }

  orderSearch(event: IOrderParam) {
    this.orderParam = event;
    this.getUsers();
  }

  updateSearchText(event: any) {
    this.searchParam.SearchText = event.target.value
  }
}
