import { Component } from '@angular/core';
import { ICompanyOverview } from '../../../services/models/member.model';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { faCircleDot, faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent {
  // Icons
  protected readonly faView = faEye;
  protected readonly faMenuItem = faCircleDot;

  // Component Variables
  companyData: ICompanyOverview[];
  mapData: ICompanyOverview[];
  zoom: number = 2.5;
  centerLat: number = 15;
  centerLong: number = 0;
  searchText: string = null;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  env = environment;

  constructor(private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.loadMarkers();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadMarkers() {
    this.loading = true;

    this.apiService.get('Map/GetData').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyOverview) => {
      if (data) {
        this.companyData = Object.assign([], data);
        this.mapData = [...this.companyData];
      }
      this.loading = false;
    });
  }

  searchMembers() {
    this.zoom = 2.5;
    this.setCenter(15, 0);
    const s = this.searchText.toLowerCase();

    if (s && s.length > 0) {
      const result = this.companyData.filter(x => x.Name.toLowerCase().includes(s) ||
        x.Country.toLowerCase().includes(s) ||
        x.City.toLowerCase().includes(s));
      if (result && result.length > 0) {
        this.mapData = [...result];
      } else {
        this.mapData = [];
      }
    } else {
      this.resetMap();
      this.searchText = null;
    }
  }

  goToMemberLocation(member: ICompanyOverview) {
    this.setCenter(member.Latitude, member.Longitude);
    this.zoom = 6;
  }

  resetMap() {
    this.mapData = [...this.companyData];
  }

  getCountryCount() {
    const array = this.companyData;

    if (array && array.length > 0) {
      const unique = [...new Set(array.map(item => item.Country))];
      return unique.length;
    }

    return '-';
  }

  setCenter(lat: number, long: number) {
    this.centerLat = lat;
    this.centerLong = long;
  }
}
