import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-rate-mapping-search',
  templateUrl: './rate-mapping-search.component.html',
  styleUrls: ['./rate-mapping-search.component.scss']
})
export class RateMappingSearchComponent implements OnInit {
  // Input Variables
  @Input() TableName: string = '';
  @Input() SearchLabel: string = '';
  @Input() Identifier: string = '';
  @Input() SelectedID: number;

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();

  // Component Variables
  public selectedModel: any = null;
  searching: boolean = false;
  searchFailed: boolean = false;

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: any) => result.Name;

  constructor(private api: ApiService) { }

  ngOnInit() { }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => { this.searching = true; this.searchFailed = false; }),
      switchMap(term =>
        this.getSearch(term).pipe(
          tap(() => { this.searching = false; }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  }

  getSearch(searchText: string): Observable<any> {
    if (searchText && searchText.length > 2) {
      // Build URL for request
      const urlPath = 'FailedRates/Search/' + this.TableName + '/' + searchText;
      // Call FailedRates controller "Search" endpoint
      return this.api.get(urlPath).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  onSelect() {
    if (this.selectedModel && this.selectedModel.ID) {
      this.OnSelect.emit(this.selectedModel.ID);
    }
  }

  clear() {
    this.selectedModel = null;
    this.OnSelect.emit(null);
  }
}
