import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { SessionStorageVariables } from '../core/constants/session-storage-variables';
import { HeaderNames } from '../core/constants/header-names';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private storageService: StorageService) {
  }

  private static formatErrors(error: any) {
    return throwError(error.error);
  }

  createHeaders(headers: HttpHeaders) {
    headers = headers.append(environment.api_key_header, environment.api_key);

    let userID = this.storageService.getSessionItem(SessionStorageVariables.UserID);
    let companyID = this.storageService.getSessionItem(SessionStorageVariables.CompanyID);

    if (userID && companyID) {
      headers = headers.append(HeaderNames.CompanyId, companyID.toString());
      headers = headers.append(HeaderNames.UserId, userID.toString());
    }

    return headers;
  }

  get(path: string): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.get(
      `${environment.api_url}${path}`,
      {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }

  getFile(path: string): Observable<HttpResponse<Blob>> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.get<Blob>(
      `${environment.api_url}${path}`,
      {headers, observe: 'response', responseType: 'blob' as 'json'}
    ).pipe(catchError(ApiService.formatErrors));
  }

  postFile(path: string, body: object = {}): Observable<HttpResponse<Blob>> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.post<Blob>(
      `${environment.api_url}${path}`,
      body,
      {headers, observe: 'response', responseType: 'blob' as 'json'}
    ).pipe(catchError(ApiService.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.put(
      `${environment.api_url}${path}`,
      body,
      {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.post(
      `${environment.api_url}${path}`,
      body,
      {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }

  delete(path): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.createHeaders(headers);
    // Create and return request
    return this.http.delete(
      `${environment.api_url}${path}`,
      {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }
}
