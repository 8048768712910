import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryCodes } from './country-codes';

@Component({
  selector: 'app-mobile-number',
  templateUrl: './mobile-number.component.html',
  styleUrls: ['./mobile-number.component.scss']
})
export class MobileNumberComponent {
  @Input() Max: number = 10;
  @Input() Touched: boolean;
  @Input() Disabled: boolean;
  @Input() set Number(number: string) {
    this.number = number;
    this.splitNumberAndCountry();
  }

  @Output() OnChange: EventEmitter<string> = new EventEmitter<string>();

  defaultText = 'Country';
  dropdownText: string = this.defaultText;
  countryCode: string = null;
  number: string = '';
  maxLength: string = `${this.Max}.0`;
  countryCodes = CountryCodes;
  defaultClass = 'fi fi-nl';
  class = null; //this.defaultClass; // determines the flag that is shown in the dropdown
  originalNumber: string;

  splitNumberAndCountry() {
    if (this.number) {
      this.originalNumber = this.number;
      this.number = this.number.trimStart();
      this.tryGetCountryCode();
      this.splitNumber();
    } else {
      this.number = '';
    }
  }

  tryGetCountryCode() {
    // try to find the country code
    const code = this.number.substring(0, this.number.indexOf(' '));
    const country = CountryCodes.find(x => x.code == code);
    if (country) {
      this.setCountryCode(country.code, country.iso, false);
    }
  }

  splitNumber() {
    // set the number after the country code
    this.number = this.number.substring(this.number.indexOf(' ') + 1);
    this.formatNumber();
    // emit the change
    if (this.originalNumber != `${this.countryCode} ${this.number}`) {
      this.emitOutput();
    }
  }

  formatNumber() {
    this.number = this.number.replaceAll('.', '');
    this.number = this.number.replaceAll('+', '');
    this.number = this.number.replaceAll(' ', '');
    this.number = this.number.replaceAll('-', '');
    this.number = this.number.replaceAll('(', '');
    this.number = this.number.replaceAll(')', '');
  }

  setCountryCode(code: string, iso: string, emit: boolean) {
    this.dropdownText = code;
    this.class = `fi fi-${iso}`;
    this.countryCode = code;
    if (emit) {
      this.emitOutput();
    }
  }

  emitOutput() {
    // only emit if both are set or cleared
    if ((this.countryCode && this.number) ||
      (!this.countryCode && !this.number)) {
      this.OnChange.emit(`${this.countryCode} ${this.number}`);
    }
  }

  clear() {
    this.countryCode = '';
    this.dropdownText = this.defaultText;
    this.class = this.defaultClass;
    this.number = '';
    this.OnChange.emit(null);
  }
}
