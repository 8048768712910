import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateFn
} from '@angular/router';
import { Observable } from 'rxjs';
import { AdAuthService } from '../ad-auth-service/ad-auth.service';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const router: Router = inject(Router);
  const authService: AdAuthService = inject(AdAuthService);
  const code = route.data.ExpectedPermission;

  const isLoggedIn = authService.IsLoggedIn;

  if (isLoggedIn) {
    if (authService.IsAuthorized) {
      // Check current route's expected permission code
      if (code) {
        if (authService.CheckPermissionByCode(code)) {
          return true;
        } else {
          console.log('Access denied: ' + code);
          return router.navigateByUrl('/access-denied');
        }
      } else {
        // For routes that only require a successful login but no permissions
        // ect dashboard and user profile
        return true;
      }
    } else {
      authService.CurrentUser$.subscribe(() => {
        if (code) {
          if (authService.CheckPermissionByCode(code)) {
            return true;
          } else {
            console.log('Access denied: ' + code);
            return router.navigateByUrl('/access-denied');
          }
        } else {
          return true;
        }
      });
    }
  } else {
    authService.Login(state);
  }
}
