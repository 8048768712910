<div class="modal-header">
    <h4>Json view of Rate</h4>
    <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
<div class="modal-body overflow-hidden" *ngIf="json">
    <div class="row">
        <div class="col-12">
            <pre *ngIf="json"><code>{{json}}</code></pre>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
