import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { JsonViewRatesModalComponent } from '../json-view-rates-modal/json-view-rates-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faAnchor, faEye, faSeedling, faShip, faTrain, faTruck, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  EContainerType,
  ERequestType,
  IFclResponse, IFreight,
  IShipment
} from '../../../../services/models/external-data/searates.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';
import { ExternalDataService } from '../../../../services/external-data.service';
import { environment } from '../../../../../environments/environment';
import { ICityDto } from '../../../../services/models/pricing/location.model';

@Component({
  selector: 'app-sea-rates-tab',
  templateUrl: './sea-rates-tab.component.html',
  styleUrls: ['./sea-rates-tab.component.scss']
})
export class SeaRatesTabComponent implements OnInit {

  // Icons
  faEye = faEye;
  faTruck = faTruck;
  faTrain = faTrain;
  faAnchor = faAnchor;
  faShip = faShip;
  faSeed = faSeedling;
  faExit = faXmark;

  public loading: boolean;
  ratesTypes = ERequestType;
  selectedRateType: ERequestType = ERequestType.FLC;
  containerTypes = EContainerType;
  selectedContainerTypes: string[] = ['ST20'];
  to: string;
  from: string;
  containerType: number;
  fclDate: string;
  currency: string = 'USD';
  fclResponse: IFclResponse;
  Currencies$ = this.pricingService.Currencies$.asObservable();
  selectedSeaRates: IShipment[] = null;
  freightOnly: boolean = false;
  isInvalid: boolean
  noResultFcl: boolean = false;

  constructor(private pricingService: PricingService,
              private datePipe: DatePipe,
              private alertService: AlertService,
              private externalDataService: ExternalDataService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    const today = new Date();
    this.fclDate = this.datePipe.transform(today, environment.FormattingStandards.ShortDateFormat);
    // testing location 1
    // this.from = '51.9167, 4.5';
    // this.to = '46.1956, 6.14811';
    // testing location 2
    // this.from = '52.35, 4.91667'; // chicago
    // this.to = '41.85, -87.6501'; // amsterdam
    // this.getFlcRates();
  }

  jsonViewRate(rate) {
    const modalRef = this.modalService.open(JsonViewRatesModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.Rate = rate;
    modalRef.result.then((result: boolean) => {
      if (result) {
      }
    }, () => {
    });
  }


  setFromCity(city: ICityDto) {
    if (this.isCoordinatesValid(city)) {
      this.from = city.Coordinates.trim();
    } else {
      this.alertService.error(`The coordinates for ${city.Name} are invalid. Please manually fill in the coordinates`);
      this.from = '';
    }
  }

  setToCity(city: ICityDto) {
    if (this.isCoordinatesValid(city)) {
      this.to = city.Coordinates.trim();
    } else {
      this.alertService.error(`The coordinates for ${city.Name} are invalid. Please manually fill in the coordinates`);
      this.to = '';
    }
  }

  isCoordinatesValid(city: ICityDto) {
    if (city) {
      if (city?.Coordinates) {
        return true;
      } else {
        this.alertService.error('The city coordinates are not set');
      }
    }
    return false;
  }

  getFlcRates() {
    if (this.isFclRequestValid()) {
      this.loading = true;
      this.externalDataService.getFclRates(this.from, this.to, this.selectedContainerTypes, this.fclDate, this.currency, this.freightOnly)
        .subscribe({
          next: value => {
            this.fclResponse = Object.assign({}, value);
            this.loading = false;
            this.noResultFcl = !(this.fclResponse.data.shipment.length > 0);
          }, error: () => {
            this.alertService.error('Could not retrieve the FCL rates');
            this.loading = false;
          }
        });
    }
  }

  isFclRequestValid() {
    if (this.selectedRateType == ERequestType.FLC &&
      this.from && this.to && this.fclDate &&
      this.selectedContainerTypes.length > 0) {
      this.isInvalid = false;
      return true;
    }
    this.alertService.error('Ensure required filters are entered');
    this.isInvalid = true;
    return false;
  }

  showSelectedRateInfo(index: number) {
    this.fclResponse.data.shipment[index].showSection = !this.fclResponse.data.shipment[index].showSection;
  }

  importSeaRates() {
    const ratesToImport = this.fclResponse.data.shipment.filter(x => x.selected);
    if (ratesToImport.length > 0) {
      this.loading = true;
      this.externalDataService.importSeaRates(ratesToImport)
        .subscribe({
          next: () => {
            this.alertService.success('Rates successfully imported!');
            this.loading = false;
          }, error: () => {
            this.alertService.error('Could not import rates');
            this.loading = false;
          }
        });
    } else {
      this.alertService.error('Please select rates to be imported');
    }
  }

  getFullFclPrice(freights: IFreight[]) {
    let fullPrice = 0;
    freights.forEach(x => {
      fullPrice += x.price;
      fullPrice += x.truckTo?.originalPrice;
      fullPrice += x.truckFrom?.originalPrice;
      fullPrice += x.railTo?.originalPrice;
      fullPrice += x.railFrom?.originalPrice;
      x.portFeesTo.forEach(y => {
        fullPrice += y?.price;
      });
      x.portFeesFrom.forEach(y => {
        fullPrice += y?.price;
      });
    });
    return fullPrice;
  }

  selectRate(rate: IShipment, $event: any) {
    rate.selected = $event.target.checked;
  }
}
