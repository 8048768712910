<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Pricing Admin
  </h1>
</div>
<div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs" id="pricingAdminTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="project-tab" data-bs-toggle="tab" href="#project" role="tab" aria-controls="project" aria-selected="true">
          Projects
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="rate-request-type-tab" data-bs-toggle="tab" href="#rate-request-type" role="tab" aria-controls="rate-request-type" aria-selected="true">
          Rate Request Types
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="service-type-tab" data-bs-toggle="tab" href="#service-type" role="tab" aria-controls="service-type-templates" aria-selected="true">
          Service Types
        </a>
      </li>
    </ul>
    <!-------------------------------->
    <!------ Tab Content Start ------->
    <!-------------------------------->
    <div class="tab-content" id="pricingAdminTabContent">
      <!-------------------------------->
      <!---------- Projects ------------>
      <!-------------------------------->
      <div class="tab-pane fade show active mb-5" id="project" role="tabpanel" aria-labelledby="project-tab">

      </div>
      <!------------------------------->
      <!----- Rate Request Types ------>
      <!------------------------------->
      <div class="tab-pane fade mb-5" id="rate-request-type" role="tabpanel" aria-labelledby="rate-request-type-tab">

      </div>
      <!------------------------------>
      <!------- Service Types -------->
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="service-type" role="tabpanel" aria-labelledby="service-type-tab">

      </div>
    </div>
  </div>
</div>
