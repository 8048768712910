<div class="modal-header modal-title">
  <h4>Upload a New Emergency Plan Document</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-2">
      <b>Name</b>
    </div>
    <div class="col-7">
      <input class="form-control form-control-sm" type="text"
             [ngClass]="{'is-invalid': plan?.Name?.length < 1 && this.invalid}"
             [disabled]="!hasAccess"
             [placeholder]="'Enter a name for the document'" [(ngModel)]="plan.Name">
    </div>
    <div class="col-3">
      <button class="btn btn-primary harmony-purple-button float-end"
              type="button" [disabled]="!hasAccess"
              (click)="FileHandler.click()">
        <fa-icon [icon]="faDownload"></fa-icon>
        Upload
      </button>
      <input hidden
             #FileHandler
             type="file"
             id="file"
             multiple
             (change)="selectFile($event)"/>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
    </div>
    <div class="col-9">
      {{ plan.FileName }}
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="!isNew" [disabled]="loading || !hasAccess" (click)="update()">
    Save
  </button>
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="isNew" [disabled]="loading || !hasAccess" (click)="add()">
    Save
  </button>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="close(false)">
    Close
  </button>
</div>
