import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NetworkMenuComponent } from './network-menu/network-menu.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { NetworkRoutingModule } from './network-routing.module';
import { MemberUserManagementComponent } from './member-user-management/member-user-management.component';
import {
  MemberUserManagementModalComponent
} from './member-user-management/member-user-management-modal/member-user-management-modal.component';
import { AdminModule } from '../admin/admin.module';
import { TrainingResourcesComponent } from './training-resources/training-resources.component';
import { MemberSearchComponent } from './member-search/member-search.component';
import { MemberComponent } from './member-search/member/member.component';
import {
  ArticleItemComponent
} from '../../shared/components/article-item/article-item.component';
import { UserContactModalComponent } from './member-search/member/user-contact-modal/user-contact-modal.component';
import { DocumentListComponent } from '../../shared/components/document-list/document-list.component';

@NgModule({
  declarations: [
    NetworkMenuComponent,
    MemberProfileComponent,
    MemberUserManagementComponent,
    MemberUserManagementModalComponent,
    TrainingResourcesComponent,
    MemberSearchComponent,
    MemberComponent,
    UserContactModalComponent
  ],
  exports: [
    NetworkMenuComponent,
    MemberProfileComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    NetworkRoutingModule,
    AdminModule,
    NgOptimizedImage,
    ArticleItemComponent,
    DocumentListComponent
  ],
})
export class NetworkModule { }
