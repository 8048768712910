import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { ApiService } from '../../../../services/api.service';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ICityDto,
  ICityPageParam,
  ICityPageResult
} from '../../../../services/models/pricing/location.model';
import { faBoxOpen, faEdit, faEye, faPlus, faSortDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CityEditModalComponent } from './city-edit-modal/city-edit-modal.component';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-city-management',
  templateUrl: './city-management.component.html',
  styleUrls: ['./city-management.component.scss']
})
export class CityManagementComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faSortAsc = faSortDown;
  protected readonly faDelete = faTrash;
  protected readonly faRemove = faTrash;
  protected readonly faAdd = faPlus;

  // Component variables
  paginationData: IPaginationData<ICityPageResult>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: ICityPageParam;
  hasDeletePermission: boolean = false;

  // General Variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private auth: AdAuthService,
              private alertService: AlertService) {}

  ngOnInit() {
    this.loading = true;
    this.initPagination();
    // Load agents
    this.searchCities();
    // Set permissions
    this.hasDeletePermission = this.auth.CheckPermissionByCode(PermissionCodes.Admin_LocationDelete);
  }

  initPagination() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set filter
    this.searchParam = {
      SearchText: null,
      CountryId: null,
      StateId: null,
      Active: true
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchFilter() {
    this.page.pageNumber = 1;
    this.searchCities();
  }

  resetSearch() {
    this.initPagination();
    this.searchFilter();
  }

  searchCities() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`City/List?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<ICityPageResult>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchCities();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchCities();
  }

  newCity() {
    const modalRef = this.modalService.open(CityEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CityId = null;
    modalRef.result.then((result: ICityDto) => {
        this.searchCities();
      }, () => { }
    );
  }

  editCity(city: ICityPageResult) {
    // Open modal to edit a user
    const modalRef = this.modalService.open(CityEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CityId = city.Id;
    // On modal close, read the result and refresh user list
    modalRef.result.then((result: ICityDto) => {
      this.searchCities();
      }, () => { }
    );
  }

  toggleCity(city: ICityPageResult) {
    city.Active = !city.Active;
    this.api.get(`City/ToggleActive/${city.Id}/${city.Active}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('City record successfully updated!');
      }, error: () => {
        this.alertService.error('City record failed to update');
      }
    });
  }
}
