import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { ICityFunctionDto, ICityFunctionSearch } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-port-search',
  templateUrl: './port-search.component.html',
  styleUrls: ['./port-search.component.scss']
})
export class PortSearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() IsAirport: boolean = false;
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;
  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadPortByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<ICityFunctionDto> = new EventEmitter<ICityFunctionDto>();

  // Component Variables
  selectedId: number;
  public selectedPort: ICityFunctionDto = null;
  searching: boolean = false;
  searchFailed: boolean = false;
  placeholderText: string;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: ICityFunctionDto) => `${result.CityName} (${result.Code})`;

  constructor(private api: ApiService) { }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadPortByID(this.SelectedID);
    }

    this.placeholderText = this.IsAirport == true ? 'Search Airport...' : 'Search Seaport...';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.IsAirport && changes.IsAirport.currentValue) {
      this.selectedPort = null;
      this.placeholderText = this.IsAirport == true ? 'Search Airport...' : 'Search Seaport...';
    }

    if (changes.Disabled && changes.Disabled.currentValue) {
      if (changes.Disabled.currentValue == true && changes.Disabled.previousValue == false) {
        this.clear();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchPort = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => { this.searching = true; this.searchFailed = false; }),
      switchMap(term =>
        this.getPort(term).pipe(
          tap(() => { this.searching = false; }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  }

  getPort(searchText: string): Observable<ICityFunctionDto[]> {
    if (searchText && searchText.length > 2) {
      const param = {
        Active: true,
        FunctionTypeId: this.IsAirport ? 4 : 1,
        Name: searchText
      } as ICityFunctionSearch;
      // Build URL for request
      const urlPath = 'CityFunction/Search';
      // Call City controller "List" endpoint
      return this.api.post(urlPath, param).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadPortByID(Id: number) {
    // Call City controller "Single" endpoint
    this.api.get('CityFunction/Single/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICityFunctionDto) => {
      if (data) {
        this.selectedPort = Object.assign({}, data);
      }
    });
  }

  onSelect(selectedPort: ICityFunctionDto) {
    if (selectedPort && selectedPort.Id) {
      this.OnSelect.emit(selectedPort.Id);
      this.OnSelectObject.emit(selectedPort);
    }
  }

  clear() {
    this.searching = false;
    this.selectedPort = null;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
  }
}
