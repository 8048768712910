<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    {{ riskAssessment == null ? 'Health & Safety Risk Assessment' : riskAssessment.Name }}
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1" *ngIf="riskAssessment"
            [disabled]="loading" (click)="openInfoModal()">
      Company Information
    </button>
    <button class="btn btn-sm btn-primary harmony-purple-outline-button"
            *ngIf="canCreateNew && !riskAssessment"
            [disabled]="loading" (click)="openRiskAssessmentCreate()">
      New Risk Assessment
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-2"
            *ngIf="riskAssessment"
            [ngbTooltip]="'Back to overview'" (click)="back(true)">
      Back
    </button>
  </div>
</div>
<!--------------------------------->
<!-- Risk Assessment Overview ----->
<!--------------------------------->
<ng-container *ngIf="!riskAssessment">
  <div class="risk-assessment">
    <!----------------------->
    <!------- Filters ------->
    <!----------------------->
    <ng-container *ngIf="canViewCompanies">
      <div class="row ms-1 me-1">
        <div class="col-1 align-self-center">
          <b>Company</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="riskAssessmentSearchParam.CompanyId"
                  [disabled]="loading || !(companies$ | async)">
            <option [ngValue]="null" selected>All companies...</option>
            <option *ngFor="let company of (companies$ | async)"
                    [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
        <div class="col-1 align-self-center">
          <b>Year</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="riskAssessmentSearchParam.Year"
                  [disabled]="loading">
            <option [ngValue]="null" selected>All years...</option>
            <option *ngFor="let year of generateYearsBetween()"
                    [ngValue]="year">{{ year }}</option>
          </select>
        </div>
        <div class="col-1 align-self-center">
          <b>Status</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="riskAssessmentSearchParam.AssessmentStatusId"
                  [disabled]="loading || !(riskAssessmentStatus$ | async)">
            <option [ngValue]="null" selected>All statuses...</option>
            <option *ngFor="let status of (riskAssessmentStatus$ | async)"
                    [ngValue]="status.Id">{{ status.Description }}</option>
          </select>
        </div>

        <div class="col-2 offset-sm-1 mb-0">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
                  (click)="searchRiskAssessments()">
            Search
          </button>
        </div>
      </div>
      <hr class="my-1">
    </ng-container>
    <!----------------------->
    <!------- Results ------->
    <!----------------------->
    <!-- Pagination -->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'RAOverview'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <div class="row mt-2 header-row">
      <div class="col-2 header-title border-split-left">
        Company
      </div>
      <div class="col-1 header-title">
        Year
      </div>
      <div class="col-3 header-title">
        File Name
      </div>
      <div class="col-1 header-title overflow-hidden">
        Modified
      </div>
      <div class="col-2 header-title">
        Modified By
      </div>
      <div class="col-1 header-title">
        Status
      </div>
      <div class="col-2 header-title border-split-right text-center">
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-no-result [showNoResults]="noResult"></app-no-result>
    <div class="row result-row my-0" *ngFor='let r of paginationData.DataSet; let i = index'>
      <div class="col-2 overflow-hidden">
        {{ r.CompanyName }}
      </div>
      <div class="col-1 fw-bold overflow-hidden">
        {{ r.Year }}
      </div>
      <div class="col-3 overflow-hidden"  [title]="r.Name">
        {{ r.Name }}
      </div>
      <div class="col-1 overflow-hidden">
        <small>{{ r.DateModified ? (r.DateModified | date: environment.FormattingStandards.ShortDateFormat) : '-' }}</small>
      </div>
      <div class="col-2 overflow-hidden">
        {{ r.LastModifiedByUserName }}
      </div>
      <div class="col-1 overflow-hidden">
        {{ r.AssessmentStatus }}
      </div>
      <div class="col-2 text-end pe-1">
        <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                [title]="'View or edit risk assessment'" (click)="navigateToCompanyChart(r.Id)">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                [title]="'View report of risk assessment'" (click)="openRiskAssessmentReport(r)">
          <fa-icon [icon]="faView"></fa-icon>
        </button>
        <button *ngIf="authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_DeleteAssessment)"
                class="btn btn-sm btn-outline-danger"
                [title]="'Delete risk assessment'" (click)="deleteRiskAssessment(r.Id)">
          <fa-icon [icon]="faDelete"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<!--------------------------->
<!-- Active Risk Assessment-->
<!--------------------------->
<app-loader *ngIf="loadingRiskAssessment"></app-loader>
<app-watermark *ngIf="companyImages && showWatermark" [image]="companyImages.Logo"></app-watermark>
<ng-container *ngIf="riskAssessment && !loadingRiskAssessment">
  <div class="row">
    <div class="col-6 mb-1">
      <button class="btn btn-sm btn-outline-primary harmony-grey-outline-button px-2 me-2"
              (click)="showOpenIRL = false; showOpenRRL = false; showNeedAttention = false;">
        All
      </button>
      <button class="btn btn-sm px-2 me-2"
              [ngClass]="{'btn-outline-primary harmony-grey-outline-button' : showOpenIRL == false,
                          'btn-primary harmony-teal-button' : showOpenIRL == true}"
              (click)="showOpenIRL = !showOpenIRL"
              [title]="'Show all items that never have been filled out or have a IRL of 0'">
        Open IRL
      </button>
      <button class="btn btn-sm px-2 me-2"
              [ngClass]="{'btn-outline-primary harmony-grey-outline-button' : showOpenRRL == false,
                          'btn-primary harmony-teal-button' : showOpenRRL == true}"
              (click)="showOpenRRL = !showOpenRRL"
              [title]="'Show all items that never have been filled out or have a RRL of 0'">
        Open RRL
      </button>
      <button class="btn btn-sm px-2 me-2"
              [ngClass]="{'btn-outline-primary harmony-grey-outline-button' : showNeedAttention == false,
                          'btn-primary harmony-teal-button' : showNeedAttention == true}"
              (click)="showNeedAttention = !showNeedAttention"
              [title]="'Show all items with a (high) RRL that need attention'">
        Need attention
      </button>
    </div>
    <div class="col-1 col-compress align-self-center">
      <div class="form-check form-switch form-switch-sm">
        <input class="form-check-input pointer float-end"
               type="checkbox"
               role="switch"
               [title]="'Watermark'"
               [id]="'hs-watermark-switch'"
               [checked]="showWatermark"
               (change)="showWatermark = !showWatermark">
      </div>
    </div>
    <div class="col-5" *ngIf="canEditName">
      <div class="row">
        <div class="col-9">
          <input class="form-control form-control-sm"
                 type="text"
                 [(ngModel)]="riskAssessment.Name">
        </div>
        <div class="col-3">
          <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button w-100"
                  (click)="updateName()">
            Update Name
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-no-result [showNoResults]="noResult"></app-no-result>
  <div class="accordion" id="accordion-question" *ngIf="riskAssessmentGroups && riskAssessmentItems && !loading">
    <ng-container *ngFor='let group of riskAssessmentGroups; let c = index;'>
      <div class="row" *ngIf="getGroupItems(group.Id).length > 0">
        <div class="col-12">
          <div class="card my-1">
            <div class="card-header p-0" [attr.id]="'heading-' + c">
              <div class="row pointer" (click)="chapterBtn.click()">
                <div class="col-10 text-start">
                  <h3 class="mb-0">
                    <button class="btn chapter-header mb-0 pt-2 fw-bold" #chapterBtn [ngClass]="{ 'collapsed': c > 0 }"
                            type="button"
                            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + c"
                            [attr.aria-expanded]="c == 0" [attr.aria-controls]="'collapse-' + c">
                      {{ group.Id + '. ' + group.Description }}
                    </button>
                  </h3>
                </div>
                <div class="col-2 text-center align-middle">
                  <div class="mb-0 pt-2 fs-6">
                    {{ 'Open IRL: ' + filteredIRL + ' | ' + 'Open RRL: ' + filteredRRL }}
                  </div>
                </div>
              </div>
            </div>
            <div [attr.id]="'collapse-' + c" class="collapse" [attr.aria-labelledby]="'heading-' + c"
                 [ngClass]="{'show' : c == 0 }" data-bs-parent="#accordion-question">
              <!-- For each Assessment item group a card body  -->
              <div class="card-body">
                <div class="row m-0 header-row">
                  <div class="col-1 header-title-small border-split-left">
                    <small>Item number</small>
                  </div>
                  <div class="col-4 header-title-small">
                    <small>Topic</small>
                  </div>
                  <div class="col-4 header-title-small">
                    <small>Subject</small>
                  </div>
                  <div class="col-1 header-title-small text-center">
                    <small>IRL</small>
                  </div>
                  <div class="col-1 header-title-small text-center">
                    <small>RRL</small>
                  </div>
                  <div class="col-1 header-title-small text-end border-split-right">
                  </div>
                </div>
                <div class="row result-row border-bottom" *ngFor='let item of getGroupItems(group.Id);'
                     [ngClass]="{'even': item % 2 === 0}">
                  <div class="col-1">
                    {{ item.ItemNumber }}
                  </div>
                  <div class="col-4 overflow-hidden">
                    {{ item.Topic }}
                  </div>
                  <div class="col-4 overflow-hidden">
                    {{ item.Subject }}
                  </div>
                  <div class="col-1 pe-2 py-0 text-center fw-bold" [ngClass]="setIRLRiskLevelColor(item)">
                    {{ item.IRL >= 0  ? item.IRL : '-' }}
                  </div>
                  <div class="col-1 pe-2 py-0 text-center fw-bold" [ngClass]="setRRLRiskLevelColor(item)">
                    {{ (item.AnswerModifiedDate != null && item.RRL != null) ? item.RRL : '-' }}
                  </div>
                  <div class="col-1 text-end">
                    <button *ngIf="item && isExpired == false"  class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                            (click)="openRiskAssessmentAnswerModal(item, group)"
                            [title]="'Edit item'">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-6">
      <!---- Legend ---->
      <div class="mt-2 mb-4">
        <div class="row my-2 fs-6 h-25">
          <div class="col-2 ps-4 fw-bold text-start">
            <small>Risk level</small>
          </div>
          <div class="col-10 ps-4 fw-bold">
            <small>Risk priorities</small>
          </div>
        </div>
        <div class="row mb-1 my-2 small h-25">
          <div class="col-2 ps-4 text-center">
            <div class="risk-level risk-level-green">
              < 21
            </div>
          </div>
          <div class="col-10 ps-4">
            low: occupational risk is low or sufficiently managed
          </div>
        </div>
        <div class="row mb-1 my-2 small h-25">
          <div class="col-2 ps-4 text-center">
            <div class="risk-level risk-level-lightgreen">
              21-70
            </div>
          </div>
          <div class="col-10 ps-4">
            medium: occupational risks are low or managed but still require attention
          </div>
        </div>
        <div class="row mb-1 my-2 small h-25">
          <div class="col-2 ps-4 text-center">
            <div class="risk-level risk-level-yellow">
              71-200
            </div>
          </div>
          <div class="col-10 ps-4">
            high: improvement action is necessary to reduce risk level
          </div>
        </div>
        <div class="row mb-1 my-2 small h-25">
          <div class="col-2 ps-4 text-center">
            <div class="risk-level risk-level-orange">
              201-400
            </div>
          </div>
          <div class="col-10 ps-4">
            very high: take immediate action to address risk
          </div>
        </div>
        <div class="row mb-1 my-2 small h-25">
          <div class="col-2 ps-4 text-center">
            <div class="risk-level risk-level-red">
              > 400
            </div>
          </div>
          <div class="col-10 ps-4">
            unacceptable: stop work immediately (*)
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center text-end">
      <img *ngIf="companyImages" [src]="companyImages.Logo" alt="member-logo" class="assessment-logo">
    </div>
  </div>
</ng-container>


