import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { IEnvironmentalAssessmentGroup } from '../../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../../core/ad-auth-service/ad-auth.service";
import { SharedModule } from "../../../../../shared/shared.module";

@Component({
  selector: 'app-environmental-assessment-group-modal',
  templateUrl: './environmental-assessment-group-admin-modal.component.html',
  standalone: true,
  imports: [SharedModule],
  styleUrls: ['./environmental-assessment-group-admin-modal.component.scss']
})
export class EnvironmentalAssessmentGroupAdminModalComponent {

  // Component Variables
  isValid: boolean = false;
  environmentalAssessmentGroup: IEnvironmentalAssessmentGroup;

  constructor(private activeModal: NgbActiveModal,
              private hseService: HseService,
              private alertService: AlertService,
              public authService: AdAuthService) {
  }

  saveGroup() {
    this.hseService.updateEnvironmentalAssessmentGroup(this.environmentalAssessmentGroup)
       .subscribe({
         next: (data) => {
           this.alertService.info('Environmental assessment group ' + this.environmentalAssessmentGroup.Description + ' successfully saved.');
           this.environmentalAssessmentGroup = Object.assign({}, data);
           this.close();
         },
         error: () => {
           this.alertService.error('Failed to update environmental assessment group.');
         }
       });
  }

  close() {
    this.activeModal.close();
  }
}
