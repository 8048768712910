import { Component } from '@angular/core';
import {
  faBoxOpen,
  faCheckCircle,
  faCircleXmark,
  faEdit,
  faEye, faPlus,
  faSortDown,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import {
  ICountryDto,
  ICountryPageParam,
  ICountryPageResult
} from '../../../../services/models/pricing/location.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { CountryEditModalComponent } from './country-edit-modal/country-edit-modal.component';
import { AlertService } from '../../../../services/alert.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-country-management',
  templateUrl: './country-management.component.html',
  styleUrls: ['./country-management.component.scss']
})
export class CountryManagementComponent {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faSortAsc = faSortDown;
  protected readonly faDelete = faTrash;
  protected readonly faCheck = faCheckCircle;
  protected readonly faFalse = faCircleXmark;
  protected readonly faRemove = faTrash;
  protected readonly faAdd = faPlus;

  // Component variables
  paginationData: IPaginationData<ICountryPageResult>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: ICountryPageParam;
  hasDeletePermission: boolean = false;

  // General Variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private auth: AdAuthService) {}

  ngOnInit() {
    this.loading = true;
    this.initPagination();
    // Load agents
    this.searchCountries();
    // Set permissions
    this.hasDeletePermission = this.auth.CheckPermissionByCode(PermissionCodes.Admin_LocationDelete);
  }

  initPagination() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set filter
    this.searchParam = {
      SearchText: null,
      Active: true
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchFilter() {
    this.page.pageNumber = 1;
    this.searchCountries();
  }

  resetSearch() {
    this.initPagination();
    this.searchFilter();
  }

  searchCountries() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`Country/List?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<ICountryPageResult>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchCountries();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchCountries();
  }

  newCountry() {
    const modalRef = this.modalService.open(CountryEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CountryId = null;
    modalRef.result.then((result: ICountryDto) => {
        this.searchCountries();
      }, () => { }
    );
  }

  editCountry(country: ICountryPageResult) {
    // Open modal to edit a user
    const modalRef = this.modalService.open(CountryEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CountryId = country.Id;
    // On modal close, read the result and refresh user list
    modalRef.result.then((result: ICountryDto) => {
        country.Name = result.Name;
        country.Iso2 = result.Iso2;
        country.Iso3 = result.Iso3;
        country.IsEu = result.IsEu;
        country.Continent = result.ContinentCode;
      }, () => { }
    );
  }

  toggleCountry(city: ICountryPageResult) {
    city.Active = !city.Active;
    this.api.get(`Country/ToggleActive/${city.Id}/${city.Active}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('Country record successfully updated!');
      }, error: () => {
        this.alertService.error('Country record failed to update');
      }
    });
  }
}
