<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    HSE Documents
  </h1>
</div>
<!----------------------->
<!------- Results ------->
<!----------------------->
<app-loader *ngIf="loading"></app-loader>
<div class="accordion" [id]="'accordion-hse-documents'">
  <!-- Document Categories -->
  <ng-container *ngFor="let category of (documentCategories$ | async)">
    <div class="row" *ngIf="category.ModuleName == 'HSE'">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-' + category.Id">
            <div class="row pointer" (click)="categoryBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #categoryBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-' + category.Id"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-' + category.Id">
                    {{ category.Name }}
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-' + category.Id" class="collapse"
               [attr.aria-labelledby]="'heading-' + category.Id"
               [attr.data-bs-parent]="'#accordion-hse-documents'">
            <div class="card-body">
              <app-document-list [CategoryCode]="category.Code" [ModuleName]="'HSE'"></app-document-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
