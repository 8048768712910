<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Emergency
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-purple-outline-button me-2"
            *ngIf="isDrillTab && canAdd" [title]="'Add a drill evaluation'"
            (click)="openDrillEvaluationModal(true)">
      + New Drill Evaluation
    </button>
    <button type="button" class="btn btn-sm btn-primary harmony-teal-outline-button me-2"
            *ngIf="isPlanTab" [title]="'Download template'" (click)="downloadTemplate()">
      <fa-icon [icon]="faDownload"></fa-icon>
      Emergency Plan Template
    </button>
    <button type="button" class="btn btn-sm btn-primary harmony-purple-outline-button me-2"
            *ngIf="isPlanTab && canAdd" [title]="'Upload document'" (click)="openDocumentModal(true)">
      <fa-icon [icon]="faUpload"></fa-icon>
      Upload Emergency Plan
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <!--------------->
    <!---- Tabs ----->
    <!--------------->
    <ul class="nav nav-tabs" id="emergency" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="drill-evaluation-tab" data-bs-toggle="tab" href="#drill-evaluation" role="tab"
           aria-controls="drill-evaluation" aria-selected="false" (click)="setDrillTab()">
          Drill Evaluation
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="emergency-plan-tab" data-bs-toggle="tab" href="#emergency-plan" role="tab"
           aria-controls="emergency-plan"
           aria-selected="true" (click)="setPlanTab()">
          Emergency Plan
        </a>
      </li>
    </ul>
    <div id="emergency-content" class="tab-content mt-2">
      <!------------------------------->
      <!------ Drill Evaluation ------->
      <!------------------------------->
      <div class="tab-pane fade show active" id="drill-evaluation" role="tabpanel"
           aria-labelledby="drill-evaluation-tab">
        <!------- filters -------->
        <div class="row">
          <div class="col-1" *ngIf="canFilterCompany">
            <b>Company</b>
          </div>
          <div class="col-3" *ngIf="canFilterCompany">
            <select class="form-select form-select-sm pointer"
                    [(ngModel)]="companyId" (ngModelChange)="updateBranches()"
                    [disabled]="loading || !(companies$ | async)">
              <option [ngValue]="null" selected>Select a company...</option>
              <option *ngFor="let company of (companies$ | async)"
                      [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
            </select>
          </div>
          <div class="col-1">
            <b>Branch</b>
          </div>
          <div class="col-3">
            <div *ngIf="branches.length == 0">
              <select class="form-select form-select-sm form-select-sm pointer" aria-label="Disabled select example"
                      disabled>
                <option selected>Default</option>
              </select>
            </div>
            <div *ngIf="branches.length > 0">
              <select class="form-select form-select-sm pointer"
                      [(ngModel)]="branch"
                      [disabled]="loading || !companyId">
                <option [ngValue]="null" selected>Select a branch...</option>
                <option *ngFor="let branch of branches"
                        [ngValue]="branch">{{ branch.Name }}</option>
              </select>
            </div>
          </div>
          <div class="col-2" [ngClass]="canFilterCompany ? 'offset-2' : 'offset-6'"
               *ngIf="(!canFilterCompany && branches.length > 0) || canFilterCompany">
            <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
                    [disabled]="!companyId && !canFilterCompany" (click)="searchDrills()">
              Search
            </button>
          </div>
        </div>
        <hr class="mb-0 mt-2">
        <!------- Pagination -------->
        <div class="row ms-1 me-1">
          <div class="col-12">
            <app-pagination [showPageNumber]=true
                            [showRowsPerPage]=true
                            [paginationData]=drillPagination
                            (pageEvent)="getDrillPage($event)"
                            [name]="'DrillEvaluation'"
                            [page]="drillPage">
            </app-pagination>
          </div>
        </div>
        <!------- Table Header -------->
        <div class="row header-row mt-2">
          <div class="col-3 header-title border-split-left">
            Company
          </div>
          <div class="col-2 header-title">
            Branch
          </div>
          <div class="col-2 header-title">
            Location
          </div>
          <div class="col-2 header-title">
            Evaluation Date
          </div>
          <div class="col-1 header-title">
            Relation
          </div>
          <div class="col-2 header-title border-split-right">
          </div>
        </div>
        <!------- Table Results -------->
        <app-no-result [showNoResults]="!(drillPagination?.DataSet?.length > 0)"></app-no-result>
        <div class="row result-row" *ngFor="let x of drillPagination?.DataSet; let i = index">
          <div class="col-3 overflow-hidden">
            <b>{{ x.CompanyName }}</b>
          </div>
          <div class="col-2 overflow-hidden">
            {{ x.BranchName ?? '-' }}
          </div>
          <div class="col-2 overflow-hidden">
            {{ x.Location ?? '-' }}
          </div>
          <div class="col-2 overflow-hidden">
            <small>{{ x.ExecutedDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
          </div>
          <div class="col-1 overflow-hidden">
            <fa-icon *ngIf="x.IsEnvironment" [icon]="faEarth" [size]="'lg'"
                     class="harmony-green-text me-2" [title]="'Environment'">
            </fa-icon>
            <fa-icon *ngIf="x.IsHealthAndSafety" [icon]="faHealthAndSafety" [size]="'lg'"
                     class="harmony-blue-grey-text" [title]="'Health and Safety'">
            </fa-icon>
          </div>
          <div class="col-2 overflow-hidden text-end pe-0">
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    *ngIf="canEdit"
                    [title]="'Edit Drill Evaluation'" (click)="openDrillEvaluationModal(false, x)">
              <fa-icon [icon]="faEditIcon"></fa-icon>
            </button>
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    *ngIf="!canEdit"
                    [title]="'View Drill Evaluation'" (click)="openDrillEvaluationModal(false, x)">
              <fa-icon [icon]="faView"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger me-0"
                    *ngIf="canDelete"
                    [title]="'Delete Drill Evaluation'" (click)="deleteDrill(i, x.Id)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!----------------------------->
      <!------ Emergency Plan ------->
      <!----------------------------->
      <div class="tab-pane fade" id="emergency-plan" role="tabpanel" aria-labelledby="emergency-plan-tab">
        <div class="row ms-1 me-1">
          <app-pagination [showPageNumber]=true
                          [showRowsPerPage]=true
                          [paginationData]=planPagination
                          (pageEvent)="getPlanPage($event)"
                          [name]="'EmergencyPlan'"
                          [page]="planPage"></app-pagination>
        </div>
        <div class="row header-row mt-2">
          <div class="header-title border-split-left" [ngClass]="{'col-4': this.canFilterCompany, 'col-8': !this.canFilterCompany}">
            Name
          </div>
          <div class="col-4 header-title" *ngIf="this.canFilterCompany">
            Company
          </div>
          <div class="col-2 header-title">
            Upload Date
          </div>
          <div class="col-2 header-title border-split-right">
          </div>
        </div>
        <div class="row result-row" *ngFor="let x of planPagination?.DataSet; let i = index">
          <div class="overflow-hidden"  [ngClass]="{'col-4': this.canFilterCompany, 'col-8': !this.canFilterCompany}">
            {{ x.Name }}
          </div>
          <div class="col-4 overflow-hidden" *ngIf="this.canFilterCompany">
            {{ x.CompanyName }}
          </div>
          <div class="col-2 overflow-hidden">
            <small>{{ x.UploadDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
          </div>
          <div class="col-2 overflow-hidden">
            <button class="btn btn-sm btn-outline-danger me-1 float-end"
                    *ngIf="canDelete"
                    [title]="'Delete Emergency Plan File'" (click)="deletePlan(i, x.Id)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1 float-end"
                    [title]="'Download File'" (click)="downloadPlan(i, x.Reference)">
              <fa-icon [icon]="faDownload"></fa-icon>

            </button>
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1 float-end"
                    *ngIf="canEdit"
                    [title]="'Edit plan'" (click)="openDocumentModal(false, x)">
              <fa-icon [icon]="faEditIcon"></fa-icon>
            </button>
          </div>
        </div>
        <app-no-result [showNoResults]="!(planPagination?.DataSet?.length > 0)"></app-no-result>
      </div>
    </div>
  </div>
</div>
