<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="!isNew">
    Edit Agent: {{ preferredAgent?.Id }}
  </h4>
  <h4 class="modal-title" *ngIf="isNew">
    Add Agent
  </h4>
</div>
<!---- Add/Edit Agent ---->
<div class="modal-body" *ngIf="preferredAgent">
  <form [formGroup]="agentForm">
    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <b>Agent</b>
        </div>
        <div class="col-4">
          <app-agent-search class="d-inline"
                            [Identifier]="'AgentID'"
                            [ShowError]="agentForm.controls.agentId.invalid && agentForm.controls.agentId.touched"
                            (click)="agentForm.controls.agentId.markAsTouched()"
                            [SelectedID]="preferredAgent.AgentId"
                            (OnSelectObject)="setAgent($event)">
          </app-agent-search>
        </div>
        <div class="col-2 overflow-hidden text-nowrap">
          <b>Requesting Company</b>
        </div>
        <div class="col-4">
          <select class="form-select form-select-sm pointer" id="requested-company-id"
                  [ngClass]="{'is-invalid' : agentForm.controls.requestedCompanyId.invalid && agentForm.controls.requestedCompanyId.touched}"
                  [disabled]="!(companies$ | async)"
                  (click)="agentForm.controls.requestedCompanyId.markAsTouched()"
                  (change)="setCompanyId(company?.CompanyId)"
                  [(ngModel)]="company"
                  [ngModelOptions]="{standalone: true}">
            <option [ngValue]="null" selected>Apply to all members...</option>
            <option *ngFor="let c of (companies$ | async)" [ngValue]="c">{{ c.CompanyName }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2">
          <b>Location Type</b>
        </div>
        <div class="col-4">
          <select class="form-select form-select-sm pointer" id="location-type"
                  [ngClass]="{'is-invalid': agentForm.controls.locationTypeId.invalid && agentForm.controls.locationTypeId.touched}"
                  [disabled]="!(locationTypes$ | async)"
                  (click)="agentForm.controls.locationTypeId.markAsTouched()"
                  (change)="setLocationTypeId(selectedLocationType)"
                  [(ngModel)]="selectedLocationType"
                  [ngModelOptions]="{standalone: true}">
            <option [ngValue]="null" selected>Select a location type...</option>
            <option *ngFor="let lt of (locationTypes$ | async)" [ngValue]="lt">{{ lt.Name }}</option>
          </select>
        </div>
        <div class="col-2">
          <b>Tender</b>
        </div>
        <div class="col-4">
          <app-tender-search class="d-inline"
                             [Identifier]="'TenderID'"
                             [ErrorColor]="'#cc000f'"
                             [ShowError]="agentForm.controls.tenderId.invalid && agentForm.controls.tenderId.touched"
                             [SelectedID]="preferredAgent.TenderId"
                             [Placeholder]="'Type to search Tender'"
                             (OnSelect)="preferredAgent.TenderId = $event">
          </app-tender-search>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2">
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'City'">
            <b>City</b>
          </div>
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'Country'">
            <b>Country</b>
          </div>
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'State'">
            <b>State</b>
          </div>
        </div>
        <div class="col-4">
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'City'">
            <app-city-search class="d-inline" [Identifier]="'CityID'"
                             [ShowError]="agentForm.controls.cityId.invalid && agentForm.controls.cityId.touched"
                             (click)="agentForm.controls.cityId.markAsTouched()"
                             [SelectedID]="preferredAgent.CityId"
                             (OnSelect)="preferredAgent.CityId = $event; preferredAgent.LocationId = $event; agentForm.controls.cityId.setValue($event)">
            </app-city-search>
          </div>
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'Country'">
            <app-country-search class="d-inline" [Identifier]="'CountryID'"
                                [ShowError]="agentForm.controls.countryId.invalid && agentForm.controls.countryId.touched"
                                (click)="agentForm.controls.countryId.markAsTouched()"
                                [SelectedID]="preferredAgent.CountryId"
                                (OnSelect)="preferredAgent.CountryId = $event; preferredAgent.LocationId = $event; agentForm.controls.countryId.setValue($event)">
            </app-country-search>
          </div>
          <div *ngIf="selectedLocationType && selectedLocationType?.Name == 'State'">
            <app-state-search class="d-inline" [Identifier]="'StateID'"
                              [ShowError]="agentForm.controls.stateId.invalid && agentForm.controls.stateId.touched"
                              (click)="agentForm.controls.stateId.markAsTouched()"
                              [SelectedID]="preferredAgent.StateId"
                              (OnSelect)="preferredAgent.StateId = $event; preferredAgent.LocationId = $event; agentForm.controls.stateId.setValue($event)">
            </app-state-search>
          </div>
        </div>
        <div class="col-2">
          <b>Start Date</b>
        </div>
        <div class="col-4">
          <app-date-picker [identifier]="'startDate'"
                           [showError]="agentForm.controls.startDate.invalid && agentForm.controls.startDate.touched"
                           [value]="preferredAgent.StartDate"
                           (click)="agentForm.controls.startDate.markAsTouched()"
                           (onChange)="setStartDate($event)"
                           [title]="'Select a start date'">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2">
          <b>Rate Type</b>
        </div>
        <div class="col-4">
          <select class="form-select form-select-sm pointer" id="rate-type"
                  [disabled]="!(rateTypes$ | async)"
                  (click)="agentForm.controls.rateTypeId.markAsTouched()"
                  (change)="setRateTypeId(selectedRate.Id)"
                  [(ngModel)]="selectedRate"
                  [ngModelOptions]="{standalone: true}">
            <option [ngValue]="null" selected>Select a rate type...</option>
            <option *ngFor="let r of (rateTypes$ | async)" [ngValue]="r">{{ r.Name }}</option>
          </select>
        </div>
        <div class="col-2">
          <b>End Date</b>
        </div>
        <div class="col-4">
          <app-date-picker [identifier]="'endDate'"
                           [value]="preferredAgent.EndDate"
                           (onChange)="setEndDate($event)"
                           [title]="'Select an end date'">
          </app-date-picker>
        </div>
      </div>
      <div class="row ps-0 pe-0 mt-2">
        <div class="col-2">
          <b>Comment</b>
        </div>
        <div class="col-4">
          <textarea id="comment" cols="2" rows="5" class="form-control form-control-sm" appAutocompleteOff
                    [placeholder]="'Enter an optional comment'"
                    [(ngModel)]="preferredAgent.Comment"
                    [ngModelOptions]="{standalone: true}"></textarea>
        </div>
        <div class="col-2">
          <div class="row">
            <div class="col-12">
              <b>Active</b>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <b>Additional Agent</b>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <b>Ignore Agent</b>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <app-toggle-button [id]="'active'"
                                 [checked]="preferredAgent.Active"
                                 (change)="setActive($event)"
                                 [Placeholder]="'Toggle Active'">
              </app-toggle-button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <app-toggle-button [id]="'additional-agent'"
                                 [checked]="preferredAgent.AdditionalAgent"
                                 (change)="setAdditionalAgents($event)"
                                 [Placeholder]="'Toggle Additional Agents'">
              </app-toggle-button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <app-toggle-button [id]="'ignore-agent'"
                                 [checked]="preferredAgent.IgnoreAgent"
                                 (change)="setIgnoreAgent($event)"
                                 [Placeholder]="'Toggle Ignore Agent'">
              </app-toggle-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>

</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="add()">Add</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
