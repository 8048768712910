import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-json-view-rates-modal',
  templateUrl: './json-view-rates-modal.component.html',
  styleUrls: ['./json-view-rates-modal.component.scss']
})
export class JsonViewRatesModalComponent implements OnInit {

  // Variables
  public Rate: object;
  json: string;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.Rate) {
      this.json = JSON.stringify(this.Rate, null, 2);
    }
  }

  close() {
    this.activeModal.close();
  }
}
