<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Document Categories
  </h1>
  <div>
    <button
      class="btn btn-sm btn-outline-primary harmony-purple-outline-button"
      (click)="addCategory()">
      <fa-icon [icon]="faAdd"></fa-icon> New Category
    </button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-4 align-self-center">
            <b>Module</b>
          </div>
          <div class="col-8">
            <select name="module"
                    class="form-select form-select-sm"
                    id="module"
                    [(ngModel)]="searchParam.ModuleId">
              <option [ngValue]="null" selected disabled>Select a module...</option>
              <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
                {{module.Name}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-1 mb-1">
    <div class="row mt-1 mb-1">
      <div class="col-4">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               [placeholder]="'Search...'">
      </div>
      <div class="col-1 offset-5 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                (click)="searchCategories()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
  </div>
</div>
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'EmailOverview'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<div class="row mt-2 header-row">
  <div class="col-2 header-title pointer border-split-left"
       (appOrder)="orderSearch($event)"
       data-column="Name"
       data-order="asc">
    Name
    <fa-icon [hidden]="orderParam.OrderBy != 'Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Code"
       data-order="asc">
    Code
    <fa-icon [hidden]="orderParam.OrderBy != 'Code'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Module.Name"
       data-order="asc">
    Module
    <fa-icon [hidden]="orderParam.OrderBy != 'Module.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-4 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Description"
       data-order="asc">
    Description
    <fa-icon [hidden]="orderParam.OrderBy != 'Description'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DateCreated"
       data-order="asc">
    Created Date
    <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="CreatedByUser.Name"
       data-order="asc">
    By
    <fa-icon [hidden]="orderParam.OrderBy != 'CreatedByUser.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title border-split-right">

  </div>
</div>
<ng-container *ngIf="!loading && paginationData.DataSet.length > 0">
  <div class="row result-row" *ngFor='let category of paginationData.DataSet; let i = index'>
    <div class="col-2 overflow-hidden" [title]="category.Name">
      <b><small>{{ category.Name }}</small></b>
    </div>
    <div class="col-1 overflow-hidden" [title]="category.Code">
      <code>{{ category.Code }}</code>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ category.ModuleName ? category.ModuleName : '-' }}</small>
    </div>
    <div class="col-4 overflow-hidden" [title]="category.Description">
      <small>{{ category.Description }}</small>
    </div>
    <div class="col-2 overflow-hidden">
      <small>{{ category.DateCreated ? (category.DateCreated | date: env.FormattingStandards.LongDateFormat) : '-' }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ category.CreatedByUserName }}</small>
    </div>
    <div class="col-1 text-end pe-1">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button"
              [title]="'View/Edit category'"
              (click)="editCategory(category)">
        <fa-icon [icon]="faEdit" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm ms-1 btn-outline-danger"
              [title]="'Delete category'"
              (click)="deleteCategory(category.Id)">
        <fa-icon [icon]="faDelete" size="sm"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
  <!-- No Results -->
  <div class="row mt-4">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No results...</h4>
    </div>
  </div>
</ng-container>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
