import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Input() set value(value: string) {
    if (value) {
      this.currentValue = this.datePipe.transform(value, this.format);
      const date = new Date(this.currentValue);
      this.startDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    } else {
      this.currentValue = null;
      this.startDate = null;
    }
  }
  get value(): string {
    return this.currentValue;
  }

  @Input() identifier: string;
  @Input() title = 'Select a date';
  @Input() placeholder = 'dd MMM yyyy';
  @Input() showError = false;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  faCalendar = faCalendar;
  format = 'dd MMM yyyy';
  currentValue: string = null;
  startDate: any = null;

  constructor(private datePipe: DatePipe) { }

  setDate(event: NgbDate) {
    if (event) {
      const date = new Date(event.year, event.month - 1, event.day);
      this.currentValue = this.datePipe.transform(date, this.format);
      this.onChange.emit(this.currentValue);
    } else {
      this.clear();
    }
  }

  toNgbDate(event: any) {
    if (event.target.value) {
      const date = new Date(event.target.value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1);
      const day = date.getDate();
      this.setDate(new NgbDate(year, month, day));
    } else {
      this.clear();
    }
    return;
  }

  clear() {
    this.currentValue = '';
    this.onChange.emit(null);
  }
}
