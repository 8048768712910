import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { AnnualReviewAdminComponent } from './anual-review-admin/annual-review-admin.component';
import { CompanyReviewComponent } from './company-review/company-review.component';
import { AuditComponent } from './audit/audit.component';

const routes: Routes = [
  {
    path: 'member-review',
    component: CompanyReviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'ANNREV'
    },
  },
  {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'ARAUDIT'
    },
  },
  {
    path: 'admin',
    component: AnnualReviewAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'ARADMIN'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnnualReviewRoutingModule { }
