<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-teal-text fw-bold mt-2">Featured Articles</h5>
      </div>
    </div>
    <div class="card-body overflow-mask">
      <ng-container *ngIf="articles && articles.length > 0">
        <div class="row" *ngFor="let article of articles ">
          <div class="col-12">
            <app-article-item [Article]="article" [Class]="'headline-odd'"></app-article-item>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!articles || articles.length <= 0">
        <div class="row mt-5">
          <div class="col-12 text-center">
            No featured articles...
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
