import { Component, OnDestroy, OnInit } from '@angular/core';
import { IStateDto } from '../../../../../services/models/pricing/location.model';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../services/alert.service';
import { ApiService } from '../../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-state-edit-modal',
  templateUrl: './state-edit-modal.component.html',
  styleUrls: ['./state-edit-modal.component.scss']
})
export class StateEditModalComponent implements OnInit, OnDestroy {
  // Icons

  // Component variables
  public StateId: number;
  state: IStateDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit(): void {
    if (this.StateId != null && this.StateId > 0) {
      this.loadState();
    } else {
      this.state = {
        Id: 0,
        DateModified: null,
        Name: null,
        Code: null,
        CountryId: null,
        CountryName: null,
        CountryCode: null
      } as IStateDto;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadState() {
    this.loading = true;
    this.api.get('State/Single/' + this.StateId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IStateDto) => {
        if (data) {
          this.state = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  save() {
    if (this.isValid()) {
      this.loading = true;
      this.api.post('State/Update', this.state).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IStateDto) => {
          if (data) {
            this.alertService.success('State record successfully updated!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    } else {
      this.alertService.warn('Unable to save. Please make sure all required fields are correctly populated.');
    }
  }

  isValid() {
    const c = this.state;

    return (c.Name && c.Name.length > 0) &&
      (c.Code && c.Code.length > 0) &&
      (c.CountryId && c.CountryId > 0);
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
