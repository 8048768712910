import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-admin',
  templateUrl: './hub-admin.component.html',
  styleUrls: ['./hub-admin.component.scss']
})
export class HubAdminComponent {

}
