import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITenderSearch } from 'src/app/services/models/pricing/tender.model';
import { ApiService } from 'src/app/services/api.service';
import { IState } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-tender-search',
  templateUrl: './tender-search.component.html',
  styleUrls: ['./tender-search.component.scss']
})
export class TenderSearchComponent implements OnInit, OnDestroy, OnChanges {
  // Input Variables
  @Input() Identifier: string = '';
  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadTenderByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  @Input() Disabled: boolean = false;
  @Input() Placeholder: string = 'Search...';
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<ITenderSearch> = new EventEmitter<ITenderSearch>();

  constructor(private api: ApiService,
              private ref: ChangeDetectorRef) { }

  // Component Variables
  selectedId: number;
  public selectedTender: ITenderSearch = null;
  searching: boolean = false;
  searchFailed: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: ITenderSearch) => result.Name;

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && (changes.SelectedID.currentValue == null || changes.SelectedID.currentValue == 0)) {
      this.clear();
    }
  }

  searchTender = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => { this.searching = true; this.searchFailed = false; }),
      switchMap(term =>
        this.getTender(term).pipe(
          tap(() => { this.searching = false; }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  }

  getTender(searchText: string): Observable<ITenderSearch[]> {
    if (searchText && searchText.length > 2) {
      const urlPath = 'Tender/Search/' + searchText;
      return this.api.get(urlPath).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadTenderByID(Id: number) {
    this.searching = true;
    // Call state controller "Single" endpoint
    this.api.get('Tender/SearchSingle/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IState) => {
      if (data) {
        this.selectedTender = Object.assign({}, data);
      }
      this.searching = false;
    });
  }

  onSelect() {
    if (this.selectedTender && this.selectedTender.Id) {
      this.OnSelect.emit(this.selectedTender.Id);
      this.OnSelectObject.emit(this.selectedTender);
    }
  }

  clear() {
    this.selectedTender = null;
    this.searching = false;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
    this.ref.detectChanges();
  }
}
