import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { IModule } from '../../../../services/models/user.model';
import { takeUntil } from 'rxjs/operators';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-module-modal',
  templateUrl: './module-modal.component.html',
  styleUrls: ['./module-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ModuleModalComponent implements OnInit {
  // Icons
  faInfo = faCircleQuestion;

  // Component Variables
  public ModuleId: number;
  public IsNew: boolean;
  module: IModule;
  nameInvalid: boolean = false;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (this.IsNew) {
      this.module = {
        Id: null,
        Active: true,
        DateCreated: new Date(),
        DateModified: null,
        Name: null,
        Description: null
      } as IModule;
    } else {
      this.loadModule();
    }
  }

  loadModule() {
    this.loading = true;

    this.api.get(`Module/Single/${this.ModuleId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IModule) => {
        if (data) {
          this.module = Object.assign({}, data);
        }
        this.loading = false;
      }, error: (error) => {
        this.alertService.error('Error occurred retrieving module data: ' + error);
        this.loading = false;
      }
    });
  }

  saveModule() {
    if (this.module.Name?.length >= 3) {
      this.loading = true;
      this.api.post('Module/Update', this.module).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.alertService.success('Module update successful!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: (error) => {
          this.alertService.error('Error occurred attempting to update the module: ' + error);
          this.loading = false;
        }
      });
    } else {
      this.nameInvalid = true;
    }
  }

  close() {
    this.activeModal.close(null);
  }
}
