import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-management',
  templateUrl: './location-management.component.html',
  styleUrls: ['./location-management.component.scss']
})
export class LocationManagementComponent implements OnInit {
  activeTab: string = 'manage';

  constructor(private route:ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      const routeParam = paramMap.get('activeTab');

      if (routeParam) {
        this.activeTab = routeParam;
      } else {
        this.activeTab = 'manage';
      }
    });
  }
}
