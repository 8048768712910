import { Component, Input } from '@angular/core';
import {
  faAward, faCancel, faCogs,
  faNetworkWired,
  faNewspaper,
  faProjectDiagram,
  faStamp,
  faToolbox
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-module-overview',
  templateUrl: './user-module-overview.component.html',
  styleUrls: ['./user-module-overview.component.scss']
})
export class UserModuleOverviewComponent {
  // Inputs
  @Input() Modules: string[];

  // Permission icons
  faNews = faNewspaper;
  faNetwork = faProjectDiagram;
  faHub = faNetworkWired;
  faAnnualReview = faStamp;
  faHSE = faAward;
  faPricing = faToolbox;
  faAdmin = faCogs;
  faNoAccess = faCancel;

  hasModule(moduleName: string) {
    const hasModule = this.Modules.filter(x => x == moduleName);
    return hasModule && hasModule.length > 0;
  }
}
