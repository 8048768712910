<div class="row">
  <div class="col-12 d-flex align-self-center">
    <input type="text"
           class="form-control form-control-sm d-inline-flex"
           [placeholder]="'email1@example.com;email2@example.com;...'"
           [maxLength]="MaxLength"
           [disabled]="Disabled"
           [value]="emailAddress"
           (change)="onChange($event.target['value'])">
    <ng-container *ngIf="emailAddress?.length > 0">
      <fa-icon *ngIf="errorMessages?.length > 0"
               [icon]="faInvalid"
               [size]="'1x'"
               class="text-danger d-inline-flex m-2 pointer"
               [title]="'Invalid: \n\n' + errorMessages.join('\n')"></fa-icon>
      <fa-icon *ngIf="errorMessages?.length <= 0"
               [icon]="faValid"
               [size]="'1x'"
               class="text-success d-inline-flex m-2 pointer"
               [title]="'All email addresses are valid'"></fa-icon>
    </ng-container>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div *ngIf="errorMessages.length > 0" class="text-danger" style="font-size: 0.75rem;">
        Invalid email{{errorMessages.length > 1 ? 's' : ''}}: <small class="d-inline" *ngFor="let error of errorMessages; let i = index">{{ i > 0 ? ',' : '' }}&nbsp;{{ error }}</small>
    </div>
  </div>
</div>
