import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IncidentImprovementComponent } from './incident-improvement/incident-improvement.component';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { DangerousSubstanceRegisterComponent } from './dangerous-substance-register/dangerous-substance-register.component';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { PulseChartComponent } from './pulse-chart/pulse-chart.component';
import { RiskAssessmentsOverviewComponent } from './risk-assessment/risk-assessments-overview.component';
import { EmergencyComponent } from './emergency/emergency.component';
import { EnvironmentalAssessmentsOverviewComponent } from './environmental-assessment/environmental-assessments-overview.component';
import { CompanyChartOverviewComponent } from './company-chart/company-chart-overview.component';
import { EnvironmentalImpactDataComponent } from './environmental-impact-data/environmental-impact-data.component';
import {RiskAssessmentAdminComponent} from "./hse-admin/risk-assessment-admin/risk-assessment-admin.component";
import {
  EnvironmentalAssessmentAdminComponent
} from "./hse-admin/environmental-assessment-admin/environmental-assessment-admin.component";
import {BranchAdminComponent} from "./hse-admin/branch-admin/branch-admin.component";
import {PsPhrasesAdminComponent} from "./hse-admin/ps-phrases-admin/ps-phrases-admin.component";
import {HrPhrasesAdminComponent} from "./hse-admin/hr-phrases-admin/hr-phrases-admin.component";
import {DashboardContainerHseComponent} from "./hse-dashboard/dashboard-container-hse/dashboard-container-hse.component";

import { HseDocumentsComponent } from './hse-documents/hse-documents.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardContainerHseComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_DashBoard
    },
  },
  {
    path: 'incident-improvement',
    component: IncidentImprovementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_IncidentImprovement
    },
  },
  {
    path: 'incident-improvement/:incidentId',
    component: IncidentImprovementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_IncidentImprovement
    },
  },
  {
    path: 'dsr',
    component: DangerousSubstanceRegisterComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_DSR
    },
  },
  {
    path: 'pulse-chart',
    component: PulseChartComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_PulseChart
    },
  },
  {
    path: 'pulse-chart/:pulseChartId',
    component: PulseChartComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_PulseChart
    },
  },
  {
    path: 'hs-risk-assessment',
    component: RiskAssessmentsOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_HS_RiskAssessment
    }
  },
  {
    path: 'hs-risk-assessment/:hsAssessmentId',
    component: RiskAssessmentsOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_HS_RiskAssessment
    }
  },
  {
    path: 'emergency',
    component: EmergencyComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Emergency
    }
  },
  {
    path: 'environmental-risk-assessment',
    component: EnvironmentalAssessmentsOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_EnvironmentalAssessment
    }
  },
  {
    path: 'environmental-risk-assessment/:envAssessmentId',
    component: EnvironmentalAssessmentsOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_EnvironmentalAssessment
    }
  },
  {
    path: 'company-chart',
    component: CompanyChartOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_HS_CompanyChart
    }
  },
  {
    path: 'company-chart/:ccId',
    component: CompanyChartOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_HS_CompanyChart
    }
  },
  {
    path: 'company-chart/:ccId/:ccItemId',
    component: CompanyChartOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_HS_CompanyChart
    }
  },
  {
    path: 'environmental-impact-data',
    component: EnvironmentalImpactDataComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_EnvironmentalImpactData
    }
  },
  {
    path: 'risk-assessment-admin',
    component: RiskAssessmentAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Admin
    }
  },
  {
    path: 'environmental-assessment-admin',
    component: EnvironmentalAssessmentAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Admin
    }
  },
  {
    path: 'branch-admin',
    component: BranchAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Admin
    }
  },
  {
    path: 'ps-phrases-admin',
    component: PsPhrasesAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Admin
    }
  },
  {
    path: 'hr-phrases-admin',
    component: HrPhrasesAdminComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Admin
    }
  },
  {
    path: 'documents',
    component: HseDocumentsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.HSE_Documents
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HseRoutingModule {
}
