import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-email-capture',
  templateUrl: './email-capture.component.html',
  styleUrls: ['./email-capture.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    FontAwesomeModule
  ]
})
export class EmailCaptureComponent {
  @Input('EmailString')
  set EmailString(emailString: string) {
    this.emailAddress = emailString;
    this.onChange(this.emailAddress);
  }
  @Input('MaxLength') MaxLength: number = 150;
  @Input('Disabled') Disabled: boolean = false;

  @Output() OnResult: EventEmitter<string> = new EventEmitter();
  @Output() OnValidate: EventEmitter<boolean> = new EventEmitter();

  // Icons
  faInvalid = faXmark;
  faValid = faCheck;

  // Component variables
  emailAddress: string;
  emailArray: string[] = [];
  errorMessages: string[] = [];

  constructor() { }

  validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email.trim());
  }

  onChange(input: string): void {
    const emailInput = input;
    this.emailArray = [];
    this.errorMessages = [];
    if (emailInput?.length > 0) {
      const emailList = emailInput.split(';');
      // Loop and validate each email
      emailList.forEach(email => {
        email = email.trim();
        if (this.validateEmail(email)) {
          this.emailArray.push(email.trim());
        } else {
          this.errorMessages.push(`${email}`);
          this.OnValidate.emit(false);
        }
      });
      // Emit true if all emails are valid
      if (this.errorMessages.length === 0) {
        this.OnValidate.emit(true);
        this.setResult();
      }
    } else {
      this.emailArray = [];
      this.setResult();
    }
  }

  setResult() {
    if (this.emailArray?.length > 0) {
      this.emailAddress = this.emailArray.join(';');
    } else {
      this.emailAddress = '';
    }
    this.OnResult.emit(this.emailAddress);
  }
}
