// Get the total difference between today and date passed in
export function getDayDifference(date: string): number {
  if (date) {
    const today = new Date();
    const myDate = new Date(date);

    // calculate time difference in UTC
    const timeDifference = Date.UTC(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    return Math.floor((timeDifference) / (1000 * 60 * 60 * 24));
  }
  return null;
}

// Returns boolean indicating if the date passed in is greater than x from today
// Returns false if date is invalid
export function isDayGreaterThan(date: string, total: number): boolean {
  const totalDays = getDayDifference(date);
  if (totalDays){
    return totalDays > total;
  }
  return false;
}

// Returns boolean indicating if the date passed in is smaller than x from today
// Returns false if date is invalid
export function isDaySmallerThan(date: string, total: number): boolean {
  const totalDays = getDayDifference(date);
  if (totalDays){
    return totalDays < total;
  }
  return false;
}
