<div class="mt-4 mb-5 pb-5">
  <div class="row ps-2 pe-2">
    <div class="col-12 text-start harmony-grey-text">
      <p class="mb-0">
        <small>
          <fa-icon [icon]="faInfo"></fa-icon> Please add all locations that are part of your RFP. We will link your locations to one of the cities in our database, so we can generate rates that are applicable for this location.
        </small>
      </p>
      <p class="mb-0">
        <small>
          <fa-icon [icon]="faInfo"></fa-icon> Always confirm that the correct city is found for every location. If an incorrect city is selected, please correct this by clearing the auto-populated value and searching manually.
        </small>
      </p>
      <p class="mb-0">
        <small>
          <fa-icon [icon]="faInfo"></fa-icon> Please be patient while auto search features run.
        </small>
      </p>
    </div>
  </div>
  <div class="row m-1 mb-2">
    <div class="col-md-6 text-start ps-0 pt-2">
      <b>Total Locations:</b> {{ getLocations().length }} / {{ locationLimit }}
    </div>
    <div class="col-md-6 text-end pe-0">
      <button
        class="btn btn-sm btn-primary harmony-purple-button"
        [disabled]="disableAddLocation()"
        (click)="addLocation()">
        + Add Locations
      </button>
    </div>
  </div>
  <div class="row ms-1 me-1 location-header">
    <div class="col-md-3 location-header-title border-split-left">
      Name
    </div>
    <div class="col-md-1 location-header-title text-center">
      Origin
    </div>
    <div class="col-md-1 location-header-title text-center">
      Destination
    </div>
    <div class="col-md-2 location-header-title">
      City
    </div>
    <div class="col-md-2 location-header-title">
      Airport
    </div>
    <div class="col-md-2 location-header-title">
      Seaport
    </div>
    <div class="col-md-1 location-header-title text-center border-split-right">

    </div>
  </div>
  <div class="row me-1 ms-1 location-record" *ngFor="let location of getLocations(); let i = index">
    <div class="col-md-3 location-record-cell pt-1">
      <small>{{ i + 1 }}</small>&nbsp;&nbsp; <b>{{location.PartnerLocationName}}</b>
    </div>
    <div class="col-md-1 location-record-cell text-center pt-1 ps-3">
      <div class="form-check form-switch center-switch mb-0">
        <input class="form-check-input d-inline mb-0"
               type="checkbox"
               role="switch"
               [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
               [(ngModel)]="location.IsOrigin">
      </div>
    </div>
    <div class="col-md-1 location-record-cell text-center pt-1 ps-3">
      <div class="form-check form-switch center-switch mb-0">
        <input class="form-check-input d-inline mb-0"
               type="checkbox"
               role="switch"
               [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
               [(ngModel)]="location.IsDestination">
      </div>
    </div>
    <div class="col-md-2 location-record-cell text-nowrap">
      <p class="mb-0 ms-1 overflow-hidden"
         style="text-overflow: ellipsis"
         *ngIf="location.Id && location.CityId && !location.IsEdit">
        <small>{{ location.City?.Name }} ({{ location.City?.InfoName }})</small>
      </p>
      <app-city-search
        *ngIf="location.Id === null || location.CityId === null || location.IsEdit === true"
        class="d-inline"
        [Disabled]="tenderService.IsQuoteAccepted() || location.IsLoading"
        [Identifier]="location.PartnerLocationName"
        [SelectedID]="location.CityId"
        [CityName]="location.PartnerLocationName"
        [ShowError]="location.CityId === null"
        [ErrorColor]="'#A22B9D'"
        (OnSelect)="setCity(location, $event)">
      </app-city-search>
    </div>
    <div class="col-md-2 location-record-cell text-nowrap">
      <P class="mb-0 ms-1 overflow-hidden"
         style="text-overflow: ellipsis"
         [title]="location.AirPort?.Description + ' (' + location.AirPort?.Code + ')'"
         *ngIf="location.Id && location.AirportCityFunctionId && !location.IsEdit">
        <small>{{ location.AirPort?.Description }} ({{ location.AirPort?.Code }})</small>
      </P>
      <app-port-search
        *ngIf="location.Id === null || location.AirportCityFunctionId === null || location.IsEdit === true"
        class="d-inline"
        [Disabled]="this.tenderService.IsQuoteAccepted() || location.IsLoading"
        [Identifier]="'AIR_' + location.Id"
        [SelectedID]="location.AirportCityFunctionId"
        [IsAirport]="true"
        [ShowError]="location.AirportCityFunctionId === null"
        [ErrorColor]="'#A22B9D'"
        (OnSelectObject)="selectAirPort(location, $event)">
      </app-port-search>
    </div>
    <div class="col-md-2 location-record-cell">
      <p class="mb-0 ms-1 overflow-hidden"
         style="text-overflow: ellipsis"
         [title]="location.SeaPort?.Description + ' (' + location.SeaPort?.Code + ')'"
         *ngIf="location.Id && location.SeaPortCityFunctionId && !location.IsEdit">
        <small>{{ location.SeaPort?.Description }} ({{ location.SeaPort?.Code }})</small>
      </p>
      <app-port-search
        *ngIf="location.Id === null || location.SeaPortCityFunctionId === null || location.IsEdit === true"
        class="d-inline"
        [Disabled]="this.tenderService.IsQuoteAccepted() || location.IsLoading"
        [Identifier]="'SEA_' + location.Id"
        [SelectedID]="location.SeaPortCityFunctionId"
        [IsAirport]="false"
        [ShowError]="location.SeaPortCityFunctionId === null"
        [ErrorColor]="'#A22B9D'"
        (OnSelectObject)="selectSeaPort(location, $event)">
      </app-port-search>
    </div>
    <div class="col-1 location-record-cell text-end">
      <ng-container *ngIf="!tenderService.IsQuoteAccepted() && !tenderService.loading">
        <button class="btn btn-sm me-1"
                [ngClass]="{'btn-outline-success': isLocationValid(location), 'btn-primary harmony-purple-button': !isLocationValid(location)}"
                (click)="location.IsEdit = true"
                type="button">
          <fa-icon [icon]="faEdit" size="1x"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-danger"
                *ngIf="location.Active == true"
                (click)="removeLocation(location)"
                type="button">
          <fa-icon [icon]="faBin" size="1x"></fa-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
