<div id="ar-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark" id="headingAR" data-bs-toggle="collapse" data-bs-target="#collapseAR" aria-expanded="true" aria-controls="collapseAR">
      <h5 class="portal-menu-heading">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faAnnualReview"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-teal-text': router.url.includes('review/')}">
          Annual Review
        </span>
      </h5>
    </div>
    <div id="collapseAR" class="collapse" aria-labelledby="headingAR" data-bs-parent="#ar-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="annualReview">
              <a class="nav-link pointer"
                 [routerLink]="['review/member-review']"
                 [ngClass]="{'active': router.url.includes('member-review')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Review
              </a>
            </li>
            <li class="nav-item" *ngIf="audit && auditMenu.IsVisible">
              <a class="nav-link pointer"
                 [routerLink]="['review/audit']"
                 [ngClass]="{'active': router.url.includes('audit')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Audit
              </a>
            </li>
            <li class="nav-item" *ngIf="admin">
              <a class="nav-link pointer"
                 [routerLink]="['review/admin']"
                 [ngClass]="{'active': router.url.includes('review/admin')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Admin
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
