import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { faAdd, faEdit, faFileText, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import {
  IEnvironmentalAssessmentDto,
  IEnvironmentalAssessmentGroup,
  IEnvironmentalAssessmentItem
} from '../../../../services/models/hse/hse.model';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import {
  EnvironmentalAssessmentItemAdminModalComponent
} from './environmental-assessment-item-admin-modal/environmental-assessment-item-admin-modal.component';
import {
  EnvironmentalAssessmentGroupAdminModalComponent
} from './environmental-assessment-group-admin-modal/environmental-assessment-group-admin-modal.component';
import { SharedModule } from '../../../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-environmental-assessment-admin',
  templateUrl: './environmental-assessment-admin.component.html',
  styleUrls: ['./environmental-assessment-admin.component.scss'],
  imports: [
    SharedModule,
    FontAwesomeModule,
    NgClass,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class EnvironmentalAssessmentAdminComponent implements OnInit {
  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  faPlus = faPlus;

  // Component Variables
  environmentalAssessment: IEnvironmentalAssessmentDto;
  environmentalAssessmentGroups: IEnvironmentalAssessmentGroup[];
  environmentalAssessmentGroup: IEnvironmentalAssessmentGroup;
  environmentalAssessmentItems: IEnvironmentalAssessmentItem[];
  environmentalAssessmentItem: IEnvironmentalAssessmentItem;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  searchText: string = null;
  branch: IBranchDto;

  // Permissions
  public readonly PermissionCodes = PermissionCodes;
  isAdmin: boolean = false;

  // Shared Components
  loading: boolean;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              public authService: AdAuthService,
              private alertService: AlertService,
              public router: Router,
              private hseService: HseService) {
  }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.loadAssessmentGroups();
    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
          this.loadAssessmentGroups();
        });
    }
  }

  setPermissions() {
    this.isAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
  }

  getGroupItems(groupId: number): IEnvironmentalAssessmentItem[] {
    return this.environmentalAssessmentItems.filter(x => x.AssessmentGroupId === groupId);
  }

  loadAssessmentGroups() {
    this.loading = true;
    this.hseService.getEnvironmentalAssessmentGroups()
      .subscribe({
        next: (data): void => {
          this.environmentalAssessmentGroups = Object.assign([], data);
          this.hseService.searchAllEnvironmentalAssessmentItems(this.searchText)
            .subscribe({
              next: (data): void => {
                this.environmentalAssessmentItems = Object.assign([], data);
                this.loading = false;
              },
              error: (): void => {
                this.loading = false;
              }
            });
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  openEnvironmentalAssessmentItemModal(assessmentItem: IEnvironmentalAssessmentItem) {
    const modalRef = this.modalService.open(EnvironmentalAssessmentItemAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.environmentalAssessmentItem = assessmentItem;
  }

  addItem(group: IEnvironmentalAssessmentGroup) {
    const modalRef = this.modalService.open(EnvironmentalAssessmentItemAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.environmentalAssessmentItem = {
      Id: 0,
      AssessmentGroupId: group.Id,
      ItemNumber: null,
      Topic: '',
      Risk: null,
      Subject: null,
      Area: null,
      PossibleMeasures: null,
      Active: true,
      IRL: null,
      RRL: null
    } as IEnvironmentalAssessmentItem;
    modalRef.componentInstance.environmentalAssessmentGroup = group;

    // On modal close, read the result and apply logic
    modalRef.result.then((environmentalAssessmentItem: IEnvironmentalAssessmentItem) => {
      if (environmentalAssessmentItem.Id > 0) {
        this.environmentalAssessmentItem = environmentalAssessmentItem;
        this.environmentalAssessmentItems.push(this.environmentalAssessmentItem);
      }
    });
  }

  addGroup() {
    const modalRef = this.modalService.open(EnvironmentalAssessmentGroupAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.environmentalAssessmentGroup = {
      Id: null,
      Description: null
    } as IEnvironmentalAssessmentGroup;

    // On modal close, read the result and apply logic
    modalRef.result.then(() => {
      this.loadAssessmentGroups();
    });
  }

  deleteEnvironmentalAssessmentItem(environmentalAssessmentItem: IEnvironmentalAssessmentItem) {
    const message = 'Are you sure you want to delete this Environmental assessment item?';

    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (environmentalAssessmentItem != null) {
            this.loading = true;
            environmentalAssessmentItem.Active = false;
            this.hseService.updateEnvironmentalAssessmentItem(environmentalAssessmentItem)
              .subscribe({
                next: () => {
                  this.alertService.info('Environmental assessment item ' + environmentalAssessmentItem.ItemNumber + ' successfully deleted.');
                  this.loadAssessmentGroups();
                  this.loading = false;
                },
                error: () => { }
              });
          }
        }
      });
  }

  searchItems() {
    this.loadAssessmentGroups();
  }

  resetSearch() {
    this.searchText = null;
    this.loadAssessmentGroups();
  }

  editGroup(group: IEnvironmentalAssessmentGroup) {
    const modalRef = this.modalService.open(EnvironmentalAssessmentGroupAdminModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.environmentalAssessmentGroup = group;
    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.loadAssessmentGroups();
      }
    }, () => { });
  }

  deleteGroup(groupId: number) {
      const message = 'Are you sure you want to delete this Environmental assessment group ?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer) => {
          if (answer) {
            if (groupId != null) {
              this.loading = true;
              this.hseService.deleteEnvironmentalAssessmentGroup(groupId)
                .subscribe({
                  next: () => {
                    this.loadAssessmentGroups();
                    this.loading = false;
                  },
                  error: () => { }
                });
            }
          }
        });
  }
}
