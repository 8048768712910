import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { IEnvironmentalAssessmentList, IEnvironmentalAssessmentReport } from '../../../../services/models/hse/hse.model';

@Component({
  selector: 'app-environmental-assessment-report-modal',
  templateUrl: './environmental-assessment-report-modal.component.html',
  styleUrls: ['./environmental-assessment-report-modal.component.scss']
})
export class EnvironmentalAssessmentReportModalComponent implements OnInit, OnDestroy {

  // Icons

  // Component Variables
  environmentalAssessment: IEnvironmentalAssessmentList;
  environmentReport: IEnvironmentalAssessmentReport;
  loading: boolean = false;

  // Shared Components

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService) {
  }

  ngOnInit() {
    this.createReport()
  }

  ngOnDestroy(): void {
  }

  createReport(){

    this.loading = true;

    this.hseService.getEnvironmentalAssessmentReport(this.environmentalAssessment.Id)
      .subscribe({
        next: (data) => {
          this.environmentReport = Object.assign([], data);
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Failed to get the Environmental Risk Assessment report');
          this.loading = false;
        }
      });
  }

  // PrintReport() {
  //   window.setTimeout('window.print()', 600);
  // }

  close() {
    this.activeModal.close();
  }

}
