import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPage } from 'src/app/shared/models/pagination-data.model';
import { ApiService } from './api.service';
import { AnnualReviewCloneRequest, IAnnualReviewCreateRequest, IAnnualReviewUpdateRequest } from './models/annual-review/annual-review.model';
import { IAnswerUpsertRequest } from './models/annual-review/annual-review-answer.model';
import { IChapterCreateRequest, IChapterUpdateRequest } from './models/annual-review/annual-review-chapter.model';
import { ICompanyReviewAuditRequest, ICompanyReviewFilterRequest, ICompanyReviewRequest } from './models/annual-review/annual-review-company-review.model';
import { IQuestionOptionDeleteRequest, IQuestionRequest, IQuestionUpdateRequest } from './models/annual-review/annual-review-question.model';
import { IAuditAnswerRequest, IAuditAnswerSubmitRequest } from './models/annual-review/annual-review-audit-answer';
import { IAuditQuestionRequest, IAuditQuestionUpdateRequest } from './models/annual-review/annual-review-audit-question';

@Injectable({
  providedIn: 'root'
})
export class AnnualReviewService implements OnDestroy {

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getAllAnnualReviews(page: IPage) {
    return this.apiService.get(`AnnualReview?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getAnnualReviewSearchFilter() {
    return this.apiService.get('AnnualReview/Search').pipe(
      takeUntil(this.unsubscribe));
  }

  addAnnualReview(body: IAnnualReviewCreateRequest) {
    return this.apiService.post(`AnnualReview`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updateAnnualReview(body: IAnnualReviewUpdateRequest) {
    return this.apiService.put(`AnnualReview`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  cloneAnnualReview(reviewId: number, reviewIdToClone: number) {
    const request = {
      ReviewId: reviewId,
      ReviewIdToClone: reviewIdToClone
    } as AnnualReviewCloneRequest;

    return this.apiService.post(`AnnualReview/Clone`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteAnnualReview(reviewId: number) {
    return this.apiService.delete(`AnnualReview/${reviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // AR QUESTIONS ----------------------------------------------------------------------------------
  getQuestion(questionId: number) {
    return this.apiService.get(`AnnualReviewQuestion/${questionId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getQuestions(reviewId: number) {
    return this.apiService.get(`AnnualReviewQuestion/GetAllByReviewId/${reviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  addQuestion(request: IQuestionRequest) {
    return this.apiService.post('AnnualReviewQuestion', request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updateQuestion(request: IQuestionUpdateRequest) {
    return this.apiService.put('AnnualReviewQuestion', request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteQuestion(questionId: number) {
    return this.apiService.delete(`AnnualReviewQuestion/${questionId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteQuestionOption(optionId: number) {
    const request = {
      Id: optionId
    } as IQuestionOptionDeleteRequest;
    return this.apiService.post('AnnualReviewQuestion/Option', request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getSearchFields(reviewId: number){
    return this.apiService.get(`AnnualReviewQuestion/Search/${reviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // AUDIT ANSWER ------------------------------------------------------------------------------------
  isAuditMenuItemVisible(companyId: number){
    return this.apiService.get(`AnnualReviewAuditAnswer/visibility/${companyId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getAudit(reviewId: number, companyReviewId: number) {
    return this.apiService.get(`AnnualReviewAuditAnswer/${reviewId}/${companyReviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  upsertAnswers(request: IAuditAnswerRequest){
    return this.apiService.put(`AnnualReviewAuditAnswer`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }


  submitAudit(request: IAuditAnswerSubmitRequest){
    return this.apiService.post(`AnnualReviewAuditAnswer/submit`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // AUDIT DOCUMENT ----------------------------------------------------------------------------------
  getNetworkDocument(reviewId: number, companyReviewId: number, companyId: number){
    return this.apiService.getFile(`AnnualReviewDocument/Network/${reviewId}/${companyReviewId}/${companyId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getCompanyDocument(reviewId: number, companyReviewId: number, companyId: number){
    return this.apiService.getFile(`AnnualReviewDocument/Company/${reviewId}/${companyReviewId}/${companyId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getAuditDocument(reviewId: number, companyReviewId: number, companyId: number){
    return this.apiService.getFile(`AnnualReviewDocument/Audit/${reviewId}/${companyReviewId}/${companyId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  downloadExcelExportData(reviewId: number){
    return this.apiService.getFile(`AnnualReviewDocument/ExportData/${reviewId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  // AUDIT QUESTIONS ----------------------------------------------------------------------------------
  getAuditQuestions(reviewId: number) {
    return this.apiService.get(`AnnualReviewAuditQuestion/${reviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  addAuditQuestion(request: IAuditQuestionRequest) {
    return this.apiService.post(`AnnualReviewAuditQuestion`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updateAuditQuestion(request: IAuditQuestionUpdateRequest) {
    return this.apiService.put(`AnnualReviewAuditQuestion`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteAuditQuestion(auditQuestionId: number) {
    return this.apiService.delete(`AnnualReviewAuditQuestion/${auditQuestionId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  uploadDocument(file: any, companyReviewId: number, auditQuestionId: number, reviewId: number) {
    const formData = new FormData();
    formData.append('File', file, file.name);
    formData.append('CompanyReviewId', companyReviewId.toString());
    formData.append('AuditQuestionId', auditQuestionId.toString());
    formData.append('ReviewId', reviewId.toString());

    return this.apiService.post('AnnualReviewDocument', formData).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteAuditDocument(auditDocumentId: number) {
    return this.apiService.delete(`AnnualReviewDocument/${auditDocumentId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // AR ANSWERS ----------------------------------------------------------------------------------
  getAnswers(reviewId: number, companyReviewId: number) {
    return this.apiService.get(`AnnualReviewAnswer/${reviewId}/${companyReviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  upsertAnswer(answers: IAnswerUpsertRequest){
    return this.apiService.put(`AnnualReviewAnswer`, answers).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  submitAnswer(companyReviewId: number){
    return this.apiService.post(`AnnualReviewAnswer/${companyReviewId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // AR CHAPTERS ----------------------------------------------------------------------------------
  getChapter(chapterId: number) {
    return this.apiService.get(`AnnualReviewChapter/${chapterId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getChapters(reviewId: number) {
    return this.apiService.get(`AnnualReviewChapter/All/${reviewId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  addChapter(request: IChapterCreateRequest) {
    return this.apiService.post(`AnnualReviewChapter`, request).pipe(
      takeUntil(this.unsubscribe));
  }

  updateChapter(request: IChapterUpdateRequest) {
    return this.apiService.put(`AnnualReviewChapter`, request).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteChapter(chapterId: number) {
    return this.apiService.delete(`AnnualReviewChapter/${chapterId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  // AR COMPANY REVIEW ----------------------------------------------------------------------------------
  filterCompanyReviews(body: ICompanyReviewFilterRequest) {
    return this.apiService.post(`AnnualReviewCompanyReview/Filter`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getCompanyReviews(companyId: number) {
    return this.apiService.get(`AnnualReviewCompanyReview/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  addCompanyReviews(request: ICompanyReviewRequest){
    return this.apiService.post(`AnnualReviewCompanyReview/`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  toggleCompanyReviewAudit(request: ICompanyReviewAuditRequest){
    return this.apiService.post(`AnnualReviewCompanyReview/ToggleAudit`, request).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
