<div class="row">
  <div class="col-6">
    <div class="row mt-2">
      <div class="col-4 align-self-center">
        <b>Mode</b>
      </div>
      <div class="col-6">
        {{ RateGroup.ModalityTypeName ?? '-' }}
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 align-self-center">
        <b>Currency</b>
      </div>
      <div class="col-6">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(Currencies$ | async)"
                [(ngModel)]="selectedCurrency"
                [ngClass]="{'required': selectedCurrency == null}"
                (ngModelChange)="setCurrency()">
          <option [ngValue]="null">Select currency...</option>
          <option *ngFor="let currency of (Currencies$ | async)" [ngValue]="currency">{{ currency.Currency.Code }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 align-self-center">
        <b>Primary Qty</b>
      </div>
      <div class="col-3">
        <input type="text"
               class="form-control form-control-sm"
               [placeholder]="'0'"
               numbersOnly="6.2"
               [ngClass]="{'required': testParam.Quantity_1 == null || testParam.Quantity_1 <= 0}"
               [(ngModel)]="testParam.Quantity_1">
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(UnitTypes$ | async)"
                [ngClass]="{'required': testParam.RateUnitTypeID_1 == null}"
                [(ngModel)]="testParam.RateUnitTypeID_1">
          <option [ngValue]="null">None...</option>
          <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 align-self-center">
        <b class="mb-0">Secondary Qty</b>
        <br>
        <small class="font-italic text-muted position-absolute"
               style="margin-top: -5px;">
          (optional)
        </small>
      </div>
      <div class="col-3">
        <input type="text"
               class="form-control form-control-sm"
               [placeholder]="'0'"
               numbersOnly="6.2"
               [(ngModel)]="testParam.Quantity_2">
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(UnitTypes$ | async)"
                [(ngModel)]="testParam.RateUnitTypeID_2">
          <option [ngValue]="null">None...</option>
          <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row mt-2">
      <div class="col-6">
        <button type="button"
                class="btn btn-sm btn-primary harmony-teal-button w-100"
                [disabled]="!canRunTest()"
                (click)="runTest()">
          Get Rate
        </button>
      </div>
      <div class="col-6 align-self-center text-end">
        <b class="text-black">{{ selectedCurrency ? selectedCurrency.Currency.Code : '-' }}</b> {{ testResult.Rate | number }}
      </div>
    </div>
    <div class="row mt-1" *ngIf="testResult.Comment">
      <div class="col-12 align-self-center text-end harmony-grey-text">
        {{ testResult.Comment }}
      </div>
    </div>
    <hr>
    <div class="row mt-2">
      <div class="col-12 text-start harmony-grey-text">
        <small>
          <b>Note:</b> Please save any changes before testing the rates. Changes made to the rates will not be included in the calculations if they have not been saved.
        </small>
      </div>
    </div>
  </div>
</div>
