/// NEWS ///
export class NewsRoutes {
  static readonly Article_Read = '/news/article';
  static readonly Article_Editor = '/news/article-editor';
  static readonly Article_Network = '/news/articles';
  static readonly Article_Management = '/news/article-management';
  static readonly Article_Review = '/news/article-review';
  static readonly Article_ReviewCompare = '/news/article-review/compare';
}

export class HSERoutes {
  static readonly Incident_Improvement = '/hse/incident-improvement';
}

/// Integration HUB ///
export class HubRoutes {
  static readonly Message_Log = '/hub/message-log/';
}
