import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  IHubClient,
  IHubClientSystemOverview,
  IHubLookupType, IHubProcessStatus,
  IInitConditionType
} from './models/hub/hub.model';
import {
  IHubElement,
  IHubLinkedSchema,
  IHubLinkSchemaElement,
  IHubSchema,
  IHubSchemaType
} from './models/hub/schema.model';
import {
  IHubClientValue,
  IHubCreateDefaultValue,
  IHubCreateMapping, IHubDefaultValue,
  IHubDefaultValueParam,
  IHubDeleteDefaultValue, IHubValue
} from './models/hub/hub-values.model';
import { IPage, IPaginationData } from '../shared/models/pagination-data.model';
import { IOrderParam } from '../shared/directives/sort/order.directive';
import { IHubValueMappingOverview, IHubValueMappingSearchParam } from './models/hub/hub-mapping.model';
import {
  EditValueListModalComponent
} from '../modules/hub/default-values/edit-value-list-modal/edit-value-list-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../core/constants/permission-codes';
import { IAuthUser } from './models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class HubAdminService implements OnDestroy {
  // Variables
  public Clients$: BehaviorSubject<IHubClient[]> = new BehaviorSubject<IHubClient[]>([]);
  public ClientSystemOverview$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  public SchemaTypes$: BehaviorSubject<IHubSchemaType[]> = new BehaviorSubject<IHubSchemaType[]>([]);
  public LinkedSchemaTypes$: BehaviorSubject<IHubLinkedSchema[]> = new BehaviorSubject<IHubLinkedSchema[]>([]);
  public LookupTypes$: BehaviorSubject<IHubLookupType[]> = new BehaviorSubject<IHubLookupType[]>([]);
  public InitConditionTypes$: BehaviorSubject<IInitConditionType[]> = new BehaviorSubject<IInitConditionType[]>([]);
  public ProcessStatuses$: BehaviorSubject<IHubProcessStatus[]> = new BehaviorSubject<IHubProcessStatus[]>([]);

  // General Variables
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public router: Router,
              private api: ApiService,
              private auth: AdAuthService,
              private modalService: NgbModal) {
    // Load lookups
    this.getClients();
    this.getSchemaTypes();
    this.getLinkedSchemaTypes();
    this.getInitConditionTypes();
    this.getLookupTypes();
    this.getProcessStatuses();
    this.getClientSystemOverview();

    this.auth.CurrentUser$.subscribe({
      next: (user: IAuthUser) => {
        this.getClientSystemOverview();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ///////////////////////
  // Schema & Mappings //
  ///////////////////////

  public GetSchema(schemaTypeId: number): Observable<IHubSchema[]> {
    return this.api.get(`Schema/GetSchema/${schemaTypeId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateMapping(param: IHubCreateMapping) {
    return this.api.post('Hub/Values/CreateMapping', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  /////////////////////////////
  // Values & Default Values //
  /////////////////////////////

  public SearchValueMappings(param: IHubValueMappingSearchParam, page: IPage, order: IOrderParam): Observable<IPaginationData<IHubValueMappingOverview>> {
    const p = page;
    const o = order;

    return this.api.post(`Hub/Values/SearchMappings?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetValueItem(id: number): Observable<IHubValue> {
    return this.api.get(`Hub/Values/VL/${id}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public EditValue$(valueListId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // Open modal to edit a value list item
      const modalRef = this.modalService.open(EditValueListModalComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.ValueListId = valueListId;
      modalRef.componentInstance.LoadValueListItem();
      // On modal close, resolve the promise
      modalRef.result.then((result: boolean) => {
          resolve(result);
      });
    });
  }

  public UpdateValueList(valueListId: number, newValue: string): Observable<void> {
    return this.api.put(`Hub/Values/VL/${valueListId}/${newValue}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateDefaultValue(param: IHubCreateDefaultValue): Observable<string> {
    return this.api.post('Hub/Values/DefaultValue/Create', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public SearchDefaultValues(param: IHubDefaultValueParam, page: IPage, order: IOrderParam): Observable<IPaginationData<IHubDefaultValue>> {
    return this.api.post(`Hub/Values/DefaultValue/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public DeleteDefaultValue(param: IHubDeleteDefaultValue): Observable<void> {
    return this.api.post('Hub/Values/DefaultValue/Delete', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ClientSystemValues(systemId: number, clientId: number): Observable<IHubClientValue[]> {
    return this.api.get(`Hub/Values/SystemValues/${systemId}/${clientId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ClientElementValues(systemId: number, clientId: number, elementId: number): Observable<IHubClientValue[]> {
    return this.api.get(`Hub/Values/ElementValues/${systemId}/${clientId}/${elementId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  /////////////
  // Element //
  /////////////
  public GetClientsBySystem(systemId: number, isSourceSystem: boolean): Observable<IHubClient[]> {
    return this.api.get(`Hub/Lookup/SystemClients/${systemId}/${isSourceSystem}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetTargetSystemsByElement(elementId: number, isSourceSystem: boolean) {
    return this.api.get(`Hub/Lookup/SystemsByElement/${elementId}/${isSourceSystem}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetSystemElements(systemId: number): Observable<IHubElement[]> {
    return this.api.get(`Hub/Lookup/SystemElements/${systemId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetMappedSystemElements(systemId: number): Observable<IHubElement[]> {
    return this.api.get(`Hub/Lookup/SystemElements/Mapped/${systemId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetElement(elementId: number): Observable<IHubElement> {
    return this.api.get(`Hub/Element/${elementId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetElementsBySchemaType(schemaTypeId: number): Observable<IHubElement[]> {
    return this.api.get(`Hub/Element/BySchemaType/${schemaTypeId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetTargetElements(sourceElementId: number): Observable<IHubLinkSchemaElement[]> {
    return this.api.get(`Hub/Element/TargetElements/${sourceElementId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  /////////////
  // Lookups //
  /////////////
  getClients(): void {
    this.api.get('Hub/Lookup/Clients').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubClient[]) => {
      this.Clients$.next(Object.assign([], data));
    });
  }

  getClientSystemOverview(): void {
    const hasPermission = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);
    this.api.get(`Hub/Lookup/CSO/${hasPermission}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubClientSystemOverview[]) => {
      this.ClientSystemOverview$.next(Object.assign([], data));
    });
  }

  getLookupTypes(): void {
    this.api.get('Hub/Lookup/LookupTypes').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubLookupType[]) => {
      this.LookupTypes$.next(Object.assign([], data));
    });
  }

  getProcessStatuses(): void {
    this.api.get('Hub/Message/ProcessStatuses').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubProcessStatus[]) => {
      this.ProcessStatuses$.next(Object.assign([], data));
    });
  }

  getInitConditionTypes(): void {
    this.api.get('Hub/Lookup/InitConditionTypes').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IInitConditionType[]) => {
      this.InitConditionTypes$.next(Object.assign([], data));
    });
  }

  getSchemaTypes(): void {
    this.api.get('Hub/Schema/SchemaTypes').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubSchemaType[]) => {
      this.SchemaTypes$.next(Object.assign([], data));
    });
  }

  getLinkedSchemaTypes(): void {
    this.api.get('Hub/Schema/LinkedSchemas').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IHubLinkedSchema[]) => {
      this.LinkedSchemaTypes$.next(Object.assign([], data));
    });
  }
}
