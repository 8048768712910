import { Component } from '@angular/core';
import {
  faCalendar,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import {
  IRateRequestTemplateDto,
  IRateRequestTemplateInclusionDto, IRateRequestTemplateServiceDto, IRateRequestTemplateServiceRateDto
} from '../../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../../services/pricing.service';
import { AdAuthService } from '../../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../../services/alert.service';
import { ApiService } from '../../../../../services/api.service';
import {
  OpenConfirmationModal
} from '../../../../../shared/components/confirmation-modal/confirmation-modal-functions';

@Component({
  selector: 'app-request-template-edit-modal',
  templateUrl: './request-template-edit-modal.component.html',
  styleUrls: ['./request-template-edit-modal.component.scss']
})
export class RequestTemplateEditModalComponent {
  // Icons
  faPlus = faPlus;
  faDelete = faTrash;
  faInfo = faCircleQuestion;
  faCalendar = faCalendar;

  // Component variables
  public TemplateId: number = null;
  requestTemplate: IRateRequestTemplateDto;
  requestInclusions: IRateRequestTemplateInclusionDto[] = [];
  activeRateGroupServiceId: number = null;
  projectId: number = null;

  // Lookup data
  Currencies$ = this.pricingService.Currencies$.asObservable();
  UnitTypes$ = this.pricingService.UnitTypes$.asObservable();
  RateLimitTypes$ = this.pricingService.RateLimitTypes$.asObservable();
  CalculationMethods$ = this.pricingService.CalculationMethods$.asObservable();
  ServiceTypes$ = this.pricingService.ServiceTypes$.asObservable();
  RateVerdicts$ = this.pricingService.RateVerdicts$.asObservable();
  RateSources$ = this.pricingService.RateSources$.asObservable();
  ModalityTypes$ = this.pricingService.ModalityTypes$.asObservable();
  Projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              public authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private api: ApiService) { }

  ngOnInit() {}

  // Set the TemplateId then call this function when creating the modal instance.
  // e.g.
  // modalRef.componentInstance.TemplateId = rateRequestTemplateId;
  // modalRef.componentInstance.LoadRequestTemplate();
  public LoadRequestTemplate() {
    this.loading = true;

    this.api.get('RateRequestTemplate/Single/' + this.TemplateId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.requestTemplate = Object.assign({} as IRateRequestTemplateDto, data);
          this.projectId = this.requestTemplate.ProjectId;
          this.loadInclusions();
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to retrieve data for rate request template ID: ' + this.TemplateId);
        this.loading = false;
      }
    });
  }

  public LoadNewRequestTemplate() {
    const awaitingRates = this.pricingService.RateVerdicts$.getValue().find(x => x.Name == "Awaiting Rates");
    this.projectId = null;
    this.requestInclusions = [];

    this.requestTemplate = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RateRequestTypeId: null,
      RateRequestTypeName: null,
      ProjectId: null,
      ProjectName: null,
      ModalityTypeId: null,
      ModalityTypeName: null,
      IsOrigin: false,
      IsDestination: false,
      RateBuySellTypeId: null,
      RateBuySellTypeName: null,
      RateSourceId: null,
      RateSourceName: null,
      RateVerdictId: awaitingRates.Id,
      RateVerdictName: awaitingRates.Name,
      RateRequestTemplateServices: []
    } as IRateRequestTemplateDto;
  }

  loadInclusions() {
    this.api.get('RateRequestTemplate/Inclusions/' + this.TemplateId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.requestInclusions = Object.assign([] as IRateRequestTemplateInclusionDto[], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to retrieve data for rate request template ID: ' + this.TemplateId);
        this.loading = false;
      }
    });
  }

  toggleActiveRateGroupService(rateGroupServiceID: number) {
    if (this.activeRateGroupServiceId == null) {
      this.activeRateGroupServiceId = rateGroupServiceID;
    } else if (this.activeRateGroupServiceId === rateGroupServiceID) {
      this.activeRateGroupServiceId = null;
    } else {
      this.activeRateGroupServiceId = rateGroupServiceID;
    }
  }

  newRateGroupService() {
    const newRequestTemplateService = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RateRequestTemplateId: this.requestTemplate.Id,
      ServiceTypeId: null,
      ServiceTypeName: null,
      CalculationMethodId: null,
      CalculationMethodName: null,
      RateRequestTemplateServiceRates: []
    } as IRateRequestTemplateServiceDto;

    this.requestTemplate.RateRequestTemplateServices.push(newRequestTemplateService);
    this.newServiceRate(newRequestTemplateService);
  }

  newServiceRate(requestTemplateService: IRateRequestTemplateServiceDto) {
    const newServiceRate = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RateRequestTemplateServiceId: requestTemplateService.Id,
      RateLimitTypeId: null,
      RateLimitTypeName: null,
      RateUnitTypeId1: null,
      RateUnitType1Name: null,
      RateUnitType1Abbreviation: null,
      RateQuantity1: null,
      MeasurementUnitTypeId1: null,
      MeasurementUnitType1Name: null,
      MeasurementUnitType1Abbreviation: null,
      RateUnitTypeId2: null,
      RateUnitType2Name: null,
      RateUnitType2Abbreviation: null,
      RateQuantity2: null,
      MeasurementUnitTypeId2: null,
      MeasurementUnitType2Name: null,
      MeasurementUnitType2Abbreviation: null,
      CurrencyId: null,
      CurrencyName: null,
      CurrencyCode: null
    } as IRateRequestTemplateServiceRateDto;

    requestTemplateService.RateRequestTemplateServiceRates.push(newServiceRate);
  }

  removeTemplateService(templateService: IRateRequestTemplateServiceDto) {
    if (templateService) {
      const message = 'Are you sure you want to re-activate this rate? \n A save is required for changes to be applied.';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer == true) {
            templateService.Active = false;
            templateService.RateRequestTemplateServiceRates.forEach((s) => s.Active = false);
          }
        });
    }
  }

  enableTemplateService(templateService: IRateRequestTemplateServiceDto) {
    if (templateService) {
      const message = 'Are you sure you want to de-activate this service? \n A save is required for changes to be applied.';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer == true) {
            templateService.Active = true;
            templateService.RateRequestTemplateServiceRates.forEach((s) => s.Active = true);
          }
        });

    }
  }

  removeServiceRate(serviceRate: IRateRequestTemplateServiceRateDto) {
    if (serviceRate) {
      const message = 'Are you sure you want to de-activate this rate? Please save for changes to be applied.';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer == true) {
            serviceRate.Active = false;
          }
        });
    }
  }

  activateServiceRate(serviceRate: IRateRequestTemplateServiceRateDto) {
    if (serviceRate) {
      const message = 'Are you sure you want to re-activate this rate? Please save for changes to be applied.';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer == true) {
            serviceRate.Active = true;
          }
        });
    }
  }

  addInclusion() {
    const newInclusion = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RateRequestTemplateId: this.requestTemplate.Id,
      Description: null,
      IsIncluded: true,
      StartDate: new Date(),
      EndDate: new Date(),
      Order: 0
    } as IRateRequestTemplateInclusionDto;

    this.requestInclusions.push(newInclusion);
    this.sortInclusions();
  }

  sortInclusions() {
    this.requestInclusions = this.requestInclusions.sort((a, b) => a.Order - b.Order).sort((a,b) => a.IsIncluded > b.IsIncluded ? -1 : 1);
  }

  removeInclusion(inclusion: IRateRequestTemplateInclusionDto, index: number) {
    if (inclusion.Id == null) {
      this.requestInclusions.splice(index, 1);
    } else {
      inclusion.Active = !inclusion.Active;
    }
  }

  disableSave() {
    let result: boolean;
    const t = this.requestTemplate;

    if (t && t.RateRequestTemplateServices) {
      const invalidRgs = t.RateRequestTemplateServices.filter((rgs) => rgs.ServiceTypeId == null);
      const invalidInclusions = this.requestInclusions.filter((incl) => (incl.Description == null || incl.Description.length <= 0));

      result = (
        this.loading ||
        (invalidInclusions && invalidInclusions.length > 0) ||
        (invalidRgs && invalidRgs.length > 0) ||
        (t.Description == null || t.Description.length <= 0) ||
        t.RateRequestTypeId == null ||
        t.RateBuySellTypeId == null ||
        t.RateSourceId == null
      );
    } else {
      result = true;
    }

    return result;
  }

  close() {
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.close(false);
  }

  save() {
    this.loading = true;

    // Save primary object first
    this.api.post('RateRequestTemplate/Upsert', this.requestTemplate).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestTemplateDto) => {
        this.requestTemplate = Object.assign({}, data);
        if (this.requestInclusions && this.requestInclusions.length > 0) {
          const id = data.Id;
          // Set inclusions Template ID's
          this.requestInclusions.forEach((incl) => {
            if (incl.RateRequestTemplateId == null) {
              incl.RateRequestTemplateId = id;
            }
          });
          this.saveInclusions();
        } else {
          this.alertService.success('Template successfully saved!');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.success('An error has occurred while attempting to save the template.');
      }
    });
  }

  saveInclusions() {
    this.loading = true;

    // Save primary object first
    this.api.post('RateRequestTemplate/UpdateInclusions', this.requestInclusions).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestTemplateInclusionDto[]) => {
        this.requestInclusions = Object.assign([], data)
        this.alertService.success('Template successfully saved!');
      },
      error: () => {
        this.alertService.success('An error has occurred while attempting to save the template.');
      }
    });
  }
}
