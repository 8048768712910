import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdAuthService } from '../ad-auth-service/ad-auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AdAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // Check for error code in response
      if ([401, 403].includes(err.status) && this.authService.IsLoggedIn) {
        // auto logout if 401 or 403 response returned from api
        this.authService.Logout();
      }
      // Bad Request
      if ([400].includes(err.status) || [500].includes(err.status) || [409].includes(err.status)) {
        console.error(err.error);
        return throwError(err);
      }

      const error = (err && err.error && err.error.message) || err.statusText;
      console.error(err);
      return throwError(error);
    }));
  }
}
