import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '../../../../../services/file.service';
import { IDocument, IDocumentUpdateParam } from '../../../../../services/models/document/document.model';
import { DocumentService } from '../../../../../services/document.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../../services/alert.service';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../../../../../services/company.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-document-edit-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss']
})
export class DocumentModalComponent implements AfterViewChecked {
  @ViewChild('fileUploader') fileUploader: ElementRef;

  // Icons
  protected readonly faDelete = faTrash;
  protected readonly faDownload = faDownload;

  // Component variables
  public DocumentId: number;
  document: IDocumentUpdateParam;
  documentData: IDocument;
  file: any = null;
  fileSize: number = null;
  documentCategories$ = this.documentService.documentCategories$.asObservable();
  companies$ = this.companyService.ActiveMembers$.asObservable();
  invalid: boolean = false;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private documentService: DocumentService,
              private fileService: FileService,
              private companyService: CompanyService,
              private cdRef: ChangeDetectorRef,
              private alertService: AlertService) { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public SetDocument() {
    if (this.DocumentId && this.DocumentId > 0) {
      this.documentService.getDocument(this.DocumentId).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IDocument) => {
          this.documentData = Object.assign({}, data);
          this.document = {
            Id: data.Id,
            CompanyId: data.CompanyId,
            DisplayName: data.DisplayName,
            DocumentCategoryId: data.DocumentCategoryId,
            Description: data.Description,
            FileName: data.FileName,
            DocumentUserId: data.DocumentUserId
          } as IDocumentUpdateParam;
          this.getFileSize(data.FileName);
        },
        error: err => {
          this.alertService.error(err.Message);
        }
      });
    } else {
      this.document = {
        Id: null,
        CompanyId: null,
        DisplayName: null,
        DocumentCategoryId: null,
        Description: null,
        FileName: null,
        DocumentUserId: null
      } as IDocumentUpdateParam;
    }
  }

  getFileSize(fileName: string) {
    this.fileService.GetFileSize('documents', fileName)
      .subscribe({
        next: (data) => {
          this.fileSize = data / 1024 / 1024;
        }
      });
  }

  setFile(event: any) {
    const file = event.target.files[0];
    this.loading = true;
    // Limit file size to 5mb
    this.fileSize = file.size / 1024 / 1024;
    if (this.fileSize > 5) {
      this.loading = false;
      this.alertService.warn('The selected file is bigger than 5MB, please select another file');
      this.fileUploader.nativeElement.value = '';
      event.target.value = null;
      this.fileSize = 0;
    } else {
      this.loading = false;
      this.file = file;
      this.document.FileName = file.name;
    }
  }

  setCompanyId(id: number) {
    this.document.CompanyId = id;
  }

  setUserId(id: number) {
    this.document.DocumentUserId = id;
  }

  create() {
    if (this.canCreate()) {
    this.documentService.createDocument(this.document, this.file)
      .subscribe({
        next: (data: IDocument) => {
          this.document.Id = data.Id;
          this.alertService.success('Document/File has been successfully uploaded!');
          this.activeModal.close(data);
        },
        error: err => {
          this.alertService.warn('an error occurred while uploading the document: ' + err.Message);
        }
      });
    } else {
      this.invalid = true;
    }
  }

  update() {
    if (this.canUpdate()) {
    this.documentService.updateDocument(this.document, this.file)
      .subscribe({
        next: (data: IDocument) => {
          this.alertService.success('Document/File has been successfully uploaded!');
          this.activeModal.close(data);
        },
        error: err => {
          this.alertService.warn('an error occurred while uploading the document: ' + err.Message);
        }
      });
    } else {
      this.invalid = true;
    }
  }

  canCreate() {
    return !!(this.document.DisplayName?.length > 3 &&
      this.document.Description?.length > 0 &&
      this.document.DocumentCategoryId != null &&
      this.file != null)
  }

  canUpdate() {
    return !!(this.document.DisplayName?.length > 3 &&
      this.document.Description?.length > 0 &&
      this.document.DocumentCategoryId != null)
  }

  close() {
    this.activeModal.dismiss();
  }

  protected readonly env = environment;
}
