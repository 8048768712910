import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IHubCreateMapping,
  IHubMissingValue, IHubMissingValueMessage, IHubMissingValueMessageParam
} from '../../../../services/models/hub/hub-values.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import { HubAdminService } from '../../../../services/hub-admin.service';
import { IHubElement } from '../../../../services/models/hub/schema.model';
import { faArrowRight, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { HubRoutes } from '../../../../core/constants/routes';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { environment } from '../../../../../environments/environment';
import { IHubClientSystemOverview } from '../../../../services/models/hub/hub.model';

@Component({
  selector: 'app-map-missing-value-modal',
  templateUrl: './map-missing-value-modal.component.html',
  styleUrls: ['./map-missing-value-modal.component.scss']
})
export class MapMissingValueModalComponent implements OnInit {
  protected readonly faDirection = faArrowRight;
  protected readonly faView = faEye;
  protected readonly faDelete = faTrash;
  protected readonly faEdit = faEdit;

  // Component variables
  public MissingValue: IHubMissingValue;
  public UserClients: IHubClientSystemOverview[];
  newMapping: IHubCreateMapping;
  targetElement: IHubElement = null;
  messages: IHubMissingValueMessage[] = [];
  targetClients: any[];
  invalid: boolean = false;
  timezone: string = '';

  // General variables
  protected readonly PermissionCodes = PermissionCodes;
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  env = environment;

  constructor(private api: ApiService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private router: Router,
              private hubAdmin: HubAdminService) { }

  ngOnInit(): void {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.newMapping = {
      SourceValue: this.MissingValue.SourceValue,
      SourceElementId: this.MissingValue.SourceElementId,
      SourceClientId: this.MissingValue.SourceClientId,
      TargetValue: null,
      TargetElementId: this.MissingValue.TargetElementId,
      TargetClientId: this.MissingValue.TargetClientId
    } as IHubCreateMapping;

    this.loadTargetElement();
    this.findMessages();
    this.setTargetClients();
  }

  loadTargetElement() {
    this.loading = true;

    this.hubAdmin.GetElement(this.MissingValue.TargetElementId).subscribe({
      next: (data: IHubElement) => {
        this.targetElement = Object.assign({}, data);
        this.loading = false;
      },
      error: () => {
        this.targetElement = null;
        this.loading = false;
      }
    });
  }

  setTargetClients() {
    const targetSystemId = this.MissingValue.TargetSystemId;

    this.targetClients = this.UserClients.reduce(function(filtered, option) {
      let exists = filtered.find(x => x.ClientId == option.TargetClientId)
      if (option.TargetSystemId === targetSystemId && !exists) {
        let someNewValue = { ClientId: option.TargetClientId, ClientName: option.TargetClientName, SystemName: option.TargetSystemName };
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
  }

  findMessages() {
    this.loading = true;
    const param = {
      SourceElementId: this.MissingValue.SourceElementId,
      SourceValue: this.MissingValue.SourceValue,
      TargetElementId: this.MissingValue.TargetElementId
    } as IHubMissingValueMessageParam;

    this.api.post(`Hub/Message/MissingValueMessages`, param)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: IHubMissingValueMessage[]) => {
          this.messages = Object.assign([], data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  viewMessage(messageId: number) {
    let newRelativeUrl = this.router.createUrlTree([`${HubRoutes.Message_Log}/${messageId}`]);
    let baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  canSave() {
    return this.newMapping.SourceClientId != null &&
           this.newMapping.SourceElementId != null &&
           this.newMapping.TargetClientId != null &&
           this.newMapping.TargetElementId != null &&
           (this.newMapping.TargetValue != null && this.newMapping.TargetValue.length > 0) &&
           (this.newMapping.SourceValue != null && this.newMapping.SourceValue.length > 0);
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.invalid = false;

      this.api.post('Hub/Values/CreateMapping', this.newMapping).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          this.alertService.success('New value mapping successfully created!');
          this.loading = false;
        },
        error: (error) => {
          this.alertService.success(error.Message);
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close();
  }
}
