import { Component, OnInit } from '@angular/core';
import { faCircleDot, faStamp } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { AnnualReviewService } from '../../../services/annual-review.service';
import { IAuditVisibilityResponse } from '../../../services/models/annual-review/annual-review-answer.model';
import { IAuthUser } from '../../../services/models/auth.model';
import { StorageService } from '../../../services/storage.service';
import { SessionStorageVariables } from '../../../core/constants/session-storage-variables';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-annual-review-menu',
  templateUrl: './annual-review-menu.component.html',
  styleUrls: ['./annual-review-menu.component.scss']
})
export class AnnualReviewMenuComponent implements OnInit {
  // Icons
  faAnnualReview = faStamp;
  faMenuItem = faCircleDot;

  // Variables
  public auditMenu = {} as IAuditVisibilityResponse;
  companyId: number;

  // Menu permissions
  annualReview: boolean = false;
  audit: boolean = false;
  admin: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private arService: AnnualReviewService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.companyId = user.User.CompanyId;
      this.setPermissions();
    });
  }

  setPermissions() {
    this.annualReview = this.authService.CheckPermissionByCode(PermissionCodes.AnnualReview);
    this.audit = this.authService.CheckPermissionByCode(PermissionCodes.AnnualReview_Audit);
    this.admin = this.authService.CheckPermissionByCode(PermissionCodes.AnnualReview_Admin);

    if (this.audit == true) {
      this.isAuditVisible();
    }
  }

  isAuditVisible() {
    this.arService.isAuditMenuItemVisible(this.companyId)
      .subscribe(data => {
        this.auditMenu = Object.assign(data, {});
        this.storageService.storeSessionItem(SessionStorageVariables.AuditMenu, this.auditMenu);
      });
  }
}
