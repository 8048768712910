import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IHubCreateDefaultValue } from '../../../../services/models/hub/hub-values.model';
import { HubAdminService } from '../../../../services/hub-admin.service';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../../services/alert.service';
import { IHubClientSystemOverview } from '../../../../services/models/hub/hub.model';
import { IHubElement } from '../../../../services/models/hub/schema.model';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-new-default-value-component',
  templateUrl: './new-default-value-modal.component.html',
  styleUrls: ['./new-default-value-modal.component.scss']
})
export class NewDefaultValueModalComponent implements OnInit, OnDestroy {
  // Icons

  // Variables
  defaultValue: IHubCreateDefaultValue;
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  hubSchemaTypes$ = this.hubAdmin.SchemaTypes$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClient: IHubClientSystemOverview = null;
  selectedSchemaTypeId: number = null;
  targetElements$: BehaviorSubject<IHubElement[]> = new BehaviorSubject<IHubElement[]>([]);
  canViewAll: boolean = false;
  ucid: number = null;
  invalid: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  env = environment;

  constructor(public activeModal: NgbActiveModal,
              private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.defaultValue = {
      SourceClientId: null,
      TargetClientId: null,
      TargetElementId: null,
      Value: null
    } as IHubCreateDefaultValue;

    this.ucid = this.auth.CurrentUser.User.CompanyId;
    this.setPermissions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);

    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAll) {
        this.ucid = null;
        this.userClients$.next(data);
      } else {
        const clients = data.filter(x => x.SourceCompanyId == this.ucid);
        this.userClients$.next(clients);
        if (clients.length >= 1) {
          this.selectedClient = clients[0];
          this.setClientData();
        }
      }
    });
  }

  setClientData() {
    if (this.selectedClient) {
      this.defaultValue.SourceClientId = this.selectedClient.SourceClientId;
      this.defaultValue.TargetClientId = this.selectedClient.TargetClientId;
      this.defaultValue.TargetElementId = null;
      this.selectedSchemaTypeId = null;
      this.targetElements$.next(null);
    }
  }

  loadElements() {
    this.loading = true;
    this.hubAdmin.GetElementsBySchemaType(this.selectedSchemaTypeId).subscribe({
      next: (data: IHubElement[]) => {
        this.targetElements$.next(data);
        if (data.length == 1) {
          this.defaultValue.TargetElementId = data[0].ElementId;
        }
        this.loading = false;
      },
      error: () => {
        this.targetElements$.next([]);
        this.loading = false;
      }
    });
  }

  canSave() {
    return this.selectedClient != null &&
      this.selectedSchemaTypeId != null &&
      this.defaultValue.SourceClientId != null &&
      this.defaultValue.TargetClientId != null &&
      this.defaultValue.TargetElementId != null &&
      (this.defaultValue.Value != null && this.defaultValue.Value.length > 0);
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.invalid = false;

      this.hubAdmin.CreateDefaultValue(this.defaultValue).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: string) => {
          this.alertService.success(data);
          this.loading = false;
          this.activeModal.close(true);
        },
        error: (error) => {
          this.alertService.error(error.Message);
        }
      });
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
