<div class="mt-3 mb-5 pb-5">
  <div class="row">
    <div class="col-12 text-start harmony-grey-text">
      <p class="mb-0">
        Please add all volumes and/or weights that are part of your RFP. Rates will be calculated for all volumes and weights entered.
      </p>
      <p class="mb-0">
        <small>
          <fa-icon [icon]="faInfo"></fa-icon> It is possible to adjust your margin settings per volume/weight.
        </small>
      </p>
    </div>
  </div>
  <div class="row mt-2 header-row">
    <div class="col-1 header-title border-split-left">
      Mode
    </div>
    <div class="col-1 header-title">
      Quantity
    </div>
    <div class="col-3 header-title">
      Measurement Type
    </div>
    <div class="col-2 header-title">
      Margin %
    </div>
    <div class="col-2 header-title">
      Margin Min.
    </div>
    <div class="col-3 header-title border-split-right">
      Margin Max.
    </div>
  </div>
  <div class="row m-0 result-row border-bottom" *ngFor="let mode of getModalityList()">
    <div class="col-1 ps-3">
      <strong>{{ mode.ModalityType?.Name }}</strong>
    </div>
    <div class="col-1 ps-3">
      {{ mode.Quantity }}
    </div>
    <div class="col-3 ps-3">
      {{ mode.UnitType.Name }}
    </div>
    <div class="col-2 ps-3">
      {{ mode.MarginPercentage }} %
    </div>
    <div class="col-2 ps-3">
      <b>{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '...' }}</b> {{ mode.MarginMinimum | number:'1.2-2' }}
    </div>
    <div class="col-2 ps-3">
      <b>{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '...' }}</b> {{ mode.MarginMaximum | number:'1.2-2' }}
    </div>
    <div class="col-1 text-end pe-0">
      <button
        class="btn btn-sm btn-outline-danger"
        type="button"
        [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
        (click)="removeRecord(mode)">
        <fa-icon [icon]="faBin" size="sm"></fa-icon>
      </button>
    </div>
  </div>
  <div class="row m-0 me-1 ms-1 modality-record-add" *ngIf="newMode && !tenderService.IsQuoteAccepted()">
    <div class="col-md-1 modality-record-cell">
      <select class="form-select form-select-sm"
              [(ngModel)]="selectedModality"
              (change)="setMode()">
        <option [ngValue]="null" [disabled]="true">Select...</option>
        <option *ngFor="let modality of (modalityTypes$ | async)" [ngValue]="modality">
          {{modality.Name}}
        </option>
      </select>
    </div>
    <div class="col-md-1 modality-record-cell">
      <input type="text"
             numbersOnly="6.2"
             class="form-control form-control-sm"
             max="999999"
             min="0"
             maxlength="8"
             [disabled]="tenderService.loading"
             [(ngModel)]="newMode.Quantity"
             placeholder="e.g. 100" />
    </div>
    <div class="col-md-3 modality-record-cell">
      <select class="form-select form-select-sm"
              [(ngModel)]="selectedUnitType"
              (change)="setQuantityUnitType()"
              [disabled]="!newMode.ModalityTypeId || tenderService.loading">
        <option [ngValue]="null" [disabled]="true">Select...</option>
        <option *ngFor="let unitType of unitTypes" [ngValue]="unitType">
          {{ unitType.Name }} ({{ unitType.Abbreviation }})
        </option>
      </select>
    </div>
    <div class="col-md-2 modality-record-cell">
      <div class="input-group input-group-sm">
        <input type="text"
               numbersOnly="3.2"
               class="form-control form-control-sm"
               max="100"
               min="0"
               maxlength="6"
               [disabled]="tenderService.loading"
               [(ngModel)]="newMode.MarginPercentage"
               placeholder="" />
          <span class="input-group-text" id="percentage-add-on">%</span>
      </div>
    </div>
    <div class="col-md-2 modality-record-cell">
      <div class="input-group input-group-sm">
        <input type="text"
               numbersOnly="4.2"
               class="form-control form-control-sm"
               max="9999"
               min="0"
               maxlength="8"
               [disabled]="tenderService.loading"
               [(ngModel)]="newMode.MarginMinimum"
               placeholder="e.g. 300" />
          <span class="input-group-text" *ngIf="tenderService.SelectedCurrency">
            {{ tenderService.SelectedCurrency.Currency.Code }}
          </span>
      </div>
    </div>
    <div class="col-md-2 modality-record-cell">
      <div class="input-group input-group-sm">
        <input type="text"
               numbersOnly="4.2"
               class="form-control form-control-sm"
               max="9999"
               min="0"
               maxlength="8"
               [disabled]="this.tenderService.loading"
               [(ngModel)]="newMode.MarginMaximum"
               placeholder="e.g. 500" />
          <span class="input-group-text" *ngIf="tenderService.SelectedCurrency">
            {{ tenderService.SelectedCurrency.Currency.Code }}
          </span>
      </div>
    </div>
    <div class="col-md-1 modality-record-cell text-end pe-0">
      <button class="btn btn-sm btn-outline-success"
              type="button"
              [disabled]="canAddMode()"
              (click)="addMode()">
        <fa-icon [icon]="faAdd"></fa-icon>
      </button>
    </div>
  </div>
</div>
