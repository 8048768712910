import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight, faCircleDot, faCogs } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  protected readonly faCogs = faCogs;
  protected readonly faMenuItem = faCircleDot;
  protected readonly faExpandable = faAngleRight;

  // Menu permissions
  email: boolean = false;
  members: boolean = false;
  agents: boolean = false;
  users: boolean = false;
  permissions: boolean = false;
  documentManagement: boolean = false;
  documentCategoryManagement: boolean = false;
  locationManagement: boolean = false;
  jobScheduler: boolean = false;
  log: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    } else {
      this.authService.CurrentUser$.subscribe(() => {
        this.setPermissions();
      });
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.email = this.authService.CheckPermissionByCode(PermissionCodes.Admin_Emailing);
    this.members = this.authService.CheckPermissionByCode(PermissionCodes.Admin_MemberManagement);
    this.agents = this.authService.CheckPermissionByCode(PermissionCodes.Admin_AgentManagement);
    this.users = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Management);
    this.permissions = this.authService.CheckPermissionByCode(PermissionCodes.Admin_PermissionManagement);
    this.documentManagement = this.authService.CheckPermissionByCode(PermissionCodes.Admin_DocumentManagement);
    this.documentCategoryManagement = this.authService.CheckPermissionByCode(PermissionCodes.Admin_DocumentCategories);
    this.locationManagement = this.authService.CheckPermissionByCode(PermissionCodes.Admin_LocationManagement);
    this.jobScheduler = this.authService.CheckPermissionByCode(PermissionCodes.Admin_JobScheduler);
    this.log = this.authService.CheckPermissionByCode(PermissionCodes.Admin_ApplicationLog);
  }
}
