import { Component } from '@angular/core';

@Component({
  selector: 'app-services-business-intelligence',
  templateUrl: './services-business-intelligence.component.html',
  styleUrls: ['./services-business-intelligence.component.scss']
})
export class ServicesBusinessIntelligenceComponent {

}
