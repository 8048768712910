import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../../../../services/alert.service';
import { FileService } from '../../../../services/file.service';
import { environment } from '../../../../../environments/environment';
import { IAuditDocumentResponse } from '../../../../services/models/annual-review/annual-review-audit-answer';
import { AnnualReviewService } from '../../../../services/annual-review.service';

@Component({
  selector: 'app-hse-legislation-document-modal',
  templateUrl: './annual-review-document-modal.component.html',
  styleUrls: ['./annual-review-document-modal.component.scss']
})
export class AnnualReviewDocumentModalComponent implements OnInit, OnDestroy {
  @ViewChild('FileHandler', {static: true}) FileHandler: ElementRef;

  // Icons
  faDelete = faTrash;
  faDownload = faDownload;

  // Component variables
  documents: IAuditDocumentResponse[] = [];
  requiredDocumentAmount: number;
  companyReviewId: number;
  reviewId: number;
  auditQuestionId: number;

  // General variables
  env = environment;
  loading: boolean = false;
  public madeChanges = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private alertService: AlertService,
              private fileService: FileService,
              private annualReviewService: AnnualReviewService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setFiles(event) {
    const files = event.target.files;
    for (let i = 0; i <= files.length - 1; i++) {
      this.loading = true;
      const file = files[i];
      if ((file.size / 1024 / 1024) > 10) {
        this.alertService.warn('The selected file is bigger than 10MB, please select another file');
        this.FileHandler.nativeElement.value = '';
        event.target.value = null;
        this.loading = false;
      } else {
        this.annualReviewService.uploadDocument(file, this.companyReviewId, this.auditQuestionId, this.reviewId)
          .subscribe({
            next:
              (document: IAuditDocumentResponse) => {
                this.documents.push(document);
                this.loading = false;
              }, error: (err) => {
              if (err.Meta.Code == 409) {
                this.alertService.error(err.Meta.Message)
              } else {
                this.alertService.error("An error occurred trying to upload the document, please try again")
              }
              event.target.value = null;
              this.loading = false;
            }
          });
      }
    }
  }

  close() {
    const result = {
      MadeChanges: this.madeChanges,
      Documents: this.documents
    };
    this.activeModal.close(result);
  }

  delete(document: IAuditDocumentResponse) {
    this.loading = true;
    this.annualReviewService.deleteAuditDocument(document.Id)
      .subscribe(() => {
        const index = this.documents.indexOf(document);
        if (index >= 0) {
          this.documents.splice(index, 1);
        }
        this.madeChanges = true;
        this.loading = false;
      });
  }

  downloadDocument(doc: IAuditDocumentResponse) {
    const subFolderAndFileName = `${doc.Reference}/${doc.Name}`;
    this.fileService.GetFile('ar-audit', subFolderAndFileName);
  }
}
