import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { IRiskAssessmentGroup } from '../../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../../core/ad-auth-service/ad-auth.service";
import { SharedModule } from "../../../../../shared/shared.module";

@Component({
  selector: 'app-risk-assessment-answer-modal',
  templateUrl: './risk-assessment-group-admin-modal.component.html',
  standalone: true,
  imports: [SharedModule],
  styleUrls: ['./risk-assessment-group-admin-modal.component.scss']
})
export class RiskAssessmentGroupAdminModalComponent {

  // Component Variables
  isValid: boolean = false;
  riskAssessmentGroup: IRiskAssessmentGroup;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  saveGroup() {
     this.hseService.updateRiskAssessmentGroup(this.riskAssessmentGroup)
       .subscribe({
         next: (data) => {
           this.alertService.info('Risk assessment group ' + this.riskAssessmentGroup.Description + ' successfully saved.');
           this.riskAssessmentGroup = Object.assign({}, data);
           this.close();
         },
         error: () => {
           this.alertService.error('Failed to update risk assessment group.');
         }
       });
  }

  close() {
    this.activeModal.close();
  }
}
