<div class="modal-header">
  <h4 class="modal-title">
    {{ this.RoleId ? 'Editing Role ID: ' + this.RoleId : 'New Role' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body" *ngIf="Role">
  <div class="row">
    <div class="col-6">
      <h5 class="harmony-teal-text mb-0">Details</h5>
      <hr class="my-1">
    </div>
    <div class="col-6">
      <h5 class="harmony-teal-text mb-0">Assigned to users</h5>
      <hr class="my-1">
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-12" style="line-height: 11px;">
          <input type="text"
                 [(ngModel)]="Role.Name"
                 [ngClass]="{'is-invalid': (!this.Role.Name || this.Role.Name.length < 3) && invalid}"
                 [placeholder]="'Module name e.g. Pricing'"
                 class="form-control form-control-sm">
          <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Required. Minimum length of 3 characters.</small>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12" style="line-height: 11px;">
          <textarea class="form-control form-control-sm"
                    name="roleDescription"
                    id="roleDescription"
                    [placeholder]="'Role description. Minimum 10 characters.'"
                    [ngClass]="{'is-invalid': (!this.Role.Description || this.Role.Description.length < 10) && invalid}"
                    [(ngModel)]="Role.Description"
                    [rows]="5"></textarea>
          <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Required. Minimum length of 10 characters.</small>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12" style="line-height: 11px;">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="Role.ParentRoleId"
                  [disabled]="!(roles$ | async)">
            <option [ngValue]="null">No parent selected...</option>
            <option *ngFor="let role of (roles$ | async)"
                    [ngValue]="role.Id">
              {{ role.Name }}
            </option>
          </select>
          <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Optional. Parent roles are used to control access levels for admin users of different departments.</small>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <input type="text"
                 [(ngModel)]="userSearchText"
                 (keyup)="filterUsers()"
                 [placeholder]="'Search users...'"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="(filteredUsers$ | async)?.length <= 0">
            <small class="harmony-grey-text">{{ Role.Id == null ? 'Save the new role before it can be assigned to users' : 'Role is not assigned to any users' }}</small>
          </ng-container>
          <div class="role-users-list">
            <ng-container *ngIf="(filteredUsers$ | async)?.length > 0">
              <div class="row result-row" *ngFor='let user of (filteredUsers$ | async); let i = index'>
                <div class="col-11 ps-0">
                  {{user.Username}}
                </div>
                <div class="col-1 pointer" *ngIf="user.DisplayName && user.DisplayName.length > 0" [title]="user.DisplayName">
                  <fa-icon [icon]="faEdit" class="harmony-purple-text pointer" [title]="'Edit user'" (click)="editUser(user.Username)"></fa-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="row fw-bold">
        <div class="col-6">
          <h5 class="harmony-teal-text mb-0">Available Permissions</h5>
          <hr class="my-1">
        </div>
        <div class="col-6">
          <h5 class="harmony-teal-text mb-0">Active Permissions</h5>
          <hr class="my-1">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <select name="module"
                  class="form-select form-select-sm"
                  id="module"
                  [(ngModel)]="moduleId">
            <option [ngValue]="null" selected>All modules...</option>
            <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
              {{module.Name}}
            </option>
          </select>
        </div>
        <div class="col-6">
          <select name="module"
                  class="form-select form-select-sm"
                  id="active-module"
                  [(ngModel)]="activeModuleId">
            <option [ngValue]="null" selected>All modules...</option>
            <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
              {{module.Name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6 permission-list border-end ps-0 pe-0">
          <ng-container *ngFor="let component of availableRoleFeatures(); let i = index; trackBy: identify">
            <div class="row result-row" *ngIf="!hasComponent(component.Id)">
              <div class="col-7">
                {{component.Name}}
              </div>
              <div class="col-3">
                <code>{{component.Code}}</code>
              </div>
              <div class="col-2 text-end">
                <fa-icon [icon]="faAdd" class="harmony-green-text pointer" size="1x" (click)="addRoleFeature(component)"></fa-icon>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-6 permission-list ps-0 pe-0">
          <ng-container *ngIf="!Role.Features || Role.Features.length == 0">
            <div class="ps-3">
              <small class="harmony-grey-text">No permissions have been assigned to this role</small>
            </div>
          </ng-container>
          <ng-container *ngIf="Role.Features && Role.Features.length > 0">
            <div class="row result-row" *ngFor="let roleFeature of activeRoleFeatures(); let i = index; trackBy: identify">
              <div class="col-7">
                {{ roleFeature.PermissionName }}
              </div>
              <div class="col-3">
                <code>{{ roleFeature.PermissionCode }}</code>
              </div>
              <div class="col-2 text-end">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer"
                         size="1x" (click)="removeRoleFeature(roleFeature)"></fa-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveRole()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
