<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ city && CityId ? 'Edit City: ' + city.Name : 'Create New City' }}
  </h4>
</div>
<div class="modal-body">
  <ng-container *ngIf="city && !loading">
    <div class="row">
      <div class="col-4">
        <b>Name</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !city.Name || city.Name.length <= 0}"
               [(ngModel)]="city.Name">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Name Without Diacritics</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !city.NameWoDiacritics || city.NameWoDiacritics.length <= 0}"
               [(ngModel)]="city.NameWoDiacritics">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Info Name</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !city.InfoName || city.InfoName.length <= 0}"
               [(ngModel)]="city.InfoName">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>LO Code</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="city.LOCode">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>State</b>
      </div>
      <div class="col-8">
        <app-state-search [Identifier]="'StateID'"
                          [SelectedID]="city.StateId"
                          (OnSelect)="city.StateId = $event">
        </app-state-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Country</b>
      </div>
      <div class="col-8">
        <app-country-search [Disabled]="loading"
                            [SelectedID]="city.CountryId"
                            [ShowError]="city.CountryId == null"
                            (OnSelect)="city.CountryId = $event">
        </app-country-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Coordinates</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="city.Coordinates">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Google Place ID</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="city.GooglePlaceId">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!city && loading">
    <app-loader *ngIf="loading" class="ms-2" [Width]="40"></app-loader>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="cancel()">Close</button>
</div>
