import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {IRiskAssessmentGroup, IRiskAssessmentItem} from '../../../../../services/models/hse/hse.model';
import {AdAuthService} from "../../../../../core/ad-auth-service/ad-auth.service";
import {SharedModule} from "../../../../../shared/shared.module";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-risk-assessment-answer-modal',
  templateUrl: './risk-assessment-item-admin-modal.component.html',
  standalone: true,
  imports: [
    SharedModule,
    NgClass
  ],
  styleUrls: ['./risk-assessment-item-admin-modal.component.scss']
})
export class RiskAssessmentItemAdminModalComponent {

  // Component Variables
  riskAssessmentItem: IRiskAssessmentItem;
  riskAssessmentGroup: IRiskAssessmentGroup;
  isInvalid: boolean = false;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  updateItem() {
    if (this.isValid()) {
      this.hseService.updateRiskAssessmentItem(this.riskAssessmentItem)
        .subscribe({
          next: (data) => {
            this.alertService.info('Assessment item ' + this.riskAssessmentItem.ItemNumber + ' successfully saved.');
            this.riskAssessmentItem = Object.assign({}, data);
            this.close(this.riskAssessmentItem);
          },
          error: () => {
            this.alertService.error('Error: Failed to update risk assessment item.');
          }
        });
    } else {
      this.alertService.error('Please fill all required fields.');
    }
  }

  isValid() {
    const r = this.riskAssessmentItem;

    if (r.ItemNumber != null &&
       (r.Topic && r.Topic.length > 0) &&
       (r.Subject && r.Subject.length > 0) &&
       (r.Risk && r.Risk.length > 0) &&
       (r.Area && r.Area.length > 0) &&
       (r.PossibleMeasures && r.PossibleMeasures.length > 0)) {
      this.isInvalid = false;
      return true;
    }

    this.isInvalid = true;
    return false;
  }

  close(riskAssessmentItem: IRiskAssessmentItem) {
    this.activeModal.close(riskAssessmentItem);
  }
}
