import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IDocumentCategory,
  IDocumentCategorySearchParam
} from '../../../../services/models/document/document-category.model';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { faSortUp, faEdit, faBoxOpen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DocumentService } from '../../../../services/document.service';
import { CompanyService } from '../../../../services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { DocumentCategoryModalComponent } from './document-category-modal/document-category-modal.component';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-document-categories',
  templateUrl: './document-categories.component.html',
  styleUrls: ['./document-categories.component.scss']
})
export class DocumentCategoriesComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faSortDesc = faSortUp;
  protected readonly faEdit = faEdit;
  protected readonly faDelete = faTrash;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faAdd = faPlus;

  // Component variables
  paginationData: IPaginationData<IDocumentCategory>;
  searchParam: IDocumentCategorySearchParam;
  page: IPage;
  orderParam: IOrderParam;
  modules$ = this.authService.Modules$.asObservable();

  // General variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private documentService: DocumentService,
              private companyService: CompanyService,
              private modalService: NgbModal,
              private authService: AdAuthService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.loading = true;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;
    // Set filter
    this.searchParam = {
      SearchText: null,
      ModuleId: null
    };
    // Load categories
    this.searchCategories();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  resetSearch() {
    this.searchParam = {
      SearchText: null,
      ModuleId: null
    };
    this.searchCategories();
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchCategories();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchCategories();
  }

  searchCategories() {
    this.loading = true;

    this.documentService.searchDocumentCategories(this.searchParam, this.page, this.orderParam).subscribe({
      next: (data: IPaginationData<IDocumentCategory>) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search document categories.');
      }
    });
  }

  addCategory() {
    // Open modal to Add/Edit a document category
    const modalRef = this.modalService.open(DocumentCategoryModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CategoryId = null;
    modalRef.componentInstance.SetDocumentCategory();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IDocumentCategory) => {
      if (result) {
        this.searchCategories();
        this.documentService.LoadLookupData();
      }
    }, () => { });
  }

  editCategory(category: IDocumentCategory) {
    // Open modal to Add/Edit a document category
    const modalRef = this.modalService.open(DocumentCategoryModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CategoryId = category.Id;
    modalRef.componentInstance.SetDocumentCategory();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IDocumentCategory) => {
      if (result) {
        category.Name = result.Name;
        category.ModuleId = result.ModuleId;
        category.ModuleName = result.ModuleName;
        category.Description = result.Description;
        category.RoleName = result.RoleName;
      }
    }, () => { });
  }

  deleteCategory(categoryId: number) {
    const message = `This document category will be deleted. This action can not be undone. \n\n Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(answer => {
          if (answer) {
            const successMessage = `Document category successfully deleted.`;
            return this.documentService.deleteDocumentCategory(categoryId)
              .pipe(
                tap(() => {
                  this.alertService.success(successMessage);
                  this.searchCategories();
                }));
          }
        })
      ).subscribe();
  }
}
