import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOrder]'
})
export class OrderDirective {
  @Output('appOrder') ordered: EventEmitter<IOrderParam> = new EventEmitter();

  faSortAsc = '<svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"></path></svg>'
  faSortDesc = '<svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" class="svg-inline--fa fa-sort-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"></path></svg>';

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  @HostListener("click")
  orderData() {
    const elem = this.el.nativeElement;
    const order = elem.getAttribute("data-order");
    const orderBy = elem.getAttribute("data-column");

    if (order === "desc") {
      elem.setAttribute("data-order", "asc");
      let icon = this.el.nativeElement.querySelector('fa-icon');
      this.renderer.setProperty(icon, 'innerHTML', this.faSortDesc);
      this.renderer.setStyle(icon, 'bottom', '-3px');
    } else {
      elem.setAttribute("data-order", "desc");
      let icon = this.el.nativeElement.querySelector('fa-icon');
      this.renderer.setProperty(icon, 'innerHTML', this.faSortAsc);
      this.renderer.setStyle(icon, 'bottom', '3px');
    }

    this.ordered.emit({
      OrderBy: orderBy,
      OrderDirection: order
    } as IOrderParam);
  }
}

export interface IOrderParam {
  OrderBy: string;
  OrderDirection: string;
}
