<div>
  <div class="text-center">
    <h2><b>Dangerous Substance Safety Chart</b></h2>
  </div>
  <table>
    <tr>
      <td><b>Product Name</b></td>
      <td>{{substance.ProductName}}</td>
      <td><b>Substance</b></td>
      <td>{{substance.Name}} </td>
    </tr>
    <tr>
      <td><b>Location</b></td>
      <td>{{substance.Location}}</td>
      <td><b>Activity</b></td>
      <td>{{substance.Activity}}</td>
    </tr>
    <tr>
      <td><b>Usage</b></td>
      <td>{{substance.Usage}}</td>
      <td><b>UN number</b></td>
      <td>{{substance.UNNumber}}</td>
    </tr>
    <tr>
      <td><b>Supplier</b></td>
      <td>{{substance.Supplier}}</td>
      <td><b>Emergency Number</b></td>
      <td>{{substance.EmergencyNumber}}</td>
    </tr>
    <tr>
      <td><b>Owner</b></td>
      <td>{{substance.OwnerUser}}</td>
      <td><b>MSDS available</b></td>
      <td>{{substance.MSDS ? 'Yes' : 'No'}}</td>
    </tr>
  </table>
  <div class="row" *ngIf="substance.HRPhrases">
    <div class="col-3">
      <b>Hazard/Risk Phrases</b>
    </div>
    <div class="col-9">
      <div *ngFor="let phrase of substance.HRPhrases;">
        {{phrase}}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="substance.PSPhrases">
    <div class="col-3">
      <b>Protection/Safety Phrases</b>
    </div>
    <div class="col-9">
      <div *ngFor="let phrase of substance.PSPhrases;">
        {{phrase}}
      </div>
    </div>
  </div>
  <div class="row text-center secondary-header mx-0 mb-1">
    <b>Properties of Substance</b>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Explosive">
      <img [src]="imageRoute + 'explosive.jpg'" alt="explosive">
      <div>Explosive</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.GasPressure">
      <img [src]="imageRoute + 'gas_pressure.jpg'" alt="gas pressure">
      <div class="text-center">Gas Pressure</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Flammable">
      <img [src]="imageRoute + 'flammable.jpg'" alt="flammable">
      <div>Flammable</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Oxidiser">
      <img [src]="imageRoute + 'oxidiser.jpg'" alt="oxidiser">
      <div>Oxidiser</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Harmful">
      <img [src]="imageRoute + 'harmful.jpg'" alt="harmful">
      <div>Harmful</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Toxic">
      <img [src]="imageRoute + 'toxic.jpg'" alt="toxic">
      <div>Toxic</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Irritant">
      <img [src]="imageRoute + 'irritant.jpg'" alt="irritant">
      <div>Irritant</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Corrosive">
      <img [src]="imageRoute + 'corrosive.jpg'" alt="corrosive">
      <div>Corrosive</div>
    </div>
    <div class="col-auto text-center ps-1 pe-1" *ngIf="substance.Environment">
      <img [src]="imageRoute + 'environment.jpg'" alt="environment">
      <div>Environment</div>
    </div>
  </div>
  <div class="row text-center secondary-header mx-0 mb-1">
    <b>Route of Exposure</b>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto text-center" *ngIf="substance.Skin">
      <img [src]="imageRoute + 'skin.jpg'" alt="skin">
      <div>Skin</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Eyes">
      <img [src]="imageRoute + 'eyes.jpg'" alt="eyes">
      <div>Eyes</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Inhalation">
      <img [src]="imageRoute + 'inhalation.jpg'" alt="inhalation">
      <div>Inhalation</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Ingestion">
      <img [src]="imageRoute + 'ingestion.jpg'" alt="ingestion">
      <div>Ingestion</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Cuts">
      <img [src]="imageRoute + 'cuts.jpg'" alt="cuts">
      <div>Cuts</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Injection">
      <img [src]="imageRoute + 'injection.jpg'" alt="injection">
      <div>Injection</div>
    </div>
  </div>
  <div class="row text-center secondary-header mx-0 mb-1">
    <b>Protective Equipment Required</b>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto text-center" *ngIf="substance.Gloves">
      <img [src]="imageRoute + 'gloves.jpg'" alt="gloves">
      <div>Gloves</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Apron">
      <img [src]="imageRoute + 'apron.jpg'" alt="apron">
      <div>Apron</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.DustMask">
      <img [src]="imageRoute + 'dust_mask.jpg'" alt="dust mask">
      <div>Dust Mask</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.GasMask">
      <img [src]="imageRoute + 'gas_mask.jpg'" alt="gas mask">
      <div class="text-center">Gas Mask</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Glasses">
      <img [src]="imageRoute + 'glasses.jpg'" alt="glasses">
      <div>Glasses</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.FaceMask">
      <img [src]="imageRoute + 'face_mask.jpg'" alt="face mask">
      <div class="text-center position-relative">Face Mask</div>
    </div>
    <div class="col-auto text-center" *ngIf="substance.Boots">
      <img [src]="imageRoute + 'boots.jpg'" alt="boots">
      <div>Boots</div>
    </div>
  </div>
  <div class="row text text-center secondary-header mx-0 mb-1">
    <b>Additional Measures</b>
  </div>
  <table>
    <tr>
      <td>General</td>
      <td>{{substance.General}}</td>
    </tr>
    <tr>
      <td>Storage</td>
      <td>{{substance.Storage}}</td>
    </tr>
    <tr>
      <td>Transport</td>
      <td>{{substance.Transport}}</td>
    </tr>
    <tr>
      <td>Ventilation</td>
      <td>{{substance.Ventilation}}</td>
    </tr>
    <tr>
      <td>Fire Precautions</td>
      <td>{{substance.Fire}}</td>
    </tr>
    <tr>
      <td>First Aid</td>
      <td>{{substance.FirstAid}}</td>
    </tr>
    <tr>
      <td>Spillage/Leak</td>
      <td>{{substance.Spillage}}</td>
    </tr>
    <tr>
      <td>Disposal</td>
      <td>{{substance.Disposal}}</td>
    </tr>
  </table>
</div>
