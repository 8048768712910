import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { ArticleManagementComponent } from './article-management/article-management.component';
import { PermissionCodes } from "../../core/constants/permission-codes";
import { ArticleEditorComponent } from "./article-management/article-editor/article-editor.component";
import { ArticleReadComponent } from "./article-read/article-read.component";
import { ArticleReviewComponent } from "./article-management/article-review/article-review.component";
import { NetworkArticlesComponent } from './network-articles/network-articles.component';
import { ArticleChangeReviewComponent } from './article-management/article-change-review/article-change-review.component';

const routes: Routes = [
  {
    path: 'article/:articleId',
    component: ArticleReadComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.News_NetworkArticles
    }
  },
  {
    path: 'article-management',
    component: ArticleManagementComponent,
    canActivate: [AuthGuard],
    data: {ExpectedPermission: PermissionCodes.News_ManageArticles}
  },
  {
    path: 'article-editor/:articleId',
    component: ArticleEditorComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.News_ManageArticles
    }
  },
  {
    path: 'article-review/:articleId',
    component: ArticleReviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.News_ApproveArticles
    }
  },
  {
    path: 'article-review/compare/:articleId',
    component: ArticleChangeReviewComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.News_ManageArticles
    }
  },
  {
    path: 'articles',
    component: NetworkArticlesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.News_NetworkArticles
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule {
}
