import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { ITraining, ITrainingLog, ITrainingLogParam } from '../../../services/models/portal/training.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IAuthUser } from '../../../services/models/auth.model';

@Component({
  selector: 'app-training-resources',
  templateUrl: './training-resources.component.html',
  styleUrls: ['./training-resources.component.scss']
})
export class TrainingResourcesComponent implements OnInit, OnDestroy {
  // Icons

  // Component variables
  trainingResources: ITraining[] = [];
  trainingLogs: ITrainingLog[];
  activeTraining: ITraining;
  courseHtml: SafeHtml = "";

  // General Variables
  loading: boolean = false;

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.loadTrainingResources();

    if (this.authService.CurrentUser) {
      this.loadTrainingLogs();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.loadTrainingLogs();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this.authService.HideMenu = false;
  }

  loadTrainingResources() {
    this.loading = true;

    this.api.get('Training/List').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.trainingResources = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.alertService.error("An error has occurred while trying to load the training manuals.");
      }
    });
  }

  loadTrainingLogs() {
    this.loading = true;

    this.api.get('Training/UserLog').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.trainingLogs = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.alertService.error("An error has occurred while trying to load the training manuals.");
      }
    });
  }

  openTrainingManual(training: ITraining) {
    this.activeTraining = training;
    this.authService.HideMenu = true;
    this.updateHtml();
    const hasLog = this.userHasStartedCourse(training.Id);
    if (!hasLog) {
      this.logUserTraining();
    }
  }

  updateHtml(){
    const courseContent = `<iframe style="width: 100%; height: 100%" src="${this.activeTraining.TrainingResourceUrl}" referrerpolicy="no-referrer" allow="autoplay; encrypted-media" [allowFullscreen]="true"></iframe>`
    this.courseHtml = this.sanitizer.bypassSecurityTrustHtml(courseContent);
  }

  userHasStartedCourse(trainingId: number): ITrainingLog {
    const logs = this.trainingLogs;

    return (logs && logs.length > 0) ? logs.find(x => x.TrainingId == trainingId) : null;
  }

  backToOverview() {
    this.activeTraining = null;
    this.authService.HideMenu = false;
    this.loadTrainingLogs();
  }

  logUserTraining() {
    const param = {
      TrainingId: this.activeTraining.Id,
      UserId: this.authService.CurrentUser.UserId,
      CompanyId: this.authService.CurrentUser.User.CompanyId,
      StartDate: new Date,
      EndDate: null
    } as ITrainingLogParam;

    this.api.post('Training/LogTraining', param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.loadTrainingLogs();
        }
      },
      error: () => { }
    });
  }
}
