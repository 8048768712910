import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import { CompanyService } from '../../../../services/company.service';
import { ICompany, ICompanyDetail } from '../../../../services/models/member.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../services/api.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-dangerous-substance-information-modal',
  templateUrl: './dangerous-substance-information-modal.component.html',
  styleUrls: ['./dangerous-substance-information-modal.component.scss']
})
export class DangerousSubstanceInformationModalComponent implements OnInit, AfterContentChecked {

  companyId: number;
  info = {} as IBranchDto;
  companyDetails: ICompanyDetail[];
  branch = {} as IBranchDto;
  editBranch: boolean = false;
  hasAccess: boolean = false;
  company: ICompany;
  invalid: boolean;
  updateCompanyDetails: ICompanyDetail[] = [];

  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private apiService: ApiService,
              private cdr: ChangeDetectorRef,
              private alertService: AlertService) {
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (this.branch) {
      this.editBranch = true;
    } else {
      this.defaultBranchToCompany();
    }
  }

  defaultBranchToCompany() {
    this.companyService.getCompany(this.companyId).subscribe(
      {
        next: value => {
          this.company = Object.assign({}, value);
          this.branch = {
            Name: this.company.Name,
            Address1: this.company.AddressPhysical,
            CityId: null,
            CountryId: this.company.CountryIdPhysical,
            ZipCode: this.company.ZipCodePhysical,
            PrimaryContact: this.company.CompanyDetails.find(x => x.CompanyDetailDefinition.PropertyName == 'MemberTelephone')?.PropertyValue
          } as IBranchDto;
        }
      }
    );
  }

  update() {
    this.canSave();
    if (!this.invalid) {
      this.apiService.put('Branch', this.branch)
        .subscribe({
          next: () => {
            if (this.updateCompanyDetails?.length > 0) {
              this.companyService.UpsertCompanyDetailList(this.updateCompanyDetails).subscribe({
                next: () => {
                  this.close();
                }
              });
            } else {
              this.close();
            }
          }
        });
    } else {
      this.alertService.error('Please check all mandatory fields marked with *');
    }
  }

  canSave() {
    if (this.branch.Name &&
      this.branch.Address1 &&
      this.branch.ZipCode &&
      this.branch.CountryId > 0 &&
      this.branch.CityId > 0) {
      this.invalid = false;
    }
    this.invalid = true;
  }

  close() {
    this.activeModal.close();
  }
}
