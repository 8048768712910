import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { ICityDto } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-city-search',
  templateUrl: './city-search.component.html',
  styleUrls: ['./city-search.component.scss']
})
export class CitySearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() CityName: string;
  @Input() Disabled: boolean = false;
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;

  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadCityByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectCityObject: EventEmitter<ICityDto> = new EventEmitter<ICityDto>();

  // Component Variables
  selectedId: number;
  public SelectedCity: ICityDto;
  searching: boolean = false;
  searchFailed: boolean = false;
  message: string = '';
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: ICityDto) => result.InfoName;

  constructor(private api: ApiService) {
  }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadCityByID(this.SelectedID);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && (changes.SelectedID.currentValue == null || changes.SelectedID.currentValue == 0)) {
      this.clear();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchCity = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.setSearching()),
      switchMap(term =>
        this.getCity(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            this.message = 'An error occurred';
            return of([]);
          }))
      )
    );
  };

  setSearching() {
    this.searching = true;
    this.searchFailed = false;
    this.message = '';
  }

  getCity(searchText: string): Observable<ICityDto[]> {
    if (searchText && searchText.length > 2) {
      return this.api.get(`City/Search/${searchText}`).pipe(
        map((data) => data.length > 0 ? data : [{InfoName: 'No results', Id: null} as ICityDto])
      );
    } else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadCityByID(Id: number) {
    this.searching = true;
    // Call City controller "Single" endpoint
    this.api.get('City/Single/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICityDto) => {
      if (data) {
        this.SelectedCity = Object.assign({}, data);
      }
      this.searching = false;
    });
  }

  onSelect(selectedCity: ICityDto) {
    if (selectedCity && selectedCity.Id) {
      this.OnSelect.emit(selectedCity.Id);
      this.OnSelectCityObject.emit(selectedCity);
    }
  }

  clear() {
    this.setDefault();
    this.searching = false;
    this.OnSelect.emit(null);
    this.OnSelectCityObject.emit(null);
  }

  setDefault() {
    this.SelectedCity = {
      InfoName: null,
      Id: null,
      Name: null
    } as ICityDto;
  }
}
