<div class="modal-header">
  <h4 class="modal-title">
    Create a new default value
  </h4>
</div>
<div class="modal-body">
  <div class="row mt-2">
    <div class="col-8 offset-2">
      <select class="form-select form-select-sm pointer"
              [disabled]="!(userClients$ | async)"
              [(ngModel)]="selectedClient"
              (change)="setClientData()">
        <option [ngValue]="null" [disabled]="true" *ngIf="canViewAll">Select client direction...</option>
        <option *ngFor="let client of (userClients$ | async)"
                [ngValue]="client">
          {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
        </option>
      </select>
    </div>
  </div>
  <hr class="my-2">
  <div class="row mt-1">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(hubSchemaTypes$ | async) || (hubSchemaTypes$ | async).length <= 0 || !selectedClient?.SourceSystemId"
                  [(ngModel)]="selectedSchemaTypeId"
                  (change)="loadElements()">
            <option [ngValue]="null">All source schemas</option>
            <option *ngFor="let schemaType of (hubSchemaTypes$ | async)"
                    [hidden]="schemaType.SystemId != selectedClient?.SourceSystemId"
                    [ngValue]="schemaType.Id">
              {{ schemaType.SchemaTypeName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <select class="form-select form-select-sm pointer"
                  [ngClass]="{'is-invalid': invalid && defaultValue.TargetElementId == null}"
                  [disabled]="!(targetElements$ | async) || (targetElements$ | async).length <= 0 || selectedSchemaTypeId == null || loading"
                  [(ngModel)]="defaultValue.TargetElementId">
            <option [ngValue]="null" [disabled]="true">Select element...</option>
            <option *ngFor="let element of (targetElements$ | async)" [ngValue]="element.ElementId">
              {{ element.ElementPath }} &nbsp;({{ element.ElementId }})
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      <input type="text"
             [ngClass]="{'is-invalid': invalid && (defaultValue.Value == null || defaultValue.Value.length <= 0)}"
             class="form-control form-control-sm"
             [maxlength]="512"
             [disabled]="loading"
             [(ngModel)]="defaultValue.Value"
             placeholder="Enter value...">
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
