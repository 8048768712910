import { Component } from '@angular/core';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { IArticle } from '../../../../../services/models/news/article.model';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ArticleService } from "../../../../../services/article.service";
import { AlertService } from "../../../../../services/alert.service";

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html',
  styleUrls: ['./publish-modal.component.scss']
})
export class PublishModalComponent {
  // Icons
  faInfo = faCircleQuestion;

  // Component variables
  article: IArticle;
  isValid: boolean = true;

  constructor(private activeModal: NgbActiveModal,
              private articleService: ArticleService,
              private alertService: AlertService) {
  }

  canSubmit(): boolean {
    if (this.article.PublishDate) {
      this.article.Status = 'Awaiting Approval';
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    return this.isValid;
  }

  submit() {
    if (this.canSubmit()) {
      this.articleService.submitForApproval(this.article)
        .subscribe({
          next: (article) => {
            this.article = article;
            this.close(true);
          },
          error: () => {
            this.alertService.error('Error occurred trying to submit for approval');
          }
        });
    }
  }

  close(hasSaved: boolean) {
    this.activeModal.close(hasSaved)
  }
}
