import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../services/alert.service';
import {
  IAuditQuestionRequest,
  IAuditQuestionResponse,
  IAuditQuestionUpdateRequest
} from '../../../../services/models/annual-review/annual-review-audit-question';
import {
  IAnnualReviewQuestionSearchResponse
} from '../../../../services/models/annual-review/annual-review-question.model';
import {AnnualReviewService} from '../../../../services/annual-review.service';

@Component({
  selector: 'app-view-audit-question-modal',
  templateUrl: './view-audit-question-modal.component.html',
  styleUrls: ['./view-audit-question-modal.component.scss']
})
export class ViewAuditQuestionModalComponent implements OnInit, OnDestroy {

  public isNew: false;
  public auditForm: UntypedFormGroup;
  private unsubscribe: Subject<any> = new Subject<any>();
  public audit = {} as IAuditQuestionResponse;
  public questionSearchFields: IAnnualReviewQuestionSearchResponse[];
  public reviewId: number;
  typeSelected: number;
  question: IAnnualReviewQuestionSearchResponse;

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: UntypedFormBuilder,
              private annualReviewService: AnnualReviewService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.createForm();
    if (!this.isNew) {
      this.fillForm();
    }
    this.getQuestionSearchFields();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getQuestionSearchFields() {
    this.annualReviewService.getSearchFields(this.reviewId)
      .subscribe({
        next: (data) => {
          if (data) {
            this.questionSearchFields = [...data];
          }
        },
        error: () => {
          this.alertService.error('Could not load the questions');
        }
      });
  }

  // FORM Functions
  createForm() {
    this.auditForm = this.formBuilder.group({
      value: new UntypedFormControl(null, [Validators.required, Validators.minLength(5)]),
      label: new UntypedFormControl(null, Validators.required),
      order: new UntypedFormControl(null),
      type: new UntypedFormControl(this.typeSelected, Validators.required),
      reference: new UntypedFormControl(null),
      requiredDocumentAmount: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      question: new UntypedFormControl(this.audit.QuestionId, Validators.required),
    });
  }

  fillForm() {
    this.auditForm.controls.value.setValue(this.audit.Value);
    this.auditForm.controls.label.setValue(this.audit.Label);
    this.auditForm.controls.order.setValue(this.audit.Order);
    this.auditForm.controls.type.setValue(this.audit.Type);
    this.auditForm.controls.reference.setValue(this.audit.Reference);
    this.auditForm.controls.requiredDocumentAmount.setValue(this.audit.RequiredDocumentAmount);
    this.auditForm.controls.question.setValue(this.audit.QuestionId);
  }

  isFormValid() {
    this.auditForm.markAllAsTouched();
    this.auditForm.markAsTouched();
    if (this.auditForm.controls.type.value && this.auditForm.controls.type.value !== 3) {
      // Remove validation on document when it's not selected
      this.auditForm.controls.requiredDocumentAmount.clearValidators();
      this.auditForm.controls.requiredDocumentAmount.setValue(null);
      this.auditForm.controls.requiredDocumentAmount.updateValueAndValidity();
    }
    if (this.auditForm.invalid) {
      this.alertService.warn('Form is not valid, please check required fields');
    }
    return this.auditForm.valid;
  }

  // FOOTER FUNCTIONS
  add() {
    if (this.isFormValid()) {
      this.updateValues();
      const request = {
        Value: this.audit.Value.trim(),
        Label: this.audit.Label,
        Order: this.audit.Order,
        Reference: this.audit.Reference,
        Type: this.audit.Type,
        RequiredDocumentAmount: this.audit.RequiredDocumentAmount,
        QuestionId: this.audit.QuestionId,
        ReviewId: this.reviewId
      } as IAuditQuestionRequest;
      this.annualReviewService.addAuditQuestion(request)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to add');
          }
        });
    }
  }

  update() {
    if (this.isFormValid()) {
      this.updateValues();
      const request = {
        Id: this.audit.Id,
        Value: this.audit.Value.trim(),
        Label: this.audit.Label.trim(),
        Order: this.audit.Order,
        Reference: this.audit.Reference,
        Type: this.audit.Type,
        RequiredDocumentAmount: this.audit.RequiredDocumentAmount,
        QuestionId: this.audit.QuestionId
      } as IAuditQuestionUpdateRequest;
      this.annualReviewService.updateAuditQuestion(request)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to update');
          }
        });
    }
  }

  close(added: boolean) {
    this.activeModal.close(added);
  }

  setAuditQuestion(question: IAnnualReviewQuestionSearchResponse) {
    this.audit.QuestionId = question.QuestionId;
    this.audit.Requirement = question.Requirement;
    this.audit.ReviewQuestion = question.Question;
    this.auditForm.controls.question.setValue(question.QuestionId);
  }

  updateValues() {
    this.audit.Value = this.auditForm.controls.value.value;
    this.audit.Type = this.auditForm.controls.type.value;
    this.audit.RequiredDocumentAmount = this.auditForm.controls.requiredDocumentAmount.value;
    this.audit.Order = this.auditForm.controls.order.value;
    this.audit.Reference = this.auditForm.controls.reference.value;
    this.audit.Label = this.auditForm.controls.label.value;
    this.audit.QuestionId = this.auditForm.controls.question.value;
  }
}
