<div class="modal-header">
  <button type="button"
          class="btn-close"
          aria-label="Close"
          (click)="activeModal.dismiss(false)">
  </button>
</div>
<div class="modal-body p-3" *ngIf="UserID">
  <app-contact-card [UserID]="UserID"></app-contact-card>
</div>
