import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { IContactCard } from '../../../services/models/user.model';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;
  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadUserByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<IContactCard> = new EventEmitter<IContactCard>();

  // Component Variables
  selectedId: number;
  public selectedUser: IContactCard = null;
  searching: boolean = false;
  searchFailed: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: IContactCard) =>
    (`${result.DisplayName} | ${result.CompanyName}`);

  constructor(private api: ApiService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadUserByID(this.SelectedID);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && (changes.SelectedID.currentValue == null || changes.SelectedID.currentValue == 0)) {
      this.clear();
    }
  }

  public Reset() {
    this.clear();
  }

  searchUser = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.searchFailed = false;
      }),
      switchMap(term =>
        this.getUsers(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  };

  getUsers(searchText: string): Observable<IContactCard[]> {
    if (searchText && searchText.length > 2) {
      // Call City controller "List" endpoint
      return this.api.get(`User/Search/${searchText}`).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
      return of([]);
  }

  loadUserByID(Id: number) {
    this.searching = true;
    // Call Agent controller "Single" endpoint
    this.api.get(`User/ContactCard/${Id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IContactCard) => {
      if (data) {
        this.selectedUser = Object.assign({}, data);
      }
      this.searching = false;
      this.ref.detectChanges();
    });
  }

  onSelect(selectedUser: IContactCard) {
    if (selectedUser && selectedUser.Id) {
      this.OnSelect.emit(selectedUser.Id);
      this.OnSelectObject.emit(selectedUser);
    }
  }

  clear() {
    this.selectedId = null;
    this.selectedUser = null;
    this.searching = false;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
    this.ref.detectChanges();
  }
}
