<div class="modal-header">
  <h4 class="modal-title harmony-teal-text">
    Please Note!
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(null)">

  </button>
</div>
<div class="modal-body">
  <app-loader *ngIf="loading"></app-loader>
  <p class="harmony-grey-text">
    Once the tender is exported, no more changes or configuration of rate groups will be allowed. The Tender can be exported again at any moment, but no further changes are permitted.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="loading" (click)="accept()">Accept</button>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="close()">Cancel</button>
</div>

