<!-- Search Bar -->
<div class="row">
  <div class="col-12">
    <div class="row mt-2 mb-2">
      <div class="col-2 display-field">
        <b>Origin</b>
      </div>
      <div class="col-3">
        <app-port-search class="search-input" [Identifier]="'Origin'" [IsAirport]=true [SelectedID]="originId"
                         (OnSelect)="originId = $event" (OnSelectObject)="origin = $event?.Code">
        </app-port-search>
      </div>
      <div class="col-2">
        <b>From Date</b>
      </div>
      <div class="col-3">
        <app-date-picker [identifier]="'rateFromDate'" (onChange)="fromDate = $event" [title]="'Select a from date'"></app-date-picker>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
                [title]="'Reset search'"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 display-field">
    <b>Destination</b>
  </div>
  <div class="col-3">
    <app-port-search class="search-input" [Identifier]="'Destination'" [IsAirport]=true
                     [SelectedID]="destinationId" (OnSelect)="destinationId = $event"
                     (OnSelectObject)="destination = $event?.Code">
    </app-port-search>
  </div>
  <div class="col-2">
    <b>To Date</b>
  </div>
  <div class="col-3">
    <app-date-picker [identifier]="'toDate'" (onChange)="toDate = $event" [title]="'Select a to date'"></app-date-picker>
  </div>
  <div class="col-2">
    <button class="btn btn-sm btn-primary harmony-teal-button w-100" [disabled]="!canImportRates()"
            (click)="importRates()" [title]="'Import rates by selecting the + icon'">
      Import
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row mt-2 mb-2">
      <div class="col-2 display-field">
        <b>Applicable Product</b>
      </div>
      <div class="col-3">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control form-control-sm filter-input"
                 [placeholder]="'Enter an applicable product...'" [(ngModel)]="applicableProduct">
          <button
            class="input-group-text btn btn-outline-primary harmony-purple-outline-button"
            type="button"
            [title]="'Clear'"
            (click)="clear()">X
          </button>
        </div>
      </div>
      <div class="col-2">
        <b>Rate Source</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm" id="rateSourceType" [(ngModel)]="rateSource">
          <option [ngValue]="null">Select a rate source...</option>
          <option [ngValue]="'G'">General / Loose Cargo Rate</option>
          <option [ngValue]="'U'">Unitized Cargo Rate</option>
          <option [ngValue]="'B'">Basic + Unit Cargo Rate</option>
          <option [ngValue]="'A'">Add On Rate</option>
          <option [ngValue]="'S'">Surcharges</option>
        </select>
      </div>
      <div class="col-2 text-end">
        <button class="btn btn-sm btn-primary harmony-purple-button w-100" [disabled]="!canSearch()"
                (click)="getFreightRates()" [title]="'Search with at least one filter'">
          Search
        </button>
      </div>
    </div>
  </div>
</div>
<hr class="my-1">
<!----- Results  ----->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <!----- Rates Pagination Top----->
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=false
                    [paginationData]=pagination
                    (pageEvent)="changePage($event)"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!----- Table Header ----->
<div class="row header-row mt-2">
  <div class="col-1 header-title border-split-left">
    Origin
  </div>
  <div class="col-1 header-title">
    Final Destination
  </div>
  <div class="col-3 header-title">
    Effective Period
  </div>
  <div class="col-2 header-title">
    Applicable Product
  </div>
  <div class="col-2 header-title">
    Charges Min.
  </div>
  <div class="col-1 header-title">
    Charges Normal
  </div>
  <div class="col-2 header-title border-split-right text-end pe-2">
    Actions
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<!----- Table Data ----->
<div class="row result-row p-1" *ngFor='let rate of rates; let i = index'>
  <div class="col-1 overflow-hidden">
    {{ rate.TransportMovement.ApplicableSector.Origin[0].ID }}
  </div>
  <div class="col-1 overflow-hidden">
    {{ rate.TransportMovement.ApplicableSector.FinalDestination[0].ID }}
  </div>
  <div class="col-3 overflow-hidden">
    {{ rate.EffectivePeriod.EffectiveDate | date }} &nbsp;-> {{ rate.EffectivePeriod.ExpiryDate | date }}
  </div>
  <div class="col-2 overflow-hidden">
    {{ rate.ApplicableProduct.Name}}
  </div>
  <div class="col-2 overflow-hidden">
    {{ rate.Charges?.StandardCharges?.MinimumCharge }}
  </div>
  <div class="col-1 overflow-hidden">
    {{ rate.Charges?.StandardCharges?.NormalCharge }}
  </div>
  <div class="col-2 overflow-hidden">
    <button class="btn btn-sm btn-outline-light harmony-teal-outline-button float-end ms-1"
            (click)="jsonViewRate(rate)" [title]="'View entire Rate as JSON'">
      <b>{{ '{ }' }}</b>
    </button>
    <app-toggle-button [id]="i.toString()" (change)="selectRate(rate)">
    </app-toggle-button>
  </div>
</div>
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <!----- No Results View ----->
    <app-no-result [showNoResults]="noResults"></app-no-result>
    <!----- Rates Pagination Bottom----->
    <app-pagination [showPageNumber]=false
                    [showRowsPerPage]=false
                    [paginationData]=pagination
                    (pageEvent)="changePage($event)"
                    [page]="page">
    </app-pagination>
  </div>
</div>
