import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserManagementComponent } from './user-management/user-management.component';
import { ApiService } from '../../services/api.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionManagementComponent } from './permission-management/permission-management.component';
import { ModuleModalComponent } from './permission-management/module-modal/module-modal.component';
import { PermissionModalComponent } from './permission-management/permission-modal/permission-modal.component';
import { RoleModalComponent } from './permission-management/role-modal/role-modal.component';
import { UserManagementModalComponent } from './user-management/user-management-modal/user-management-modal.component';
import { EmailComponent } from './email/email.component';
import { EmailOverviewComponent } from './email/email-overview/email-overview.component';
import { EmailScheduleOverviewComponent } from './email/email-schedule-overview/email-schedule-overview.component';
import { EditScheduleModalComponent } from './email/email-schedule-overview/edit-schedule-modal/edit-schedule-modal.component';
import { ViewEmailModalComponent } from './email/email-overview/view-email-modal/view-email-modal.component';
import { MemberManagementComponent } from './member-management/member-management.component';
import { ManageMemberModalComponent } from './member-management/manage-member-modal/manage-member-modal.component';
import { AdminRoutingModule } from './admin-routing.module';
import { CompanyService } from '../../services/company.service';
import { UserModuleOverviewComponent } from './user-management/user-module-overview/user-module-overview.component';
import { AgentManagementComponent } from './agent-management/agent-management.component';
import { ManageAgentModalComponent } from './agent-management/manage-agent-modal/manage-agent-modal.component';
import { CreateAgentModalComponent } from './agent-management/create-agent-modal/create-agent-modal.component';
import { MergeAgentsModalComponent } from './agent-management/merge-agents-modal/merge-agents-modal.component';
import { EmailTemplateOverviewComponent } from './email/email-template-overview/email-template-overview.component';
import { EmailTemplateModalComponent } from './email/email-template-overview/email-template-modal/email-template-modal.component';
import { ApplicationLogComponent } from './application-log/application-log.component';
import { ApplicationLogModalComponent } from './application-log/application-log-modal/application-log-modal.component';
import { LocationManagementComponent } from './location-management/location-management.component';
import { CityManagementComponent } from './location-management/city-management/city-management.component';
import { CountryManagementComponent } from './location-management/country-management/country-management.component';
import { StateManagementComponent } from './location-management/state-management/state-management.component';
import { CityFunctionManagementComponent } from './location-management/city-function-management/city-function-management.component';
import { CityEditModalComponent } from './location-management/city-management/city-edit-modal/city-edit-modal.component';
import { StateEditModalComponent } from './location-management/state-management/state-edit-modal/state-edit-modal.component';
import { CountryEditModalComponent } from './location-management/country-management/country-edit-modal/country-edit-modal.component';
import { CityFunctionEditModalComponent } from './location-management/city-function-management/city-function-edit-modal/city-function-edit-modal.component';
import { JobSchedulerComponent } from './job-scheduler/job-scheduler.component';
import { DocumentCategoriesComponent } from './document/document-categories/document-categories.component';
import { DocumentsComponent } from './document/document-management/documents.component';
import { DocumentModalComponent } from './document/document-management/document-modal/document-modal.component';
import { DocumentCategoryModalComponent } from './document/document-categories/document-category-modal/document-category-modal.component';
import { DocumentListComponent } from '../../shared/components/document-list/document-list.component';
import { DocumentTypeIconComponent } from '../../shared/components/document-type-icon/document-type-icon.component';

@NgModule({
  declarations: [
    AdminMenuComponent,
    UserManagementComponent,
    UserProfileComponent,
    PermissionManagementComponent,
    ModuleModalComponent,
    PermissionModalComponent,
    RoleModalComponent,
    UserManagementModalComponent,
    EmailComponent,
    EmailOverviewComponent,
    EmailScheduleOverviewComponent,
    EditScheduleModalComponent,
    ViewEmailModalComponent,
    MemberManagementComponent,
    ManageMemberModalComponent,
    UserModuleOverviewComponent,
    AgentManagementComponent,
    ManageAgentModalComponent,
    CreateAgentModalComponent,
    MergeAgentsModalComponent,
    EmailTemplateOverviewComponent,
    EmailTemplateModalComponent,
    ApplicationLogComponent,
    ApplicationLogModalComponent,
    LocationManagementComponent,
    CityManagementComponent,
    CountryManagementComponent,
    StateManagementComponent,
    CityFunctionManagementComponent,
    CityEditModalComponent,
    StateEditModalComponent,
    CountryEditModalComponent,
    CityFunctionEditModalComponent,
    JobSchedulerComponent,
    DocumentCategoriesComponent,
    DocumentsComponent,
    DocumentModalComponent,
    DocumentCategoryModalComponent
  ],
  exports: [
    AdminMenuComponent,
    UserModuleOverviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FontAwesomeModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    AdminRoutingModule,
    DocumentListComponent,
    DocumentTypeIconComponent
  ],
  providers: [
    ApiService,
    CompanyService
  ]
})

export class AdminModule { }
