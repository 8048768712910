import { Component } from '@angular/core';

@Component({
  selector: 'app-email-template-modal',
  templateUrl: './email-template-modal.component.html',
  styleUrls: ['./email-template-modal.component.scss']
})
export class EmailTemplateModalComponent {

}
