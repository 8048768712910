import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../../services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITenderDto } from '../../../../../services/models/pricing/tender.model';

@Component({
  selector: 'app-add-service-modal',
  templateUrl: './export-rates-modal.component.html',
  styleUrls: ['./export-rates-modal.component.scss']
})
export class ExportRatesModalComponent implements OnInit, OnDestroy {
 // Public variables
  Tender: ITenderDto;

  // Component variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService) { }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  accept() {
    this.loading = true;
    this.Tender.DateFirstExport = new Date();
    this.Tender.TenderStatusId = 3; // Set exported status

    const param = JSON.parse(JSON.stringify(this.Tender));
    param.TenderLocations = null;
    param.TenderFreights = null;
    param.TenderModalities = null;

    this.api.post('Tender/Update', param).pipe(takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ITenderDto) => {
        if (data) {
          this.Tender = Object.assign({} as ITenderDto, data);
          this.activeModal.close(this.Tender);
        } else {
          this.activeModal.close(null);
        }
        this.loading = false;
      }, error: () => {
        this.activeModal.dismiss(null);
        this.loading = false;
      }
    });
  }

  close() {
    this.activeModal.dismiss(null);
  }
}
