import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faCopy,
  faEdit,
  faFileLines,
  faFileText,
  faPlus,
  faSortDown,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { environment } from '../../../../../environments/environment';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import {
  ICompanyChartCategories,
  ICompanyChartCategory,
  ICompanyChartDto,
  ICompanyChartItem,
  ICompanyChartItemsSearchParam
} from '../../../../services/models/hse/hse.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { Subject } from 'rxjs';
import { CompanyService } from '../../../../services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from '../../../../services/hse.service';
import { CompanyChartItemModalComponent } from '../company-chart-item-modal/company-chart-item-modal.component';
import {
  CompanyChartInformationModalComponent
} from '../company-chart-information-modal/company-chart-information-modal.component';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { getDayDifference } from '../../../../shared/functions/datetime-functions';
import {
  CompanyChartCategoryModalComponent
} from '../company-chart-category-modal/company-chart-category-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-company-chart-view',
  templateUrl: './company-chart-view.component.html',
  styleUrls: ['./company-chart-view.component.scss']
})
export class CompanyChartViewComponent {
  @Input()
  set CompanyChart(companyChart: ICompanyChartDto) {
    if (companyChart) {
      this.companyChart = companyChart;
      this.companyId = this.companyChart.CompanyId;
      this.loadCompanyChartCategories();
    } else {
      this.companyChart = null;
      this.back();
    }
  }

  @Output() OnClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faFile = faFileLines;
  faSortAsc = faSortDown;
  faAdd = faPlus;
  faInfo = faCircleQuestion;
  faCopy = faCopy;

  // Component Variables
  companyChart: ICompanyChartDto;
  companyId: number;
  ccItemId: number | null = null;
  companyChartCategories: ICompanyChartCategories[];
  companyChartItems: ICompanyChartItem[];
  environment = environment;
  orderParam: IOrderParam;
  itemOrderParam: IOrderParam;
  public companyChartItemsSearchParam: ICompanyChartItemsSearchParam = {
    CompanyChartId: null,
    SearchCriteria: ''
  }
  isExpired: boolean;
  category: ICompanyChartCategory;
  contactValid: boolean = true;

  // Permissions
  public readonly PermissionCodes = PermissionCodes;
  canCreateNew: boolean;
  canEditInformation: boolean;
  canDeleteItems: boolean;
  canUpload: boolean;
  canDeleteCategories: boolean;

  // General Variables
  loading: boolean;
  loadingCompanyChart: boolean;
  noResult: boolean = false;
  searching: boolean = false;

  protected readonly getDayDifference = getDayDifference;
  private unsubscribe: Subject<any> = new Subject<any>();
  companies$ = this.companyService.ActiveMembers$.asObservable();

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  ngOnInit() {
    this.itemOrderParam = {
      OrderBy: 'Subject',
      OrderDirection: 'asc'
    } as IOrderParam;

    if (this.authService.CurrentUser) {
      this.setPermissions();
    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
        });
    }

    this.route.paramMap.subscribe(paramMap => {
      const itemId = paramMap.get('ccItemId');

      if (itemId) {
        this.ccItemId = +itemId;
      }
    });
  }

  setPermissions() {
    this.canCreateNew = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_CreateNew);
    this.canEditInformation = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_EditInformation);
    this.canDeleteItems = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_Delete);
    this.canUpload = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_UploadDocument);
    this.canDeleteCategories = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_DeleteCategories);
  }

  loadCompanyChartCategories() {
    this.loadingCompanyChart = true;

    this.hseService.getCompanyChartCategoriesByCompanyChartId(this.companyChart.Id)
      .subscribe({
        next: (data): void => {
          this.companyChartCategories = Object.assign([], data);
          this.companyChartItemsSearchParam.CompanyChartId = this.companyChart.Id;
          this.hseService.searchCompanyChartItems(this.companyChartItemsSearchParam)
            .subscribe({
              next: (data): void => {
                this.companyChartItems = Object.assign([], data);
                this.calcCategoryCounts();
                // If an itemId is provided in the route, open the item automatically
                if (this.ccItemId) {
                  this.openCompanyChartItem(this.ccItemId);
                }

                // Only filter categories when searching
                if (this.companyChartItemsSearchParam?.SearchCriteria?.length > 0) {
                  this.searching = true;
                  const filteredCategories = this.companyChartItems.map(o => o.CategoryId).filter((v, i, a) => a.indexOf(v) === i)
                  this.companyChartCategories = this.companyChartCategories.filter(i => filteredCategories.includes(i.Id));
                } else {
                  this.searching = false;
                }

                this.loadingCompanyChart = false;
              },
              error: (): void => {
                this.loadingCompanyChart = false;
              }
            });
        },
        error: (): void => {
          this.loadingCompanyChart = false;
        }
      });
  }

  orderItems(param: IOrderParam) {
    this.itemOrderParam = param;
    const p1 = param.OrderDirection == 'asc' ? 1 : -1;
    const asc = param.OrderDirection == 'asc';
    const p2 = p1 * -1;

    switch (param.OrderBy) {
      case 'Subject':
        this.companyChartItems.sort((a, b) => a.Subject > b.Subject ? p1 : p2);
        break;
      case 'Action':
        this.companyChartItems.sort((a, b) => a.Action > b.Action ? p1 : p2);
        break;
      case 'ResponsibleManager':
        this.companyChartItems.sort((a, b) => a.ResponsibleManager > b.ResponsibleManager ? p1 : p2);
        break;
      case 'CcBy':
        this.companyChartItems.sort((a, b) => a.CcBy > b.CcBy ? p1 : p2);
        break;
      case 'Next':
        this.companyChartItems.sort(this.sortDates(asc));
        break;
      case 'StatusDescription':
        this.companyChartItems.sort(this.sortStatus(asc));
        break;
    }
  }

  sortDates(ascending: boolean) {
    return function (a: ICompanyChartItem, b: ICompanyChartItem) {
      // equal items sort equally
      if (a.Next === b.Next) {
        return 0;
      }
      // nulls sort after anything else
      if (a.Next === null) {
        return 1;
      }
      if (b.Next === null) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return a.Next < b.Next ? -1 : 1;
      }
      // if descending, highest sorts first
      return a.Next < b.Next ? 1 : -1;
    };
  }

  sortStatus(ascending: boolean) {
    return function (a: ICompanyChartItem, b: ICompanyChartItem) {
      // equal items sort equally
      if (a.StatusDescription === b.StatusDescription) {
        return 0;
      }
      // nulls sort after anything else
      if (a.StatusDescription === null) {
        return 1;
      }
      if (b.StatusDescription === null) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return a.StatusDescription < b.StatusDescription ? -1 : 1;
      }
      // if descending, highest sorts first
      return a.StatusDescription < b.StatusDescription ? 1 : -1;
    };
  }

  calcCategoryCounts() {
    this.companyChartCategories.forEach((category) => {
      category.TotalCount = this.getCompanyChartItems(category.Id).length;
      category.AttentionCount = this.companyChartItems.filter(x => x.CategoryId === category.Id && x.CcStatus == 2).length;
      category.OverdueCount = this.companyChartItems.filter(x => x.CategoryId === category.Id && x.CcStatus == 3).length;
    });
  }

  getCompanyChartItems(categoryId: number): ICompanyChartItem[] {
    const items = this.companyChartItems.filter(x => x.CategoryId === categoryId);
    return items ?? [];
  }

  openCompanyChartItem(itemId: number) {
    const item = this.companyChartItems.find(x => x.Id === itemId);
    if (item) {
      this.openCompanyChartItemModal(item);
    } else {
      this.alertService.info(`Item with ID ${itemId} not found`);
    }
  }

  back() {
    this.OnClose.emit(true);
  }

  editCompanyChartName() {
    if (this.companyChart?.ContactEmail?.length > 0 && this.contactValid == false && this.companyChart?.Name?.length > 0) {
      this.hseService.updateCompanyChart(this.companyChart)
        .subscribe({
          next: () => {
            this.alertService.info('Company chart name successfully updated.');
          },
          error: (error) => {
            this.alertService.error(error.Message);
          }
        });
    } else {
      this.alertService.error('Please fill all required fields outlined red.');
    }
  }

  openCompanyChartItemModal(companyChartItem: ICompanyChartItem) {
    const modalRef = this.modalService.open(CompanyChartItemModalComponent, {
      size: 'xl',
      windowClass: 'modal-2xl',
      backdrop: 'static'
    });

    if (companyChartItem != null) {
      modalRef.componentInstance.CompanyChartItem = Object.assign({}, companyChartItem);
      modalRef.componentInstance.CompanyId = this.companyId;
      modalRef.componentInstance.title = "Edit company chart item";
    }

    // On modal close, read the result and apply logic
    modalRef.result.then((result: ICompanyChartItem) => {
      if (result != null && result.Id != null) {
        companyChartItem.Subject = result.Subject;
        companyChartItem.MakeType = result.MakeType;
        companyChartItem.Year = result.Year;
        companyChartItem.Action = result.Action;
        companyChartItem.NrOfItems = result.NrOfItems;
        companyChartItem.Standard = result.Standard;
        companyChartItem.Last = result.Last;
        companyChartItem.Next = result.Next;
        companyChartItem.CcStatus = result.CcStatus;
        companyChartItem.Frequency = result.Frequency;
        companyChartItem.ResponsibleManager = result.ResponsibleManager;
        companyChartItem.CcBy = result.CcBy;
        companyChartItem.NrOfAttachments = result.NrOfAttachments;
        companyChartItem.Remarks = result.Remarks;
        companyChartItem.CategoryId = result.CategoryId;
      }
      this.calcCategoryCounts();
    }, () => {
    });
  }

  copyCompanyChartItem(companyChartItem: ICompanyChartItem) {
    const modalRef = this.modalService.open(CompanyChartItemModalComponent, {
      size: 'xl',
      windowClass: 'modal-2xl',
      backdrop: 'static'
    });

    modalRef.componentInstance.title = "Copy company chart item";

    modalRef.componentInstance.CompanyChartItem = {
      Action: companyChartItem.Action,
      Active: companyChartItem.Active,
      CategoryId: companyChartItem.CategoryId,
      CcBy: companyChartItem.CcBy,
      CcStatus: companyChartItem.CcStatus,
      CompanyChartId: companyChartItem.CompanyChartId,
      CreatedByUserId: companyChartItem.CreatedByUserId,
      DateCreated: companyChartItem.DateCreated,
      DateModified: companyChartItem.DateModified,
      Frequency: companyChartItem.Frequency,
      Id: 0,
      Last: companyChartItem.Last,
      MakeType: companyChartItem.MakeType,
      ModifiedByUserId: companyChartItem.ModifiedByUserId,
      Next: companyChartItem.Next,
      NrOfAttachments: companyChartItem.NrOfAttachments,
      NrOfItems: companyChartItem.NrOfItems,
      Remarks: companyChartItem.Remarks,
      ResponsibleManager: companyChartItem.ResponsibleManager,
      Standard: companyChartItem.Standard,
      StatusDescription: companyChartItem.StatusDescription,
      Subject: companyChartItem.Subject,
      Year: companyChartItem.Year
    };
    modalRef.componentInstance.CompanyId = this.companyChart.CompanyId;
    modalRef.componentInstance.IsNew = true;

    // On modal close, read the result and apply logic
    modalRef.result.then((companyChartItem: ICompanyChartItem) => {
      if (companyChartItem != null && companyChartItem.Id > 0) {
        this.companyChartItems.push(companyChartItem);
      }
    }, () => {
    });
  }

  addCompanyChartItem(categoryId: number) {
    const modalRef = this.modalService.open(CompanyChartItemModalComponent, {
      size: 'xl',
      windowClass: 'modal-2xl',
      backdrop: 'static'
    });

    modalRef.componentInstance.CompanyChartItem = {
      Id: 0,
      CompanyChartId: this.companyChart.Id,
      CategoryId: categoryId,
      DateCreated: new Date(),
      DateModified: null,
      CreatedByUserId: this.authService.CurrentUser.UserId,
      ModifiedByUserId: this.authService.CurrentUser.UserId
    } as ICompanyChartItem;
    modalRef.componentInstance.CompanyId = this.companyChart.CompanyId;
    modalRef.componentInstance.IsNew = true;
    modalRef.componentInstance.title = "New company chart item";

    // On modal close, read the result and apply logic
    modalRef.result.then((companyChartItem: ICompanyChartItem) => {
      if (companyChartItem != null && companyChartItem.Id > 0) {
        this.companyChartItems.push(companyChartItem);
      }
    }, () => {
    });
  }

  openInfoModal() {
    const modalRef = this.modalService.open(CompanyChartInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.companyChart.CompanyId;
    modalRef.componentInstance.hasAccess = this.canEditInformation;
  }

  deleteCompanyChartItem(companyChartItem: ICompanyChartItem) {
    const message = 'Are you sure you want to delete this Company chart item?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer && companyChartItem != null) {
          this.loading = true;
          this.hseService.deleteCompanyChartItem(companyChartItem.Id)
            .subscribe({
              next: () => {
                this.alertService.info(`Company chart item '${companyChartItem.Subject}' deleted successfully.`);
                this.companyChartItems.splice(this.companyChartItems.findIndex(f => f.Id == companyChartItem.Id), 1);
                this.loading = false;
              },
              error: () => {
                this.alertService.info('Failed to delete the Company chart item.');
                this.loading = false;
              }
            });
        }
      });
  }

  editCategory(category: ICompanyChartCategory) {
    const modalRef = this.modalService.open(CompanyChartCategoryModalComponent, {size: 'lg', backdrop: 'static'});

    modalRef.componentInstance.category = category;
    modalRef.componentInstance.companyChart = this.companyChart;

    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.loadCompanyChartCategories();
      }
    }, () => {
    });
  }

  createCategory() {
    const modalRef = this.modalService.open(CompanyChartCategoryModalComponent, {size: 'lg', backdrop: 'static'});

    this.category = {
      Id: 0,
      CompanyChartId: this.companyChart.Id,
      DateCreated: new Date(),
      Description: '',
      Active: true,
      OrderBy: null
    }

    modalRef.componentInstance.category = this.category;
    modalRef.componentInstance.companyChart = this.companyChart;

    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.loadCompanyChartCategories();
      }
    }, () => {
    });
  }

  deleteCategory(categoryId: number) {
    let message = 'Are you sure you want to delete this category. It can not be undone?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.hseService.deleteCompanyChartCategory(this.companyChart.Id, categoryId)
            .subscribe({
              next: () => {
                this.alertService.info('Company chart category successfully deleted.');
                this.loadCompanyChartCategories();
              },
              error: (error) => {
                this.alertService.error(error.Message);
              }
            });
        }
      });
  }

  resetSearch() {
    // Set search param
    this.companyChartItemsSearchParam.SearchCriteria = '';
    this.loadCompanyChartCategories();
  }

  setStatusColor(item: ICompanyChartItem): string {
    let status = item.CcStatus
    if (status == 1) {
      return 'cc-status-green';
    }
    if (status == 2) {
      return 'cc-status-orange';
    }
    if (status == 3) {
      return 'cc-status-red';
    }
    if (status == 4) {
      return 'cc-status-grey';
    }
    return '';
  }
}
