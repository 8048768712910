<div id="tool-accordion">
  <div class="card portal-menu-collapse-card">
<!--    <a [routerLink]="['pricing/dashboard']">-->
      <div class="portal-menu-collapse-header bg-dark"
           id="headingOne"
           data-bs-toggle="collapse"
           data-bs-target="#collapseTools"
           aria-expanded="true"
           aria-controls="collapseTools">
        <h5 class="portal-menu-heading">
          <span class="heading-icon icon-color">
            <fa-icon [icon]="faToolbox"></fa-icon>
          </span>
          <span class="portal-menu-module-name"
                [ngClass]="{'harmony-teal-text': router.url.includes('pricing/')}">
            Pricing Tools
          </span>
        </h5>
      </div>
<!--    </a>-->
    <div id="collapseTools" class="collapse" aria-labelledby="headingOne" data-bs-parent="#tool-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-3" style="background-color: transparent !important;">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="pricingDashboard">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/dashboard']"
                 [ngClass]="{'active': router.url.endsWith('pricing/dashboard')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Dashboard
              </a>
            </li>
            <li class="nav-item" *ngIf="tenderTool">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/rate-tender']"
                 [ngClass]="{'active': router.url.includes('rate-tender')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Rate Tender
              </a>
            </li>
            <li class="nav-item" *ngIf="singleRate">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/rate-calc']"
                 [ngClass]="{'active': router.url.includes('rate-calc')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Single-Lane Rates
              </a>
            </li>
            <li class="nav-item" *ngIf="rateGroupManager">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/rate-group-manager']"
                 [ngClass]="{'active': router.url.includes('rate-group-manager')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Rate Group Manager
              </a>
            </li>
            <li class="nav-item" *ngIf="captureRates">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/rate-capture']"
                 [ngClass]="{'active': router.url.includes('rate-capture')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Capture Rates
              </a>
            </li>
            <li class="nav-item" *ngIf="requestRates">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="requestRateSubMenu.hidden = !requestRateSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('rate-request')}">
                      <fa-icon [rotate]="requestRateSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Request Rates
                    </a>
                  </div>
                </div>
                <div #requestRateSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/rate-request/manage']"
                         [ngClass]="{'active': router.url.includes('rate-request/manage')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Manage Requests
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/rate-request/send']"
                         [ngClass]="{'active': router.url.includes('rate-request/send')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Send Requests
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/rate-request/templates']"
                         [ngClass]="{'active': router.url.includes('rate-request/templates')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Templates
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="failedRates">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/failed-rates']"
                 [ngClass]="{'active': router.url.includes('failed-rates')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Failed Rates
              </a>
            </li>
            <li class="nav-item" *ngIf="preferredAgents">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/preferred-agents']"
                 [ngClass]="{'active': router.url.includes('preferred-agents')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Preferred Agents
              </a>
            </li>
            <li class="nav-item" *ngIf="externalData">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="externalDataSubMenu.hidden = !externalDataSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('external-data-manager')}">
                      <fa-icon [rotate]="externalDataSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> External Data
                    </a>
                  </div>
                </div>
                <div #externalDataSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer" *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/external-data-manager/prism']"
                         [ngClass]="{'active': router.url.includes('external-data-manager/prism')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Prism
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer" *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/external-data-manager/iata']"
                         [ngClass]="{'active': router.url.includes('external-data-manager/iata')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Iata
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer" *ngIf="captureRates">
                      <a class="sub-link"
                         [routerLink]="['pricing/external-data-manager/sea-rates']"
                         [ngClass]="{'active': router.url.includes('external-data-manager/sea-rates')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Sea Rates
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="pricingAdmin">
              <a class="nav-link pointer"
                 [routerLink]="['pricing/pricing-admin']"
                 [ngClass]="{'active': router.url.includes('pricing-admin')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Admin
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
