import { EditorOptions } from 'tinymce';

export const editorConfig = {
  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  // plugins: 'image',
  selector: 'tiny-editor',
  schema: 'html5',
  valid_styles: {
    '*': 'border,font-size,color',
    'div': 'width,height,color'
  },
  tinycomments_mode: 'embedded',
  tinycomments_author: 'Author name',
  mergetags_list: [
    {value: 'First.Name', title: 'First Name'},
    {value: 'Email', title: 'Email'}
  ]
} as unknown as EditorOptions;
