import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faImage
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-document-type-icon',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './document-type-icon.component.html',
  styleUrls: ['./document-type-icon.component.scss']
})
export class DocumentTypeIconComponent {

  // Inputs
  @Input() Extension: string = null;

  // Icons
  protected readonly faTypePdf = faFilePdf;
  protected readonly faTypeDefault = faFile;
  protected readonly faTypeImage = faImage;
  protected readonly faTypeImageAlt = faFileImage;
  protected readonly faTypeWord = faFileWord;
  protected readonly faTypeExcel = faFileExcel;
  protected readonly faTypeCsv = faFileCsv;
  protected readonly faTypePP = faFilePowerpoint;
}
