import { Component, OnDestroy, OnInit } from '@angular/core';
import { IFreightCombinations, ITenderFreight, ITenderLocation } from '../../../../services/models/pricing/tender.model';
import { TenderService } from '../../../../services/tender.service';
import { environment } from '../../../../../environments/environment';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { PricingService } from '../../../../services/pricing.service';
import { IModalityType } from '../../../../services/models/pricing/rates.model';

@Component({
  selector: 'app-step-freight',
  templateUrl: './step-freight.component.html',
  styleUrls: ['./step-freight.component.scss']
})
export class StepFreightComponent implements OnInit, OnDestroy {
  // Icons
  faInfo = faCircleQuestion;

  // Component Variables
  mailToString: string = '';
  originLocations: ITenderLocation[];
  destinationLocations: ITenderLocation[];
  freightCombinations: IFreightCombinations[] = [];
  locationCount: number = 0;
  locationLimit: number = 50;
  freightModalityTypes: IModalityType[] = null;

  // Lookup variables
  modalityType$ = this.pricingService.ModalityTypes$.asObservable();

  // General variables
  loading: boolean = false;

  constructor(public tenderService: TenderService,
              public pricingService: PricingService,
              private authService: AdAuthService) { }

  ngOnInit() {
    this.locationCount = this.tenderService.TenderView.TenderLocations.length;
    this.originLocations = this.tenderService.TenderView.TenderLocations.filter((x) => x.IsOrigin === true).sort((a,b) => a.PartnerLocationName < b.PartnerLocationName ? -1 : 1);
    this.destinationLocations = this.tenderService.TenderView.TenderLocations.filter((x) => x.IsDestination === true).sort((a,b) => a.PartnerLocationName < b.PartnerLocationName ? -1 : 1);
    this.locationLimit = environment.Pricing.TenderLocationFreightCapacity;

    this.modalityType$.subscribe(data => {
      if (data && data.length > 0) {
        this.freightModalityTypes = data.filter((x) => x.Name.toLowerCase() != 'lcl' && x.Name.toLowerCase() != 'road');
        this.loadTenderFreights();
      }
    });

    if (this.locationCount > this.locationLimit) {
      // Set email data
      const u = this.authService.CurrentUser.User;
      const t = this.tenderService.TenderView;
      const emailAddress = environment.Pricing.TenderEmail;
      const emailSubject = 'Tender Freight Rate Request For Tender ID: ' + t.Id;
      const emailBody = `I, ${u.FirstName} ${u.LastName}, would like to request Freight rates for Tender ID: ${t.Id}.
      The tender consists of ${this.locationCount} locations and reached the capacity for generating freight rates through the online tool.`;

      this.mailToString = 'mailto:' + emailAddress + '?subject=' + emailSubject + '&body=' + emailBody
    }
  }

  ngOnDestroy(): void {
    this.tenderService.TenderFreightUpdateList = [];
  }

  loadTenderFreights() {
    this.loading = true;

    this.tenderService.GetTenderFreights(this.tenderService.TenderView.Id).subscribe({
      next: (data) => {
        if (data) {
          this.tenderService.TenderView.TenderFreights = Object.assign([], data);
          this.initMatrices();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  initMatrices() {
    if (this.originLocations && this.destinationLocations && this.freightModalityTypes) {
      // Get modality types to be mapped, only map ones in use
      const modalityTypes = [];
      this.pricingService.ModalityTypes$.getValue().forEach((modality: IModalityType) => {
        if (this.getTenderModalityByModalityTypeID(modality.Id).length > 0) {
          modalityTypes.push(modality);
        }
      });

      // Loop through each origin location and map all possible destination combinations to each one, for each modality type in use
      this.originLocations.forEach((origin: ITenderLocation) => {
        this.destinationLocations.forEach((destination: ITenderLocation) => {
          modalityTypes.forEach((modality: IModalityType) => {
            this.freightCombinations.push({
              ModalityTypeID: modality.Id,
              Origin: origin,
              Destination: destination,
              IsSelected: false
            } as IFreightCombinations);
          });
        });
      });

      // Map the selected options loaded from the TenderFreights table
      this.mapSelected();
    }
  }

  isActiveTab(modalityTypeID: number) {
    const firstModality = this.tenderService.TenderView.TenderModalities.sort((a, b) => a.ModalityTypeId - b.ModalityTypeId)[0];

    return modalityTypeID === firstModality.ModalityTypeId;
  }

  mapSelected() {
    this.freightCombinations.forEach((freight) => {
      const selected = this.tenderService.TenderView.TenderFreights.find((x) =>
        x.OriginTenderLocationId === freight.Origin.Id &&
        x.DestinationTenderLocationId === freight.Destination.Id &&
        x.ModalityTypeId === freight.ModalityTypeID &&
        x.Active === true
      );

      if (selected) {
        freight.IsSelected = true;
      }
    });
  }

  toggleCombination(combination: IFreightCombinations) {
    if (!this.tenderService.IsQuoteAccepted()) {
      if (combination.Origin.Id !== combination.Destination.Id) {
        // Find index of TenderFreight object within array
        const freight = this.tenderService.TenderView.TenderFreights.find((x) =>
          x.OriginTenderLocationId === combination.Origin.Id &&
          x.DestinationTenderLocationId === combination.Destination.Id &&
          x.ModalityTypeId === combination.ModalityTypeID
        );

        // If IsSelected is false, it means that it has now been selected and must be added to the TenderFreight array
        if (combination && combination.IsSelected === false) {
          // Mark freight combination as selected
          combination.IsSelected = true;
          // If this combination was previously selected and saved in the database, loop through the applicable records and mark them as active again.
          if (freight && freight.Id) {
            // Update TenderFreight record
            freight.Active = true;
            this.tenderService.MarkTenderFreightForUpdate(freight);
          } else {
            // Add a new TenderFreight object to the array to be saved to the database
            const newFreight = {
              Id: null,
              DateCreated: new Date(),
              DateModified: null,
              Active: true,
              TenderId: this.tenderService.TenderView.Id,
              ModalityTypeId: combination.ModalityTypeID,
              OriginTenderLocationId: combination.Origin.Id,
              DestinationTenderLocationId: combination.Destination.Id,
              TenderFreightRateGroups: []
            } as ITenderFreight;
            // Push onto array to be saved in database
            this.tenderService.TenderView.TenderFreights.push(newFreight);
            this.tenderService.MarkTenderFreightForUpdate(newFreight);
          }
        } else {
          combination.IsSelected = false;
          // If it has already been saved to the database, mark as inactive, else, remove from array entirely
          if (freight && freight.Id) {
            freight.Active = false;
            this.tenderService.MarkTenderFreightForUpdate(freight);
          } else {
            const index: number = this.tenderService.TenderView.TenderFreights.indexOf(freight);
            const indexUpdate: number = this.tenderService.TenderFreightUpdateList.indexOf(freight);
            if (index !== -1) {
              this.tenderService.TenderView.TenderFreights.splice(index, 1);
              this.tenderService.TenderFreightUpdateList.splice(indexUpdate, 1);
            }
          }
        }
      }
    }
  }

  selectAll(modalityType: IModalityType) {
    if (!this.tenderService.IsQuoteAccepted()) {
      this.freightCombinations.forEach((freight: IFreightCombinations) => {
        if (freight.ModalityTypeID === modalityType.Id) {
          // Mark as false, then toggle will mark as true
          freight.IsSelected = false;
          this.toggleCombination(freight);
        }
      });
    }
  }

  unselectAll(modalityType: IModalityType) {
    if (!this.tenderService.IsQuoteAccepted()) {
      this.freightCombinations.forEach((freight: IFreightCombinations) => {
        if (freight.ModalityTypeID === modalityType.Id) {
          // Mark as false, then toggle will mark as true
          freight.IsSelected = true;
          this.toggleCombination(freight);
        }
      });
    }
  }

  toggleColumn(destination: ITenderLocation, modalityType: IModalityType) {
    if (!this.tenderService.IsQuoteAccepted() && this.tenderService.loading === false) {
      this.freightCombinations.forEach((freight: IFreightCombinations) => {
        if (freight.Destination.Id === destination.Id &&
          freight.Origin.Id !== destination.Id &&
          freight.ModalityTypeID === modalityType.Id) {
          this.toggleCombination(freight);
        }
      });
    }
  }

  toggleRow(origin: ITenderLocation, modalityType: IModalityType) {
    if (!this.tenderService.IsQuoteAccepted() && this.tenderService.loading === false) {
      this.freightCombinations.forEach((freight: IFreightCombinations) => {
        if (freight.Origin.Id === origin.Id &&
          freight.Destination.Id !== origin.Id &&
          freight.ModalityTypeID === modalityType.Id) {
          this.toggleCombination(freight);
        }
      });
    }
  }

  getTooltip(origin: ITenderLocation, destination: ITenderLocation) {
    const originName = origin.PartnerLocationName;
    const destinationName = destination.PartnerLocationName;
    return `${originName}  →  ${destinationName}`;
  }

  getTenderModalityByModalityTypeID(modalityTypeID: number) {
    if (this.tenderService.TenderView.TenderModalities && this.tenderService.TenderView.TenderModalities.length > 0) {
      return this.tenderService.TenderView.TenderModalities.filter((p) => p.ModalityTypeId === modalityTypeID);
    }
  }

  getOptionsByOrigin(freightArray: IFreightCombinations[], originID: number, modalityTypeID: number) {
    if (freightArray) {
      return freightArray.filter((x) => x.Origin.Id.toString() === originID.toString() && x.ModalityTypeID === modalityTypeID);
    }
  }
}
