import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-data-manager',
  templateUrl: './external-data-manager.component.html',
  styleUrls: ['./external-data-manager.component.scss'],
  providers: [DatePipe]
})

export class ExternalDataManagerComponent implements OnInit {
  activeTab: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      const routeParam = paramMap.get('activeTab');

      if (routeParam) {
        this.activeTab = routeParam;
      } else {
        this.activeTab = 'prism';
      }
    });
  }
}
