import { Component, NgZone, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { CompanyService } from '../../../services/company.service';
import {
  faBoxOpen,
  faCodeMerge,
  faPlus, faSortUp,
  faSyncAlt,
  faTrash,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { IAuthUser } from '../../../services/models/auth.model';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IAgentSearchParam, IAgentSearchResult } from '../../../services/models/pricing/agent.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ManageAgentModalComponent } from './manage-agent-modal/manage-agent-modal.component';
import { CreateAgentModalComponent } from './create-agent-modal/create-agent-modal.component';
import { MergeAgentsModalComponent } from './merge-agents-modal/merge-agents-modal.component';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-agent-management',
  templateUrl: './agent-management.component.html',
  styleUrls: ['./agent-management.component.scss']
})
export class AgentManagementComponent implements OnInit {
  // Icons
  faEdit = faUserEdit;
  faRefresh = faSyncAlt;
  faRemove = faTrash;
  faAdd = faPlus;
  faEmpty = faBoxOpen;
  faMerge = faCodeMerge;
  faSortDesc = faSortUp;

  // Company variables
  paginationData: IPaginationData<IAgentSearchResult>;
  orderParam: IOrderParam;
  searchParam: IAgentSearchParam;
  page: IPage;
  now: Date = new Date();
  canEdit: boolean = false;

  // General Variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private zone: NgZone,
              private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.loading = true;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set filter
    this.searchParam = {
      SearchText: null,
      CompanyId: null,
      AgentId: null,
      CountryId: null,
      CityName: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;
    // Load agents
    this.searchAgents();
  }

  resetSearch() {
    this.searchParam.CompanyId = null;
    this.searchParam.AgentId = null;
    this.searchParam.CountryId = null;
    this.searchParam.SearchText = null;
    this.searchParam.CityName = null;
    this.searchParam.CurrentPage = 1;
    this.resetPaginationData();
    this.searchAgents();
  }

  resetPaginationData() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;
    this.setPage();
  }

  setPage() {
    this.page.pageNumber = this.paginationData.CurrentPage;
    this.page.pageSize = this.paginationData.PageSize;
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.searchParam.CurrentPage = page.pageNumber;
    this.searchParam.RowCount = page.pageSize;

    this.searchAgents();
  }

  search() {
    this.resetPaginationData();
    this.searchParam.CurrentPage = 1;
    this.searchAgents();
  }

  searchAgents() {
    const o = this.orderParam;
    return this.api.post(`Company/SearchAgents?orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        this.paginationData = Object.assign({}, data);
        this.loading = false;
      },
      error: () => {
        this.alertService.warn('An error has occurred while searching for agents.');
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchAgents();
  }

  editAgent(agent: IAgentSearchResult) {
    this.zone.run(() => {
      // Open modal to create a new user
      const modalRef = this.modalService.open(ManageAgentModalComponent, {
        size: 'xl',
        windowClass: 'modal-2xl',
        backdrop: 'static'
      });
      modalRef.componentInstance.LoadAgent(agent.CompanyId);
      // On modal close, read the result and apply logic
      modalRef.result.then((result: IAuthUser) => {
        if (result) {
          this.searchAgents();
        }
      }, () => {
      });
    });
  }

  deleteAgent(companyId: number) {
    if (confirm('The agent will be marked as inactive. This is a permanent change. Continue?')) {
      this.companyService.DeleteAgent(companyId).subscribe({
        next: () => {
          this.searchAgents();
        },
        error: () => {
          this.alertService.warn('An error has occurred while attempting to delete the agent.');
        }
      });
    }
  }

  newAgent() {
    this.zone.run(() => {
      // Open modal to create a new user
      const modalRef = this.modalService.open(CreateAgentModalComponent, {
        size: 'xl',
        backdrop: 'static'
      });
      // On modal close, read the result and apply logic
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.searchAgents();
        }
      }, () => {
      });
    });
  }

  mergeAgents() {
    this.zone.run(() => {
      // Open modal to create a new user
      const modalRef = this.modalService.open(MergeAgentsModalComponent, {
        size: 'lg',
        backdrop: 'static'
      });
      // On modal close, read the result and apply logic
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.searchAgents();
        }
      }, () => {
      });
    });
  }
}
