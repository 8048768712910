import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagementComponent } from './user-management/user-management.component';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { PermissionManagementComponent } from './permission-management/permission-management.component';
import { EmailComponent } from './email/email.component';
import { MemberManagementComponent } from './member-management/member-management.component';
import { AgentManagementComponent } from './agent-management/agent-management.component';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { ApplicationLogComponent } from './application-log/application-log.component';
import { LocationManagementComponent } from './location-management/location-management.component';
import { JobSchedulerComponent } from './job-scheduler/job-scheduler.component';
import { DocumentsComponent } from './document/document-management/documents.component';
import { DocumentCategoriesComponent } from './document/document-categories/document-categories.component';

const routes: Routes = [
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Admin_User_Management
    }
  },
  {
    path: 'permission-management',
    component: PermissionManagementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Admin_PermissionManagement
    }
  },
  {
    path: 'email',
    component: EmailComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_Emailing
    }
  },
  {
    path: 'manage-members',
    component: MemberManagementComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_MemberManagement
    }
  },
  {
    path: 'manage-agents',
    component: AgentManagementComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_AgentManagement
    }
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_DocumentManagement
    }
  },
  {
    path: 'documents/categories',
    component: DocumentCategoriesComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_DocumentCategories
    }
  },
  {
    path: 'location-management/:activeTab',
    component: LocationManagementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Admin_LocationManagement
    }
  },
  {
    path: 'application-log',
    component: ApplicationLogComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_ApplicationLog
    }
  },
  {
    path: 'job-scheduler',
    component: JobSchedulerComponent,
    canActivate: [ AuthGuard ],
    data: {
      ExpectedPermission: PermissionCodes.Admin_JobScheduler
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
