import { Component, OnInit } from '@angular/core';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { ICompany, ICompanySearchResult } from '../../../../services/models/member.model';
import { CompanyService } from '../../../../services/company.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-manage-member-modal',
  templateUrl: './merge-agents-modal.component.html',
  styleUrls: ['./merge-agents-modal.component.scss']
})
export class MergeAgentsModalComponent implements OnInit {
  // Icons
  faInfo = faCircleQuestion;
  faArrowRight = faArrowRight;

  // Component Variables
  agentMergeFrom: ICompanySearchResult;
  mergeFromCompanyId: number = null;
  agentMergeTo: ICompanySearchResult;
  mergeToCompanyId: number = null;
  invalid: boolean = false;

  // General variables
  env = environment;
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public auth: AdAuthService,
              private companyService: CompanyService,
              private alertService: AlertService) { }

  ngOnInit(): void { }

  setMergeFromCompany(company: ICompanySearchResult) {
    if (company == null) {
      this.mergeFromCompanyId = null;
      this.agentMergeFrom = null;
    } else {
      this.mergeFromCompanyId = company.CompanyId;
      if (this.agentMergeTo?.CompanyId != this.mergeFromCompanyId) {
        this.agentMergeFrom = company;
      } else {
        this.alertService.warn("Cannot merge from the same company.");
        setTimeout(() => {
          this.mergeFromCompanyId = null;
        }, 200);
      }
    }
  }

  setMergeToCompany(company: ICompanySearchResult) {
    if (company == null) {
      this.mergeToCompanyId = null;
      this.agentMergeTo = null;
    } else {
      this.mergeToCompanyId = company.CompanyId;
      if (this.agentMergeFrom?.CompanyId != this.mergeToCompanyId) {
        this.agentMergeTo = company;
      } else {
        this.alertService.warn("Cannot merge to the same company.");
        setTimeout(() => {
          this.mergeToCompanyId = null;
        }, 200);
      }
    }
  }

  canSave() {
    let isValid = false;
    // Check if "old" agent is
    if (this.agentMergeFrom && this.agentMergeTo) {
      isValid = (this.agentMergeFrom.AgentId && this.agentMergeFrom.AgentId > 0 && this.agentMergeTo.CompanyId > 0);
    }

    // Return result for button
    return isValid;
  }

  reset() {
    this.agentMergeFrom = null;
    this.agentMergeTo = null;
  }

  save() {
    if (this.canSave()) {
      if (confirm("This action cannot be undone. Are you sure you want to continue?")) {
        this.loading = true;
        this.invalid = false;
        // Save member
        this.companyService.mergeAgents(this.agentMergeFrom.CompanyId, this.agentMergeTo.CompanyId).subscribe({
          next: (data: ICompany) => {
            if (data) {
              this.loading = false;
              this.alertService.success('Agent merge successful!');
              this.reset();
            }
          },
          error: () => {
            this.alertService.warn('An error occurred while attempting to update the member information.');
          }
        });
      }
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close();
  }
}
