import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { MemberUserManagementComponent } from './member-user-management/member-user-management.component';
import { TrainingResourcesComponent } from './training-resources/training-resources.component';
import { MemberSearchComponent } from './member-search/member-search.component';
import { MemberComponent } from './member-search/member/member.component';

const routes: Routes = [
  {
    path: 'search-members',
    component: MemberSearchComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'SRHMEMBR'
    },
  },
  {
    path: 'member/:companyId',
    component: MemberComponent
  },
  {
    path: 'edit-member',
    component: MemberProfileComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'EDTMEMBR'
    },
  },
  {
    path: 'company-users',
    component: MemberUserManagementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'MNGMBRUS'
    },
  },
  {
    path: 'training',
    component: TrainingResourcesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: 'TRAINING'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NetworkRoutingModule { }
