import { Directive, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formValidationStyle]',
  // tslint:disable-next-line:no-host-metadata-property
  host: {'(ngSubmit)': 'onSubmit($event)'}
})

export class FormValidationStyleDirective {
  @Input('formValidationStyle') private formGroup: UntypedFormGroup;
  @Input('useValidation') private useValidation: boolean;

  onSubmit($event) {
    if (this.formGroup.valid) {
    } else {
      if (this.useValidation) {
        this.validateAllFormFields(this.formGroup);
      }
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
