import { Component, OnInit } from '@angular/core';
import { IAgent, IPort } from '../../../../services/models/pricing/prism.model';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { IPortType, IRequestType } from '../../../../services/models/external-data/prism.model';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { ExternalDataService } from '../../../../services/external-data.service';

@Component({
  selector: 'app-prism-tab',
  templateUrl: './prism-tab.component.html',
  styleUrls: ['./prism-tab.component.scss']
})
export class PrismTabComponent implements OnInit {
  // Icons
  faEmpty = faBoxOpen;

  // Component variables
  public primsLoading: boolean;
  prismFromDate: string;
  portType: IPortType = IPortType.NotSet;
  rateSource: string = null;
  portTypes = IPortType;
  countryCode: string = '';
  requestTypes = IRequestType;
  requestType: string = 'Agents';
  ports: IPort[] = [];
  agents: IAgent[] = [];

  paginationPorts: IPaginationData<IPort>;
  paginationAgents: IPaginationData<IAgent>;
  page: IPage;
  constructor(private externalDataService: ExternalDataService) {
  }

  ngOnInit() {
    const paginationInit = {
      DataSet: [],
      Data: null,
      CurrentPage: 0,
      PageSize: 30,
      TotalPages: 0
    };
    this.paginationPorts = paginationInit;
    this.paginationAgents = paginationInit;

    this.page = {
      pageNumber: 1,
      pageSize: 50,
      batched: false
    } as IPage;
  }
  searchPrism() {
    this.primsLoading = true;
    if (this.requestType === 'Ports') {
      this.getPorts(this.prismFromDate, this.portType, this.countryCode);
    } else {
      this.getAgents(this.prismFromDate);
    }
  }

  getPorts(fromDate: string, portType: IPortType, countryCode: string) {
    this.primsLoading = true;
    this.externalDataService.getPrismPorts(fromDate, portType, countryCode, this.page)
      .subscribe({
        next: (data) => {
          if (data) {
            this.paginationPorts = Object.assign([], data);
            this.ports = this.paginationPorts.DataSet;
          }
          this.primsLoading = false;
        }, error: () => {
          this.primsLoading = false;
        }
      });
  }

  searchPage(page: IPage) {
    this.page = page;
    this.searchPrism();
  }

  getAgents(fromDate: string) {
    this.primsLoading = true;
    this.externalDataService.getPrismAgents(fromDate, this.page)
      .subscribe({
        next: (data) => {
          if (data) {
            this.paginationAgents = Object.assign([], data);
            this.agents = this.paginationAgents.DataSet;
          }
          this.primsLoading = false;
        }, error: () => {
          this.primsLoading = false;
        }
      });
  }

  // Validation Functions
  canSearchPrism() {
    return (this.prismFromDate && this.requestType);
  }

  hasResults() {
    if ((this.ports && this.ports.length > 0) || (this.agents && this.agents.length > 0)) {
      return true;
    }
    return this.primsLoading;
  }

}
