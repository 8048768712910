<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="isNew">
    Add New Pulse Chart
  </h4>
  <h4 class="modal-title" *ngIf="!isNew">
    Edit Pulse Chart: <b>{{ pulseChartId }}</b>
  </h4>
</div>
<!---- Add/Edit ---->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-3">
      Company <span class="text-danger">*</span>
    </div>
    <div class="col-3">
      <app-company-search [Identifier]="'companyId'" [Disabled]="!canSelectCompany || !canEdit"
                          [ShowError]="invalid && !pulseChart.CompanyId"
                          [SelectedID]="companyId" (OnSelect)="pulseChart.CompanyId = $event"></app-company-search>
    </div>
    <div class="col-3">
      Incidents (no sick leave)
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'" [disabled]="!canEdit"
             [(ngModel)]="pulseChart.IncidentsWithNoSickLeave" (ngModelChange)="updateIncidents()"
             [placeholder]="'e.g. 5605'">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Year <span class="text-danger">*</span>
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'4.0'"
             [ngClass]="{'is-invalid': !this.pulseChart.Year && invalid}"
             [(ngModel)]="pulseChart.Year" [disabled]="!canEdit"
             [placeholder]="'e.g. 2023'">
    </div>
    <div class="col-3">
      Ration worked hour incident
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'" [disabled]="true"
             [(ngModel)]="pulseChart.RatioWorkHoursIncident"
             [placeholder]="'e.g. 5'">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      FTE Average <span class="text-danger">*</span>
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [ngClass]="{'is-invalid': !this.pulseChart.FTE && invalid}"
             [(ngModel)]="pulseChart.FTE" [disabled]="!canEdit"
             [placeholder]="'e.g. 10.5'">
    </div>
    <div class="col-3">
      Incidents per million hour
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.IncidentsPerMillionHours">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Worked Days <span class="text-danger">*</span>
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [ngClass]="{'is-invalid': !this.pulseChart.WorkDays && invalid}"
             [(ngModel)]="pulseChart.WorkDays" [disabled]="!canEdit"
             [placeholder]="'e.g. 300'">
    </div>
    <div class="col-3">
      Environmental Incidents
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [(ngModel)]="pulseChart.EnvironmentalIncident" (ngModelChange)="updateEnvIncidents()"
             [disabled]="!canEdit" [placeholder]="'e.g. 4'">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Worked Hours
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [(ngModel)]="pulseChart.WorkHours" (ngModelChange)="updateWorkHours()"
             [disabled]="!canEdit" [placeholder]="'e.g. 90000'">
    </div>
    <div class="col-3">
      Ratio worked hour Env Incident
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.RatioWorkHoursEnvIncident">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Accidents with sick leave
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [(ngModel)]="pulseChart.AccidentsWithSickLeave" (ngModelChange)="updateAccidents()"
             [disabled]="!canEdit" [placeholder]="'e.g. 5'">
    </div>
    <div class="col-3">
      Env incidents per million hour
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.EnvIncidentsPerMillionHours">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Ration worked hour accident
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.RatioWorkHoursAccident">
    </div>
    <div class="col-3">
      Sick Days
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [(ngModel)]="pulseChart.SickDays" (ngModelChange)="updateSickDays()"
             [disabled]="!canEdit" [placeholder]="'e.g. 5605'">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3">
      Accidents per million hour
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.AccidentsPerMillionHours">
    </div>
    <div class="col-3">
      Sick Days %
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.SickDaysPercentage">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3 offset-6">
      Work related sick days  <span class="text-danger">*</span>
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="text" [numbersOnly]="'10.2'"
             [ngClass]="{'is-invalid': !this.pulseChart.WorkRelatedSickDays && invalid}"
             [(ngModel)]="pulseChart.WorkRelatedSickDays" (ngModelChange)="updateWorkSickDays()"
             [disabled]="!canEdit" [placeholder]="'e.g. 5605'">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-3 offset-6">
      Percentage %
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="pulseChart.WorkRelatedSickDaysPercentage">
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="canEdit" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="add()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
