import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';

@Component({
  selector: 'app-article-read',
  templateUrl: './article-read.component.html',
  styleUrls: ['./article-read.component.scss']
})
export class ArticleReadComponent implements OnInit {

  // Route Parameter
  articleId: number = null;

  constructor(private route: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      const articleId = Number(paramMap.get('articleId'));
      if (articleId) {
        this.articleId = articleId;
      }
    });
  }

  onBackClicked() {
    this.location.back();
  }
}
