<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit" *ngIf="authUser.UserId != null">
    {{ canEdit ? 'Editing' : 'Viewing' }} user: {{ authUser.User.DisplayName }}
  </h4>
  <h4 class="modal-title" id="modal-title-create" *ngIf="authUser.UserId == null">
    <b>Create new user</b>
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body" *ngIf="authUser">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-12 harmony-teal-text">
          <h5 class="mb-0 fw-bold">User Info</h5>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row mt-2" *ngIf="authUser.User">
        <div class="col-4 pe-1">
          <b>Username</b>
          <app-loader *ngIf="checkingUser" class="float-end" [Width]="20"></app-loader>
          <ng-container *ngIf="authUser.User.Username && authUser.User.Username.length > 0">
            <fa-icon [icon]="faValid" size="1x" *ngIf="usernameValid && domainValid" class="mt-1 float-end harmony-green-text"></fa-icon>
            <fa-icon [icon]="faInvalid" size="1x" *ngIf="!domainValid || !usernameValid" class="mt-1 float-end text-danger"></fa-icon>
          </ng-container>
        </div>
        <div class="col-8 ps-1" style="line-height: 1em;">
          <ng-container *ngIf="authUser.UserId != null && authUser.UserId > 0 || !canEdit">
            <div class="row mb-1">
              <div class="col-12 ps-4 py-1">
                {{ authUser.User.Username }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(authUser.UserId == null || authUser.UserId == 0) && canCreate">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngModel]="authUser.User.Username"
                   [disabled]="checkingUser"
                   [email]="true"
                   [required]="true"
                   [autofocus]="true"
                   #username="ngModel"
                   (change)="onUsernameChanged(username, $event);"
                   [ngClass]="{'is-invalid': !usernameValid && showValidation}">
            <small class="small" *ngIf="username.errors?.email">
              Primary email address is not valid.
            </small>
            <small *ngIf="!domainValid">
              {{ usernameValidMessage }}
            </small>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 pe-1">
          <b>First Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.FirstName"
                 (change)="authUser.User.FirstName = $event.target['value']"
                 [ngClass]="{'is-invalid': (!authUser.User.FirstName || authUser.User.FirstName.length <= 0) && showValidation}"
                 [required]="true"
                 [disabled]="!canEdit"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 pe-1">
          <b>Last Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.LastName"
                 (change)="authUser.User.LastName = $event.target['value']"
                 [ngClass]="{'is-invalid': (!authUser.User.LastName || authUser.User.LastName.length <= 0) && showValidation}"
                 [required]="true"
                 [disabled]="!canEdit"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 pe-1">
          <b>Display Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.DisplayName"
                 (change)="authUser.User.DisplayName = $event.target['value']"
                 [disabled]="!canEdit"
                 [ngClass]="{'is-invalid': authUser.User.DisplayName.length < 1 && showValidation}"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 pe-1">
          <b>Default Company</b>
        </div>
        <div class="col-8 ps-1" *ngIf="!isNewUser">
          <select class="form-select form-select-sm pointer"
                  [disabled]="authUser?.UserCompanies.length <= 0 || !canEdit"
                  [ngClass]="{'is-invalid': authUser.User.CompanyId == null && showValidation}"
                  (change)="checkForValidUsername()"
                  [(ngModel)]="authUser.User.CompanyId">
            <option [ngValue]="null" [disabled]="true" selected>Select a default company...</option>
            <option *ngFor="let company of authUser?.UserCompanies" [ngValue]="company.CompanyId">
              {{ company?.CompanyName }}
            </option>
          </select>
        </div>
        <div class="col-8 ps-1" *ngIf="isNewUser">
          <select class="form-select form-select-sm pointer"
                  [disabled]="newUserCompanies?.length <= 0 || !canEdit"
                  [ngClass]="{'is-invalid': authUser.User.CompanyId == null && showValidation}"
                  (change)="checkForValidUsername()"
                  [(ngModel)]="authUser.User.CompanyId">
            <option [ngValue]="null" [disabled]="true" selected>Select a default company...</option>
            <option *ngFor="let company of newUserCompanies" [ngValue]="company.CompanyId">
              {{ company?.CompanyName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4 border-start">
      <div class="row">
        <div class="col-12 harmony-teal-text">
          <h5 class="mb-0 fw-bold">Active Directory</h5>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row" *ngIf="loadingAd">
        <div class="col-12 text-center">
          <app-loader class="ms-2" [Width]="40"></app-loader>
        </div>
      </div>
      <div class="row" *ngIf="authUser.User.AzureRegistered === null">
        <div class="col-12 text-center">
          <app-loader class="ms-2" [Width]="20"></app-loader>
        </div>
      </div>
      <div class="row mt-1" *ngIf="authUser.User.AzureRegistered !== null">
        <div class="col-12" *ngIf="authUser.User.AzureRegistered === true">
          <div class="row">
            <div class="col-12">
              <b class="float-start">Username</b> <small
              class="text-dark float-end">{{ this.adUser.Identities[0].IssuerAssignedId }}</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b class="float-start">ID</b> <small class="text-dark float-end">{{ this.adUser.Id }}</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b class="float-start">Status</b> <span class="fw-bold float-end harmony-green-text">Active</span>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="authUser.User.AzureRegistered === false">
          <div class="row" *ngIf="this.authUser.UserId == null">
            <div class="col-12 small">
              The user will be registered in the AD when saved, those details will then appear here.
              Once those details appear the user is active and will be able to reset their password and log in to the
              portal.
            </div>
          </div>
          <div class="row" *ngIf="this.authUser.UserId != null">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <b class="float-start">Username</b> <small class="text-dark float-end">-</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b class="float-start">ID</b> <small class="text-dark float-end">-</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b class="float-start">Status</b> <span class="fw-bold float-end text-danger">Not registered</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1" *ngIf="authUser.User.AzureRegistered === false && this.authUser.UserId != null">
            <div class="col-12">
              <button class="btn btn-sm btn-primary button-azure w-100"
                      (click)="registerUserInAD(authUser.User)"
                      [disabled]="!canCreate">
                Register in AD
              </button>
            </div>
            <div class="col-12">
              <input class="form-check-input mt-1"
                     type="checkbox"
                     role="switch"
                     [(ngModel)]="sendNotification"
                     [title]="'Send welcome email'"
                     [id]="'send-notification'">
              Send notification to user (Welcome Email)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 text-end align-self-center">
<!--      <img class="img-responsive user-picture picture-shadow" *ngIf="authUser.User.ProfilePicture && token"-->
<!--           [src]="authUser.User.ProfilePicture + token" alt="User picture">-->
<!--      <img class="img-responsive user-picture picture-shadow" *ngIf="!authUser.User.ProfilePicture"-->
<!--           [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderUrl" alt="User picture">-->
      <img *ngIf="picture" class="img-responsive user-picture" (error)="picture = null" [src]="picture" alt="my-profile-picture">
      <img *ngIf="!picture"
           class="img-responsive user-picture"
           [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
           alt="profile-picture">
    </div>
  </div>
  <hr>
  <div class="row mt-2">
    <div class="col-12" *ngIf="authUser">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12 harmony-teal-text">
              <h5 class="mb-0 fw-bold">User Companies</h5>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="row mt-1 mb-2">
            <div class="col-12">
              <app-company-search class="d-inline"
                                  [Identifier]="'AgentID'"
                                  [ErrorColor]="'#a22b9d'"
                                  [Disabled]="!canEditCompanies"
                                  [SelectedID]="selectedCompanyId"
                                  (OnSelectObject)="addCompanyToUser($event)">
              </app-company-search>
            </div>
          </div>
          <div class="row result-row" *ngFor="let existingUserCompany of authUser.UserCompanies; let i = index">
            <div class="col-10"
                 [ngClass]="{'strikethrough': existingUserCompany.Active == false}">
              {{ existingUserCompany?.CompanyName }}
            </div>
            <div class="col-2 text-end">
              <ng-container *ngIf="canEdit">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer" size="1x" title="Remove"
                         *ngIf="existingUserCompany.Active && existingUserCompany.CompanyId !== authUser.User.CompanyId"
                         (click)="existingUserCompany.Active = false">
                </fa-icon>
                <fa-icon [icon]="faAdd"
                         class="harmony-green-text pointer" size="1x" title="Reactivate"
                         *ngIf="!existingUserCompany.Active && existingUserCompany.CompanyId !== authUser.User.CompanyId"
                         (click)="existingUserCompany.Active = true">
                </fa-icon>
              </ng-container>
            </div>
          </div>
          <div class="row result-row" *ngFor="let newUserCompany of newUserCompanies; let i = index">
            <div class="col-10">
              {{ newUserCompany?.CompanyName }}
            </div>
            <div class="col-2 text-end">
              <ng-container *ngIf="canCreate">
                <fa-icon [icon]="faRemove" class="text-danger pointer" size="1x" title="Remove"
                         *ngIf="newUserCompany.CompanyId !== authUser.User.CompanyId"
                         (click)="removeNewUserCompany(i, newUserCompany.CompanyId, newUserCompany)">
                </fa-icon>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-6 border-start">
          <div class="row">
            <div class="col-12 harmony-teal-text">
              <h5 class="mb-0 fw-bold">Roles</h5>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="row mt-1 mb-2">
            <div class="col-6 pe-1">
              <select class="form-select form-select-sm pointer"
                      *ngIf="roleCompanies"
                      [(ngModel)]="rolesCompanyId"
                      [disabled]="loading || roleCompanies.length <= 0"
                      (change)="changeCompanyRoles()">
                <option [ngValue]="null" [disabled]="true">Select company to edit roles...</option>
                <option *ngFor="let userCompany of roleCompanies"
                        [ngValue]="userCompany.CompanyId">
                  {{ userCompany?.CompanyName }}
                </option>
              </select>
            </div>
            <div class="col-6 ps-1">
              <ng-container *ngIf="roleAdmin">
                <select class="form-select form-select-sm pointer"
                        [(ngModel)]="roleToAdd"
                        [disabled]="loading || !(roles$ | async) || (authUser.UserCompanies?.length <= 0 && newUserCompanies?.length <= 0)"
                        (ngModelChange)="addRoleToUser()">
                  <option [ngValue]="null">Select role to add...</option>
                  <ng-container *ngFor="let role of (roles$ | async)">
                    <option *ngIf="!userHasRole(role)"
                            [ngValue]="role">
                      {{ role.Name }}
                    </option>
                  </ng-container>
                </select>
              </ng-container>
              <ng-container *ngIf="!roleAdmin">
                <select class="form-select form-select-sm pointer"
                        [(ngModel)]="roleToAdd"
                        [disabled]="loading || !availableRoles || availableRoles.length <= 0 || (authUser.UserCompanies?.length <= 0 && newUserCompanies?.length <= 0)"
                        (ngModelChange)="addRoleToUser()">
                  <option [ngValue]="null">Select role to add...</option>
                  <ng-container *ngFor="let role of availableRoles">
                    <option *ngIf="!userHasRole(role)"
                            [ngValue]="role">
                      {{ role.Name }}
                    </option>
                  </ng-container>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="row result-row" *ngFor="let userRole of authUser.UserRoles; let i = index">
            <div class="col-10"
                 [ngClass]="{'strikethrough': userRole.Active == false}">
              {{ userRole?.RoleName}}
            </div>
            <div class="col-2 text-end">
              <ng-container *ngIf="canEdit || canDeleteRole(userRole)">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer"
                         size="1x"
                         *ngIf="userRole.Active == true"
                         (click)="updateCurrentUserRole(i, false)"></fa-icon>
                <fa-icon [icon]="faAdd"
                         class="harmony-green-text pointer"
                         size="1x"
                         *ngIf="userRole.Active == false"
                         (click)="updateCurrentUserRole(i, true)"></fa-icon>
              </ng-container>
            </div>
          </div>
          <div class="row result-row" *ngFor="let userRole of filteredNewUserRoles; let i = index">
            <div class="col-10">
              {{ userRole?.RoleName}}
            </div>
            <div class="col-2 text-end">
              <ng-container>
                <fa-icon [icon]="faRemove" class="text-danger pointer"
                         size="1x" (click)="removeNewUserRole(i)">
                </fa-icon>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

