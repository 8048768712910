import { Component, OnInit } from '@angular/core';
import { faEdit, faFileText, faPlus, faTrash, faAdd, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AlertService } from '../../../../services/alert.service';
import { environment } from '../../../../../environments/environment';
import { Router} from "@angular/router";
import { SharedModule} from "../../../../shared/shared.module";
import { FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import { IPage, IPaginationData} from "../../../../shared/models/pagination-data.model";
import { IOrderParam} from "../../../../shared/directives/sort/order.directive";
import {IHRPhrase, IPhrasesSearchparam } from "../../../../services/models/hse/hse.model";
import {HrPhraseAdminModalComponent} from "./hr-phrase-admin-modal/hr-phrase-admin-modal.component";

@Component({
  selector: 'app-hr-phrases-admin',
  templateUrl: './hr-phrases-admin.component.html',
  styleUrls: ['./hr-phrases-admin.component.scss'],
  imports: [
    SharedModule,
    FontAwesomeModule,
    NgClass,
    NgIf,
    NgForOf,
    AsyncPipe,
    DatePipe
  ],
  standalone: true
})
export class HrPhrasesAdminComponent implements OnInit {

  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  faPlus = faPlus;
  faSortAsc = faSortDown;

  // Component Variables
  public readonly PermissionCodes = PermissionCodes;
  hrPhrases: IHRPhrase[];

  public phraseSearchParams: IPhrasesSearchparam = {
    Description: null,
    LanguageCode: null,
    CurrentPage: null,
    RowCount: null,
  }
  orderParam: IOrderParam;

  //Roles/User rights
  isAdmin: boolean = false;

  // Shared Components
  loading: boolean;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  paginationData: IPaginationData<IHRPhrase> = null;
  page: IPage;

  environment = environment;

  constructor(private modalService: NgbModal,
              public authService: AdAuthService,
              private alertService: AlertService,
              public router: Router,
              private hseService: HseService) {
  }

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    } as IPaginationData<IHRPhrase>;

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    this.phraseSearchParams = {
      Description: null,
      LanguageCode: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    }

    this.orderParam = {
      OrderBy: 'Description',
      OrderDirection: 'asc'
    } as IOrderParam;

    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.loadPhrases();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.setPermissions();
        this.loadPhrases();
      });
  }

  setPermissions() {
    this.isAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.phraseSearchParams.CurrentPage = page.pageNumber;
    this.phraseSearchParams.RowCount = page.pageSize;
    this.loadPhrases();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.loadPhrases();
  }

  addPhrase() {

    const modalRef = this.modalService.open(HrPhraseAdminModalComponent, {
      size: 'xl',
      backdrop: 'static'
    });

    modalRef.componentInstance.HRPhrase = {
      Id: null,
      Description: null,
      LanguageCode: null
    } as IHRPhrase;

    // On modal close, read the result and apply logic
    modalRef.result.then((phrase: IHRPhrase) => {
      if(phrase){
        this.alertService.info('Phrase: ' + phrase.Description + ' successfully created.');
        this.loadPhrases();
      }
    }, () => { });

  }

  searchPhrases() {
    this.loadPhrases()
  }

  openPhrase(phrase: IHRPhrase) {

    const modalRef = this.modalService.open(HrPhraseAdminModalComponent, {
      size: 'xl',
      backdrop: 'static'
    });
    modalRef.componentInstance.HRPhrase = phrase;

    // On modal close, read the result and apply logic
    modalRef.result.then((phrase: IHRPhrase) => {
      if(phrase) {
        this.alertService.info('HR Phrase successfully updated.');
        this.loadPhrases();
      }
    }, () => { });

  }

  deletePhrase(phrase: IHRPhrase) {
    const message = 'Are you sure you want to delete this hr phrase ?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (phrase != null) {
            this.loading = true;

            this.hseService.deleteHRPhrase(phrase.Id)
              .subscribe({
                next: () => {
                  this.alertService.info('Phrase ' + phrase.Description + ' successfully deleted.');
                  this.loadPhrases();
                  this.loading = false;
                },
                error: () => {
                  this.loading = false;
                  this.alertService.error("An error occurred while deleting the HR Phrase.");
                }
              });
          }
        }
      });
  }

  loadPhrases() {

    this.loading = true;

    const p = this.page;
    const o = this.orderParam;

    this.hseService.searchHRPhrases(this.phraseSearchParams, p, o)
      .subscribe({
        next: (data): void => {
          this.paginationData = Object.assign([], data);
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });

  }

  resetSearch() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;

    this.phraseSearchParams = {
      Description: null,
      LanguageCode: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    }

    this.page.pageNumber = 1;
    this.page.pageSize = 15;

    this.loadPhrases();
  }
}
