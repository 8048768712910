import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingService } from '../../../../services/pricing.service';
import { IApplicationLogDto } from '../../../../services/models/portal/application-log.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'add-request-locations-modal',
  templateUrl: './application-log-modal.component.html',
  styleUrls: ['./application-log-modal.component.scss']
})
export class ApplicationLogModalComponent implements OnInit {
  // Icons

  // Variables
  public Log: IApplicationLogDto;

  // General variables
  public loading: boolean;
  env = environment;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService) { }

  ngOnInit() {

  }

  getClass(level: string) {
    switch (level) {
      case 'Trace':
        return 'harmony-green-text';
      case 'Debug':
        return 'harmony-blue-grey-text';
      case 'Info':
        return 'text-info';
      case 'Warn':
        return 'harmony-coral-text';
      case 'Error':
        return 'text-danger';
      default:
        return 'harmony-teal-text';
    }
  }

  close() {
    this.activeModal.dismiss(null);
  }
}
