import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faAngleRight, faCircleDot, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-hub-menu',
  templateUrl: './hub-menu.component.html',
  styleUrls: ['./hub-menu.component.scss']
})
export class HubMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  protected readonly faHub = faNetworkWired;
  protected readonly faMenuItem = faCircleDot;
  protected readonly faExpandable = faAngleRight;

  // Menu permissions
  messageLogs: boolean = false;
  missingValues: boolean = false;
  defaultValues: boolean = false;
  manageMappings: boolean = false;
  schemaManager: boolean = false;
  schemaMappings: boolean = false;
  hubAdmin: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.messageLogs = this.authService.CheckPermissionByCode(PermissionCodes.Hub_MessageLogs);
    this.missingValues = this.authService.CheckPermissionByCode(PermissionCodes.Hub_MissingValues);
    this.defaultValues = this.authService.CheckPermissionByCode(PermissionCodes.Hub_DefaultValues);
    this.manageMappings = this.authService.CheckPermissionByCode(PermissionCodes.Hub_ManageMappings);
    this.schemaManager = this.authService.CheckPermissionByCode(PermissionCodes.Hub_SchemaManager);
    this.schemaMappings = this.authService.CheckPermissionByCode(PermissionCodes.Hub_SchemaMappings);
    this.hubAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Hub_Admin);
  }
}
