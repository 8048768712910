import { Component, OnInit } from '@angular/core';
import { TenderService } from '../../../../services/tender.service';
import { ITenderCurrency } from '../../../../services/models/pricing/tender.model';
import { PricingService } from '../../../../services/pricing.service';

@Component({
  selector: 'app-step-create-tender',
  templateUrl: './step-create-tender.component.html',
  styleUrls: ['./step-create-tender.component.scss']
})
export class StepCreateTenderComponent implements OnInit {
  // Private Variables
  today: any = null;

  // Lookup data
  Currencies$ = this.pricingService.Currencies$.asObservable();

  formatter = (result: any) => result.Name;

  constructor(public tenderService: TenderService,
              public pricingService: PricingService) { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    } as any;
  }

  onCurrencySelect() {
    if (this.tenderService.SelectedCurrency && this.tenderService.SelectedCurrency.CurrencyId) {
      this.tenderService.TenderView.DefaultCurrencyId = this.tenderService.SelectedCurrency.CurrencyId;
    }
  }

  compareCurrencyFn(c1: ITenderCurrency, c2: ITenderCurrency): boolean {
    return c1 && c2 ? c1.CurrencyId === c2.CurrencyId : c1 === c2;
  }
}
