import { Component, OnInit } from '@angular/core';
import { faDownload, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { IArticleAttachment } from '../../../../../services/models/news/article.model';
import { FileService } from '../../../../../services/file.service';
import {
  UserContactModalComponent
} from '../../../../network/member-search/member/user-contact-modal/user-contact-modal.component';

@Component({
  selector: 'app-publish-modal',
  templateUrl: './article-preview-modal.component.html',
  styleUrls: ['./article-preview-modal.component.scss']
})
export class ArticlePreviewModalComponent implements OnInit {
  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faNews = faNewspaper;
  protected readonly faDownload = faDownload;

  // Component variables
  ImageUrl: string;
  Title: string;
  PublishDate: string;
  Author: string;
  AuthorUserId: number;
  HtmlContent: string;
  Attachments: IArticleAttachment[] = [];

  // General variables
  env = environment;
  placeholderImage: string = '';

  constructor(private activeModal: NgbActiveModal,
              private fileService: FileService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  viewContactCard() {
    // Open modal to edit user
    const modalRef = this.modalService.open(UserContactModalComponent, {size: 'md', centered: true, backdrop: 'static'});
    modalRef.componentInstance.UserID = this.AuthorUserId;
  }

  close(hasSaved: boolean) {
    this.activeModal.close(hasSaved)
  }
}
