<div class="modal-header">
  <h4 class="modal-title">
    Add Locations
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body">
  <app-loader *ngIf="loading"></app-loader>
  <div class="row mt-3 mb-3 text-center">
    <div class="col-md-6" (click)="setIsSingle(true)">
      <div class="location-option p-2" [ngClass]="{ 'active': isSingleLocation, 'inactive-mode': !isSingleLocation }">Single Location</div>
    </div>
    <div class="col-md-6" (click)="setIsSingle(false)">
      <div class="location-option p-2" [ngClass]="{ 'active': !isSingleLocation, 'inactive-mode': isSingleLocation }">Multiple Locations</div>
    </div>
  </div>
  <div class="row" *ngIf="isSingleLocation">
    <div class="col-md-12 harmony-grey-text">
      <input type="text"
             class="form-control"
             [placeholder]="'Enter location name here'"
             [(ngModel)]="singleLocationName"/>
      <small><fa-icon [icon]="faInfo" size="xs"></fa-icon> Provide city and country name for more accurate results</small>
    </div>
  </div>
  <div class="row" *ngIf="!isSingleLocation">
    <div class="col-md-12 harmony-grey-text">
      <textarea class="form-control"
                [(ngModel)]="multiLocationName"
                (ngModelChange)="onMultiLocationChange()"
                [placeholder]="'Insert location list here, up to ' + locationLimit"
                rows="8"></textarea>
      <small><fa-icon [icon]="faInfo" size="xs"></fa-icon> Provide city and country name for more accurate results</small>
      <br>
      <small><fa-icon [icon]="faInfo" size="xs"></fa-icon> Only enter one location per line</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="loading" (click)="close()">+ Add</button>
</div>

