import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IChapterCreateRequest, IChapterResponse, IChapterUpdateRequest } from '../../../../services/models/annual-review/annual-review-chapter.model';
import { IAnnualReviewSearchFilterResponse } from '../../../../services/models/annual-review/annual-review.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import { AnnualReviewService } from '../../../../services/annual-review.service';

@Component({
  selector: 'app-view-chapter-modal',
  templateUrl: './view-chapter-modal.component.html',
  styleUrls: ['./view-chapter-modal.component.scss']
})
export class ViewChapterModalComponent implements OnInit, OnDestroy {

  public chapter: IChapterResponse;
  public isNew: false;
  public reviewSearchFilter: IAnnualReviewSearchFilterResponse[];
  public chapterForm: UntypedFormGroup;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: UntypedFormBuilder,
              private annualReviewService: AnnualReviewService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.createForm();
    if (!this.isNew) {
      this.fillForm(this.chapter);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // FORM Functions
  createForm() {
    this.chapterForm = this.formBuilder.group({
      name: new UntypedFormControl(null, Validators.required),
      order: new UntypedFormControl(null),
      reviewId: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(null),
    });
  }

  fillForm(chapter: IChapterResponse) {
    this.chapterForm.controls.name.setValue(chapter.Name);
    this.chapterForm.controls.order.setValue(chapter.Order);
    this.chapterForm.controls.reviewId.setValue(chapter.ReviewId);
    this.chapterForm.controls.active.setValue(chapter.Active);
  }

  isFormValid() {
    this.chapterForm.markAllAsTouched();
    this.chapterForm.markAsTouched();
    if (this.chapterForm.invalid) {
      this.alertService.warn('Form is not valid');
    }
    return this.chapterForm.valid;
  }

  // FOOTER FUNCTIONS
  add() {
    if (this.isFormValid()) {
      const chapter = {
        Name: this.chapterForm.value.name,
        Order: this.chapterForm.value.order,
        ReviewId: this.chapterForm.value.reviewId,
      } as IChapterCreateRequest;

      this.annualReviewService.addChapter(chapter).pipe(
        takeUntil(this.unsubscribe)).subscribe(() => {
          this.close();
        },
        () => {
          this.alertService.error('Failed to add chapter');
        });
    }
  }

  update() {
    if (this.isFormValid()) {
      const chapter = {
        Name: this.chapterForm.value.name,
        Order: this.chapterForm.value.order,
        ReviewId: this.chapterForm.value.reviewId,
        Active: this.chapterForm.value.active,
        Id: this.chapter.Id
      } as IChapterUpdateRequest;

      this.annualReviewService.updateChapter(chapter).pipe(
        takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.close(this.chapter);
          },
          error: () => {
            this.alertService.error('Failed to update chapter');
          }
        });
    }
  }

  close(chapter: IChapterResponse = null) {
    this.activeModal.close(chapter);
  }
}
