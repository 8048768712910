import { Component, OnDestroy, OnInit } from '@angular/core';
import { IModule, IUserAdminDto } from '../../../services/models/user.model';
import { ApiService } from '../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IActiveCompany, IUsersSearchDto } from '../../../services/models/member.model';
import {
  faBoxOpen,
  faPlus, faSortDown,
  faSyncAlt,
  faTrash,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { IAuthUser } from '../../../services/models/auth.model';
import * as jwt_decode from 'jwt-decode';
import { UserManagementModalComponent } from './user-management-modal/user-management-modal.component';
import { environment } from '../../../../environments/environment';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { FileService } from '../../../services/file.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faUserEdit;
  faRefresh = faSyncAlt;
  faRemove = faTrash;
  faAdd = faPlus;
  faEmpty = faBoxOpen;
  faSortAsc = faSortDown;

  // Company variables
  companies: IActiveCompany[] = [];

  // User variables
  paginationData: IPaginationData<IUserAdminDto>;
  orderParam: IOrderParam;
  searchParam: IUsersSearchDto;
  page: IPage;
  selectedUser: IAuthUser = null;
  hasEditPermission: boolean;
  hasDeletePermission: boolean;
  hasCreatePermission: boolean;

  // Lookups variables
  modules: IModule[];
  roles$ = this.authService.Roles$.asObservable();

  // General Variables
  loading: boolean = false;
  env = environment;
  defaultImage = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.ImagePlaceholderUrl;
  token: string;
  private unsubscribe: Subject<any> = new Subject<any>();
  showLogin: boolean;

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService,
              public authService: AdAuthService,
              private fileService: FileService) {
  }

  ngOnInit() {
    this.getToken();
    // Load all companies for filtering users
    this.initiatePage();
    this.initOrder();
    this.setPermissions();
    this.initSearchParam();
    this.getUsersForAdmin();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initSearchParam() {
    this.searchParam = {
      SearchText: '',
      CompanyId: null,
      Active: true,
      RoleId: null
    } as IUsersSearchDto;
  }

  initiatePage() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };
  }
  initOrder(){
    this.orderParam = {
      OrderBy: 'Username',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  setPermissions() {
    this.hasEditPermission = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Edit);
    this.hasDeletePermission = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Delete);
    this.hasCreatePermission = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Create);
  }

  search() {
    this.page.pageNumber = 1;
    this.getUsersForAdmin();
  }

  resetSearch() {
    this.initSearchParam();
    this.search();
  }

  getUsersForAdmin() {
    this.loading = true;
    const pn = this.page?.pageNumber;
    const ps = this.page?.pageSize;
    const st = this.searchParam?.SearchText;
    const a = this.searchParam?.Active ?? '';
    const c = this.searchParam.CompanyId ?? '';
    const r = this.searchParam.RoleId ?? '';
    const ob = this.orderParam.OrderBy;
    const od = this.orderParam.OrderDirection;

    this.api.get(`User/GetPaginated?pageNumber=${pn}&pageSize=${ps}&searchText=${st}&active=${a}&companyId=${c}&roleId=${r}&orderBy=${ob}&order=${od}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: IPaginationData<IUserAdminDto>) => {
          this.paginationData = {...data};
          this.showLogin = this.searchParam.Active || this.searchParam.Active == null;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.getUsersForAdmin();
  }

  identify(index, item) {
    return item;
  }

  onPageChange(page: IPage) {
    this.page = page;
    this.getUsersForAdmin();
  }

  openUserEditModal(user: IUserAdminDto) {
    this.loading = true;
    this.selectedUser = null;

    this.authService.GetUserPermissions(user.Username).subscribe({
      next: (data) => {
        if (data) {
          // Decode JWT token data section and set the current user
          const decoded: any = jwt_decode.default(data.Token);
          this.selectedUser = {
            UserId: +decoded.UserID,
            User: JSON.parse(decoded.User),
            UserRoles: JSON.parse(decoded.UserRoles),
            Permissions: JSON.parse(decoded.Permissions),
            UserCompanies: JSON.parse(decoded.UserCompanies)
          } as IAuthUser;
          // Set AD Status
          this.selectedUser.User.AzureRegistered = user.AzureRegistered;
          // Open modal to Add/Edit a module
          const modalRef = this.modalService.open(UserManagementModalComponent, {size: 'xl', backdrop: 'static'});
          modalRef.componentInstance.authUser = this.selectedUser;
          // On modal close, read the result and apply logic
          modalRef.result.then((result: string) => {
            if (result) {
              this.alertService.success(result);
              this.getUsersForAdmin();
            }
          }, () => {
          });
        } else {
          this.alertService.error('Permission data for ' + user.DisplayName + ' could not be loaded.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Permission data for ' + user.DisplayName + ' could not be loaded.');
        this.loading = false;
      }
    });
  }

  openNewUserModal() {
    // Open modal to edit a user
    const modalRef = this.modalService.open(UserManagementModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNewUser = true;
    // On modal close, read the result and apply logic
    modalRef.result.then(() => {
      this.getUsersForAdmin();
    }, () => {
    });
  }

  toggleUserActive(user: IUserAdminDto, activate: boolean) {
    const message: string = activate == true ? 'This user will be re-enabled and granted access to the portal again. \n\n Continue?' : 'This user will be disabled and will not be able to access the Portal. \n\n Continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.authService.ToggleUserActive(user.Id, activate)
            .subscribe({
              next: (data: boolean) => {
                if (data) {
                  this.alertService.success(user.DisplayName + ' updated successfully');
                  this.getUsersForAdmin();
                }
              },
              error: () => {
                this.alertService.success('Failed to update info for ' + user.DisplayName);
              }
            });
        }
      });
  }

  getToken() {
    this.fileService.GetSasToken(this.env.ContainerNames.UserProfilePictures)
      .subscribe({
        next: (data) => {
          this.token = `?${data.Token}`;
        }
      });
  }
}
