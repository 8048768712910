import { Component, OnInit } from '@angular/core';
import { faBoxOpen, faCode, faEye, faSortAsc } from '@fortawesome/free-solid-svg-icons';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { HubAdminService } from '../../../services/hub-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAuthUser } from '../../../services/models/auth.model';
import { takeUntil } from 'rxjs/operators';
import {
  IHubSourceMessageOverview,
  IHubSourceMessageParam
} from '../../../services/models/hub/hub-messages.model';
import { IHubSchemaType } from '../../../services/models/hub/schema.model';
import { ViewMessageModalComponent } from './view-message-modal/view-message-modal.component';
import { ViewMessageMappingModalComponent } from './view-message-mapping-modal/view-message-mapping-modal.component';
import { IHubClientSystemOverview } from '../../../services/models/hub/hub.model';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { AlertService } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HubRoutes } from '../../../core/constants/routes';

@Component({
  selector: 'app-message-logs',
  templateUrl: './message-logs.component.html',
  styleUrls: ['./message-logs.component.scss']
})
export class MessageLogsComponent  implements OnInit {
  // Icons
  faView = faEye;
  faData = faCode;
  faEmpty = faBoxOpen;
  faSortDesc = faSortAsc;

  // Component variables
  searchParam: IHubSourceMessageParam;
  paginationData: IPaginationData<IHubSourceMessageOverview>;
  orderParam: IOrderParam;
  page: IPage;
  messageId: number = null;
  hubSchemaTypes$ = this.hubAdmin.SchemaTypes$.asObservable();
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  processStatuses$ = this.hubAdmin.ProcessStatuses$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClient: IHubClientSystemOverview = null;
  sourceSchemaTypes$ = new BehaviorSubject<IHubSchemaType[]>([]);
  targetSchemaTypes$ = new BehaviorSubject<IHubSchemaType[]>([]);
  ucid: number = null;
  timezone: string = '';

  // Permissions
  canViewAllDirections: boolean = false;

  // General variables
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  loading: boolean = false;
  env = environment;

  constructor(private api: ApiService,
              private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.searchParam = {
      SearchText: null,
      SourceClientId: null,
      SourceSchemaTypeId: null,
      TargetClientId: null,
      TargetSchemaTypeId: null,
      ProcessStatusId: null,
      FromDate: null,
      ToDate: null
    } as IHubSourceMessageParam;
    this.initPage();

    if (this.auth.CurrentUser) {
      this.ucid = this.auth.CurrentUser.User.CompanyId;
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.ucid = user.User.CompanyId;
      this.setPermissions();
    });
  }

  checkRoute() {
    this.route.paramMap.subscribe(paramMap => {
      if (!this.messageId || this.messageId <= 0) {
        this.messageId = +paramMap.get('messageId');
        if (this.messageId) {
          this.viewMessage(this.messageId);
        }
      }
    });
  }

  initPage() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IHubSourceMessageOverview>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'CreatedOn',
      OrderDirection: 'desc'
    } as IOrderParam;
  }

  setPermissions() {
    // Set view permission
    this.canViewAllDirections = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);
    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAllDirections) {
        this.ucid = null;
        this.userClients$.next(data);
        this.checkRoute();
        this.searchMessages();
      } else {
        const clients = data.filter(x => x.SourceCompanyId == this.ucid || x.TargetCompanyId == this.ucid);
        this.userClients$.next(clients);
        this.checkRoute();
        if (clients.length >= 1) {
          this.selectedClient = clients[0];
          this.setClientData();
        }
      }
    });
  }

  // Default permission functions
  setClientData() {
    if (this.selectedClient) {
      this.searchParam.SourceClientId = this.selectedClient.SourceClientId;
      this.searchParam.TargetClientId = this.selectedClient.TargetClientId;
      if (this.canViewAllDirections) {
        this.loadSourceSchemaTypes();
        this.loadTargetSchemaTypes();
      }
      this.searchMessages();
    } else {
      this.searchParam.SourceClientId = null;
      this.searchParam.TargetClientId = null;
      this.searchParam.SourceSchemaTypeId = null;
      this.searchParam.TargetSchemaTypeId = null;
      this.sourceSchemaTypes$.next([]);
      this.targetSchemaTypes$.next([]);
      this.searchMessages();
    }
  }

  loadSourceSchemaTypes() {
    this.hubSchemaTypes$.subscribe((schemaTypes) => {
      const sst = schemaTypes.filter(x => x.SystemId == this.selectedClient.SourceSystemId);
      this.sourceSchemaTypes$.next(sst);
    });
  }

  loadTargetSchemaTypes() {
    this.hubSchemaTypes$.subscribe((schemaTypes) => {
      const sst = schemaTypes.filter(x => x.SystemId == this.selectedClient.TargetSystemId);
      this.targetSchemaTypes$.next(sst);
    });
  }

  resetSearch() {
    if (this.canViewAllDirections) {
      this.searchParam.SourceClientId = null;
      this.searchParam.TargetClientId = null;
      this.selectedClient = null;
    }

    this.searchParam.SearchText = null;
    this.searchParam.SourceSchemaTypeId = null;
    this.searchParam.TargetSchemaTypeId = null;
    this.searchParam.FromDate = null;
    this.searchParam.ToDate = null;
    this.searchParam.ProcessStatusId = null;

    this.initPage();
    this.searchMessages();
  }

  searchMessages() {
    if (this.canViewAllDirections == false && this.selectedClient == null) {
      this.alertService.info('Please select a client direction.');
    } else {
      this.loading = true;
      const p = this.page;
      const o = this.orderParam;

      this.api.post(`Hub/Message/SourceMessages?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (data: IPaginationData<IHubSourceMessageOverview>) => {
            this.paginationData = Object.assign({}, data);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    }
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchMessages();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchMessages();
  }

  viewMessage(messageId: number) {
    // Open modal to view all Hub Azure Logs
    const modalRef = this.modalService.open(ViewMessageModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.SourceMessageId = messageId;
    modalRef.componentInstance.UserClients = this.userClients$.getValue();
    // On modal close, read the result and apply logic
    modalRef.result.then(() => {
      this.router.navigate([`${HubRoutes.Message_Log}`]).catch();
    }, () => {
    });
  }

  viewMapping(message: IHubSourceMessageOverview) {
    // Open modal to view the message Debug/Mapping data
    const modalRef = this.modalService.open(ViewMessageMappingModalComponent, {size: 'xl', windowClass: 'modal-3xl', backdrop: 'static'});
    modalRef.componentInstance.SourceMessage = message;
  }
}
