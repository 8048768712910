import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TenderService } from '../../../../services/tender.service';
import { takeUntil } from 'rxjs/operators';
import { ITenderQuote } from '../../../../services/models/pricing/tender.model';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-step-quote-accept',
  templateUrl: './single-lane-quote.component.html',
  styleUrls: ['./single-lane-quote.component.scss']
})
export class SingleLaneQuoteComponent implements OnInit {
  // Component variables
  termsAccepted: boolean = false;
  quoteAccepted: boolean = false;
  singleLaneQuote: ITenderQuote[];

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public tenderService: TenderService,
              private alertService: AlertService) { }

  ngOnInit() { }

  public LoadQuote(tenderId: number, currencyId: number) {
    this.loading = true;

    // Get Quote data
    const param = {
      TenderID: tenderId,
      CurrencyID: currencyId
    };

    this.tenderService.GetQuote(param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (quoteData) => {
        if (quoteData) {
          this.singleLaneQuote = Object.assign([] as ITenderQuote[], quoteData);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred while retrieving the quote.');
        this.loading = false;
      }
    });
  }

  getQuoteItems() {
    return this.singleLaneQuote.filter((x) => x.Quantity > 0);
  }

  getTotal() {
    let result = 0;

    if (this.singleLaneQuote) {
      this.singleLaneQuote.forEach((q) => {
        result += q.Amount;
      });
    }

    return result;
  }

  disableSubmit() {
    return (!this.termsAccepted || !this.quoteAccepted) || this.loading;
  }

  close() {
    this.activeModal.dismiss();
  }

  decline() {
    const result = {
      IsAccepted: false,
      QuoteAmount: this.getTotal()
    }
    this.activeModal.close(result);
  }

  accept() {
    const result = {
      IsAccepted: true,
      QuoteAmount: this.getTotal()
    }
    this.activeModal.close(result);
  }
}
