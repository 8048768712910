import { Component } from '@angular/core';
import {
  IStateDto,
  IStatePageParam,
  IStatePageResult
} from '../../../../services/models/pricing/location.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { takeUntil } from 'rxjs/operators';
import { faBoxOpen, faEdit, faEye, faPlus, faSortDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateEditModalComponent } from './state-edit-modal/state-edit-modal.component';
import { AlertService } from '../../../../services/alert.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-state-management',
  templateUrl: './state-management.component.html',
  styleUrls: ['./state-management.component.scss']
})
export class StateManagementComponent {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faSortAsc = faSortDown;
  protected readonly faDelete = faTrash;
  protected readonly faRemove = faTrash;
  protected readonly faAdd = faPlus;

  // Component variables
  paginationData: IPaginationData<IStatePageResult>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: IStatePageParam;
  hasDeletePermission: boolean = false;

  // General Variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private auth: AdAuthService) {}

  ngOnInit() {
    this.loading = true;
    this.initPagination();
    // Load agents
    this.searchStates();
    // Set permissions
    this.hasDeletePermission = this.auth.CheckPermissionByCode(PermissionCodes.Admin_LocationDelete);
  }

  initPagination() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set filter
    this.searchParam = {
      SearchText: null,
      CountryId: null,
      Active: true
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchFilter() {
    this.page.pageNumber = 1;
    this.searchStates();
  }

  resetSearch() {
    this.initPagination();
    this.searchFilter();
  }

  searchStates() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`State/List?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<IStatePageResult>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchStates();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchStates();
  }

  newState() {
    const modalRef = this.modalService.open(StateEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.StateId = null;
    modalRef.result.then((result: IStateDto) => {
        this.searchStates();
      }, () => { }
    );
  }

  editState(state: IStatePageResult) {
    const modalRef = this.modalService.open(StateEditModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.StateId = state?.Id;
    modalRef.result.then((result: IStateDto) => {
      state.DateModified = result.DateModified;
      state.CountryName = result.CountryName;
      state.Code = result.Code;
      state.Name = result.Name;
      }, () => { }
    );
  }

  toggleState(state: IStatePageResult) {
    state.Active = !state.Active;
    this.api.get(`State/ToggleActive/${state.Id}/${state.Active}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('State record successfully updated!');
      }, error: () => {
        this.alertService.error('State record failed to update');
      }
    });
  }
}
