<!---- Header ---->
<div class="modal-header">
  <div class="col-10">
    <h4 class="modal-title" *ngIf="isNew">
      Add New Dangerous Substance
    </h4>
    <h4 class="modal-title" *ngIf="!isNew">
      Edit Dangerous Substance: <b>{{ substanceId }}</b>
    </h4>
  </div>
  <div class="col-2">
    <button class="dropdown-toggle form-control form-control-sm" type="button"
            id="language-code" data-bs-toggle="dropdown" aria-expanded="false">
      {{ selectedLanguage }}
    </button>
    <ul class="dropdown-menu pointer-plain" aria-labelledby="language-code">
      <li class="dropdown-item" (click)="setLanguageCode('EN')">EN</li>
      <li class="dropdown-item" (click)="setLanguageCode('NL')">NL</li>
      <li class="dropdown-item" (click)="setLanguageCode('DE')">DE</li>
      <li class="dropdown-item" (click)="setLanguageCode('PT')">PT</li>
    </ul>
  </div>
</div>
<!---- Add/Edit Substance ---->
<form [formGroup]="dsForm">
  <div class="modal-body">
    <div class="row mb-1">
      <div class="col-2">
        Company <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <app-company-search [Disabled]="!hasCompanyFilterPermission || !canEdit"
                            [ShowError]="dsForm.controls.companyId.invalid && dsForm.controls.companyId.touched"
                            [SelectedID]="substance.CompanyId"
                            (OnSelect)="changeCompanyId($event)"
                            [MembersOnly]="true"></app-company-search>
      </div>
      <div class="col-2">
        Branch
      </div>
      <div class="col-4">
        <div *ngIf="branches.length == 0">
          <select class="form-select form-select-sm form-select-sm pointer"
                  aria-label="Disabled select example"
                  disabled>
            <option selected>Default</option>
          </select>
        </div>
        <div *ngIf="branches.length > 0">
          <select [formControlName]="'branchId'"
                  class="form-select form-select-sm form-select-sm pointer"
                  [ngClass]="{'is-invalid': dsForm.controls.branchId.invalid && dsForm.controls.branchId.touched}">
            <option [ngValue]="null" selected>Select a branch...</option>
            <option *ngFor="let branch of branches"
                    [ngValue]="branch.Id">{{ branch.Name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Substance Name <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <input [formControlName]="'name'" type="text" [placeholder]="'e.g. Oil'"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': dsForm.controls.name.invalid && dsForm.controls.name.touched}">
      </div>
      <div class="col-2">
        Quantity <span class="text-danger">*</span>
      </div>
      <div class="col-3 pe-1">
        <input [formControlName]="'quantity'" type="number" [placeholder]="'e.g. 10'" [min]="1"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': dsForm.controls.quantity.invalid && dsForm.controls.quantity.touched}">
      </div>
      <div class="col-1 ps-0">
        <select [formControlName]="'quantityType'"
                [ngClass]="{'is-invalid': dsForm.controls.quantityType.touched && dsForm.controls.quantityType.invalid}"
                required name="quantity-type" id="quantity-type"
                class="form-select form-select-sm form-select-sm pointer"
                [(ngModel)]="substance.QuantityMeasurement">
          <option disabled selected>Select measurement type</option>
          <option [ngValue]="1">Kg</option>
          <option [ngValue]="2">g</option>
          <option [ngValue]="3">L</option>
          <option [ngValue]="4">ml</option>
        </select>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Product Name <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <input [formControlName]="'product'" [placeholder]="'e.g. Engine Oil'"
               type="text" class="form-control form-control-sm"
               [ngClass]="{'is-invalid': dsForm.controls.product.invalid && dsForm.controls.product.touched}">
      </div>
      <div class="col-2">
        UN Number <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <input [formControlName]="'unNumber'" type="number" [numbersOnly]="'4.0'"
               [ngClass]="{'is-invalid': dsForm.controls.unNumber.invalid && dsForm.controls.unNumber.touched}"
               class="form-control form-control-sm" [placeholder]="'e.g. 1234'">
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Location <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <select class="form-select form-select-sm form-select-sm pointer"
                [formControlName]="'locationId'"
                [ngClass]="{'is-invalid': dsForm.controls.locationId.invalid && dsForm.controls.locationId.touched}">
          <option *ngFor="let location of locations"
                  [ngValue]="location.Id">{{ location.Description }}</option>
        </select>
      </div>
      <div class="col-2">
        Emergency Contact <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <app-mobile-number [Number]="substance.EmergencyNumber"
                           [Max]="15"
                           [Disabled]="!canEdit"
                           [Touched]="dsForm.controls.emergencyNumber.touched"
                           (OnChange)="setMobileNumber($event)"></app-mobile-number>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Activity <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <select class="form-select form-select-sm pointer"
                [ngClass]="{'is-invalid': dsForm.controls.activityId.invalid && dsForm.controls.activityId.touched}"
                [formControlName]="'activityId'">
          <option *ngFor="let activity of activities"
                  [ngValue]="activity.Id">{{ activity.Description }}</option>
        </select>
      </div>
      <div class="col-2">
        Usage
      </div>
      <div class="col-4">
        <textarea name="usage" class="form-control form-control-sm" rows="1" [formControlName]="'usage'"></textarea>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Owner User <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <select class="form-select form-select-sm form-select-sm pointer"
                [formControlName]="'userId'"
                [ngClass]="{'is-invalid': dsForm.controls.userId.invalid && dsForm.controls.userId.touched}">
          <option *ngFor="let user of users"
                  [ngValue]="user.Id">{{ user.DisplayName }}</option>
        </select>
      </div>
      <div class="col-2">
        MSDS
      </div>
      <div class="col-4">
        <div class="form-check form-switch form-switch-md">
          <input class="form-check-input" type="checkbox" role="switch" [id]="'switch-msdn'"
                 [title]="'Check for MSDS available'" [checked]="substance.MSDS"
                 (change)="substance.MSDS = $event.target.checked" [disabled]="!canEdit">
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        Supplier
      </div>
      <div class="col-4">
        <input [formControlName]="'supplier'" [placeholder]="'e.g. British Petroleum'"
               type="text" class="form-control form-control-sm">
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-2">
        H/R Phrase
      </div>
      <div class="col-4">
        <button class="dropdown-toggle form-control form-control-sm text-start" type="button" [disabled]="!canEdit"
                id="hr-phrase" data-bs-toggle="dropdown" aria-expanded="false">
          Select HR phrases
        </button>
        <div class="dropdown-menu" aria-labelledby="hr-phrase">
          <ng-container *ngFor="let phrase of hrPhrases">
            <ul class="dropdown-item pointer-plain" (click)="addHrPhrase(phrase)">
              {{ phrase.Description }}
            </ul>
          </ng-container>
        </div>
        <div *ngFor="let phrase of newHrPhrases; let i = index">
          <fa-icon [icon]="faDelete" class="text-danger"
                   [title]="'Remove item'" (click)="removeHrPhrase(i)">
          </fa-icon>
          {{ phrase?.HazardRiskPhrase?.Description }}
        </div>
        <div *ngFor="let phrase of currentHrPhrases; let i = index">
          <button [disabled]="!canEdit" class="button-no-style" type="button">
            <fa-icon [icon]="faDelete" class="text-danger"
                     [title]="'Remove item'" (click)="deleteHrPhrase(phrase, i)">
            </fa-icon>
          </button>
          {{ phrase?.Description }}
        </div>
      </div>
      <div class="col-2">
        P/S Phrase
      </div>
      <div class="col-4">
        <button class="dropdown-toggle form-control form-control-sm text-start" type="button" [disabled]="!canEdit"
                id="ps-phrase" data-bs-toggle="dropdown" aria-expanded="false">
          Select PS phrases
        </button>
        <div class="dropdown-menu" aria-labelledby="ps-phrase">
          <ng-container *ngFor="let phrase of psPhrases">
            <ul class="dropdown-item pointer-plain" (click)="addPsPhrase(phrase)">
              {{ phrase?.Description }}
            </ul>
          </ng-container>
        </div>
        <div *ngFor="let phrase of newPsPhrases; let i = index">
          <fa-icon [icon]="faDelete" class="text-danger"
                   [title]="'Remove item'" (click)="removePsPhrase(i)">
          </fa-icon>
          {{ phrase?.ProtectionSafetyPhrase?.Description }}
        </div>
        <div *ngFor="let phrase of currentPsPhrases; let i = index">
          <button [disabled]="!canEdit" class="button-no-style" type="button">
            <fa-icon [icon]="faDelete" class="text-danger"
                     [title]="'Remove item'" (click)="deletePsPhrase(phrase, i)">
            </fa-icon>
          </button>
          {{ phrase?.Description }}
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="row">
        <div class="col-3 border-end">
          <div class="row">
            <div class="col-12 fw-bold harmony-teal-text">
              Physical Risk
              <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                       [title]="'Physical risk properties of the substance'">
              </fa-icon>
              <hr class="mt-1 mb-1">
            </div>

          </div>
          <div class="row">
            <div class="col-9">
              Explosive
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-explosive'"
                       [title]="'Is the substance explosive'" [checked]="substance.Explosive" [disabled]="!canEdit"
                       (change)="substance.Explosive = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Gas Pressure
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-gas-pressure'"
                       [title]="'Does substances have gas pressure'" [checked]="substance.GasPressure"
                       [disabled]="!canEdit"
                       (change)="substance.GasPressure = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Flammable
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-flammable'"
                       [title]="'Is the substance flammable'" [checked]="substance.Flammable" [disabled]="!canEdit"
                       (change)="substance.Flammable = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Oxidiser
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-oxidiser'"
                       [title]="'Is the substance an oxidiser'" [checked]="substance.Oxidiser" [disabled]="!canEdit"
                       (change)="substance.Oxidiser = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Harmful
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-harmful'"
                       [title]="'Is substance harmful'" [checked]="substance.Harmful" [disabled]="!canEdit"
                       (change)="substance.Harmful = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Toxic
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-toxic'"
                       [title]="'Is the substance toxic'" [checked]="substance.Toxic" [disabled]="!canEdit"
                       (change)="substance.Toxic = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Irritant
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-irritant'"
                       [title]="'Is the substance irritant'" [checked]="substance.Irritant" [disabled]="!canEdit"
                       (change)="substance.Irritant = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Corrosive
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-corrosive'"
                       [title]="'Does substances have gas pressure'" [checked]="substance.Corrosive"
                       [disabled]="!canEdit"
                       (change)="substance.Corrosive = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Environment
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-environment'"
                       [title]="'Is substance explosive'" [checked]="substance.Environment" [disabled]="!canEdit"
                       (change)="substance.Environment = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 border-end">
          <div class="row">
            <div class="col-12 fw-bold harmony-teal-text">
              Physical Exposure
              <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                       [title]="'Route of exposure to the substance'">
              </fa-icon>
              <hr class="mt-1 mb-1">
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Skin
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-skin'"
                       [title]="'Is the substance exposed through skin contact'" [checked]="substance.Skin"
                       [disabled]="!canEdit"
                       (change)="substance.Skin = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Eyes
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-eyes'"
                       [title]="'Is substance exposed through eyes'" [checked]="substance.Eyes"
                       [disabled]="!canEdit"
                       (change)="substance.Eyes = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Inhalation
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-inhalation'"
                       [title]="'Is substance exposed via inhalation'" [checked]="substance.Inhalation"
                       [disabled]="!canEdit"
                       (change)="substance.Inhalation = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Ingestion
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-ingestion'"
                       [title]="'Is substance exposed via ingestion'" [checked]="substance.Ingestion"
                       [disabled]="!canEdit"
                       (change)="substance.Ingestion = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Cuts
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-cuts'"
                       [title]="'Is substance exposed via cuts'" [checked]="substance.Cuts"
                       [disabled]="!canEdit"
                       (change)="substance.Cuts = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Injection
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-injections'"
                       [title]="'Is substance exposed via injections'" [checked]="substance.Injection"
                       [disabled]="!canEdit"
                       (change)="substance.Injection = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 border-end">
          <div class="row">
            <div class="col-12 fw-bold harmony-teal-text">
              Persons Exposed
              <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                       [title]="'Persons exposed to the substance'">
              </fa-icon>
              <hr class="mt-1 mb-1">
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Users
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-users'"
                       [title]="'Are users exposed to the substance'" [checked]="substance.Users"
                       [disabled]="!canEdit"
                       (change)="substance.Users = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Warehouse
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-warehouse'"
                       [title]="'Is the warehouse exposed to the substance'" [checked]="substance.Warehouse"
                       [disabled]="!canEdit"
                       (change)="substance.Warehouse = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Office
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-office'"
                       [title]="'Are offices exposed to the substance'" [checked]="substance.Office"
                       [disabled]="!canEdit"
                       (change)="substance.Office = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Public
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-public'"
                       [title]="'Are the public exposed to the substance'" [checked]="substance.Public"
                       [disabled]="!canEdit"
                       (change)="substance.Public = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Visitors
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-visitors'"
                       [title]="'Are visitors exposed to the substance'" [checked]="substance.Visitors"
                       [disabled]="!canEdit"
                       (change)="substance.Visitors = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="row">
            <div class="col-12 fw-bold harmony-teal-text">
              Required Protective Wear
              <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                       [title]="'What protective wear is required'">
              </fa-icon>
              <hr class="mt-1 mb-1">
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Gloves
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-gloves'"
                       [title]="'Require gloves to be worn'" [checked]="substance.Gloves"
                       [disabled]="!canEdit"
                       (change)="substance.Gloves = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Apron
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-apron'"
                       [title]="'Require an apron to be worn'" [checked]="substance.Apron"
                       [disabled]="!canEdit"
                       (change)="substance.Apron = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Dust Mask
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-dust-mask'"
                       [title]="'Require a dust mask to be worn'" [checked]="substance.DustMask"
                       [disabled]="!canEdit"
                       (change)="substance.DustMask = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Gas Mask
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-gas-mask'"
                       [title]="'Require a gas mask to be worn'" [checked]="substance.GasMask"
                       [disabled]="!canEdit"
                       (change)="substance.GasMask = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Glasses
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-glasses'"
                       [title]="'Require glasses to be worn'" [checked]="substance.Glasses"
                       [disabled]="!canEdit"
                       (change)="substance.Glasses = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Face Mask
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-fae-mask'"
                       [title]="'Require face mask to be worn'" [checked]="substance.FaceMask"
                       [disabled]="!canEdit"
                       (change)="substance.FaceMask = $event.target.checked">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Boots
            </div>
            <div class="col-3">
              <div class="form-check form-switch form-switch-md">
                <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-boots'"
                       [title]="'Require boots to be worn'" [checked]="substance.Boots"
                       [disabled]="!canEdit"
                       (change)="substance.Boots = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2">
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        General
      </div>
      <div class="col-5">
        <textarea name="general" class="form-control form-control-sm" rows="1" [formControlName]="'general'"></textarea>
      </div>
      <div class="col-1">
        Storage
      </div>
      <div class="col-5">
        <textarea name="storage" class="form-control form-control-sm" rows="1" [formControlName]="'storage'"></textarea>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        Transport
      </div>
      <div class="col-5">
        <textarea name="transport" class="form-control form-control-sm" rows="1"
                  [formControlName]="'transport'"></textarea>
      </div>
      <div class="col-1">
        Ventilation
      </div>
      <div class="col-5">
        <textarea name="ventilation" class="form-control form-control-sm" rows="1"
                  [formControlName]="'ventilation'"></textarea>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        Fire
      </div>
      <div class="col-5">
        <textarea name="fire" class="form-control form-control-sm" rows="1" [formControlName]="'fire'"></textarea>
      </div>
      <div class="col-1">
        First Aid
      </div>
      <div class="col-5">
        <textarea name="first-aid" class="form-control form-control-sm" rows="1"
                  [formControlName]="'firstAid'"></textarea>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        Spillage
      </div>
      <div class="col-5">
        <textarea name="spillage" class="form-control form-control-sm" rows="1"
                  [formControlName]="'spillage'"></textarea>
      </div>
      <div class="col-1">
        Disposal
      </div>
      <div class="col-5">
        <textarea name="disposal" class="form-control form-control-sm" rows="1"
                  [formControlName]="'disposal'"></textarea>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        Comment
      </div>
      <div class="col-5">
        <textarea name="comment" class="form-control form-control-sm" rows="1" [formControlName]="'comment'"></textarea>
      </div>
    </div>
  </div>
</form>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="!canEdit" (click)="update()">Save
  </button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="!canEdit" (click)="add()">Save
  </button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
