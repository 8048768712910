import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../services/email.service';
import { EditScheduleModalComponent } from './email-schedule-overview/edit-schedule-modal/edit-schedule-modal.component';
import { AlertService } from '../../../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  providers: [EmailService]
})
export class EmailComponent implements OnInit {
  // Icons
  faAdd = faPlus;

  constructor(private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  newSchedule() {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(EditScheduleModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.EmailScheduleID = null;
    modalRef.componentInstance.InitEmailSchedule();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        // this.alertService.success(result);
      }
    }, () => { });
  }
}
