import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import {
  ICompanyChartCategories,
  ICompanyChartItem, ICompanyChartStatus,
  ICompanyChartDocument,
  IDocumentRequestDto
} from '../../../../services/models/hse/hse.model';
import { AlertService } from "../../../../services/alert.service";
import { HseService } from "../../../../services/hse.service";
import { environment } from "../../../../../environments/environment";
import { ApiService } from "../../../../services/api.service";
import {
  faDownload,
  faUpload,
  faEdit,
  faTrash,
  faSave,
  faBoxOpen
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { OpenConfirmationModal } from "../../../../shared/components/confirmation-modal/confirmation-modal-functions";
import { takeUntil } from "rxjs/operators";
import { FileService } from "../../../../services/file.service";
import { Subject } from "rxjs";
import { IApiResponse } from "../../../../shared/models/api-reponse.model";
import {
  CompanyChartItemDocumentModalComponent
} from "../company-chart-item-document-modal/company-chart-item-document-modal.component";
import { IUser } from '../../../../services/models/user.model';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { getDayDifference } from "../../../../shared/functions/datetime-functions";

@Component({
  selector: 'app-company-chart',
  templateUrl: './company-chart-item-modal.component.html',
  styleUrls: ['./company-chart-item-modal.component.scss']
})
export class CompanyChartItemModalComponent implements OnInit, OnDestroy {
  // Icons
  faUpload = faUpload;
  faDelete = faTrash;
  faEdit = faEdit;
  faDownload = faDownload;
  faSave = faSave;
  faInfo = faCircleQuestion;
  faBoxOpen = faBoxOpen;

  // Component Variables
  public CompanyId: number;
  public CompanyChartItem: ICompanyChartItem;
  public IsNew: boolean;
  companyChartCategories: ICompanyChartCategories[] = [];
  companyChartStatus$ = this.hseService.CompanyChartStatus$.asObservable();
  activeStatus: ICompanyChartStatus;
  // overdueStatus: ICompanyChartStatus;
  users: IUser[] = null;
  document = {} as IDocumentRequestDto;
  documents: ICompanyChartDocument[] = [];
  invalid: boolean;
  isUpdated: boolean = false;
  title: string = "";

  // Permissions
  canEdit: boolean;
  canUpload: boolean;

  // General variables
  loading: boolean;
  environment = environment;
  private unsubscribe: Subject<any> = new Subject<any>();


  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private apiService: ApiService,
              private hseService: HseService,
              public authService: AdAuthService,
              private modalService: NgbModal,
              private fileService: FileService) { }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    } else {
      this.authService.CurrentUser$.subscribe(() => {
        this.setPermissions();
      });
    }

    this.loadCompanyChartCategories(this.CompanyChartItem.CompanyChartId)
    this.getDocuments();
    // Set status
    this.companyChartStatus$.subscribe((x) => {
      this.activeStatus = x.find(r => r.Id === this.CompanyChartItem.CcStatus);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_EditInformation);
    this.canUpload = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_UploadDocument);
  }

  validate(): boolean {
    return (this.CompanyChartItem.Subject != null && this.CompanyChartItem.Next != null && this.CompanyChartItem.CcStatus != null)
  }

  loadCompanyChartCategories(companyChartId: number) {
    this.loading = true;

    this.hseService.getCompanyChartCategoriesByCompanyChartId(companyChartId)
      .subscribe({
        next: (data): void => {
          this.companyChartCategories = Object.assign([], data);
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  updateCompanyChartItem() {
    if (this.validate()) {
      this.CompanyChartItem.DateModified = new Date();
      this.CompanyChartItem.ModifiedByUserId = this.authService.CurrentUser.UserId;
      this.CompanyChartItem.Active = true;

      this.hseService.updateCompanyChartItem(this.CompanyChartItem)
        .subscribe({
          next: (data) => {
            this.CompanyChartItem = Object.assign({}, data);
            this.alertService.success('Company Chart item: ' + this.CompanyChartItem.Subject + ' successfully saved');
            this.isUpdated = true;
            this.IsNew = false;
          },
          error: () => {
            this.alertService.error('Failed to update Company Chart item');
          }
        });
    } else {
      this.alertService.error('Please fill all required fields');
    }
  }

  setLastDate(date: string) {
    this.CompanyChartItem.Last = date;
  }

  setNextDate(date: string) {
    this.CompanyChartItem.Next = date;

    const daysToNext = getDayDifference(date);
    if (daysToNext < 0) {
      // Set status
      this.companyChartStatus$.subscribe((x) => {
        this.activeStatus = x.find(r => r.Status === "Overdue");
      });
      // this.activeStatus = this.overdueStatus;
      this.setStatus();
    } else {
      if (daysToNext > 0 && daysToNext  <= 7) {
        // If selected date is within 7 days from now set status to Requires Attention
        this.companyChartStatus$.subscribe((x) => {
          this.activeStatus = x.find(r => r.Status === "Requires attention");
        });
        // this.activeStatus = this.overdueStatus;
      } else if (daysToNext > 7) {
        // If selected date is later than 7 days from today, set status to ok
        this.companyChartStatus$.subscribe((x) => {
          this.activeStatus = x.find(r => r.Status === "OK");
        });
        // this.activeStatus = this.overdueStatus;
      }
      this.setStatus();
    }
  }

  setStatus() {
    this.CompanyChartItem.CcStatus = this.activeStatus?.Id;
    this.CompanyChartItem.StatusDescription = this.activeStatus?.Status;
  }

  openFileSelectionModal() {
    const modalRef = this.modalService.open(CompanyChartItemDocumentModalComponent, {size: 'lg', backdrop: 'static'});
    if (this.CompanyChartItem != null) {
      modalRef.componentInstance.companyChartItem = this.CompanyChartItem;
      modalRef.componentInstance.companyId = this.CompanyId;
    }
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result == true) {
        this.isUpdated = true;
        this.getDocuments();
      }
    }, () => { });
  }

  getDocuments() {
    if (this.CompanyId && this.CompanyChartItem.Id) {
      this.loading = true;
      this.apiService.get(`CompanyChart/Documents/List?companyId=${this.CompanyId}&companyChartItemId=${this.CompanyChartItem.Id}`).subscribe({
        next: (data) => {
          this.loading = false;
          this.documents = Object.assign([], data);
        }, error: (err: IApiResponse) => {
          this.loading = false;
          this.alertService.error(err?.Meta?.Message);
        }
      });
    }
  }

  deleteDocument(id: number) {
    let message = 'Are you sure you want to delete this file. It can not be undone?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.apiService.delete(`CompanyChart/Document/${id}`)
            .subscribe({
              next: () => {
                // remove from display
                let index = this.documents.findIndex(f => f.Id == id)
                this.documents.splice(index, 1);
                this.isUpdated = true;
              }
            });
        }
      });
  }

  downloadDocument(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.CompanyChart, reference);
  }

  updateName(document: ICompanyChartDocument) {
    this.hseService.updateDocumentDescription(document)
      .subscribe({
        next: () => {
          this.isUpdated = true;
          this.alertService.success('File description successfully updated.');
        },
        error: (error) => {
          this.alertService.error('An error occurred: ' + error.Meta.Message);
        }
      });
  }

  close(companyChartItem: ICompanyChartItem) {
    if (companyChartItem != null && companyChartItem.Id > 0 && this.isUpdated){
      companyChartItem.NrOfAttachments = this.documents.length;
      this.activeModal.close(companyChartItem);
    } else {
      companyChartItem = null;
      this.activeModal.close(null);
    }
  }
}
