import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RateMappingSearchComponent } from './components/rate-mapping-search/rate-mapping-search.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AlertComponent } from './components/alert/alert.component';
import { CitySearchComponent } from './components/city-search/city-search.component';
import { PortSearchComponent } from './components/port-search/port-search.component';
import { RegexMaskDirective } from './directives/regex-mask/regex-mask.directive';
import { PasswordMaskDirective } from './directives/password-mask/password-mask.directive';
import { VarDirective } from './directives/variable/var.directive';
import { PercentageValueDirective } from './directives/percentage-value/percentage-value.directive';
import { AutocompleteOffDirective } from './directives/autocomplete-off/autocomplete-off.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { CountrySearchComponent } from './components/country-search/country-search.component';
import { StateSearchComponent } from './components/state-search/state-search.component';
import { AgentSearchComponent } from './components/agent-search/agent-search.component';
import { TenderSearchComponent } from './components/tender-search/tender-search.component';
import { NoResultComponent } from './components/no-result/no-result.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortDirective } from './directives/sort/sort.directive';
import { FormValidationStyleDirective } from './directives/form-validation/form-validation-directive';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { ManageImageComponent } from './components/manage-image/manage-image.component';
import { MobileNumberComponent } from './components/mobile-number/mobile-number.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MemberMapComponent } from './components/member-map/member-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { OrderDirective } from './directives/sort/order.directive';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { HangfireDashboardComponent } from './components/hangfire-dashboard/hangfire-dashboard.component';
import { NoSanitizePipe } from './directives/no-sanitize/no-sanitize.directive';
import { EmailCaptureComponent } from './components/email-capture/email-capture.component';
import {DashboardCardComponent} from "./components/dashboard/dashboard-card/dashboard-card.component";
import {DashboardContainerComponent} from "./components/dashboard/dashboard-container/dashboard-container.component";
import { WatermarkComponent } from './components/watermark/watermark.component';
import { UserSearchComponent } from './components/user-search/user-search.component';

@NgModule({
  declarations: [
    RateMappingSearchComponent,
    LoaderComponent,
    AlertComponent,
    CitySearchComponent,
    CountrySearchComponent,
    StateSearchComponent,
    PortSearchComponent,
    AgentSearchComponent,
    RegexMaskDirective,
    PasswordMaskDirective,
    VarDirective,
    PercentageValueDirective,
    AutocompleteOffDirective,
    PaginationComponent,
    ToggleButtonComponent,
    TenderSearchComponent,
    NoResultComponent,
    TenderSearchComponent,
    ToggleButtonComponent,
    SortDirective,
    FormValidationStyleDirective,
    NumbersOnlyDirective,
    CompanySearchComponent,
    ManageImageComponent,
    MobileNumberComponent,
    CompanyDetailsComponent,
    ConfirmationModalComponent,
    DatePickerComponent,
    MemberMapComponent,
    OrderDirective,
    ContactCardComponent,
    HangfireDashboardComponent,
    NoSanitizePipe,
    DashboardCardComponent,
    DashboardContainerComponent,
    NoSanitizePipe,
    WatermarkComponent,
    UserSearchComponent
  ],
  exports: [
    RateMappingSearchComponent,
    LoaderComponent,
    AlertComponent,
    CitySearchComponent,
    CountrySearchComponent,
    StateSearchComponent,
    AgentSearchComponent,
    PortSearchComponent,
    PasswordMaskDirective,
    VarDirective,
    PercentageValueDirective,
    AutocompleteOffDirective,
    PaginationComponent,
    ToggleButtonComponent,
    TenderSearchComponent,
    NoResultComponent,
    TenderSearchComponent,
    ToggleButtonComponent,
    SortDirective,
    FormValidationStyleDirective,
    NumbersOnlyDirective,
    FormsModule,
    CompanySearchComponent,
    ManageImageComponent,
    MobileNumberComponent,
    CompanyDetailsComponent,
    DatePickerComponent,
    MemberMapComponent,
    OrderDirective,
    ContactCardComponent,
    HangfireDashboardComponent,
    NoSanitizePipe,
    WatermarkComponent,
    UserSearchComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    GoogleMapsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    EmailCaptureComponent
  ]
})
export class SharedModule { }
