import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MissingValuesComponent } from './missing-values/missing-values.component';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { DefaultValuesComponent } from './default-values/default-values.component';
import { MessageLogsComponent } from './message-logs/message-logs.component';
import { ValueMappingsComponent } from './value-mappings/value-mappings.component';
import { SchemaManagerComponent } from './schema-manager/schema-manager.component';
import { SchemaMappingsComponent } from './schema-mappings/schema-mappings.component';
import { HubClientsComponent } from './hub-admin/hub-clients/hub-clients.component';
import { HubLookupDataComponent } from './hub-admin/hub-lookup-data/hub-lookup-data.component';
import { HubLogComponent } from './hub-admin/hub-log/hub-log.component';
import { HubSystemsComponent } from './hub-admin/hub-systems/hub-systems.component';
import { PermissionCodes } from '../../core/constants/permission-codes';

const routes: Routes = [
  {
    path: 'missing-values',
    component: MissingValuesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_MissingValues
    }
  },
  {
    path: 'default-values',
    component: DefaultValuesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_DefaultValues
    }
  },
  {
    path: 'message-log',
    component: MessageLogsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_MessageLogs
    }
  },
  {
    path: 'message-log/:messageId',
    component: MessageLogsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_MessageLogs
    }
  },
  {
    path: 'value-mappings',
    component: ValueMappingsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_ManageMappings
    }
  },
  {
    path: 'schema-manager',
    component: SchemaManagerComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_SchemaManager
    }
  },
  {
    path: 'schema-mappings',
    component: SchemaMappingsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_SchemaMappings
    }
  },
  {
    path: 'admin/clients',
    component: HubClientsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_Admin
    }
  },
  {
    path: 'admin/systems',
    component: HubSystemsComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_Admin
    }
  },
  {
    path: 'admin/log',
    component: HubLogComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_Admin
    }
  },
  {
    path: 'admin/lookup-data',
    component: HubLookupDataComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Hub_Admin
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HubRoutingModule { }
