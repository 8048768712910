import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appPasswordMask]'
})
export class PasswordMaskDirective {
  private shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(div: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      div.innerHTML = '<img src="assets/icons/eye-slash.png" width="20" alt="" />';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      div.innerHTML = '<img src="assets/icons/eye.svg" width="20" alt="" />';
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const div = document.createElement('div');
    div.setAttribute('class', 'show-password');
    div.innerHTML = '<img src="assets/icons/eye.svg" width="20" alt="" />';
    div.addEventListener('click', (event) => {
      this.toggle(div);
    });
    parent.appendChild(div);
  }
}
