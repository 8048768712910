import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {
  // Value before the decimal point specifies the number of digits before decimal and value after the decimal specifies the number of digits after decimal.
  @Input('numbersOnly') decimals = '4.2';

  private check(value: string) {
    let [length, precision] = this.decimals.split('.'),
      regExpString = `^(-?[\\d]{0,${+length}})((\\.{1})([\\d]{0,${+precision}})?)?$`;
    return String(value).match(new RegExp(regExpString));
  }

  private run(oldValue: string) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue && !this.check(currentValue)) {
        if (oldValue.indexOf('.') >= 0 && oldValue.split('.')[1].length == 0) {
          oldValue = oldValue.replace('.', '');
        }
        this.control.control.patchValue(oldValue);
      }
    }, 200);
  }

  private runPaste(oldValue: string) {
    setTimeout(() => {
      let currentValue: string = oldValue.replaceAll(' ', '');
      let [length, precision] = this.decimals.split('.');
      let [value, valuePrecision] = currentValue.split('.');
      // Check if precision value is allowed
      if (precision && valuePrecision) {
        const maxLength = parseInt(precision);
        if (valuePrecision.length > maxLength) {
          valuePrecision = valuePrecision.substring(0, maxLength);
        }
        currentValue = `${value}.${valuePrecision}`;
      } else {
        currentValue = `${value}`;
      }
      // Run standard check on new value
      if (!this.check(currentValue)) {
        if (oldValue.indexOf('.') >= 0 && oldValue.split('.')[1].length == 0) {
          oldValue = oldValue.replace('.', '');
        }
        this.control.control.patchValue(null);
      } else {
        this.control.control.patchValue(currentValue);
      }
    });
  }

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.runPaste(pastedText);
  }
}
