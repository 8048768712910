<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    News Room
  </h1>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mt-1">
      <div class="col-4">
        <input (change)="search()"
               *ngIf="searchParam"
               [(ngModel)]="searchParam.SearchText"
               class="filter-item form-control form-control-sm"
               placeholder="Search article by title or author name..."
               type="text">
      </div>
    </div>
    <hr class="mt-2 mb-0">
    <!--------------------->
    <!---- Pagination ----->
    <!--------------------->
    <div class="row ms-1 me-1">
      <div class="col-12" *ngIf="page">
        <app-pagination (pageEvent)="onPageChange($event)"
                        [page]="page"
                        [paginationData]=paginationData
                        [showPageNumber]=true
                        [showRowsPerPage]=true></app-pagination>
      </div>
    </div>
    <!--------------------->
    <!------ Results ------>
    <!--------------------->
    <div class="flex-wrap flex-md-nowrap align-items-center mt-1 pb-5">
      <div class="row header-row align-content-start mt-2">
        <div class="col-1 header-title border-split-left"></div>
        <div class="col-5 header-title pointer"
             (appOrder)="onOrderChange($event)"
             data-column="Title"
             data-order="asc">
          Title
          <fa-icon [hidden]="order?.OrderBy != 'Title'" class="ms-1 position-relative"></fa-icon>
        </div>
        <div class="col-1 header-title text-center">
          Featured
        </div>
        <div class="col-1 header-title col-compress pointer"
             (appOrder)="onOrderChange($event)"
             data-column="PublishDate"
             data-order="asc">
          Published
          <fa-icon [hidden]="order?.OrderBy != 'PublishDate'" [icon]="faSortUp" style="bottom: -3px;" class="ms-1 position-relative"></fa-icon>
        </div>
        <div class="col-4 header-title pointer border-split-right"
             (appOrder)="onOrderChange($event)"
             data-column="CreatedByUser.DisplayName"
             data-order="asc">
          Author
          <fa-icon [hidden]="order?.OrderBy != 'CreatedByUser.DisplayName'" class="ms-1 position-relative"></fa-icon>
        </div>
      </div>
      <!---------------------->
      <!------- Loader ------->
      <!---------------------->
      <app-loader *ngIf="loading"></app-loader>
      <app-no-result [showNoResults]="noResult"></app-no-result>
      <!----------------------->
      <!------- Results ------->
      <!----------------------->
      <div *ngFor="let article of paginationData?.DataSet; let i = index" class="row result-row border-bottom">
        <div class="col-1 overflow-hidden text-center ps-0" data-bs-toggle="tooltip">
          <ng-container>
            <div class="article-image-wrapper"
                 *ngIf="article.ImageReference"
                 [ngStyle]="{'background-image': 'url(\'' + imageBaseUrl + article.Id + '/'+ article.ImageReference + '\')' }">
            </div>
          </ng-container>
          <ng-container>
            <div class="article-image-wrapper"
                 *ngIf="!article.ImageReference"
                 [ngStyle]="{'background-image': 'url(\'' + placeholderImage + '\')' }">
            </div>
          </ng-container>
        </div>
        <div class="col-5 text-wrap text-capitalize" data-bs-toggle="tooltip" title="{{ article.Title }}">
          <h6 class="fw-bold harmony-teal-text">{{ article.Title }}</h6>
          <small>{{ article.Summary.slice(1, 200) }}...</small>
        </div>
        <div class="col-1 overflow-hidden text-center">
          <fa-icon *ngIf="article.Featured" [icon]="faCheck" class="harmony-green-text" size="lg"></fa-icon>
          <fa-icon *ngIf="!article.Featured" [icon]="faNotFeatured" class="harmony-grey-text" size="lg"></fa-icon>
        </div>
        <div class="col-1 overflow-hidden">
          <small>{{ article.PublishDate | date: env.FormattingStandards.ShortDateFormat }}</small>
        </div>
        <div class="col-2 overflow-hidden">
          {{ article.Author }}
        </div>
        <div class="col-2 overflow-hidden text-end pe-0">
          <button
            (click)="redirectReadArticle(article.Id)"
            class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-1"
            title="Read article">
            <fa-icon [icon]="faRead" size="lg"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

