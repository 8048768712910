import { Component, OnInit } from '@angular/core';
import {
  faBoxOpen,
  faPencil,
  faSortDown,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
  IHubDefaultValue, IHubDefaultValueParam, IHubDeleteDefaultValue
} from '../../../services/models/hub/hub-values.model';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { HubAdminService } from '../../../services/hub-admin.service';
import { AlertService } from '../../../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAuthUser } from '../../../services/models/auth.model';
import { takeUntil } from 'rxjs/operators';
import { NewDefaultValueModalComponent } from './new-default-value-modal/new-default-value-modal.component';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { EditValueListModalComponent } from './edit-value-list-modal/edit-value-list-modal.component';
import { IHubClientSystemOverview } from '../../../services/models/hub/hub.model';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-default-values',
  templateUrl: './default-values.component.html',
  styleUrls: ['./default-values.component.scss']
})
export class DefaultValuesComponent implements OnInit {
  // Icons
  protected readonly faEdit = faPencil;
  protected readonly faDelete = faTrash;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faSortAsc = faSortDown;

  // Component variables
  searchParam: IHubDefaultValueParam;
  paginationData: IPaginationData<IHubDefaultValue>;
  orderParam: IOrderParam;
  page: IPage;
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClient: IHubClientSystemOverview = null;

  // Permissions
  canViewAll: boolean = false;
  ucid: number = null;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  env = environment;

  constructor(private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.initPage();

    if (this.auth.CurrentUser) {
      this.ucid = this.auth.CurrentUser.User.CompanyId;
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.ucid = user.User.CompanyId;
      this.setPermissions();
    });
  }

  initPage() {
    this.searchParam = {
      SourceClientId: null,
      TargetClientId: null
    } as IHubDefaultValueParam;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IHubDefaultValue>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'SourceClientName',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  setPermissions() {
    this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);

    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAll) {
        this.ucid = null;
        this.userClients$.next(data);
        this.searchDefaultValues();
      } else {
        const clients = data.filter(x => x.TargetCompanyId == this.ucid);
        this.userClients$.next(clients);
        if (clients.length >= 1) {
          this.selectedClient = clients[0];
          this.setClientData();
        }
      }
    });
  }

  setClientData() {
    this.searchParam.SourceClientId = this.selectedClient?.SourceClientId;
    this.searchParam.TargetClientId = this.selectedClient?.TargetClientId;

    this.searchDefaultValues();
  }

  searchDefaultValues() {
    this.loading = true;

    this.hubAdmin.SearchDefaultValues(this.searchParam, this.page, this.orderParam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: IPaginationData<IHubDefaultValue>) => {
          this.paginationData = Object.assign({}, data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchDefaultValues();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchDefaultValues();
  }

  editValue(param: IHubDefaultValue) {
    // Open modal to edit a value list item
    const modalRef = this.modalService.open(EditValueListModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.ValueListId = param.ValueListId;
    modalRef.componentInstance.LoadValueListItem();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.searchDefaultValues();
      }
    });
  }

  deleteDefaultValue(param: IHubDefaultValue) {
    const message = 'Are you sure you want to delete this default?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          const deleteParam = {
            ValueListId: param.ValueListId,
            ElementId: param.ElementId,
            ClientId: param.SourceClientId
          } as IHubDeleteDefaultValue

          this.hubAdmin.DeleteDefaultValue(deleteParam).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
            next: () => {
              this.alertService.success('Default value successfully deleted.');
              this.searchDefaultValues();
            },
            error: () => {
              this.alertService.error('An error occurred while trying to delete this default value.');
            }
          });
        }
      });
  }

  newDefaultValue() {
    // Open modal to create the missing mapping
    const modalRef = this.modalService.open(NewDefaultValueModalComponent, {size: 'lg', backdrop: 'static'});
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.searchDefaultValues();
      }
    });
  }
}
