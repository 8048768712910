import { Component } from '@angular/core';

@Component({
  selector: 'app-services-customer-satisfaction',
  templateUrl: './services-customer-satisfaction.component.html',
  styleUrls: ['./services-customer-satisfaction.component.scss']
})
export class ServicesCustomerSatisfactionComponent {

}
