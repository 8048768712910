import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { HubMenuComponent } from './hub-menu/hub-menu.component';
import { NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingValuesComponent } from './missing-values/missing-values.component';
import { JsonViewRatesModalComponent } from '../pricing/external-data-manager/json-view-rates-modal/json-view-rates-modal.component';
import { HubRoutingModule } from './hub-routing.module';
import { HubAdminService } from '../../services/hub-admin.service';
import { MapMissingValueModalComponent } from './missing-values/map-missing-value-modal/map-missing-value-modal.component';
import { ValueMappingsComponent } from './value-mappings/value-mappings.component';
import { DefaultValuesComponent } from './default-values/default-values.component';
import { MessageLogsComponent } from './message-logs/message-logs.component';
import { ViewMessageMappingModalComponent } from './message-logs/view-message-mapping-modal/view-message-mapping-modal.component';
import { ViewMessageModalComponent } from './message-logs/view-message-modal/view-message-modal.component';
import { NewDefaultValueModalComponent } from './default-values/new-default-value-modal/new-default-value-modal.component';
import { EditValueListModalComponent } from './default-values/edit-value-list-modal/edit-value-list-modal.component';
import { CreateValueMappingModalComponent } from './value-mappings/create-value-mapping-modal/create-value-mapping-modal.component';
import { SchemaManagerComponent } from './schema-manager/schema-manager.component';
import { SchemaMappingsComponent } from './schema-mappings/schema-mappings.component';
import { HubAdminComponent } from './hub-admin/hub-admin.component';
import { HubClientsComponent } from './hub-admin/hub-clients/hub-clients.component';
import { HubSystemsComponent } from './hub-admin/hub-systems/hub-systems.component';
import { HubLogComponent } from './hub-admin/hub-log/hub-log.component';
import { HubLookupDataComponent } from './hub-admin/hub-lookup-data/hub-lookup-data.component';

@NgModule({
    declarations: [
      HubMenuComponent,
      MissingValuesComponent,
      JsonViewRatesModalComponent,
      MapMissingValueModalComponent,
      ValueMappingsComponent,
      DefaultValuesComponent,
      MessageLogsComponent,
      ViewMessageModalComponent,
      ViewMessageMappingModalComponent,
      NewDefaultValueModalComponent,
      EditValueListModalComponent,
      CreateValueMappingModalComponent,
      SchemaManagerComponent,
      SchemaMappingsComponent,
      HubAdminComponent,
      HubClientsComponent,
      HubSystemsComponent,
      HubLogComponent,
      HubLookupDataComponent
    ],
    exports: [
      HubMenuComponent
    ],
    providers: [
      HubAdminService
    ],
    imports: [
      CommonModule,
      SharedModule,
      FormsModule,
      RouterModule,
      FontAwesomeModule,
      NgbPopoverModule,
      NgbTooltipModule,
      NgbDatepickerModule,
      HubRoutingModule
    ]
})
export class HubModule { }
