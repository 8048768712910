<div class="modal-header">
  <h4 class="modal-title">
    {{ this.RoleId ? 'Editing Role ID: ' + this.RoleId : 'New Role' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body" *ngIf="Role">
  <div class="row">
    <div class="col-3">
      <b>Name</b>
    </div>
    <div class="col-9" style="line-height: 11px;">
      <input type="text"
             [(ngModel)]="Role.Name"
             [ngClass]="{'is-invalid': (!this.Role.Name || this.Role.Name.length < 3) && invalid}"
             placeholder="minimum 3 characters"
             class="form-control">
      <small *ngIf="(!this.Role.Name || this.Role.Name.length < 3) && invalid" class="harmony-grey-text">Required. Minimum length of 3 characters.</small>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>Description</b>
    </div>
    <div class="col-9" style="line-height: 11px;">
      <textarea class="form-control"
                name="roleDescription"
                id="roleDescription"
                placeholder="minimum 10 characters"
                [ngClass]="{'is-invalid': (!this.Role.Description || this.Role.Description.length < 10) && invalid}"
                [(ngModel)]="Role.Description"
                cols="30"
                rows="3"></textarea>
      <small *ngIf="(!this.Role.Description || this.Role.Description.length < 10) && invalid" class="harmony-grey-text">Required. Minimum length of 10 characters.</small>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>Parent Role</b>
    </div>
    <div class="col-9" style="line-height: 11px;">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="Role.ParentRoleId"
              [disabled]="!(roles$ | async)">
        <option [ngValue]="null">No parent selected...</option>
        <option *ngFor="let role of (roles$ | async)"
                [ngValue]="role.Id">
          {{ role.Name }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="row m-1 fw-bold">
        <div class="col-6 text-center">
          <b>Available Permissions</b>
        </div>
        <div class="col-6 text-center">
          <b>Active Permissions</b>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row mt-1">
        <div class="col-6">
          <select name="module"
                  class="form-select form-select-sm"
                  id="module"
                  [(ngModel)]="moduleId">
            <option [ngValue]="null" selected>All modules...</option>
            <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
              {{module.Name}}
            </option>
          </select>
        </div>
        <div class="col-6">
          <select name="module"
                  class="form-select form-select-sm"
                  id="active-module"
                  [(ngModel)]="activeModuleId">
            <option [ngValue]="null" selected>All modules...</option>
            <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
              {{module.Name}}
            </option>
          </select>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row">
        <div class="col-6 permission-list border-end ps-0 pe-0">
          <ng-container *ngFor="let component of availableRoleFeatures(); let i = index; trackBy: identify">
            <div class="row result-row" *ngIf="!hasComponent(component.Id)">
              <div class="col-7">
                {{component.Name}}
              </div>
              <div class="col-3">
                <code>{{component.Code}}</code>
              </div>
              <div class="col-2 text-end">
                <fa-icon [icon]="faAdd" class="harmony-green-text pointer" size="1x" (click)="addRoleFeature(component)"></fa-icon>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-6 permission-list ps-0 pe-0">
          <ng-container *ngIf="!this.Role.RoleFeatures || this.Role.RoleFeatures.length == 0">
            <div class="ps-3">
                No permissions have been assigned to this role...
            </div>
          </ng-container>
          <ng-container *ngIf="this.Role.RoleFeatures && this.Role.RoleFeatures.length > 0">
            <div class="row result-row" *ngFor="let roleFeature of activeRoleFeatures(); let i = index; trackBy: identify">
              <div class="col-7">
                {{ roleFeature.Component.Name }}
              </div>
              <div class="col-3">
                <code>{{ roleFeature.Component.Code }}</code>
              </div>
              <div class="col-2 text-end">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer"
                         size="1x" (click)="removeRoleFeature(roleFeature)"></fa-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveRole()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
