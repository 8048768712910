import { Component, OnInit } from '@angular/core';
import { IPulseChartDto } from '../../../../services/models/hse/pulse-chart.model';
import { ApiService } from '../../../../services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-pulse-chart-modal',
  templateUrl: './pulse-chart-modal.component.html',
  styleUrls: ['./pulse-chart-modal.component.scss']
})
export class PulseChartModalComponent implements OnInit {

  // Input
  public pulseChartId: number;
  public companyId: number;
  public isNew: false;
  public canSelectCompany: false;
  public canEdit: false;
  // Component Variables
  public pulseChart = {} as IPulseChartDto;
  invalid: boolean = false;

  constructor(private apiService: ApiService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService) {
  }

  ngOnInit() {
    if (!this.isNew) {
      this.getPulseChart();
    }
    if (this.companyId){
      this.pulseChart.CompanyId = this.companyId;
    }
  }


  getPulseChart() {
    this.apiService.get(`PulseChart/${this.pulseChartId}`)
      .subscribe({
        next: (data) => {
          this.pulseChart = Object.assign({}, data);
          if (this.companyId){
            this.pulseChart.CompanyId = this.companyId;
          }
        }
      });
  }

  calculateRatio(total: number, items: number) {
    if (total && items) {
      const value = total / items;
      return this.round(value);
    }
    return null;
  }

  calculatePerMillion(total: number, items: number) {
    if (total && items) {
      const value = 1000000 / total * items;
      return this.round(value);
    }
    return null
  }

  calculatePercentage(total: number, items: number) {
    if (total && items){
      const value = items / total * 100;
      return this.round(value);
    }
    return null;
  }

  round(value: number){
    return (Math.round((value + Number.EPSILON) * 100) / 100);
  }

  // Footer Functions
  close(reload: boolean) {
    this.activeModal.close(reload);
  }

  update() {
    if (this.isValid()) {
      this.apiService.put(`PulseChart/${this.pulseChartId}`, this.pulseChart)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to update pulse chart');
          }
        });
    }
  }

  add() {
    if (this.isValid()) {
      this.apiService.post('PulseChart', this.pulseChart)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to add pulse chart');
          }
        });
    }
  }

  isValid() {
    if (this.pulseChart.Year &&
      this.pulseChart.WorkRelatedSickDays &&
      this.pulseChart.WorkDays &&
      this.pulseChart.FTE) {
      return true;
    }
    this.invalid = true;
    this.alertService.error('Please check all mandatory fields marked with *');
    return false;
  }

  updateAccidents() {
    this.pulseChart.RatioWorkHoursAccident = this.calculateRatio(this.pulseChart.WorkHours, this.pulseChart.AccidentsWithSickLeave);
    this.pulseChart.AccidentsPerMillionHours = this.calculatePerMillion(this.pulseChart.WorkHours, this.pulseChart.AccidentsWithSickLeave);
  }

  updateIncidents() {
    this.pulseChart.RatioWorkHoursIncident = this.calculateRatio(this.pulseChart.WorkHours, this.pulseChart.IncidentsWithNoSickLeave);
    this.pulseChart.IncidentsPerMillionHours = this.calculatePerMillion(this.pulseChart.WorkHours, this.pulseChart.IncidentsWithNoSickLeave);
  }

  updateEnvIncidents() {
    this.pulseChart.RatioWorkHoursEnvIncident = this.calculateRatio(this.pulseChart.WorkHours, this.pulseChart.EnvironmentalIncident);
    this.pulseChart.EnvIncidentsPerMillionHours = this.calculatePerMillion(this.pulseChart.WorkHours, this.pulseChart.EnvironmentalIncident);
  }

  updateSickDays() {
    this.pulseChart.SickDaysPercentage = this.calculatePercentage(this.pulseChart.WorkHours, this.pulseChart.SickDays);
  }

  updateWorkSickDays() {
    this.pulseChart.WorkRelatedSickDaysPercentage = this.calculatePercentage(this.pulseChart.WorkHours, this.pulseChart.WorkRelatedSickDays);
  }


  updateWorkHours() {
    this.updateAccidents();
    this.updateIncidents();
    this.updateEnvIncidents();
    this.updateSickDays();
    this.updateWorkSickDays();
  }
}
