<div id="news-accordion">
  <div class="card portal-menu-collapse-card">
    <div aria-controls="collapseNews"
         aria-expanded="true"
         class="portal-menu-collapse-header bg-dark"
         data-bs-target="#collapseNews"
         data-bs-toggle="collapse"
         id="headingNews">
      <h5 class="portal-menu-heading">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faNews"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-teal-text': router.url.includes('news/')}">
          News & Insights
        </span>
      </h5>
    </div>
    <div aria-labelledby="headingNews" class="collapse" data-bs-parent="#news-accordion" id="collapseNews">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li *ngIf="newsRoom" class="nav-item">
              <a [ngClass]="{'active': router.url.includes(NewsRoutes.Article_Network)}"
                 [routerLink]="NewsRoutes.Article_Network"
                 class="nav-link pointer">
                <fa-icon [icon]="faMenuItem" class="link-icon me-2" size="2xs"></fa-icon>
                News Room
              </a>
            </li>
          </ul>
          <ul class="nav flex-column mb-0">
            <li *ngIf="articleManagement" class="nav-item">
              <a [ngClass]="{'active': router.url.includes(NewsRoutes.Article_Management)}"
                 [routerLink]="NewsRoutes.Article_Management"
                 class="nav-link pointer">
                <fa-icon [icon]="faMenuItem" class="link-icon me-2" size="2xs"></fa-icon>
                Article Management
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
