<div class="row">
  <div class="col-1 offset-11">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
            (click)="setDashboard()">
      Refresh
    </button>
  </div>
</div>
<div class="row mt-2" style="height: 72vh;" >
  <div class="col-12">
    <iframe [src]="dashboardUrl" referrerpolicy="no-referrer" class="w-100 h-100"></iframe>
  </div>
</div>
