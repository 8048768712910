<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <ng-container *ngIf="company && company?.CompanyType && company?.CompanyType?.Description.toLowerCase() != 'agent'">
      <app-dashboard-company-info [Company]="company" [column]="3" [row]="2"></app-dashboard-company-info>
      <app-dashboard-articles [column]="1" [row]="5"></app-dashboard-articles>
      <app-dashboard-company-documents [column]="1" [row]="2"></app-dashboard-company-documents>
    </ng-container>
    <ng-container *ngIf="company && company?.CompanyType && company?.CompanyType?.Description.toLowerCase() == 'agent'">

    </ng-container>
  </div>
</div>
