import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert, AlertType } from './alert.model';
import { AlertService } from '../../../services/alert.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id: string;

  alerts: Alert[] = [];
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.subject.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(alert => {
        if (!alert.message) {
          // clear alerts when an empty alert is received
          this.alerts = [];
          return;
        }

        // add alert to array
        this.alerts.push(alert);
        this.delay().then(() => {
          this.removeAlert(alert);
        });
      });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  removeAlert(alert: Alert) {
    // Remove specified alert from array
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-success';
      case AlertType.Error:
        return 'alert alert-danger';
      case AlertType.Info:
        return 'alert alert-info';
      case AlertType.Warning:
        return 'alert alert-warning';
    }
  }

  delay = () => {
    return new Promise( resolve => setTimeout(resolve, 5000) );
  }
}
