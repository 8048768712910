import { Component, OnInit } from '@angular/core';
import { TenderService } from '../../../../services/tender.service';
import { ITenderModality } from '../../../../services/models/pricing/tender.model';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { PricingService } from '../../../../services/pricing.service';
import { IModalityType, IUnitType } from '../../../../services/models/pricing/rates.model';

@Component({
  selector: 'app-step-modality',
  templateUrl: './step-modality.component.html',
  styleUrls: ['./step-modality.component.scss']
})
export class StepModalityComponent implements OnInit {
  // Icons
  faBin = faTrashAlt;
  faInfo = faCircleQuestion;
  faAdd = faPlus;

  // Component variables
  newMode: ITenderModality;
  selectedModality: IModalityType = null;
  selectedUnitType: IUnitType = null;
  unitTypes: IUnitType[] = [];

  // Lookup data
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();

  constructor(public tenderService: TenderService,
              public pricingService: PricingService) { }

  ngOnInit() {
    this.resetNew();
  }

  getModalityList(): ITenderModality[] {
    return this.tenderService.TenderView.TenderModalities.filter(x => x.Active == true);
  }

  removeRecord(tenderModality: ITenderModality) {
    tenderModality.Active = false;
  }

  setMode() {
    this.newMode.ModalityTypeId = this.selectedModality.Id;
    this.newMode.ModalityType = this.selectedModality;
    // Set UnitType options
    const unitTypeFilter = this.pricingService.ModalityUnitTypes$.getValue().filter((m) => m.ModalityTypeID === this.newMode.ModalityTypeId).map(s => s.UnitTypeID_1);
    if (unitTypeFilter && unitTypeFilter.length > 0) {
      this.unitTypes = this.pricingService.UnitTypes$.getValue().filter(x => unitTypeFilter.includes(x.Id)).sort((a,b) => a.Name < b.Name ? -1 : 1);
    } else {
      this.unitTypes = [];
    }
  }

  setQuantityUnitType() {
    this.newMode.UnitTypeId = this.selectedUnitType.Id;
    this.newMode.UnitType = this.selectedUnitType;
  }

  canAddMode() {
    return !(this.newMode &&
      this.newMode.ModalityTypeId !== null &&
      this.newMode.UnitTypeId !== null &&
      this.newMode.Quantity !== null);
  }

  addMode() {
    // Check if TenderModalities variable has been initialised, otherwise init the variable
    if (!this.tenderService.TenderView.TenderModalities) {
      this.tenderService.TenderView.TenderModalities = [];
    }

    // Add the record to the official list
    this.tenderService.TenderView.TenderModalities.push(this.newMode);
    // this.tenderService.MarkTenderModalityForUpdate(this.newMode);

    // Sort the list by Modality then Quantity
    const tm = this.tenderService.TenderView.TenderModalities;
    this.tenderService.TenderView.TenderModalities = tm.sort((a: ITenderModality, b: ITenderModality) => {
      if (a.ModalityTypeId > b.ModalityTypeId) {
        return 1;
      } else if (a.ModalityTypeId < b.ModalityTypeId) {
        return -1;
      } else {
        if (a.Quantity > b.Quantity) {
          return 1;
        } else if (a.Quantity < b.Quantity) {
          return -1;
        } else {
          return 0;
        }
      }
    });

    this.resetNew();
  }

  resetNew() {
    // Reset newMode object so that another one can be added
    this.newMode = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      TenderId: this.tenderService.TenderView.Id,
      ModalityTypeId: null,
      Quantity: null,
      UnitTypeId: null,
      MarginPercentage: this.newMode?.MarginPercentage ?? this.tenderService.TenderView.DefaultMarginPercentage,
      MarginMinimum: this.newMode?.MarginMinimum ?? this.tenderService.TenderView.DefaultMarginMinimum,
      MarginMaximum: this.newMode?.MarginMaximum ?? this.tenderService.TenderView.DefaultMarginMaximum,
      ModalityType: null,
      UnitType: null
    } as ITenderModality;

    this.selectedModality = null;
    this.selectedUnitType = null;
  }
}
