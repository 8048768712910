import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IRiskAssessmentDto } from "../../../../services/models/hse/hse.model";
import { HseService } from "../../../../services/hse.service";
import { AlertService } from "../../../../services/alert.service";

@Component({
  selector: 'app-dashboard-card-health-safety',
  templateUrl: './dashboard-card-health-safety.component.html',
  styleUrls: ['./dashboard-card-health-safety.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardHealthSafetyComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Component variables
  healthAndSafetyAssessment: IRiskAssessmentDto;

  constructor(public router: Router,
              private hseService: HseService,
              private alertService: AlertService,
              public authService: AdAuthService) { }

  ngOnInit() {
    this.getHealthAndSafetyAssessmentReport()
  }

  viewAssessment(hsAssessmentId: number) {
    this.router.navigate(['hs-risk-assessment/' + hsAssessmentId]).then(() => {});
  }

  getHealthAndSafetyAssessmentReport() {
    this.hseService.GetRiskAssessmentReportDashboard()
      .subscribe({
        next: (data) => {
          if (data) {
            this.healthAndSafetyAssessment = Object.assign({}, data);
          }
        },
        error: () => {
          this.alertService.error('Failed to get the Health & Safety Assessment Report');
        }
      });
  }
}
