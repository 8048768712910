import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  IRateGroup,
  IRateGroupSampleParam,
  IRateGroupSampleResult
} from '../../../../services/models/pricing/rates.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';
import { ITenderCurrency } from '../../../../services/models/pricing/tender.model';

@Component({
  selector: 'app-test-rate-group',
  templateUrl: './test-rate-group.component.html',
  styleUrls: ['./test-rate-group.component.scss']
})
export class TestRateGroupComponent implements OnInit, OnChanges {
  @Input() RateGroup: IRateGroup = null;

  // Component variables
  testParam: IRateGroupSampleParam;
  selectedCurrency: ITenderCurrency = null;
  testResult: IRateGroupSampleResult = {
    Rate: 0,
    Comment: null
  };

  // Lookup data
  Currencies$ = this.pricingService.Currencies$.asObservable();
  UnitTypes$ = this.pricingService.UnitTypes$.asObservable();

  constructor(public pricingService: PricingService,
              private alertService: AlertService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.RateGroup && changes.RateGroup.currentValue) {
      this.updateTestParam();
    }
  }

  updateTestParam() {
    this.testParam = {
      RateGroupID: this.RateGroup.ID,
      RateUnitTypeID_1: null,
      RateUnitTypeID_2: null,
      Quantity_1: null,
      Quantity_2: null,
      CurrencyID: null,
      OriginLocationID: this.RateGroup.OriginLocationID,
      OriginLocationTypeID: this.RateGroup.OriginLocationTypeID,
      DestinationLocationID: this.RateGroup.DestinationLocationID,
      DestinationLocationTypeID: this.RateGroup.DestinationLocationTypeID
    } as IRateGroupSampleParam;

    this.selectedCurrency = null;

    this.testResult = {
      Rate: 0,
      Comment: null
    };
  }

  setCurrency() {
    if (this.selectedCurrency) {
      this.testParam.CurrencyID = this.selectedCurrency.CurrencyId;
    }
  }

  canRunTest() {
    return !!(this.testParam.CurrencyID && this.testParam.Quantity_1 > 0 && this.testParam.RateUnitTypeID_1 > 0 && this.testParam.RateGroupID > 0);
  }

  runTest() {
    this.testParam.RateGroupID = this.RateGroup.ID;

    this.pricingService.GetRateGroupSample(this.testParam)
      .subscribe({
        next: (data) => {
          if (data) {
            this.testResult = data;
          }
        },
        error: () => {
          this.alertService.warn('Unable to generate a sample rate for this Rate Group.');
        }
      });
  }
}
