import { Component } from '@angular/core';
import { IHubClientValue, IHubCreateMapping } from '../../../../services/models/hub/hub-values.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { IHubClientSystemOverview } from '../../../../services/models/hub/hub.model';
import { IHubElement, IHubLinkSchemaElement } from '../../../../services/models/hub/schema.model';
import { environment } from '../../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HubAdminService } from '../../../../services/hub-admin.service';
import { AlertService } from '../../../../services/alert.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-create-value-mapping-modal',
  templateUrl: './create-value-mapping-modal.component.html',
  styleUrls: ['./create-value-mapping-modal.component.scss']
})
export class CreateValueMappingModalComponent {
  // Icons
  faDirection = faArrowRight;

  // Variables
  newMapping: IHubCreateMapping;
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClientFlow: IHubClientSystemOverview = null;
  sourceElements$: BehaviorSubject<IHubElement[]> = new BehaviorSubject<IHubElement[]>([]);
  targetElements$: BehaviorSubject<IHubLinkSchemaElement[]> = new BehaviorSubject<IHubLinkSchemaElement[]>([]);
  sourceValues$: BehaviorSubject<IHubClientValue[]> = new BehaviorSubject<IHubClientValue[]>([]);
  selectedSourceValue: IHubClientValue = null;
  targetValues$: BehaviorSubject<IHubClientValue[]> = new BehaviorSubject<IHubClientValue[]>([]);
  canViewAll: boolean = false;
  ucid: number = null;
  newSourceValue: boolean = false;
  invalid: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  env = environment;

  constructor(public activeModal: NgbActiveModal,
              private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.newMapping = {
      SourceClientId: null,
      SourceElementId: null,
      SourceValue: null,
      TargetClientId: null,
      TargetElementId: null,
      TargetValue: null
    } as IHubCreateMapping;

    this.ucid = this.auth.CurrentUser.User.CompanyId;
    this.setPermissions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);

    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAll) {
        this.ucid = null;
        this.userClients$.next(data);
      } else {
        const clients = data.filter(x => x.SourceCompanyId == this.ucid);
        this.userClients$.next(clients);
        if (clients.length >= 1) {
          this.selectedClientFlow = clients[0];
          this.setClientData();
        }
      }
    });
  }

  setClientData() {
    this.reset();
    if (this.selectedClientFlow) {
      this.newMapping.SourceClientId = this.selectedClientFlow.SourceClientId;
      this.loadSourceElements();
      this.newMapping.TargetClientId = this.selectedClientFlow.TargetClientId;
    }
  }

  // Source
  setSourceElement() {
    this.loadTargetElements();
    if (!this.newSourceValue) {
      this.loadSourceValues();
    }
  }

  loadSourceElements() {
    this.hubAdmin.GetMappedSystemElements(this.selectedClientFlow.SourceSystemId).subscribe(
      {
        next: (result) => {
          this.sourceElements$.next(result);
        },
        error: () => {
          this.sourceElements$.next([]);
        }
      }
    )
  }

  toggleNewSourceValue(event: any) {
    this.newSourceValue = event.target.checked;
    if (event.target.checked == true) {
      this.newMapping.SourceValue = null;
    } else {
      this.newMapping.SourceValue = null;
      this.loadSourceValues();
    }
  }

  loadSourceValues() {
    this.hubAdmin.ClientElementValues(this.selectedClientFlow.SourceSystemId, this.selectedClientFlow.SourceClientId, this.newMapping.SourceElementId).subscribe(
      {
        next: (result: IHubClientValue[]) => {
          this.sourceValues$.next(result);
        },
        error: () => {
          this.sourceValues$.next([]);
        }
      }
    )
  }

  setSourceValue() {
    this.newMapping.SourceValue = this.selectedSourceValue?.Value;
  }

  // Target
  loadTargetElements() {
    this.hubAdmin.GetTargetElements(this.newMapping.SourceElementId).subscribe(
      {
        next: (result) => {
          this.targetElements$.next(result);
        },
        error: () => {
          this.targetElements$.next([]);
        }
      }
    )
  }

  loadTargetValues() {
    this.hubAdmin.ClientElementValues(this.selectedClientFlow.TargetSystemId, this.selectedClientFlow.TargetClientId, this.newMapping.TargetElementId).subscribe(
      {
        next: (result: IHubClientValue[]) => {
          this.targetValues$.next(result);
        },
        error: () => {
          this.targetValues$.next([]);
        }
      }
    )
  }

  reset() {
    this.newMapping = {
      SourceClientId: null,
      SourceElementId: null,
      SourceValue: null,
      TargetClientId: null,
      TargetElementId: null,
      TargetValue: null
    } as IHubCreateMapping;

    this.selectedSourceValue = null;
    this.sourceElements$.next([]);
    this.targetElements$.next([]);
    this.sourceValues$.next([]);
    this.targetValues$.next([]);
  }

  canSave() {
    return this.newMapping.SourceClientId != null &&
      this.newMapping.SourceElementId != null &&
      (this.newMapping.SourceValue != null && this.newMapping.SourceValue.length > 0) &&
      this.newMapping.TargetClientId != null &&
      this.newMapping.TargetElementId != null &&
      (this.newMapping.TargetValue != null && this.newMapping.TargetValue.length > 0);
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.invalid = false;

      this.hubAdmin.CreateMapping(this.newMapping).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: string) => {
          this.alertService.success(data);
          this.activeModal.close(true);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error.Message);
        }
      });
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
