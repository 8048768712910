import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Component variables

  // General variables

  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit() { }
}
