import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import {
  IArticleAttachment,
  IArticleReviewResponse
} from '../../../../../services/models/news/article.model';
import { ArticleService } from '../../../../../services/article.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  faCalendar,
  faCalendarXmark,
  faCheckCircle,
  faCircleXmark,
  faPaperclip
} from '@fortawesome/free-solid-svg-icons';
import { diffLines, diffWords } from 'diff';
import {
  ArticlePreviewModalComponent
} from '../../article-review/article-preview-modal/article-preview-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-article-review-comparison',
  templateUrl: './article-review-comparison.component.html',
  styleUrls: ['./article-review-comparison.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ArticleReviewComparisonComponent implements OnInit {
  @Input('ArticleReview')
  set ArticleReview(articleReview: IArticleReviewResponse) {
    if (articleReview) {
      this.articleReview = articleReview;
      this.initReview();
    } else {
      this.articleReview = null;
    }
  }

  // Icons
  protected readonly faCheck = faCheckCircle;
  protected readonly faCancel = faCircleXmark;
  protected readonly faDate = faCalendar;
  protected readonly faExpire = faCalendarXmark;
  protected readonly faAttachment = faPaperclip;

  // Component variables
  articleReview: IArticleReviewResponse;
  imageBaseUrl: string = '';
  placeholderImage: string = '';
  diffBody: string = '';
  diffTitle: SafeHtml = '';
  diffCategories: SafeHtml = '';
  attachments: IArticleAttachment[] = [];

  protected readonly env = environment;

  constructor(private articleService: ArticleService,
              private sanitizer: DomSanitizer,
              private modalService: NgbModal) {}

  ngOnInit(): void {
    this.imageBaseUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/`;
    this.placeholderImage = `${environment.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;
  }

  initReview() {
    this.computeDifference();
    this.computeCategoryDiff();
    this.getAttachments();
  }

  computeDifference() {
    // Compare titles
    const title = diffWords(this.articleReview.Article.Title, this.articleReview.Review.Title);
    this.diffTitle = this.sanitizeArticleContent(this.formatDiff(title));
    // Compare article content
    const body = diffLines(this.articleReview.Article.HtmlContent, this.articleReview.Review.HtmlContent);
    this.diffBody = this.formatDiff(body);
  }

  computeCategoryDiff(): void {
    let diffOutput = '';

    const originalCategories = this.articleReview.Article.Categories.map(x => x.Name);
    const reviewCategories = this.articleReview.Review.Categories.map(x => x.Name);
    const allCategories = this.merge([...originalCategories], [...reviewCategories]);

    for (let i = 0; i < allCategories.length; i++) {
      const originalHasCategory = this.articleReview.Article.Categories.find(x => x.Name == allCategories[i]);
      const reviewHasCategory = this.articleReview.Review.Categories.find(x => x.Name == allCategories[i]);

      let style = 'category-no-change';

      if (originalHasCategory && !reviewHasCategory) {
        style = 'category-delete';
      } else if (!originalHasCategory && reviewHasCategory) {
        style = 'category-insert';
      }
      diffOutput +=  `<label class="${style}">${allCategories[i]}</label>`;
    }

    this.diffCategories = diffOutput;
  }

  merge = (a: string[], b: any[], predicate = (a: any, b: any) => a === b) => {
    const c = [...a];
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
  }

  formatDiff(diff: any[]): string {
    return diff.map(part => {
      const color = part.added ? 'diff-insert' : part.removed ? 'diff-delete' : '';
      return `<span class="${color}">${part.value}</span>`;
    }).join('');
  }

  sanitizeArticleContent(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getAttachments() {
    this.articleService.getAttachments(this.articleReview.Article.ArticleId)
      .subscribe({
        next: (data) => {
          this.attachments = [...data];
        }
      });
  }

  preview() {
    const a = this.articleReview.Article;
    const r = this.articleReview.Review;

    const modalRef = this.modalService.open(ArticlePreviewModalComponent, { size: 'xl', windowClass: 'modal-2xl', backdrop: 'static' });
    modalRef.componentInstance.ImageUrl = this.imageBaseUrl + a.ArticleId + '/'+ a.ImageReference;
    modalRef.componentInstance.Title = r.Title;
    modalRef.componentInstance.PublishDate = r.PublishDate;
    modalRef.componentInstance.Author = a.Author;
    modalRef.componentInstance.HtmlContent = r.HtmlContent;
    modalRef.componentInstance.Attachments = this.attachments;
  }
}
