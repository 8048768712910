import { ConfirmationModalComponent } from './confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export function OpenConfirmationModal(modalService: NgbModal, message: string = null, confirmButton: string = null, cancelButton: string = 'Cancel') {
  const modalRef = modalService.open(ConfirmationModalComponent, {backdrop: 'static', keyboard: false, centered: true});
  modalRef.componentInstance.Message = message;
  if (confirmButton) {
    modalRef.componentInstance.ConfirmButton = confirmButton;
  }
  modalRef.componentInstance.CancelButton = cancelButton;
  return modalRef.componentInstance['Answer'];
}
