import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { Subject } from 'rxjs';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { IHubValue } from '../../../../services/models/hub/hub-values.model';
import { HubAdminService } from '../../../../services/hub-admin.service';
import { IUser } from '../../../../services/models/user.model';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-edit-value-list-modal',
  templateUrl: './edit-value-list-modal.component.html',
  styleUrls: ['./edit-value-list-modal.component.scss']
})
export class EditValueListModalComponent implements OnDestroy {
  // Icons

  // Variables
  public ValueListId: number;
  public ValueListItem: IHubValue;
  createdBy: IUser = null;
  modifiedBy: IUser = null;
  newValue: string = null;
  invalid: boolean = false;

  // General variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private hubAdmin: HubAdminService,
              private alertService: AlertService,
              private auth: AdAuthService,
              private modalService: NgbModal) {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public LoadValueListItem() {
    this.loading = true;
    this.hubAdmin.GetValueItem(this.ValueListId).subscribe({
      next: (data: IHubValue) => {
        if (data) {
          this.ValueListItem = Object.assign({} as IHubValue, data);
          this.loadCreatedByUser();
          this.loadModifiedByUser();
        }
        this.loading = false;
      }, error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  loadCreatedByUser() {
    if (this.ValueListItem?.CreatedById > 0) {
      this.auth.GetUserById(this.ValueListItem.CreatedById).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IUser) => {
          this.createdBy = Object.assign({}, data);
        },
        error: () => {
          this.createdBy = null;
        }
      })
    }
  }

  loadModifiedByUser() {
    if (this.ValueListItem?.ModifiedById > 0) {
      this.auth.GetUserById(this.ValueListItem.ModifiedById).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IUser) => {
          this.modifiedBy = Object.assign({}, data);
        },
        error: () => {
          this.createdBy = null;
        }
      })
    }
  }

  updateValue() {
    if (this.newValue && this.newValue.length > 0) {
      this.invalid = false;
      const message = 'Updating the value list record will update it for all mappings using this value list record. Are you sure you want to continue?';
      OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: IHubValue) => {
        if (response) {
          this.loading = true;
          this.hubAdmin.UpdateValueList(this.ValueListId, this.newValue)
            .subscribe({
              next: () => {
                this.alertService.success('Value has been successfully updated.');
                this.activeModal.close(true);
                this.loading = false;
              }, error: (error) => {
                this.alertService.error(error);
                this.loading = false;
              }
          });
        }
      });
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
