<div class="modal-body">
  <div class="row mt-2 mb-2">
    <div class="col-12 text-start">
      <b>Uploaded documents required: {{ documents.length }}/{{ requiredDocumentAmount }}</b>
    </div>
  </div>
  <hr>
  <div class="row p-2">
    <div class="col-8">
      <b>File Name</b>
    </div>
    <div class="col-4 text-end">
      <b></b>
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <div class="row mt-2 p-2" *ngFor="let doc of documents">
    <div class="col-8">
      <p>{{ doc.Name }}</p>
    </div>
    <div class="col-4 text-end">
      <span [ngbTooltip]="'Remove document'"
            class="btn btn-outline-danger"
            (click)="delete(doc)">
        <fa-icon [icon]="faDelete"></fa-icon>
      </span>
      <button class="btn btn-outline-success harmony-purple-outline-button pointer ms-2"
              [ngbTooltip]="'Download Document'"
              (click)="downloadDocument(doc)">
        <fa-icon [icon]="faDownload"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary harmony-purple-button"
          type="button"
          (click)="FileHandler.click()">
    + Upload
  </button>
  <input hidden
         #FileHandler
         type="file"
         id="file"
         multiple
         (change)="setFiles($event)"/>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="close()">
    Close
  </button>
</div>
