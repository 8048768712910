import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCopy, faExternalLinkAlt, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../../../../services/alert.service';
import { AzureService } from '../../../../services/azure.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { environment } from '../../../../../environments/environment';
import { IHubAzureLog, IHubClientSystemOverview } from '../../../../services/models/hub/hub.model';
import { IHubSourceMessage, IHubSourceMessageOverview } from '../../../../services/models/hub/hub-messages.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { HubRoutes } from '../../../../core/constants/routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-debug-log-json-modal',
  templateUrl: './view-message-modal.component.html',
  styleUrls: ['./view-message-modal.component.scss']
})
export class ViewMessageModalComponent implements OnInit, OnDestroy {
  // Icons
  faExternal = faExternalLinkAlt;
  faCopy = faCopy;
  faMessage = faFileImport;

  // Variables
  public SourceMessageId: number;
  public UserClients: IHubClientSystemOverview[];
  message: IHubSourceMessage;
  messageLogs: IHubAzureLog[];
  resubmitSuccess: boolean = false;
  resubmitFailed: boolean = false;
  messageObject: string;

  // Permissions
  canViewAll: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  env = environment;

  constructor(public activeModal: NgbActiveModal,
              private azureService: AzureService,
              private alertService: AlertService,
              private clipboard: Clipboard,
              private auth: AdAuthService,
              private router: Router,
              private api: ApiService) { }

  ngOnInit(): void {
    if (this.SourceMessageId) {
      this.loadMessage();
      this.loadLogs();
      this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadMessage() {
    this.api.get(`Hub/Message/SourceMessage/${this.SourceMessageId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IHubSourceMessage) => {
        if (data) {
          this.message = Object.assign({}, data);
          this.validateUser();
          this.setMessageData();
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.warn('Unable to retrieve the source message for ID: ' + this.SourceMessageId);
        this.loading = false;
      }
    });
  }

  validateUser() {
    if (!this.canViewAll) {
      const canView = this.UserClients.find(x => x.SourceClientId == this.message.SourceClientId && x.TargetClientId == this.message.TargetClientId);
      if (!canView) {
        this.alertService.warn('You do not have access to view this message.');
        this.returnToOverview();
      }
    }
  }

  returnToOverview() {
    this.router.navigate([`${HubRoutes.Message_Log}`]).then(() => { });
  }

  setMessageData() {
    switch (this.message.MessageType) {
      case 1:
        this.messageObject = this.message.MessageXml;
        break;
      case 2:
        this.messageObject = JSON.parse(this.message.MessageJson);
        break;
      default:
        this.messageObject = JSON.parse(this.message.MessageJson);
        break;
    }
  }

  copyMessageToClipboard() {
    this.clipboard.copy(JSON.stringify(this.messageObject));
  }

  loadLogs() {
    this.loading = true;

    this.api.get(`Hub/AzureLog/Reference/${this.SourceMessageId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IHubAzureLog[]) => {
        if (data) {
          this.messageLogs = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.warn('Unable to retrieve logs for this SrcMessageID: ' + this.SourceMessageId);
        this.loading = false;
      }
    });
  }

  goToLogicAppRun(log: IHubAzureLog) {
    const subKey = environment.azureSubscriptionKey;
    const baseUri = 'https://portal.azure.com/#blade/Microsoft_Azure_EMA/LogicAppsMonitorBlade/runid/';
    const directUri = `/subscriptions/${subKey}/resourceGroups/${log.ResourceGroupName}/providers/Microsoft.Logic/workflows/${log.ResourceName}/runs/${log.RunId}`;

    const encodedUri = encodeURIComponent(directUri);
    const completeUri = baseUri + encodedUri;

    // Open link in new tab
    window.open(completeUri, '_blank');
  }

  resubmitMessage(srcMessage: IHubSourceMessageOverview) {
    // Create parameter
    const param = {
      srcMessageID: srcMessage.Id.toString(),
      messageType: srcMessage.MessageType.toString(),
      sourceClientID: srcMessage.SourceClientId.toString(),
      sourceSchemaTypeID: srcMessage.SourceSchemaTypeId.toString(),
      targetClientID: srcMessage.TargetClientId.toString(),
      targetSchemaTypeID: srcMessage.TargetSchemaTypeId.toString()
    };

    this.azureService.resubmitToTargetDecision(param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next:(data: any) => {
        if (data) {
          this.alertService.success('Message has been successfully resubmitted. Response: ' + data.response);
        }
        this.resubmitSuccess = true;
      },
      error: (error: any) => {
        this.alertService.error('Message failed to resubmit. Response: ' + error.response);
        this.resubmitFailed = true;
      }
    });
  }

  close() {
    this.activeModal.close();
  }
}
