import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { ICompany, ICompanyDetail } from '../../../../services/models/member.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-environmental-assessment-information-modal',
  templateUrl: './environmental-assessment-information-modal.component.html',
  styleUrls: ['./environmental-assessment-information-modal.component.scss']
})
export class EnvironmentalAssessmentInformationModalComponent implements AfterContentChecked {

  companyId: number;
  hasAccess: boolean = false;
  company: ICompany;
  updateCompanyDetails: ICompanyDetail[] = [];

  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private cdr: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  update() {
    if (this.updateCompanyDetails?.length > 0) {
      this.companyService.UpsertCompanyDetailList(this.updateCompanyDetails).subscribe({
        next: () => {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
