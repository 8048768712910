import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsMenuComponent } from './news-menu/news-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NewsRoutingModule } from './news-routing.module';
import { AdminModule } from '../admin/admin.module';
import { SharedModule } from '../../shared/shared.module';
import { ArticleEditorComponent } from './article-management/article-editor/article-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ArticleManagementComponent } from './article-management/article-management.component';
import { PublishModalComponent } from './article-management/article-editor/publish-modal/publish-modal.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ArticleAttachmentModalComponent } from './article-management/article-editor/article-attachment-modal/article-attachment-modal.component';
import { ArticleReviewComponent } from './article-management/article-review/article-review.component';
import { ArticleReadComponent } from './article-read/article-read.component';
import { NetworkArticlesComponent } from './network-articles/network-articles.component';
import { ArticlePreviewModalComponent } from './article-management/article-review/article-preview-modal/article-preview-modal.component';
import { ArticleReviewComparisonComponent } from './article-management/article-change-review/article-review-comparison/article-review-comparison.component';
import { ArticleChangeReviewComponent } from './article-management/article-change-review/article-change-review.component';
import {
  ArticleReviewCommentModalComponent
} from './article-management/article-review/article-review-comment-modal/article-review-comment-modal.component';
import { ArticleComponent } from './article-read/article/article.component';

@NgModule({
  declarations: [
    NewsMenuComponent,
    ArticleEditorComponent,
    ArticleManagementComponent,
    PublishModalComponent,
    ArticleAttachmentModalComponent,
    ArticleReviewComponent,
    ArticleReadComponent,
    ArticleComponent,
    NetworkArticlesComponent,
    ArticlePreviewModalComponent,
    ArticleReviewComparisonComponent,
    ArticleChangeReviewComponent,
    ArticleReviewCommentModalComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NewsRoutingModule,
    AdminModule,
    SharedModule,
    EditorModule,
    NgSelectModule
  ],
  exports: [
    NewsMenuComponent
  ],
  providers: []
})
export class NewsModule {
}
