<!---- Header ---->
<div class="modal-header modal-title">
 Submit Article
</div>
<!---- Edit ---->
<div class="modal-body" *ngIf="article">
  <div class="row mb-1">
    <div class="col-4">
      <b>Publish Date</b>
      <span>
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info pointer ms-1"
               [title]="'Preferred date for when the article should be visible to other members'">
      </fa-icon>
    </span>
    </div>
    <div class="col-8">
      <app-date-picker [identifier]="'publishDate'"
                       [showError]="!isValid && !article.PublishDate"
                       [value]="article.PublishDate"
                       (onChange)="article.PublishDate = $event"
                       [title]="'Select a publish date'"></app-date-picker>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4">
      <b>Expire Date</b>
      <span>
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info pointer ms-1"
               [title]="'Optional date for when the article should expire and no longer be visible to other members'">
      </fa-icon>
    </span>
    </div>
    <div class="col-8">
      <app-date-picker [identifier]="'expireDate'"
                       [value]="article.ExpireDate"
                       (onChange)="article.ExpireDate = $event"
                       [title]="'Select an expire date'"></app-date-picker>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4">
      <b>Time Zone</b>
      <span>
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info pointer ms-1"
               [title]="'The time zone of when the article will display and expire'">
      </fa-icon>
    </span>
    </div>
    <div class="col-8">
      <small>(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</small>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="submit()">Submit for Approval</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
