<ng-container *ngIf="companyDetailDefinitions?.length <= 0">
  <app-loader class="mt-2" [Width]="30"></app-loader>
</ng-container>
<ng-container *ngIf="companyDetailDefinitions.length > 0">
  <div *ngFor="let definition of companyDetailDefinitions; let i = index">
    <div class="row mb-1" *ngIf="definition.IsEditable">
      <div class="col-5 fw-bold">
        {{ definition.DisplayName ?? definition.PropertyName }}
        <span *ngIf="showInfo">
           <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info pointer"
                    [title]="definition.Description"></fa-icon>
        </span>
      </div>
      <div class="col-7" *ngIf="companyDetails && orderedCompanyDetails.length > 0">
        <!--Text Input-->
        <input type="text" class="form-control form-control-sm"
               [attr.id]="'text-' + i"
               [attr.name]="'text-input-' + i"
               *ngIf="definition.DataTypeId == 1"
               [disabled]="!definition.IsEditable || viewOnly"
               [(ngModel)]="orderedCompanyDetails[i].PropertyValue"
               (ngModelChange)="updateValue(i, $event)">
        <!--Number Input-->
        <input type="number" class="form-control form-control-sm"
               [attr.id]="'number-' + i"
               [attr.name]="'number-input-' + i"
               *ngIf="definition.DataTypeId == 2"
               [disabled]="!definition.IsEditable || viewOnly"
               [numbersOnly]="definition.Format ?? '10'"
               [(ngModel)]="orderedCompanyDetails[i].PropertyValue"
               (ngModelChange)="updateValue(i, $event)">
        <!--Boolean Input-->
        <input type="text" class="form-control form-control-sm"
               [attr.id]="'bool-' + i"
               [attr.name]="'bool-input-' + i"
               *ngIf="definition.DataTypeId == 3"
               [disabled]="!definition.IsEditable || viewOnly"
               [(ngModel)]="orderedCompanyDetails[i].PropertyValue"
               (ngModelChange)="updateValue(i, $event)">
        <!--Date Input-->
        <input type="text" class="form-control form-control-sm"
               [attr.id]="'date-' + i"
               [attr.name]="'date-input-' + i"
               *ngIf="definition.DataTypeId == 4"
               [disabled]="!definition.IsEditable || viewOnly"
               [(ngModel)]="orderedCompanyDetails[i].PropertyValue"
               (ngModelChange)="updateValue(i, $event)">
        <!--Email Input-->
        <app-email-capture *ngIf="definition.DataTypeId == 5"
                           [EmailString]="orderedCompanyDetails[i].PropertyValue"
                           [Disabled]="!definition.IsEditable || viewOnly"
                           (OnResult)="updateValue(i, $event)"></app-email-capture>
        <!--Phone Number Input-->
        <app-mobile-number *ngIf="definition.DataTypeId == 6" [Disabled]="!definition.IsEditable || viewOnly"
                           [Number]="orderedCompanyDetails[i].PropertyValue"
                           (OnChange)="updateValue(i, $event)"></app-mobile-number>
      </div>
    </div>
  </div>
</ng-container>
