<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    File Upload
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<!-- Body -->
<div class="modal-body">
  <div class="row result-row my-0" style="margin-left: 0; margin-right: 0;">
    <div class="col-9 ps-0">
      <div class="d-flex">
        <input class="form-control form-control-sm" type="text"
               [ngClass]="{'is-invalid': !this.isValid()}"
               [placeholder]="'Enter a description for the file'"
               [(ngModel)]="document.Name">
      </div>
      <div>
        <small>
          {{ document?.FileName ?? '-' }}
        </small>
      </div>
    </div>
    <div class="col-3 text-end pe-0">
      <button class="btn btn-sm btn-primary harmony-purple-button float-end"
              type="button" [disabled]="false"
              (click)="FileHandler.click()">
        <fa-icon [icon]="faUpload"></fa-icon>
        Select file
      </button>
      <input hidden
             #FileHandler
             type="file"
             id="file"
             multiple
             (change)="selectFile($event)"/>
    </div>
  </div>
<!--  <div class="row">-->
<!--    <div class="col-5">-->
<!--      <b>Description</b>-->
<!--    </div>-->
<!--    <div class="col-5">-->
<!--      <b>File name</b>-->
<!--    </div>-->
<!--    <div class="col-2">-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-5">-->
<!--      <input class="form-control form-control-sm" type="text"-->
<!--             [ngClass]="{'is-invalid': !this.isValid()}"-->
<!--             [placeholder]="'Enter a name for the document'"-->
<!--             [(ngModel)]="document.Name">-->
<!--    </div>-->
<!--    <div class="col-5">-->
<!--      {{ document?.FileName ?? '-' }}-->
<!--    </div>-->
<!--    <div class="col-2 text-end">-->
<!--      <button class="btn btn-sm btn-primary harmony-purple-button float-end"-->
<!--              type="button" [disabled]="false"-->
<!--                (click)="FileHandler.click()">-->
<!--        <fa-icon [icon]="faUpload"></fa-icon>-->
<!--        Upload document-->
<!--      </button>-->
<!--      <input hidden-->
<!--             #FileHandler-->
<!--             type="file"-->
<!--             id="file"-->
<!--             multiple-->
<!--             (change)="selectFile($event)"/>-->
<!--    </div>-->
<!--  </div>-->
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="addDocument()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
