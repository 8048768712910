<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Manage users for {{ companyName }}
  </h1>
  <div>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-1"
            (click)="openNewUserModal()">
      + New User
    </button>
  </div>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mt-1">
      <div class="col-4">
        <input type="text"
               class="filter-item form-control form-control-sm"
               (change)="updateSearchText($event)"
               (keyup.enter)="getUsers()"
               placeholder="Filter users...">
      </div>
    </div>
    <hr class="mt-2 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPageChange($event)"
                        [name]="'MUManagement'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!--------------------->
    <!---- Result table --->
    <!--------------------->
    <div class="row mt-1 header-row">
      <div class="col-4 header-title col-compress border-split-left">
        <div class="row">
          <div class="col-10 col-compress offset-2 pointer"
               (appOrder)="orderSearch($event)"
               data-column="Username"
               data-order="asc">
            Username
            <fa-icon [hidden]="orderParam.OrderBy != 'Username'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
          </div>
        </div>
      </div>
      <div class="col-3 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="DisplayName"
           data-order="asc">
        Display Name
        <fa-icon [hidden]="orderParam.OrderBy != 'DisplayName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title"
           (appOrder)="orderSearch($event)"
           data-column="DateCreated"
           data-order="asc">
        Created
        <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-3 col-compress header-title">

      </div>
      <div class="col-1 header-title col-compress text-end border-split-right">

      </div>
    </div>
    <!--------------------->
    <!------ Results ------>
    <!--------------------->
    <ng-container *ngIf="paginationData">
      <div class="row result-row p-1"
           [ngClass]="{'selected-user': selectedUser && (selectedUser.UserId === user.Id) }"
           *ngFor="let user of paginationData.DataSet; let i = index; trackBy: identify">
        <div class="col-4 col-compress">
          <div class="row">
            <div class="col-2 overflow-hidden">
              <div class="profile-icon" *ngIf="token && user.ProfilePicture"
                   [ngStyle]="{'background-image': 'url(\'' + user.ProfilePicture + token + '\')' }">
              </div>
              <div class="profile-icon" *ngIf="!user.ProfilePicture"
                   [ngStyle]="{'background-image': 'url(\'' + defaultImage + '\')' }">
              </div>
            </div>
            <div class="col-10 col-compress">
              <span class="ms-0">{{ user.Username }}</span>
            </div>
          </div>
        </div>
        <div class="col-3 col-compress overflow-hidden">
          <b>{{ user.DisplayName ? user.DisplayName : '-' }} {{ user.Active ? '' : '(Inactive)' }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          <small>{{ user.DateCreated | date }}</small>
        </div>
        <div class="col-3 col-compress text-end overflow-hidden">
          <app-user-module-overview [Modules]="user.Modules"></app-user-module-overview>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <fa-icon class="azure-registered-icon me-2" [icon]="faRegistered" *ngIf="user.AzureRegistered"></fa-icon>
          <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                  (click)="openUserEditModal(user)" [title]="'Edit user'">
            <fa-icon [icon]="faEdit" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-danger"
                  *ngIf="user.Active === true"
                  (click)="toggleUserActive(user)">
            <fa-icon [icon]="faRemove" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-success"
                  *ngIf="user.Active === false"
                  (click)="toggleUserActive(user)">
            <fa-icon [icon]="faAdd" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!--------------------->
    <!------ Loader ------->
    <!--------------------->
    <div class="row mt-4" *ngIf="loading">
      <div class="col-12 text-center">
        <app-loader [Width]="50"></app-loader>
      </div>
    </div>
    <!--------------------->
    <!---- No Results ----->
    <!--------------------->
    <div class="row mt-4" *ngIf="!loading && (paginationData && paginationData.DataSet.length === 0)">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <h4>No results...</h4>
      </div>
    </div>
  </div>
</div>
