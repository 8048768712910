import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AnnualReviewDocumentModalComponent } from './audit/annual-review-audit-document-modal/annual-review-document-modal.component';
import { AnnualReviewMenuComponent } from './annual-review-menu/annual-review-menu.component';
import { AnnualReviewAdminComponent } from './anual-review-admin/annual-review-admin.component';
import { ViewReviewModalComponent } from './anual-review-admin/view-review-modal/view-review-modal.component';
import { ViewQuestionModalComponent } from './anual-review-admin/view-question-modal/view-question-modal.component';
import { ViewChapterModalComponent } from './anual-review-admin/view-chapter-modal/view-chapter-modal.component';
import { ViewAnswerModalComponent } from './company-review/view-answer-modal/view-answer-modal.component';
import { AnnualReviewRoutingModule } from './annual-review-routing.module';
import { CompanyReviewComponent } from './company-review/company-review.component';
import { ViewAuditQuestionModalComponent } from './anual-review-admin/view-audit-question-modal/view-audit-question-modal.component';
import { AuditComponent } from './audit/audit.component';

@NgModule({
    declarations: [
        AnnualReviewDocumentModalComponent,
        AnnualReviewMenuComponent,
        AnnualReviewAdminComponent,
        ViewReviewModalComponent,
        ViewQuestionModalComponent,
        ViewChapterModalComponent,
        ViewAnswerModalComponent,
        CompanyReviewComponent,
        ViewAuditQuestionModalComponent,
        AuditComponent
    ],
    exports: [
        AnnualReviewMenuComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        CoreModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgbTooltipModule,
        NgbPopoverModule,
        NgbModule,
        AnnualReviewRoutingModule
    ]
})
export class AnnualReviewModule { }
