import { Component, OnInit } from '@angular/core';
import { SchedulerService } from '../../../services/scheduler.service';
import { IStoredProcJobParam } from '../../../services/models/hangfire/scheduler.model';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-job-scheduler',
  templateUrl: './job-scheduler.component.html',
  styleUrls: ['./job-scheduler.component.scss']
})
export class JobSchedulerComponent implements OnInit {
  // Component variables
  recurringJobParam: IStoredProcJobParam;

  // General variables
  loading: boolean = false;

  constructor(private scheduler: SchedulerService,
              private alertService: AlertService) { }

  ngOnInit(): void {
      this.setRecurringParam();
  }

  setRecurringParam() {
    this.recurringJobParam = {
      Name: null,
      DatabaseName: null,
      Parameters: {},
      Query: 'EXEC ',
      CronExpression: '0 0 6 * * ?'
    } as IStoredProcJobParam;
  }

  createJob() {
    this.loading = true;

    this.scheduler.CreateRecurringJob(this.recurringJobParam)
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          this.alertService.success('Recurring Job successfully created!');
        }, error: (error: any) => {
          this.loading = false;
          this.alertService.success('Error: ' + error.Message);
        }
      });
  }
}
