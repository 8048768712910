import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IQuestionOption,
  IQuestionRequest,
  IQuestion,
  IQuestionUpdateRequest
} from '../../../../services/models/annual-review/annual-review-question.model';
import { IAnnualReviewSearchFilterResponse } from '../../../../services/models/annual-review/annual-review.model';
import { IChapterResponse } from '../../../../services/models/annual-review/annual-review-chapter.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ViewChapterModalComponent } from '../view-chapter-modal/view-chapter-modal.component';
import { faEdit, faAdd, faTrash, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../services/alert.service';
import { AnnualReviewService } from '../../../../services/annual-review.service';

@Component({
  selector: 'app-view-question-modal',
  templateUrl: './view-question-modal.component.html',
  styleUrls: ['./view-question-modal.component.scss']
})
export class ViewQuestionModalComponent implements OnInit, OnDestroy {

  // Icons
  faEdit = faEdit;
  faAdd = faAdd;
  faDelete = faTrash;
  faSave = faFloppyDisk;

  // Variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public question: IQuestion = {} as IQuestion;
  public reviewId: number;
  public chapterId: number;
  public isNew: false;
  public reviewSearchFilter: IAnnualReviewSearchFilterResponse[];
  public chapters: IChapterResponse[];
  public chapter: IChapterResponse;
  public questionForm: UntypedFormGroup;
  public options = [] as IQuestionOption[];
  isValid = true;
  isAdded = false;

  constructor(private activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private formBuilder: UntypedFormBuilder,
              private annualReviewService: AnnualReviewService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {

  }

  init() {
    this.createForm();

    if (!this.isNew) {
      this.fillForm(this.question);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // Form Functions
  createForm() {
    this.questionForm = this.formBuilder.group({
      value: new UntypedFormControl(null, Validators.required),
      label: new UntypedFormControl(null, Validators.required),
      mandatory: new UntypedFormControl(false),
      type: new UntypedFormControl(null, Validators.required),
      companyType: new UntypedFormControl(null, Validators.required),
      order: new UntypedFormControl(0, Validators.required),
      requirement: new UntypedFormControl(null),
      option: new UntypedFormControl(null),
      optionLabel: new UntypedFormControl(null),
      active: new UntypedFormControl(null),
      chapterId: new UntypedFormControl(this.chapterId, Validators.required),
      reviewId: new UntypedFormControl(this.reviewId, Validators.required),
    });

    this.getChapters();
  }

  fillForm(question: IQuestion) {
    this.questionForm.controls.reviewId.setValue(question.ReviewId);
    this.questionForm.controls.chapterId.setValue(question.ChapterId);
    this.getChapters();
    this.questionForm.controls.value.setValue(question.Value);
    this.questionForm.controls.label.setValue(question.Label);
    this.questionForm.controls.mandatory.setValue(question.Mandatory);
    this.questionForm.controls.type.setValue(question.Type);
    this.questionForm.controls.companyType.setValue(question.CompanyType);
    this.questionForm.controls.order.setValue(question.Order);
    this.questionForm.controls.requirement.setValue(question.Requirement);
    this.questionForm.controls.active.setValue(question.Active);
  }

  setQuestionType(event: any) {
    this.question.Type = event.target.value;
  }

  isFormValid() {
    this.questionForm.markAllAsTouched();
    this.questionForm.markAsTouched();
    this.isValid = this.questionForm.valid;
    return this.questionForm.valid;
  }

  // FOOTER FUNCTIONS
  add() {
    if (this.isFormValid()) {
      const question = {
        Value: this.questionForm.value.value,
        Label: this.questionForm.value.label,
        Mandatory: this.questionForm.value.mandatory,
        Type: this.questionForm.value.type,
        MemberType: this.questionForm.value.companyType,
        Order: this.questionForm.value.order,
        Requirement: this.questionForm.value.requirement,
        Options: this.options,
        ReviewId: this.questionForm.value.reviewId,
        ChapterId: this.questionForm.value.chapterId,
      } as IQuestionRequest;

      this.annualReviewService.addQuestion(question)
        .subscribe(() => {
            this.alertService.success('Question added successfully.');
            this.isAdded = true;
          },
          () => {
            this.alertService.error('Failed to add question');
          });
    }
  }

  update() {
    if (this.isFormValid()) {
      const question = {
        Value: this.questionForm.value.value,
        Label: this.questionForm.value.label,
        Mandatory: this.questionForm.value.mandatory,
        Type: this.questionForm.value.type,
        MemberType: this.questionForm.value.companyType,
        Order: this.questionForm.value.order,
        Requirement: this.questionForm.value.requirement,
        ReviewId: this.questionForm.value.reviewId,
        ChapterId: this.questionForm.value.chapterId,
        Active: this.questionForm.value.active,
        Id: this.question.Id,
        Options: this.options,
        ExistingOptions: this.question.Options
      } as IQuestionUpdateRequest;

      this.annualReviewService.updateQuestion(question)
        .subscribe(() => {
            this.alertService.success('Question successfully updated.');
          },
          () => {
            this.alertService.error('Failed to update question.');
          });
    }
  }

  // FUNCTIONS FOR NEW OPTIONS
  addOption(option: string, label: string) {
    const item = {
      Option: option,
      Label: label
    } as IQuestionOption;
    this.options.unshift(item);
    this.questionForm.controls.option.setValue(null);
    this.questionForm.controls.optionLabel.setValue(null);
  }

  updateOption(index: number, event: any) {
    this.options[index].Option = event.target.value;
  }

  updateOptionLabel(index: number, event: any) {
    this.options[index].Label = event.target.value;
  }

  deleteOption(optionIndex: number) {
    if (optionIndex > -1) {
      this.options.splice(optionIndex, 1);
    }
  }



  getChapters() {
    const reviewId = this.questionForm.value.reviewId;
    if (reviewId) {
      this.annualReviewService.getChapters(reviewId).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(data => {
          if (data) {
            this.chapters = Object.assign([], data);
          }
        });
    }
  }

  deleteExistingOption(optionId: number, index: number) {
    this.annualReviewService.deleteQuestionOption(optionId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.question.Options.splice(index, 1);
    });
  }

  openChapterModal(isNew: boolean) {
    if (isNew) {
      const modalRef = this.modalService.open(ViewChapterModalComponent, {size: 'xl', backdrop: 'static'});
      modalRef.componentInstance.isNew = isNew;
      modalRef.componentInstance.chapter = {} as IChapterResponse;
      modalRef.componentInstance.reviewSearchFilter = this.reviewSearchFilter;
    } else {
      this.annualReviewService.getChapter(this.question.ChapterId).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(data => {
          if (data) {
            this.chapter = Object.assign([], data);
            const modalRef = this.modalService.open(ViewChapterModalComponent, {size: 'xl', backdrop: 'static'});
            modalRef.componentInstance.isNew = isNew;
            modalRef.componentInstance.chapter = this.chapter;
            modalRef.componentInstance.reviewSearchFilter = this.reviewSearchFilter;
          }
        }, () => {
          this.alertService.warn('Could not load chapter.');
        });
    }
  }

  close() {
    this.activeModal.close(this.isAdded);
  }
}
