import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {
IRiskAssessmentDto
} from '../../../../services/models/hse/hse.model';

@Component({
  selector: 'app-risk-assessment-create-modal',
  templateUrl: './risk-assessment-create-modal.component.html',
  styleUrls: ['./risk-assessment-create-modal.component.scss']
})
export class RiskAssessmentCreateModalComponent implements OnInit, OnDestroy {

  // Icons

  // Component Variables
  riskAssessment: IRiskAssessmentDto;
  companyId: number;
  branchId: number = null;
  selectedYear: number;
  allowedYears: number[] = [];
  assessmentName: string;
  branch = null;
  loading: boolean = false;

  companies$ = this.companyService.ActiveMembers$.asObservable();
  admin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_CreateNew);


  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  ngOnInit() {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 1; year++) {
      this.allowedYears.push(year);
    }
  }

  ngOnDestroy(): void { }

  createRiskAssessment() {
    const openStatus = this.hseService.RiskAssessmentAnswerStatus.find(x => x.Description == 'Open');
    this.riskAssessment = {
      Name: this.assessmentName,
      Year: this.selectedYear,
      CompanyId: this.companyId,
      BranchId: this.branchId,
      Active: true,
      AssessmentStatusId: openStatus.Id,
      CreatedByUserId: this.authService.CurrentUser.UserId,
      LastModifiedByUserId: this.authService.CurrentUser.UserId
    } as IRiskAssessmentDto

    this.hseService.updateRiskAssessment(this.riskAssessment)
      .subscribe({
        next: () => {
          this.alertService.info('Risk Assessment successfully created.');
          this.close(true);
        },
        error: (error) => {
          this.alertService.error(error.Meta.Message);
        }
      });
  }

  close(shouldReload:boolean) {
    this.activeModal.close(shouldReload);
  }
}
