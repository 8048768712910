<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Manage Rate Groups <ng-container *ngIf="!isRateAdmin">for {{ authService.CurrentUser?.User?.Company?.Name }}</ng-container>
  </h1>
  <div class="btn-toolbar mb-2 mb-md-0" *ngIf="isRateAdmin">
    <div class="btn-group">
      <button type="button" class="btn btn-sm harmony-purple-outline-button" (click)="createNewRateGroup()">+ New Rate Group</button>
    </div>
  </div>
</div>
<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row mt-1">
  <div class="col-4">
    <div class="row">
      <div class="col-4 overflow-hidden">
        <b>Rate Group ID</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [placeholder]="'Enter RateGroup ID'"
               [(ngModel)]="searchParam.RateGroupID">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Project</b>
      </div>
      <div class="col-8">
        <ng-container *ngIf="isRateAdmin">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(projects$ | async)"
                  [(ngModel)]="searchParam.ProjectID"
                  (change)="searchParam.RateRequestTypeID = null">
            <option [ngValue]="null" selected>Select a project...</option>
            <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="!isRateAdmin">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(companyProjects$ | async)"
                  [(ngModel)]="searchParam.ProjectID">
            <option [ngValue]="null" selected>Select a project...</option>
            <option *ngFor="let project of (companyProjects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
          </select>
        </ng-container>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Request Type</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!searchParam.ProjectID || !(rateRequestTypes$ | async)"
                [(ngModel)]="searchParam.RateRequestTypeID">
          <option [ngValue]="null" selected>Select a request type...</option>
          <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                  [hidden]="rateRequestType.ProjectId != searchParam.ProjectID"
                  [ngValue]="rateRequestType.Id">
            {{ rateRequestType.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-1" *ngIf="isRateAdmin">
      <div class="col-4 overflow-hidden">
        <b>Request ID</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [placeholder]="'Enter RateRequest ID'"
               [(ngModel)]="searchParam.RateRequestID">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Modality</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(modalityTypes$ | async)"
                (change)="setPortType()"
                [(ngModel)]="searchParam.ModalityTypeID">
          <option [ngValue]="null" selected>Select a modality type...</option>
          <option *ngFor="let modalityType of (modalityTypes$ | async)" [ngValue]="modalityType.Id">{{ modalityType.Name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-4 overflow-hidden">
        <b>Valid Start From</b>
      </div>
      <div class="col-8">
        <app-date-picker [identifier]="'validFrom'" (onChange)="searchParam.ValidFrom = $event" [placeholder]="'Select a from date'"></app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Valid End Before</b>
      </div>
      <div class="col-8">
        <app-date-picker [identifier]="'validTo'" (onChange)="searchParam.ValidTo = $event" [placeholder]="'Select a to date'"></app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Departure Port</b>
      </div>
      <div class="col-8">
        <app-port-search
          class="d-inline"
          [SelectedID]="searchParam.DeparturePortID"
          [Disabled]="searchParam.ModalityTypeID === null || searchParam.ModalityTypeID > 3"
          [Identifier]="'DeparturePort'"
          [IsAirport]="PortType"
          [ShowError]="false"
          (OnSelect)="searchParam.DeparturePortID = $event">
        </app-port-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Arrival Port</b>
      </div>
      <div class="col-8">
        <app-port-search
          class="d-inline"
          [SelectedID]="searchParam.ArrivalPortID"
          [Disabled]="searchParam.ModalityTypeID === null || searchParam.ModalityTypeID > 3"
          [Identifier]="'ArrivalPort'"
          [IsAirport]="PortType"
          [ShowError]="false"
          (OnSelect)="searchParam.ArrivalPortID = $event">
        </app-port-search>
      </div>
    </div>
    <div class="row mt-1" *ngIf="isRateAdmin">
      <div class="col-4 overflow-hidden">
        <b>Agent</b>
      </div>
      <div class="col-8">
        <app-agent-search
          class="d-inline"
          [Identifier]="'AgentID'"
          [ErrorColor]="'#a22b9d'"
          [SelectedID]="searchParam.AgentID"
          (OnSelect)="searchParam.AgentID = $event">
        </app-agent-search>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Origin City</b>
      </div>
      <div class="col-8">
        <app-city-search
          class="d-inline"
          [Identifier]="'OriginCityID'"
          [ErrorColor]="'#a22b9d'"
          [SelectedID]="searchParam.OriginCityID"
          (OnSelect)="searchParam.OriginCityID = $event">
        </app-city-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Origin Country</b>
      </div>
      <div class="col-8">
        <app-country-search
          class="d-inline"
          [Identifier]="'OriginCountryID'"
          [ErrorColor]="'#a22b9d'"
          [SelectedID]="searchParam.OriginCountryID"
          (OnSelect)="searchParam.OriginCountryID = $event">
        </app-country-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Destination City</b>
      </div>
      <div class="col-8">
        <app-city-search
          class="d-inline"
          [Identifier]="'DestinationCityID'"
          [ErrorColor]="'#a22b9d'"
          [SelectedID]="searchParam.DestinationCityID"
          (OnSelect)="searchParam.DestinationCityID = $event">
        </app-city-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden">
        <b>Destination Country</b>
      </div>
      <div class="col-8">
        <app-country-search
          class="d-inline"
          [Identifier]="'DestinationCountryID'"
          [ErrorColor]="'#a22b9d'"
          [SelectedID]="searchParam.DestinationCountryID"
          (OnSelect)="searchParam.DestinationCountryID = $event">
        </app-country-search>
      </div>
    </div>
    <div class="row mt-1" *ngIf="this.isRateAdmin">
      <div class="col-4 overflow-hidden">
        <b>Rate Verdict</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(rateVerdicts$ | async)"
                [(ngModel)]="searchParam.RateVerdictID">
          <option [ngValue]="null" selected>Select a rate verdict...</option>
          <option *ngFor="let rateVerdict of (rateVerdicts$ | async)" [ngValue]="rateVerdict.Id">{{ rateVerdict.Name }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<hr class="mt-2 mb-1">
<div class="row">
  <div class="col-1 align-self-center text-start">
    <div class="form-check form-switch form-switch-sm">
      <input class="form-check-input pointer float-start"
             type="checkbox"
             role="switch"
             [title]="'Show active rate groups only'"
             [id]="'active-rg-only'"
             [checked]="searchParam.ActiveOnly"
             (change)="searchParam.ActiveOnly = !searchParam.ActiveOnly">
    </div>
  </div>
  <div class="col-4 offset-5 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-2"
            [title]="'Reset search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2 text-end ps-1">
    <button class="btn btn-sm btn-primary harmony-purple-button w-100"
            [disabled]="searchParam.ProjectID === null && searchParam.RateGroupID == null"
            (click)="updateSearch()">
      Search
    </button>
  </div>
</div>
<hr class="mt-1 mb-0">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'RGManager'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-2 header-title border-split-left pointer"
       (appOrder)="orderSearch($event)"
       data-column="Description"
       data-order="desc">
    Mode / Service
    <fa-icon [hidden]="orderParam.OrderBy != 'Description'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Origin -->
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="OriginCity.Name"
       data-order="asc">
    Origin Location
    <fa-icon [hidden]="orderParam.OrderBy != 'OriginCity.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Destination -->
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DestinationCity.Name"
       data-order="asc">
    Destination Location
    <fa-icon [hidden]="orderParam.OrderBy != 'DestinationCity.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <!-- Other -->
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Company.Name"
       data-order="asc">
    Agent
    <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="ValidTo"
       data-order="asc">
    Valid Dates
    <fa-icon [hidden]="orderParam.OrderBy != 'ValidTo'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-start col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="RateVerdictID"
       data-order="asc">
    Verdict
    <fa-icon [hidden]="orderParam.OrderBy != 'RateVerdictID'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-start col-compress header-title border-split-right">

  </div>
</div>
<!---------------------->
<!------- Results ------->
<!---------------------->
<ng-container *ngIf="!loading">
  <div class="row result-row" *ngFor='let rg of paginationData.DataSet; let i = index'>
    <div class="col-2 col-compress overflow-hidden" [title]="rg.Description">
      <b>{{ rg.Description ?? '-' }}</b>
    </div>
    <!-- Origin -->
    <div class="col-2 col-compress overflow-hidden" [title]="rg.OriginLocationName">
      <small>
        <!-- Location types City, Country, State -->
        <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
        {{ rg.OriginLocationName ?? '-' }}
        <br>
        <!-- Location types Seaport or Airport -->
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        {{ rg.DeparturePortCode ?? '-' }} {{ rg.DeparturePortDescription ? ' - ' + rg.DeparturePortDescription : '' }}
      </small>
    </div>
    <!-- Destination -->
    <div class="col-2 col-compress overflow-hidden" [title]="rg.DestinationLocationName ? rg.DestinationLocationName : rg.ArrivalPortCode">
      <small>
        <!-- Location types City, Country, State -->
        <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
        {{ rg.DestinationLocationName ?? '-' }}
        <br>
        <!-- Location types Seaport or Airport -->
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
        {{ rg.ArrivalPortCode ?  rg.ArrivalPortCode : '-' }} {{ rg.ArrivalPortDescription ? ' - ' + rg.ArrivalPortDescription : '' }}
      </small>
    </div>
    <!-- Other -->
    <div class="col-2 col-compress overflow-hidden" [title]="rg.CompanyName">
      <small>{{ rg.CompanyName ?? '-' }}</small>
    </div>
    <div class="col-2 col-compress overflow-hidden">
      <small>
        {{ rg.ValidFrom ? (rg.ValidFrom | date:environment.FormattingStandards.ShortDateFormat) : 'X' }}
        <fa-icon [icon]="faDateArrow" size="sm" class="mx-1"></fa-icon>
        {{ rg.ValidTo ? (rg.ValidTo | date:environment.FormattingStandards.ShortDateFormat) : 'X'}}
      </small>
    </div>
    <div class="col-1 col-compress pe-0">
      <small class="me-1 overflow-hidden">{{ rg.RateVerdictName }}</small>
    </div>
    <div class="col-1 col-compress text-end pe-0">
      <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
              [title]="'Edit Rate Group: ' + rg.RateGroupId"
              (click)="manageRateGroup(rg)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
      <ng-container *ngIf="isRateAdmin">
        <button type="button"
                class="btn btn-sm btn-outline-success ms-1"
                *ngIf="rg.Active === false"
                (click)="activateRateGroup(rg)">
          <fa-icon [icon]="faAdd" size="sm"></fa-icon>
        </button>
        <button type="button"
                class="btn btn-sm btn-outline-danger ms-1"
                *ngIf="rg.Active === true"
                (click)="removeRateGroup(rg)">
          <fa-icon [icon]="faRemove" size="sm"></fa-icon>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="!paginationData && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>Select a project to begin</h4>
  </div>
</div>
<div class="row mt-4" *ngIf="paginationData && paginationData.DataSet.length === 0 && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No rate groups found, change filters and try again...</h4>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
<br>
<br>
