<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Manage Location Data
  </h1>
  <div>
  </div>
</div>
<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row">
  <div class="col-12">
    <ul class="nav nav-tabs" id="locationManager" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'city'}"
           (click)="activeTab = 'city'"
           id="city-management-tab"
           data-bs-toggle="tab"
           href="#city-management"
           role="tab"
           aria-controls="city-management"
           aria-selected="true">
          City
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'city-functions'}"
           (click)="activeTab = 'city-functions'"
           id="city-functions-management-tab"
           data-bs-toggle="tab"
           href="#city-functions-management"
           role="tab"
           aria-controls="city-functions-management"
           aria-selected="true">
          City Functions
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'state'}"
           (click)="activeTab = 'state'"
           id="state-management-tab"
           data-bs-toggle="tab"
           href="#state-management"
           role="tab"
           aria-controls="state-management"
           aria-selected="false">
          State
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'country'}"
           (click)="activeTab = 'country'"
           id="country-management-tab"
           data-bs-toggle="tab"
           href="#country-management"
           role="tab"
           aria-controls="country-management"
           aria-selected="false">
          Country
        </a>
      </li>
    </ul>
    <div class="tab-content mt-2" id="rateRequestManagerContent">
      <!----------------------------->
      <!----------- City ------------>
      <!----------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'city'}"
           id="city-management"
           role="tabpanel"
           aria-labelledby="city-management-tab">
        <app-city-management></app-city-management>
      </div>
      <!---------------------->
      <!------- State -------->
      <!---------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'state'}"
           id="state-management"
           role="tabpanel"
           aria-labelledby="state-management-tab">
        <app-state-management></app-state-management>
      </div>
      <!--------------------------->
      <!--------- Country --------->
      <!--------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'country'}"
           id="country-management"
           role="tabpanel"
           aria-labelledby="country-management-tab">
        <app-country-management></app-country-management>
      </div>
      <!----------------------------->
      <!------ City Functions-------->
      <!----------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'city-functions'}"
           id="city-functions-management"
           role="tabpanel"
           aria-labelledby="city-functions-management-tab">
        <app-city-function-management></app-city-function-management>
      </div>
    </div>
  </div>
</div>
