import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import {
  ITenderOverviewResult,
  ITenderOverviewSearchParam
} from '../../../../services/models/pricing/tender.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TenderService } from '../../../../services/tender.service';
import { IAuthUser } from '../../../../services/models/auth.model';
import { faEdit, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { environment } from 'src/environments/environment';
import { getDayDifference, isDayGreaterThan, isDaySmallerThan } from 'src/app/shared/functions/datetime-functions';

@Component({
  selector: 'app-rate-wizard-overview',
  templateUrl: './tender-wizard-overview.component.html',
  styleUrls: ['./tender-wizard-overview.component.scss']
})
export class TenderWizardOverviewComponent implements OnInit, OnDestroy {
  @Output() TenderSelected = new EventEmitter();
  // Icons
  faEditIcon = faEdit;
  faReRun = faRefresh;

  // Component variables
  paginationData: IPaginationData<ITenderOverviewResult>;
  searchParam: ITenderOverviewSearchParam;
  now: Date;
  page: IPage;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  canReProcess: boolean = false;
  environment = environment;
  isDayGreaterThan = isDayGreaterThan;
  isDaySmallerThan = isDaySmallerThan;
  getDayDifference = getDayDifference;

  constructor(private api: ApiService,
              private alertService: AlertService,
              public authService: AdAuthService,
              public tenderService: TenderService,
              public pricingService: PricingService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.now = new Date();
    this.initiatePage();

    if (this.authService.CurrentUser) {
      this.searchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
      this.searchTenders();
      this.checkPermissions();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.searchParam.CompanyId = user.User.CompanyId;
      this.searchTenders();
      this.checkPermissions();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  checkPermissions() {
    this.canReProcess = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Tender_PreProcess);
  }

  createNew() {
    this.tenderService.NewTender();
  }

  initiatePage() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    };

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };

    this.searchParam = {
      TenderStatusId: null,
      TenderName: null,
      CompanyId: null,
      IsSingleLaneTender: false,
      CurrentPage: 1,
      RowCount: 15
    };
  }

  searchTenders() {
    this.loading = true;

    if (this.searchParam.TenderName && this.searchParam.TenderName.length <= 0) {
      this.searchParam.TenderName = null;
    }

    this.api.post('Tender/List', this.searchParam).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: IPaginationData<ITenderOverviewResult>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  resetSearch() {
    this.searchParam.TenderStatusId = null;
    this.searchParam.TenderName = null;
    this.searchParam.CurrentPage = 1;
    this.searchParam.RowCount = 15;
    this.resetPaginationData();
    this.searchTenders();
  }

  resetPaginationData() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;
    this.paginationData.PageSize = this.searchParam.RowCount;
    this.setPage();
  }

  setPage() {
    this.page.pageNumber = this.paginationData.CurrentPage;
    this.page.pageSize = this.paginationData.PageSize;
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.searchParam.CurrentPage = page.pageNumber;
    this.searchParam.RowCount = page.pageSize;

    this.searchTenders();
  }

  editTender(tender: ITenderOverviewResult) {
    this.TenderSelected.emit(tender);
  }

  resetTender(tender: ITenderOverviewResult) {
    const message = 'This action will reset the rate tender. Are you sure you want to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          tender.IsProcessed = false;
          this.tenderService.ResetTender(tender.Id).subscribe({
            next: () => {
              this.alertService.success('Successfully reset tender with ID: ' + tender.Id);
              this.searchTenders();
            },
            error: () => {
              this.alertService.warn('Could not reset tender with ID: ' + tender.Id);
            }
          });
        }
      });
  }
}
