import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPage, IPaginationData } from '../../models/pagination-data.model';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  // Input variables
  @Input() showPageNumber: boolean = false;
  @Input() showRowsPerPage: boolean = false;
  @Input() paginationData = {} as IPaginationData<any>;
  @Input() page = {} as IPage;
  @Input() batched: boolean = false;
  @Input() name: string;

  // Output variables
  @Output() pageEvent: EventEmitter<IPage> = new EventEmitter<IPage>();

  constructor(private storage: StorageService) { }

  ngOnInit(): void {
    if (this.name && this.name.length > 0) {
      const pageSize = this.storage.getSessionItem(this.name);
      if (pageSize) {
        this.page.pageSize = pageSize;
        this.pageEvent.emit(this.page);
      }
    }
  }

  hasPaginationData() {
    if (this.paginationData == null) {
      return false;
    }

    return this.paginationData.DataSet != null;
  }

  disableNextPage() {
    if (this.batched) {
      if (!this.paginationData || !this.paginationData.Data) {
        return true;
      }
    } else {
      if (!this.paginationData || !this.paginationData.DataSet) {
        return true;
      }
    }

    if (this.paginationData.TotalPages <= 1) {
      return true;
    }

    return (this.page.pageNumber === this.paginationData.TotalPages);
  }

  disablePreviousPage() {
    if (this.paginationData == null) {
      return true;
    }

    return this.page.pageNumber <= 1;
  }

  setRowCount() {
    this.page.pageNumber = 1
    this.storage.storeSessionItem(this.name, this.page.pageSize);
    this.pageEvent.emit(this.page);
  }

  next() {
    this.page.pageNumber += 1;
    this.pageEvent.emit(this.page);
  }

  previous() {
    this.page.pageNumber -= 1;
    this.pageEvent.emit(this.page);
  }
}
