import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import {
  faBoxOpen,
  faMapPin,
  faPlaneArrival, faPlaneDeparture,
  faPlus,
  faShip,
  faTrash,
  faTruck
} from '@fortawesome/free-solid-svg-icons';
import { AddRequestLocationsModalComponent } from '../add-locations-modal/add-request-locations-modal.component';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ViewRateGroupsModalComponent } from '../view-rategroups-modal/view-rate-groups-modal.component';
import { environment } from 'src/environments/environment';
import {
  IRateRequestLocation, IRateRequestSendResult,
  IRateRequestType,
  IRequestSendDto,
  IRequestSendSearchParam
} from '../../../../services/models/pricing/rate-request.model';
import { IRateGroup } from '../../../../services/models/pricing/rates.model';
import { ICityDto, ILocationType } from '../../../../services/models/pricing/location.model';
import { PricingService } from '../../../../services/pricing.service';
import { ApiService } from '../../../../services/api.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { IAuthUser } from '../../../../services/models/auth.model';
import { IAgent } from '../../../../services/models/pricing/partner.model';

@Component({
  selector: 'app-send-rate-request',
  templateUrl: './send-rate-request.component.html',
  styleUrls: ['./send-rate-request.component.scss'],
  providers: [DatePipe]
})
export class SendRateRequestComponent implements OnInit, OnDestroy {
  // Icons
  faAdd = faPlus;
  faRemove = faTrash;
  faEmpty = faBoxOpen;
  faLocation = faMapPin;
  faDepartureAir = faPlaneDeparture;
  faArrivalAir = faPlaneArrival;
  faSea = faShip;
  faRoad = faTruck;

  // Variables
  projectRequestTypes: IRateRequestType[] = [];
  sendRequestParam: IRequestSendSearchParam = {
    RateRequestTypes: [],
    Agents: [],
    Locations: [],
    ProjectId: null,
    ResponseDate: null,
    RateValidityDate: null,
    LastSendDate: null,
    UserId: null
  } as IRequestSendSearchParam;
  rateRequests: IRequestSendDto[] = [];
  newRateGroups: IRateGroup[];
  cityLocationType: ILocationType = null;
  selectedCityId: number = null;
  selectedAgentId: number = null;

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(public pricingService: PricingService,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.sendRequestParam.UserId = this.authService.CurrentUser.UserId;
    } else {
      this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
        this.sendRequestParam.UserId = user.UserId;
      });
    }
    this.cityLocationType = this.pricingService.GetLocationTypeByName('City');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ////////////////////////////////////
  //// Send Requests Tab functions ///
  ////////////////////////////////////
  canLoadAgents() {
    return (this.sendRequestParam &&
      this.sendRequestParam.Locations && this.sendRequestParam.Locations.length > 0 &&
      this.sendRequestParam.RateRequestTypes && this.sendRequestParam.RateRequestTypes.length > 0);
  }

  loadAgents() {
    this.loading = true;
    this.rateRequests = [];
    this.sendRequestParam.Agents = [];

    this.api.post('RateRequest/LoadAgents', this.sendRequestParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IAgent[]) => {
        if (data) {
          this.sendRequestParam.Agents = data;
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  addAgent(agent: IAgent) {
    if (agent) {
      this.selectedAgentId = agent.AgentId;
      const exists = this.sendRequestParam.Agents.find((x) => x.CompanyId === agent.CompanyId);
      // Only add if it does not exist
      if (!exists) {
        this.sendRequestParam.Agents.push(agent);
      }
      this.loading = false;
      // reset value on a delay to allow change detection on app-agent-search to triggered correctly every time.
      setTimeout(() => {
        this.selectedAgentId = null;
      }, 200);
    }
  }

  removeAgent(agent: IAgent) {
    if (agent) {
      this.rateRequests = [];
      const index = this.sendRequestParam.Agents.findIndex((x) => x.CompanyId === agent.CompanyId);
      // Remove if exists in list
      if (index >= 0) {
        this.sendRequestParam.Agents.splice(index, 1);
      }
    }
  }

  resetLocationDependent() {
    this.sendRequestParam.RateRequestTypes = [];
    this.sendRequestParam.Agents = [];
    this.rateRequests = [];
  }

  addLocation(location: ICityDto) {
    if (location && location.Id) {
      this.selectedCityId = location.Id;
      this.loading = true;
      this.resetLocationDependent();
      const exists = this.sendRequestParam.Locations.find((x) => x.LocationId === location.Id);
      // Only add if it does not exist
      if (!exists) {
        const newLocation = {
          LocationTypeId: this.cityLocationType.Id,
          LocationId: location.Id,
          LocationCode: location.LOCode,
          LocationName: location.InfoName
        } as IRateRequestLocation;

        this.sendRequestParam.Locations.push(newLocation);
      }
      this.loading = false;
      // reset value on a delay to allow change detection on app-city-search to triggered correctly every time.
      setTimeout(() => {
        this.selectedCityId = null;
      }, 200);
    }
  }

  openLocationAddModal() {
    // Open modal to add locations
    const modalRef = this.modalService.open(AddRequestLocationsModalComponent, { size: 'xl', backdrop: 'static' });
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IRateRequestLocation[]) => {
      if (result) {
        result.forEach((location: IRateRequestLocation) => {
          const exists = this.sendRequestParam.Locations.find((x) => x.LocationId === location.LocationId);
          // Only add city if it is not already in the list, avoid duplicates
          if (!exists) {
            this.sendRequestParam.Locations.push(location);
            // Reset dependent values
            this.rateRequests = [];
            this.sendRequestParam.Agents = [];
          }
        });
      }
    });
  }

  removeLocation(locationID: number) {
    if (locationID) {
      this.resetLocationDependent();
      const index = this.sendRequestParam.Locations.findIndex((x) => x.LocationId === locationID);
      // Remove if exists in list
      if (index >= 0) {
        this.sendRequestParam.Locations.splice(index, 1);
      }
    }
  }

  canSearchRequestsToSend() {
    return (this.sendRequestParam && this.sendRequestParam.ProjectId &&
      (this.sendRequestParam.Locations && this.sendRequestParam.Locations.length > 0) &&
      (this.sendRequestParam.RateRequestTypes && this.sendRequestParam.RateRequestTypes.length > 0) &&
      (this.sendRequestParam.Agents && this.sendRequestParam.Agents.length > 0) &&
      this.sendRequestParam.ResponseDate &&
      this.sendRequestParam.RateValidityDate &&
      this.sendRequestParam.LastSendDate &&
      this.sendRequestParam.UserId);
  }

  searchRateRequestsToSend() {
    this.loading = true;
    this.rateRequests = [];

    this.api.post('RateRequest/LoadRequestsToSend', this.sendRequestParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.rateRequests = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  generateRateGroups() {
    this.loading = true;

    this.api.post('RateRequest/GenerateRequestRateGroups', this.rateRequests).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateGroup[]) => {
        if (data) {
          // Open modal to show new RateGroup
          this.newRateGroups = Object.assign([], data);
          this.showRateGroupModal();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  showRateGroupModal() {
    // Open modal to add locations
    const modalRef = this.modalService.open(ViewRateGroupsModalComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.RateGroups = this.newRateGroups;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result === true) {
        this.sendRequests();
      } else {
        this.alertService.info('Rate groups were not saved and requests were not sent.');
      }
    });
  }

  sendRequests() {
    this.loading = true;

    this.api.post('RateRequest/SendRateRequests', this.rateRequests).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestSendResult) => {
        if (data) {
          this.rateRequests = Object.assign([], data.Data);
          this.alertService.success("Rate Requests successfully sent!");
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.alertService.error("An error has occurred while attempting to send the Rate Requests.");
      }
    });
  }

  // Additional methods
  setRequestTypes() {
    this.projectRequestTypes = this.getRateRequestTypeByProject(this.sendRequestParam.ProjectId);
    this.resetLocationDependent();
  }

  getRateRequestTypeByProject(projectID: number): IRateRequestType[] {
    if (projectID && projectID > 0) {
      return this.pricingService.RateRequestTypes$.getValue().filter((t) => t.ProjectId === projectID);
    } else {
      return [];
    }
  }

  getOriginLocationName(rr: IRequestSendDto) {
    switch (rr.OriginLocationTypeId) {
      case 3: return rr.OriginCountryName;
      case 4: return rr.OriginStateName;
      case 5: return rr.OriginCityName;
      default: return '-';
    }
  }

  getDestinationLocationName(rr: IRequestSendDto) {
    switch (rr.DestinationLocationTypeId) {
      case 3: return rr.DestinationCountryName;
      case 4: return rr.DestinationStateName;
      case 5: return rr.DestinationCityName;
      default: return '-';
    }
  }
}
