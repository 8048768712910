import { Component } from '@angular/core';

@Component({
  selector: 'app-services-marketing',
  templateUrl: './services-marketing.component.html',
  styleUrls: ['./services-marketing.component.scss']
})
export class ServicesMarketingComponent {

}
