<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="!isNew && !isClone">
    Edit Review: {{ review.Id }} - {{ review.YearCreated }}
  </h4>
  <h4 class="modal-title" *ngIf="isNew">
    Add New Review
  </h4>
  <h4 class="modal-title" *ngIf="isClone">
    Clone the review {{ reviewIdClone }}
  </h4>
</div>
<!---- Add/Edit Review ---->
<form [formGroup]="reviewForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-3 display-field">
        Year
      </div>
      <div class="col-4">
        <input [formControlName]="'year'" type="text" numbersOnly="4.0" class="form-control form-control-sm" placeholder="Enter year: yyyy"
               [title]="'Type the review year'"
               [ngClass]="{'is-invalid': reviewForm.controls.year.touched && reviewForm.controls.year.invalid}"/>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 display-field">
        Start Date
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'startDate'" (onChange)="setStartDate($event)"
                         [placeholder]="'Select a start date'" [value]="review.StartDate">
        </app-date-picker>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 display-field">
        End Date
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'endDate'" (onChange)="setEndDate($event)"
                         [placeholder]="'Select an end date'" [value]="review.EndDate">
        </app-date-picker>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3 display-field">
        Harmony Document
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'harmonyDocDate'" (onChange)="setHarmonyDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.HarmonyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-1 display-field">
        Template
      </div>
      <div class="col-3">
        {{ review.HarmonyDocument }}
      </div>
      <div class="col-1">
        <input [hidden]="true" #ar1 (change)="uploadHarmonyDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary harmony-teal-button input-picker-button"
                (click)="ar1.click()"
                [title]="'Upload document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 display-field">
        Network Company Document
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'networkDocDate'" (onChange)="setNetworkDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.NetworkCompanyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-1 display-field">
        Template
      </div>
      <div class="col-3">
        {{ review.NetworkCompanyDocument }}
      </div>
      <div class="col-1">
        <input [hidden]="true" #ar2 (change)="uploadNetworkDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary harmony-teal-button input-picker-button"
                (click)="ar2.click()"
                [title]="'Upload document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 display-field">
        Company Document
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'companyDocDate'" (onChange)="setCompanyDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.CompanyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-1 display-field">
        Template
      </div>
      <div class="col-3">
        {{ review.CompanyDocument }}
      </div>
      <div class="col-1 template-button">
        <input [hidden]="true" #ar3 (change)="uploadCompanyDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary harmony-teal-button input-picker-button"
                (click)="ar3.click()"
                [title]="'Upload document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <hr>
    <div class="row mt-2">
      <div class="col-3 display-field">
        Audit Start Date
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'auditStartDate'" (onChange)="setAuditStartDate($event)"
                         [placeholder]="'Select a start date'" [value]="review.AuditStartDate">
        </app-date-picker>
      </div>
      <div class="col-1 display-field">
        Template
      </div>
      <div class="col-3">
        {{ review.AuditDocument }}
      </div>
      <div class="col-1 template-button">
        <input [hidden]="true" #ara (change)="uploadAuditDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary harmony-teal-button input-picker-button"
                (click)="ara.click()"
                [title]="'Upload Audit document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 display-field">
        Audit End Date
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'auditEndDate'" (onChange)="setAuditEndDate($event)"
                         [placeholder]="'Select an end date'" [value]="review.AuditEndDate">
        </app-date-picker>
      </div>
    </div>
  </div>
</form>
<app-loader *ngIf="loading"></app-loader>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew && !isClone">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="add()">Add</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isClone">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="clone()">Clone</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
