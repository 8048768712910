<div class="modal-header">
  <h4 class="modal-title">
    Mapping for message ID: {{ SourceMessage.Id }}
  </h4>
</div>
<div class="modal-body" *ngIf="SourceMessage">
  <div class="row">
    <div class="col-12">
      <!-- Searching -->
      <div class="row">
        <div class="col-4">
          <input class="form-control form-control-sm"
                 type="text"
                 id="DebugSearchText"
                 [placeholder]="'Search...'"
                 [(ngModel)]="searchText"
                 [disabled]="!SourceMessageDebug || SourceMessageDebug.length === 0"
                 (input)="searchDebug()"
                 name="DebugSearchText">
        </div>
        <div class="col-3 offset-5 text-end">
          Row Count: <b>{{ FilteredSourceMessageDebug.length }}</b>
        </div>
      </div>
      <hr class="my-2">
      <!-- Data -->
      <div class="row">
        <div class="col-5">
          <h5 class="harmony-teal-text mb-0 ps-2">
            Source
          </h5>
        </div>
        <div class="col-7">
          <h5 class="harmony-teal-text mb-0">
            Target
          </h5>
        </div>
      </div>
      <div class="row mt-1 header-row">
        <div class="col-3 header-title-small border-split-left">
          Source Path
        </div>
        <div class="col-2 header-title-small border-split-right">
          Source Value
        </div>
        <div class="col-3 header-title-small border-split-left">
          Target Element
        </div>
        <div class="col-2 header-title-small">
          Target Value
        </div>
        <div class="col-2 header-title-small border-split-right">
          Lookup Type
        </div>
      </div>
      <div class="message-mapping-wrapper">
        <div class="row result-row" *ngFor='let message of FilteredSourceMessageDebug; let i = index'>
          <div class="col-3 overflow-hidden">
            {{ message.srcPath ?? '-' }}
          </div>
          <div class="col-2 overflow-hidden" [title]="message.srcElementValue">
            <small>{{ message.srcElementValue ?? '-' }}</small>
          </div>
          <div class="col-3 overflow-hidden">
            {{ message.trgElementName ?? '-' }}
          </div>
          <div class="col-2 overflow-hidden" [title]="message.trgElementValue">
            <small>{{ message.trgElementValue ?? '-' }}</small>
          </div>
          <div class="col-2 overflow-hidden" [title]="message.lookupTypeName">
            <small>{{ message.lookupTypeName ?? '-' }}</small>
          </div>
        </div>
      </div>
      <!------------------->
      <!----- Loader ------>
      <!------------------->
      <ng-container *ngIf="loading">
        <div class="row mt-4">
          <div class="col-12 text-center">
            <app-loader [Width]="50"></app-loader>
          </div>
        </div>
      </ng-container>
      <!------------------->
      <!--- No Results ---->
      <!------------------->
      <ng-container *ngIf="!loading && FilteredSourceMessageDebug.length <= 0">
        <div class="row mt-4" *ngIf="!loading && (FilteredSourceMessageDebug && FilteredSourceMessageDebug.length === 0)">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
          </div>
          <div class="col-12 text-center harmony-grey-text mt-1">
            <h4>No results...</h4>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
