<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="isNew">
    Add Answer
  </h4>
  <h4 class="modal-title" *ngIf="!isNew && canEdit">
    Edit Answer
  </h4>
  <h4 class="modal-title" *ngIf="!canEdit && !isNew">
    Answer
  </h4>
</div>
<!---- Add/Edit Answer ---->
<form [formGroup]="answerForm">
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-12">
        <textarea [disabled]="!canEdit" formControlName="answer" name="chapter-name" rows="15" class="form-control" placeholder="You can expand this box at the bottom right"></textarea>
      </div>
    </div>
  </div>
</form>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew && canEdit">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="update()">Update</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Cancel</button>
</div>
<div class="modal-footer" *ngIf="isNew && canEdit">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="update()">Add</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Cancel</button>
</div>
  <div class="modal-footer" *ngIf="!canEdit">
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Cancel</button>
</div>

