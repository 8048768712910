<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit" *ngIf="agent != null">
    {{ agent.Name }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-5">
      <div class="row">
        <div class="col-12 fw-bold harmony-teal-text">
          Agent Details
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!agent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container  *ngIf="agent">
        <div class="row mt-2">
          <div class="col-4">
            <b>Type</b>
          </div>
          <div class="col-8">
            {{ agent.CompanyType ? agent.CompanyType.Description : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Name</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': (!agent.Name || agent.Name.length <= 0) && invalid}"
                   [(ngModel)]="agent.Name">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Legal Name</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [(ngModel)]="agent.LegalName">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent ID </b> <small> (RedSky)</small>
          </div>
          <div class="col-8" *ngIf="agent.AgentId != null">
            {{ agent.AgentId }}
          </div>
          <div class="col-8" *ngIf="agent.AgentId == null">
            <input type="text"
                   numbersOnly="10.0"
                   class="form-control form-control-sm"
                   [placeholder]="'Agent Id (RedSky)'"
                   [(ngModel)]="newAgentId">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <b>Last Modified By</b>
          </div>
          <div class="col-8 small overflow-hidden">
            {{ lastModifiedUser ? lastModifiedUser.DisplayName + ' (' + (agent.ModifiedOnDate | date: env.FormattingStandards.ShortDateFormat) + ')' : '-' }}
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12 fw-bold harmony-teal-text">
          Additional Info
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!agent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="agent">
        <div class="row mt-2">
          <div class="col-4">
            <b>Allowed Domains</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(emailDomainDefId, $event)"
                   [value]="getDetailValue('EmailDomein')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Rate Contact</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(rateRequestDefId, $event)"
                   [value]="getDetailValue('RateRequest')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Status</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [(ngModel)]="agent.AgentStatus">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Code</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(agentCodeDefId, $event)"
                   [value]="getDetailValue('AgentCode')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Parent Code</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(agentParentDefId, $event)"
                   [value]="getDetailValue('AgentParent')">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <b>FIDI</b>
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="'switch-fidi'"
                     [checked]="agent.FIDINumber === 'Y'"
                     [title]="'Is FIDI Member?'"
                     (change)="setFidiNumber($event)">
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12 fw-bold harmony-teal-text">
          Service Information
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!agent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="agent">
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Moving Provider
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="agent.CompanyId.toString() + '_moving'"
                     [checked]="agent.IsMovingServiceProvider"
                     (change)="setMovingProvider($event)">
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Relocation Provider
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="agent.CompanyId.toString() + '_relocation'"
                     [checked]="agent.IsRelocationServiceProvider"
                     (change)="setRelocationProvider($event)">
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-12 fw-bold harmony-teal-text">
          Address Information
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!agent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="agent">
        <div class="row mt-2">
          <div class="col-12">
            <b>Physical</b>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'Physical Address'"
                   [(ngModel)]="agent.AddressPhysical">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'City'"
                   [(ngModel)]="agent.CityPhysical">
          </div>
          <div class="col-4">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'ZIP Code'"
                   [(ngModel)]="agent.ZipCodePhysical">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <app-country-search
              class="d-inline"
              [Identifier]="'PhysicalCountryID'"
              [ShowError]="(!agent.CountryIdPhysical || agent.CountryIdPhysical <= 0) && invalid"
              [SelectedID]="agent.CountryIdPhysical"
              (OnSelectObject)="setCountryPhysical($event)">
            </app-country-search>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <b>Postal</b>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'Postal Address'"
                   [(ngModel)]="agent.AddressPostal">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'City'"
                   [(ngModel)]="agent.CityPostal">
          </div>
          <div class="col-4">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'ZIP Code'"
                   [(ngModel)]="agent.ZipCodePostal">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <app-country-search
              class="d-inline"
              [Identifier]="'PhysicalCountryID'"
              [SelectedID]="agent.CountryIdPostal"
              (OnSelectObject)="setCountryPostal($event)">
            </app-country-search>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <b>Longitude</b>
          </div>
          <div class="col-8">
            <input type="text"
                   numbersOnly="3.6"
                   class="form-control form-control-sm"
                   [(ngModel)]="agent.Longitude">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Latitude</b>
          </div>
          <div class="col-8">
            <input type="text"
                   numbersOnly="3.6"
                   class="form-control form-control-sm"
                   [(ngModel)]="agent.Latitude">
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-3 border-start">
      <div class="row">
        <div class="col-8 fw-bold harmony-teal-text">
          Users
        </div>
        <div class="col-4 text-end">
          <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button"
                  *ngIf="hasCreatePermission"
                  (click)="openNewUserModal()">
            +
          </button>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="user-list">
        <ng-container *ngIf="auth.CheckPermissionByCode('USERMAN')">
          <div class="row mt-1" *ngFor="let user of users">
            <div class="col-10 overflow-hidden">
              {{ user.Username }}
            </div>
            <div class="col-2 text-start col-compress">
              <fa-icon [icon]="faEdit" class="harmony-purple-text pointer" (click)="editUser(user.Username)"></fa-icon>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!auth.CheckPermissionByCode('USERMAN')">
          <div class="row mt-1" *ngFor="let user of users">
            <div class="col-12 overflow-hidden">
              {{ user.Username }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!users || users.length <= 0">
          <div class="row">
            <div class="col-12 harmony-grey-text">
              This agent has no users.
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" [disabled]="loading" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="close()">Close</button>
</div>
