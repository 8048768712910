import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICityFunctionDto } from '../../../../../services/models/pricing/location.model';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../services/alert.service';
import { ApiService } from '../../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IFunctionType } from '../../../../../services/models/pricing/rates.model';

@Component({
  selector: 'app-city-function-edit-modal',
  templateUrl: './city-function-edit-modal.component.html',
  styleUrls: ['./city-function-edit-modal.component.scss']
})
export class CityFunctionEditModalComponent implements OnInit, OnDestroy {
  // Icons

  // Component variables
  public CityFunctionId: number;
  public FunctionTypes: IFunctionType[];
  cityFunction: ICityFunctionDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit(): void {
    if (this.CityFunctionId != null && this.CityFunctionId > 0) {
      this.loadCity();
    } else {
      this.cityFunction = {
        Id: 0,
        Active: true,
        CityId: null,
        CityName: null,
        Code: null,
        DateCreated: new Date(),
        DateModified: null,
        Description: null,
        FunctionTypeId: null,
        FunctionDescription: null
      } as ICityFunctionDto;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadCity() {
    this.loading = true;
    this.api.get('CityFunction/Single/' + this.CityFunctionId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ICityFunctionDto) => {
        if (data) {
          this.cityFunction = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  save() {
    if (this.isValid()) {
      this.loading = true;
      this.api.post('CityFunction/Update', this.cityFunction).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: ICityFunctionDto) => {
          if (data) {
            this.alertService.success('City record successfully updated!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    } else {
      this.alertService.warn('Unable to save. Please make sure all required fields are correctly populated.');
    }
  }

  isValid() {
    const c = this.cityFunction;

    return (c.CityId && c.CityId > 0) &&
      (c.FunctionTypeId && c.FunctionTypeId > 0) &&
      (c.Code && c.Code.length > 0) &&
      (c.Description && c.Description.length > 0);
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
