<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="isNew">
    Add New Chapter
  </h4>
  <h4 class="modal-title" *ngIf="!isNew">
    Edit Chapter: <b>{{ chapter.Id }}</b>
  </h4>
</div>
<!---- Add/Edit Chapter ---->
<form [formGroup]="chapterForm">
  <div class="modal-body">
    <div class="row mt-1">
      <div class="col-3 fw-bold">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-9">
        <textarea [formControlName]="'name'"
                  name="chapter-name"
                  appAutocompleteOff
                  rows="3"
                  class="form-control form-control-sm">
        </textarea>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3 fw-bold">
        Review <span class="text-danger">*</span>
      </div>
      <div class="col-9">
        <select class="form-select form-select-sm"
                [formControlName]="'reviewId'">
          <option [ngValue]="null" disabled selected>Select a review year</option>
          <option *ngFor="let r of reviewSearchFilter" [ngValue]="r.Id">
            {{ r.ReviewYear }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3 fw-bold">
        Order
      </div>
      <div class="col-9">
        <input [formControlName]="'order'"
               type="number"
               class="form-control form-control-sm"
               placeholder="Enter an optional chapter order number">
      </div>
    </div>
  </div>
</form>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="add()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>
