import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
  IDangerousSubstanceOverviewResponse,
  IDangerousSubstanceSafetyChartResponse
} from '../../../services/models/hse/dangerous-substance-register.model';
import { ApiService } from '../../../services/api.service';
import { Subject } from 'rxjs';
import { faEdit, faEye, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DangerousSubstanceModalComponent } from './dangerous-substance-modal/dangerous-substance-modal.component';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { DangerousSubstanceInformationModalComponent } from './dangerous-substance-information-modal/dangerous-substance-information-modal.component';
import { IBranchDto } from '../../../services/models/hse/branch.model';
import { AlertService } from '../../../services/alert.service';
import { HseDsrService } from '../../../services/hse-dsr.service';
import { HseService } from '../../../services/hse.service';
import { IAuthUser } from '../../../services/models/auth.model';

@Component({
  selector: 'app-dangerous-substance-register',
  templateUrl: './dangerous-substance-register.component.html',
  styleUrls: ['./dangerous-substance-register.component.scss'],
  providers: [HseDsrService]
})
export class DangerousSubstanceRegisterComponent implements OnInit, OnDestroy {
  // Icons
  faView = faEye;
  faDelete = faTrash;
  faPrint = faPrint;
  faEditIcon = faEdit;

  // Component Variables
  public canEditOrAdd: boolean = false;
  public canDelete: boolean = false;
  public canFilterCompany: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public substances: IDangerousSubstanceOverviewResponse[];
  companyId: number = null;
  branches = [] as IBranchDto[];
  branch: IBranchDto;
  filteredBranch: IBranchDto;
  hasBranches: boolean;
  selectedSubstance: IDangerousSubstanceSafetyChartResponse;

  // Shared Components
  paginationData: IPaginationData<IDangerousSubstanceOverviewResponse> = null;
  page: IPage;
  loading: boolean;
  noResult: boolean = false;

  constructor(private companyService: CompanyService,
              private apiService: ApiService,
              private modalService: NgbModal,
              private authService: AdAuthService,
              private hseService: HseService,
              private alertService: AlertService) {
  }

  @HostListener('window:afterprint')
  onafterprint() {
    window.setTimeout(() => {
      this.authService.HideMenu = false;
    }, 200);
  }

  companies$ = this.companyService.ActiveMembers$.asObservable();

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IDangerousSubstanceOverviewResponse>;

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    this.canFilterCompany = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DSR_CompanyFilter);
    if (!this.canFilterCompany) {
      if (this.authService.CurrentUser) {
        this.companyId = this.authService.CurrentUser.User.CompanyId;
        this.getDangerousSubstances();
        this.getBranches();
      } else {
        this.authService.CurrentUser$
          .subscribe((user: IAuthUser) => {
            this.companyId = user.User.CompanyId;
            this.getDangerousSubstances();
            this.getBranches();
          });
      }
    } else {
      this.getDangerousSubstances();
    }
    this.canEditOrAdd = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DSR_Edit);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DSR_Delete);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  /// Modals
  openSubstanceModal(isNew: boolean, substanceId: number = null, companyId: number = null) {
    const modalRef = this.modalService.open(DangerousSubstanceModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.hasCompanyFilterPermission = this.canFilterCompany;
    modalRef.componentInstance.canEdit = this.canEditOrAdd;
    modalRef.componentInstance.substanceId = substanceId;
    modalRef.componentInstance.companyId = companyId;
    modalRef.result.then((reload: boolean) => {
      if (reload) {
        this.getDangerousSubstances();
      }
    });
  }

  openInfoModal() {
    if (!this.companyId) {
      this.alertService.error('Please select a company');
      return;
    }
    if (!this.branch && this.hasBranches) {
      this.alertService.error('Please select a branch');
      return;
    }
    const modalRef = this.modalService.open(DangerousSubstanceInformationModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.companyId;
    modalRef.componentInstance.branch = this.branch;
    modalRef.componentInstance.hasAccess = this.canEditOrAdd;
  }

  /// API Calls
  getDangerousSubstances() {
    let route = `DangerousSubstanceRegister?pageNumber=${this.page.pageNumber}&pageSize=${this.page.pageSize}`;
    if (this.filteredBranch?.Id) {
      route += `&branchId=${this.filteredBranch?.Id}`;
    }
    if (this.companyId) {
      route += `&companyId=${this.companyId}`;
    }

    this.loading = true;
    this.apiService.get(route)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.paginationData = Object.assign({}, data);
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.substances = this.paginationData.DataSet;
          this.noResult = this.paginationData.DataSet.length == 0;
        }, error: () => {
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  deleteDangerousSubstance(id: number, index: number) {
    this.apiService.delete(`DangerousSubstanceRegister/${id}`)
      .subscribe({
        next: () => {
          // remove from display
          this.paginationData.DataSet.splice(index, 1);
          this.substances.splice(index, 1);
        }
      });
  }

  getBranches() {
    // Reset branch
    this.branch = null;
    this.branches = [];
    this.hasBranches = false;
    // Get branches for company
    if (this.companyId) {
      this.hseService.getBranchesByCompanyId(this.companyId)
        .subscribe({
          next: value => {
            if (value) {
              this.branches = Object.assign([], value);
              this.hasBranches = true;
            } else {
              this.branches = [];
              this.hasBranches = false;
            }
          },
        });
    }
  }

  printSafetyChart(substanceId: number) {
    this.authService.HideMenu = true;
    this.apiService.get(`DangerousSubstanceRegister/SafetyChart/${substanceId}`)
      .subscribe({
        next: (data) => {
          this.selectedSubstance = Object.assign({}, data);
          window.setTimeout('window.print()', 600);
        }
      });
  }

  searchSubstances() {
    this.filteredBranch = this.branch;
    this.page.pageNumber = 1;
    this.getDangerousSubstances();
  }

  onPageChange(event: IPage) {
    this.page = event;
    this.getDangerousSubstances();
  }
}
