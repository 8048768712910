import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../../services/email.service';
import { IEmail, IEmailSearch } from '../../../../services/models/email.model';
import { AlertService } from '../../../../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEmailModalComponent } from './view-email-modal/view-email-modal.component';
import { faBoxOpen, faEdit, faEye, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { environment } from 'src/environments/environment';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-email-overview',
  templateUrl: './email-overview.component.html',
  styleUrls: ['./email-overview.component.scss']
})
export class EmailOverviewComponent implements OnInit {
  // Icons
  faEditIcon = faEdit;
  faView = faEye;
  faEmpty = faBoxOpen;
  faSortDesc = faSortUp;

  // Component variables
  paginationData: IPaginationData<IEmail>;
  page: IPage;
  searchParam: IEmailSearch;
  emailTemplates$ = this.emailService.emailTemplates$.asObservable();
  emailStatuses$ = this.emailService.emailStatuses$.asObservable();
  emailSchedules$ = this.emailService.emailSchedules$.asObservable();
  orderParam: IOrderParam;

  // General variables
  loading: boolean = false;
  environment = environment;

  constructor(public emailService: EmailService,
              private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IEmail>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'ProcessDate',
      OrderDirection: 'desc'
    } as IOrderParam;
    // Init searchParam
    this.resetSearch();
    this.searchEmails();
  }

  searchEmails() {
    this.loading = true;

    this.emailService.searchEmails(this.searchParam, this.orderParam).subscribe({
      next: (data) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search emails.');
      }
    });
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.searchParam.CurrentPage = page.pageNumber;
    this.searchParam.RowCount = page.pageSize;

    this.searchEmails();
  }

  resetSearch() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;

    this.searchParam = {
      EmailID: null,
      ScheduleID: null,
      StatusID: null,
      TemplateID: null,
      ToAddress: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    } as IEmailSearch;
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchEmails();
  }

  viewEmail(emailID: number) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ViewEmailModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.EmailID = emailID;
    modalRef.componentInstance.LoadEmailContent();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        // this.alertService.success(result);
      }
    }, () => { });
  }
}
