export const CountryCodes = [
  {country: 'Afghanistan', code: '+93', iso: 'af '},
  {country: 'Albania', 'code': '+355', iso: 'al'},
  {country: 'Algeria', code: '+213', iso: 'dz'},
  {country: 'American Samoa', code: '+1-684', iso: 'as'},
  {country: 'Andorra', code: '+376', iso: 'ad'},
  {country: 'Angola', code: '+244', iso: 'ao'},
  {country: 'Anguilla', code: '+264', iso: 'ai'},
  {country: 'Antarctica', code: '+672', iso: 'aq'},
  {country: 'Antigua and Barbuda', code: '+1-268', iso: 'ag'},
  {country: 'Argentina', code: '+54', iso: 'ar'},
  {country: 'Armenia', code: '+374', iso: 'am'},
  {country: 'Aruba', code: '+297', iso: 'aw'},
  {country: 'Australia', code: '+61', iso: 'au'},
  {country: 'Austria', code: '+43', iso: 'at'},
  {country: 'Azerbaijan', code: '+994', iso: 'az'},
  {country: 'Bahamas', code: '+1-242', iso: 'bs'},
  {country: 'Bahrain', code: '+973', iso: 'bh'},
  {country: 'Bangladesh', code: '+880', iso: 'bd'},
  {country: 'Barbados', code: '+1-246', iso: 'bb'},
  {country: 'Belarus', code: '+375', iso: 'by'},
  {country: 'Belgium', code: '+32', iso: 'be'},
  {country: 'Belize', code: '+501', iso: 'bz'},
  {country: 'Benin', code: '+229', iso: 'bj'},
  {country: 'Bermuda', code: '+1-441', iso: 'bm'},
  {country: 'Bhutan', code: '+975', iso: 'bt'},
  {country: 'Bolivia', code: '+591', iso: 'bo'},
  {country: 'Bosnia and Herzegovina', code: '+387', iso: 'ba'},
  {country: 'Botswana', code: '+267', iso: 'bw'},
  {country: 'Brazil', code: '+55', iso: 'br'},
  {country: 'British Indian Ocean Territory', code: '+246', iso: 'io'},
  {country: 'British Virgin Islands', code: '+1-284', iso: 'vg'},
  {country: 'Brunei', code: '+673', iso: 'bn'},
  {country: 'Bulgaria', code: '+359', iso: 'bg'},
  {country: 'Burkina Faso', code: '+226', iso: 'bf'},
  {country: 'Burundi', code: '+257', iso: 'bi'},
  {country: 'Cambodia', code: '+855', iso: 'kh'},
  {country: 'Cameroon', code: '+237', iso: 'cm'},
  {country: 'Canada', code: '+1', iso: 'ca'},
  {country: 'Cape Verde', code: '+238', iso: 'cv'},
  {country: 'Cayman Islands', code: '+1-345', iso: 'ky'},
  {country: 'Central African Republic', code: '+236', iso: 'cf'},
  {country: 'Chad', code: '+235', iso: 'td'},
  {country: 'Chile', code: '+56', iso: 'cl'},
  {country: 'China', code: '+86', iso: 'cn'},
  {country: 'Christmas Island', code: '+61', iso: 'cx'},
  {country: 'Cocos Islands', code: '+61', iso: 'cc'},
  {country: 'Colombia', code: '+57', iso: 'co'},
  {country: 'Comoros', code: '+269', iso: 'km'},
  {country: 'Cook Islands', code: '+682', iso: 'ck'},
  {country: 'Costa Rica', code: '+506', iso: 'cr'},
  {country: 'Croatia', code: '+385', iso: 'hr'},
  {country: 'Cuba', code: '+53', iso: 'cu'},
  {country: 'Curacao', code: '+599', iso: 'cw'},
  {country: 'Cyprus', code: '+357', iso: 'cy'},
  {country: 'Czech Republic', code: '+420', iso: 'cz'},
  {country: 'Democratic Republic of the Congo', code: '+243', iso: 'cd'},
  {country: 'Denmark', code: '+45', iso: 'dk'},
  {country: 'Djibouti', code: '+253', iso: 'dj'},
  {country: 'Dominica', code: '+1-767', iso: 'dm'},
  {country: 'Dominican Republic', code: '+1-809', iso: 'do'},
  {country: 'Dominican Republic', code: '+1-829', iso: 'do'},
  {country: 'Dominican Republic', code: '+1-849', iso: 'do'},
  {country: 'East Timor', code: '+670', iso: 'tl'},
  {country: 'Ecuador', code: '+593', iso: 'ec'},
  {country: 'Egypt', code: '+20', iso: 'eg'},
  {country: 'El Salvador', code: '+503', iso: 'sv'},
  {country: 'Equatorial Guinea', code: '240', iso: 'gq'},
  {country: 'Eritrea', code: '+291', iso: 'er'},
  {country: 'Estonia', code: '+372', iso: 'ee'},
  {country: 'Ethiopia', code: '+251', iso: 'et'},
  {country: 'Falkland Islands', code: '+500', iso: 'fk'},
  {country: 'Faroe Islands', code: '+298', iso: 'fo'},
  {country: 'Fiji', code: '+679', iso: 'fj'},
  {country: 'Finland', code: '+358', iso: 'fi'},
  {country: 'France', code: '+33', iso: 'fr'},
  {country: 'French Polynesia', code: '+689', iso: 'pf'},
  {country: 'Gabon', code: '+241', iso: 'ga'},
  {country: 'Gambia', code: '+220', iso: 'gm'},
  {country: 'Georgia', code: '+995', iso: 'ge'},
  {country: 'Germany', code: '+49', iso: 'de'},
  {country: 'Ghana', code: '+233', iso: 'gh'},
  {country: 'Gibraltar', code: '+350', iso: 'gi'},
  {country: 'Greece', code: '+30', iso: 'gr'},
  {country: 'Greenland', code: '+299', iso: 'gl'},
  {country: 'Grenada', code: '+1-473', iso: 'gd'},
  {country: 'Guam', code: '+1-671', iso: 'gu'},
  {country: 'Guatemala', code: '+502', iso: 'gt'},
  {country: 'Guernsey', code: '+44-1481', iso: 'gg'},
  {country: 'Guinea', code: '+224', iso: 'gn'},
  {country: 'Guinea-Bissau', code: '+245', iso: 'gw'},
  {country: 'Guyana', code: '+592', iso: 'gy'},
  {country: 'Haiti', code: '+509', iso: 'ht'},
  {country: 'Honduras', code: '+504', iso: 'hn'},
  {country: 'Hong Kong', code: '+852', iso: 'hk'},
  {country: 'Hungary', code: '+36', iso: 'hu'},
  {country: 'Iceland', code: '+354', iso: 'is'},
  {country: 'India', code: '+91', iso: 'in'},
  {country: 'Indonesia	', code: '+62', iso: 'id'},
  {country: 'Iran', code: '+98', iso: 'ir'},
  {country: 'Iraq', code: '+964', iso: 'iq'},
  {country: 'Ireland', code: '+353', iso: 'ie'},
  {country: 'Isle of Man', code: '+44-1624', iso: 'im'},
  {country: 'Israel', code: '+972', iso: 'il'},
  {country: 'Italy', code: '+39', iso: 'it'},
  {country: 'Ivory Coast', code: '+225', iso: 'ci'},
  {country: 'Jamaica', code: '+1-876', iso: 'jm'},
  {country: 'Japan', code: '+81', iso: 'jp'},
  {country: 'Jersey', code: '+44-1534', iso: 'je'},
  {country: 'Jordan', code: '+962', iso: 'jo'},
  {country: 'Kazakhstan', code: '+7', iso: 'kz'},
  {country: 'Kenya', code: '+254', iso: 'ke'},
  {country: 'Kiribati', code: '+686', iso: 'ki'},
  {country: 'Kosovo', code: '+383', iso: 'xk'},
  {country: 'Kuwait', code: '+965', iso: 'kw'},
  {country: 'Kyrgyzstan', code: '+996', iso: 'kg'},
  {country: 'Laos', code: '+856', iso: 'la'},
  {country: 'Latvia', code: '+371', iso: 'lv'},
  {country: 'Lebanon', code: '+961', iso: 'lb'},
  {country: 'Lesotho', code: '+266', iso: 'ls'},
  {country: 'Liberia', code: '+231', iso: 'lr'},
  {country: 'Libya', code: '+218', iso: 'ly'},
  {country: 'Liechtenstein', code: '+423', iso: 'li'},
  {country: 'Lithuania', code: '+370', iso: 'lt'},
  {country: 'Luxembourg', code: '+352', iso: 'lu'},
  {country: 'Macau', code: '+853', iso: 'mo'},
  {country: 'Macedonia', code: '+389', iso: 'mk'},
  {country: 'Madagascar', code: '+261', iso: 'mg'},
  {country: 'Malawi', code: '+265', iso: 'mw'},
  {country: 'Malaysia', code: '+60', iso: 'my'},
  {country: 'Maldives', code: '+960', iso: 'mv'},
  {country: 'Mali', code: '+223', iso: 'ml'},
  {country: 'Malta', code: '+356', iso: 'mt'},
  {country: 'Marshall Islands', code: '+692', iso: 'mh'},
  {country: 'Mauritania', code: '+222', iso: 'mr'},
  {country: 'Mauritius', code: '+230', iso: 'mu'},
  {country: 'Mayotte', code: '+262', iso: 'yt'},
  {country: 'Mexico', code: '+52', iso: 'mx'},
  {country: 'Micronesia', code: '+691', iso: 'fm'},
  {country: 'Moldova', code: '+373', iso: 'md'},
  {country: 'Monaco', code: '+377', iso: 'mc'},
  {country: 'Mongolia', code: '+976', iso: 'mn'},
  {country: 'Montenegro', code: '+382', iso: 'me'},
  {country: 'Montserrat', code: '+1-664', iso: 'ms'},
  {country: 'Morocco', code: '+212', iso: 'ma'},
  {country: 'Mozambique', code: '+258', iso: 'mz'},
  {country: 'Myanmar', code: '+95', iso: 'mm'},
  {country: 'Namibia', code: '+264', iso: 'na'},
  {country: 'Nauru', code: '+674', iso: 'nr'},
  {country: 'Nepal', code: '+977', iso: 'np'},
  {country: 'Netherlands', code: '+31', iso: 'nl'},
  // {country: 'Netherlands Antilles', code: '+599', iso: 'an'},
  {country: 'New Caledonia', code: '+687', iso: 'nc'},
  {country: 'New Zealand', code: '+64', iso: 'nz'},
  {country: 'Nicaragua', code: '+505', iso: 'ni'},
  {country: 'Niger', code: '+227', iso: 'ne'},
  {country: 'Nigeria', code: '+234', iso: 'ng'},
  {country: 'Niue', code: '+683', iso: 'nu'},
  {country: 'North Korea', code: '+850', iso: 'kp'},
  {country: 'Northern Mariana Islands', code: '+1-670', iso: 'mp'},
  {country: 'Norway', code: '+47', iso: 'no'},
  {country: 'Oman', code: '+968', iso: 'om'},
  {country: 'Pakistan', code: '+92', iso: 'pk'},
  {country: 'Palau', code: '+680', iso: 'pw'},
  {country: 'Palestine', code: '+970', iso: 'ps'},
  {country: 'Panama', code: '+507', iso: 'pa'},
  {country: 'Papua New Guinea', code: '+675', iso: 'pg'},
  {country: 'Paraguay', code: '+595', iso: 'py'},
  {country: 'Peru', code: '+51', iso: 'pe'},
  {country: 'Philippines', code: '+63', iso: 'ph'},
  {country: 'Pitcairn', code: '+64', iso: 'pn'},
  {country: 'Poland', code: '+48', iso: 'pl'},
  {country: 'Portugal', code: '+351', iso: 'pt'},
  {country: 'Puerto Rico', code: '+1-787', iso: 'pr'},
  {country: 'Puerto Rico', code: '+1-939', iso: 'pr'},
  {country: 'Qatar', code: '+974', iso: 'qa'},
  {country: 'Republic of the Congo', code: '+242', iso: 'cg'},
  {country: 'Reunion', code: '+262', iso: 're'},
  {country: 'Romania', code: '+40', iso: 'ro'},
  {country: 'Russia', code: '+7', iso: 'ru'},
  {country: 'Rwanda', code: '+250', iso: 'rw'},
  {country: 'Saint Barthelemy', code: '+590', iso: 'bl'},
  {country: 'Saint Helena', code: '+290', iso: 'sh'},
  {country: 'Saint Kitts and Nevis', code: '+1-869', iso: 'kn'},
  {country: 'Saint Lucia', code: '+1-758', iso: 'lc'},
  {country: 'Saint Martin', code: '+590', iso: 'mf'},
  {country: 'Saint Pierre and Miquelon', code: '+508', iso: 'pm'},
  {country: 'Saint Vincent and the Grenadines', code: '+1-784', iso: 'vc'},
  {country: 'Samoa', code: '+685', iso: 'ws'},
  {country: 'San Marino', code: '+378', iso: 'sm'},
  {country: 'Sao Tome and Principe', code: '+239', iso: 'st'},
  {country: 'Saudi Arabia', code: '+966', iso: 'sa'},
  {country: 'Senegal', code: '+221', iso: 'sn'},
  {country: 'Serbia', code: '+381', iso: 'rs'},
  {country: 'Seychelles', code: '+248', iso: 'sc'},
  {country: 'Sierra Leone', code: '+232', iso: 'sl'},
  {country: 'Singapore', code: '+65', iso: 'sg'},
  {country: 'Sint Maarten', code: '+1-721', iso: 'sx'},
  {country: 'Slovakia', code: '+421', iso: 'sk'},
  {country: 'Slovenia', code: '+386', iso: 'si'},
  {country: 'Solomon Islands', code: '+677', iso: 'sb'},
  {country: 'Somalia', code: '+252', iso: 'so'},
  {country: 'South Africa', code: '+27', iso: 'za'},
  {country: 'South Korea', code: '+82', iso: 'kr'},
  {country: 'South Sudan', code: '+211', iso: 'ss'},
  {country: 'Spain', code: '+34', iso: 'es'},
  {country: 'Sri Lanka', code: '+94', iso: 'lk'},
  {country: 'Sudan', code: '+249', iso: 'sd'},
  {country: 'Suriname', code: '+597', iso: 'sr'},
  {country: 'Svalbard and Jan Mayen', code: '+47', iso: 'sj'},
  {country: 'Swaziland', code: '+268', iso: 'sz'},
  {country: 'Sweden', code: '+46', iso: 'se'},
  {country: 'Switzerland', code: '+41', iso: 'ch'},
  {country: 'Syria', code: '+963', iso: 'sy'},
  {country: 'Taiwan', code: '+886', iso: 'tw'},
  {country: 'Tajikistan', code: '+992', iso: 'tj'},
  {country: 'Tanzania', code: '+255', iso: 'tz'},
  {country: 'Thailand', code: '+66', iso: 'th'},
  {country: 'Togo', code: '+228', iso: 'tg'},
  {country: 'Tokelau', code: '+690', iso: 'tk'},
  {country: 'Tonga', code: '+676', iso: 'to'},
  {country: 'Trinidad and Tobago', code: '+1-868', iso: 'tt'},
  {country: 'Tunisia', code: '+216', iso: 'tn'},
  {country: 'Turkey', code: '+90', iso: 'tr '},
  {country: 'Turkmenistan', code: '+993', iso: 'tm'},
  {country: 'Turks and Caicos Islands', code: '+1-649', iso: 'tc'},
  {country: 'Tuvalu', code: '+688', iso: 'tv'},
  {country: 'U.S. Virgin Islands', code: '+1-340', iso: 'vi'},
  {country: 'Uganda', code: '+256', iso: 'ug'},
  {country: 'Ukraine', code: '+380', iso: 'ua'},
  {country: 'United Arab Emirates', code: '+971', iso: 'ae'},
  {country: 'United Kingdom', code: '+44', iso: 'gb'},
  {country: 'United States', code: '+1', iso: 'us'},
  {country: 'Uruguay', code: '+598', iso: 'uy'},
  {country: 'Uzbekistan', code: '+998', iso: 'uz'},
  {country: 'Vanuatu', code: '+678', iso: 'vu'},
  {country: 'Vatican', code: '+379', iso: 'va'},
  {country: 'Venezuela	', code: '+58', iso: 've'},
  {country: 'Vietnam', code: '+84', iso: 'vn'},
  {country: 'Wallis and Futuna', code: '+681', iso: 'wf'},
  {country: 'Western Sahara', code: '+212', iso: 'eh'},
  {country: 'Yemen', code: '+967', iso: 'ye'},
  {country: 'Zambia', code: '+260', iso: 'zm'},
  {country: 'Zimbabwe', code: '+263', iso: 'zw'}
];
