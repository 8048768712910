<div class="modal-header">
  <h4 class="modal-title">
    {{ ModuleId ? 'Editing Module ID: ' + ModuleId : 'New Module' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="module">
    <div class="row">
      <div class="col-6">
        <h5 class="harmony-teal-text mb-0">Details</h5>
        <hr class="my-1">
      </div>
      <div class="col-6">
        <h5 class="harmony-teal-text mb-0">Child Permissions</h5>
        <hr class="my-1">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row mt-1">
          <div class="col-12" style="line-height: 11px;">
            <input type="text"
                   [ngClass]="{'is-invalid': nameInvalid}"
                   class="form-control form-control-sm"
                   [placeholder]="'Module name e.g. Pricing'"
                   [(ngModel)]="module.Name">
            <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Required. Minimum length of 3 characters.</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <textarea type="text"
              class="form-control form-control-sm"
              [rows]="5"
              [placeholder]="'Module description'"
              [(ngModel)]="module.Description"></textarea>
          </div>
        </div>
      </div>
      <div class="col-6 module-permission-list">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="!module.Permissions || module.Permissions.length <= 0">
              {{ module.Id == null ? 'Save the new module before components can be linked.' : 'Module has no linked components...' }}
            </ng-container>
            <ng-container *ngIf="module.Permissions && module.Permissions.length > 0">
              <div class="row result-row" *ngFor='let component of module.Permissions; let i = index'>
                <div class="col-11 ps-0">
                  {{component.Name}}
                </div>
                <div class="col-1 pointer" *ngIf="component.Description && component.Description.length > 0" [title]="component.Description">
                  <fa-icon [icon]="faInfo" class="harmony-grey-text"></fa-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveModule()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

