import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ICompanyRequestDto } from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';

@Component({
  selector: 'app-review-request-modal',
  templateUrl: './review-request-modal.component.html',
  styleUrls: ['./review-request-modal.component.scss']
})
export class ReviewRequestModalComponent implements OnInit, OnDestroy {
  // Icons
  faDateArrow = faArrowRight;

  // Variables
  public CompanyRequest: ICompanyRequestDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  environment = environment;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  accept() {
    this.activeModal.close(true);
  }

  decline() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
