export enum ERequestType {
  FLC = 'FLC',
  LCL = 'LCL'
}

export enum EContainerType {
  ST20 = 'ST20',
  ST40 = 'ST40',
  HQ40 = 'HQ40',
  HQ45 = 'HQ45'
}

export interface IFclResponse {
  data: IData;
}

export interface IData {
  shipment: IShipment[];
  default: IDefault;
  identity: string;
}

export interface IDefault {
  services: string[];
  bookingViaEmail: boolean;
}

export interface IShipment {
  shipmentId: string;
  transportationMode: string;
  incoterms: string;
  currency: string;
  cityFrom: ILocation;
  cityTo: ILocation;
  portFrom: ILocation;
  portTo: ILocation;
  freight: IFreight[];
  showSection: boolean;
  selected: boolean;
}

export interface ILocation {
  id: number | null;
  name: string;
  code: string;
  countryCode: string;
  lat: number | null;
  lng: number | null;
}

export interface IFreight {
  shippingLine: string;
  logo: string;
  containerCode: string;
  validTo: string | null;
  containerType: string;
  overdue: boolean;
  co2: number;
  quantity: number;
  linerTerms: string;
  originalPrice: number;
  originalCurrency: string;
  price: number;
  distance: string;
  comment: string;
  transitTime: string;
  transportFrom: string;
  transportTo: string;
  alternative: boolean;
  portFeesFrom: IPortFees[];
  portFeesTo: IPortFees[];
  truckFrom: IRailFrom;
  truckTo: IRailFrom;
  railFrom: IRailFrom;
  railTo: IRailFrom;
  dryFrom: string;
  dryTo: string;
  bargeFrom: IBarge;
  bargeTo: IBarge;
}

export interface IBarge {
  price: number | null;
  distance: string | null;
  transitTime: string | null;
  validTo: string | null;
  currency: string | null;
  co2: string | null;
  port: ILocation;
}

export interface IPortFees {
  abbr: string;
  title: string;
  text: string;
  originalPrice: number;
  originalCurrency: string;
  price: number;
  perLot: boolean;
  co2: number;
}

export interface IRailFrom {
  originalPrice: number | null;
  originalCurrency: string | null;
  price: number | null;
  distance: string;
  transitTime: string;
  interpolation: boolean | null;
  co2: number | null;
  comment: string | null;
}


