<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="Incident.IncidentNumber > 0">Incident number: <b>{{ Incident.IncidentNumber }}</b></span>
    <span *ngIf="(Incident.IncidentNumber === 0 || Incident.IncidentNumber == null)">New incident</span>
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body ps-4 pe-4">
  <div class="row">
    <div class="col-12">
      <!--Start tab -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active"
             (click)="changeActiveTab('registration')"
             id="registration-tab"
             data-bs-toggle="tab"
             data-bs-target="#registration"
             type="button"
             role="tab"
             aria-controls="registration"
             aria-selected="true">Registration</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             (click)="changeActiveTab('handling')"
             #handlingTab
             id="handling-tab"
             data-bs-toggle="tab"
             data-bs-target="#handling"
             [class.disabled]="registrationForm.invalid"
             type="button"
             role="tab"
             aria-controls="handling"
             aria-selected="false">Handling</a>
        </li>
        <li class="nav-item" role="presentation"
            *ngIf="causeInvestigationTabVisible()">
          <a class="nav-link"
             (click)="changeActiveTab('causeinvestigation')"
             id="causeinvestigation-tab"
             data-bs-toggle="tab"
             data-bs-target="#causeinvestigation"
             [class.disabled]="handlingForm.invalid"
             *ngIf="true"
             type="button"
             role="tab"
             aria-controls="causeinvestigation"
             aria-selected="false">Cause investigation</a>
        </li>
        <li class="nav-item" role="presentation"
            *ngIf="analysisTabVisible()">
          <a class="nav-link"
             (click)="changeActiveTab('analysis')"
             id="analysis-tab"
             data-bs-toggle="tab"
             data-bs-target="#analysis"
             [class.disabled]="handlingForm.invalid"
             type="button"
             role="tab"
             aria-controls="analysis"
             aria-selected="false">Analysis</a>
        </li>
        <li class="nav-item" role="presentation"
            *ngIf="effectivenessTabVisible()">
          <a class="nav-link"
             (click)="changeActiveTab('effectiveness')"
             id="effectiveness-tab"
             data-bs-toggle="tab"
             data-bs-target="#effectiveness"
             *ngIf="tabEffectivenessIsHidden"
             type="button"
             role="tab"
             aria-controls="effectiveness"
             aria-selected="false">Effectiveness</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <!--------------------------->
        <!--  Part Registration ----->
        <!--------------------------->
        <div class="tab-pane fade show active" id="registration" role="tabpanel" aria-labelledby="registration-tab">
          <form [formGroup]="registrationForm" #registrationFormElement="ngForm"
                [formValidationStyle]="registrationForm" [useValidation]=true>
            <div class="row mt-4">
              <div class="col-4">
                <b>Level <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <ng-container *ngIf="this.hasLevelPermission; else nonAdminLevel">
                  <select class="form-select form-select-sm pointer"
                          appAutocompleteOff
                          [ngClass]="{'is-invalid': registrationForm.controls.IncidentLevel.touched && registrationForm.controls.IncidentLevel.invalid }"
                          [formControlName]="'IncidentLevel'">
                    <option value="null">Select an incident level. This field is required.</option>
                    <option *ngFor="let level of hseService.IncidentLevel"
                            [ngValue]="level.IncidentLevelId">{{ level.Description }}</option>
                  </select>
                </ng-container>
                <ng-template #nonAdminLevel>
                  <div><span>{{ this.hseService.findLevelDescription('Member') }}</span></div>
                </ng-template>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4 ">
                <b>Company <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <ng-container *ngIf="hasCompanyFilterPermission; else nonAdminCompany">
                  <select class="form-select form-select-sm pointer"
                          appAutocompleteOff
                          [ngClass]="{'is-invalid': registrationForm.controls.Company.touched && registrationForm.controls.Company.invalid}"
                          (change)="companyChange()"
                          [formControlName]="'Company'">
                    <option [ngValue]="null">Select a company. This field is required.</option>
                    <option *ngFor="let company of (companies$ | async)"
                            [ngValue]="company.CompanyId"
                            [selected]="company.CompanyId === authService.CurrentUser.User.CompanyId">{{ company.CompanyName }}</option>
                  </select>
                </ng-container>
                <ng-template #nonAdminCompany>
                  <div>
                    <span>{{ hseService.findCompanyName(authService.CurrentUser.User.CompanyId) }}</span>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Discipline <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': registrationForm.controls.RegistrationDiscipline.touched && registrationForm.controls.RegistrationDiscipline.invalid}"
                        [formControlName]="'RegistrationDiscipline'"
                        (change)="registrationDisciplineChanged($event)">
                  <option [ngValue]="null" selected >Select a discipline. This field is required.</option>
                  <option *ngFor="let discipline of hseService.RegistrationDiscipline"
                          [ngValue]="discipline.DisciplineId">{{ discipline.Description }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Registration Type <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': registrationForm.controls.RegistrationType.touched && registrationForm.controls.RegistrationType.invalid}"
                        [formControlName]="'RegistrationType'"
                        (change)="registrationTypeChanged($event)">
                  <option [ngValue]="null">Select a registration type. This field is required.</option>
                  <option *ngFor="let type of filterItemsOfDiscipline(registrationForm.controls.RegistrationDiscipline.value)"
                          [value]="type.RegistrationTypeId">
                    {{ type.Description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Source <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': registrationForm.controls.RegistrationSource.touched && registrationForm.controls.RegistrationSource.invalid}"
                        [formControlName]="'RegistrationSource'"
                        (change)="registrationSourceChanged($event)">
                  <option [ngValue]="null">Select a source. This field is required.</option>
                  <option *ngFor="let source of hseService.RegistrationSource"
                          [value]="source.RegistrationSourceId">{{ source.Description }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Incident Date <span class="text-danger">*</span></b>
              </div>
              <div class="col-8">
                <app-date-picker [identifier]="'codDatepicker'"
                                 (onChange)="setRegistrationDate($event)"
                                 [value]="Incident.RegistrationDate"
                                 [showError]="registrationForm.controls.RegistrationDate.touched && registrationForm.controls.RegistrationDate.invalid"
                                 [placeholder]="'Select a date'">
                </app-date-picker>
              </div>
              <ng-container *ngIf="showInvalidRegistrationDateText">
                <small class="text-danger me-4">
                  Please select a registration date with the datepicker
                </small>
              </ng-container>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Reported By <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <input type="text"
                       class="form-control form-control-sm"
                       placeholder="Enter a value. This field is required"
                       appAutocompleteOff
                       [ngClass]="{'is-invalid': registrationForm.controls.RegistrationReportedBy.touched && registrationForm.controls.RegistrationReportedBy.invalid}"
                       [formControlName]="'RegistrationReportedBy'">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Assigned To</b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        appAutocompleteOff
                        [formControlName]="'AssignedUserId'">
                  <option [ngValue]="null">Select a user...</option>
                  <option *ngFor="let user of (users$ | async)"
                          [ngValue]="user.Id"
                          [selected]="user.Id === Incident.AssignedUserId">{{ user.DisplayName }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Description <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                  <textarea class="form-control form-control-sm"
                            rows="4"
                            style="width:100%;"
                            placeholder="Enter a description. This field is required with a minimum length of 3 characters."
                            appAutocompleteOff
                            [ngClass]="{'is-invalid': registrationForm.controls.IncidentDescription.touched && registrationForm.controls.IncidentDescription.invalid}"
                            [formControlName]="'IncidentDescription'">
                  </textarea>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Probable cause</b>
              </div>
              <div class="col-8 overflow-hidden">
                  <textarea class="form-control form-control-sm"
                            appAutocompleteOff
                            [formControlName]="'IncidentProbableCause'"
                            rows="4"
                            style="width:100%;">
                  </textarea>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Suggested action</b>
              </div>
              <div class="col-8 overflow-hidden">
                  <textarea class="form-control form-control-sm"
                            appAutocompleteOff
                            [formControlName]="'IncidentSuggestedAction'"
                            rows="4"
                            style="width:100%;">
                  </textarea>
              </div>
            </div>
          </form>
          <!----------------------------------------------------------------------------------------------->
          <!-- Additional Form shown when Registration Type and Registration Source is "Incident Report" -->
          <!----------------------------------------------------------------------------------------------->
          <ng-container *ngIf="(Incident.RegistrationTypeId == 5) && (Incident.RegistrationSourceId == 5)">
            <div class="row mt-3">
              <div class="col-12">
                <div class="form-group row mt-4 mb-4 ms-2 me-2">
                  <mark class="px-4 py-2">
                  <span>
                    Any accident that according to national legislation must be reported to authorities must also be
                    specifically reported to the Certifying Body, as this is a specific certification requirement.
                  </span>
                  </mark>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <form [formGroup]="incidentReportForm"
                      [formValidationStyle]="incidentReportForm"
                      [useValidation]=true>
                  <div class="row mb-0">
                    <legend class="col-form-label col-8 pt-0 border-bottom">
                      <b>Incident type</b>
                    </legend>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <ng-container *ngIf="showInvalidInvalidIncidentText">
                        <small class="text-danger me-4">
                          Minimum of 1 selection required
                        </small>
                      </ng-container>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="AccidentHospitalisation"
                               [formControlName]="'AccidentHospitalisation'"
                               type="checkbox">
                        <label for="AccidentHospitalisation"
                               class="form-check-label">
                          Accident + Hospitalisation
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="AccidentSickLeave"
                               [formControlName]="'AccidentSickLeave'"
                               type="checkbox">
                        <label for="AccidentSickLeave"
                               class="form-check-label">
                          Accident + Sick Leave
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="AccidentFirstAid"
                               [formControlName]="'AccidentFirstAid'"
                               type="checkbox">
                        <label for="AccidentFirstAid"
                               class="form-check-label">
                          Accident + First Aid
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="MaterialDamageLossOfProduction"
                               [formControlName]="'MaterialDamageLossOfProduction'"
                               type="checkbox">
                        <label for="MaterialDamageLossOfProduction"
                               class="form-check-label">
                          Material Damage / Loss Of Production
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="NearAccident"
                               [formControlName]="'NearAccident'"
                               type="checkbox">
                        <label for="NearAccident"
                               class="form-check-label">
                          Near Accident
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               id="PsychoSocialIncidents"
                               [formControlName]="'PsychoSocialIncidents'"
                               type="checkbox">
                        <label for="PsychoSocialIncidents"
                               class="form-check-label">
                          Psychosocial incidents
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-6">
                <form [formGroup]="injuryDamageTypeForm"
                      #injuryDamageTypeFormElement="ngForm"
                      [formValidationStyle]="injuryDamageTypeForm"
                      [useValidation]=true>
                  <div class="row mb-0">
                    <legend class="col-form-label col-8 pt-0 border-bottom">
                      <b>Injury / Damage type</b>
                    </legend>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <ng-container *ngIf="showInvalidDamageTypeText">
                        <small class="text-danger me-4">
                          Minimum of 1 selection required
                        </small>
                      </ng-container>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeCut'"
                               id="injuryDamageTypeCut"
                               type="checkbox">
                        <label for="injuryDamageTypeCut"
                               class="form-check-label">
                          Cut
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeGraze'"
                               id="injuryDamageTypeGraze"
                               type="checkbox">
                        <label for="injuryDamageTypeGraze"
                               class="form-check-label">
                          Graze
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeBurn'"
                               id="injuryDamageTypeBurn"
                               type="checkbox">
                        <label for="injuryDamageTypeBurn"
                               class="form-check-label">
                          Burn
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeSprain'"
                               id="injuryDamageTypeSprain"
                               type="checkbox">
                        <label for="injuryDamageTypeSprain"
                               class="form-check-label">
                          Sprain
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeContusion'"
                               id="injuryDamageTypeContusion"
                               type="checkbox">
                        <label for="injuryDamageTypeContusion"
                               class="form-check-label">
                          Contusion
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeFracture'"
                               id="injuryDamageTypeFracture"
                               type="checkbox">
                        <label for="injuryDamageTypeFracture"
                               class="form-check-label">
                          Fracture
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeIntoxication'"
                               id="injuryDamageTypeIntoxication"
                               type="checkbox">
                        <label for="injuryDamageTypeIntoxication"
                               class="form-check-label">
                          Intoxication
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeSuffocation'"
                               id="injuryDamageTypeSuffocation"
                               type="checkbox">
                        <label for="injuryDamageTypeSuffocation"
                               class="form-check-label">
                          Suffocation
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeDrowning'"
                               id="injuryDamageTypeDrowning"
                               type="checkbox">
                        <label for="injuryDamageTypeDrowning"
                               class="form-check-label">
                          Drowning
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeStressConflict'"
                               id="injuryDamageTypeStressConflict"
                               type="checkbox">
                        <label for="injuryDamageTypeStressConflict"
                               class="form-check-label">
                          Short or long term effect of stress, conflict or violence
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageTypeOther'"
                               id="injuryDamageTypeOther"
                               type="checkbox">
                        <label for="injuryDamageTypeOther"
                               class="form-check-label">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <form [formGroup]="injuryDamageToForm">
                  <div class="row mb-0">
                    <legend class="col-form-label col-8 pt-0 border-bottom">
                      <b>Injury / Damage to</b>
                    </legend>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <ng-container *ngIf="showInvalidDamageToText">
                        <small class="text-danger me-4">
                          Minimum of 1 selection required
                        </small>
                      </ng-container>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToHead'"
                               id="injuryDamageToHead"
                               type="checkbox">
                        <label for="injuryDamageToHead"
                               class="form-check-label">Head</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToTorso'"
                               id="injuryDamageToTorso"
                               type="checkbox">
                        <label for="injuryDamageToTorso"
                               class="form-check-label">Torso</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToArmHand'"
                               id="injuryDamageToArmHand"
                               type="checkbox">
                        <label for="injuryDamageToArmHand"
                               class="form-check-label">Arm / Hand</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToLegFoot'"
                               id="injuryDamageToLegFoot"
                               type="checkbox">
                        <label for="injuryDamageToLegFoot"
                               class="form-check-label">Leg / Foot</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToEyes'"
                               id="injuryDamageToEyes"
                               type="checkbox">
                        <label for="injuryDamageToEyes"
                               class="form-check-label">Eye(s)</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToInternal'"
                               id="injuryDamageToInternal"
                               type="checkbox">
                        <label for="injuryDamageToInternal"
                               class="form-check-label">Internal</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'DamageToOther'"
                               id="injuryDamageToOther"
                               type="checkbox">
                        <label for="injuryDamageToOther"
                               class="form-check-label">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-6">
                <form [formGroup]="injuryCauseForm">
                  <div class="row mb-0">
                    <legend class="col-form-label col-8 pt-0 border-bottom">
                      <b>Injury cause</b>
                    </legend>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <ng-container *ngIf="showInvalidInjuryCauseText">
                        <small class="text-danger me-4">
                          Minimum of 1 selection required
                        </small>
                      </ng-container>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseCutSting'"
                               id="injuryCauseCutSting"
                               type="checkbox">
                        <label for="injuryCauseCutSting"
                               class="form-check-label">Cut Sting</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseBump'"
                               id="injuryCauseBump"
                               type="checkbox">
                        <label for="injuryCauseBump"
                               class="form-check-label">Bump</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CausePinch'"
                               id="injuryCausePinch"
                               type="checkbox">
                        <label for="injuryCausePinch"
                               class="form-check-label">Pinch</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseStumble'"
                               id="injuryCauseStumble"
                               type="checkbox">
                        <label for="injuryCauseStumble"
                               class="form-check-label">Stumble</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseFall'"
                               id="injuryCauseFall"
                               type="checkbox">
                        <label for="injuryCauseFall"
                               class="form-check-label">Fall</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseBeingHit'"
                               id="injuryCauseBeingHit"
                               type="checkbox">
                        <label for="injuryCauseBeingHit"
                               class="form-check-label">Being Hit</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseCauseBurn'"
                               id="injuryCauseCauseBurn"
                               type="checkbox">
                        <label for="injuryCauseCauseBurn"
                               class="form-check-label">Cause Burn</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseInhalation'"
                               id="injuryCauseInhalation"
                               type="checkbox">
                        <label for="injuryCauseInhalation"
                               class="form-check-label">Inhalation</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseElectrocution'"
                               id="injuryCauseElectrocution"
                               type="checkbox">
                        <label for="injuryCauseElectrocution"
                               class="form-check-label">Electrocution</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"
                               [formControlName]="'CauseOther'"
                               id="causeOther"
                               type="checkbox">
                        <label for="causeOther"
                               class="form-check-label">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="peopleInvolvedForm"
                      #peopleInvolvedFormElement="ngForm"
                      [formValidationStyle]="peopleInvolvedForm"
                      [useValidation]=true>
                  <div class="row mt-2">
                    <legend class="col-form-label col-12 border-bottom">
                      <b>People involved</b>
                    </legend>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="form-group row">
                        <label for="peopleInvolvedVictimName"
                               class="col-sm-4 col-form-label">Victim name or reference</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedVictimName.touched && peopleInvolvedForm.controls.InvolvedVictimName.invalid}"
                                 [formControlName]="'InvolvedVictimName'"
                                 id="peopleInvolvedVictimName"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedVictimAge"
                               class="col-sm-4 col-form-label">Victim Age</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedVictimAge.touched && peopleInvolvedForm.controls.InvolvedVictimAge.invalid}"
                                 [formControlName]="'InvolvedVictimAge'"
                                 id="peopleInvolvedVictimAge"
                                 numbersOnly="3.1"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedJobTitle"
                               class="col-sm-4 col-form-label">Job Title</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedJobTitle.touched && peopleInvolvedForm.controls.InvolvedJobTitle.invalid}"
                                 [formControlName]="'InvolvedJobTitle'"
                                 id="peopleInvolvedJobTitle"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedJobExperience"
                               class="col-sm-4 col-form-label">Job Experience (years):</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedJobExperience.touched && peopleInvolvedForm.controls.InvolvedJobExperience.invalid}"
                                 [formControlName]="'InvolvedJobExperience'"
                                 id="peopleInvolvedJobExperience"
                                 numbersOnly="2.1"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedJobTraining"
                               class="col-sm-4 col-form-label">Job Training/Diploma's</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedJobTraining.touched && peopleInvolvedForm.controls.InvolvedJobTraining.invalid}"
                                 placeholder="This field is required with a minimum length of 3 characters."
                                 [formControlName]="'InvolvedJobTraining'"
                                 id="peopleInvolvedJobTraining"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedSafetyTraining"
                               class="col-sm-4 col-form-label">Safety Training/Diploma's</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [ngClass]="{'is-invalid': peopleInvolvedForm.controls.InvolvedSafetyTraining.touched && peopleInvolvedForm.controls.InvolvedSafetyTraining.invalid}"
                                 placeholder="This field is required with a minimum length of 3 characters."
                                 [formControlName]="'InvolvedSafetyTraining'"
                                 id="peopleInvolvedSafetyTraining"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedFirstAid"
                               class="col-sm-4 col-form-label">First Aid By</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [formControlName]="'InvolvedFirstAid'"
                                 id="peopleInvolvedFirstAid"
                                 type="text">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="peopleInvolvedWitness"
                               class="col-sm-4 col-form-label">Witness(es)</label>
                        <div class="col-sm-8 mb-2">
                          <input class="form-control form-control-sm"
                                 [formControlName]="'InvolvedWitness'"
                                 id="peopleInvolvedWitness"
                                 type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <legend class="col-form-label col-12 mt-2 border-bottom">
                      <b>Other</b>
                    </legend>
                  </div>
                  <div class="row mt-2">
                    <label for="otherNrSickDays"
                           class="col-sm-4 col-form-label">Sick Days</label>
                    <div class="col-sm-8">
                      <input class="form-control form-control-sm"
                             [ngClass]="{'is-invalid': peopleInvolvedForm.controls.NrSickDays.touched && peopleInvolvedForm.controls.NrSickDays.invalid}"
                             placeholder="Enter the number of sick days. This field is required."
                             [formControlName]="'NrSickDays'"
                             id="otherNrSickDays"
                             numbersOnly="3.0"
                             type="text">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-container>
        </div>
        <!--------------------------->
        <!--  Part Handling      -->
        <!--------------------------->
        <div class="tab-pane fade" id="handling" role="tabpanel" aria-labelledby="handling-tab">
          <form [formGroup]="handlingForm" #handlingFormElement="ngForm" [formValidationStyle]="handlingForm"
                [useValidation]=true>
            <div class="row mt-4">
              <div class="col-4">
                <b>Severity <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': handlingForm.controls.Severity.touched && handlingForm.controls.Severity.invalid }"
                        [formControlName]="'Severity'">
                  <option [ngValue]="null">None...</option>
                  <option
                    *ngFor="let severity of filterItemsOfSeverity()"
                    [ngValue]="severity.SeverityId"> {{ severity.Description }} </option>
                </select>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-4">
                <b>Action By</b>
              </div>
              <div class="col-8 overflow-hidden">
                <input type="text"
                       appAutocompleteOff
                       class="form-control form-control-sm"
                       [formControlName]="'ActionBy'">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-4">
                <b>Action Deadline <span class="text-danger">*</span></b>
              </div>
              <div class="col-8">
                <app-date-picker [identifier]="'adlDatepicker'"
                                 (onChange)="setActionDeadLineDate($event)"
                                 [value]="Incident.ActionDeadline"
                                 [showError]="handlingForm.controls.ActionDeadLine.touched && handlingForm.controls.ActionDeadLine.invalid"
                                 [placeholder]="'Select a date'">
                </app-date-picker>
              </div>
              <ng-container *ngIf="showInvalidActionDeadlineText">
                <small class="text-danger me-4">
                  Please select a action deadline date with the datepicker
                </small>
              </ng-container>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Action Taken</b>
              </div>
              <div class="col-8 overflow-hidden">
                  <textarea class="form-control form-control-sm"
                            appAutocompleteOff
                            [formControlName]="'ActionTaken'"
                            rows="4"
                            style="width:100%;">
                  </textarea>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Handling Remarks</b>
              </div>
              <div class="col-8 overflow-hidden">
                  <textarea class="form-control form-control-sm"
                            appAutocompleteOff
                            [formControlName]="'HandlingRemarks'"
                            rows="4"
                            style="width:100%;">
                  </textarea>
              </div>
            </div>
          </form>
        </div>
        <!--------------------------------->
        <!--  Part Cause investigation -->
        <!--------------------------------->
        <div class="tab-pane fade" id="causeinvestigation"
             *ngIf="true"
             role="tabpanel" aria-labelledby="causeinvestigation-tab">
          <form [formGroup]="causeInvestigationForm" #causeInvestigationFormElement="ngForm"
                [formValidationStyle]="causeInvestigationForm" [useValidation]=true>
            <div class="form-row mt-4">
              <div class="col-12">
                <b>HOW did the incident happen (the obvious occurrence of the incident)?</b>
              </div>
              <div class="col-12">
                <input type="text"
                       class="form-control form-control-sm"
                       [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationHow.touched && causeInvestigationForm.controls.CauseinvestigationHow.invalid }"
                       [formControlName]="'CauseinvestigationHow'">
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-12">
                <b>WHY could the incident happen as described?</b>
              </div>
              <div class="col-12">
                <input type="text"
                       class="form-control form-control-sm"
                       [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationWhy.touched && causeInvestigationForm.controls.CauseinvestigationWhy.invalid }"
                       [formControlName]="'CauseinvestigationWhy'">
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-12">
                <b>WHAT is the underlying cause for this to have happened?</b>
              </div>
              <div class="col-12">
                <input type="text"
                       class="form-control form-control-sm"
                       [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationWhat.touched && causeInvestigationForm.controls.CauseinvestigationWhat.invalid }"
                       [formControlName]="'CauseinvestigationWhat'">
              </div>
            </div>
            <div class="form-row mt-3 border-bottom">
              <span class="col-form-label pt-0">
                <b>Could any of the following be CONTRIBUTING FACTORS to the incident</b>
              </span>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Machines or equipment (malfunctioning, poor maintenance, or wrongly deployed)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMachinesYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMachines.touched && causeInvestigationForm.controls.CauseinvestigationMachines.invalid }"
                         [checked]="Incident.CauseinvestigationMachinesOrEquipment === true"
                         (change)="Incident.CauseinvestigationMachinesOrEquipment = true"
                         [value]="true"
                         [formControlName]="'CauseinvestigationMachines'">
                  <label class="form-check-label" for="CauseinvestigationMachinesYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMachinesNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMachines.touched && causeInvestigationForm.controls.CauseinvestigationMachines.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationMachinesOrEquipment === false"
                         (change)="Incident.CauseinvestigationMachinesOrEquipment = false"
                         [formControlName]="'CauseinvestigationMachines'">
                  <label class="form-check-label" for="CauseinvestigationMachinesNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Materials (quality, quantity or availability of materials)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMaterialsYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMaterials.touched && causeInvestigationForm.controls.CauseinvestigationMaterials.invalid }"
                         [value]="true"
                         [checked]="Incident.CauseinvestigationMaterials === true"
                         (change)="Incident.CauseinvestigationMaterials = true"
                         [formControlName]="'CauseinvestigationMaterials'">
                  <label class="form-check-label" for="CauseinvestigationMaterialsYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMaterialsNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMaterials.touched && causeInvestigationForm.controls.CauseinvestigationMaterials.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationMaterials === false"
                         (change)="Incident.CauseinvestigationMaterials = false"
                         [formControlName]="'CauseinvestigationMaterials'">
                  <label class="form-check-label" for="CauseinvestigationMaterialsNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Measurements (wrong, insufficient or no information of goods or equipment)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMeasurementsYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMeasurements.touched && causeInvestigationForm.controls.CauseinvestigationMeasurements.invalid }"
                         [value]="true"
                         [checked]="Incident.CauseinvestigationMeasurements === true"
                         (change)="Incident.CauseinvestigationMeasurements = true"
                         [formControlName]="'CauseinvestigationMeasurements'">
                  <label class="form-check-label" for="CauseinvestigationMeasurementsYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMeasurementsNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMeasurements.touched && causeInvestigationForm.controls.CauseinvestigationMeasurements.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationMeasurements === false"
                         (change)="Incident.CauseinvestigationMeasurements = false"
                         [formControlName]="'CauseinvestigationMeasurements'">
                  <label class="form-check-label" for="CauseinvestigationMeasurementsNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Methods (wrong, insufficient or no instructions or procedures)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMethodsYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMethods.touched && causeInvestigationForm.controls.CauseinvestigationMethods.invalid }"
                         [value]="true"
                         [checked]="Incident.CauseinvestigationMethods === true"
                         (change)="Incident.CauseinvestigationMethods = true"
                         [formControlName]="'CauseinvestigationMethods'">
                  <label class="form-check-label" for="CauseinvestigationMethodsYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationMethodsNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationMethods.touched && causeInvestigationForm.controls.CauseinvestigationMethods.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationMethods === false"
                         (change)="Incident.CauseinvestigationMethods = false"
                         [formControlName]="'CauseinvestigationMethods'">
                  <label class="form-check-label" for="CauseinvestigationMethodsNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Environment (weather, temperature or other external factors)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationEnvironmentYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationEnvironment.touched && causeInvestigationForm.controls.CauseinvestigationEnvironment.invalid }"
                         [value]="true"
                         [checked]="Incident.CauseinvestigationEnvironment === true"
                         (change)="Incident.CauseinvestigationEnvironment = true"
                         [formControlName]="'CauseinvestigationEnvironment'">
                  <label class="form-check-label" for="CauseinvestigationEnvironmentYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationEnvironmentNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationEnvironment.touched && causeInvestigationForm.controls.CauseinvestigationEnvironment.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationEnvironment === false"
                         (change)="Incident.CauseinvestigationEnvironment = false"
                         [formControlName]="'CauseinvestigationEnvironment'">
                  <label class="form-check-label" for="CauseinvestigationEnvironmentNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-2">
              <div class="col-8">
                Personnel (insufficient skills, poor judgement, reprehensible behaviour, honest mistake)
              </div>
              <div class="col-4">
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationPersonnelYes"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationPersonnel.touched && causeInvestigationForm.controls.CauseinvestigationPersonnel.invalid }"
                         [value]="true"
                         [checked]="Incident.CauseinvestigationPersonnel === true"
                         (change)="Incident.CauseinvestigationPersonnel = true"
                         [formControlName]="'CauseinvestigationPersonnel'">
                  <label class="form-check-label" for="CauseinvestigationPersonnelYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"
                         type="radio"
                         id="CauseinvestigationPersonnelNo"
                         [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationPersonnel.touched && causeInvestigationForm.controls.CauseinvestigationPersonnel.invalid }"
                         [value]="false"
                         [checked]="Incident.CauseinvestigationPersonnel === false"
                         (change)="Incident.CauseinvestigationPersonnel = false"
                         [formControlName]="'CauseinvestigationPersonnel'">
                  <label class="form-check-label" for="CauseinvestigationPersonnelNo">No</label>
                </div>
              </div>
            </div>
            <div class="form-row row mt-3">
              <div class="col-12">
                <b>CONCLUSION as to the cause of the incident</b>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <textarea class="form-control form-control-sm"
                          appAutocompleteOff
                          [formControlName]="'CauseinvestigationConclusion'"
                          [ngClass]="{'is-invalid': causeInvestigationForm.controls.CauseinvestigationConclusion.touched && causeInvestigationForm.controls.CauseinvestigationConclusion.invalid }"
                          rows="4"
                          style="width:100%;">
                </textarea>
              </div>
            </div>
          </form>
        </div>
        <!------------------->
        <!-- Part Analysis -->
        <!------------------->
        <div class="tab-pane fade" id="analysis" role="tabpanel" aria-labelledby="analysis-tab">
          <form [formGroup]="analysisForm" #analysisFormElement="ngForm" [formValidationStyle]="analysisForm"
                [useValidation]=true>
            <div class="row mt-4">
              <div class="col-4">
                <b>Status <span class="text-danger">*</span></b>
              </div>
              <div class="col-8">
                <div *ngIf="authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_Analysis); else nonAdminAnalysisStatus">
                  <select class="form-select form-select-sm pointer"
                          title="AnalysisStatus"
                          [ngClass]="{'is-invalid': analysisForm.controls.AnalysisStatus.touched && analysisForm.controls.AnalysisStatus.invalid }"
                          [formControlName]="'AnalysisStatus'">
                    <option [ngValue]="0">None...</option>
                    <option *ngFor="let status of hseService.AnalysisStatus"
                            [ngValue]="status.AnalysisStatusId">{{ status.Description }}</option>
                  </select>
                </div>
                <ng-template #nonAdminAnalysisStatus>
                  <div><span>{{ hseService.findAnalysisDescription(Incident.AnalysisStatusId) }}</span></div>
                </ng-template>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Action Finished <span class="text-danger">*</span></b>
              </div>
              <div class="col-8">
                <app-date-picker [identifier]="'aafDatepicker'"
                                 (onChange)="setActionFinishedDate($event)"
                                 [value]="Incident.AnalysisActionFinished"
                                 [showError]="analysisForm.controls.AnalysisActionFinished.touched && analysisForm.controls.AnalysisActionFinished.invalid"
                                 [placeholder]="'Select a date'">
                </app-date-picker>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Approved By <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <input type="text"
                       class="form-control form-control-sm"
                       [ngClass]="{'is-invalid': analysisForm.controls.AnalysisApprovedBy.touched && analysisForm.controls.AnalysisApprovedBy.invalid }"
                       [formControlName]="'AnalysisApprovedBy'">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Cost</b>
              </div>
              <div class="col-3 overflow-hidden">
                <input type="text"
                       numbersOnly="6.2"
                       class="form-control form-control-sm"
                       [formControlName]="'Cost'">
              </div>
              <div class="col-2">
                <b>Currency</b>
              </div>
              <div class="col-3 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        [formControlName]="'Currency3ISO'">
                  <option [ngValue]="null">None...</option>
                  <option *ngFor="let curr of hseService.Currency"
                          [ngValue]="curr.Id">{{ curr.Code + ': ' + curr.Name }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Analysis 1: occurrence <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        [ngClass]="{'is-invalid': analysisForm.controls.AnalysisCauseOccurrence.touched && analysisForm.controls.AnalysisCauseOccurrence.invalid }"
                        [formControlName]="'AnalysisCauseOccurrence'">
                  <option [ngValue]="null">None...</option>
                  <option *ngFor="let occ of hseService.AnalysisCauseOccurrence"
                          [ngValue]="occ.AnalysisCauseOccurrenceId">{{ occ.Description }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Analysis 2: factor <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        (ngModelChange)="Incident.AnalysisCauseCategory2Id = analysisForm.controls.AnalysisCauseFactor.value; filterItemsOfCauseSpecific(Incident.AnalysisCauseCategory2Id)"
                        [ngClass]="{'is-invalid': analysisForm.controls.AnalysisCauseFactor.touched && analysisForm.controls.AnalysisCauseFactor.invalid }"
                        [formControlName]="'AnalysisCauseFactor'">
                  <option [ngValue]="null">None...</option>
                  <option *ngFor="let factor of hseService.AnalysisCauseFactor"
                          [ngValue]="factor.AnalysisCauseFactorId">{{ factor.Description }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Analysis 3: specific <span class="text-danger">*</span></b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        [ngClass]="{'is-invalid': analysisForm.controls.AnalysisCauseSpecific.touched && analysisForm.controls.AnalysisCauseSpecific.invalid }"
                        [formControlName]="'AnalysisCauseSpecific'">
                  <option [ngValue]="null">None...</option>
                  <option *ngFor="let cause of analysisCauseSpecificationOptions"
                          [ngValue]="cause.AnalysisCauseSpecificId">{{ cause.Description }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Action *</b>
              </div>
              <div class="col-8 overflow-hidden">
                <select class="form-select form-select-sm pointer"
                        [ngClass]="{'is-invalid': analysisForm.controls.AnalysisAction.touched && analysisForm.controls.AnalysisAction.invalid }"
                        [formControlName]="'AnalysisAction'">
                  <option [ngValue]="null">None...</option>
                  <option *ngFor="let action of hseService.AnalysisAction"
                          [ngValue]="action.AnalysisActionId">{{ action.Description }}</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <!--------------------------->
        <!--  Part Effectiveness ---->
        <!--------------------------->
        <div class="tab-pane fade" id="effectiveness"
             *ngIf="tabEffectivenessIsHidden"
             role="tabpanel" aria-labelledby="effectiveness-tab">
          <form [formGroup]="effectivenessForm" #effectivenessFormElement="ngForm"
                [formValidationStyle]="effectivenessForm" [useValidation]=true>
            <div class="row mt-4">
              <div class="col-4">
                <b>Measure effective</b>
              </div>
              <div class="col-8">
                <select class="form-select form-select-sm pointer"
                        title="Measure effective"
                        [ngClass]="{'is-invalid': effectivenessForm.controls.MeasureEffective.touched && effectivenessForm.controls.MeasureEffective.invalid}"
                        [formControlName]="'MeasureEffective'">
                  <option [ngValue]="null">None...</option>
                  <option [ngValue]=true [selected]="Incident.MeasureEffective===true">Yes</option>
                  <option [ngValue]=false [selected]="Incident.MeasureEffective===false">No</option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Elaborate</b>
              </div>
              <div class="col-8">
                <input type="text"
                       class="form-control form-control-sm"
                       placeholder="This field is required with a minimum length of 3 characters."
                       [ngClass]="{'is-invalid': effectivenessForm.controls.MeasureEffectiveElaborate.touched && effectivenessForm.controls.MeasureEffectiveElaborate.invalid}"
                       [formControlName]="'MeasureEffectiveElaborate'">
              </div>
            </div>
            <div *ngIf="Incident.IncidentLevelId==3">
              <div class="row mt-2">
                <div class="col-4">
                  <b>Group measure</b>
                </div>
                <div class="col-8">
                  <input type="text"
                         class="form-control form-control-sm"
                         placeholder="This field is required with a minimum length of 3 characters."
                         [ngClass]="{'is-invalid': effectivenessForm.controls.GroupMeasure.touched && effectivenessForm.controls.GroupMeasure.invalid}"
                         [formControlName]="'GroupMeasure'">
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4">
                  <b>Elaborate</b>
                </div>
                <div class="col-8">
                  <input type="text"
                         class="form-control form-control-sm"
                         placeholder="This field is required with a minimum length of 3 characters."
                         [ngClass]="{'is-invalid': effectivenessForm.controls.GroupMeasureElaborate.touched && effectivenessForm.controls.GroupMeasureElaborate.invalid}"
                         [formControlName]="'GroupMeasureElaborate'">
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Approved by</b>
              </div>
              <div class="col-8">
                <input type="text"
                       class="form-control form-control-sm"
                       placeholder="This field is required with a minimum length of 3 characters."
                       [ngClass]="{'is-invalid': effectivenessForm.controls.EffectivenessApprovedBy.touched && effectivenessForm.controls.EffectivenessApprovedBy.invalid}"
                       [formControlName]="'EffectivenessApprovedBy'">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Date</b>
              </div>
              <div class="col-8">
                <app-date-picker [identifier]="'effDatepicker'"
                                 (onChange)="setEffectivenessDate($event)"
                                 [value]="Incident.EffectiveDate"
                                 [showError]="effectivenessForm.controls.EffectivenessDate.touched && effectivenessForm.controls.EffectivenessDate.invalid"
                                 [placeholder]="'Select a date'">
                </app-date-picker>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b>Status</b>
              </div>
              <div class="col-8">
                <select class="form-select form-select-sm pointer"
                        title="Status"
                        [value]="Incident.EffectiveStatus"
                        [ngClass]="{'is-invalid': effectivenessForm.controls.EffectivenessStatus.touched && effectivenessForm.controls.EffectivenessStatus.invalid}"
                        [formControlName]="'EffectivenessStatus'">
                  <option [ngValue]="null">None...</option>
                  <option [ngValue]=false [selected]="Incident.EffectiveStatus===false">Effective open</option>
                  <option [ngValue]=true [selected]="Incident.EffectiveStatus===true">Effective closed</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--End tab -->
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          *ngIf="activeTab == 'registration'"
          class="btn btn-primary harmony-purple-button me-2"
          (click)="validateRegistrationNext()">
    Next
  </button>
  <button type="button"
          *ngIf="activeTab != 'registration'"
          class="btn btn-primary harmony-purple-button me-2"
          (click)="saveIncident()">
    Save
  </button>
  <button type="button"
          class="btn btn-primary harmony-teal-button me-2"
          (click)="cancel()">
    Close
  </button>
</div>
