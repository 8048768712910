<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
    <div class="dashboard-content">
      <!-- For each card create below div with card component inside-->
      <app-dashboard-card-incident-improvement *ngIf="incidentImprovement" class="dashboard-card" [column]="2" [row]="1"></app-dashboard-card-incident-improvement>
      <app-dashboard-card-pulse-chart *ngIf="pulseChart" class="dashboard-card" [column]="1" [row]="2"></app-dashboard-card-pulse-chart>
      <app-dashboard-hse-articles class="dashboard-card" [column]="1" [row]="5" [categories]="['HSE','News']"></app-dashboard-hse-articles>
      <app-dashboard-card-health-safety *ngIf="riskAssessment" class="dashboard-card" [column]="1" [row]="1"></app-dashboard-card-health-safety>
      <app-dashboard-card-environment  *ngIf="environmentalAssessment" class="dashboard-card" [column]="1" [row]="1"></app-dashboard-card-environment>
      <app-dashboard-card-company-chart *ngIf="companyChart" class="dashboard-card" [column]="2" [row]="2"></app-dashboard-card-company-chart>
      <dashboard-card-hse-document-list *ngIf="documents" class="dashboard-card" [column]="1" [row]="2"></dashboard-card-hse-document-list>
    </div>
</div>

