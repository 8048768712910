<div class="row mb-5">
  <div class="col-12">
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-4">
            <b>Locations</b>&nbsp;<small>({{ sendRequestParam.Locations.length }})</small>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-sm btn-primary harmony-teal-button me-2"
                    [disabled]="loading"
                    (click)="openLocationAddModal()">
              <fa-icon [icon]="faAdd"></fa-icon> Set Locations
            </button>
            <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                    [title]="'Reset Locations'"
                    [disabled]="loading"
                    (click)="resetLocationDependent();sendRequestParam.Locations = [];">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-6">
            <b>Request Types</b>&nbsp;<small>({{ sendRequestParam.RateRequestTypes.length }})</small>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                    [title]="'Reset Request Types'"
                    [disabled]="loading"
                    (click)="sendRequestParam.RateRequestTypes = []; sendRequestParam.Agents = []; rateRequests = []">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-4">
            <b>Agents</b>&nbsp;<small>({{ sendRequestParam.Agents.length }})</small>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-sm btn-primary harmony-teal-button me-2"
                    [title]="'Requires Locations and Request Types.'"
                    [disabled]="!canLoadAgents() || loading"
                    (click)="loadAgents()">
              Load Agents
            </button>
            <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                    [title]="'Reset Agents'"
                    [disabled]="loading"
                    (click)="sendRequestParam.Agents = []">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <b>Request Options</b>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-1 mb-1">
    <div class="row mt-1">
      <div class="col-3">
        <!-- Locations -->
        <div class="row">
          <div class="col-12">
            <app-city-search
              class="d-inline"
              [Identifier]="'LocationCityID'"
              [ErrorColor]="'#a22b9d'"
              [SelectedID]="selectedCityId"
              [Disabled]="loading"
              (OnSelectCityObject)="addLocation($event)">
            </app-city-search>
          </div>
        </div>
        <div class="row mt-2 locations-list">
          <div class="col-12" *ngIf="!sendRequestParam.Locations || sendRequestParam.Locations.length == 0">
            <p>
              Add locations to begin.
            </p>
          </div>
          <div class="col-12" *ngIf="sendRequestParam.Locations && sendRequestParam.Locations.length > 0">
            <div class="row mt-1" *ngFor="let location of sendRequestParam.Locations">
              <div class="col-10">
                {{ location.LocationName }} <small>({{ location.LocationCode }})</small>
              </div>
              <div class="col-2 text-end">
                <fa-icon [icon]="faRemove" *ngIf="!loading" (click)="removeLocation(location.LocationId)" class="text-danger pointer" size="sm"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Projects && Request Types -->
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(projects$ | async) || sendRequestParam.Locations.length === 0 || loading"
                    [(ngModel)]="sendRequestParam.ProjectId"
                    (ngModelChange)="setRequestTypes()">
              <option [ngValue]="null" selected>Select a project...</option>
              <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
          </div>
          <div class="col-12">
            <select class="form-select form-select-sm pointer mt-1"
                    [disabled]="!sendRequestParam.ProjectId || !(rateRequestTypes$ | async) || loading"
                    [multiple]="true"
                    [size]="8"
                    (ngModelChange)="rateRequests = []"
                    [(ngModel)]="sendRequestParam.RateRequestTypes">
              <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                      [hidden]="rateRequestType.ProjectId != sendRequestParam.ProjectId"
                      [ngValue]="rateRequestType.Id">
                {{ rateRequestType.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Agents -->
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <app-agent-search
              class="d-inline"
              [Identifier]="'AgentID'"
              [ErrorColor]="'#a22b9d'"
              [Disabled]="!sendRequestParam.ProjectId || !sendRequestParam.RateRequestTypes || sendRequestParam.RateRequestTypes.length <= 0 || loading"
              [SelectedID]="selectedAgentId"
              (OnSelectObject)="addAgent($event)">
            </app-agent-search>
          </div>
          <div class="col-12">
            <div class="agent-list mt-1">
              <div class="row" *ngFor="let agent of sendRequestParam.Agents">
                <div class="col-10 overflow-hidden">
                  {{ agent.AgentName }}
                </div>
                <div class="col-2 text-end">
                  <fa-icon [icon]="faRemove" *ngIf="!loading" (click)="removeAgent(agent)" class="text-danger pointer" size="sm"></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Search and action buttons -->
      <div class="col-3 action-container">
        <div class="row">
          <div class="col-4 col-compress">
            <small>Respond Date</small>
          </div>
          <div class="col-8 col-compress">
            <app-date-picker [identifier]="'responseDate'"
                             (onChange)="sendRequestParam.ResponseDate = $event"
                             [disabled]="loading"
                             [placeholder]="'Select an expected response date'">
            </app-date-picker>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 col-compress">
            <small>Rates Valid To</small>
          </div>
          <div class="col-8 col-compress">
            <app-date-picker [identifier]="'rateValidityDate'"
                             (onChange)="sendRequestParam.RateValidityDate = $event"
                             [disabled]="loading"
                             [placeholder]="'Select a rate expired before date'">
            </app-date-picker>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 col-compress">
            <small>Last Sent</small>
          </div>
          <div class="col-8 col-compress">
            <app-date-picker [identifier]="'lastSendDate'"
                             (onChange)="sendRequestParam.LastSendDate = $event"
                             [disabled]="loading"
                             [placeholder]="'Select a last sent before date'">
            </app-date-picker>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-1">
    <div class="row mt-2">
      <div class="col-3 mt-1">
        <b>Rate Request Count:</b> {{ rateRequests.length }}
      </div>
      <!-- Search and action buttons -->
      <div class="col-1 offset-4">
        <app-loader [Width]="20" *ngIf="loading"></app-loader>
      </div>
      <div class="col-4">
        <div class="row align-bottom">
          <div class="col-6">
            <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-1 w-100"
                    [disabled]="!canSearchRequestsToSend() || loading"
                    (click)="searchRateRequestsToSend()">
              Load requests
            </button>
          </div>
          <div class="col-6">
            <button type="button"
                    class="btn btn-sm btn-primary harmony-purple-button me-1 w-100"
                    [disabled]="!rateRequests || rateRequests.length <= 0 || loading"
                    (click)="generateRateGroups()">
              Generate & Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Results -->
    <div class="row mt-1">
      <div class="col-12">
        <div class="row mb-0 mt-1 header-row">
          <div class="col-1 header-title border-split-left">
            ID
          </div>
          <div class="col-3 header-title">
            Agent
          </div>
          <div class="col-4 header-title">
            Location Info
          </div>
          <div class="col-3 header-title">
            Request Type
          </div>
          <div class="col-1 header-title border-split-right">
            Respond By
          </div>
        </div>
        <div class="row mt-3" *ngIf="!loading && (!rateRequests || rateRequests.length <= 0)">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
          </div>
          <div class="col-12 text-center harmony-grey-text">
            <h4>No Rate Requests found. Please update your parameters and try again.</h4>
          </div>
        </div>
        <div class="request-result-container">
          <div class="row result-row border-top" *ngFor='let request of rateRequests; let i = index'>
            <div class="col-1 overflow-hidden align-self-center">
              {{ request.RequestId }}
            </div>
            <div class="col-3 overflow-hidden align-self-center">
              <b>{{ request.AgentName }}</b>
            </div>
            <div class="col-4 overflow-hidden">
              <span *ngIf="!request.RateRequestTypeName.includes('Destination') && !request.RateRequestTypeName.includes('Freight')">
                <!-- Location types City, Country, State -->
                  <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
                {{ getOriginLocationName(request) }}
                <br>
                <!-- Location types Seaport or Airport -->
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                {{ request.DeparturePortName ?  request.DeparturePortName : '-' }}
              </span>
              <span *ngIf="request.RateRequestTypeName?.includes('Destination')">
                <!-- Location types City, Country, State -->
                  <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
                {{ getDestinationLocationName(request) }}
                <br>
                <!-- Location types Seaport or Airport -->
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
                {{ request.ArrivalPortName ?  request.ArrivalPortName : '-' }}
              </span>
              <span *ngIf="request.RateRequestTypeName?.includes('Freight')">
                <b>Ori. Port:</b> {{ request.DeparturePortName ?  request.DeparturePortName : '-' }}
                <b>Dest. Port:</b> {{ request.ArrivalPortName ?  request.ArrivalPortName : '-' }}
              </span>
            </div>
            <div class="col-3 overflow-hidden align-self-center">
              {{ request.RateRequestTypeName }}
            </div>
            <div class="col-1 overflow-hidden align-self-center text-end">
              <small>{{ request.ResponseDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
