<div class="row">
  <div class="col-4 pe-0">
    <button class="dropdown-toggle form-control form-control-sm" type="button"
            id="country-number" data-bs-toggle="dropdown" aria-expanded="false"
            [ngClass]="{'is-invalid': !countryCode && Touched}" [disabled]="Disabled">
      <span [attr.class]="class"></span> {{dropdownText}}
    </button>
    <div class="dropdown-menu" aria-labelledby="country-number">
      <ng-container *ngFor="let country of countryCodes">
        <ul class="dropdown-item pointer-plain"
            (click)="setCountryCode(country.code, country.iso, true)">
          <span [attr.class]="'fi fi-'+country.iso"></span> {{country.code}} | {{country.country}}
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="col-8 ps-1">
    <div class="input-group input-group-sm">
      <input type="text"
             [numbersOnly]="maxLength"
             [ngClass]="{'is-invalid':!number && Touched }"
             [disabled]="Disabled"
             class="form-control form-control-sm"
             [(ngModel)]="number"
             (ngModelChange)="emitOutput()">
      <button
        class="btn btn-outline-primary harmony-purple-outline-button"
        type="button"
        [title]="'Clear'"
        [disabled]="Disabled"
        (click)="clear()">X
      </button>
    </div>
  </div>
</div>
