import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  IEnvironmentalAssessmentGroup,
  IEnvironmentalAssessmentItem
} from '../../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../../core/ad-auth-service/ad-auth.service";
import { SharedModule } from "../../../../../shared/shared.module";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-environmental-assessment-item-modal',
  templateUrl: './environmental-assessment-item-admin-modal.component.html',
  standalone: true,
  imports: [
    SharedModule,
    NgClass
  ],
  styleUrls: ['./environmental-assessment-item-admin-modal.component.scss']
})
export class EnvironmentalAssessmentItemAdminModalComponent {

  // Component Variables
  environmentalAssessmentItem: IEnvironmentalAssessmentItem;
  environmentalAssessmentGroup: IEnvironmentalAssessmentGroup;
  isInvalid: boolean = false;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  updateItem() {
    if (this.isValid()) {
      this.hseService.updateEnvironmentalAssessmentItem(this.environmentalAssessmentItem)
        .subscribe({
          next: (data) => {
            this.alertService.info('Environmental assessment item ' + this.environmentalAssessmentItem.ItemNumber + ' successfully saved.');
            this.environmentalAssessmentItem = Object.assign({}, data);
            this.close(this.environmentalAssessmentItem);
          },
          error: () => {
            this.alertService.error('Failed to update environmental assessment item.');
          }
        });
    } else {
      this.alertService.error('Please fill all required fields outlined red.');
    }
  }

  isValid() {
    const r = this.environmentalAssessmentItem;

    if (r.ItemNumber != null &&
       (r.Topic && r.Topic.length > 0) &&
       (r.Subject && r.Subject.length > 0) &&
       (r.Risk && r.Risk.length > 0) &&
       (r.Area && r.Area.length > 0) &&
       (r.PossibleMeasures && r.PossibleMeasures.length > 0)){
      this.isInvalid = false;
      return true;
    }

    this.isInvalid = true;
    return false;
  }

  close(environmentalAssessmentItem: IEnvironmentalAssessmentItem) {
    this.activeModal.close(environmentalAssessmentItem);
  }
}
