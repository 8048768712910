import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faBoxOpen, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ITenderOverviewResult, ITenderOverviewSearchParam } from '../../../../services/models/pricing/tender.model';
import { ICityDto, ILocationType } from '../../../../services/models/pricing/location.model';
import { IRateRequestLocation } from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';
import { CompanyService } from '../../../../services/company.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../services/api.service';
import { IPaginationData } from '../../../../shared/models/pagination-data.model';

@Component({
  selector: 'add-request-locations-modal',
  templateUrl: './add-request-locations-modal.component.html',
  styleUrls: ['./add-request-locations-modal.component.scss']
})
export class AddRequestLocationsModalComponent implements OnInit {
  // Icons
  faInfo = faCircleQuestion;
  faRemove = faMinusSquare;
  faEmpty = faBoxOpen;

  // Variables
  tenders: ITenderOverviewResult[] = [];
  tenderCompanyId: number = null;
  selectedTenderId: number = null;
  selectCityId: number = null;
  selectLocationType: ILocationType = null;
  requestLocations: IRateRequestLocation[] = [];

  // Lookup data
  companies$ = this.companyService.ActiveMembers$.asObservable();

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private companyService: CompanyService,
              private authService: AdAuthService,
              private api: ApiService) { }

  ngOnInit() {
    this.selectLocationType = this.pricingService.GetLocationTypeByName('City');

    if (this.authService.CurrentUser) {
      this.tenderCompanyId = this.authService.CurrentUser.User.CompanyId;
      this.loadTenders();
    } else {
      this.authService.CurrentUser$.subscribe((user) => {
        this.tenderCompanyId = user.User.CompanyId;
        this.loadTenders();
      });
    }
  }

  loadTenders() {
    this.loading = true;

    const params = {
      TenderStatusId: null,
      TenderName: null,
      CompanyId: this.tenderCompanyId,
      IsSingleLaneTender: false,
      CurrentPage: 1,
      RowCount: 15
    } as ITenderOverviewSearchParam;

    this.api.post('Tender/List', params).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe((data: IPaginationData<ITenderOverviewResult>) => {
      if (data) {
        this.tenders = data.DataSet;
      }
      this.loading = false;
    });
  }

  addLocation(location: ICityDto) {
    if (location) {
      this.selectCityId = location.Id;
      this.loading = true;
      const exists = this.requestLocations.find((x) => x.LocationId === location.Id);
      // Only add if it does not exist
      if (!exists) {
        const newLocation = {
          LocationTypeId: this.selectLocationType.Id,
          LocationId: location.Id,
          LocationCode: location.LOCode,
          LocationName: location.Name
        } as IRateRequestLocation;

        this.requestLocations.push(newLocation);
      }
      this.loading = false;
      // reset value on a delay to allow change detection on app-city-search to triggered correctly every time.
      setTimeout(() => {
        this.selectCityId = null;
      }, 200);
    }
  }

  removeLocation(locationID: number) {
    this.loading = true;

    if (locationID) {
      const index = this.requestLocations.findIndex((x) => x.LocationId === locationID);
      // Remove if exists in list
      if (index >= 0) {
        this.requestLocations.splice(index, 1);
      }
      this.loading = false;
    }
  }

  getLocationsByTenderID() {
    this.loading = true;
    // Load City ID's linked to a Tender
    if (this.selectedTenderId) {
      this.api.get(`TenderLocation/GetLocationsByTenderID/${this.selectedTenderId}`).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IRateRequestLocation[]) => {
          if (data && data.length > 0) {
            this.requestLocations = Object.assign([], data);
            // data.forEach((location) => {
            //   this.requestLocations.push(location);
            // });
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    }
  }

  apply() {
    this.activeModal.close(this.requestLocations);
  }

  close() {
    this.activeModal.dismiss(null);
  }
}
