import { Component } from '@angular/core';

@Component({
  selector: 'app-services-integration',
  templateUrl: './services-integration.component.html',
  styleUrls: ['./services-integration.component.scss']
})
export class ServicesIntegrationComponent {

}
