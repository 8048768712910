import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ICompany, ICompanyOverview } from '../../../../services/models/member.model';
import { MapStyles } from '../../../../core/constants/map-styles';
import { environment } from '../../../../../environments/environment';
import { CompanyService } from '../../../../services/company.service';
import {
  faCheckCircle,
  faEye,
  faTrash,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { ICompanyUserDto } from '../../../../services/models/user.model';
import { FileService } from '../../../../services/file.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserContactModalComponent } from './user-contact-modal/user-contact-modal.component';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  // Icons
  faRemove = faTrash;
  faInfo = faCircleQuestion;
  faEdit = faUserEdit;
  faCheck = faCheckCircle;
  faView = faEye;

  // Component Variables
  company: ICompany | null = null;
  companyTelephone: string | null = null;
  companyUrl: string | null = null;
  companyEmail: string | null = null;
  notFound = false;
  placeholderImage: string = '';
  users: ICompanyUserDto[] = [];
  searchText: string;

  // Map variables
  mapData: ICompanyOverview[] = [];
  mapStyles = MapStyles;
  centerLat: number = 15;
  centerLong: number = 0;

  // General variables
  loading: boolean = false;
  env = environment;
  defaultImage = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.ImagePlaceholderUrl;
  token: string;

  constructor(public companyService: CompanyService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private fileService: FileService) { }

  ngOnInit() {
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.SiteUrls.ImagePlaceholderLargeUrl}`;

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.route.params.subscribe((param: Params) => {
      this.loadMember(param['companyId']);
      this.loadCompanyUsers(param['companyId']);
    });
  }

  loadMember(companyID: number) {
    if (companyID && companyID > 0) {
      this.loading = true;
      this.companyService.getCompany(companyID).subscribe((data: ICompany) => {
        if (data) {
          this.company = Object.assign({}, data);
          this.companyTelephone = this.getDetailValue('MemberTelephone');
          this.companyUrl = this.getDetailValue('Url');
          this.companyEmail = this.getDetailValue('MemberEmail');
          this.setMapData();
          this.getToken();
        } else {
          this.company = null;
          this.mapData = [];
          this.notFound = true;
        }
        this.loading = false;
      });
    }
  }

  getToken() {
    this.fileService.GetSasToken(this.env.ContainerNames.UserProfilePictures)
      .subscribe({
        next: (data) => {
          this.token = `?${data.Token}`;
        }
      });
  }

  getCompanyPicture() {
    if (this.company && this.company.CompanyPicture) {
      return this.company.CompanyPicture;
    } else {
      return this.placeholderImage;
    }
  }

  getDetailValue(propertyName: string) {
    const result = this.company?.CompanyDetails?.find((cd) => cd.CompanyDetailDefinition.PropertyName === propertyName);

    if (result) {
      return result.PropertyValue;
    } else {
      return null;
    }
  }

  openNewWindow(url: string | undefined | null) {
    if (url) {
      const hasHttps = url.includes('https') || url.includes('https');

      if (hasHttps) {
        window.open(url, '_blank');
      } else {
        window.open('https://' + url, '_blank');
      }
    }
  }

  getDirectQuote() {
    window.open(this.env.website_url + '/direct-quote', '_blank');
  }

  contactMember() {
    const member = this.getCompanyOverview();

    if (member) {
      window.open(this.env.website_url + '/contact/' + member.CompanyID, '_blank');
    }
  }

  setMapData() {
    const member = this.getCompanyOverview();

    if (member) {
      this.mapData = [];
      this.mapData.push(member);
      this.setCenter(member.Latitude, member.Longitude);
    }
  }

  getCompanyOverview() {
    const website = this.getDetailValue('WebSite');
    const memberEmail = this.getDetailValue('MemberEmail');
    const memberTelephone = this.getDetailValue('MemberTelephone');

    return {
      CompanyID: this.company?.CompanyId,
      CompanyType: null,
      Name: this.company?.Name,
      Country: this.company?.CountryPhysical,
      Biography: this.company?.Biography,
      CompanyLogo: this.company?.CompanyLogo,
      Latitude: this.company?.Latitude,
      Longitude: this.company?.Longitude,
      WebSite: website ? website : null,
      MemberEmail: memberEmail ? memberEmail : null,
      ContactNumber: memberTelephone ? memberTelephone : null,
      IsMovingServiceProvider: this.company?.IsMovingServiceProvider,
      IsRelocationServiceProvider: this.company?.IsRelocationServiceProvider,
      City: this.company ? this.company.CityPhysical : '-'
    } as ICompanyOverview;
  }

  setCenter(lat: number, long: number) {
    this.centerLat = lat;
    this.centerLong = long;
  }

  loadCompanyUsers(companyId: number) {
    this.loading = true;

    this.companyService.getCompanyUsers(companyId).subscribe({
      next:(data: ICompanyUserDto[]) => {
        this.users = Object.assign([], data);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getFilteredUsers(): ICompanyUserDto[] {
    const s = this.searchText?.toLowerCase();
    if (s && s.length > 0) {
      return this.users.filter(x => x.DisplayName?.toLowerCase().includes(s) || x.Username?.toLowerCase().includes(s) || x.JobTitle?.toLowerCase().includes(s));
    } else {
      return this.users;
    }
  }

  viewContactCard(userId: number) {
    // Open modal to edit user
    const modalRef = this.modalService.open(UserContactModalComponent, {size: 'md', centered: true, backdrop: 'static'});
    modalRef.componentInstance.UserID = userId;
  }
}
