import {Component, OnInit} from '@angular/core';
import {faCircleDot, faNewspaper} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {AdAuthService} from '../../../core/ad-auth-service/ad-auth.service';
import {PermissionCodes} from '../../../core/constants/permission-codes';
import {NewsRoutes} from "../../../core/constants/routes";

@Component({
  selector: 'app-news-menu',
  templateUrl: './news-menu.component.html',
  styleUrls: ['./news-menu.component.scss']
})
export class NewsMenuComponent implements OnInit {
  // Icons
  faNews = faNewspaper;
  faMenuItem = faCircleDot;

  // Menu permissions
  newsRoom: boolean = false;
  articleManagement: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;
  protected readonly NewsRoutes = NewsRoutes;

  constructor(public router: Router,
              public auth: AdAuthService) {
  }

  ngOnInit(): void {
    if (this.auth.CurrentUser) {
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe(
      () => {
        this.setPermissions();
      });
  }

  setPermissions() {
    this.newsRoom = this.auth.CheckPermissionByCode(PermissionCodes.News_NetworkArticles);
    this.articleManagement = this.auth.CheckPermissionByCode(PermissionCodes.News_ManageArticles);
  }
}
