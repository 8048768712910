import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishModalComponent } from './publish-modal/publish-modal.component';
import { IArticle, IArticleAttachment } from '../../../../services/models/news/article.model';
import { ArticleService } from "../../../../services/article.service";
import { AlertService } from "../../../../services/alert.service";
import { editorConfig } from "./editorConfig";
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleAttachmentModalComponent } from "./article-attachment-modal/article-attachment-modal.component";
import {
  faFloppyDisk, faPaperclip, faPlus, faStamp,
  faTrash, faUpload, faRotate, faCircleQuestion, faDownload
} from '@fortawesome/free-solid-svg-icons';
import { throwError } from "rxjs";
import { FileService } from "../../../../services/file.service";
import { catchError, switchMap } from "rxjs/operators";
import { Location } from "@angular/common";
import { NewsRoutes } from "../../../../core/constants/routes";
import { IApiResponse } from '../../../../shared/models/api-reponse.model';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ArticlePreviewModalComponent } from '../article-review/article-preview-modal/article-preview-modal.component';
import { EditorOptions } from 'tinymce';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: ['./article-editor.component.scss']
})
export class ArticleEditorComponent implements OnInit {
  @Input() isNew: boolean;
  @Input() articleId: number;

  // Icons
  protected readonly faAttachment = faPaperclip;
  protected readonly faStamp = faStamp;
  protected readonly faPlus = faPlus;
  protected readonly faSave = faFloppyDisk;
  protected readonly faDelete = faTrash;
  protected readonly faUpload = faUpload;
  protected readonly faChange = faRotate;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faDownload = faDownload;

  // Component variables
  tinymce: any;
  coverImageFile: any;
  coverImage: any;
  article: IArticle = {} as IArticle;
  attachments: IArticleAttachment[] = [];
  categories$ = this.articleService.Categories$.asObservable();
  isSaveInvalid: boolean = false; // Different validations for saving as draft
  isPublishInvalid: boolean = false; // Validations for submitting for approval
  // disable: boolean = false;

  // Permissions
  canEdit: boolean = false;
  isPublished: boolean = false;

  // General variables
  loading: boolean = false;
  imageBaseUrl: string = '';
  placeholderImage: string = '';
  protected readonly editorConfig: EditorOptions = editorConfig;
  protected readonly environment = environment;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              private articleService: ArticleService,
              private alertService: AlertService,
              private fileService: FileService,
              private router: Router,
              private authService: AdAuthService,
              private location: Location) { }

  ngOnInit(): void {
    this.imageBaseUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/`;
    this.placeholderImage = `${environment.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;
    if (this.authService.CurrentUser) {
      this.initArticle();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.initArticle();
      });
  }

  initTinymce(event: any) {
    this.tinymce = event.editor;
  }

  initArticle() {
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.News_ApproveArticles);
    this.route.paramMap.subscribe(paramMap => {
      const articleId = Number(paramMap.get('articleId'));
      if (articleId && articleId > 0) {
        this.isNew = false;
        this.articleId = articleId;
        this.getArticle();
        this.getAttachments();
      } else {
        this.isNew = true;
        this.article = {
          Title: '',
          HtmlContent: null,
          PublishDate: '',
          ExpireDate: null,
          Featured: false,
          StatusId: 1, // Draft as default
          AuthorUserId: this.authService.CurrentUser.UserId,
          Author: this.authService.CurrentUser.User.DisplayName,
          Categories: []
        } as IArticle;
      }
    })
  }

  getArticle() {
    this.loading = true;
    this.articleService.get(this.articleId)
      .subscribe({
        next: (article: IArticle) => {
          this.loading = false;
          if (article != null) {
            switch (article.Status) {
              case 'Draft':
                this.article = Object.assign({}, article);
                break;
              case 'Published':
                this.isPublished = true;
                this.article = Object.assign({}, article);
                if (this.canEdit == false) {
                  this.alertService.warn('The article has already been published. No action required');
                  this.returnToOverview();
                }
                break;
              case 'Awaiting Approval':
                this.alertService.warn('The article has already been submitted for approval and cannot be edited.');
                this.returnToOverview();
                break;
              case 'Rejected':
                this.alertService.warn('The article has been rejected and can no longer be actioned');
                this.returnToOverview();
                break;
              case 'Change Requested':
                this.alertService.warn('The requested changes are still awaiting review by the author');
                this.returnToOverview();
                break;
            }
          } else {
            this.alertService.warn('Only the original author can action their own articles');
            this.returnToOverview();
          }
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  preview() {
    const a = this.article;

    const modalRef = this.modalService.open(ArticlePreviewModalComponent, { size: 'xl', windowClass: 'modal-2xl', backdrop: 'static' });
    modalRef.componentInstance.ImageUrl = a.ImageReference ? (this.imageBaseUrl + a.ArticleId + '/'+ a.ImageReference) : this.placeholderImage;
    modalRef.componentInstance.Title = a.Title;
    modalRef.componentInstance.PublishDate = a.PublishDate;
    modalRef.componentInstance.Author = a.Author;
    modalRef.componentInstance.AuthorUserId = a.AuthorUserId;
    modalRef.componentInstance.HtmlContent = a.HtmlContent;
    modalRef.componentInstance.Attachments = this.attachments;
  }

  returnToOverview() {
    this.router.navigate([`${NewsRoutes.Article_Management}`])
      .then(() => {
      });
  }

  getPlainTextFromEditor() {
    return this.tinymce.getContent({format: 'text'});
  }

  selectFile(event: any) {
    const file = event.target.files[0];
    this.loading = true;
    if ((file.size / 1024 / 1024) > 3) {
      event.target.value = null;
      this.alertService.info('The selected file is bigger than 3MB, please select a smaller file or reduce the file size');
      this.loading = false;
      return;
    } else {
      if (!this.articleId) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.coverImage = event.target.result;
        };
        reader.readAsDataURL(file);
        this.coverImageFile = file;
        event.target.value = null;
        this.loading = false;
      } else {
        if (event.target.files.length > 1) {
          this.alertService.error('Only one image may be uploaded');
          event.target.value = null;
          return;
        } else {
          this.removeImage();
          this.uploadImage(file);
        }
      }
    }
  }

  uploadImage(file: any) {
    this.loading = true;
    const fullFileName = `${this.articleId}/${file.name}`;

    this.fileService.Upload(file, this.environment.ContainerNames.ArticleImages, fullFileName)
      .pipe(
        switchMap(() => {
          this.article.ImageReference = file.name;
          this.coverImageFile = null;
          return this.articleService.update(this.article);
        }),
        catchError((err: any) => {
          if (err.Meta.Code == 409) {
            // Delete the existing duplicate image
            this.fileService.DeleteFile(this.environment.ContainerNames.ArticleImages, fullFileName)
              .subscribe({
                next: () => {
                }, error: err1 => {
                  return throwError(err1);
                }
              })
            return throwError(err); // Re-throw the error to be caught by the outer catchError
          }
          this.alertService.error(err.Meta.Message);
          return throwError(err); // Re-throw the error to be caught by the outer catchError
        })
      ).subscribe({
          next: () => {
            this.loading = false;
            this.alertService.info('Image updated');
          }, error: (err) => {
            this.loading = false;
            this.alertService.error(err.Meta.Message);
          }
        }
      );
  }

  removeImage() {
    if (this.articleId && this.article.ImageReference) {
      const fullFileName = `${this.articleId}/${this.article.ImageReference}`;
      this.fileService.DeleteFile(environment.ContainerNames.ArticleImages, fullFileName)
        .pipe(
          switchMap(() => {
            this.article.ImageReference = null;
            return this.articleService.update(this.article);
          })
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.alertService.info('Image has been removed');
          },
          error: (err) => {
            this.loading = false;
            this.alertService.error(err.Meta.Message);
          }
        });
    }
  }

  setFeaturedStatus(event: any) {
    this.article.Featured = event.target.checked;
  }

  setWebsiteStatus(event: any) {
    this.article.Website = event.target.checked;
  }

  getAttachments() {
    this.articleService.getAttachments(this.articleId)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.attachments = [...data];
          this.article.TotalAttachments = this.attachments.length;
        }, error: (err: IApiResponse) => {
          this.alertService.error(err.Meta.Message);
          this.article.TotalAttachments = 0;
          this.loading = false;
        }
      });
  }

  openAttachmentModal() {
    if (!this.articleId) {
      this.alertService.info('Save the article before adding attachments');
    } else {
      const modalRef = this.modalService.open(ArticleAttachmentModalComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.articleId = this.articleId;
      modalRef.result.then((result: string) => {
        if (result) {
          this.getAttachments();
        }
      });
    }
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  canSave() {
    if (this.article?.Title?.length > 1 &&
      this.article?.HtmlContent?.length > 1) {
      this.isSaveInvalid = false;
      return true;
    }
    this.isSaveInvalid = true;
    return false;
  }

  canPublish() {
    if (this.article?.Title?.length > 1 &&
      this.article?.HtmlContent?.length > 1 &&
      this.article?.ImageReference?.length > 1 &&
      this.article?.Categories?.length > 0) {
      this.isPublishInvalid = false;
      this.isSaveInvalid = false;
      return true;
    }
    this.isPublishInvalid = true;
    this.isSaveInvalid = true;
    return false;
  }

  save() {
    if (this.canSave()) {
      this.article.PlainContent = this.getPlainTextFromEditor();
      if (this.isNew || this.articleId == null) {
        this.addArticle();
        return;
      } else {
        this.updateArticle();
        return;
      }
    }
    this.alertService.warn('Please check the required fields');
  }

  addArticle() {
    this.loading = true;
    this.articleService.add(this.article)
      .subscribe({
        next: (article: IArticle) => {
          this.loading = false;
          this.alertService.info('Successfully created article');
          this.article.ArticleId = article.ArticleId;
          this.article.DateModified = article.DateModified;
          this.articleId = article.ArticleId;
          if (this.coverImageFile) {
            this.uploadImage(this.coverImageFile);
          }
          this.isNew = false;
          // update the route
          this.location.replaceState(`${NewsRoutes.Article_Review}/${this.article.ArticleId}`);
        },
        error: () => {
          this.alertService.error('Failed to save article');
          this.loading = false;
        }
      });
  }

  updateArticle() {
    this.loading = true;
    this.articleService.update(this.article)
      .subscribe((article: IArticle) => {
        this.loading = false;
        this.article.DateModified = article.DateModified;
        this.alertService.info('Successfully updated article');
      });
  }

  openSubmitModal() {
    if (this.canPublish()) {
      this.save();
      const modalRef = this.modalService.open(PublishModalComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.article = this.article;
      modalRef.result.then((submitted) => {
        if (submitted) {
          this.alertService.success('Article successfully submitted for approval');
          // this.disable = true;
          this.isPublished = true;
        }
      });
    } else {
      this.alertService.warn('Please make sure all required fields are populated and saved to submit an article for approval');
    }
  }
}
