<div class="row ps-1 pe-1 mt-2">
  <div class="col-2 ps-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
            (click)="previous()"
            [disabled]="disablePreviousPage() || !hasPaginationData()">
      Previous Page
    </button>
  </div>
  <div class="col-8">
    <div class="row">
      <div class="col-6 text-start">
        <div *ngIf="showRowsPerPage">
          <span class="mt-1">Page size:</span>
          <select class="form-select d-inline row-count-select"
                  name="rowCount"
                  id="rowCount"
                  [(ngModel)]="page.pageSize"
                  (change)="setRowCount()">
            <option [ngValue]="15">15</option>
            <option [ngValue]="30">30</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 text-end">
        <div *ngIf="showPageNumber">
          <p class="mt-1 mb-0">
            Page <b>{{ page?.pageNumber}}</b> of <b>{{ paginationData?.TotalPages }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
            (click)="next()"
            [disabled]="disableNextPage() || !hasPaginationData()">
      Next Page
    </button>
  </div>
</div>
