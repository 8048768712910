import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { IEnvironmentalAssessmentAnswer, IEnvironmentalAssessmentGroup, IEnvironmentalAssessmentItem } from '../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../core/ad-auth-service/ad-auth.service";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-environmental-assessment-answer-modal',
  templateUrl: './environmental-assessment-answer-modal.component.html',
  styleUrls: ['./environmental-assessment-answer-modal.component.scss']
})
export class EnvironmentalAssessmentAnswerModalComponent {
  // Component Variables
  isValid: boolean = false;
  environmentalAssessmentAnswer: IEnvironmentalAssessmentAnswer;
  environmentalAssessmentItem: IEnvironmentalAssessmentItem;
  environmentalAssessmentGroup: IEnvironmentalAssessmentGroup;

  exposure$ = this.hseService.Exposure$.asObservable();
  likeliness$ = this.hseService.Likeliness$.asObservable();
  severity$ = this.hseService.EnvironmentalSeverity$.asObservable();
  influence$ = this.hseService.Influence$.asObservable();
  measure$ = this.hseService.Measure$.asObservable();
  levelOfMeasures$ = this.hseService.LevelOfMeasures$.asObservable();
  environmentalAssessmentAnswerStatus$ = this.hseService.EnvironmentalAssessmentAnswerStatus$.asObservable();

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) { }

  validate(): boolean {
    this.isValid = (this.environmentalAssessmentAnswer.ExposureValue != null &&
      this.environmentalAssessmentAnswer.Likeliness != null &&
      this.environmentalAssessmentAnswer.SeverityValue != null &&
      this.environmentalAssessmentAnswer.InfluenceValue != null &&
      (this.environmentalAssessmentAnswer.Legislation != null && this.environmentalAssessmentAnswer.Legislation.length > 0) &&
      this.environmentalAssessmentAnswer.MeasureValue != null &&
      this.environmentalAssessmentAnswer.LevelOfMeasuresId != null &&
      this.environmentalAssessmentAnswer.StatusId != null &&
      ((this.environmentalAssessmentAnswer.RemainingExposure == null && this.environmentalAssessmentAnswer.StatusId == 1) ||
      (this.environmentalAssessmentAnswer.RemainingExposure != null)) &&
      ((this.environmentalAssessmentAnswer.RemainingLikeliness == null && this.environmentalAssessmentAnswer.StatusId == 1) ||
      (this.environmentalAssessmentAnswer.RemainingLikeliness != null)) &&
      ((this.environmentalAssessmentAnswer.RemainingSeverity == null && this.environmentalAssessmentAnswer.StatusId == 1) ||
      (this.environmentalAssessmentAnswer.RemainingSeverity != null))
    );

    return this.isValid;
  }

  updateAnswer() {
     if (this.validate()) {
       this.environmentalAssessmentAnswer.LastModifiedByUserId = this.authService.CurrentUser.UserId;

       this.hseService.upsertEnvironmentalAssessmentAnswer(this.environmentalAssessmentAnswer)
         .subscribe({
           next: (data) => {
             this.environmentalAssessmentAnswer = Object.assign({}, data);

             this.alertService.info('Assessmentitem ' + this.environmentalAssessmentItem.ItemNumber + ' successfully saved.');
             this.runCheck();
           },
           error: () => {
             this.alertService.error('Failed to update Environmental Risk Assessment item.');
           }
         });
     } else {
       this.alertService.error('Please fill all required fields outlined red.');
     }
  }

  runCheck() {
    //Check if the environmental assessment is complete
    this.hseService.checkEnvironmentalAssessmentComplete(this.environmentalAssessmentAnswer.EnvironmentalAssessmentId)
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.alertService.info('The assessment has been completed and set to Active');
          }
          this.close(this.environmentalAssessmentAnswer);
        },
        error: () => {
          this.alertService.warn('Failed checking the environmental assessment.');
        }
      });
  }

  calculateIRL(){
    this.environmentalAssessmentAnswer.RiskLevel1 = Number((this.environmentalAssessmentAnswer.ExposureValue * this.environmentalAssessmentAnswer.Likeliness * this.environmentalAssessmentAnswer.SeverityValue).toFixed(2));
  }

  calculateRRL(){
    this.environmentalAssessmentAnswer.RemainingTotal = Number((this.environmentalAssessmentAnswer.RemainingExposure * this.environmentalAssessmentAnswer.RemainingLikeliness * this.environmentalAssessmentAnswer.RemainingSeverity).toFixed(2));
  }

  residualRisksIsEmpty(): boolean {
    return this.environmentalAssessmentAnswer.RemainingExposure == null
      || this.environmentalAssessmentAnswer.RemainingLikeliness == null
      || this.environmentalAssessmentAnswer.RemainingSeverity == null
  }

  setRiskLevelColor(riskLevel: number): string {
    if(riskLevel <= 20 && !this.residualRisksIsEmpty()){
      return 'risk-level-green';
    }

    if(riskLevel > 20 && riskLevel <= 70){
      return 'risk-level-lightgreen';
    }

    if(riskLevel > 70 && riskLevel <= 200){
      return 'risk-level-yellow';
    }

    if(riskLevel > 200 && riskLevel <= 400){
      return 'risk-level-orange';
    }

    if(riskLevel > 400){
      return 'risk-level-red';
    }
  }

  setResidualRiskLevelColor(riskLevel: number): string {
    if(riskLevel <= 20 && !this.residualRisksIsEmpty()){
      return 'risk-level-green';
    }

    if(riskLevel > 20 && riskLevel <= 70){
      return 'risk-level-lightgreen';
    }

    if(riskLevel > 70 && riskLevel <= 200){
      return 'risk-level-yellow';
    }

    if(riskLevel > 200 && riskLevel <= 400){
      return 'risk-level-orange';
    }

    if(riskLevel > 400){
      return 'risk-level-red';
    }
  }

  close(environmentalAssessmentAnswer: IEnvironmentalAssessmentAnswer) {
    this.activeModal.close(environmentalAssessmentAnswer);
  }

  protected readonly faInfo = faCircleQuestion;
}
