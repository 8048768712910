import { Component } from '@angular/core';

@Component({
  selector: 'app-schema-mappings',
  templateUrl: './schema-mappings.component.html',
  styleUrls: ['./schema-mappings.component.scss']
})
export class SchemaMappingsComponent {

}
