import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IDocument, IDocumentListParam, } from '../../../services/models/document/document.model';
import { DocumentService } from '../../../services/document.service';
import { AlertService } from '../../../services/alert.service';
import { FileService } from '../../../services/file.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgForOf, NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { DocumentTypeIconComponent } from '../document-type-icon/document-type-icon.component';

@Component({
  selector: 'app-document-list',
  standalone: true,
  templateUrl: './document-list.component.html',
  imports: [
    FontAwesomeModule,
    NgForOf,
    SharedModule,
    NgIf,
    DocumentTypeIconComponent
  ],
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {
  // Inputs
  @Input() ModuleName: string = null;
  @Input() CategoryCode: string = null;
  @Input()
  set CompanyId(companyId: number) {
    if (companyId && companyId > 0) {
      this.searchParam.CompanyId = companyId;
      this.loadDocuments();
    }
  }

  @Input() DocumentUserId: number = null;
  @Input() Recents: boolean = null;
  @Input() NoDocumentMessage: string = 'No documents found...';

  // Icons
  protected readonly faDownloadIcon = faDownload;
  protected readonly faInfo = faCircleQuestion;

  // Component variables
  searchParam = {
    CategoryCode: null,
    ModuleName: null,
    CompanyId: null,
    DocumentUserId: null,
    Recents: null
  } as IDocumentListParam;
  documents: IDocument[] = [];

  // General variables
  loading: boolean = false;
  protected readonly env = environment;

  constructor(private alertService: AlertService,
              private fileService: FileService,
              private documentService: DocumentService) { }

  ngOnInit(): void {
    this.loadDocuments();
  }

  loadDocuments() {
    this.searchParam.ModuleName = this.ModuleName;
    this.searchParam.CategoryCode = this.CategoryCode;
    this.searchParam.DocumentUserId = this.DocumentUserId;
    this.searchParam.Recents = this.Recents;

    this.documentService.loadDocuments(this.searchParam).subscribe({
      next: (data: IDocument[]) => {
        if (data) {
          this.documents = Object.assign([], data);
          // Populate extensions
          this.documents.forEach(x => x.Extension = this.getFileType(x.FileName).toLowerCase());
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search documents.');
      }
    });
  }

  downloadDocument(doc: IDocument) {
    this.fileService.GetFile('documents', doc.FileName, doc.DisplayName);
  }

  getFileType(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
}
