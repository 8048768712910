<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Pulse Chart
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1" *ngIf="!showTableView"
            [disabled]="loading" (click)="openInfoModal()">
      Information
    </button>
    <button class="btn btn-sm btn-primary harmony-purple-outline-button" *ngIf="canAdd"
            [disabled]="loading" (click)="openPulseChartModal(true, null, companyId)">
      + New Chart
    </button>
  </div>
</div>
<!----------------------->
<!------- Filters ------->
<!----------------------->
<div *ngIf="!showTableView">
  <div class="row" *ngIf="canFilterCompany">
    <div class="col-1">
      <b>Company</b>
    </div>
    <div class="col-3">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="selectedCompany" (ngModelChange)="selectCompany($event)"
              [disabled]="loading || !(companies$ | async)">
        <option [ngValue]="null" selected>All companies...</option>
        <option *ngFor="let company of (companies$ | async)"
                [ngValue]="company">{{ company.CompanyName }}</option>
      </select>
    </div>
    <div class="col-2 offset-6" *ngIf="canFilterCompany">
      <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
              [disabled]="!companyId" (click)="search()">
        Search
      </button>
    </div>
  </div>
  <hr class="my-2" *ngIf="canFilterCompany">
  <!----------------------->
  <!----- Pagination ------>
  <!----------------------->
  <div class="row ms-1 me-1">
    <div class="col-12">
      <app-pagination [showPageNumber]=true
                      [showRowsPerPage]=true
                      [paginationData]=paginationData
                      (pageEvent)="onPageChange($event)"
                      [name]="'PulseChart'"
                      [page]="page">
      </app-pagination>
    </div>
  </div>
  <!----------------------->
  <!------- Results ------->
  <!----------------------->
  <div class="row mt-2 header-row">
    <div class="col-3 header-title border-split-left" *ngIf="canFilterCompany">
      Company
    </div>
    <div class="col-2 header-title" *ngIf="canFilterCompany">
      City
    </div>
    <div class="col-1 header-title" *ngIf="canFilterCompany">
      Year
    </div>
    <div class="col-4 header-title border-split-left" *ngIf="!canFilterCompany">
      Year
    </div>
    <div class="col-1 header-title">
      FTE
    </div>
    <div class="col-2 header-title">
      Work Days
    </div>
    <div class="col-2 header-title" *ngIf="!canFilterCompany">
      Work Hours
    </div>
    <div class="col-2 header-title">
      Sick Days
    </div>
    <div class="col-1 header-title border-split-right text-center">
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <app-no-result [showNoResults]="noResult"></app-no-result>
  <!------ Results-->
  <div class="row result-row" *ngFor='let c of pulseChartsOverview; let i = index'>
    <div class="col-3 overflow-hidden" *ngIf="canFilterCompany">
      <b>{{ c.CompanyName ?? '-'}}</b>
    </div>
    <div class="col-2 overflow-hidden" *ngIf="canFilterCompany">
      {{ c.City ?? '-'}}
    </div>
    <div class="col-1 overflow-hidden" *ngIf="canFilterCompany">
      <b>{{ c.Year ?? '-'}}</b>
    </div>
    <div class="col-4 overflow-hidden" *ngIf="!canFilterCompany">
      <b>{{ c.Year ?? '-'}}</b>
    </div>
    <div class="col-1 overflow-hidden">
      {{ c.FTE ?? '-'}}
    </div>
    <div class="col-2 overflow-hidden">
      {{ c.WorkDays ?? '-'}}
    </div>
    <div class="col-2 overflow-hidden" *ngIf="!canFilterCompany">
      {{ c.WorkHours ?? '-'}}
    </div>
    <div class="col-1 overflow-hidden">
      {{ c.SickDays ?? '-'}}
    </div>
    <div class="col-2 text-end pe-0">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
              [title]="'View pulse chart'" (click)="openPulseChartModal(false, c.Id, c.CompanyId)">
        <fa-icon *ngIf="canEdit" [icon]="faEdit"></fa-icon>
        <fa-icon *ngIf="!canEdit" [icon]="faView"></fa-icon>
      </button>
      <button class="btn btn-sm harmony-purple-outline-button"
              [title]="'View in table form'" (click)="viewTabledPulseChart(c.CompanyId)">
        <fa-icon [icon]="faTable"></fa-icon>
      </button>
      <button *ngIf="canDelete" class="btn btn-sm btn-outline-danger ms-1"
              [title]="'Delete pulse chart'" (click)="deleteChart(c.Id, i)">
        <fa-icon [icon]="faDelete"></fa-icon>
      </button>
    </div>
  </div>
</div>
<!------------------------->
<!------- Table View ------>
<!------------------------->
<ng-container *ngIf="showTableView">
  <div class="row">
    <div class="col-12">
      <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button float-end me-2"
              (click)="showTableView = false">
        Back
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="fixed-column">
      <div class="row justify-content-end me-1 mb-1 border-bottom harmony-blue-text">
        Year
      </div>
      <div class="row justify-content-end me-1 mb-1">
        FTE
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Days Worked
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Hours Worked
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Accidents(with sick leave)
      </div>
      <div class="row justify-content-end me-1 mb-1 py-1">
        Ratio Worked hours/accidents
      </div>
      <div class="row justify-content-end me-1 py-1">
        Accidents per million hours
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Incidents(with no sick leave)
      </div>
      <div class="row justify-content-end me-1 mb-1 py-1">
        Ratio worked hours/incidents
      </div>
      <div class="row justify-content-end me-1 py-1">
        Incidents per million hours
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Environmental incidents
      </div>
      <div class="row justify-content-end me-1 mb-1 py-1">
        Ration Worked hours/env incidents
      </div>
      <div class="row justify-content-end me-1 py-1">
        Env incidents per million hours
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Sick days
      </div>
      <div class="row justify-content-end me-1 py-1">
        Percentage
      </div>
      <div class="row justify-content-end me-1 mb-1 py-1">
        Work related sick days
      </div>
      <div class="row justify-content-end me-1 mb-1">
        Percentage
      </div>
    </div>
    <div class="col-8 horizontal-scrollable d-flex">
      <div *ngFor='let chart of pulseCharts; let i = index' class="float-start ps-1 pe-1"
           style="min-width: 12vw; width: 20vw">
        <div class="row me-1 mb-1 border-bottom">
          <div class="col-8">
            <b class="harmony-blue-text">{{ chart.Year ?? '-' }}</b>
          </div>
          <div class="col-4">
            <fa-icon class="float-end icon-edit"
                     *ngIf="canEdit"
                     [icon]="faEdit"
                     (click)="openPulseChartModal(false, chart.Id, chart.CompanyId)"></fa-icon>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.FTE ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.WorkDays ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.WorkHours ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.AccidentsWithSickLeave ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.RatioWorkHoursAccident ?? 0 }}</small>
          </div>
        </div>
        <div class="row flex-nowrap me-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.AccidentsPerMillionHours ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.IncidentsWithNoSickLeave ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.RatioWorkHoursIncident ?? 0 }}</small>
          </div>
        </div>
        <div class="row flex-nowrap me-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.IncidentsPerMillionHours ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.EnvironmentalIncident ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.RatioWorkHoursEnvIncident ?? 0 }}</small>
          </div>
        </div>
        <div class="row flex-nowrap me-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.EnvIncidentsPerMillionHours ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.SickDays ?? 0 }}</small>
          </div>
        </div>
        <div class="row flex-nowrap me-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.SickDaysPercentage ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12">
            <small>{{ chart.WorkRelatedSickDays ?? 0 }}</small>
          </div>
        </div>
        <div class="row me-1 mb-1">
          <div class="col-12 calculated-section py-1">
            <small>{{ chart.WorkRelatedSickDaysPercentage ?? 0 }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
