<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    Review Request
  </h4>
</div>
<div class="modal-body" style="max-height: 30em; overflow-y: auto;">
  <div class="row">
    <div class="col-12">
      <p>
        You have received a request from Harmony Relocation Network to provide rates for the specification provided below.
        <br>
        If you accept this request to provide rates, please do so before <b>{{ CompanyRequest.RespondByDate | date: environment.FormattingStandards.ShortDateFormat }}</b>.
        <br>
        The request will automatically expire after this date and you will be unable to submit your rates.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <b class="d-inline">Request type:</b><span> {{ CompanyRequest.RateRequestTypeName }}</span>
    </div>
  </div>
  <div class="row" *ngIf="CompanyRequest.ModalityTypeName">
    <div class="col-12">
      <b class="d-inline">Mode of transport:</b><span> {{ CompanyRequest.ModalityTypeName }}</span>
    </div>
  </div>
  <div class="row ms-1 me-1 mt-4">
    <div class="col-5 text-center border-bottom">
      <h4 class="harmony-teal-text">Origin</h4>
    </div>
    <div class="col-2">

    </div>
    <div class="col-5 text-center border-bottom">
      <h4 class="harmony-teal-text">Destination</h4>
    </div>
  </div>
  <div class="row ms-1 me-1 mt-2">
    <div class="col-5">
      <div class="row">
        <div class="col-12 overflow-hidden">
          <b>{{ CompanyRequest.OriginLocationTypeName ?? 'City' }}: </b>{{ CompanyRequest.OriginLocationName ?? 'N/A' }}
        </div>
        <div class="col-12 overflow-hidden">
          <b>Port: </b>{{ CompanyRequest.DeparturePortName ?? 'N/A' }}
        </div>
      </div>
    </div>
    <div class="col-2 text-center pt-2 harmony-grey-text">
      <fa-icon [icon]="faDateArrow" size="2x"></fa-icon>
    </div>
    <div class="col-5">
      <div class="row">
        <div class="col-12 overflow-hidden">
          <b>{{ CompanyRequest.DestinationLocationTypeName ?? 'City' }}: </b>{{ CompanyRequest.DestinationLocationName ?? 'N/A' }}
        </div>
        <div class="col-12 overflow-hidden">
          <b>Port: </b>{{ CompanyRequest.ArrivalPortName ?? 'N/A' }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="accept()">Accept</button>
  <button type="button" class="btn btn-outline-danger" (click)="decline()">Decline</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="cancel()">Close</button>
</div>
