import {Component, OnInit} from '@angular/core';
import {CompanyService} from '../../../services/company.service';
import {IPage, IPaginationData} from '../../../shared/models/pagination-data.model';
import {faEdit, faFileText, faTrash} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdAuthService} from '../../../core/ad-auth-service/ad-auth.service';
import {HseService} from 'src/app/services/hse.service';
import {IBranchDto} from '../../../services/models/hse/branch.model';
import {IEnvironmentalAssessmentDto, IEnvironmentalAssessmentAnswer, IEnvironmentalAssessmentGroup, IEnvironmentalAssessmentItem, IEnvironmentalAssessmentList, IEnvironmentalAssessmentListParam} from '../../../services/models/hse/hse.model';
import {EnvironmentalAssessmentAnswerModalComponent} from './environmental-assessment-answer-modal/environmental-assessment-answer-modal.component';
import {EnvironmentalAssessmentCreateModalComponent} from './environmental-assessment-create-modal/environmental-assessment-create-modal.component';
import {EnvironmentalAssessmentReportModalComponent} from './environmental-assessment-report-modal/environmental-assessment-report-modal.component';
import {EnvironmentalAssessmentInformationModalComponent} from './environmental-assessment-information-modal/environmental-assessment-information-modal.component';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import {OpenConfirmationModal} from "../../../shared/components/confirmation-modal/confirmation-modal-functions";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { AlertService } from '../../../services/alert.service';
import { environment } from '../../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";
import { ICompanyImagesDto } from '../../../services/models/member.model';

@Component({
  selector: 'app-environmental-assessment-overview',
  templateUrl: './environmental-assessments-overview.component.html',
  styleUrls: ['./environmental-assessments-overview.component.scss'],
})
export class EnvironmentalAssessmentsOverviewComponent implements OnInit {
  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;

  // Component Variables
  paginationData: IPaginationData<IEnvironmentalAssessmentList> = null;
  environmentalAssessment: IEnvironmentalAssessmentDto;
  environmentalAssessmentGroups: IEnvironmentalAssessmentGroup[];
  environmentalAssessmentItems: IEnvironmentalAssessmentItem[];
  environmentalAnswer: IEnvironmentalAssessmentAnswer;
  companyImages: ICompanyImagesDto;
  page: IPage;
  showOpenIRL: boolean = false;
  showOpenRRL: boolean = false;
  showNeedAttention: boolean = false;
  filteredIRL: number = 0;
  filteredRRL: number = 0;
  branch: IBranchDto;
  showWatermark: boolean = false;
  public environmentalAssessmentSearchParam: IEnvironmentalAssessmentListParam = {
    CompanyId: null,
    BranchId: null,
    Year: null,
    AssessmentStatusId: null,
    CurrentPage: 1,
    RowCount: 15
  };
  isExpired: boolean = false;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  environmentalStatus$ = this.hseService.EnvironmentalAssessmentStatus$.asObservable();

  // Permissions
  public readonly PermissionCodes = PermissionCodes;
  canCreateNew: boolean = false;
  canViewCompanies: boolean = false;
  canEditInformation: boolean = false;
  canEditName: boolean = false;

  // Shared variables
  loading: boolean = false;
  loadingEnvironmentalAssessment: boolean = false;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  protected readonly environment = environment;

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private route:ActivatedRoute,
              public router: Router,
              public authService: AdAuthService,
              private hseService: HseService) {
  }

  ngOnInit() {
      this.paginationData = {
          DataSet: [],
          CurrentPage: 1,
          PageSize: 15,
          TotalPages: 1
      } as IPaginationData<IEnvironmentalAssessmentList>;

      this.page = {
          pageNumber: this.paginationData.CurrentPage,
          pageSize: this.paginationData.PageSize
      } as IPage;

    if (this.authService.CurrentUser) {
      this.setPermissions();

      this.getEnvironmentalAssessments();

    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
          this.getEnvironmentalAssessments();
        });
    }

    this.route.paramMap.subscribe(paramMap => {
      const envAssessmentId = paramMap.get('envAssessmentId');

      if(envAssessmentId) {
        return this.openEnvironmentalAssessment(Number(envAssessmentId));
      }
    });

  }

  setPermissions() {
    this.canCreateNew = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment_CreateNew);
    this.canViewCompanies = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment_CompanyFilter);
    if (!this.canViewCompanies) {
      this.environmentalAssessmentSearchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
    }
    this.canEditInformation = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment_EditInformation);
    this.canEditName = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment_EditName);
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.environmentalAssessmentSearchParam.CurrentPage = page.pageNumber;
    this.environmentalAssessmentSearchParam.RowCount = page.pageSize;
    this.getEnvironmentalAssessments();
  }

  navigateToCompanyChart(envAssessmentId: number) {
    this.router.navigate(['hse/environmental-risk-assessment/' + envAssessmentId]).catch();
  }

  searchEnvironmentalAssessments() {
    this.page.pageNumber = 1
    this.environmentalAssessmentSearchParam.CurrentPage = this.page.pageNumber;
    this.getEnvironmentalAssessments();
  }

  getEnvironmentalAssessments() {
    this.loading = true;
    this.hseService.getEnvironmentalAssessments(this.environmentalAssessmentSearchParam)
      .subscribe({
        next: (data) : void => {
          this.paginationData = Object.assign([], data);
          this.noResult = this.paginationData.DataSet.length == 0;
          this.loading = false;
        },
        error: () : void => {
          this.loading = false;
        }
      });
  }

  openEnvironmentalAssessment(environmentalAssessmentId: number) {
    this.loadingEnvironmentalAssessment = true;
    this.hseService.getEnvironmentalAssessmentById(environmentalAssessmentId)
      .subscribe({
        next: (data) : void => {
          this.environmentalAssessment = Object.assign([], data);
          this.isExpired = this.environmentalAssessment.AssessmentStatusName == 'Expired';
          this.loadAssessmentGroups();
          this.loadCompanyImages();
          this.loadingEnvironmentalAssessment = false;
        },
        error: () : void => {
          this.loadingEnvironmentalAssessment = false;
        }
      });
  }

  getGroupItems(groupId: number): IEnvironmentalAssessmentItem[] {
    let filteredItems = this.environmentalAssessmentItems.filter(x => x.AssessmentGroupId === groupId &&
      (
        (this.showOpenIRL == true && (x.IRL == 0 || x.IRL == null) || this.showOpenIRL == false) &&
        (this.showOpenRRL == true && (x.RRL == 0 || x.AnswerModifiedDate == null || x.RRL == null) || this.showOpenRRL == false) &&
        (this.showNeedAttention == true && x.IRL > 70 || this.showNeedAttention == true && x.RRL > 70 || this.showNeedAttention == false))
      );

    this.filteredIRL = filteredItems.filter(x => (x.IRL == 0 || x.IRL == null)).length;
    this.filteredRRL = filteredItems.filter(x => (x.RRL == 0 || x.RRL == null || x.AnswerModifiedDate == null)).length;

    return filteredItems;
  }

  loadAssessmentGroups() {
    this.loadingEnvironmentalAssessment = true;

    this.hseService.getEnvironmentalAssessmentGroups()
      .subscribe({
        next: (data) : void => {
          this.environmentalAssessmentGroups = Object.assign([], data);
          this.hseService.getListEnvironmentalAssessmentItems(this.environmentalAssessment.Id)
            .subscribe({
              next: (data) : void => {
                this.environmentalAssessmentItems = Object.assign([], data);
                this.loadingEnvironmentalAssessment = false;
              },
              error: () : void => {
                this.loadingEnvironmentalAssessment = false;
              }
            });
        },
        error: () : void => {
          this.loadingEnvironmentalAssessment = false;
        }
      });
  }

  loadCompanyImages() {
    this.companyService.getCompanyImages(this.environmentalAssessment.CompanyId)
      .subscribe({
        next: (data) : void => {
          this.companyImages = Object.assign({}, data);
        },
        error: () : void => {
          this.alertService.warn('Could not load company image data.');
        }
    });
  }

  updateName() {
    const param = {...this.environmentalAssessment};
    param.EnvironmentalAssessmentAnswers = null;

    this.hseService.updateEnvironmentalAssessment(param)
      .subscribe({
        next: () => {
          this.alertService.success('Assessment name successfully updated.');
        },
        error: (error) => {
          this.alertService.error('An error occurred: ' + error.Meta.Message);
        }
      });
  }

  back(shouldRefresh: boolean) {
    this.environmentalAssessment = null;
    this.showWatermark = false;
    if (shouldRefresh){
      this.getEnvironmentalAssessments();
    }
  }

  openInfoModal() {
    const modalRef = this.modalService.open(EnvironmentalAssessmentInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.environmentalAssessment.CompanyId;
    modalRef.componentInstance.hasAccess = this.canEditInformation;
  }

  openEnvironmentalAssessmentAnswerModal(assessmentItem: IEnvironmentalAssessmentItem, assessmentGroup: IEnvironmentalAssessmentGroup) {
    const modalRef = this.modalService.open(EnvironmentalAssessmentAnswerModalComponent, {size: 'lg', backdrop: 'static'});

    this.environmentalAnswer = this.environmentalAssessment.EnvironmentalAssessmentAnswers.find(i => i.AssessmentItemId === assessmentItem.Id);
    if (this.environmentalAnswer == null) {
      modalRef.componentInstance.environmentalAssessmentAnswer = {
        EnvironmentalAssessmentId: this.environmentalAssessment.Id,
        AssessmentItemId: assessmentItem.Id,
        ExposureValue: null,
        Likeliness: null,
        SeverityValue: null,
        InfluenceValue: null,
        MeasureValue: null,
        LevelOfMeasuresId: null,
        StatusId: null,
        RemainingExposure: null,
        RemainingLikeliness: null,
        RemainingSeverity: null,
        CreatedByUserId: this.authService.CurrentUser.UserId,
        Active: true

      } as IEnvironmentalAssessmentAnswer;
    } else {
      modalRef.componentInstance.environmentalAssessmentAnswer = this.environmentalAnswer;
    }
    modalRef.componentInstance.environmentalAssessmentItem = assessmentItem;
    modalRef.componentInstance.environmentalAssessmentGroup = assessmentGroup;
    // On modal close, read the result and apply logic
    modalRef.result.then((environmentalAssessmentAnswer: IEnvironmentalAssessmentAnswer) => {
      if (environmentalAssessmentAnswer != null) {
        this.environmentalAnswer = environmentalAssessmentAnswer;
        assessmentItem.AnswerModifiedDate = this.environmentalAnswer.DateModified;
        assessmentItem.IRL = environmentalAssessmentAnswer.RiskLevel1;
        assessmentItem.RRL = environmentalAssessmentAnswer.RemainingTotal;
        this.environmentalAssessment.EnvironmentalAssessmentAnswers.push(this.environmentalAnswer);
      }
    });
  }

  openEnvironmentalAssessmentReport(environmentalAssessment: IEnvironmentalAssessmentList){
    const modalRef = this.modalService.open(EnvironmentalAssessmentReportModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.environmentalAssessment = environmentalAssessment;
    modalRef.result.then(() => { });
  }

  openEnvironmentalAssessmentCreate(){
    const modalRef = this.modalService.open(EnvironmentalAssessmentCreateModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.result.then((shouldReload: boolean) => {
        if (shouldReload) {
            this.getEnvironmentalAssessments();
        }
    });
  }

  deleteEnvironmentalAssessment(environmentalAssessmentId: number){
    const message = "Are you sure you want to delete this Environmental Risk Assessment?";
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (environmentalAssessmentId != null) {
            this.loading = true;
            this.hseService.deleteEnvironmentalAssessment(environmentalAssessmentId)
              .subscribe({
                next: () => {
                  this.getEnvironmentalAssessments();
                  this.loading = false;
                },
                error: () => {
                  this.loading = false;
                }
              });
          }
        }
      });
  }

  setIRLRiskLevelColor(riskItem: IEnvironmentalAssessmentItem): string {
    let irlLevel = riskItem.IRL;

    if (irlLevel == null) {
      return '';
    }
    if (irlLevel >= 0 && irlLevel <= 20) {
      return 'risk-level-green';
    }
    if (irlLevel > 20 && irlLevel <= 70) {
      return 'risk-level-lightgreen';
    }
    if (irlLevel > 70 && irlLevel <= 200) {
      return 'risk-level-yellow';
    }
    if (irlLevel > 200 && irlLevel <= 400) {
      return 'risk-level-orange';
    }
    if (irlLevel > 400) {
      return 'risk-level-red';
    }
  }

  setRRLRiskLevelColor(riskItem: IEnvironmentalAssessmentItem): string {
    let rrlLevel = riskItem.RRL;

    if (rrlLevel == null || riskItem.AnswerModifiedDate == null) {
      return '';
    }
    if (rrlLevel >= 0 && rrlLevel <= 20) {
      return 'risk-level-green';
    }
    if (rrlLevel > 20 && rrlLevel <= 70) {
      return 'risk-level-lightgreen';
    }
    if (rrlLevel > 70 && rrlLevel <= 200) {
      return 'risk-level-yellow';
    }
    if (rrlLevel > 200 && rrlLevel <= 400) {
      return 'risk-level-orange';
    }
    if (rrlLevel > 400) {
      return 'risk-level-red';
    }
  }

  generateYearsBetween() {
    const startYear: number = 2014;
    let endYear: number = new Date().getFullYear() + 1;
    let years = [];

    for (let i = endYear; i >= startYear; i--) {
      years.push(endYear);
      endYear--;
    }
    return years;
  }
}
