import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { loggerCallback } from '../../app.module';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// Define MSAL Instance Factory configuration
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.b2cPolicies.applicationID,
      authority: environment.b2cPolicies.authorities.signIn.authority,
      redirectUri: environment.b2cPolicies.redirectUrl,
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
      postLogoutRedirectUri: environment.b2cPolicies.redirectUrl,
      navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
    }
  }
};

// Define permissions defined in the AD
export const protectedResources = {
  harmonyAPI: {
    endpoint: environment.api_url.replace('/api/', ''),
    scopes: []
  }
};
