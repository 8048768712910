import { Component } from '@angular/core';

@Component({
  selector: 'app-schema-manager',
  templateUrl: './schema-manager.component.html',
  styleUrls: ['./schema-manager.component.scss']
})
export class SchemaManagerComponent {

}
