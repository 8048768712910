import { Component } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-publish-modal',
  templateUrl: './article-review-comment-modal.component.html',
  styleUrls: ['./article-review-comment-modal.component.scss']
})
export class ArticleReviewCommentModalComponent {
  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faDownload = faDownload;

  // Component variables
  public ArticleId: string;
  public Message: string;
  public Title: string;
  comment: string = null;
  invalid: boolean = false;

  // General variables
  env = environment;

  constructor(private activeModal: NgbActiveModal) { }

  save() {
    if (this.ArticleId && this.comment?.length > 0) {
      this.activeModal.close(this.comment);
    } else {
      this.invalid = false;
    }
  }

  close() {
    this.activeModal.close(null);
  }
}
