import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { ApiService } from '../../../../services/api.service';
import { IDangerousSubstanceResponse, ISubstancePhrase } from 'src/app/services/models/hse/dangerous-substance-register.model';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { IActivity, IHRPhrase, ILocation, IPSPhrase } from '../../../../services/models/hse/hse.model';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import { HseDsrService } from '../../../../services/hse-dsr.service';
import { HseService } from '../../../../services/hse.service';
import { ICompanyUserDto } from '../../../../services/models/user.model';
import { CompanyService } from '../../../../services/company.service';

@Component({
  selector: 'app-dangerous-substance-modal',
  templateUrl: './dangerous-substance-modal.component.html',
  styleUrls: ['./dangerous-substance-modal.component.scss'],
  providers: [HseDsrService]
})
export class DangerousSubstanceModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  // Icons
  faInfo = faCircleQuestion;
  faDelete = faTrash;

  // Component variables
  public substanceId: number;
  public isNew: false;
  public hasCompanyFilterPermission: false;
  public canEdit = false;
  public dsForm: UntypedFormGroup;
  public substance: IDangerousSubstanceResponse;
  public companyId: number = 0;
  public languageCode = 'en';
  selectedLanguage = 'Language';
  Locations$ = this.hseDsrService.Locations$.asObservable();
  locations: ILocation[] = null;
  Activities$ = this.hseDsrService.Activities$.asObservable();
  activities: IActivity[] = null;
  hrPhrases$ = this.hseDsrService.HRPhrases$.asObservable();
  hrPhrases: IHRPhrase[] = null;
  psPhrases$ = this.hseDsrService.PSPhrases$.asObservable();
  psPhrases: IPSPhrase[] = null;
  users: ICompanyUserDto[] = null;
  currentHrPhrases = [] as IHRPhrase[];
  currentPsPhrases = [] as IPSPhrase[];
  newHrPhrases = [] as ISubstancePhrase[];
  newPsPhrases = [] as ISubstancePhrase[];
  branches: IBranchDto[] = [];

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private apiService: ApiService,
              private companyService: CompanyService,
              private hseDsrService: HseDsrService,
              private hseService: HseService,
              private cdRef: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.substance = {} as IDangerousSubstanceResponse;
    this.dsForm = {} as UntypedFormGroup;
    this.subscribeToDropDowns();
    this.createAndFillForm();

    if (!this.isNew) {
      this.getDangerousSubstance();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  subscribeToDropDowns() {
    this.Locations$.subscribe({
      next: value => {
        this.locations = value;
        this.locations = this.locations.filter(x => x.LanguageCode.toLowerCase() == this.languageCode.toLowerCase());
      }
    });
    this.Activities$.subscribe({
      next: value => {
        this.activities = value;
        this.activities = this.activities.filter(x => x.LanguageCode.toLowerCase() == this.languageCode.toLowerCase());
      }
    });
    this.psPhrases$.subscribe({
      next: value => {
        this.psPhrases = value;
        this.psPhrases = this.psPhrases.filter(x => x.LanguageCode.toLowerCase() == this.languageCode.toLowerCase());
      }
    });
    this.hrPhrases$.subscribe({
      next: value => {
        this.hrPhrases = value;
        this.hrPhrases = this.hrPhrases.filter(x => x.LanguageCode.toLowerCase() == this.languageCode.toLowerCase());
      }
    });
  }

  getDangerousSubstance() {
    this.apiService.get(`DangerousSubstanceRegister/${this.substanceId}`)
      .subscribe({
        next: (data) => {
          this.substance = Object.assign({}, data);
          this.createAndFillForm();
          this.setCurrentPhrases();
        }
      });
  }

  // FORM Functions
  createAndFillForm() {
    if (this.companyId) {
      this.substance.CompanyId = this.companyId;
    }
    this.dsForm = this.formBuilder.group({
      name: new UntypedFormControl({value: this.substance.Name, disabled: !this.canEdit}, [Validators.required]),
      product: new UntypedFormControl({value: this.substance.ProductName, disabled: !this.canEdit}, Validators.required),
      quantity: new UntypedFormControl({value: this.substance.Quantity, disabled: !this.canEdit}, Validators.required),
      quantityType: new UntypedFormControl({value: this.substance.QuantityMeasurement, disabled: !this.canEdit}, Validators.required),
      unNumber: new UntypedFormControl({value: this.substance.UNNumber, disabled: !this.canEdit}, Validators.required),
      emergencyNumber: new UntypedFormControl({value: this.substance.EmergencyNumber, disabled: !this.canEdit}, Validators.required),
      usage: new UntypedFormControl({value: this.substance.Usage, disabled: !this.canEdit}),
      // ID's
      locationId: new UntypedFormControl({value: this.substance.LocationId, disabled: !this.canEdit || !this.locations}, [Validators.required, Validators.min(1)]),
      activityId: new UntypedFormControl({value: this.substance.ActivityId, disabled: !this.canEdit || !this.activities}, [Validators.required, Validators.min(1)]),
      branchId: new UntypedFormControl({value: this.substance.BranchId, disabled: !this.canEdit}),
      companyId: new UntypedFormControl({value: this.companyId ?? this.substance.CompanyId, disabled: !this.canEdit}, [Validators.required, Validators.min(1)]),
      userId: new UntypedFormControl({value: this.substance.OwnerUserId, disabled: this.users?.length <= 0 || !this.canEdit}, [Validators.required, Validators.min(1)]),
      // Comment text section
      general: new UntypedFormControl({value: this.substance.General, disabled: !this.canEdit}),
      storage: new UntypedFormControl({value: this.substance.Storage, disabled: !this.canEdit}),
      transport: new UntypedFormControl({value: this.substance.Transport, disabled: !this.canEdit}),
      ventilation: new UntypedFormControl({value: this.substance.Ventilation, disabled: !this.canEdit}),
      fire: new UntypedFormControl({value: this.substance.Fire, disabled: !this.canEdit}),
      firstAid: new UntypedFormControl({value: this.substance.FirstAid, disabled: !this.canEdit}),
      spillage: new UntypedFormControl({value: this.substance.Spillage, disabled: !this.canEdit}),
      disposal: new UntypedFormControl({value: this.substance.Disposal, disabled: !this.canEdit}),
      comment: new UntypedFormControl({value: this.substance.Comment, disabled: !this.canEdit}),
      supplier: new UntypedFormControl({value: this.substance.Supplier, disabled: !this.canEdit})
    });
    this.populateDropdowns(this.substance.CompanyId);
  }

  setCurrentPhrases() {
    if (this.substance.SubstancePhrases?.length > 0) {
      const hrPhrases = this.substance.SubstancePhrases.filter(x => x.HazardRiskPhrase).map(x => x.HazardRiskPhrase);
      if (hrPhrases?.length > 0) {
        this.currentHrPhrases = hrPhrases;
      }
      const psPhrases = this.substance.SubstancePhrases.filter(x => x.ProtectionSafetyPhrase).map(x => x.ProtectionSafetyPhrase);
      if (psPhrases?.length > 0) {
        this.currentPsPhrases = psPhrases;
      }
    }
  }

  isFormValid() {
    this.dsForm.markAllAsTouched();
    this.dsForm.markAsTouched();
    if (this.dsForm.invalid) {
      this.alertService.error('Please check all mandatory fields marked with *');
    }
    return this.dsForm.valid;
  }

  // FOOTER FUNCTIONS
  add() {
    if (this.isFormValid()) {
      this.updateSubstanceModel();
      this.apiService.post('DangerousSubstanceRegister', this.substance)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to add substance');
          }
        });
    }
  }

  update() {
    if (this.isFormValid()) {
      this.updateSubstanceModel();
      this.apiService.put('DangerousSubstanceRegister', this.substance)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to update substance');
          }
        });
    }
  }

  updateSubstanceModel() {
    this.substance.Name = this.dsForm.controls.name.value;
    this.substance.ProductName = this.dsForm.controls.product.value;
    this.substance.Quantity = this.dsForm.controls.quantity.value;
    this.substance.QuantityMeasurement = this.dsForm.controls.quantityType.value;
    this.substance.UNNumber = this.dsForm.controls.unNumber.value;
    this.substance.EmergencyNumber = this.dsForm.controls.emergencyNumber.value;
    this.substance.Usage = this.dsForm.controls.usage.value;
    this.substance.LocationId = this.dsForm.controls.locationId.value;
    this.substance.ActivityId = this.dsForm.controls.activityId.value;
    this.substance.BranchId = this.dsForm.controls.branchId.value;
    this.substance.OwnerUserId = this.dsForm.controls.userId.value;
    this.substance.General = this.dsForm.controls.general.value;
    this.substance.Storage = this.dsForm.controls.storage.value;
    this.substance.Transport = this.dsForm.controls.transport.value;
    this.substance.Ventilation = this.dsForm.controls.ventilation.value;
    this.substance.Fire = this.dsForm.controls.fire.value;
    this.substance.FirstAid = this.dsForm.controls.firstAid.value;
    this.substance.Spillage = this.dsForm.controls.spillage.value;
    this.substance.Disposal = this.dsForm.controls.disposal.value;
    this.substance.Comment = this.dsForm.controls.comment.value;
    this.substance.Supplier = this.dsForm.controls.supplier.value;

    this.substance.SubstancePhrases = [];
    if (this.newHrPhrases) {
      this.substance.SubstancePhrases.push(...this.newHrPhrases);
    }
    if (this.newPsPhrases) {
      this.substance.SubstancePhrases.push(...this.newPsPhrases);
    }
  }

  setLanguageCode(languageCode: string) {
    this.languageCode = languageCode;
    this.selectedLanguage = languageCode;
    this.subscribeToDropDowns();
  }

  close(reload: boolean = false) {
    this.activeModal.close(reload);
  }

  addHrPhrase(phrase: IHRPhrase) {
    const newPhrase = {
      HazardRiskPhraseId: phrase.Id,
      HazardRiskPhrase: phrase
    } as ISubstancePhrase;
    this.newHrPhrases.unshift(newPhrase);
  }

  addPsPhrase(phrase: IPSPhrase) {
    const newPhrase = {
      ProtectionSafetyPhraseId: phrase.Id,
      ProtectionSafetyPhrase: phrase
    } as ISubstancePhrase;
    this.newPsPhrases.unshift(newPhrase);
  }

  removeHrPhrase(index: number) {
    this.newHrPhrases.splice(index, 1);
  }

  removePsPhrase(index: number) {
    this.newPsPhrases.splice(index, 1);
  }

  setMobileNumber($event: any) {
    this.dsForm.controls.emergencyNumber.setValue($event);
    this.dsForm.controls.emergencyNumber.markAsTouched();
  }

  // Populate the dropdowns based on the companyId
  populateDropdowns(id) {
    if (id) {
      this.getUsers();
      this.getBranches();
    } else {
      this.users = [];
      this.branches = [];
    }
  }

  changeCompanyId(id: number) {
    this.substance.CompanyId = id;
    this.dsForm.controls.companyId.setValue(id);
    this.dsForm.controls.companyId.markAsTouched();
    this.populateDropdowns(id);
  }

  getBranches() {
    this.hseService.getBranchesByCompanyId(this.substance.CompanyId)
      .subscribe({
        next: value => {
          if (value) {
            this.branches = Object.assign([], value);
          }
        }
      });
  }

  getUsers() {
    if (this.substance.CompanyId) {
      this.companyService.getCompanyUsers(this.substance.CompanyId).subscribe({
        next: (response: ICompanyUserDto[]) => {
          this.users = Object.assign([], response);
        }
      });
    }
  }

  deleteHrPhrase(phrase: IHRPhrase, i: number) {
    if (this.canEdit) {
      const substancePhrase = this.substance.SubstancePhrases.find(x => x.HazardRiskPhrase == phrase);
      const index = this.substance.SubstancePhrases.indexOf(substancePhrase);
      if (index > 0) {
        substancePhrase.HazardRiskPhraseId = null;
        substancePhrase.HazardRiskPhrase = null;
        this.deletePhrase(substancePhrase, index);
        this.currentHrPhrases.splice(i, 1);
      }
    }
  }

  deletePsPhrase(phrase: IPSPhrase, i: number) {
    if (this.canEdit) {

      const substancePhrase = this.substance.SubstancePhrases.find(x => x.ProtectionSafetyPhrase == phrase);
      const index = this.substance.SubstancePhrases.indexOf(substancePhrase);
      if (index > 0) {
        substancePhrase.ProtectionSafetyPhraseId = null;
        substancePhrase.ProtectionSafetyPhrase = null;
        this.deletePhrase(substancePhrase, index);
        this.currentPsPhrases.splice(i, 1);
      }
    }
  }

  deletePhrase(phrase: ISubstancePhrase, index: number) {
    const body = {
      Id: phrase.Id,
      SubstanceRegisterId: phrase.SubstanceRegisterId,
      HazardRiskPhraseId: phrase.HazardRiskPhraseId,
      ProtectionSafetyPhraseId: phrase.ProtectionSafetyPhraseId
    };
    this.apiService.put('DangerousSubstanceRegister/Phrase', body)
      .subscribe({
        next: () => {
          this.substance.SubstancePhrases.splice(index, 1);
        }
      });
  }
}
