import {NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HseMenuComponent } from './hse-menu/hse-menu.component';
import { IncidentImprovementComponent } from './incident-improvement/incident-improvement.component';
import { IncidentImprovementModalComponent } from './incident-improvement/incident-improvement-modal/incident-improvement-modal.component';
import { HseRoutingModule } from './hse-routing.module';
import { DangerousSubstanceRegisterComponent } from './dangerous-substance-register/dangerous-substance-register.component';
import { DangerousSubstanceModalComponent } from './dangerous-substance-register/dangerous-substance-modal/dangerous-substance-modal.component';
import { DangerousSubstanceInformationModalComponent } from './dangerous-substance-register/dangerous-substance-information-modal/dangerous-substance-information-modal.component';
import { SafetyChartComponent } from './dangerous-substance-register/safety-chart/safety-chart.component';
import { PulseChartComponent } from './pulse-chart/pulse-chart.component';
import { PulseChartInformationComponent } from './pulse-chart/pulse-chart-information/pulse-chart-information.component';
import { PulseChartModalComponent } from './pulse-chart/pulse-chart-modal/pulse-chart-modal.component';
import { RiskAssessmentsOverviewComponent } from './risk-assessment/risk-assessments-overview.component';
import { RiskAssessmentAnswerModalComponent } from './risk-assessment/risk-assessment-answer-modal/risk-assessment-answer-modal.component';
import { CompanyChartCreateModalComponent } from './company-chart/company-chart-create-modal/company-chart-create-modal.component';
import { RiskAssessmentCreateModalComponent } from './risk-assessment/risk-assessment-create-modal/risk-assessment-create-modal.component';
import { RiskAssessmentReportModalComponent } from './risk-assessment/risk-assessment-report-modal/risk-assessment-report-modal.component';
import { RiskAssessmentInformationModalComponent } from './risk-assessment/risk-assessment-information-modal/risk-assessment-information-modal.component';
import { HseService } from '../../services/hse.service';
import { EmergencyComponent } from './emergency/emergency.component';
import { EmergencyPlanDocumentModalComponent } from './emergency/emergency-plan-document-modal/emergency-plan-document-modal.component';
import { EmergencyDrillEvaluationModalComponent } from './emergency/emergency-drill-evaluation-modal/emergency-drill-evaluation-modal.component';
import {
  EnvironmentalAssessmentInformationModalComponent
} from './environmental-assessment/environmental-assessment-information-modal/environmental-assessment-information-modal.component';
import { EnvironmentalAssessmentsOverviewComponent } from './environmental-assessment/environmental-assessments-overview.component';
import { EnvironmentalAssessmentAnswerModalComponent } from './environmental-assessment/environmental-assessment-answer-modal/environmental-assessment-answer-modal.component';
import { EnvironmentalAssessmentCreateModalComponent } from './environmental-assessment/environmental-assessment-create-modal/environmental-assessment-create-modal.component';
import { EnvironmentalAssessmentReportModalComponent } from './environmental-assessment/environmental-assessment-report-modal/environmental-assessment-report-modal.component';
import { CompanyChartOverviewComponent } from './company-chart/company-chart-overview.component';
import { CompanyChartItemModalComponent } from './company-chart/company-chart-item-modal/company-chart-item-modal.component';
import { CompanyChartInformationModalComponent } from './company-chart/company-chart-information-modal/company-chart-information-modal.component';
import { CompanyChartCategoryModalComponent } from './company-chart/company-chart-category-modal/company-chart-category-modal.component';
import { CompanyChartItemDocumentModalComponent } from './company-chart/company-chart-item-document-modal/company-chart-item-document-modal.component';
import { EnvironmentalImpactDataComponent } from './environmental-impact-data/environmental-impact-data.component';
import { ImpactDataInformationModalComponent } from './environmental-impact-data/impact-data-information-modal/impact-data-information-modal.component';
import { CompanyChartViewComponent } from './company-chart/company-chart-view/company-chart-view.component';
import { EmailCaptureComponent } from '../../shared/components/email-capture/email-capture.component';
import {
  DashboardContainerHseComponent
} from "./hse-dashboard/dashboard-container-hse/dashboard-container-hse.component";
import {
  DashboardCardIncidentImprovementComponent
} from "./hse-dashboard/dashboard-card-incident-improvement/dashboard-card-incident-improvement.component";
import {
  DashboardCardCompanyChartComponent
} from "./hse-dashboard/dashboard-card-company-chart/dashboard-card-company-chart.component";
import {
  DashboardCardHealthSafetyComponent
} from "./hse-dashboard/dashboard-card-health-safety/dashboard-card-health-safety.component";
import {
  DashboardCardEnvironmentComponent
} from "./hse-dashboard/dashboard-card-environment/dashboard-card-environment.component";
import {
  DashboardCardPulseChartComponent
} from "./hse-dashboard/dashboard-card-pulse-chart/dashboard-card-pulse-chart.component";
import {
  DashboardCardHseArticlesComponent
} from "./hse-dashboard/dashboard-card-hse-articles/dashboard-card-hse-articles.component";
import {ArticleItemComponent} from "../../shared/components/article-item/article-item.component";
import { HseDocumentsComponent } from './hse-documents/hse-documents.component';
import { DocumentListComponent } from '../../shared/components/document-list/document-list.component';
import {
  DashboardCardHseDocumentListComponent
} from "./hse-dashboard/dashboard-card-hse-document-list/dashboard-card-hse-document-list.component";
import {DocumentTypeIconComponent} from "../../shared/components/document-type-icon/document-type-icon.component";


@NgModule({
  declarations: [
    HseMenuComponent,
    IncidentImprovementComponent,
    IncidentImprovementModalComponent,
    DangerousSubstanceRegisterComponent,
    DangerousSubstanceModalComponent,
    DangerousSubstanceInformationModalComponent,
    SafetyChartComponent,
    PulseChartComponent,
    PulseChartInformationComponent,
    PulseChartModalComponent,
    RiskAssessmentsOverviewComponent,
    RiskAssessmentAnswerModalComponent,
    RiskAssessmentCreateModalComponent,
    RiskAssessmentReportModalComponent,
    RiskAssessmentInformationModalComponent,
    EnvironmentalAssessmentInformationModalComponent,
    EnvironmentalAssessmentsOverviewComponent,
    EnvironmentalAssessmentAnswerModalComponent,
    EnvironmentalAssessmentCreateModalComponent,
    EnvironmentalAssessmentReportModalComponent,
    EmergencyComponent,
    EmergencyPlanDocumentModalComponent,
    EmergencyDrillEvaluationModalComponent,
    EnvironmentalImpactDataComponent,
    ImpactDataInformationModalComponent,
    EmergencyDrillEvaluationModalComponent,
    CompanyChartCreateModalComponent,
    CompanyChartOverviewComponent,
    CompanyChartItemModalComponent,
    CompanyChartInformationModalComponent,
    CompanyChartCategoryModalComponent,
    CompanyChartItemDocumentModalComponent,
    CompanyChartViewComponent,
    DashboardContainerHseComponent,
    DashboardCardIncidentImprovementComponent,
    DashboardCardCompanyChartComponent,
    DashboardCardHealthSafetyComponent,
    DashboardCardEnvironmentComponent,
    DashboardCardPulseChartComponent,
    DashboardCardHseArticlesComponent,
    CompanyChartViewComponent,
    HseDocumentsComponent,
    DashboardCardHseDocumentListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    HseRoutingModule,
    EmailCaptureComponent,
    ArticleItemComponent,
    DocumentListComponent,
    DocumentTypeIconComponent,
  ],
  exports: [
    HseMenuComponent,
    SafetyChartComponent
  ],
  providers: [
    HseService
  ]
})
export class HseModule {
}
