import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing-admin',
  templateUrl: './pricing-admin.component.html',
  styleUrls: ['./pricing-admin.component.scss']
})
export class PricingAdminComponent {

}
