<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h1 class="h2 harmony-grey-text">
    New Environmental Risk Assessment
  </h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(false)"> </button>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row ms-1 me-1 my-1">
    <div class="col-1">
      <b>Company</b>
    </div>
    <div class="col-5">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="companyId"
              [disabled]="loading || !(companies$ | async)">
        <option [ngValue]="0" selected>Select a company...</option>
        <option *ngFor="let company of (companies$ | async)"
                [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
      </select>
    </div>
  </div>
<!--  <div class="row ms-1 me-1 my-1">-->
<!--    <div class="col-1" *ngIf="(!admin && branches.length > 0) || admin">-->
<!--      <b>Branch</b>-->
<!--    </div>-->
<!--    <div class="col-5" *ngIf="(!admin && branches.length > 0) || admin">-->
<!--      <select class="form-select form-select-sm pointer"-->
<!--              [(ngModel)]="branch"-->
<!--              [disabled]="loading || !companyId">-->
<!--        <option [ngValue]="null" selected>Select a branch...</option>-->
<!--        <option *ngFor="let branch of branches"-->
<!--                [ngValue]="branch">{{ branch.Name }}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->
  <div class="row ms-1 me-1 my-1">
    <div class="col-1">
      <b>Year</b>
    </div>
    <div class="col-5">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="selectedYear">
        <option *ngFor="let year of allowedYears" [value]="year">{{ year }}</option>
      </select>
<!--      <input class="form-control form-control-sm"-->
<!--             type="text"-->
<!--             numbersOnly="4.0"-->
<!--             [placeholder]="'e.g. ' + currentYear"-->
<!--             [(ngModel)]="currentYear">-->
    </div>
  </div>
  <div class="row ms-1 me-1 my-1">
    <div class="col-1">
      <b>Name</b>
    </div>
    <div class="col-5">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="assessmentName">
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="createEnvironmentalAssessment()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
