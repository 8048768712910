<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ HRPhrase?.Id != null ? HRPhrase.Description : 'New Hazard Risk phrase'}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"> </button>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Description
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [ngClass]="{ 'is-invalid': HRPhrase.Description == null }"
                [rows]="4"
                [(ngModel)]="HRPhrase.Description">
        </textarea>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Language
    </div>
    <div class="col-2">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="HRPhrase.LanguageCode"
              [ngClass]="{ 'is-invalid': HRPhrase.LanguageCode == null }">
        <option [ngValue]="null" selected>All languages...</option>
        <option [ngValue]="'NL'" selected>NL</option>
        <option [ngValue]="'EN'" selected>EN</option>
        <option [ngValue]="'DE'" selected>DE</option>
        <option [ngValue]="'PT'" selected>PT</option>
      </select>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
   <button type="button" class="btn btn-primary harmony-purple-button" (click)="savePhrase()">Save</button>
   <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

