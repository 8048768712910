
<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <!--Header-->
        <h5 class="harmony-teal-text fw-bold mt-2">Company Chart</h5>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="companyChartReportDto?.length > 0">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-2 text-center harmony-blue-text label align-self-center">OK</div>
              <div class="col-2 text-center harmony-blue-text label align-self-center">Requires<br>attention</div>
              <div class="col-2 text-center harmony-blue-text label align-self-center">Overdue</div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <hr class="my-1">
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let report of companyChartReportDto">
            <div class="row mt-2 subject pointer" (click)="viewCompanyChart(report.CompanyChartId)">
              <div class="harmony-blue-link fw-bold col-6 label overflow-hidden" style="text-overflow: ellipsis; white-space: nowrap;">
                {{ report.CompanyChartName }}
              </div>
              <div class="col-2 text-center subject">
                <span class="cc-numbers bg-open">{{ report.OpenItems }}</span>
              </div>
              <div class="col-2 text-center subject">
                <span class="cc-numbers bg-attention">{{ report.RequiresAttentionItems }}</span>
              </div>
              <div class="col-2 text-center subject">
                <span class="cc-numbers bg-overdue">{{ report.OverdueItems }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="companyChartReportDto?.length <= 0">
            <div class="row label">
              <div class="col-12">
                <small>No company charts available</small>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

