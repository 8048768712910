import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-service-modal',
  templateUrl: './export-single-lane-rates-modal.component.html',
  styleUrls: ['./export-single-lane-rates-modal.component.scss']
})
export class ExportSingleLaneRatesModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  accept() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.dismiss(false);
  }
}
