import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEnvironmentalImpactData } from '../../../services/models/hse/environmental-dashboard-model';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { CompanyService } from '../../../services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { ApiService } from '../../../services/api.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { ImpactDataInformationModalComponent } from './impact-data-information-modal/impact-data-information-modal.component';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IApiResponse } from '../../../shared/models/api-reponse.model';
import { IActiveCompany, ICompanyImagesDto } from '../../../services/models/member.model';

@Component({
  selector: 'app-environmental-impact-data',
  templateUrl: './environmental-impact-data.component.html',
  styleUrls: ['./environmental-impact-data.component.scss']
})
export class EnvironmentalImpactDataComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faEdit;
  faView = faEye;
  faDelete = faTrash;
  faInfo = faCircleQuestion;

  // Component variables
  selectedCompanyId: number = null;
  selectedCompany: IActiveCompany = null;
  companyImages: ICompanyImagesDto;
  data: IEnvironmentalImpactData[] = [];
  invalid: boolean;
  newEntry: boolean;
  showWatermark: boolean = false;
  companies$ = this.companyService.ActiveMembers$.asObservable();

  // Permissions
  canAdd: boolean = false;
  canFilterCompany: boolean;
  canDelete: boolean;
  canEdit: boolean;

  // General Variables
  loading: boolean;
  noResult: boolean = true;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private apiService: ApiService,
              private authService: AdAuthService) {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.selectCompany(this.authService.CurrentUser.User.CompanyId);
      this.assignPermissions();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.selectCompany(this.authService.CurrentUser.User.CompanyId);
        this.assignPermissions();
      });
  }

  assignPermissions() {
    this.canFilterCompany = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_CompanyFilter);
    this.canAdd = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Add);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Delete);
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Edit);
  }

  selectCompany($event: number) {
    this.selectedCompanyId = $event;
    this.setCompanyData();
    if (!!$event) {
      this.getImpactData();
    }
  }

  setCompanyData() {
    this.companies$.subscribe((x) => {
      if (this.selectedCompanyId) {
        this.selectedCompany = x.find(l => l.CompanyId === this.selectedCompanyId);
      }
    });

    this.companyService.getCompanyImages(this.selectedCompanyId)
      .subscribe({
        next: (data) : void => {
          this.companyImages = Object.assign({}, data);
        },
        error: () : void => {
          this.alertService.warn('Could not load company image data.');
        }
      });
  }

  getImpactData() {
    this.loading = true;
    this.apiService.get(`EnvironmentalImpactData/ByCompanyId/${this.selectedCompanyId}`)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.data = data;
          this.noResult = this.data.length == 0;
        }, error: () => {
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  calculateCO2(value: number, index: number, calculation: number) {
    if (!!value) {
      return ((value * calculation) / 1000).toFixed(2);
    }
    return 0;
  }

  openInfoModal() {
    if (!this.selectedCompanyId) {
      this.alertService.error('Please select company');
      return;
    }
    const modalRef = this.modalService.open(ImpactDataInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.selectedCompanyId;
    modalRef.componentInstance.hasAccess = this.canEdit;
  }

  upsert(item: IEnvironmentalImpactData) {
    if (!item.Year || item.Year < 2000) { // general measurement
      this.invalid = true;
      this.alertService.error('Please enter a valid year from 2000');
      return;
    }
    this.invalid = false;
    if (item.IsNew) {
      this.add(item);
    } else {
      this.update(item);
    }
  }

  update(item: IEnvironmentalImpactData) {
    this.loading = true;
    this.apiService.put(`EnvironmentalImpactData`, item)
      .subscribe({
        next: () => {
          this.getImpactData();
          this.loading = false;
          this.alertService.info('Successfully saved');
        }, error: (err: IApiResponse) => {
          this.getImpactData();
          this.alertService.error(err.Meta.Message);
          this.loading = false;
        }
      });
  }

  add(item: IEnvironmentalImpactData) {
    this.loading = true;
    this.apiService.post(`EnvironmentalImpactData`, item)
      .subscribe({
        next: () => {
          this.getImpactData();
          this.loading = false;
          this.newEntry = false;
          this.alertService.info('Successfully saved');
        }, error: (err: IApiResponse) => {
          this.newEntry = false;
          this.getImpactData();
          this.alertService.error(err.Meta.Message);
          this.loading = false;
        }
      });
  }

  delete(itemId: number, isNew: boolean) {
    if (isNew) {
      const index = this.data.findIndex(x => x.IsNew);
      this.data.splice(index, 1);
      this.newEntry = false;
      return;
    } else {
      const message = 'This cannot be undone and will permanently delete the data. Would you like to continue?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(answer => {
          if (answer) {
            this.apiService.delete(`EnvironmentalImpactData/${itemId}`)
              .subscribe({
                next: () => {
                  this.getImpactData();
                }, error: () => {
                  this.getImpactData();
                }
              });
          }
        });
    }
  }

  createEntry() {
    if (!this.selectedCompanyId || !(this.selectedCompanyId > 0)) {
      this.alertService.error('Select a company before adding a new entry');
      return;
    }
    if (!this.newEntry) {
      const item = {
        IsNew: true,
        CompanyId: this.selectedCompanyId
      } as IEnvironmentalImpactData;
      this.data.unshift(item);
      this.noResult = false;
      return;
    }
    this.alertService.warn('Please save the existing new entry before adding another.');
  }

  getColorIndicator(value: number, previousValue: number) {
    if (value > previousValue) {
      return 'level-high';
    }
    if (value < previousValue) {
      return 'level-low';
    }
    return 'level-neutral';
  }
}
