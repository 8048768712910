<div class="modal-header">
  <h4 class="modal-title">
    {{ this.ComponentId ? 'Editing Permission ID: ' + this.ComponentId : 'New Permission' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="Permission">
    <div class="row mt-1">
      <div class="col-3">
        <b>Module</b>
      </div>
      <div class="col-9">
        <select name="module"
                class="form-select form-select-sm"
                id="module"
                [ngClass]="{'is-invalid': !this.Permission.ModuleId && invalid}"
                [(ngModel)]="Permission.ModuleId">
          <option [ngValue]="null" selected disabled>Select a module...</option>
          <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
            {{module.Name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-2">
        <b>Unique Code</b>
      </div>
      <div class="col-1">
        <app-loader *ngIf="loading" [Width]="25"></app-loader>
      </div>
      <div class="col-9">
        <input type="text"
               class="form-control"
               [ngClass]="{'is-invalid': uniqueCodeExists || (!Permission.Code && invalid)}"
               [disabled]="Permission.Id != null"
               (keyup)="updateCode($event)"
               [maxLength]="8"
               [(ngModel)]="Permission.Code">
        <small *ngIf="uniqueCodeExists" class="required-text">Unique code already used</small>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>Name</b>
      </div>
      <div class="col-9" style="line-height: 11px;">
        <input type="text"
               class="form-control"
               [ngClass]="{'is-invalid': (!this.Permission.Name || this.Permission.Name.length < 3) && invalid}"
               [(ngModel)]="Permission.Name">
        <small class="harmony-grey-text">Required. Minimum length of 3 characters.</small>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <b>Description</b>
      </div>
      <div class="col-9">
        <textarea class="form-control"
                  name="componentDescription"
                  id="componentDescription"
                  [(ngModel)]="Permission.Description"
                  cols="30"
                  rows="3">
        </textarea>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveComponent()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

