<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    External Data Manager
  </h1>
</div>
<div class="row">
  <div class="col-12">
    <!--------------->
    <!---- Tabs ----->
    <!--------------->
    <ul class="nav nav-tabs" id="external-data-manager" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'prism'}"
           (click)="activeTab = 'prism'"
           id="prism-tab"
           data-bs-toggle="tab"
           href="#prism"
           role="tab"
           aria-controls="prism"
           aria-selected="true">
          Prism
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'iata'}"
           (click)="activeTab = 'iata'"
           id="iata-freight-rates-tab"
           data-bs-toggle="tab"
           href="#freight-rates"
           role="tab"
           aria-controls="freight-rates"
           aria-selected="false">
          IATA Freight Rates
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'sea-rates'}"
           (click)="activeTab = 'sea-rates'"
           id="searates-tab"
           data-bs-toggle="tab"
           href="#searates"
           role="tab"
           aria-controls="searates"
           aria-selected="false">
          Sea Rates
        </a>
      </li>
    </ul>
    <div id="external-data-manager-content" class="tab-content mt-2">
      <!------------------------>
      <!------ Prism Tab ------->
      <!------------------------>
      <div class="tab-pane fade " id="prism" role="tabpanel" aria-labelledby="prism-tab"
           [ngClass]="{'active show': activeTab == 'prism'}">
      <app-prism-tab></app-prism-tab>
      </div>
      <!------------------------------------->
      <!------ IATA Freight Rates Tab ------->
      <!------------------------------------->
      <div class="tab-pane fade" id="freight-rates" role="tabpanel" aria-labelledby="iata-freight-rates-tab"
           [ngClass]="{'active show': activeTab == 'iata'}">
        <app-iata-rates-tab></app-iata-rates-tab>
      </div>
      <!---------------------------->
      <!------ Sea Rates Tab ------->
      <!---------------------------->
      <div class="tab-pane fade" id="searates" role="tabpanel" aria-labelledby="searates-tab"
           [ngClass]="{'active show': activeTab == 'sea-rates'}">
        <app-sea-rates-tab></app-sea-rates-tab>
      </div>
    </div>
  </div>
</div>
