import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-step-locations-add-modal',
  templateUrl: './step-locations-add-modal.component.html',
  styleUrls: ['./step-locations-add-modal.component.scss']
})
export class StepLocationsAddModalComponent implements OnInit {
  // Input/Output Variables

  // Icons
  faInfo = faCircleQuestion;

  // Variables
  isSingleLocation: boolean = true;
  singleLocationName: string = null;
  multiLocationName: string = null;
  locationLimit: number;
  result: string[] = [];
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.locationLimit = environment.Pricing.TenderLocationLimit;
  }

  setIsSingle(isSingle: boolean) {
    this.isSingleLocation = isSingle;
  }

  onMultiLocationChange() {
    this.multiLocationName = this.multiLocationName.replaceAll('\t', '\n').replaceAll('\n\n', '\n');
  }

  close() {
    if (this.isSingleLocation) {
      if (this.singleLocationName.trim() === '') {
        this.result = [];
      } else {
        this.result.push(this.singleLocationName);
      }
    } else {
      const locationArray = this.multiLocationName.split('\n');
      if (locationArray && locationArray.length > 0) {
        // Check for empty strings
        locationArray.forEach((location, index) => {
          location = location.trim();
          if (location === '') {
            locationArray.splice(index, 1);
          }
        });

        // Remove duplicates && Return result
        this.result = locationArray.filter((element, index) => {
          return locationArray.indexOf(element) === index;
        });
      } else {
        this.result = [];
      }
    }

    this.activeModal.close(this.result);
  }
}
