import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICompany } from '../../services/models/member.model';
import { IAuthUser } from '../../services/models/auth.model';
import { AdAuthService } from '../../core/ad-auth-service/ad-auth.service';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-portal-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // Component variables
  company: ICompany = null;

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(public router: Router,
              public companyService: CompanyService,
              public authService: AdAuthService) { }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.loadCompanyInfo(this.authService.CurrentUser.User.CompanyId);
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.loadCompanyInfo(user.User.CompanyId);
    });
  }

  loadCompanyInfo(companyId: number) {
    if (companyId && companyId > 0) {
      this.loading = true;
      this.companyService.getCompany(companyId).subscribe((data: ICompany) => {
        if (data) {
          this.company = Object.assign({}, data);
        }
        this.loading = false;
      });
    }
  }
}
