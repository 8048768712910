import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddServiceModalComponent } from './add-service-modal/add-service-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TenderService } from '../../../../services/tender.service';
import { ITenderService } from '../../../../services/models/pricing/tender.model';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { PricingService } from '../../../../services/pricing.service';
import { IServiceType, IServiceTypeUnitType } from '../../../../services/models/pricing/rates.model';
import { faCircleQuestion, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-step-services',
  templateUrl: './step-services.component.html',
  styleUrls: ['./step-services.component.scss']
})
export class StepServicesComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faIncluded = faCheckCircle;
  protected readonly faExcluded = faXmarkCircle;

  // Lookup data
  serviceTypeUnitTypes$ = this.pricingService.ServiceUnitTypes$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private modalService: NgbModal,
              public tenderService: TenderService,
              public pricingService: PricingService,
              public api: ApiService) { }

  ngOnInit() {
    this.serviceTypeUnitTypes$.subscribe({
      next: () => {
        // Map all TenderService options according to TenderModalities
        this.loadTenderServices();
      }
    });
  }

  ngOnDestroy(): void {
    this.tenderService.TenderServiceUpdateList = [];
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadTenderServices() {
    this.loading = true;

    this.api.get('TenderService/List/' + this.tenderService.TenderView.Id)
      .pipe(takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.tenderService.TenderView.TenderServices = Object.assign([], data);
          this.mapTenderServices();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  mapTenderServices() {
    const stuts = this.pricingService.ServiceUnitTypes$.getValue();
    // Loop through all service types
    this.pricingService.ServiceTypes$.getValue().forEach((serviceType) => {
      // Get default ServiceTypeUnitType
      const defaultSTUT = stuts.find((x) => x.ServiceTypeId === serviceType.Id);
      // Loop through all TenderModalities
      this.tenderService.TenderView.TenderModalities.forEach((tenderModality) => {
        // Check if the combination TenderService already exists for this tender
        const exists = this.tenderService.TenderView.TenderServices.find((x) =>
          x.TenderModalityId === tenderModality.Id &&
          x.ServiceTypeId === serviceType.Id
        );
        // If exists, apply some fields
        if (exists) {
          if (this.tenderService.GetInputType(exists) === 2 && exists.ServiceTypeUnitTypeId === null) {
            exists.ServiceTypeUnitTypeId = defaultSTUT != null ? +defaultSTUT.Id : null;
            exists.ServiceTypeId = +serviceType.Id;
            exists.ServiceType = serviceType;
            this.tenderService.MarkTenderServiceForUpdate(exists);
          }

          if (this.tenderService.GetInputType(exists) === 1 && exists.Quantity === null) {
            exists.Quantity = this.checkIfDefault(serviceType);
            this.tenderService.MarkTenderServiceForUpdate(exists);
          }
        } else {
          // If it does not exist, add it to the options
          const tenderService = {
            Id: null,
            DateCreated: new Date(),
            DateModified: null,
            Active: true,
            TenderId: this.tenderService.TenderView.Id,
            ServiceTypeId: +serviceType.Id,
            TenderModalityId: tenderModality.Id,
            ServiceTypeUnitTypeId: defaultSTUT != null ? +defaultSTUT.Id : null,
            Quantity: this.checkIfDefault(serviceType),
            ApplyToOrigin: serviceType.IsOriginDefault,
            ApplyToOriginRate: serviceType.IsOriginDefault,
            ApplyToFreight: serviceType.IsFreightDefault,
            ApplyToFreightRate: serviceType.IsFreightDefault,
            ApplyToDestination: serviceType.IsDestinationDefault,
            ApplyToDestinationRate: serviceType.IsDestinationDefault,
            ServiceType: serviceType
          } as ITenderService;

          this.tenderService.TenderView.TenderServices.push(tenderService);
          this.tenderService.MarkTenderServiceForUpdate(tenderService);
        }
      });
    });
  }

  getServiceTypeUnitTypes(serviceTypeID: number): IServiceTypeUnitType[] {
    // Get applicable serviceTypeUnitTypes
    const serviceTypeUnitTypes = this.pricingService.GetServiceUnitTypes(serviceTypeID);

    if (serviceTypeUnitTypes && serviceTypeUnitTypes.length > 0) {
      return serviceTypeUnitTypes;
    } else {
      return [];
    }
  }

  checkIfDefault(serviceType: IServiceType) {
    if (serviceType.IsOriginDefault || serviceType.IsFreightDefault || serviceType.IsDestinationDefault) {
      return 1;
    } else {
      return 0;
    }
  }

  toggleOriginRate(serviceTypeID: number) {
    this.tenderService.TenderView.TenderServices.forEach((x) => {
      if (x.ServiceTypeId === serviceTypeID) {
        x.ApplyToOriginRate = !x.ApplyToOriginRate;
        this.tenderService.MarkTenderServiceForUpdate(x);
      }
    });
  }

  toggleFreightRate(serviceTypeID: number) {
    this.tenderService.TenderView.TenderServices.forEach((x) => {
      if (x.ServiceTypeId === serviceTypeID) {
        x.ApplyToFreightRate = !x.ApplyToFreightRate;
        this.tenderService.MarkTenderServiceForUpdate(x);
      }
    });
  }

  toggleDestinationRate(serviceTypeID: number) {
    this.tenderService.TenderView.TenderServices.forEach((x) => {
      if (x.ServiceTypeId === serviceTypeID) {
        x.ApplyToDestinationRate = !x.ApplyToDestinationRate;
        this.tenderService.MarkTenderServiceForUpdate(x);
      }
    });
  }

  getTenderService(serviceTypeID: number, tenderModalityID: number) {
    if (this.tenderService.TenderView && this.tenderService.TenderView.TenderServices) {
      return this.tenderService.TenderView.TenderServices.find((x) =>
        x.ServiceTypeId === serviceTypeID && x.TenderModalityId === tenderModalityID);
    } else {
      return {};
    }
  }

  getSortedTenderModalities() {
    return this.tenderService.TenderView.TenderModalities.sort((a, b) => a.Id - b.Id);
  }

  toggleServiceQuantity(tenderService: ITenderService) {
    if (tenderService) {
      if (tenderService.Quantity) {
        tenderService.Quantity = tenderService.Quantity === 0 ? 1 : 0;
      } else {
        tenderService.Quantity = 1;
      }
      this.tenderService.MarkTenderServiceForUpdate(tenderService);
    }
  }

  canShowServices() {
    const serviceUnitTypes = this.pricingService.ServiceUnitTypes$.getValue();
    const serviceTypes = this.pricingService.ServiceTypes$.getValue();

    return (
      serviceTypes?.length > 0 &&
      serviceUnitTypes?.length > 0 &&
      this.tenderService.TenderView.TenderServices &&
      this.tenderService.TenderView.TenderServices.length > 0
    );
  }

  openEditServicesModal() {
    // Open modal to add locations
    const modalRef = this.modalService.open(AddServiceModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.TenderServices = this.tenderService.TenderView.TenderServices;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: any) => {
      this.tenderService.TenderView.TenderServices = result.TenderServices;
      const tsToUpdate = result.TenderServicesToUpdate;
      tsToUpdate.forEach((ts: ITenderService) => {
        this.tenderService.MarkTenderServiceForUpdate(ts);
      });
      this.mapTenderServices();
    }, () => { });
  }
}
