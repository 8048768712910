<div class="row mt-4">
  <div class="col-9 text-start harmony-grey-text">
    <p class="mb-0">
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> Please select all services that need to be included in your rates.
      </small>
    </p>
    <p class="mb-0">
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> Some services are included by default, they are already listed below. Any other services can be added manually by clicking on "Add/Remove Services".
      </small>
    </p>
  </div>
  <div class="col-3 text-end">
    <button
      class="btn btn-sm btn-primary harmony-purple-outline-button mt-2"
      [disabled]="tenderService.IsQuoteAccepted()"
      (click)="openEditServicesModal()">
      Add/Remove Services
    </button>
  </div>
</div>
<ng-container *ngIf="canShowServices()">
  <div class="row mb-5 pb-5">
    <div class="col-12">
      <div class="table-wrapper">
        <table class="table table-bordered mt-2">
          <thead>
          <tr>
            <th scope="col"
                style="width: 300px; max-width: 300px; min-width: 300px;"
                class="fw-bold text-start harmony-teal-text">
              SERVICE
            </th>
            <th scope="col"
                style="width: 125px; max-width: 125px; min-width: 125px;"
                class="fw-bold text-center include-rate-column pointer harmony-teal-text">
              <span [title]="'Will be included as part of the final Origin rate.'">
                Origin
              </span>
            </th>
            <th scope="col"
                style="width: 125px; max-width: 125px; min-width: 125px;"
                class="fw-bold text-center include-rate-column pointer harmony-teal-text">
              <span [title]="'Will be included as part of the final Freight rate.'">
                Freight
              </span>
            </th>
            <th scope="col"
                style="width: 125px; max-width: 125px; min-width: 125px;"
                class="fw-bold text-center include-rate-column pointer harmony-teal-text">
              <span [title]="'Will be included as part of the final Destination rate.'">
                Destination
              </span>
            </th>
            <th
              scope="col"
              class="text-center"
              *ngFor="let modality of getSortedTenderModalities()">
              {{ modality.Quantity }} {{ modality.UnitType?.Abbreviation }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let distinctTS of tenderService.GetDistinctInclusions()">
            <th scope="row"
                class="pointer pb-0 text-nowrap service-row-heading">
              <span [title]="distinctTS.ServiceType?.Name">
                {{ distinctTS.ServiceType?.Name }}
              </span>
            </th>
            <!-- Origin Rate -->
            <td class="pointer text-center pb-0">
              <ng-container *ngIf="distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault || !distinctTS.ServiceType.IsOrigin">
                <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToOrigin"></fa-icon>
                <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToOrigin"></fa-icon>
              </ng-container>
              <ng-container *ngIf="!(distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault) && distinctTS.ServiceType.IsOrigin">
                <div class="form-check form-switch center-switch mb-0">
                    <input class="form-check-input d-inline mb-0"
                           type="checkbox"
                           role="switch"
                           [checked]="distinctTS.ApplyToOriginRate"
                           [disabled]="tenderService.IsQuoteAccepted()"
                           (change)="toggleOriginRate(distinctTS.ServiceTypeId)">
                </div>
              </ng-container>
            </td>
            <!-- Freight Rate -->
            <td class="pointer text-center pb-0">
              <ng-container *ngIf="distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault || !distinctTS.ServiceType.IsFreight">
                <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToFreight"></fa-icon>
                <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToFreight"></fa-icon>
              </ng-container>
              <ng-container *ngIf="!(distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault) && distinctTS.ServiceType.IsFreight">
                <div class="form-check form-switch center-switch mb-0">
                  <input class="form-check-input d-inline mb-0"
                         type="checkbox"
                         role="switch"
                         [checked]="distinctTS.ApplyToFreightRate"
                         [disabled]="tenderService.IsQuoteAccepted()"
                         (change)="toggleFreightRate(distinctTS.ServiceTypeId)">
                </div>
              </ng-container>
            </td>
            <!-- Destination Rate -->
            <td class="pointer text-center pb-0">
              <ng-container *ngIf="distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault || !distinctTS.ServiceType.IsDestination">
                <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToDestination"></fa-icon>
                <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToDestination"></fa-icon>
              </ng-container>
              <ng-container *ngIf="!(distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault) && distinctTS.ServiceType.IsDestination">
                <div class="form-check form-switch center-switch mb-0">
                  <input class="form-check-input d-inline mb-0"
                         type="checkbox"
                         role="switch"
                         [checked]="distinctTS.ApplyToDestinationRate"
                         [disabled]="tenderService.IsQuoteAccepted()"
                         (change)="toggleDestinationRate(distinctTS.ServiceTypeId)">
                </div>
              </ng-container>
            </td>
            <td class="text-center pointer pb-0"
                *ngFor="let tenderModality of tenderService.TenderView.TenderModalities">
              <div *ngVar="getTenderService(distinctTS.ServiceTypeId, tenderModality.Id) as ts">
                <div *ngIf="ts">
                  <ng-container  *ngIf="distinctTS.ServiceType?.IsOriginDefault">
                    <div class="row">
                      <div class="col-12">
                        <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToOrigin"></fa-icon>
                        <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToOrigin"></fa-icon>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container  *ngIf="distinctTS.ServiceType?.IsFreightDefault">
                    <div class="row">
                      <div class="col-12">
                        <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToFreight"></fa-icon>
                        <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToFreight"></fa-icon>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container  *ngIf="distinctTS.ServiceType?.IsDestinationDefault">
                    <div class="row">
                      <div class="col-12">
                        <fa-icon [icon]="faIncluded" class="harmony-green-text" *ngIf="distinctTS.ApplyToDestination"></fa-icon>
                        <fa-icon [icon]="faExcluded" class="harmony-grey-text" *ngIf="!distinctTS.ApplyToDestination"></fa-icon>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container  *ngIf="!(distinctTS.ServiceType?.IsOriginDefault || distinctTS.ServiceType?.IsFreightDefault || distinctTS.ServiceType?.IsDestinationDefault)">
                    <!-- Show the checkbox option if the InputType for the ServiceType is "Checkbox yes/no" -->
                    <div class="row" *ngIf="tenderService.GetInputType(distinctTS) === 1">
                      <div class="col-12 text-center">
                        <div class="form-check form-switch center-switch mb-0">
                          <input class="form-check-input d-inline mb-0"
                                 type="checkbox"
                                 role="switch"
                                 [disabled]="checkIfDefault(distinctTS.ServiceType) === 1 || tenderService.IsQuoteAccepted()"
                                 [checked]="ts.Quantity === 1"
                                 (change)="toggleServiceQuantity(ts)">
                        </div>
                      </div>
                    </div>
                    <!-- Show the checkbox option if the InputType for the ServiceType is "Numeric field" -->
                    <div class="row" *ngIf="tenderService.GetInputType(distinctTS) === 2">
                      <div class="col-12">
                        <label class="mb-0">
                          <input
                            type="text"
                            numbersOnly="4.2"
                            class="unit-quantity mb-0"
                            placeholder="e.g. 12"
                            [disabled]="checkIfDefault(distinctTS.ServiceType) === 1 || tenderService.IsQuoteAccepted()"
                            (change)="tenderService.MarkTenderServiceForUpdate(ts)"
                            [(ngModel)]="ts.Quantity">
                        </label>
                        <!-- Numeric field requires a unit type select list -->
                        <label class="mb-1">
                          <select class="unit-select mb-0"
                                  *ngIf="ts.ServiceTypeUnitTypeId"
                                  (change)="tenderService.MarkTenderServiceForUpdate(ts)"
                                  [disabled]="checkIfDefault(distinctTS.ServiceType) === 1 || tenderService.IsQuoteAccepted()"
                                  [(ngModel)]="ts.ServiceTypeUnitTypeId">
                            <option [ngValue]="null" [disabled]="true">Select...</option>
                            <option *ngFor="let serviceUnitType of getServiceTypeUnitTypes(ts.ServiceTypeId)" [ngValue]="serviceUnitType.Id">
                              {{ serviceUnitType.UnitTypeId1Navigation ? serviceUnitType.UnitTypeId1Navigation?.Name : serviceUnitType.UnitTypeId2Navigation?.Name }}
                            </option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!canShowServices() || loading">
  <app-loader [Width]="50"></app-loader>
</ng-container>
