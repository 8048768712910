import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from './services/storage.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AdAuthService } from './core/ad-auth-service/ad-auth.service';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { AlertService } from './services/alert.service';
import { IAuthUser } from './services/models/auth.model';
import { SessionStorageVariables } from './core/constants/session-storage-variables';
import { LogService } from './services/log.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { faBars, faCube, faElevator, faPowerOff, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { IVerifyValidityResponse } from './services/models/verify/verify.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyService } from './services/verify.service';
import { FileService } from './services/file.service';
import { OpenConfirmationModal } from './shared/components/confirmation-modal/confirmation-modal-functions';
import { PermissionCodes } from './core/constants/permission-codes';
import {
  VerifyCompanyUsersModalComponent
} from './modules/verification/verify-company-users-modal/verify-company-users-modal.component';
import {
  VerifyCompanyDetailsModalComponent
} from './modules/verification/verify-company-details-modal/verify-company-details-modal.component';
import { IAuthUserCompany } from './services/models/member.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'sidebarAnimation', [
        transition(':enter', [
          style
          ({transform: 'translateX(-100%)'}),
          animate('300ms', style({transform: 'translateX(0)'}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)'}),
          animate('300ms', style({transform: 'translateX(-100%)'}))
        ])
      ]),
    trigger('contentAnimation', [
      state('open', style({
        width: '83.33333333%'
      })),
      state('closed', style({
        width: '100%'
      })),
      transition('open => closed', [
        animate('300ms')
      ]),
      transition('closed => open', [
        animate('300ms')
      ]),
    ]),
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  // Icons
  faDashboard = faCube;
  faBars = faBars;
  faProfile = faUserGear;
  faSignOut = faPowerOff;
  faCompany = faElevator;

  // Component variables
  currentUser: IAuthUser;
  env = environment;
  validity: IVerifyValidityResponse;

  // Menu security
  dashboard: boolean = false;
  pricingMenu: boolean = false;
  networkMenu: boolean = false;
  newsMenu: boolean = false;
  annualReviewMenu: boolean = false;
  hubMenu: boolean = false;
  hseMenu: boolean = false;
  adminMenu: boolean = false;

  // Component variables
  picture: any = null;
  backgroundUrl: any = null;
  hasPicture: boolean = false;
  defaultImage = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.UserImagePlaceholderUrl;
  public logger = (this as any).constructor.name as string;

  private unsubscribe: Subject<any> = new Subject<any>();
  private readonly _destroying$ = new Subject<void>();

  constructor(public router: Router,
              public authService: AdAuthService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private storage: StorageService,
              private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private alertService: AlertService,
              private titleService: Title,
              private log: LogService,
              private modalService: NgbModal,
              private verifyService: VerifyService,
              private fileService: FileService,) {
  }

  ngOnInit() {
    this.titleService.setTitle('Harmony PORTAL');
    // This subscription gets triggered on each reload and loads the user permissions if the user is logged in.
    this.msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.authService.SetLoggedInStatus();
      });

    // Subscribe to successful login (Only triggers with manual sign-in)
    this.msalBroadcastService.msalSubject$.pipe(filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS
      ),
      takeUntil(this.unsubscribe)
    ).subscribe((success: any) => {
      this.msalService.instance.setActiveAccount(success.payload?.account);
      // Updated user logged in status and record successful login
      this.authService.SetLoggedInStatus();
    });

    // Subscribe to failed login
    this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_FAILURE),
      takeUntil(this.unsubscribe)
    ).subscribe((error: EventMessage) => {
      if (error.error.message.indexOf('AADB2C90118') > -1) { // Password reset request code
        this.resetPassword();
      } else if (error.error.message.indexOf('AADB2C90091') > -1) { // Password reset cancelled code
        this.alertService.info('Password reset cancelled');
      } else {
        // Updated user logged in status
        this.authService.Logout();
      }
    });

    // Subscribe to failed token
    this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ),
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.msalService.acquireTokenSilent({
        scopes: [`${environment.b2cPolicies.authorities.instanceUrl}/api/read_api`]
      }).subscribe({
        error: (error) => {
          this.log.error('Acquire B2C Token Failure', this.logger, 'acquireTokenSilent', error);
          this.authService.Logout();
        }});
    });

    // Subscribe to User Log Out Success
    this.msalBroadcastService.msalSubject$.pipe(filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGOUT_SUCCESS
      ),
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.authService.IsLoggedIn = false;
      this.authService.CurrentUser = null;
      this.storage.removeSessionItem(SessionStorageVariables.UserToken);
    });

    this.authService.CurrentUser$.subscribe({
      next: (user: IAuthUser) => {
        this.currentUser = user;
        this.loadMenu();
        this.getProfilePicture();
        this.triggerVerificationPopUps();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  /////////////////////////
  /// Start Up Functions //
  /////////////////////////
  getProfilePicture() {
    if (this.currentUser.User.ProfilePicture && !this.picture) {
      this.fileService.GetFileBlobUrl(this.env.ContainerNames.UserProfilePictures, this.getFullPictureName())
        .subscribe({
          next: (data: any) => {
            if (data) {
              this.picture = data.changingThisBreaksApplicationSecurity;
              this.backgroundUrl = `url("${data.changingThisBreaksApplicationSecurity}")`;
              this.hasPicture = true;
            }
          }, error: (err) => {
            this.log.error('Failed to get profile picture ', this.logger, 'getProfilePicture', err);
            this.picture = null;
            this.hasPicture = false;
          }
        });
    }
  }

  getFullPictureName() {
    const pictureName = this.currentUser.User.ProfilePicture.substring(
      this.currentUser.User.ProfilePicture.lastIndexOf('/') + 1);
    // Includes sub-folder of the userId
    return `${this.currentUser.User.Id}/${pictureName}`;
  }

  triggerVerificationPopUps() {
    const hasVerifyPermission = this.authService.CheckPermissionByCode(PermissionCodes.MemberManagement_VerifyPops);

    if (hasVerifyPermission) {
      this.verifyService.getValidity(this.currentUser.User.CompanyId)
        .subscribe(data => {
          if (data) {
            this.validity = Object.assign(data, {});
            if (!this.validity.IsUsersVerified) {
              const modalRef = this.modalService.open(VerifyCompanyUsersModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
              modalRef.componentInstance.currentUser = this.currentUser;
            }

            if (!this.validity.IsCompanyDetailsVerified) {
              const modalRef = this.modalService.open(VerifyCompanyDetailsModalComponent, {size: 'lg', windowClass: 'modal-2xl', backdrop: 'static', keyboard: false});
              modalRef.componentInstance.userId = this.currentUser.User.Id;
              modalRef.componentInstance.company = this.currentUser.User.Company;
            }
          }
        });
    }
  }

  loadMenu() {
    this.dashboard = this.authService.CheckPermissionByCode(PermissionCodes.Menu_Dash);
    this.pricingMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_Pricing);
    this.networkMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_Network);
    this.newsMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_News);
    this.annualReviewMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_AnnualReview);
    this.hubMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_Hub);
    this.hseMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_HSE);
    this.adminMenu = this.authService.CheckPermissionByCode(PermissionCodes.Menu_Admin);
  }

  changeCompany(userCompany: IAuthUserCompany) {
    if (userCompany && userCompany.CompanyId) {
      const message = `You are about to set ${userCompany.CompanyName} as your active member. Do you want to continue?`;
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer) {
            this.authService.SetActiveCompany(userCompany.CompanyId, userCompany.CompanyName);
          } else {
            this.alertService.info('Member change cancelled.');
          }
        });
    }
  }

  resetPassword() {
    this.msalService.loginPopup({
      ...this.msalGuardConfig.authRequest,
      authority: environment.b2cPolicies.authorities.forgotPassword.authority,
      redirectUri: environment.b2cPolicies.redirectUrl
    } as PopupRequest).subscribe((response: AuthenticationResult) => {
      this.msalService.instance.setActiveAccount(response.account);
      this.authService.SetLoggedInStatus();
      this.alertService.success('Password has been reset');
    });
  }
}
