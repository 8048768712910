import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TenderService } from '../../../../services/tender.service';
import { takeUntil } from 'rxjs/operators';
import {
  Entry,
  ITenderDto,
  ITenderProcessResult,
  ITenderQuote
} from '../../../../services/models/pricing/tender.model';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import { QuoteAcceptComponent } from './quote-accept/quote-accept.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../services/api.service';
import { StorageService } from '../../../../services/storage.service';
import { PricingService } from '../../../../services/pricing.service';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';

@Component({
  selector: 'app-rate-quote',
  templateUrl: './rate-quote.component.html',
  styleUrls: ['./rate-quote.component.scss']
})
export class RateQuoteComponent implements OnInit, OnDestroy {
  // Inputs and Output
  @Output() OnAccept: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnProcessed: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Component variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public tenderService: TenderService,
              public pricingService: PricingService,
              public api: ApiService,
              private modalService: NgbModal,
              private storage: StorageService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    if (this.tenderService.TenderView && !this.tenderService.MultiLaneQuote) {
      this.loadQuote();
    } else {
      const tender = this.tenderService.GetTenderFromStorage();
      if (tender && tender.Id) {
        this.tenderService.TenderView = Object.assign({}, tender);
        this.loadQuote();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadQuote() {
    this.loading = true;

    // Get Quote data
    const param = {
      TenderID: this.tenderService.TenderView.Id,
      CurrencyID: this.tenderService.TenderView.DefaultCurrencyId
    };

    this.tenderService.GetQuote(param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (quoteData) => {
        if (quoteData) {
          this.tenderService.MultiLaneQuote = Object.assign([] as ITenderQuote[], quoteData);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: GetQuote (TenderQuote)');
        this.loading = false;
      }
    });
  }

  getQuoteItems() {
    return this.tenderService.MultiLaneQuote.filter((x) => x.Quantity > 0);
  }

  acceptQuote() {
    // Open modal to accept quote
    const modalRef = this.modalService.open(QuoteAcceptComponent, {size: 'xl', backdrop: 'static'});
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result === true) {
        // Set process start time
        const entry = {id: 'TenderProcess', created: new Date()} as Entry;
        this.storage.storeSessionItem('tender-process-start', entry);
        // Update Tender status and step
        this.tenderService.TenderView.QuoteAcceptedDate = new Date();
        this.tenderService.TenderView.TenderStatusId = 2; // Quote accepted status
        this.tenderService.TenderView.TenderStepId = 7; // Export step. Final step.
        this.OnAccept.emit(true);
        this.tenderService.SaveTender().subscribe((data: ITenderDto) => {
          if (data) {
            this.tenderService.LoadingTenderResults = true;
            // Process the Tender
            this.tenderService.ProcessTender(this.tenderService.TenderView.Id, true).subscribe({
              next: (processResult: ITenderProcessResult) => {
                if (processResult && processResult.IsSuccess) {
                  this.OnProcessed.emit(true);
                } else {
                  this.alertService.error(processResult.Message);
                  this.tenderService.LoadingTenderResults = false;
                  this.storage.removeSessionItem('tender-process-start');
                }
              },
              error: () => {
                this.tenderService.LoadingTenderResults = false;
              }
            });
          } else {
            this.alertService.error('An error occurred while trying to update the quote acceptance date.');
          }
        });
      } else {

        const message = 'Declining will set the status of this tender to Declined, and it will no longer appear under your Active rate tenders.' +
          '\nHowever, you can still open it at any time and accept the quote and continue as normal. ' +
          '\nDo confirm that you would like to decline this quote?';
        OpenConfirmationModal(this.modalService, message)
          .pipe(takeUntil(this.unsubscribe)
          ).subscribe(answer => {
          if (answer) {
            this.tenderService.TenderView.TenderStatusId = 4; // Quote Declined
            this.tenderService.SaveTender().subscribe((data: ITenderDto) => {
              if (data) {
                this.alertService.info('Tender has been declined. Rates cannot be provided if the quote has not been accepted.');
              }
            });
          }
        });
      }
    }, () => {
      this.alertService.warn('Rates cannot be provided if the quote have not been accepted.');
    });
  }

  getTotal() {
    let result = 0;

    if (this.tenderService.MultiLaneQuote) {
      this.tenderService.MultiLaneQuote.forEach((q) => {
        result += q.Amount;
      });

      this.tenderService.TenderView.QuoteAmount = result;
    }

    return result;
  }
}
