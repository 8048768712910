import {AfterViewChecked, ChangeDetectorRef, Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {IHRPhrase} from '../../../../../services/models/hse/hse.model';
import { AdAuthService } from "../../../../../core/ad-auth-service/ad-auth.service";
import { SharedModule } from "../../../../../shared/shared.module";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-hr-phrase-admin-modal',
  templateUrl: './hr-phrase-admin-modal.component.html',
  standalone: true,
  imports: [SharedModule, NgClass],
  styleUrls: ['./hr-phrase-admin-modal.component.scss']
})
export class HrPhraseAdminModalComponent implements AfterViewChecked{

  // Component Variables
  HRPhrase: IHRPhrase;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  savePhrase(){

    if(this.isValid()) {

      this.hseService.upsertHRPhrase(this.HRPhrase)
        .subscribe({
          next: (data) => {
            this.alertService.info('HR Phrase: ' + this.HRPhrase.Description + ' successfully saved.');
            this.HRPhrase = Object.assign({}, data);
            this.close();
          },
          error: () => {
            this.alertService.error('Failed to update HR Phrase.');
          }
        });

    } else {
      this.alertService.error('Please fill all required fields outlined red.');
    }

  }

  isValid(){
    return this.HRPhrase.LanguageCode != null && this.HRPhrase?.Description?.length > 0;
  }

  close() {
    this.activeModal.close();
  }

}
