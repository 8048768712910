<!---------------------->
<!------- Header ------->
<!---------------------->
<!--<script src="../environmental-assessment/environmental-assessments-overview.component.ts"></script>-->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    {{ selectedCompany?.CompanyName }} | Environmental Impact Data
  </h1>
  <div class="justify-content-end align-items-center">
    <div class="form-check form-switch form-switch-sm" style="display: inline-block; position: relative; top: 0.6em; right: 0.4em;">
      <input class="form-check-input pointer float-end"
             type="checkbox"
             role="switch"
             [title]="'Watermark'"
             [id]="'impact-data-watermark-switch'"
             [checked]="showWatermark"
             (change)="showWatermark = !showWatermark">
    </div>
    <button (click)="openInfoModal()"
            class="btn btn-sm btn-primary harmony-teal-outline-button me-1">
      Information
    </button>
    <button (click)="createEntry()" *ngIf="canAdd"
            class="btn btn-sm btn-primary harmony-purple-outline-button">
      New Entry
    </button>
  </div>
</div>
<!----------------------->
<!------- Filters ------->
<!----------------------->
<div *ngIf="canFilterCompany" class="row me-1">
  <div class="col-1 align-self-center">
    <b>Company</b>
  </div>
  <div class="col-3">
    <select (ngModelChange)="selectCompany($event)"
            [(ngModel)]="selectedCompanyId" [disabled]="loading || !(companies$ | async)"
            class="form-select form-select-sm pointer">
      <option [ngValue]="null" selected>Select a company...</option>
      <option *ngFor="let company of (companies$ | async)"
              [ngValue]="company.CompanyId">{{ company.CompanyName }}
      </option>
    </select>
  </div>
  <div class="col-1 offset-7 col-compress align-self-center">

  </div>
</div>
<hr *ngIf="canFilterCompany" class="my-2">
<!----------------------->
<!------- Results ------->
<!----------------------->
<app-loader *ngIf="loading"></app-loader>
<app-no-result [showNoResults]="noResult"></app-no-result>
<app-watermark *ngIf="companyImages && showWatermark" [image]="companyImages.Logo"></app-watermark>
<div *ngIf="!noResult" class="accordion" id="accordion-impact-data">
  <!-- Company Basics -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-company-basics'">
            <div class="row pointer" (click)="companyBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #companyBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-company-basics'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-company-basics'">
                    Company Basics
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-company-basics'"
               class="collapse"
               [attr.aria-labelledby]="'heading-company-basics'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 align-self-center text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Company Average FTE</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Average number of FTE (full time equivalent) at the company over the year'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Office Average FTE</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Average number of FTE (full time equivalent) working at the office over the year'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Warehouse Floor Surface</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'The total surface area of your warehouse in square meters'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Office Floor Surface</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'The total surface area of your office in square meters'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Small Removal Trucks</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Total trucks owned smaller than 3.5 ton'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Large Removal Trucks</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Total trucks owned larger than 3.5 ton'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Cars</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Total cars owned'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Degree Days</label>
                      <span>
                <fa-icon [icon]="faInfo" [title]="'Unit used to determine heating requirements of buildings, representing a fall of one degree below a specified outdoor average per one day'"
                         class="harmony-grey-text detail-info">
                </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.Year" [ngClass]="{'is-invalid': (!item.Year || item.Year < 2000) && invalid}"
                                 [numbersOnly]="'4.0'"
                                 class="form-control form-control-sm fw-bold" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CompanyAverageFte" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CompanyOfficeAverageFte" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.WarehouseFloorSurfaceArea" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.OfficeFloorSurfaceArea" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.SmallRemovalTrucks" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.LargeRemovalTrucks" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.Cars" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.DegreeDays" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.CompanyBasicsComment" [placeholder]="'Add a comment'"
                            class="form-control form-control-sm"
                            id="company-basic-comment" name="company-basic-comment"
                            rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Offices and Warehouses -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-offices'">
            <div class="row pointer" (click)="officesBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #officesBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-offices'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-offices'">
                    Offices and Warehouses
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-offices'"
               class="collapse"
               [attr.aria-labelledby]="'heading-offices'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">
                        Year
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Electricity Use (Grey)
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour of electricity used provided by a non-renewable energy source, per year in the office and warehouse'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Electricity Use (Green)
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour of electricity used provided by a renewable energy source, per year in the office and warehouse'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Electricity per FTE Office
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Statistic of electricity used at the office per 1 full time equivalent employee'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Natural Gas Use
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total cubic meter gas used per year in the office or warehouse'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Natural Gas Use per Degree Days
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Statistic of gas used per day, compensated for temperature variations'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Natural Gas Use per m²
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total cubic meter gas used per square meter per year'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Fuel Oil Use
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total liters of oil used per year'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Fuel Oil Use per Degree Days
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="''"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Fuel Oil Use per m²
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total liters of oil used per square meter in a year'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Water Use
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Total cubic meter water used per year'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Coolant Replacement
                      </label>
                      <span>
                        <fa-icon [icon]="faInfo" [title]="'Number of kilograms of coolant used to fill up the airconditioning unit(CFK, HFK)'"
                                 class="harmony-grey-text detail-info">
                        </fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Comment
                      </label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span class="form-control-sm fw-bold">
                            CO²
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.GreyElectricUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.GreyElectricUse, data[i +1]?.GreyElectricUse)" class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.GreyElectricUse, i, 0.7) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.GreenElectricUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.GreenElectricUse, data[i +1]?.GreenElectricUse)" class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.GreenElectricUse, i, 0.015) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.OfficeElectricUsePerFte" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.NaturalGasUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.NaturalGasUse, data[i +1]?.NaturalGasUse)" class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.NaturalGasUse, i, 2.2) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.NaturalGasUsePerDegreeDay" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.NaturalGasUsePerDegreeDay, data[i +1]?.NaturalGasUsePerDegreeDay)"
                          class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.NaturalGasUsePerDegreeDay, i, 2.2) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.NaturalGasUsePerSquareMeter" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.NaturalGasUsePerSquareMeter, data[i +1]?.NaturalGasUsePerSquareMeter)"
                          class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.NaturalGasUsePerSquareMeter, i, 2.2) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.FuelOilUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUse, data[i +1]?.FuelOilUse)" class="col-6">
                        <span class="form-control-sm">
                          {{ calculateCO2(item.FuelOilUse, i, 3.185) }}
                        </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.FuelOilUsePerDegreeDay" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUsePerDegreeDay, data[i +1]?.FuelOilUsePerDegreeDay)"
                             class="col-6">
                        <span class="form-control-sm">
                          {{ calculateCO2(item.FuelOilUsePerDegreeDay, i, 3.185) }}
                        </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.FuelOilUsePerSquareMeter" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUsePerSquareMeter, data[i +1]?.FuelOilUsePerSquareMeter)"
                             class="col-6">
                          <span class="form-control-sm">
                            {{ calculateCO2(item.FuelOilUsePerSquareMeter, i, 3.185) }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.WaterUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CoolantReplacement" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.OfficeComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="office-comment" name="office-comment"
                              rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Vehicles and Travel -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-vehicles'">
            <div class="row pointer" (click)="vehiclesBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #vehiclesBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-vehicles'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-vehicles'">
                    Vehicles and Travel
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-vehicles'"
               class="collapse"
               [attr.aria-labelledby]="'heading-vehicles'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">
                        Year
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Large Diesel Consumption Removal Truck
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total liters of the diesel used for contracted removal trucks larger than 3.5 ton per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Small Diesel Consumption Removal Truck
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total liters of the diesel used for contracted removal trucks smaller than 3.5 ton per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Diesel Consumption Cars
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total liters of diesel used for contracted cars per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Petrol Consumption Cars
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total liters of the petrol used for contracted cars per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        LPG Consumption Cars
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="''"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Electricity Use Cars Grey
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour used for full electric contracted cars provided by a non-renewable energy source per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Electricity Use Cars Green
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour used for full electric contracted cars provided by a renewable energy source per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Diesel Consumption Subcontractors
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total liters of diesel used for subcontracted cars per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made of the above vehicle per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Average Euro Class
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The average of the euro categorisation of vehicles'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Average Emission Class
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The average emission class car outside of a euro class car'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Air Travel KM
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers traveled via air transport'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Air Travel CO²
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of carbon dioxide used via air travel (e.g. plane)'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Rail Travel
                      </label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilometers made in rail travel (e.g. train)'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">
                        Comment
                      </label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                        <div class="col-6">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    CO²
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.LargeDieselConsRemovalTrucks" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.LargeDieselConsRemovalTrucks, data[i +1]?.LargeDieselConsRemovalTrucks)"
                          class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.LargeDieselConsRemovalTrucks, i, 3.135) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.LargeDieselTruckTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div class="col-6">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.SmallDieselConsRemovalTrucks" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.SmallDieselConsRemovalTrucks, data[i +1]?.SmallDieselConsRemovalTrucks)"
                          class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.SmallDieselConsRemovalTrucks, i, 3.135) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.SmallDieselTrucksTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.DieselConsCars" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.DieselConsCars, data[i +1]?.DieselConsCars)" class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.DieselConsCars, i, 3.135) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.DieselCarsTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.PetrolConsCars" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.PetrolConsCars, data[i +1]?.PetrolConsCars)" class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.PetrolConsCars, i, 2.780) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.PetrolCarsTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.LpgConsCars" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.LpgConsCars, data[i +1]?.LpgConsCars)" class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.LpgConsCars, i, 1.860) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.LpgCarsTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.ElectricConsCarsGrey" class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.ElectricConsCarsGrey, data[i +1]?.ElectricConsCarsGrey)"
                             class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.ElectricConsCarsGrey, i, 0.7) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.ElectricCarsGreyTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.ElectricConsCarsGreen" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.ElectricConsCarsGreen, data[i +1]?.ElectricConsCarsGreen)"
                             class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.ElectricConsCarsGreen, i, 0.015) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.ElectricCarsGreenTotalKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.DieselConsCarsSubcontract" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.DieselConsCarsSubcontract, data[i +1]?.DieselConsCarsSubcontract)"
                          class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.DieselConsCarsSubcontract, i, 3.135) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.DieselCarsSubcontractTotalKm" [numbersOnly]="'10.'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.LargeAverageEuroClass" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.SmallAverageEmission" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.AirTravelKm" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.AirTravelKm, data[i +1]?.AirTravelKm)" class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.AirTravelKm, i, 0.2) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.AirTravelCarbonDioxide" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.RailTravel" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                     <textarea [(ngModel)]="item.TravelComment" [placeholder]="'Add a comment'"
                               class="form-control form-control-sm"
                               id="travel-comment" name="travel-comment"
                               rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Home/Work Travel -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-home'">
            <div class="row pointer" (click)="homeBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #homeBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-home'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-home'">
                    Home/Work Travel
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-home'"
               class="collapse"
               [attr.aria-labelledby]="'heading-home'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Total Employees Walk/Cycle</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via walking or cycling per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Total Employees Car or Scooter</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via car or scooter per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Total Employees Public Transport</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via public transport per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Total Flexible/Home Employees</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total employees that work from home partially or completely'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                  <span class="form-control-sm horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesWalkOrCycle" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesCarOrScooter" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesPublicTransport" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesFlexibleHomeWorker" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                    <textarea [(ngModel)]="item.EmployeeTravelComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="employee-travel-comment" name="employee-travel-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Waste -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-waste'">
            <div class="row pointer" (click)="wasteBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #wasteBtn
                          type="button"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-waste'"
                          [attr.aria-expanded]="false" [attr.aria-controls]="'collapse-waste'">
                    Waste
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-waste'"
               class="collapse"
               [attr.aria-labelledby]="'heading-waste'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Customer Waste Collected</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total waste collected from customers in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Customer Paper/Cardboard Waste</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total paper or cardboard waste collected from customers in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Paper/Cardboard Recycled</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total paper or cardboard waste that was recycled for customers in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Company Waste</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total waste collected from your company in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Company Paper/Cardboard Waste</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total paper or cardboard waste collected from your company in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Company Paper/Cardboard Recycled</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total paper or cardboard waste that was recycled for your company in kilograms per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                        <div class="col-6">
                  <span class="form-control-sm fw-bold">
                    CO²
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CustomerWasteCollected" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CustomerPaperWasteCollected" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CustomerPaperWasteRecycled" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CompanyWaste" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.CompanyWaste, data[i +1]?.CompanyWaste)" class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.CompanyWaste, i, 434) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CompanyPaperWaste" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                        <div [ngClass]="getColorIndicator(item?.CompanyPaperWaste, data[i +1]?.CompanyPaperWaste)"
                             class="col-6">
                  <span class="form-control-sm">
                    {{ calculateCO2(item.CompanyPaperWaste, i, 1000) }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-6">
                          <input [(ngModel)]="item.CompanyPaperWasteRecycled" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                    <textarea [(ngModel)]="item.WasteComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="waste-comment" name="waste-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Procurements -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-procurements'">
            <div class="row pointer" (click)="procurementsBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #procurementsBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-procurements'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-procurements'">
                    Procurements
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-procurements'"
               class="collapse"
               [attr.aria-labelledby]="'heading-procurements'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Single Packaging Cardboard A</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of single packaging made of cardboard type A. Type A is packing materials made of entirely recycled materials or for which a GMP certificate can be provided'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Single Packaging Cardboard B</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of single packaging made of cardboard type B. Type B is packing materials made out of some or non-recyclable materials'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Single Use Plastic</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of single use plastic used per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Office Paper Use</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper used within the office per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Print Matter</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper that has been printed per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.SingleUsePackagingCardboardA" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.SingleUsePackagingCardboardB" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.SingleUsePackagingPlastic" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.OfficePaperUse" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.PrintMatter" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                      <textarea [(ngModel)]="item.ProcurementsComment" [placeholder]="'Add a comment'"
                                class="form-control form-control-sm"
                                id="procurement-comment" name="procurement-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Planning and Control -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-planning'">
            <div class="row pointer" (click)="planningBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #planningBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-planning'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-planning'">
                    Planning and Control
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-planning'"
               class="collapse"
               [attr.aria-labelledby]="'heading-planning'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Environmental Audits Held</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Number of environment audits held'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Work Place Inspections Held</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Number of work place inspections held'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EnvironmentalAuditsHeld" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.WorkPlaceInspectionsHeld" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                    <textarea [(ngModel)]="item.PlanningComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="planning-comment" name="planning-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Compensation -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-compensation'">
            <div class="row pointer" (click)="compensationBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #compensationBtn
                          type="button"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-compensation'"
                          [attr.aria-expanded]="false" [attr.aria-controls]="'collapse-compensation'">
                    Compensation
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-compensation'"
               class="collapse"
               [attr.aria-labelledby]="'heading-compensation'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Compensated Removals</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Number of removals carried out for which CO² emissions were compensated (e.g. EcoLegit)'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Compensations</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'Number of CO² that was compensated'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Compensations Euro</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'CO² that was compensated for in euro currency'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">Comment</label>
                      <span class="align-missing-info"></span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                  <span class="form-control-sm fw-bold horizontal-header-sm">
                    {{ item.Year }}
                  </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CarbonDioxideRemovals" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CarbonDioxideCompensation" [numbersOnly]="'10.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CarbonDioxideCompensationEuro" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                    <textarea [(ngModel)]="item.CompensationComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="compensation-comment" name="compensation-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Balance -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-balance'">
            <div class="row pointer" (click)="balanceBtn.click()">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #balanceBtn
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-balance'"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'collapse-balance'">
                    Balance
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-balance'"
               class="collapse"
               [attr.aria-labelledby]="'heading-balance'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm fw-bold">Year</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Emission</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total carbon dioxide emission produced in ton per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Compensation</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The total carbon dioxide emission compensated for in ton per year'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 text-end">
                      <label class="form-control-sm">CO² Net Emission</label>
                      <span>
                  <fa-icon [icon]="faInfo" [title]="'The remaining carbon dioxide produced in ton per year. The total emission minus what was compensated for'"
                           class="harmony-grey-text detail-info">
                  </fa-icon>
              </span>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of data; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">{{ item.Year }}</span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.TotalCarbonDioxideEmission" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.TotalCarbonDioxideCompensation" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.NetCarbonDioxideEmission" [numbersOnly]="'10.2'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger float-end">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-purple-outline-button float-end me-1"
                                  type="button">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
