import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faCodePullRequest, faDownload,
  faEye, faPaperclip, faRefresh,
  faSave,
  faThumbsDown,
  faThumbsUp,
  faTrash, faUpload
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  IArticleAttachment,
  IArticleReview,
  IArticleReviewRequest,
  IArticleReviewResponse, IArticleStatusLog
} from '../../../../services/models/news/article.model';
import { AlertService } from "../../../../services/alert.service";
import { ArticleService } from "../../../../services/article.service";
import { Subject } from "rxjs";
import { editorConfig } from '../article-editor/editorConfig';
import { environment } from 'src/environments/environment';
import { formatDate, Location } from '@angular/common';
import { OpenConfirmationModal } from "../../../../shared/components/confirmation-modal/confirmation-modal-functions";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PermissionCodes } from "../../../../core/constants/permission-codes";
import { AdAuthService } from "../../../../core/ad-auth-service/ad-auth.service";
import { FileService } from '../../../../services/file.service';
import { IApiResponse } from '../../../../shared/models/api-reponse.model';
import { ArticlePreviewModalComponent } from './article-preview-modal/article-preview-modal.component';
import { NewsRoutes } from '../../../../core/constants/routes';
import {
  ArticleReviewCommentModalComponent
} from './article-review-comment-modal/article-review-comment-modal.component';
import { IAuthUser } from '../../../../services/models/auth.model';

@Component({
  selector: 'app-article-review',
  templateUrl: './article-review.component.html',
  styleUrls: ['./article-review.component.scss']
})
export class ArticleReviewComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faSave = faSave;
  protected readonly faThumbsUp = faThumbsUp;
  protected readonly faThumbsDown = faThumbsDown;
  protected readonly faCompare = faCodePullRequest;
  protected readonly faEye = faEye;
  protected readonly faDelete = faTrash;
  protected readonly faUpload = faUpload;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faDownload = faDownload;
  protected readonly faAttachment = faPaperclip;
  protected readonly faReset = faRefresh;

  // Route Parameter
  public articleId: number;

  // Component variables
  tinymce: any;
  articleReview: IArticleReviewResponse = null;
  attachments: IArticleAttachment[] = [];
  articleLogs: IArticleStatusLog[] = [];
  categories$ = this.articleService.Categories$.asObservable();
  disable: boolean = false;
  imageBaseUrl: string = '';

  // Permissions
  hasReviewPermissions: boolean = false;
  canEdit: boolean = false;

  // General variables
  protected readonly editorConfig = editorConfig;
  protected readonly env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(private route: ActivatedRoute,
              private auth: AdAuthService,
              private alertService: AlertService,
              private articleService: ArticleService,
              private fileService: FileService,
              private location: Location,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.imageBaseUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/`;

    if (this.auth.CurrentUser) {
      this.setPermissions();
    }

    this.auth.CurrentUser$
      .subscribe((user: IAuthUser) => {
        this.setPermissions();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.hasReviewPermissions = this.auth.CheckPermissionByCode(PermissionCodes.News_ApproveArticles);

    if (!this.hasReviewPermissions) {
      this.alertService.warn('You do not have the required permissions to review this article.');
      this.returnToOverview();
    } else {
      this.initArticleReview();
    }
  }

  initTinymce(event: any) {
    this.tinymce = event.editor;
  }

  back() {
    this.location.back();
  }

  getPlainTextFromEditor() {
    return this.tinymce.getContent({format: 'text'});
  }

  initArticleReview() {
    this.route.paramMap.subscribe(paramMap => {
      const articleId = Number(paramMap.get('articleId'));
      if (articleId && articleId > 0) {
        this.loading = true;
        this.articleId = articleId;
        this.getAttachments();
        this.articleService.getReview(articleId)
          .subscribe({
            next: (response: IArticleReviewResponse) => {
              if (response != null) {
                switch (response.Article?.Status) {
                  case 'Published':
                    this.alertService.warn('The article has already been published. No action required');
                    this.returnToOverview();
                    break;
                  case 'Draft':
                    this.alertService.warn('Article is still in drafts');
                    this.returnToOverview();
                    break;
                  case 'Awaiting Approval':
                    this.articleReview = Object.assign({}, response);
                    this.loadArticleStatusLogs();
                    break;
                  case 'Rejected':
                    this.alertService.warn('The article has been rejected and can no longer be actioned');
                    this.returnToOverview();
                    break;
                  case 'Change Requested':
                    this.alertService.warn('The requested changes are still awaiting review by the author');
                    this.returnToOverview();
                    break;
                }
              } else {
                this.alertService.warn('Only the original author can action their own articles');
                this.returnToOverview();
              }
            },
            error: () => {
              this.alertService.error('Failed to retrieve article review');
              this.loading = false;
            }
          });
      } else {
        this.returnToOverview();
      }
    })
  }

  returnToOverview() {
    this.loading = false;
    this.router.navigate([`${NewsRoutes.Article_Management}`])
      .then(() => {
      });
  }

  loadArticleStatusLogs() {
    this.articleService.getArticleStatusLogs(this.articleId)
      .subscribe({
        next: (data) => {
          this.articleLogs = Object.assign([], data);
          this.loading = false;
        }
      });
  }

  getAttachments() {
    this.articleService.getAttachments(this.articleId)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.attachments = [...data];
        }, error: (err: IApiResponse) => {
          this.alertService.error(err.Meta.Message);
          this.loading = false;
        }
      });
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  removeImage() {
    if (this.articleId && this.articleReview.Article.ImageReference) {
      const fullFileName = `${this.articleId}/${this.articleReview.Article.ImageReference}`;
      this.fileService.DeleteFile(environment.ContainerNames.ArticleImages, fullFileName)
        .pipe(
          switchMap(() => {
            this.articleReview.Article.ImageReference = null;
            return this.articleService.update(this.articleReview.Article);
          })
        ).subscribe({
          next: () => {
            this.loading = false;
            this.alertService.info('Image has been removed');
          },
          error: (err) => {
            this.loading = false;
            this.alertService.error(err.Meta.Message);
          }
        });
    }
  }

  setFeaturedStatus(event: any) {
    this.articleReview.Review.Featured = event.target.checked;
  }

  setWebsiteStatus(event: any) {
    this.articleReview.Review.Website = event.target.checked;
  }

  saveReview() {
    this.articleReview.Review.PlainContent = this.getPlainTextFromEditor();
    if (this.articleReview.Review && this.articleReview.Review.ReviewId > 0) {
      this.articleService.updateReview(this.articleReview.Review)
        .subscribe({
          next: (review: IArticleReview) => {
            this.articleReview.Review = Object.assign({}, review);
            this.alertService.success('Review has been updated.')
          }
        });
    } else {
      this.articleService.addReview(this.articleReview.Review)
        .subscribe({
          next: (review: IArticleReview) => {
            this.articleReview.Review = Object.assign({}, review);
            this.alertService.success('Review has been saved.')
          }
        });
    }
  }

  preview() {
    const a = this.articleReview.Article;
    const r = this.articleReview.Review;

    const modalRef = this.modalService.open(ArticlePreviewModalComponent, { size: 'xl', windowClass: 'modal-2xl', backdrop: 'static' });
    modalRef.componentInstance.ImageUrl = this.imageBaseUrl + a.ArticleId + '/'+ a.ImageReference;
    modalRef.componentInstance.Title = r.Title;
    modalRef.componentInstance.PublishDate = a.PublishDate;
    modalRef.componentInstance.Author = a.Author;
    modalRef.componentInstance.HtmlContent = r.HtmlContent;
    modalRef.componentInstance.Attachments = this.attachments;
  }

  requestChanges() {
    this.saveReview();
    // Open modal to create a new user
    const modalRef = this.modalService.open(ArticleReviewCommentModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.ArticleId = this.articleId;
    modalRef.componentInstance.Title = 'Send article changes to author';
    modalRef.componentInstance.Message = `The changes you have made will be sent to the author to accept or reject. <br><br> Please provide a comment to the author below to proceed:`;
    // On modal close, read the result and apply logic
    modalRef.result.then((comment: string) => {
      if (comment && comment.length > 0) {
        this.disable = true;
        this.articleService.requestChanges({ ArticleId: this.articleId, Comment: comment}).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
            next: (response: IArticleReviewResponse) => {
              this.alertService.success('Request for changes sent to the author successfully!');
              this.returnToOverview();
            },
            error: err => {
              this.alertService.error('Error: Request for re-approval failed');
            }
          });
        }
    });
  }

  resetArticle() {
    let message = `The review will be reset to the original article and all changes will be lost. Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.articleReview.Review.Title = this.articleReview.Article.Title;
          this.articleReview.Review.PlainContent = this.articleReview.Article.PlainContent;
          this.articleReview.Review.HtmlContent = this.articleReview.Article.HtmlContent;
          this.articleReview.Review.Categories = this.articleReview.Article.Categories;
          this.articleReview.Review.Featured = this.articleReview.Article.Featured;
          this.articleReview.Review.ExpireDate = this.articleReview.Article.ExpireDate;
          this.articleReview.Review.PublishDate = this.articleReview.Article.PublishDate;
          this.saveReview();
        }
      });
  }

  // Admin/Reviewer approval
  approveArticle() {
    const date = formatDate(this.articleReview.Article.PublishDate, environment.FormattingStandards.ShortDateFormat, 'en_US');
    let message = `By approving the article you agree that it follows the guidelines and standards of Harmony Relocation Network. \n\n Any review changes/updates will be discarded and the article will be published in it's original form. \n\n The article will be published and made available to the network on ${date}. \n \n Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(answer => {
          if (answer) {
            this.disable = true;
            const successMessage = `Article is approved. It will be live on ${date}.`;
            return this.articleService.approve(this.articleReview.Article.ArticleId)
              .pipe(
                tap(() => {
                  this.alertService.success(successMessage);
                  this.returnToOverview();
                }));
          }
        })
      ).subscribe();
  }

  rejectArticle() {
    // Open modal to create a new user
    const modalRef = this.modalService.open(ArticleReviewCommentModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.ArticleId = this.articleId;
    modalRef.componentInstance.Title = 'Reject article';
    modalRef.componentInstance.Message = `The article will be rejected. You will not be able to make further changes and it will not be published. <br><br> Please provide a comment to the author below to proceed:`;
    // On modal close, read the result and apply logic
    modalRef.result.then((comment: string) => {
      if (comment && comment.length > 0) {
        const successMessage = 'Article has been successfully rejected and the author has been notified.';
        const param = {
          ArticleId: this.articleReview.Article.ArticleId,
          Comment: comment
        } as IArticleReviewRequest;
        return this.articleService.reject(param)
          .pipe(
            tap(() => {
              this.alertService.success(successMessage);
              this.returnToOverview();
            }));
      }
    });
  }
}
