import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { environment } from '../../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { ICompany, ICompanyDetail } from '../../../services/models/member.model';
import { faEnvelope, faGlobe, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-company-info',
  templateUrl: './dashboard-company-info.component.html',
  styleUrls: ['./dashboard-company-info.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.three-by-two]': 'column == 3 && row == 2',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCompanyInfoComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  @Input()
  set Company(company: ICompany) {
    if (company && company.CompanyId > 0) {
      this.company = company;
      this.setCompanyInfo();
    }
  }

  // Icons
  protected readonly faPhoneIcon = faPhone;
  protected readonly faEmailIcon = faEnvelope;
  protected readonly faWebsiteIcon = faGlobe;
  protected readonly faLocation = faLocationDot;

  // Component variables
  company: ICompany = null;
  companyTelephone: ICompanyDetail;
  companyUrl: ICompanyDetail;
  companyEmail: ICompanyDetail;
  placeholderUrl: string;

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(public router: Router,
              public companyService: CompanyService,
              public authService: AdAuthService) { }

  ngOnInit() {
    this.placeholderUrl = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.ImagePlaceholderLargeUrl;
    if (this.company) {
      this.setCompanyInfo();
    }
  }

  setCompanyInfo() {
    const companyId = this.company.CompanyId;
    if (companyId && companyId > 0) {
      this.companyTelephone = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'MemberTelephone');
      this.companyUrl = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'Url');
      this.companyEmail = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'MemberEmail');
    }
  }

  getCompanyLogo() {
    if (this.company && this.company.CompanyLogo) {
      return this.company.CompanyLogo;
    } else {
      return this.placeholderUrl;
    }
  }

  openNewWindow(url: string) {
    if (url) {
      const hasHttps = url.includes('https') || url.includes('https');

      if (hasHttps) {
        window.open(url, '_blank');
      } else {
        window.open('https://' + url, '_blank');
      }
    }
  }
}
