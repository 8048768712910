import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAuthUser } from '../../../services/models/auth.model';
import { VerifyService } from '../../../services/verify.service';
import { IPage } from '../../../shared/models/pagination-data.model';
import { IUserVerification } from '../../../services/models/verify/verify.model';
import { LogService } from '../../../services/log.service';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-verify-company-users-modal',
  templateUrl: './verify-company-users-modal.component.html',
  styleUrls: ['./verify-company-users-modal.component.scss']
})
export class VerifyCompanyUsersModalComponent implements OnInit, OnDestroy {

  public currentUser: IAuthUser;
  inactiveUsers: number[] = [];
  activeUsers: number[] = [];

  // External Component variables
  loading: boolean = false;
  page = { batched: true } as IPage;
  users: IUserVerification[] = [];
  public logger = (this as any).constructor.name as string;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private verifyService: VerifyService,
              private log: LogService,
              private companyService: CompanyService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getUsers() {
    this.loading = true;
    this.companyService.getCompanyUsers(this.currentUser.User.CompanyId)
      .subscribe({
        next: (data: any) => {
          this.users = Object.assign([], data);
          this.checkLastLoggedInDateInactivity();
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.log.error(`Failed to load users for verification with CompanyId: ${this.currentUser.User.CompanyId}`,
            this.logger, 'getUsers', error);
        }
      });
  }

  checkLastLoggedInDateInactivity() {
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    this.users.forEach(x => {
      // make user inactive if they have not logged in for 6 months
      // but keep active if they have not logged in at all
      if (x.LastLoggedInDate != null && new Date(x.LastLoggedInDate) < sixMonthsAgo) {
        x.IsInactive = true;
      }
    });
  }

  setUser(user: IUserVerification, event: any) {
    user.IsInactive = !event.target.checked;
  }

  save() {
    const message = `You agree all information is true and accurate?\nTotal users to be inactivated: ${this.users.filter(x => x.IsInactive).length}`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          // reset for it to be calculated correct on each save
          this.inactiveUsers = [];
          this.activeUsers = []
          this.users.forEach(u => {
            if (u.IsInactive) {
              this.inactiveUsers.push(u.Id);
            } else {
              this.activeUsers.push(u.Id);
            }
          });
          this.loading = true;
          this.verifyService.users(this.currentUser.User.Id, this.currentUser.User.CompanyId, this.activeUsers, this.inactiveUsers)
            .subscribe({
              next: () => {
                this.loading = false;
                this.activeModal.close();
              },
              error: (error) => {
                this.loading = false;
                this.log.error(`Failed to save users verification with CompanyId: ${this.currentUser.User.CompanyId} UserId: ${this.currentUser.User.Id}`,
                  this.logger, 'getUsers', error);
                this.activeModal.close();
              }
            });
        }
      });
  }

  close() {
    const message = 'You will be asked on the next login to verify user(s) accessibility.';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.activeModal.close();
        }
      });
  }
}
