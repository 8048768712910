import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { IEnvironmentalAssessmentDto } from '../../../../services/models/hse/hse.model';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-environmental-assessment-create-modal',
  templateUrl: './environmental-assessment-create-modal.component.html',
  styleUrls: ['./environmental-assessment-create-modal.component.scss']
})
export class EnvironmentalAssessmentCreateModalComponent implements OnInit, OnDestroy {
  // Icons

  // Component Variables
  environmentalAssessment: IEnvironmentalAssessmentDto;
  companyId: number;
  selectedYear: number;
  allowedYears: number[] = [];
  assessmentName: string;
  branch = null;
  loading: boolean = false;

  companies$ = this.companyService.ActiveMembers$.asObservable();
  admin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment_CreateNew);

  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService,
              public authService: AdAuthService) {
  }

  ngOnInit() {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 1; year++) {
      this.allowedYears.push(year);
    }
  }

  ngOnDestroy(): void { }

  createEnvironmentalAssessment() {
    const openStatus = this.hseService.EnvironmentalAssessmentAnswerStatus.find(x => x.Description == 'Open');
    this.environmentalAssessment = {
      Name: this.assessmentName,
      Year: this.selectedYear,
      CompanyId: this.companyId,
      BranchId: null,
      Active: true,
      AssessmentStatusId: openStatus.Id,
      CreatedByUserId: this.authService.CurrentUser.UserId,
      LastModifiedByUserId: this.authService.CurrentUser.UserId
    } as IEnvironmentalAssessmentDto

    this.hseService.updateEnvironmentalAssessment(this.environmentalAssessment)
      .subscribe({
        next: () => {
          this.alertService.info('Environmental Risk Assessment successfully created.');
          this.close(true);
        },
        error: (error) => {
          this.alertService.error('An error occurred: ' + error.Meta.Message);
        }
      });
  }

  close(shouldReload:boolean) {
    this.activeModal.close(shouldReload);
  }
}
