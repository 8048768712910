import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { faMapPin, faPlaneArrival, faPlaneDeparture, faPlus, faShip, faTruck } from '@fortawesome/free-solid-svg-icons';
import { IRateGroup } from '../../../../services/models/pricing/rates.model';
import { PricingService } from '../../../../services/pricing.service';

@Component({
  selector: 'app-view-rategroups-modal',
  templateUrl: './view-rate-groups-modal.component.html',
  styleUrls: ['./view-rate-groups-modal.component.scss']
})
export class ViewRateGroupsModalComponent implements OnInit, OnDestroy {
  // Icons
  faAdd = faPlus;
  faLocation = faMapPin;
  faDepartureAir = faPlaneDeparture;
  faArrivalAir = faPlaneArrival;
  faSea = faShip;
  faRoad = faTruck;

  // Variables
  public RateGroups: IRateGroup[];
  newRateGroups: number = 0;
  existingRateGroups: number = 0;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService) {
  }

  ngOnInit(): void {
    this.newRateGroups = this.RateGroups.filter(x => x.ID == null).length;
    this.existingRateGroups = this.RateGroups.filter(x => x.ID != null).length;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  acceptAndSend() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.dismiss(false);
  }
}
