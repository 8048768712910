import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-systems',
  templateUrl: './hub-systems.component.html',
  styleUrls: ['./hub-systems.component.scss']
})
export class HubSystemsComponent {

}
