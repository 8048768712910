import {Injectable, OnDestroy} from '@angular/core';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IStoredProcJobParam } from './models/hangfire/scheduler.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService implements OnDestroy {

  // Service Variables
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public FireAndForgetJob(param: IStoredProcJobParam) {
    return this.api.post('StoredProcedureJob/FireAndForget', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateRecurringJob(param: IStoredProcJobParam) {
    return this.api.post('StoredProcedureJob/Recurring', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateContinuousJob(param: IStoredProcJobParam, parentJobName: string) {
    return this.api.post('StoredProcedureJob/Continuous/' + parentJobName, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateScheduledJob(param: IStoredProcJobParam) {
    return this.api.post('StoredProcedureJob/Recurring', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
