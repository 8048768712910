import { Component, OnInit } from '@angular/core';
import { faCalendar, faMinusSquare, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  ICompany, ICompanyDetailDefinition,
  ICompanyDetailUpdate
} from '../../../../services/models/member.model';
import { CompanyService } from '../../../../services/company.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { IUser, ICompanyUserDto } from '../../../../services/models/user.model';
import * as jwt_decode from 'jwt-decode';
import { IAuthUser } from '../../../../services/models/auth.model';
import { UserManagementModalComponent } from '../../user-management/user-management-modal/user-management-modal.component';
import { ICountry } from '../../../../services/models/pricing/location.model';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-manage-member-modal',
  templateUrl: './manage-agent-modal.component.html',
  styleUrls: ['./manage-agent-modal.component.scss']
})
export class ManageAgentModalComponent implements OnInit {
  // Icons
  faRemove = faMinusSquare;
  faInfo = faCircleQuestion;
  faEdit = faUserEdit;
  faCalendar = faCalendar;

  // Component Variables
  agent: ICompany;
  users: ICompanyUserDto[] = [];
  newDetails: ICompanyDetailUpdate[] = [];
  memberEdited: boolean = false;
  companyDetailDefinitions$ = this.companyService.CompanyDetailDefinitions$.asObservable();
  companyDetailDefinitions: ICompanyDetailDefinition[];
  emailDomainDefId: number;
  rateRequestDefId: number;
  agentCodeDefId: number;
  agentParentDefId: number;
  lastModifiedUser: IUser = null;
  invalid: boolean = false;
  newAgentId: number = null;
  // Permissions
  hasCreatePermission: boolean = false;

  // General variables
  env = environment;
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public auth: AdAuthService,
              private companyService: CompanyService,
              private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.hasCreatePermission = this.auth.CheckPermissionByCode(PermissionCodes.Admin_User_Create);

    this.companyDetailDefinitions$.subscribe({
      next: (data) => {
        this.companyDetailDefinitions = Object.assign([], data);
        this.agentCodeDefId = this.companyDetailDefinitions.find(x => x.PropertyName == 'AgentCode').Id;
        this.agentParentDefId = this.companyDetailDefinitions.find(x => x.PropertyName == 'AgentParent').Id;
        this.emailDomainDefId = this.companyDetailDefinitions.find(x => x.PropertyName == 'EmailDomein').Id;
        this.rateRequestDefId = this.companyDetailDefinitions.find(x => x.PropertyName == 'RateRequest').Id;
      }
    });
  }

  public LoadAgent(companyId: number) {
    this.loading = true;
    this.companyService.getCompany(companyId).subscribe({
      next:(data) => {
        this.agent = Object.assign({}, data);
        this.loadCompanyUsers(companyId);
        this.loadLastModifiedUser();
      },
      error:  () => {
        this.loading = false;
      }
    });
  }

  loadLastModifiedUser() {
    if (this.agent.ModifiedByUserId && this.agent.ModifiedByUserId) {
      this.auth.GetUserById(this.agent.ModifiedByUserId).subscribe(data => {
        this.lastModifiedUser = Object.assign({}, data);
      });
    }
  }

  loadCompanyUsers(companyId: number) {
    this.loading = true;

    this.companyService.getCompanyUsers(companyId).subscribe({
      next: (data: ICompanyUserDto[]) => {
        this.users = Object.assign([], data);
        this.loading = false;
      },
      error:() => {
        this.loading = false;
      }
    });
  }

  getDetailValue(propertyName: string) {
    const exists = this.agent.CompanyDetails.find((cd) => cd.CompanyDetailDefinition.PropertyName === propertyName);

    if (exists) {
      return exists.PropertyValue;
    } else {
      return null;
    }
  }

  updateDetailValue(definitionId: number, event: any) {
    this.loading = true;
    const propertyValue = event.target.value;
    const indexToUpdate = this.agent.CompanyDetails.findIndex((cd) => cd.CompanyDetailDefinitionId === definitionId);

    if (indexToUpdate < 0) {
      const newDetail = {
        Id: null,
        ModifiedByUserId: this.auth.CurrentUser.UserId,
        CompanyId: this.agent.CompanyId,
        CompanyDetailDefinitionId: definitionId,
        PropertyValue: propertyValue.toString()
      } as ICompanyDetailUpdate;

      this.newDetails.push(newDetail);
    } else {
      this.agent.CompanyDetails[indexToUpdate].PropertyValue = propertyValue;
    }
    this.loading = false;
  }

  setCountryPhysical(event: ICountry) {
    if (event) {
      this.agent.CountryPhysicalIso = event.ISO3;
      this.agent.CountryIdPhysical = event.Id;
      this.agent.CountryPhysical = event.Name;
    } else {
      this.agent.CountryPhysicalIso = null;
      this.agent.CountryIdPhysical = null;
      this.agent.CountryPhysical = null;
    }
  }

  setCountryPostal(event: ICountry) {
    if (event) {
      this.agent.CountryPostalIso = event.ISO3;
      this.agent.CountryIdPostal = event.Id;
      this.agent.CountryPostal = event.Name;
    } else {
      this.agent.CountryPostalIso = null;
      this.agent.CountryIdPostal = null;
      this.agent.CountryPostal = null;
    }
  }

  setMovingProvider(event: any) {
    this.agent.IsMovingServiceProvider = event.target.checked;
  }

  setRelocationProvider(event: any) {
    this.agent.IsRelocationServiceProvider = event.target.checked;
  }

  setFidiNumber(event: any) {
    if (event.target.checked == true) {
      this.agent.FIDINumber = 'Y';
    } else {
      this.agent.FIDINumber = 'N';
    }
  }

  openNewUserModal() {
    // Open modal to edit a user
    const modalRef = this.modalService.open(UserManagementModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.newUserCompanyID = this.agent.CompanyId;
    modalRef.componentInstance.isNewUser = true;
    // On modal close, read the result and refresh user list
    modalRef.result.then(() => {
      this.loadCompanyUsers(this.agent.CompanyId);
    }, () => {
    });
  }

  editUser(username: string) {
    this.loading = true;
    this.auth.GetUserPermissions(username).subscribe({
      next: (data) => {
        if (data) {
          this.loading = false;
          const token = data.Token;
          // Decode JWT token data section and set the current user
          const decoded: any = jwt_decode.default(token);
          const userParam = {
            UserId: +decoded.UserID,
            User: JSON.parse(decoded.User),
            UserRoles: JSON.parse(decoded.UserRoles),
            Permissions: JSON.parse(decoded.Permissions),
            UserCompanies: decoded.UserCompanies.length > 0 ? JSON.parse(decoded.UserCompanies) : []
          } as IAuthUser;
          // Open modal to Add/Edit a module
          const modalRef = this.modalService.open(UserManagementModalComponent, {size: 'xl', backdrop: 'static'});
          modalRef.componentInstance.authUser = userParam;
          // On modal close, read the result and apply logic
          modalRef.result.then((result: string) => {
            if (result) {
              this.alertService.success(result);
            }
          }, () => { });
        } else {
          this.alertService.error('Permission data for ' + username + ' could not be loaded.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Permission data for ' + username + ' could not be loaded.');
        this.loading = false;
      }
    });
  }

  save() {
    this.loading = true;
    this.memberEdited = true;
    this.agent.ModifiedByUserId = this.auth.CurrentUser.UserId;
    this.agent.ModifiedOnDate = new Date();

    if (!this.agent.AgentId && this.newAgentId) {
      this.agent.AgentId = this.newAgentId;
    }

    // Save member
    this.companyService.updateCompany(this.agent).subscribe({
      next: (data) => {
        if (data) {
          if (this.newDetails && this.newDetails.length > 0) {
            this.companyService.UpsertCompanyDetailList(this.newDetails).subscribe({
              next: () => {
                this.loading = false;
                this.newDetails = [];
                this.alertService.success('Member information successfully updated!');
                this.LoadAgent(this.agent.CompanyId);
              },
              error: () => {
                this.alertService.warn('An error occurred while attempting to updating member details.');
              }
            });
          } else {
            this.alertService.success('Member information successfully updated!');
            this.LoadAgent(this.agent.CompanyId);
          }
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.warn('An error occurred while attempting to update the member information.');
        this.loading = false;
      }
    });
  }

  close() {
    this.activeModal.close(this.memberEdited);
  }
}
