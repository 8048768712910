<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Member Management
  </h1>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!-- Filtering -->
    <div class="row mt-2">
      <div class="col-1">
        <b>Type</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(companyTypes$ | async)"
                [(ngModel)]="companyType"
                [ngModelOptions]="{standalone: true}">
          <option [ngValue]="null" selected>All member types</option>
          <option *ngFor="let c of (companyTypes$ | async)" [ngValue]="c.Description">{{ c.Description }}</option>
        </select>
      </div>
      <div class="col-1">
        <b>BC Ref.</b>
      </div>
      <div class="col-2">
        <input type="text"
               [(ngModel)]="bcRef"
               class="filter-item form-control form-control-sm"
               placeholder="Filter by BC reference...">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-1">
        <b>Name</b>
      </div>
      <div class="col-3">
        <input type="text"
               [(ngModel)]="searchText"
               class="filter-item form-control form-control-sm"
               placeholder="Filter members...">
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <div class="row mt-1">
      <div class="col-3 text-start">
        <small><b>Total members: </b> {{ getFilteredMembers().length }}</small>
      </div>
      <div class="col-1 offset-8 text-end">
        <button type="button"
                class="btn btn-sm btn-outline-primary harmony-teal-outline-button ms-2"
                (click)="searchText = null; companyType = null; bcRef = null;">
          Reset
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!-- Table header -->
    <div class="row mt-2 header-row">
      <div class="col-3 col-compress header-title border-split-left">
        Name
      </div>
      <div class="col-2 col-compress header-title">
        Type
      </div>
      <div class="col-2 col-compress header-title">
        Country
      </div>
      <div class="col-1 col-compress header-title">
        Published
      </div>
      <div class="col-1 col-compress header-title">
        BC Ref.
      </div>
      <div class="col-1 col-compress text-center header-title">
        Moving
      </div>
      <div class="col-1 col-compress text-center header-title">
        Relocation
      </div>
      <div class="col-1 col-compress header-title text-end border-split-right">

      </div>
    </div>
    <!-- Results -->
    <div class="row result-row" *ngFor="let member of getFilteredMembers()">
      <div class="col-3 col-compress overflow-hidden">
        <b>{{ member.CompanyName }}</b>
      </div>
      <div class="col-2 col-compress overflow-hidden">
        {{ member.CompanyType }}
      </div>
      <div class="col-2 col-compress overflow-hidden">
        {{ member.Country }}
      </div>
      <div class="col-1 col-compress overflow-hidden" [ngClass]="{'text-warning': member.PublishDate > now}">
        {{ member.PublishDate ? (member.PublishDate | date:env.FormattingStandards.ShortDateFormat) : '-' }}
      </div>
      <div class="col-1 col-compress overflow-hidden">
        {{ member.BusinessCentralID }}
      </div>
      <div class="col-1 col-compress text-center overflow-hidden">
        <fa-icon [icon]="faYes" class="harmony-green-text" *ngIf="member.IsMovingServiceProvider"></fa-icon>
        <fa-icon [icon]="faNo" class="harmony-grey-text" *ngIf="!member.IsMovingServiceProvider"></fa-icon>
      </div>
      <div class="col-1 col-compress text-center overflow-hidden">
        <fa-icon [icon]="faYes" class="harmony-green-text" *ngIf="member.IsRelocationServiceProvider"></fa-icon>
        <fa-icon [icon]="faNo" class="harmony-grey-text" *ngIf="!member.IsRelocationServiceProvider"></fa-icon>
      </div>
      <div class="col-1 col-compress text-end pe-0">
        <button class="btn btn-sm btn-outline-warning me-1"
                [ngClass]="{'harmony-teal-outline-button': member.PublishDate != null}"
                [title]="'Edit Member'"
                (click)="editMember(member)">
          <fa-icon [icon]="faEdit" size="xs"></fa-icon>
        </button>
      </div>
    </div>
    <!-- Loader -->
    <div class="row mt-4" *ngIf="loading">
      <div class="col-12 text-center">
        <app-loader [Width]="50"></app-loader>
      </div>
    </div>
    <!-- No Results -->
    <div class="row mt-4" *ngIf="!loading && (members && members.length === 0)">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <h4>No results...</h4>
      </div>
    </div>
  </div>
</div>
