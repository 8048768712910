import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '../../../services/file.service';
import { ICompanyReviewResponse } from '../../../services/models/annual-review/annual-review-company-review.model';
import { IAuthUser } from '../../../services/models/auth.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { Subject } from 'rxjs';
import { faCalendar, faCircleExclamation, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import {
  IAnswerDto,
  IAnswerQuestionChapterResponse,
  IAnswerUpsertRequest, IAnswerUpsertResponse,
  IAnswerQuestion,
  AnswerChapterResponse
} from '../../../services/models/annual-review/annual-review-answer.model';
import { AlertService } from '../../../services/alert.service';
import { ViewAnswerModalComponent } from './view-answer-modal/view-answer-modal.component';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AnnualReviewService } from '../../../services/annual-review.service';
import { HttpResponse } from '@angular/common/http';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IQuestionOption } from '../../../services/models/annual-review/annual-review-question.model';

@Component({
  selector: 'app-company-review',
  templateUrl: './company-review.component.html',
  styleUrls: ['./company-review.component.scss'],
  providers: [DatePipe]
})
export class CompanyReviewComponent implements OnInit, OnDestroy {
  // External variables
  @Input() isAdmin = false;
  @Input() userId: number;
  @Input() companyId: number;
  @Output() OnClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Icons
  faEdit = faEdit;
  faCalendar = faCalendar;
  faView = faEye;
  faAlert = faCircleExclamation;

  // Component variables
  public reviews: ICompanyReviewResponse[];
  public questionNoResults: boolean;
  isARSelected = false;
  isReviewCompleted: boolean;
  selectedReview: ICompanyReviewResponse;
  chapterQuestionAnswers: IAnswerQuestionChapterResponse;
  filteredChapters: AnswerChapterResponse[];
  validateAnswers: boolean;
  canSubmit: boolean;

  // Filter variables
  isMandatory: boolean = false;
  isUnanswered: boolean = false;

  public loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(private fileService: FileService,
              private annualReviewService: AnnualReviewService,
              private authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.getCompanyReviews();
    } else {
      if (this.authService.CurrentUser) {
        this.userId = this.authService.CurrentUser.User.Id;
        this.companyId = this.authService.CurrentUser.User.CompanyId;
        this.getCompanyReviews();
      }

      this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
        this.userId = user.User.Id;
        this.companyId = user.User.CompanyId;
        this.getCompanyReviews();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getCompanyReviews() {
    this.loading = true;
    this.annualReviewService.getCompanyReviews(this.companyId)
      .subscribe({
        next: (data) => {
          if (data) {
            this.reviews = Object.assign([], data);
            this.questionNoResults = this.reviews && this.reviews.length === 0;
          }
          this.loading = false;
        }, error: () => {
          this.alertService.error('Could not find any reviews, please contact us if this is not correct.');
          this.loading = false;
        }
      });
  }

  getFAQ() {
    this.fileService.GetFile(environment.ContainerNames.WebDocuments, environment.Blobs.AnnualReviewFAQ);
  }

  getDocumentByName(documentName: string) {
    this.fileService.GetFile('templates', documentName);
  }

  getAnnualReviewNetworkDoc(reviewId: number, companyReviewId: number, companyId: number) {
    this.annualReviewService.getNetworkDocument(reviewId, companyReviewId, companyId)
      .subscribe((response: HttpResponse<Blob>) => {
        const filename = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, filename);
      });
  }

  getAnnualReviewCompanyDoc(reviewId: number, companyReviewId: number, companyId: number) {
    this.annualReviewService.getCompanyDocument(reviewId, companyReviewId, companyId)
      .subscribe((response: HttpResponse<Blob>) => {
        const filename = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, filename);
      });
  }

  viewAnswers(review: ICompanyReviewResponse) {
    this.loading = true;
    this.isReviewCompleted = review.HasReviewClosed;
    this.selectedReview = review;

    this.annualReviewService.getAnswers(review.ReviewId, review.Id)
      .subscribe({
        next: data => {
          if (data) {
            this.isARSelected = true;
            this.chapterQuestionAnswers = Object.assign({}, data);
            this.filter();
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
  }

  setDateAnswer(event: NgbDate, chapterId: number, questionId: number, filteredChapterIndex: number) {
    const date = new Date(event.year, event.month - 1, event.day);
    this.changeAnswer(chapterId, questionId, this.datePipe.transform(date, environment.FormattingStandards.ShortDateFormat), filteredChapterIndex, false);
  }

  openAnswerModal(isReviewCompleted: boolean, answer: IAnswerDto, chapterId: number, questionId: number, filteredChapterIndex: number) {
    const modalRef = this.modalService.open(ViewAnswerModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.answer = answer;
    modalRef.componentInstance.isNew = !answer.Value;
    modalRef.componentInstance.canEdit = !isReviewCompleted;
    if (this.isAdmin) {
      modalRef.componentInstance.canEdit = false;
    }
    modalRef.result.then((value: string) => {
      this.changeAnswer(chapterId, questionId, value, filteredChapterIndex);
    }, () => {
    });
  }

  back() {
    this.isARSelected = false;
    this.selectedReview = null;
    this.chapterQuestionAnswers = null;
    this.canSubmit = false;
  }

  getMultipleChoiceAnswer(multipleChoiceAnswers: IAnswerDto[], option: IQuestionOption) {
    if (multipleChoiceAnswers && multipleChoiceAnswers.length > 0) {
      const answer = multipleChoiceAnswers.find(a => a.QuestionOptionId === option.Id);
      if (answer) {
        return answer.Value === 'Yes';
      }
    }
    return false;
  }

  changeMultipleChoiceAnswer(question: IAnswerQuestion, optionId: number, event: any, filteredChapterIndex: number, filteredQuestionIndex: number) {
    const totalYesAnswers = this.filteredChapters[filteredChapterIndex].AnswerQuestions[filteredQuestionIndex].MultipleChoiceAnswers.filter(a => a.Value === 'Yes');
    if (!event.target.checked && totalYesAnswers.length === 1) { // unchecked and only one yes value
      this.decreaseAnswerProgress(filteredChapterIndex);
    } else if (event.target.checked && totalYesAnswers.length === 0) { // checked and the first yes value
      this.increaseAnswerProgress(filteredChapterIndex);
    }

    const ansIndex = question.MultipleChoiceAnswers.findIndex(m => m.QuestionOptionId === optionId);
    if (ansIndex >= 0) {
      question.MultipleChoiceAnswers[ansIndex].Value = question.MultipleChoiceAnswers[ansIndex].Value === 'Yes' ? 'No' : 'Yes';
    } else {
      const answer = {
        Value: 'Yes',
        QuestionId: question.Id,
        QuestionOptionId: optionId,
        Id: null
      } as IAnswerDto;
      question.MultipleChoiceAnswers.push(answer);
    }
  }

  changeAnswer(chapterId: number, questionId: number, answer: string, filteredChapterIndex: number, applyTrim: boolean = true) {
    if (applyTrim) {
      answer = answer.trim();
    }
    const chapterIndex = this.chapterQuestionAnswers.AnswerChapters.findIndex(c => c.Id === chapterId);
    const questionIndex = this.chapterQuestionAnswers.AnswerChapters[chapterIndex].AnswerQuestions.findIndex(q => q.Id === questionId);
    this.chapterQuestionAnswers.AnswerChapters[chapterIndex].AnswerQuestions[questionIndex].Answer.Value = answer;
    if (!answer || answer === '') {
      this.decreaseAnswerProgress(filteredChapterIndex);
    } else {
      this.increaseAnswerProgress(filteredChapterIndex);
    }
  }

  getOrderedOptions(options: IQuestionOption[]): IQuestionOption[] {
    options = options.sort((a, b) => a.Label < b.Label ? -1 : 1);

    return options;
  }

  increaseAnswerProgress(chapterIndex: number) {
    this.updateAnswerProgress(chapterIndex, true);
  }

  decreaseAnswerProgress(chapterIndex: number) {
    this.updateAnswerProgress(chapterIndex, false);
  }

  updateAnswerProgress(chapterIndex: number, increase: boolean) {
    const totalAnsweredPerChapter = this.filteredChapters[chapterIndex].TotalAnswered;
    const totalAnswered = this.chapterQuestionAnswers.TotalAnswered;
    if (increase) {
      this.filteredChapters[chapterIndex].TotalAnswered = totalAnsweredPerChapter + 1;
      this.chapterQuestionAnswers.TotalAnswered = totalAnswered + 1;
    } else {
      this.filteredChapters[chapterIndex].TotalAnswered = totalAnsweredPerChapter - 1;
      this.chapterQuestionAnswers.TotalAnswered = totalAnswered - 1;
    }
  }

  save() {
    this.loading = true;
    this.annualReviewService.upsertAnswer(this.getAnswersToSave())
      .subscribe({
        next: data => {
          if (data) {
            const response: IAnswerUpsertResponse = Object.assign({}, data);
            this.canSubmit = response.CanSubmit;
            this.viewAnswers(this.selectedReview);
            if (this.canSubmit) {
              this.alertService.success('Your review was saved! Please complete it by submitting.');
            } else {
              this.alertService.success('Your review was saved! Please complete all mandatory questions and then submit.');
            }
            this.loading = false;
          }
        }, error: () => {
          this.loading = false;
          this.alertService.error('Could not save review. Please try again, or contact us for support.');
        }
      });
  }

  getAnswersToSave() {
    const answers = [] as IAnswerDto[];
    this.chapterQuestionAnswers.AnswerChapters.forEach(chapter => {
      chapter.AnswerQuestions.forEach(question => {
        if (question.Answer?.Value != null && question.Answer.QuestionOptionId === null) {
          const answer = {
            Id: question.Answer.Id,
            Value: question.Answer.Value,
            QuestionId: question.Id,
            QuestionOptionId: question.Answer.QuestionOptionId
          } as IAnswerDto;
          answers.push(answer);
        }

        if (question.MultipleChoiceAnswers != null && question.MultipleChoiceAnswers.length > 0) {
          question.MultipleChoiceAnswers.forEach(mca => {
            const answer = {
              Id: mca.Id,
              Value: mca.Value,
              QuestionId: mca.QuestionId,
              QuestionOptionId: mca.QuestionOptionId
            } as IAnswerDto;
            answers.push(answer);
          });
        }
      });
    });

    return {
      CompanyReviewId: this.selectedReview.Id,
      CompanyId: this.selectedReview.CompanyId,
      ReviewId: this.selectedReview.ReviewId,
      UserId: this.userId,
      Answers: answers
    } as IAnswerUpsertRequest;
  }

  submit() {
    this.validateAnswers = true;
    const canSubmit = this.checkMandatoryAnswers();
    if (canSubmit) {
      this.annualReviewService.upsertAnswer(this.getAnswersToSave())
        .subscribe({
          next: data => {
            if (data) {
              const message = 'Are you sure you want to submit? After submitting, no further changes can be made.\nClick Ok to continue.';
              OpenConfirmationModal(this.modalService, message)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((answer: any) => {
                  if (answer) {
                    this.annualReviewService.submitAnswer(this.selectedReview.Id)
                      .subscribe(() => {
                        this.alertService.success('Your annual review has been submitted successfully!');
                        this.getCompanyReviews();
                        this.back();
                        this.loading = false;
                      });
                  } else {
                    const response: IAnswerUpsertResponse = Object.assign({}, data);
                    this.canSubmit = response.CanSubmit;
                    this.viewAnswers(this.selectedReview);
                    if (this.canSubmit) {
                      this.alertService.success('Your review was saved! Please complete it by submitting.');
                    } else {
                      this.alertService.success('Your review was saved! Please complete all mandatory questions and then submit.');
                    }
                    this.loading = false;
                  }
                });
              this.loading = false;
            }
          }, error: () => {
            this.loading = false;
            this.alertService.error('Could not save review. Please try again, or contact us for support.');
          }
        });
    } else {
      this.alertService.warn('Answer all mandatory questions to submit.');
    }
  }

  filter() {
    this.filteredChapters = Object.assign([], this.chapterQuestionAnswers.AnswerChapters);
    this.filteredChapters = this.chapterQuestionAnswers.AnswerChapters.map((chapter) => {
      return {
        ...chapter,
        AnswerQuestions: chapter.AnswerQuestions.filter(x => (!this.isMandatory && !this.isUnanswered) // if non is checked
          || ((this.isMandatory && !this.isUnanswered && x.Mandatory) // only mandatory checked
            || (this.isUnanswered && !this.isMandatory && !this.doesQuestionHaveAnswer(x))) // only unanswered checked
          || (((this.isMandatory && this.isUnanswered && x.Mandatory && !this.doesQuestionHaveAnswer(x))))) // both are checked
      };
    });
    // filter out chapters with no questions
    this.filteredChapters = this.filteredChapters.filter(c => c.AnswerQuestions.length > 0);
    if (this.validateAnswers) {
      this.checkMandatoryAnswers(); // set the validation again
    }
  }

  doesQuestionHaveAnswer(question: IAnswerQuestion) {
    let hasAnswer = true;

    const stdAnswers = question.Type !== 7 && (question.Answer == null || question.Answer.Value === null || question.Answer.Value.length < 1);
    const multiAnswers = question.Type === 7 &&
      (question.MultipleChoiceAnswers == null || question.MultipleChoiceAnswers.filter(a => a.Value === 'Yes').length < 1);

    if (stdAnswers || multiAnswers) {
      hasAnswer = false;
    }
    return hasAnswer;
  }

  checkQuestionAnswer(questions: IAnswerQuestion[]) {
    let isAnyInvalid = false;

    const questionsWithoutAnswers = questions.filter(q => q.Mandatory && !this.doesQuestionHaveAnswer(q));
    if ((questionsWithoutAnswers && questionsWithoutAnswers.length > 0)) {
      questionsWithoutAnswers.forEach(x => x.Invalid = true);
      isAnyInvalid = true;
    }
    return isAnyInvalid;
  }


  checkMandatoryAnswers() {
    let canSubmit = true;
    this.filteredChapters.forEach(c => {
      const isInvalid = this.checkQuestionAnswer(c.AnswerQuestions);
      if (isInvalid) {
        c.Invalid = true;
        canSubmit = false;
      }
    });
    return canSubmit;
  }

  close() {
    this.OnClose.emit(false);
  }
}
