import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DatePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { NewsRoutes } from "../../../core/constants/routes";
import { IArticleOverview } from "../../../services/models/news/article.model";

@Component({
  selector: 'app-article-item',
  standalone: true,
  templateUrl: './article-item.component.html',
  imports: [
    NgStyle,
    NgClass,
    DatePipe,
    NgIf
  ],
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent implements OnInit {
  // Inputs
  @Input() Article: IArticleOverview;
  @Input() Class: string;
  imageUrl: string = '';

  protected readonly env = environment;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.Article.ImageReference) {
      this.imageUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/${this.Article.Id}/${this.Article.ImageReference}`
    } else {
      this.imageUrl = `${environment.SiteUrls.AzureStorageBaseURL}/branding/HRN_header_idmanual.jpg`;
    }
  }

  viewArticle() {
    if (this.Article) {
      this.router.navigate([`${NewsRoutes.Article_Read}/${this.Article.Id}`])
        .then(() => {
        });
    }
  }
}
