import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  faArrowRight,
  faBoxOpen,
  faCalendarXmark,
  faCheck,
  faCircleExclamation,
  faClock, faCopy,
  faEdit,
  faEye,
  faMapPin, faPlaneArrival, faPlaneDeparture, faShip, faSortDown, faSortUp,
  faSyncAlt, faTruck,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import {
  ICompanyRequestDto,
  ICompanyRequestSearchDto,
  IRateRequestLocation
} from '../../../services/models/pricing/rate-request.model';
import { RateGroupCaptureModalComponent } from './rategroup-capture-modal/rate-group-capture-modal.component';
import { ReviewRequestModalComponent } from './review-request-modal/review-request-modal.component';
import { DeclineRequestModalComponent } from './decline-request-modal/decline-request-modal.component';
import { environment } from 'src/environments/environment';
import { getDayDifference } from 'src/app/shared/functions/datetime-functions';
import { IAuthUserCompany } from '../../../services/models/member.model';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import {
  IProject, IRateGroup,
  IRateGroupPagination,
  IRateGroupParam
} from '../../../services/models/pricing/rates.model';
import { PricingService } from '../../../services/pricing.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../services/alert.service';
import { ApiService } from '../../../services/api.service';
import { IAuthUser } from '../../../services/models/auth.model';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { faCalendarAlt, faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { CloneRateGroupModalComponent } from './clone-rate-group-modal/clone-rate-group-modal.component';

@Component({
  selector: 'app-rate-capture',
  templateUrl: './rate-capture.component.html',
  styleUrls: ['./rate-capture.component.scss'],
  providers: [DatePipe]
})
export class RateCaptureComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faEye = faEye;
  protected readonly faWaiting = faClock;
  protected readonly faSuccess = faCheck;
  protected readonly faDeclined = faXmark;
  protected readonly faRejected = faCircleExclamation;
  protected readonly faExpired = faCalendarXmark;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faDateArrow = faArrowRight;
  protected readonly faLocation = faMapPin;
  protected readonly faDepartureAir = faPlaneDeparture;
  protected readonly faArrivalAir = faPlaneArrival;
  protected readonly faSea = faShip;
  protected readonly faRoad = faTruck;
  protected readonly faSortDesc = faSortUp;
  protected readonly faSortAsc = faSortDown;
  protected readonly faCopyRate = faCopy;
  protected readonly faCalendar = faCalendarAlt;

  // Request overview tab variables
  paginationData: IPaginationData<ICompanyRequestDto>;
  orderParam: IOrderParam;
  rateGroupOrderParam: IOrderParam;
  page: IPage;
  searchParam: ICompanyRequestSearchDto;
  userRateCaptureCompanies: IAuthUserCompany[];
  companyProjects$: Observable<IProject[]>;
  originLocations$: Observable<IRateRequestLocation[]>;
  destinationLocations$: Observable<IRateRequestLocation[]>;
  FromDateValue: any;
  ToDateValue: any;

  // Request rate groups tab variables
  activeRequest: ICompanyRequestDto = null;
  rateGroupsPaginationData: IPaginationData<IRateGroupPagination>;
  now: Date = null;

  // Lookup data
  RateRequestStatuses$ = this.pricingService.RateRequestStatuses$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;
  getDayDifference = getDayDifference;

  constructor(public pricingService: PricingService,
              private authService: AdAuthService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit() {
    this.initPagination();
    this.now = new Date();
    this.resetSearch();
    if (this.authService.CurrentUser) {
      this.loadUserRateCaptureCompanies();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.loadUserRateCaptureCompanies();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPagination() {
    // Init pagination data
    this.paginationData = {
      Data: null,
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<ICompanyRequestDto>;
    // Init pagination data
    this.rateGroupsPaginationData = {
      Data: null,
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IRateGroupPagination>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'ResponseDate',
      OrderDirection: 'desc'
    } as IOrderParam;

    // Set default ordering
    this.rateGroupOrderParam = {
      OrderBy: 'ValidTo',
      OrderDirection: 'desc'
    } as IOrderParam;
  }

  loadUserRateCaptureCompanies() {
    this.loading = true;

    this.authService.GetUserCompaniesForPermission(PermissionCodes.Pricing_CaptureRates).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IAuthUserCompany[]) => {
        if (data) {
          this.userRateCaptureCompanies = Object.assign([], data);
          if (this.userRateCaptureCompanies && this.userRateCaptureCompanies.length <= 1) {
            this.searchParam.CompanyID = this.authService.CurrentUser.User.CompanyId;
          }
          this.searchCompanyRequests();
          this.loadData();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  resetSearch() {
    this.initPagination();
    // Reset date inputs
    this.FromDateValue = null;
    this.ToDateValue = null;

    // Reset search param object
    this.searchParam = {
      RateGroupID: null,
      ProjectID: null,
      OriginLocationID: null,
      DestinationLocationID: null,
      RequestStatusID: null,
      CompanyID: null,
      CurrentPage: this.paginationData.CurrentPage,
      RowCount: this.paginationData.PageSize
    } as ICompanyRequestSearchDto;
  }

  searchFilter() {
    this.searchParam.CurrentPage = 1;
    this.page.pageNumber = 1;
    this.searchCompanyRequests();
    this.loadData();
  }

  searchCompanyRequests() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`RateCapture/CompanyRequests?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPaginationData<ICompanyRequestDto>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
          if (this.searchParam.RateGroupID && this.searchParam.RateGroupID > 0 && data.DataSet.length > 1) {
            this.alertService.info('No requests found for Rate Group reference: ' + this.searchParam.RateGroupID);
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchCompanyRequests();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.searchParam.CurrentPage = page.pageNumber;
    this.searchParam.RowCount = page.pageSize;

    this.searchCompanyRequests();
  }

  loadData() {
    this.getProjects();
    this.getOriginLocations();
    this.getDestinationLocations();
  }

  getProjects() {
    this.companyProjects$ = this.api.get(`RateCapture/CompanyRequestProjects/${this.searchParam.CompanyID ?? 0}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getOriginLocations() {
    this.originLocations$ = this.api.get(`RateCapture/CompanyRequestLocations/${this.searchParam.CompanyID ?? 0}/${true}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getDestinationLocations() {
    this.destinationLocations$ = this.api.get(`RateCapture/CompanyRequestLocations/${this.searchParam.CompanyID ?? 0}/${false}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // Initial status of "Pending" allows the user to accept or decline to provide rates for this request
  reviewRequest(request: ICompanyRequestDto) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ReviewRequestModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.CompanyRequest = request;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result === true) {
        this.acceptRequest(request);
      } else {
        this.declineRequest(request);
      }
    }, () => {
    });
  }

  // If the user ACCEPTS the request, update the status and open the RateGroup management
  acceptRequest(request: ICompanyRequestDto) {
    this.loading = true;
    this.api.post(`RateCapture/AcceptRequest/${this.authService.CurrentUser.UserId}`, request).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          request = Object.assign({}, data);
          this.manageRequest(request);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  // If the user DECLINES the request, update the status and refresh the list. Request can still be found with status filter
  declineRequest(request: ICompanyRequestDto) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(DeclineRequestModalComponent, {size: 'md', backdrop: 'static'});
    modalRef.componentInstance.CompanyRequest = request;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: ICompanyRequestDto) => {
      request = result;
      this.searchCompanyRequests();
    }, () => {
    });
  }

  // The user can manage valid requests at anytime
  manageRequest(request: ICompanyRequestDto) {
    this.loading = true;
    this.activeRequest = request;
    this.rateGroupsPaginationData.DataSet = [];

    const param = {
      RateRequestID: request.ID
    } as IRateGroupParam;

    const o = this.rateGroupOrderParam;
    const p = {
      pageNumber: 1,
      pageSize: 100,
      batched: false
    } as IPage;

    this.pricingService.SearchRateGroups(param, p, o).subscribe({
      next: (data: IPaginationData<IRateGroupPagination>) => {
        if (data) {
          this.rateGroupsPaginationData = Object.assign([], data);
          for (const DataSetItem of data.DataSet) {
            const rateGroup = DataSetItem;
            rateGroup.CanEdit = this.canEditRateGroup(rateGroup);
            rateGroup.CanEditDate = this.canEditDate(rateGroup);
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderRateGroups(param: IOrderParam) {
    this.rateGroupOrderParam = param;
    this.manageRequest(this.activeRequest);
  }

  deadlineClass(rateRequest: ICompanyRequestDto) {
    const isDew = rateRequest?.RateRequestStatusName === 'Pending' || rateRequest?.RateRequestStatusName === 'Accepted';
    const daysToDeadline = getDayDifference(rateRequest?.RespondByDate);

    if (isDew) {
      if (daysToDeadline > 7) {
        return 'harmony-green-text';
      } else if (daysToDeadline <= 0) {
        return 'text-danger';
      } else if (daysToDeadline < 7) {
        return 'text-warning';
      }
    } else {
      return '';
    }
  }

  daysUntilDeadline() {
    return getDayDifference(this.activeRequest?.RespondByDate);
  }

  canEditRateGroup(rg: IRateGroupPagination) {
    if (rg.RateVerdictName == null) {
      return false;
    }

    const verdict = this.pricingService.GetRateVerdictByName(rg.RateVerdictName);
    const validStatus = this.activeRequest?.RateRequestStatusName === 'Accepted';

    return (verdict.AgentEdit && validStatus);
  }

  canEditDate(rg: IRateGroupPagination) {
    let isMostRecent = true;

    const similarRateGroups = this.rateGroupsPaginationData.DataSet.filter(x => x.Description === rg.Description).sort((a, b) => a.ValidTo > b.ValidTo ? -1 : 1);
    const validStatus = this.activeRequest?.RateRequestStatusName === 'Completed';

    if (similarRateGroups.length > 0) {
      const mostRecent = similarRateGroups[0];
      isMostRecent = mostRecent.RateGroupId === rg.RateGroupId;
    }

    return isMostRecent && validStatus;
  }

  // Data handling functions
  manageRateGroup(rg: IRateGroupPagination) {
    // Open modal to manage a RateGroup
    const modalRef = this.modalService.open(RateGroupCaptureModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.RateGroupId = rg.RateGroupId;
    modalRef.componentInstance.CanEdit = rg.CanEdit;
    modalRef.componentInstance.CanEditDate = rg.CanEditDate;
    modalRef.componentInstance.LoadRateGroup();
    // On modal close, read the result and apply logic
    modalRef.result.then((data: IRateGroup) => {
      if (data != null) {
        const verdict = this.pricingService.RateVerdicts$.getValue().find(x => x.Id === data.RateVerdictID);
        rg.RateVerdictName = verdict?.Name ?? rg.RateVerdictName;
        rg.ValidFrom = data.ValidFrom;
        rg.ValidTo = data.ValidTo;
      } else {
        this.manageRequest(this.activeRequest);
      }
    }, () => {
    });
  }

  copyRateGroup(rateGroup: IRateGroupPagination) {
    // Open modal to manage a RateGroup
    const modalRef = this.modalService.open(CloneRateGroupModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.RateGroupId = rateGroup.RateGroupId;
    // On modal close, read the result and apply logic
    modalRef.result.then((refresh: boolean) => {
      if (refresh === true) {
        if (this.activeRequest.RateRequestStatusName === 'Completed') {
          this.activeRequest.RateRequestStatusName = 'Accepted';
          this.activeRequest.RespondByDate = rateGroup.ValidTo;
        }
        this.manageRequest(this.activeRequest);
      }
    }, () => { });
  }

  backToOverview() {
    this.activeRequest = null;
    this.rateGroupsPaginationData.DataSet = [];
    this.rateGroupsPaginationData.CurrentPage = 1;
    this.searchCompanyRequests();
  }

  canSubmitRates() {
    return (this.activeRequest.RateRequestStatusName === 'Accepted');
  }

  submitRatesForReview() {
    const message = 'Are you confident that you have supplied accurate and correct rates for Rate Groups listed below?\nOnce submitted for review, the rates can not be changed.';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.loading = true;
          this.api.get(`RateCapture/SubmitRates/${this.activeRequest.ID}/${this.authService.CurrentUser.UserId}`).pipe(
            takeUntil(this.unsubscribe)
          ).subscribe({
            next: (data) => {
              if (data) {
                this.activeRequest = Object.assign({}, data);
                this.alertService.success('Your rates have been successfully submitted for review. Once reviewed, you will receive an email of the result.');
                this.backToOverview();
              }
              this.loading = false;
            },
            error: () => {
              this.loading = false;
              this.alertService.error('An error has occurred while trying to submit your rates for review. If this problem persists, please contact the Harmony pricing team.');
            }
          });
        }
      });
  }
}
