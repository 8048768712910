<div class="mt-4 mb-5 pb-5">
  <div class="row">
    <div class="col-12 text-start harmony-grey-text">
      <p class="mb-0">
        Please enter the name of your tender, tender description, default margins, default currency, and a reference
        date for the validity of the rates in the tender.
      </p>
      <p>
        If you have any questions during the tender process, feel free to reach out to <a
        href="mailto:pricing@harmonyrelo.com" class="harmony-purple-link">pricing@harmonyrelo.com</a>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-8 align-self-center">
      <div class="row">
        <label class="col-2 col-form-label create-label">
          Member
        </label>
        <div class="col-6 py-1">
          {{ tenderService.SelectedCompany?.Name }}
        </div>
      </div>
      <div class="row mt-1">
        <label for="inputTenderName"
               class="col-2 col-form-label create-label">
          Tender Name
        </label>
        <div class="col-6">
          <input
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="tenderService.TenderView.Name"
            [disabled]="tenderService.IsQuoteAccepted()"
            id="inputTenderName"
            placeholder="e.g. Rate Tender 1">
        </div>
      </div>
      <div class="row mt-1">
        <label for="currencySearch"
               class="col-2 col-form-label create-label">
          Currency
        </label>
        <div class="col-6">
          <div class="input-group input-group-sm">
            <select class="form-select form-select-sm"
                    id="currencySearch"
                    [disabled]="tenderService.IsQuoteAccepted() || !(Currencies$ | async)"
                    [(ngModel)]="tenderService.SelectedCurrency"
                    [compareWith]="compareCurrencyFn"
                    (change)="onCurrencySelect()">
              <option *ngFor="let currency of (Currencies$ | async)" [ngValue]="currency">
                {{ currency.Currency.Code }}: {{ currency.Currency.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-2 col-form-label create-label">
          Margins
        </label>
        <div class="col-2">
          <div class="input-group input-group-sm">
            <input type="text"
                   class="form-control form-control-sm"
                   [disabled]="tenderService.IsQuoteAccepted()"
                   [(ngModel)]="tenderService.TenderView.DefaultMarginPercentage"
                   numbersOnly="2.2"
                   placeholder="e.g. 12.50"/>
            <span class="input-group-text" id="percentage-add-on">%</span>
          </div>
          <small class="form-text text-muted mt-0">
            Percentage Value
          </small>
        </div>
        <div class="col-2">
          <div class="input-group input-group-sm">
            <input type="text"
                   numbersOnly="4.2"
                   class="form-control form-control-sm"
                   min="0"
                   maxlength="8"
                   [disabled]="tenderService.IsQuoteAccepted()"
                   [(ngModel)]="tenderService.TenderView.DefaultMarginMinimum"
                   placeholder="e.g. 300"/>
            <span class="input-group-text"
                  *ngIf="tenderService.SelectedCurrency"
                  id="min-add-on">
              {{ tenderService.SelectedCurrency.Currency.Code }}
            </span>
          </div>
          <small class="form-text text-muted mt-0">
            Minimum margin
          </small>
        </div>
        <div class="col-2">
          <div class="input-group input-group-sm">
            <input type="text"
                   numbersOnly="4.2"
                   class="form-control form-control-sm"
                   [disabled]="tenderService.IsQuoteAccepted()"
                   [(ngModel)]="tenderService.TenderView.DefaultMarginMaximum"
                   placeholder="e.g. 500"/>
            <span class="input-group-text"
                  *ngIf="tenderService.SelectedCurrency"
                  id="max-add-on">
              {{ tenderService.SelectedCurrency.Currency.Code }}
            </span>
          </div>
          <small class="form-text text-muted mt-0">
            Maximum margin
          </small>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-2 col-form-label create-label">
          Valid Until
        </label>
        <div class="col-6">
          <app-date-picker [identifier]="'valueFromDate'"
                           [minDate]="today"
                           [value]="tenderService.TenderView.ExpiryDate"
                           (onChange)="tenderService.TenderView.ExpiryDate = $event"
                           [disabled]="tenderService.IsQuoteAccepted()"
                           [placeholder]="'Select a from date'">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="col-4 text-center">
      <img src="assets/images/vector/laptop-1.svg" style="width: 75%; margin-top: -4em; margin-bottom: -5.5em;" alt="">
    </div>
  </div>
</div>
