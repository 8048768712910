import { Component, OnInit } from '@angular/core';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  // Icons
  faToHome = faHandPointLeft;

  constructor(public router: Router) { }

  ngOnInit() {
  }
}
