import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TenderService } from '../../../services/tender.service';
import { AlertService } from '../../../services/alert.service';
import {
  ITenderDto,
  ITenderFreight,
  ITenderLocation,
  ITenderModality, ITenderOverviewResult,
  ITenderQuote,
  ITenderService
} from '../../../services/models/pricing/tender.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportRatesModalComponent } from './tender-wizard-overview/export-rates-modal/export-rates-modal.component';
import { faChevronCircleLeft, faChevronCircleRight, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { environment } from '../../../../environments/environment';
import { PricingService } from '../../../services/pricing.service';
import { FileService } from '../../../services/file.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-rate-wizard',
  templateUrl: './tender-wizard.component.html',
  styleUrls: ['./tender-wizard.component.scss'],
  providers: [TenderService]
})
export class TenderWizardComponent implements OnInit, OnDestroy, AfterViewChecked {
  // Icons
  faLeftArrow = faChevronCircleLeft;
  faRightArrow = faChevronCircleRight;
  faExcel = faFileExcel;

  // Component Variables
  currentStep: number = 1;
  tenderToLoad: ITenderOverviewResult;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public tenderService: TenderService,
              public pricingService: PricingService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private authService: AdAuthService,
              private cdRef: ChangeDetectorRef,
              private fileService: FileService) { }

  ngOnInit() {
    if (this.tenderService.TenderView) {
      this.currentStep = this.tenderService.TenderView.TenderStepId;
      this.tenderService.StoreTender();
    } else {
      // Load tender from session storage
      const tender = this.tenderService.GetTenderFromStorage();
      if (tender && tender.Id) {
        this.tenderService.TenderView = Object.assign({}, tender);
        this.currentStep = tender.TenderStepId;
        this.tenderService.LoadCompany(tender.CompanyId);
        this.tenderService.LoadTenderCurrency(tender.DefaultCurrencyId);
      } else {
        this.tenderService.RemoveTenderFromStorage();
        this.tenderService.ResetTenderService();
      }
    }

    // Check if user can view tender in storage
    if (this.authService.CurrentUser) {
      this.checkTenderCompanyValidity();
    }
    this.authService.CurrentUser$.subscribe(() => {
      this.checkTenderCompanyValidity();
    });
  }

  ngOnDestroy() {
    // this.tenderService.RemoveTenderFromStorage();
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  checkTenderCompanyValidity() {
    if (this.tenderService.TenderView && this.tenderService.TenderView.Id) {
      if (this.tenderService.TenderView.CompanyId !== this.authService.CurrentUser.User.CompanyId) {
        this.tenderService.RemoveTenderFromStorage();
        this.tenderService.ResetTenderService();
      }
    }
  }

  createNew() {
    this.currentStep = 1;
    this.tenderService.NewTender();
  }

  SetTender(tender: ITenderOverviewResult) {
    this.tenderToLoad = tender;
    this.currentStep = tender.TenderStepId;
    this.tenderService.LoadTender(tender.Id);
  }

  cancelCreateEdit() {
    this.tenderService.ResetTenderService();
  }

  /********  Validity checks per step ************/
  isStepValid() {
    switch (this.currentStep) {
      case 1:
        return this.isCreateValid();
      case 2:
        return this.isLocationsValid();
      case 3:
        return this.isModalityValid();
      case 4:
        return this.isFreightValid();
      case 5:
        return this.isQuoteValid();
      case 6:
        return this.IsRatesValid();
      default:
        break;
    }
  }

  /*** STEP 1: Create Tender ***/
  isCreateValid() {
    const p = this.tenderService.TenderView;

    return !!(p &&
      p.Name && p.Name.length > 3 &&
      p.CompanyId &&
      p.DefaultCurrencyId && p.DefaultCurrencyId > 0 &&
      p.DefaultMarginPercentage !== null && p.DefaultMarginPercentage.toString() !== '' &&
      p.DefaultMarginMinimum !== null && p.DefaultMarginMinimum >= 0 &&
      p.DefaultMarginMaximum !== null && p.DefaultMarginMaximum >= 0 &&
      p.ExpiryDate);
  }

  /*** STEP 2: Add Locations ***/
  isLocationsValid() {
    const p = this.tenderService.TenderView;
    let result = true;

    if (p.TenderLocations && p.TenderLocations.length > 0) {
      p.TenderLocations.forEach((location) => {
        if (location.AirportCityFunctionId === null || location.SeaPortCityFunctionId === null) {
          result = false;
        }
      });
    } else {
      result = false;
    }

    return result;
  }

  /*** STEP 3: Add Volume & Weight (Modality) ***/
  isModalityValid() {
    const p = this.tenderService.TenderView;
    let result = false;

    if (p.TenderModalities && p.TenderModalities.length > 0) {
      result = true;
    }

    return result;
  }

  /*** STEP 4: Select Freight Combinations ***/
  isFreightValid() {
    const p = this.tenderService.TenderView;
    let result = false;

    if (p.TenderFreights) {
      result = true;
    }

    return result;
  }

  /*** STEP 5: Quote  ***/
  isQuoteValid() {
    return true;
  }

  /*** STEP 6: Rates ***/
  IsRatesValid() {
    return true;
  }

  /********  Navigation Methods ************/
  getNextStepName(currentStep: number) {
    switch (currentStep) {
      case 1: // Configure Tender Step
        return 'Save & Next: Locations';
      case 2: // Locations Step
        return 'Save & Next: Volume & Weight';
      case 3: // Modality Step
        return 'Save & Next: Freight';
      case 4: // Freight Step
        return 'Save & Next: Services';
      case 5: // Services Step
        return 'Save & Next: Export';
      default:
        return 'Export';
    }
  }

  getNextStepNamePostExport(currentStep: number) {
    switch (currentStep) {
      case 1: // Configure Tender Step
        return 'Next: Locations';
      case 2: // Locations Step
        return 'Next: Volume & Weight';
      case 3: // Modality Step
        return 'Next: Freight';
      case 4: // Freight Step
        return 'Next: Services';
      case 5: // Services Step
        return 'Next: Export';
      default:
        return 'Export';
    }
  }

  getStepName(currentStep: number) {
    switch (currentStep) {
      case 1: // Configure Tender Step
        return 'Configure Tender';
      case 2: // Locations Step
        return 'Locations';
      case 3: // Modality Step
        return 'Volume & Weight';
      case 4: // Freight Step
        return 'Freight';
      case 5: // Services Step
        return 'Services';
      case 6: // Quote & Export step
        return 'Export';
      default:
        return 'Export';
    }
  }

  leaveExportStep() {
    if (this.currentStep > 1) {
      this.currentStep = 5;
      this.updateStep(this.currentStep);
    }
  }

  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
      this.updateStep(this.currentStep);
    }
  }

  canSaveStep() {
    return !this.tenderService.IsQuoteAccepted() &&
      (this.currentStep === 2 || this.currentStep === 3 || this.currentStep === 4 || this.currentStep === 5);
  }

  saveProgress() {
    // Save current step data
    switch (this.currentStep) {
      case 1: // Create Tender Step
        break;
      case 2: // Locations Step
        this.saveTenderLocations(false);
        break;
      case 3: // Modality Step
        this.saveTenderModalities(false);
        break;
      case 4: // Freight Step
        this.saveTenderFreight(false);
        break;
      case 5: // Services Step
        this.saveTenderServices(false);
        break;
      case 6: // Rate and Export Step
        break;
      default:
        break;
    }
  }

  nextStep() {
    // Save current step data and move to the next step
    switch (this.currentStep) {
      case 1: // Create Tender Step
        this.createTender();
        break;
      case 2: // Locations Step
        this.saveTenderLocations(true);
        break;
      case 3: // Modality Step
        this.saveTenderModalities(true);
        break;
      case 4: // Freight Step
        this.saveTenderFreight(true);
        break;
      case 5: // Services Step
        this.saveTenderServices(true);
        break;
      case 6: // Rate and Export Step
        break;
      default:
        break;
    }
  }

  updateStep(step: number) {
    // Move to provided step
    this.currentStep = step; // Freight Step
    this.tenderService.TenderView.TenderStepId = this.currentStep;
    this.tenderService.SaveTender().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.tenderService.StoreTender();
    });
  }

  // Step 1: Create Tender
  createTender() {
    this.tenderService.loading = true;
    this.tenderService.SaveTender().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.tenderService.TenderView = Object.assign({} as ITenderDto, data);
          this.tenderService.StoreTender();
          // Move to next step
          this.updateStep(2);
        } else {
          this.alertService.error('An Error Occurred: SaveTender (Create Step)');
        }
        this.tenderService.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: SaveTender');
        this.tenderService.loading = false;
      }
    });
  }

  // Step 2: Locations
  saveTenderLocations(goToNextStep: boolean = false) {
    this.tenderService.loading = true;
    // const locationsToSave = this.tenderService.TenderLocationUpdateList;
    const locationsToSave = this.tenderService.TenderView.TenderLocations;

    if (locationsToSave && locationsToSave.length > 0) {
      this.tenderService.SaveObject('TenderLocation/UpdateList', locationsToSave).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            // this.tenderService.TenderLocationUpdateList = [];
            this.tenderService.TenderView.TenderLocations = Object.assign([] as ITenderLocation[], data);
            this.validateLocationLimit();
            this.tenderService.StoreTender();
            this.alertService.success('Locations successfully saved!');
            if (goToNextStep) {
              // Move to next step
              this.updateStep(3);
            }
          }
          this.tenderService.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: SaveObject (TenderLocation)');
          this.tenderService.loading = false;
          this.tenderService.SaveTender();
        }
      });
    } else {
      if (goToNextStep) {
        // Move to next step
        this.updateStep(3);
      } else {
        this.alertService.info('No changes to be saved.');
      }
      this.tenderService.loading = false;
    }
  }

  validateLocationLimit() {
    // If there are more tenderLocations than are allowed for Freight selection, make sure that all TenderFreight records
    // are marked as Active = false, so that they are not included in the quote if the location limit was
    // reached after having selected freight lanes.
    if (this.tenderService.TenderView.TenderLocations.length >= environment.Pricing.TenderLocationFreightCapacity) {
      this.tenderService.TenderView.TenderFreights.forEach((tf) => {
        tf.Active = false;
      });

      this.tenderService.SaveObject('TenderFreight/UpdateList', this.tenderService.TenderView.TenderFreights).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.tenderService.TenderView.TenderFreights = Object.assign([] as ITenderFreight[], data);
            this.tenderService.StoreTender();
          }
          this.tenderService.loading = false;
        },
        error: () => {
          this.tenderService.loading = false;
        }
      });
    }
  }

  // Step 3: Modality
  saveTenderModalities(goToNextStep: boolean = false) {
    this.tenderService.loading = true;
    // const modalitiesToSave = this.tenderService.TenderModalityUpdateList;
    const modalitiesToSave = this.tenderService.TenderView.TenderModalities;

    if (modalitiesToSave && modalitiesToSave.length > 0) {
      this.tenderService.SaveObject('TenderModality/UpdateList', modalitiesToSave).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            // this.tenderService.TenderModalityUpdateList = [];
            this.tenderService.TenderView.TenderModalities = Object.assign([] as ITenderModality[], data);
            this.tenderService.StoreTender();
            if (goToNextStep) {
              // Move to next step
              this.updateStep(4);
            } else {
              this.alertService.success('Volume & Weight options successfully saved!');
            }
          }
          this.tenderService.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: SaveObject (TenderModality)');
          this.tenderService.loading = false;
        }
      });
    } else {
      if (goToNextStep) {
        // Move to next step
        this.updateStep(4);
      } else {
        this.alertService.info('No changes to be saved.');
      }
      this.tenderService.loading = false;
    }
  }

  // Step 4: Freight
  saveTenderFreight(goToNextStep: boolean = false) {
    this.tenderService.loading = true;
    const freightsToSave = this.tenderService.TenderFreightUpdateList;

    if (freightsToSave && freightsToSave.length > 0) {
      this.tenderService.SaveObject('TenderFreight/UpdateList', freightsToSave).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.tenderService.TenderFreightUpdateList = [];
            this.tenderService.TenderView.TenderFreights = Object.assign([] as ITenderFreight[], data);
            this.tenderService.StoreTender();
            if (goToNextStep) {
              // Move to next step
              this.updateStep(5);
            } else {
              this.alertService.success('Freight lanes successfully saved!');
            }
          }
          this.tenderService.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: SaveObject (TenderFreight)');
          this.tenderService.loading = false;
        }
      });
    } else {
      if (goToNextStep) {
        // Move to next step
        this.updateStep(5);
      } else {
        this.alertService.info('No changes to be saved.');
      }
      this.tenderService.loading = false;
    }
  }

  // Step 5: Services
  saveTenderServices(goToNextStep: boolean = false) {
    this.tenderService.loading = true;
    const servicesToSave = this.tenderService.TenderServiceUpdateList;

    if (servicesToSave && servicesToSave.length > 0) {
      this.tenderService.SaveObject('TenderService/UpdateList', servicesToSave).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.tenderService.TenderServiceUpdateList = [];
            this.tenderService.TenderView.TenderServices = Object.assign([] as ITenderService[], data);
            // clone the object in order to force Angular to apply changes
            this.tenderService.TenderView.TenderServices = JSON.parse(JSON.stringify(this.tenderService.TenderView.TenderServices));
            this.tenderService.StoreTender();
            if (goToNextStep) {
              // Move to next step
              this.generateQuote();
            } else {
              this.alertService.success('Services successfully saved!');
            }
          }
          this.tenderService.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: SaveTender (TenderServices)');
          this.tenderService.loading = false;
        }
      });
    } else {
      if (goToNextStep) {
        // Move to next step
        if (this.tenderService.IsQuoteAccepted()) {
          this.updateStep(7);
        } else {
          this.generateQuote();
        }
      } else {
        this.alertService.info('No changes to be saved.');
      }
      this.tenderService.loading = false;
    }
  }

  // Step 6: Rates
  generateQuote() {
    // Get Quote data
    const param = {
      TenderID: this.tenderService.TenderView.Id,
      CurrencyID: this.tenderService.TenderView.DefaultCurrencyId
    };

    this.tenderService.GetQuote(param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (quoteData) => {
        if (quoteData) {
          this.tenderService.MultiLaneQuote = Object.assign([] as ITenderQuote[], quoteData);
          // Move to next step
          this.updateStep(6);
        }
        this.tenderService.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: GetQuote (TenderQuote)');
        this.tenderService.loading = false;
      }
    });
  }

  exportRates() {
    if (this.tenderService.CheckPopupBlocker()) {
      alert( 'Your Pop-up blocker is enabled! Please disable the Pop-up blocker for this site and try again.');
    } else {
      if (this.tenderService.IsExported()) {
        this.exportTender();
      } else {
        // Open modal to accept export terms
        const modalRef = this.modalService.open(ExportRatesModalComponent);
        modalRef.componentInstance.Tender = this.tenderService.TenderView;
        // On modal close, read the result and apply logic
        modalRef.result.then((result: ITenderDto) => {
          if (result !== null && result.DateFirstExport) {
            this.tenderService.TenderView.QuoteAcceptedDate = result.QuoteAcceptedDate;
            this.tenderService.TenderView.TenderStatusId = result.TenderStatusId;
            this.exportTender();
          } else {
            this.alertService.info('Excel export was interrupted');
            this.tenderService.ExportingRates = false;
            this.tenderService.loading = false;
          }
        }, () => {
          this.alertService.info('Excel export was interrupted');
          this.tenderService.ExportingRates = false;
          this.tenderService.loading = false;
        });
      }
    }
  }

  exportTender() {
    this.tenderService.ExportingRates = true;
    this.tenderService.loading = true;
    this.tenderService.ExportTender().subscribe({
      next: (response: HttpResponse<Blob>) => {
        const fileName = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, fileName);
        this.tenderService.ExportingRates = false;
        this.tenderService.loading = false;
      }, error: () => {
        this.alertService.error('An error occurred while trying to export your rates. Please try again or contact support for assistance.');
        this.tenderService.ExportingRates = false;
        this.tenderService.loading = false;
      }
    });
  }
}
