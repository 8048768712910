import { Component } from '@angular/core';

@Component({
  selector: 'app-services-corporate-sales',
  templateUrl: './services-corporate-sales.component.html',
  styleUrls: ['./services-corporate-sales.component.scss']
})
export class ServicesCorporateSalesComponent {

}
