<div class="row" *ngIf="tenderService.SingleLaneTenderParam">
  <div class="col-7 border-end">
    <ng-container>
      <!-------------------------------------->
      <!-------- Configure Request ----------->
      <!-------------------------------------->
      <div class="row">
        <div class="col-12 text-center">
          <h5 class="harmony-blue-text fw-bold">Configure Preferences</h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="form-group row mt-2">
        <label for="inputTenderName" class="col-3 col-form-label">
          Name
        </label>
        <div class="col-9">
          <input type="text"
                 class="form-control form-control-sm"
                 [ngClass]="{ 'is-invalid': showValidation && (!tenderService.SingleLaneTenderParam.Name || tenderService.SingleLaneTenderParam.Name.length <= 0) }"
                 [(ngModel)]="tenderService.SingleLaneTenderParam.Name"
                 [disabled]="tenderService.IsSingleRateCompleted()"
                 id="inputTenderName"
                 placeholder="e.g. AMS to LND Rate">
        </div>
      </div>
      <div class="form-group row">
        <label for="currencySearch" class="col-3 col-form-label">
          Currency
        </label>
        <div class="col-9">
          <div class="input-group input-group-sm mb-1">
            <select *ngIf="(Currencies$ | async)"
                    class="form-select form-select-sm"
                    id="currencySearch"
                    [ngClass]="{ 'is-invalid': showValidation && !tenderService.SelectedCurrency }"
                    [disabled]="tenderService.IsSingleRateCompleted()"
                    [(ngModel)]="tenderService.SelectedCurrency"
                    [compareWith]="compareCurrencyFn"
                    (change)="onCurrencySelect()">
              <option [ngValue]="null">Select a currency..</option>
              <option *ngFor="let currency of (Currencies$ | async)" [ngValue]="currency">
                {{ currency.Currency.Code }}: {{ currency.Currency.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-3 col-form-label">
          Margins
        </label>
        <div class="col-3">
          <div class="input-group input-group-sm">
            <input
              type="text"
              class="form-control form-control-sm"
              [ngClass]="{ 'is-invalid': showValidation && (!tenderService.SingleLaneTenderParam.DefaultMarginPercentage || tenderService.SingleLaneTenderParam.DefaultMarginPercentage < 0) }"
              [disabled]="tenderService.IsSingleRateCompleted() || !tenderService.SelectedCurrency"
              [(ngModel)]="tenderService.SingleLaneTenderParam.DefaultMarginPercentage"
              numbersOnly="2.2"
              placeholder="0" />
            <span class="input-group-text" id="percentage-add-on">%</span>
          </div>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm">
            <input
              type="text"
              numbersOnly="4.2"
              class="form-control form-control-sm"
              min="0"
              maxlength="8"
              [ngClass]="{ 'is-invalid': showValidation && (!tenderService.SingleLaneTenderParam.DefaultMarginMinimum || tenderService.SingleLaneTenderParam.DefaultMarginMinimum < 0) }"
              [disabled]="tenderService.IsSingleRateCompleted() || !tenderService.SelectedCurrency"
              [(ngModel)]="tenderService.SingleLaneTenderParam.DefaultMarginMinimum"
              placeholder="Minimum, e.g. 150" />
            <span class="input-group-text"
                  *ngIf="tenderService.SelectedCurrency"
                  id="min-add-on">
            {{ tenderService.SelectedCurrency.Currency.Code }}
          </span>
          </div>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm">
            <input
              type="text"
              numbersOnly="4.2"
              class="form-control form-control-sm"
              [ngClass]="{ 'is-invalid': showValidation && (!tenderService.SingleLaneTenderParam.DefaultMarginMaximum || tenderService.SingleLaneTenderParam.DefaultMarginMaximum < 0) }"
              [disabled]="tenderService.IsSingleRateCompleted() || !tenderService.SelectedCurrency"
              [(ngModel)]="tenderService.SingleLaneTenderParam.DefaultMarginMaximum"
              placeholder="Maximum, e.g. 500" />
            <span class="input-group-text"
                  *ngIf="tenderService.SelectedCurrency"
                  id="max-add-on">
            {{ tenderService.SelectedCurrency.Currency.Code }}
          </span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-3 col-form-label">
          Valid Until
        </label>
        <div class="col-9">
          <div class="input-group input-group-sm">
            <app-date-picker [identifier]="'expireDate'"
                             (onChange)="tenderService.SingleLaneTenderParam.ExpiryDate = $event"
                             [value]="tenderService.SingleLaneTenderParam.ExpiryDate"
                             [minDate]="today"
                             [showError]="showValidation && !tenderService.SingleLaneTenderParam.ExpiryDate"
                             [disabled]="tenderService.IsSingleRateCompleted()">
            </app-date-picker>
          </div>
        </div>
      </div>

      <!------------------------------>
      <!----- Choose Locations ------->
      <!------------------------------>
      <div class="row pt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-5 text-center">
              <h5 class="harmony-blue-text fw-bold">Origin</h5>
            </div>
            <div class="col-2 text-center">
              <fa-icon [icon]="faRightArrow" size="1x"></fa-icon>
            </div>
            <div class="col-5 text-center">
              <h5 class="harmony-blue-text fw-bold">Destination</h5>
            </div>
          </div>
          <hr class="my-1">
          <div class="row mt-2 text-center">
            <div class="col-5 text-nowrap">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                  tenderService.SingleLaneTenderResult.OriginLocation ?
                  tenderService.SingleLaneTenderResult.OriginLocation.CityName :
                  '-'
                }}
              </p>
              <app-city-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="(tenderService.SingleLaneTenderParam != null && tenderService.IsSingleRateCompleted()) || originPortsLoading"
                [Identifier]="'OriginCity'"
                [SelectedID]="tenderService.SingleLaneTenderParam.OriginCityId"
                [ShowError]="showValidation && (tenderService.SingleLaneTenderParam.OriginCityId === null && tenderService.SingleLaneTenderParam.DestinationCityId === null)"
                [ErrorColor]="'#dc3545'"
                (OnSelect)="setOriginCity($event)">
              </app-city-search>
            </div>
            <div class="col-2 text-center fw-bold">
              City
            </div>
            <div class="col-5 text-center text-nowrap">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                  tenderService.SingleLaneTenderResult.DestinationLocation ?
                  tenderService.SingleLaneTenderResult.DestinationLocation.CityName :
                  '-'
                }}
              </p>
              <app-city-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="tenderService.IsSingleRateCompleted() || destinationPortsLoading"
                [Identifier]="'DestinationCity'"
                [SelectedID]="tenderService.SingleLaneTenderParam.DestinationCityId"
                [ShowError]="showValidation && (tenderService.SingleLaneTenderParam.OriginCityId === null && tenderService.SingleLaneTenderParam.DestinationCityId === null)"
                [ErrorColor]="'#dc3545'"
                (OnSelect)="setDestinationCity($event)">
              </app-city-search>
            </div>
          </div>
          <div class="row mt-1 text-center">
            <div class="col-5 text-nowrap">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 [title]="tenderService.SingleLaneTenderResult.OriginLocation?.AirPortName"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                  tenderService.SingleLaneTenderResult.OriginLocation ?
                  tenderService.SingleLaneTenderResult.OriginLocation.AirPortName :
                  '-'
                }}
              </p>
              <app-port-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="tenderService.IsSingleRateCompleted() || originPortsLoading || tenderService.SingleLaneTenderParam.OriginCityId === null"
                [Identifier]="'AIR_Origin'"
                [SelectedID]="tenderService.SingleLaneTenderParam.OriginAirPortId"
                [IsAirport]="true"
                [ShowError]="showValidation && tenderService.SingleLaneTenderParam.OriginCityId !== null && tenderService.SingleLaneTenderParam.OriginAirPortId === null"
                [ErrorColor]="'#dc3545'"
                (OnSelectObject)="selectAirPort(true, $event)">
              </app-port-search>
            </div>
            <div class="col-2 text-center fw-bold">
              Airport
            </div>
            <div class="col-5 text-center text-nowrap">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 [title]="tenderService.SingleLaneTenderResult.DestinationLocation?.AirPortName"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                  tenderService.SingleLaneTenderResult.DestinationLocation ?
                  tenderService.SingleLaneTenderResult.DestinationLocation.AirPortName :
                  '-'
                }}
              </p>
              <app-port-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="tenderService.IsSingleRateCompleted() || destinationPortsLoading || tenderService.SingleLaneTenderParam.DestinationCityId === null"
                [Identifier]="'AIR_Destination'"
                [SelectedID]="tenderService.SingleLaneTenderParam.DestinationAirPortId"
                [IsAirport]="true"
                [ShowError]="showValidation && tenderService.SingleLaneTenderParam.DestinationCityId !== null && tenderService.SingleLaneTenderParam.DestinationAirPortId === null"
                [ErrorColor]="'#dc3545'"
                (OnSelectObject)="selectAirPort(false, $event)">
              </app-port-search>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-5 text-center">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 [title]="tenderService.SingleLaneTenderResult.OriginLocation?.SeaPortName"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                  tenderService.SingleLaneTenderResult.OriginLocation ?
                  tenderService.SingleLaneTenderResult.OriginLocation.SeaPortName :
                  '-'
                }}
              </p>
              <app-port-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="tenderService.IsSingleRateCompleted() || originPortsLoading || tenderService.SingleLaneTenderParam.OriginCityId === null"
                [Identifier]="'SEA_Origin'"
                [SelectedID]="tenderService.SingleLaneTenderParam.OriginSeaPortId"
                [IsAirport]="false"
                [ShowError]="showValidation && tenderService.SingleLaneTenderParam.OriginCityId !== null && tenderService.SingleLaneTenderParam.OriginSeaPortId === null"
                [ErrorColor]="'#dc3545'"
                (OnSelectObject)="selectSeaPort(true, $event)">
              </app-port-search>
            </div>
            <div class="col-2 text-center fw-bold">
              Seaport
            </div>
            <div class="col-5 text-center text-nowrap">
              <p class="mb-0 ms-1 overflow-hidden"
                 style="text-overflow: ellipsis"
                 [title]="tenderService.SingleLaneTenderResult.DestinationLocation?.SeaPortName"
                 *ngIf="tenderService.SingleLaneTenderResult && tenderService.SingleLaneTenderResult.IsProcessed == true">
                {{
                tenderService.SingleLaneTenderResult.DestinationLocation ?
                  tenderService.SingleLaneTenderResult.DestinationLocation.SeaPortName :
                  '-'
                }}
              </p>
              <app-port-search
                *ngIf="tenderService.SingleLaneTenderResult === null || tenderService.SingleLaneTenderResult.IsProcessed == false"
                class="d-inline"
                [Disabled]="tenderService.IsSingleRateCompleted() || destinationPortsLoading || tenderService.SingleLaneTenderParam.DestinationSeaPortId === null"
                [Identifier]="'SEA_Destination'"
                [SelectedID]="tenderService.SingleLaneTenderParam.DestinationSeaPortId"
                [IsAirport]="false"
                [ShowError]="showValidation && tenderService.SingleLaneTenderParam.DestinationCityId !== null && tenderService.SingleLaneTenderParam.DestinationSeaPortId === null"
                [ErrorColor]="'#dc3545'"
                (OnSelectObject)="selectSeaPort(false, $event)">
              </app-port-search>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-5">
    <!-------------------------------------->
    <!----- Choose Volume & Weight --------->
    <!-------------------------------------->
    <div class="row volume-weight-wrapper">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center">
            <h5 class="harmony-blue-text fw-bold">Volume & Weight</h5>
          </div>
        </div>
        <hr class="my-1">
        <div class="row my-1" *ngIf="newMode && !tenderService.IsSingleRateCompleted()">
          <div class="col-3">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedModality"
                    [disabled]="!(modalityTypes$ | async)"
                    [ngClass]="{ 'is-invalid': showValidation && tenderService.SingleLaneTenderParam.TenderModalities.length <= 0 }"
                    (change)="setMode()">
              <option [ngValue]="null" [disabled]="true">Select mode...</option>
              <option *ngFor="let modality of (modalityTypes$ | async)" [ngValue]="modality">
                {{modality.Name}}
              </option>
            </select>
          </div>
          <div class="col-2">
            <input type="text"
                   numbersOnly="4.2"
                   class="form-control form-control-sm"
                   max="10000"
                   min="0"
                   maxlength="8"
                   [ngClass]="{ 'is-invalid': showValidation && tenderService.SingleLaneTenderParam.TenderModalities.length <= 0 }"
                   [(ngModel)]="newMode.Quantity"
                   placeholder="e.g. 45" />
          </div>
          <div class="col-6">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedUnitType"
                    [ngClass]="{ 'is-invalid': showValidation && tenderService.SingleLaneTenderParam.TenderModalities.length <= 0 }"
                    (change)="setQuantityUnitType()"
                    [disabled]="!newMode.ModalityTypeId || tenderService.loading">
              <option [ngValue]="null" [disabled]="true">Select unit type...</option>
              <option *ngFor="let unitType of unitTypes" [ngValue]="unitType">
                {{ unitType.Name }} ({{ unitType.Abbreviation }})
              </option>
            </select>
          </div>
          <div class="col-1 text-end">
            <button class="btn btn-sm btn-outline-success"
                    type="button"
                    [disabled]="canAddMode()"
                    (click)="addMode()">
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let mode of tenderService.SingleLaneTenderParam.TenderModalities; let i = index">
          <div class="row py-1">
            <div class="col-3 ps-3">
              <strong>{{ mode.ModalityTypeName }}</strong>
            </div>
            <div class="col-2 text-end">
              {{ mode.Quantity }}
            </div>
            <div class="col-6 overflow-hidden">
              {{ mode.UnitTypeName }}
            </div>
            <div class="col-1 text-end pe-3">
              <fa-icon class="text-danger pointer"
                *ngIf="!tenderService.IsSingleRateCompleted()"
                (click)="removeMode(i)"
                       [title]="'Remove'"
                [icon]="faBin" size="1x"></fa-icon>
            </div>
          </div>
          <hr class="mx-1 my-0">
        </ng-container>
        <ng-container *ngIf="!tenderService.SingleLaneTenderParam.TenderModalities || tenderService.SingleLaneTenderParam.TenderModalities.length <= 0">
          <div class="row mt-2">
            <div class="col-12 text-center">
              <p class="harmony-grey-text">
                Add volume/weight values...
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!tenderService.SingleLaneTenderParam">
  <app-loader [Width]="50"></app-loader>
</ng-container>
<hr>
<!-------------------------------------->
<!----- View Results and Export -------->
<!-------------------------------------->
<div class="row">
  <div class="col-12">
    <ng-container *ngIf="loadingResult">
      <app-loader [Width]="50"></app-loader>
    </ng-container>
    <ng-container *ngIf="!loadingResult && (tenderService.SingleLaneTenderResult == null || !tenderService.IsSingleRateCompleted())">
      <div class="row mt-3">
        <div class="col-4 offset-4">
          <button
            class="btn btn-outline-primary harmony-purple-outline-button w-100"
            type="button"
            (click)="getRate()">
            Get Rates
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loadingResult && tenderService.SingleLaneTenderResult != null && tenderService.IsSingleRateCompleted()">
      <div class="row" *ngIf="!tenderService.IsSingleRateExported()">
        <div class="col-12 harmony-grey-text">
          <small>
            <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the &nbsp;<fa-icon class="harmony-purple-text" [icon]="faEditIcon"></fa-icon> “Change Rate Source” icon.
          </small>
        </div>
      </div>
      <ng-container *ngFor="let mode of (modalityTypes$ | async)">
        <div class="row mt-2" *ngIf="hasMode(mode.Id)">
          <div class="col-12">
            <h5 class="harmony-blue-text fw-bold">{{ mode.Name }} Rates</h5>
          </div>
        </div>
        <div class="table-wrapper" *ngIf="hasMode(mode.Id)">
          <table class="table">
            <!-- Header -->
            <thead class="rate-table-header">
              <tr>
                <th scope="col">
                  Location
                </th>
                <th scope="col">
                  Rate Source
                </th>
                <th scope="col" class="overflow-hidden" *ngFor="let modality of tenderService.GetSingleLaneModalitiesByType(mode.Id)">
                  {{ modality.Quantity }} {{ modality.UnitTypeName }}
                </th>
              </tr>
            </thead>
            <!-- Data -->
            <tbody>
            <!-- ORIGIN -->
              <tr *ngIf="tenderService.SingleLaneTenderResult.OriginLocation != null">
                <th scope="row">
                  {{
                    tenderService.SingleLaneTenderResult.OriginLocation.CityName
                  }}
                </th>
                <ng-container *ngVar="getLocationRateGroup(tenderService.SingleLaneTenderResult.OriginLocation?.TenderLocationRateGroups, mode.Id) as locationRateGroup">
                  <ng-container *ngIf="locationRateGroup">
                    <td>
                      <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsSingleRateExported()">
                        <div class="col-12">
                          <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                            <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">
                              {{ rateGroup.IsHarmonyMember ? '(H) ' : '' }}{{ rateGroup.RedSkyAgentName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length <= 0 || tenderService.IsSingleRateExported()">
                        <div class="col-10 overflow-hidden">
                          {{ locationRateGroup.RateGroupName }}
                        </div>
                        <div class="col-2 text-end" *ngIf="!(tenderService.IsSingleRateCompleted() || getDayDifference(tenderService.SingleLaneTenderParam.ExpiryDate) < 0)">
                          <fa-icon [icon]="faEditIcon"
                                   class="harmony-purple-text pointer"
                                   [title]="'Change Rate Source'"
                                   *ngIf="!locationRateGroup.IsLoadingOptions"
                                   (click)="getRateGroupOptions(locationRateGroup, tenderService.SingleLaneTenderResult.OriginLocation, tenderService.SingleLaneTenderResult.OriginLocation.AirportCityFunctionId)"></fa-icon>
                          <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                        </div>
                      </div>
                    </td>
                    <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                      <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                      {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
              <!-- FREIGHT -->
              <tr *ngIf="tenderService.SingleLaneTenderResult.OriginLocation != null && tenderService.SingleLaneTenderResult.DestinationLocation != null">
                <th scope="row">
                  <fa-icon [icon]="faDownArrow"></fa-icon>
                </th>
                <ng-container *ngVar="getFreight(tenderService.SingleLaneTenderResult.TenderFreights, mode.Id) as freightRate">
                  <ng-container *ngIf="freightRate">
                    <td>
                      {{ freightRate.TenderFreightRateGroups[0].RateGroupName }}
                    </td>
                    <td *ngFor="let rate of freightRate.TenderFreightRateGroups">
                      <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                      {{ rate.TotalRate ? (rate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
              <!-- DESTINATION -->
              <tr *ngIf="tenderService.SingleLaneTenderResult.DestinationLocation != null">
                <th scope="row">
                  {{
                    tenderService.SingleLaneTenderResult.DestinationLocation ?
                    tenderService.SingleLaneTenderResult.DestinationLocation.CityName :
                    '- No destination chosen -'
                  }}
                </th>
                <ng-container *ngVar="getLocationRateGroup(tenderService.SingleLaneTenderResult.DestinationLocation?.TenderLocationRateGroups, mode.Id) as locationRateGroup">
                  <ng-container *ngIf="locationRateGroup">
                    <td>
                      <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsSingleRateExported()">
                        <div class="col-12">
                          <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                            <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">
                              {{ rateGroup.IsHarmonyMember ? '(H) ' : '' }}{{ rateGroup.RedSkyAgentName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length <= 0 || tenderService.IsSingleRateExported()">
                        <div class="col-10 overflow-hidden">
                          {{ locationRateGroup.RateGroupName }}
                        </div>
                        <div class="col-2 text-end" *ngIf="!(tenderService.IsSingleRateCompleted() || getDayDifference(tenderService.SingleLaneTenderParam.ExpiryDate) < 0)">
                          <fa-icon [icon]="faEditIcon"
                                   class="harmony-purple-text pointer"
                                   [title]="'Change Rate Source'"
                                   *ngIf="!locationRateGroup.IsLoadingOptions"
                                   (click)="getRateGroupOptions(locationRateGroup, tenderService.SingleLaneTenderResult.DestinationLocation, tenderService.SingleLaneTenderResult.DestinationLocation?.AirportCityFunctionId)"></fa-icon>
                          <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                        </div>
                      </div>
                    </td>
                    <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                      <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                      {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            <tr class="total-row">
              <th scope="row">
                <b>TOTAL</b>
              </th>
              <td>

              </td>
              <td *ngFor="let tm of tenderService.GetSingleLaneModalitiesByType(mode.Id)">
                <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                {{ getTotalRate(tm) | number:'1.2-2' }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
