import { Component, Input } from '@angular/core';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss']
})
export class NoResultComponent {
  @Input() showNoResults = false;
  @Input() noResultText = 'No results...';
  // Icons
  faBoxOpen = faBoxOpen;

  constructor() { }
}
