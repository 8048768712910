import { Component } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-contact-modal',
  templateUrl: './user-contact-modal.component.html',
  styleUrls: ['./user-contact-modal.component.scss']
})
export class UserContactModalComponent {
  // Component Variables
  public UserID: number;

  // General variables
  env = environment;
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.close(false);
  }
}
