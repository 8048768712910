<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h1 class="h2 harmony-grey-text">
    Report: {{ riskAssessment.Name }}
  </h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"> </button>
</div>
<app-loader *ngIf="loading"></app-loader>
<!-- Body -->
<div class="modal-body">
  <div class="row ms-1 me-1 my-1">
    <div class="header-title col-12">
      <b>Total Risk Level</b>
    </div>
  </div>
  <div class="row ms-1 me-1 my-2">
    <div class="col-6">
      Total number of items with an initial risk level higher then 70
    </div>
    <div class="col-2 text-end">
      {{ riskReport.TotalRiskLevel }}
    </div>
  </div>
  <div class="row ms-1 me-1 my-2">
    <div class="col-6">
      Total number of items with a residual risk level higher then 70
    </div>
    <div class="col-2 text-end">
      {{ riskReport.TotalRemaining }}
    </div>
  </div>

  <!-- Score of Measures Header-->
  <div class="row ms-1 me-1 my-1 mt-4">
    <div class="header-title col-12">
      <b>Score of Measures</b>
    </div>
  </div>
  <div class="row ms-1 me-1 my-2">
    <div class="col-6">
      <b>Description</b>
    </div>
    <div class="col-2 text-end">
      <b>No</b>
    </div>
    <div class="col-2 text-end ">
      <b>Percentage</b>
    </div>
  </div>
  <!-- Loop Score of Measures -->
  <div class="row result-row ms-1 me-1" *ngFor="let score of riskReport.ScoreOfMeasures; let i = index">
    <div class="col-6 col-compress overflow-hidden">
      {{ score.Description }}
    </div>
    <div class="col-2 col-compress overflow-hidden text-end">
      {{ score.Number }}
    </div>
    <div class="col-2 col-compress overflow-hidden text-end">
      {{ score.Percentage ?? 0 }} %
    </div>
  </div>

  <div class="row result-row ms-1 me-1">
    <div class="col-6 col-compress overflow-hidden fw-bold text-end">
      Total
    </div>
    <div class="col-2 col-compress overflow-hidden fw-bold text-end">
      {{ riskReport.SumMeasures }}
    </div>
    <div class="col-2 col-compress overflow-hidden">

    </div>
  </div>

  <!-- Focal points header-->
  <div class="row ms-1 me-1 my-1 mt-4">
    <div class="header-title col-12">
      <b>Top 25 Focal points after assessment</b>
    </div>
  </div>
  <div class="row ms-1 me-1 my-2">
    <div class="col-2">
      <b>Item Number</b>
    </div>
    <div class="col-8">
      <b>Topic</b>
    </div>
    <div class="col-2">
      <b>Remaining Total</b>
    </div>
  </div>
  <!-- Loop Focal points -->
  <div class="row result-row ms-1 me-1" *ngFor="let point of riskReport.FocalPoints; let i = index">
    <div class="col-2 col-compress overflow-hidden">
      {{ point.ItemNumber }}
    </div>
    <div class="col-8 col-compress overflow-hidden">
      {{ point.Topic }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ point.RemainingTotal }}
    </div>
  </div>

</div>

<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

