<!---- Header ---->
<div class="modal-header modal-title">
  <h4 *ngIf="isNew">
    Add Emergency Drill Evaluation
  </h4>
  <h4 *ngIf="!isNew">
    Edit Emergency Drill Evaluation: {{drillEvaluation.Id}}
  </h4>
</div>
<!---- Add/Edit ---->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2" *ngIf="hasCompanyFilterPermission">
      Company <span class="text-danger">*</span>
    </div>
    <div class="col-4" *ngIf="hasCompanyFilterPermission">
      <app-company-search [Disabled]="!hasCompanyFilterPermission || !canEdit"
                          [ShowError]="!drillEvaluation.CompanyId && invalid"
                          [SelectedID]="drillEvaluation.CompanyId" (OnSelect)="changeCompanyId($event)"
                          [MembersOnly]="true"></app-company-search>
    </div>
    <div class="col-2">
      Branch
    </div>
    <div class="col-4">
      <div *ngIf="branches.length == 0">
        <select class="form-select form-select-sm form-select-sm pointer" aria-label="Disabled select example"
                disabled>
          <option selected>Default</option>
        </select>
      </div>
      <div *ngIf="branches.length > 0">
        <select [disabled]="!canEdit" [(ngModel)]="drillEvaluation.BranchId"
                class="form-select form-select-sm form-select-sm pointer"
                [ngClass]="{'is-invalid': !drillEvaluation.BranchId && invalid }">
          <option [ngValue]="null" selected>Select a branch...</option>
          <option *ngFor="let branch of branches"
                  [ngValue]="branch.Id">{{ branch.Name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2">
      Drill Date <span class="text-danger">*</span>
    </div>
    <div class="col-4">
      <app-date-picker [identifier]="'executeDate'"
                       (onChange)="drillEvaluation.ExecutedDate = $event" [value]="drillEvaluation.ExecutedDate"
                       [showError]="!drillEvaluation.ExecutedDate && invalid"
                       [placeholder]="'Select a date'">
      </app-date-picker>
    </div>
    <div class="col-3">
      Environmental Emergency Drill
    </div>
    <div class="col-3">
      <div class="form-check form-switch form-switch-md">
        <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-environment'"
               [title]="'Is the evaluation environment related'" [checked]="drillEvaluation.IsEnvironment"
               (change)="setEnvironment($event)" [disabled]="!canEdit">
      </div>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2">
      Location
    </div>
    <div class="col-4">
      <textarea class="form-control form-control-sm" name="location" id="location" rows="3"
                [placeholder]="'Enter the location of were the drill evaluation took place'"
                [(ngModel)]="drillEvaluation.Location" [disabled]="!canEdit"></textarea>
    </div>
    <div class="col-3">
      Health & Safety Emergency Drill
    </div>
    <div class="col-3">
      <div class="form-check form-switch form-switch-md">
        <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-health-and-safety'"
               [title]="'Is the evaluation health and safety related'" [checked]="drillEvaluation.IsHealthAndSafety"
               (change)="setHealthAndSafety($event)" [disabled]="!canEdit">
      </div>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2">
      Participants
    </div>
    <div class="col-4">
      <textarea class="form-control form-control-sm" name="participants" id="participants" rows="3"
                [placeholder]="'Enter names of participants'"
                [(ngModel)]="drillEvaluation.Participants" [disabled]="!canEdit"></textarea>
    </div>
    <div class="col-2">
      Situation
    </div>
    <div class="col-4">
      <textarea class="form-control form-control-sm" name="situation" id="situation" rows="3"
                [placeholder]="'Describe the situation'"
                [(ngModel)]="drillEvaluation.Situation" [disabled]="!canEdit"></textarea>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2">
      Course
    </div>
    <div class="col-4">
      <textarea class="form-control form-control-sm" name="course" id="course" rows="3"
                [placeholder]="'Describe the course of action'"
                [(ngModel)]="drillEvaluation.Course" [disabled]="!canEdit"></textarea>
    </div>
    <div class="col-2">
      Findings
    </div>
    <div class="col-4">
      <textarea class="form-control form-control-sm" name="findings" id="findings" rows="3"
                [placeholder]="'Summarize your findings'"
                [(ngModel)]="drillEvaluation.Findings" [disabled]="!canEdit"></textarea>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2">
      Conclusion
    </div>
    <div class="col-10">
      <textarea class="form-control form-control-sm" name="conclusion" id="conclusion" rows="3"
                [placeholder]="'Describe your conclusion'"
                [(ngModel)]="drillEvaluation.Conclusion" [disabled]="!canEdit"></textarea>
    </div>
  </div>
<app-loader *ngIf="loading"></app-loader>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="!isNew" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="isNew" (click)="add()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
