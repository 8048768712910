<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Application Log
  </h1>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!-- Filtering -->
    <div class="row mt-2">
      <div class="col-1">
        <b>Type</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="loading"
                [(ngModel)]="searchParam.Source"
                [ngModelOptions]="{standalone: true}">
          <option [ngValue]="null" selected>All sources</option>
          <option [ngValue]="'API'">API</option>
          <option [ngValue]="'Portal'">Portal</option>
        </select>
      </div>
      <div class="col-1">
        <b>From Date</b>
      </div>
      <div class="col-2">
        <app-date-picker [identifier]="'nextDatepicker'"
                         [disabled]="loading"
                         (onChange)="searchParam.FromDate = $event"
                         [value]="searchParam.FromDate"
                         [placeholder]="'Select a from date'">
        </app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-1">
        <b>Level</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="loading"
                [(ngModel)]="searchParam.Level"
                [ngModelOptions]="{standalone: true}">
          <option [ngValue]="null" selected>All levels</option>
          <option [ngValue]="'Trace'">Trace</option>
          <option [ngValue]="'Debug'">Debug</option>
          <option [ngValue]="'Info'">Info</option>
          <option [ngValue]="'Warn'">Warn</option>
          <option [ngValue]="'Error'">Error</option>
          <option [ngValue]="'Fatal'">Fatal</option>
        </select>
      </div>
      <div class="col-1">
        <b>To  Date</b>
      </div>
      <div class="col-2">
        <app-date-picker [identifier]="'nextDatepicker'"
                         [disabled]="loading"
                         (onChange)="searchParam.ToDate = $event"
                         [value]="searchParam.ToDate"
                         [placeholder]="'Select a to date'">
        </app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-1">
        <b>Search</b>
      </div>
      <div class="col-3">
        <input type="text"
               [(ngModel)]="searchParam.SearchText"
               class="filter-item form-control form-control-sm"
               placeholder="Search logs...">
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <div class="row mt-1">
      <div class="col-2 offset-10 text-end">
        <button type="button"
                class="btn btn-sm btn-primary harmony-purple-button w-100"
                (click)="searchApplicationLog()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'ApplicationLog'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!------------------->
    <!----- Results ----->
    <!------------------->
    <div class="row mt-2 header-row">
      <div class="col-2 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Date"
           data-order="asc">
        Date
        <fa-icon [hidden]="orderParam.OrderBy != 'Date'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="Level"
           data-order="asc">
        Level
        <fa-icon [hidden]="orderParam.OrderBy != 'Level'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="Source"
           data-order="asc">
        Source
        <fa-icon [hidden]="orderParam.OrderBy != 'Source'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-3 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="Logger"
           data-order="asc">
        Logger
        <fa-icon [hidden]="orderParam.OrderBy != 'Logger'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-4 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="Message"
           data-order="asc">
        Message
        <fa-icon [hidden]="orderParam.OrderBy != 'Message'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title text-end border-split-right">

      </div>
    </div>
    <ng-container *ngIf="!loading && paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let log of paginationData.DataSet">
        <div class="col-2 col-compress overflow-hidden">
          <b><small>{{ log.Date | date: env.FormattingStandards.LongDateFormat }}</small></b>
        </div>
        <div class="col-1 col-compress overflow-hidden"
             [ngClass]="getClass(log.Level)">
          {{ log.Level ?? '-' }}
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ log.Source ?? '-' }}
        </div>
        <div class="col-3 col-compress overflow-hidden">
          <small>{{ log.Logger }}</small>
        </div>
        <div class="col-4 col-compress overflow-hidden">
          <small>{{ log.Message }}</small>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-outline-warning harmony-teal-outline-button me-1"
                  [title]="'Edit Member'"
                  (click)="viewLog(log)">
            <fa-icon [icon]="faView" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!-- Loader -->
    <ng-container *ngIf="loading">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </ng-container>
    <!-- No Results -->
    <ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
      <div class="row mt-4" *ngIf="!loading && (paginationData.DataSet && paginationData.DataSet.length === 0)">
        <div class="col-12 text-center harmony-grey-text">
          <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
        </div>
        <div class="col-12 text-center harmony-grey-text mt-1">
          <h4>No results...</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
