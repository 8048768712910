import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { IVerifyCompanyDetailsRequest, IVerifyUsersRequest } from './models/verify/verify.model';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {

  constructor(private api: ApiService) {
  }

  private unsubscribe: Subject<any> = new Subject<any>();

  public getValidity(companyId) {
    return this.api.get(`Verify/Validity/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public users(userId: number, companyId: number, activeUserIds: number[], inactiveUserIds: number[]) {
    const body = {
      UserId: userId,
      CompanyId: companyId,
      ActiveUserIds: activeUserIds,
      InactiveUserIds: inactiveUserIds
    } as IVerifyUsersRequest;

    return this.api.post('Verify/Users', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public companyDetails(request: IVerifyCompanyDetailsRequest) {
    return this.api.post('Verify/CompanyDetails', request).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
