import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { IActivity, IHRPhrase, ILocation, IPSPhrase } from './models/hse/hse.model';

@Injectable({
  providedIn: 'root'
})
export class HseDsrService implements OnDestroy {
  Activities: IActivity[];
  Activities$: BehaviorSubject<IActivity[]> = new BehaviorSubject<IActivity[]>([]);
  Locations: ILocation[];
  Locations$: BehaviorSubject<ILocation[]> = new BehaviorSubject<ILocation[]>([]);
  HRPhrases: IHRPhrase[];
  HRPhrases$: BehaviorSubject<IHRPhrase[]> = new BehaviorSubject<IHRPhrase[]>([]);
  PSPhrases: IPSPhrase[];
  PSPhrases$: BehaviorSubject<IPSPhrase[]> = new BehaviorSubject<IPSPhrase[]>([]);

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) {

    if (!this.Activities || this.Activities.length <= 0) {
      this.getActivities();
    }

    if (!this.Locations || this.Locations.length <= 0) {
      this.getLocations();
    }

    if (!this.HRPhrases || this.HRPhrases.length <= 0) {
      this.getHRPhrases();
    }

    if (!this.PSPhrases || this.PSPhrases.length <= 0) {
      this.getPSPhrases();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getActivities(){
    this.apiService.get('HSELookup/Activity')
      .subscribe({
        next: (data) => {
          this.Activities = Object.assign([], data);
          this.Activities$.next(data);
        },
        error: () => {}
      })
  }

  getLocations(){
    this.apiService.get('HSELookup/Location')
      .subscribe({
        next: (data) => {
          this.Locations = Object.assign([], data);
          this.Locations$.next(data);
        },
        error: () => {}
      })
  }

  getHRPhrases(){
    this.apiService.get('HSELookup/HRPhrase')
      .subscribe({
        next: (data) => {
          this.HRPhrases = Object.assign([], data);
          this.HRPhrases$.next(data);
        },
        error: () => {}
      })
  }

  getPSPhrases(){
    this.apiService.get('HSELookup/PSPhrase')
      .subscribe({
        next: (data) => {
          this.PSPhrases = Object.assign([], data);
          this.PSPhrases$.next(data);
        },
        error: () => {}
      })
  }


}
