import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { HseService } from 'src/app/services/hse.service';
import { IAnalysisCauseSpecific, IIncident } from '../../../../services/models/hse/hse.model';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AdAuthService } from 'src/app/core/ad-auth-service/ad-auth.service';
import { FormValidationStyleDirective } from 'src/app/shared/directives/form-validation/form-validation-directive';
import { Subject } from 'rxjs';
import { CompanyService } from '../../../../services/company.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-incident-improvement-modal',
  templateUrl: './incident-improvement-modal.component.html',
  styleUrls: ['./incident-improvement-modal.component.scss'],
  providers: [FormValidationStyleDirective]
})
export class IncidentImprovementModalComponent implements OnInit, OnDestroy {
  @ViewChild('handlingTab') handlingTab: ElementRef;
  @ViewChild('registrationFormElement') registrationFormElement: FormGroupDirective;
  @ViewChild('handlingFormElement') handlingFormElement: FormGroupDirective;
  @ViewChild('causeinvestigationFormElement') causeinvestigationFormElement: FormGroupDirective;
  @ViewChild('peopleInvolvedFormElement') peopleInvolvedFormElement: FormGroupDirective;
  @ViewChild('injuryDamageTypeFormElement') injuryDamageTypeFormElement: FormGroupDirective;
  @ViewChild('analysisFormElement') analysisFormElement: FormGroupDirective;
  @ViewChild('effectivenessFormElement') effectivenessFormElement: FormGroupDirective;

  // Component Variables
  Incident: IIncident;
  isNew: boolean;
  analysisCauseSpecificationOptions: IAnalysisCauseSpecific[];
  active = 1;
  registrationForm: UntypedFormGroup;
  handlingForm: UntypedFormGroup;
  analysisForm: UntypedFormGroup;
  effectivenessForm: UntypedFormGroup;
  incidentReportForm: UntypedFormGroup;
  injuryDamageTypeForm: UntypedFormGroup;
  injuryDamageToForm: UntypedFormGroup;
  injuryCauseForm: UntypedFormGroup;
  peopleInvolvedForm: UntypedFormGroup;
  causeinvestigationForm: UntypedFormGroup;
  tabEffectivenessIsHidden: boolean = false;
  showInvalidInvalidIncidentText: boolean = false;
  showInvalidDamageTypeText: boolean = false;
  showInvalidDamageToText: boolean = false;
  showInvalidInjuryCauseText: boolean = false;
  showInvalidRegistrationDateText: boolean = false;
  showInvalidActionDeadlineText: boolean = false;
  saveAndSubmit: boolean = false;
  formIsEdited: boolean = false;
  formIsSaved: boolean = false;
  count: number;
  activeTab: string = 'registration';
  hasCompanyFilterPermission: boolean = false;
  hasLevelPermission: boolean = false;


  // Lookup data
  companies$ = this.companyService.ActiveMembers$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public readonly PermissionCodes = PermissionCodes;

  constructor(public activeModal: NgbActiveModal,
              public authService: AdAuthService,
              public hseService: HseService,
              private alertService: AlertService,
              private companyService: CompanyService,
              private modalService: NgbModal) {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit() {
    if (this.isNew) {
      this.Incident = {
        RegistrationTypeId: null,
        IncidentLevelId: null
      } as IIncident;
    }
    this.loading = true;

    this.hasCompanyFilterPermission = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_CompanyFilter);
    this.hasLevelPermission = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_Level);

    // If the new improvement is filled by a HSEUSER then use in default values for Level and Company
    if (!this.hasLevelPermission) {
      this.Incident.IncidentLevelId = 2; // Member
    }

    if (!this.hasCompanyFilterPermission) {
      this.Incident.CompanyId = this.authService.CurrentUser.User.CompanyId;
      this.Incident.CreatedByUserId = this.authService.CurrentUser.UserId;
    }

    this.registrationForm = new UntypedFormGroup({
        IncidentLevel: new UntypedFormControl({value: this.Incident.IncidentLevelId, disabled: !this.hseService}, [Validators.required]),
        Company: new UntypedFormControl(this.Incident.CompanyId, [Validators.required, Validators.min(0)]),
        RegistrationDiscipline: new UntypedFormControl(this.Incident.DisciplineId, [Validators.required, Validators.min(0)]),
        RegistrationType: new UntypedFormControl({value: this.Incident.RegistrationTypeId, disabled: this.Incident.DisciplineId == null}, [Validators.required]),
        RegistrationSource: new UntypedFormControl(this.Incident.RegistrationSourceId, [Validators.required, Validators.min(0)]),
        RegistrationReportedBy: new UntypedFormControl(this.Incident.RegistrationReportedBy, [Validators.required]),
        IncidentDescription: new UntypedFormControl(this.Incident.IncidentDescription, {validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur'}),
        IncidentProbableCause: new UntypedFormControl(this.Incident.IncidentProbableCause),
        IncidentSuggestedAction: new UntypedFormControl(this.Incident.IncidentSuggestedAction),
        RegistrationDate: new UntypedFormControl(this.Incident.RegistrationDate, [Validators.required])
      }, {
        updateOn: 'change'
      }
    );

    this.registrationForm.controls.RegistrationDiscipline.valueChanges.subscribe((value: number) => {
      if (value && value >= 0) {
        this.registrationForm.controls.RegistrationType.enable();
      } else {
        this.registrationForm.controls.RegistrationType.disable();
      }
    });

    this.incidentReportForm = new UntypedFormGroup({
      AccidentHospitalisation: new UntypedFormControl(''),
      AccidentSickLeave: new UntypedFormControl(''),
      AccidentFirstAid: new UntypedFormControl(''),
      MaterialDamageLossOfProduction: new UntypedFormControl(''),
      NearAccident: new UntypedFormControl(''),
      PsychoSocialIncidents: new UntypedFormControl('')
    }, {
      updateOn: 'change'
    });

    this.injuryDamageTypeForm = new UntypedFormGroup({
      DamageTypeCut: new UntypedFormControl(''),
      DamageTypeGraze: new UntypedFormControl(''),
      DamageTypeBurn: new UntypedFormControl(''),
      DamageTypeSprain: new UntypedFormControl(''),
      DamageTypeContusion: new UntypedFormControl(''),
      DamageTypeFracture: new UntypedFormControl(''),
      DamageTypeIntoxication: new UntypedFormControl(''),
      DamageTypeSuffocation: new UntypedFormControl(''),
      DamageTypeDrowning: new UntypedFormControl(''),
      DamageTypeOther: new UntypedFormControl(''),
      DamageTypeStressConflict: new UntypedFormControl('')
    }, {
      updateOn: 'change'
    });

    this.injuryDamageToForm = new UntypedFormGroup({
      DamageToHead: new UntypedFormControl(''),
      DamageToTorso: new UntypedFormControl(''),
      DamageToArmHand: new UntypedFormControl(''),
      DamageToLegFoot: new UntypedFormControl(''),
      DamageToEyes: new UntypedFormControl(''),
      DamageToInternal: new UntypedFormControl(''),
      DamageToOther: new UntypedFormControl('')
    }, {
      updateOn: 'change'
    });

    this.injuryCauseForm = new UntypedFormGroup({
      CauseCutSting: new UntypedFormControl(''),
      CauseBump: new UntypedFormControl(''),
      CausePinch: new UntypedFormControl(''),
      CauseStumble: new UntypedFormControl(''),
      CauseFall: new UntypedFormControl(''),
      CauseBeingHit: new UntypedFormControl(''),
      CauseCauseBurn: new UntypedFormControl(''),
      CauseInhalation: new UntypedFormControl(''),
      CauseElectrocution: new UntypedFormControl(''),
      CauseOther: new UntypedFormControl('')
    }, {
      updateOn: 'change'
    });

    this.peopleInvolvedForm = new UntypedFormGroup({
      InvolvedVictimName: new UntypedFormControl(this.Incident.VictimName, [Validators.required, Validators.minLength(3)]),
      InvolvedVictimAge: new UntypedFormControl(this.Incident.VictimAge, [Validators.required]),
      InvolvedJobTitle: new UntypedFormControl(this.Incident.JobTitle, [Validators.required]),
      InvolvedJobExperience: new UntypedFormControl(this.Incident.JobExperienceYears, [Validators.required]),
      InvolvedJobTraining: new UntypedFormControl(this.Incident.JobTrainingDiplomas, [Validators.required, Validators.minLength(3)]),
      InvolvedSafetyTraining: new UntypedFormControl(this.Incident.SafetyTrainingDiplomas, [Validators.required, Validators.minLength(3)]),
      InvolvedFirstAid: new UntypedFormControl(this.Incident.FirstAidBy),
      InvolvedWitness: new UntypedFormControl(this.Incident.Witnesses),
      NrSickDays: new UntypedFormControl(this.Incident.NrSickDays, [Validators.required])
    }, {
      updateOn: 'change'
    });

    this.handlingForm = new UntypedFormGroup({
      Severity: new UntypedFormControl(this.Incident.SeverityId, [Validators.required, Validators.min(0)]),
      ActionBy: new UntypedFormControl(this.Incident.ActionBy),
      ActionDeadLine: new UntypedFormControl(this.Incident.ActionDeadline, [Validators.required]),
      ActionTaken: new UntypedFormControl(this.Incident.ActionTaken),
      HandlingRemarks: new UntypedFormControl(this.Incident.HandlingRemarks)
    }, {
      updateOn: 'change'
    });

    this.causeinvestigationForm = new UntypedFormGroup({
      CauseinvestigationHow: new UntypedFormControl(this.Incident.CauseinvestigationHow, [Validators.required]),
      CauseinvestigationWhy: new UntypedFormControl(this.Incident.CauseinvestigationWhy, [Validators.required]),
      CauseinvestigationWhat: new UntypedFormControl(this.Incident.CauseinvestigationWhat, [Validators.required]),
      CauseinvestigationConclusion: new UntypedFormControl(this.Incident.CauseinvestigationConclusion, [Validators.required]),
      CauseinvestigationMachines: new UntypedFormControl(this.Incident.CauseinvestigationMachinesOrEquipment, [Validators.required]),
      CauseinvestigationMaterials: new UntypedFormControl(this.Incident.CauseinvestigationMaterials, [Validators.required]),
      CauseinvestigationMeasurements: new UntypedFormControl(this.Incident.CauseinvestigationMeasurements, [Validators.required]),
      CauseinvestigationMethods: new UntypedFormControl(this.Incident.CauseinvestigationMethods, [Validators.required]),
      CauseinvestigationEnvironment: new UntypedFormControl(this.Incident.CauseinvestigationEnvironment, [Validators.required]),
      CauseinvestigationPersonnel: new UntypedFormControl(this.Incident.CauseinvestigationPersonnel, [Validators.required]),
    }, {
      updateOn: 'change'
    });

    this.analysisForm = new UntypedFormGroup({
      AnalysisStatus: new UntypedFormControl(this.Incident.AnalysisStatusId, [Validators.required]),
      AnalysisActionFinished: new UntypedFormControl(this.Incident.AnalysisActionFinished, [Validators.required]),
      AnalysisApprovedBy: new UntypedFormControl(this.Incident.AnalysisApprovedBy, [Validators.required]),
      Cost: new UntypedFormControl(this.Incident.Cost),
      Currency3ISO: new UntypedFormControl(this.Incident.Currency3ISO),
      AnalysisCauseOccurrence: new UntypedFormControl(this.Incident.AnalysisCauseCategory1Id, [Validators.required, Validators.min(0)]),
      AnalysisCauseFactor: new UntypedFormControl(this.Incident.AnalysisCauseCategory2Id, [Validators.required, Validators.min(0)]),
      AnalysisCauseSpecific: new UntypedFormControl(this.Incident.AnalysisCauseCategory3Id, [Validators.required, Validators.min(0)]),
      AnalysisAction: new UntypedFormControl(this.Incident.AnalysisActionId, [Validators.required, Validators.min(0)])
    }, {
      updateOn: 'change'
    });

    this.filterItemsOfCauseSpecific(this.Incident.AnalysisCauseCategory2Id);

    this.effectivenessForm = new UntypedFormGroup({
      MeasureEffective: new UntypedFormControl(this.Incident.MeasureEffective, [Validators.required]),
      MeasureEffectiveElaborate: new UntypedFormControl(this.Incident.MeasureEffectiveElaborate, [Validators.required, Validators.minLength(3)]),
      GroupMeasure: new UntypedFormControl({value: this.Incident.GroupMeasure, disabled: this.Incident.IncidentLevelId != 3}, [Validators.required, Validators.minLength(3)]),
      GroupMeasureElaborate: new UntypedFormControl({
        value: this.Incident.GroupMeasureElaborate,
        disabled: this.Incident.IncidentLevelId != 3
      }, [Validators.required, Validators.minLength(3)]),
      EffectivenessApprovedBy: new UntypedFormControl(this.Incident.EffectiveApprovedBy, [Validators.required, Validators.minLength(3)]),
      EffectivenessDate: new UntypedFormControl(this.Incident.EffectiveDate, [Validators.required]),
      EffectivenessStatus: new UntypedFormControl(this.Incident.EffectiveStatus, [Validators.required])
    }, {
      updateOn: 'change'
    });

    // If it is an existing incident load the incident report form
    if (this.Incident) {
      this.getIncidentTypeValues();
      this.getIncidentDamageTypeValues();
      this.getInjuryDamageToValues();
      this.getInjuryCauseValues();
    }

    this.tabEffectivenessIsHidden = this.isGreaterThan90Days(this.Incident.AnalysisActionFinished);
    this.loading = false;
  }

  // Get the checked checkboxes form the form
  setIncidentTypeValues(): string {
    let ids: string[] = [];
    for (const key in this.incidentReportForm.controls) {
      if (this.incidentReportForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }
    return ids.map(function(id) {
      return id;
    }).join(', ');
  }

  // Load the checkbox values from the database
  getIncidentTypeValues() {
    if (this.Incident.IncidentType === null || this.Incident.IncidentType === undefined) {
      return;
    }

    const incidentTypeValue = this.Incident.IncidentType.split(',');

    // For each string in IncidentType
    incidentTypeValue.forEach(incidentType => {
      Object.keys(this.incidentReportForm.controls).forEach(key => {
        const control = this.incidentReportForm.controls[key];
        // Find the corresponding checkbox
        if (key === incidentType.trim()) {
          // and set to checked
          control.setValue('true');
        }
      });
    });
  }

  setIncidentDamageTypeValues(): string {
    let ids: string[] = [];
    for (const key in this.injuryDamageTypeForm.controls) {
      if (this.injuryDamageTypeForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }
    return ids.map(function(id) {
      return id;
    }).join(', ');
  }

  // Load the checkbox values from the database
  getIncidentDamageTypeValues() {
    if (this.Incident.InjuryDamageType === undefined || this.Incident.InjuryDamageType === null) {
      return;
    }

    const incidentDamageTypeValue = this.Incident.InjuryDamageType.split(',');

    // For each string in IncidentType
    incidentDamageTypeValue.forEach(type => {
      Object.keys(this.injuryDamageTypeForm.controls).forEach(key => {
        const control = this.injuryDamageTypeForm.controls[key];
        // Find the corresponding checkbox
        if (key === type.trim()) {
          // and set to checked
          control.setValue('true');
        }
      });
    });
  }

  setInjuryDamageToValues(): string {
    let ids: string[] = [];
    for (const key in this.injuryDamageToForm.controls) {
      if (this.injuryDamageToForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }
    return ids.map(function(id) {
      return id;
    }).join(', ');
  }

  // Load the checkbox values from the database
  getInjuryDamageToValues() {
    if (this.Incident.InjuryDamageTo === undefined || this.Incident.InjuryDamageTo === null) {
      return;
    }
    const incidentDamageToValue = this.Incident.InjuryDamageTo.split(',');
    // For each string in IncidentType
    incidentDamageToValue.forEach(type => {
      Object.keys(this.injuryDamageToForm.controls).forEach(key => {
        const control = this.injuryDamageToForm.controls[key];
        // Find the corresponding checkbox
        if (key === type.trim()) {
          // and set to checked
          control.setValue('true');
        }
      });
    });
  }

  setInjuryCauseValues(): string {
    let ids: string[] = [];
    for (const key in this.injuryCauseForm.controls) {
      if (this.injuryCauseForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }
    return ids.map(function(id) {
      return id;
    }).join(', ');
  }

  // Load the checkbox values from the database
  getInjuryCauseValues() {
    if (this.Incident.InjuryCause === undefined || this.Incident.InjuryCause === null) {
      return;
    }

    const injuryCauseValue = this.Incident.InjuryCause.split(',');

    // For each string in IncidentType
    injuryCauseValue.forEach(type => {
      Object.keys(this.injuryCauseForm.controls).forEach(key => {
        const control = this.injuryCauseForm.controls[key];
        // Find the corresponding checkbox
        if (key === type.trim()) {
          // and set to checked
          control.setValue('true');
        }
      });
    });
  }

  //Set a boolean value. This is used to check if the incident report form and injury forms have to be displayed.
  registrationDisciplineChanged(event: any) {
    this.Incident.DisciplineId = event.target.value;
    this.Incident.RegistrationTypeId = null;
    this.registrationForm.controls.RegistrationType.setValue(null);
  }

  //Set a boolean value. This is used to check if the incident report form and injury forms have to be displayed.
  registrationTypeChanged(event: any) {
    this.Incident.RegistrationTypeId = event.target.value;
    this.registrationForm.controls.RegistrationType.setValue(event.target.value);
  }

  //Set a boolean value. This is used to check if the incident report form and injury forms have to be displayed.
  registrationSourceChanged(event: any) {
    this.Incident.RegistrationSourceId = event.target.value;
  }

  isGreaterThan90Days(date: string) {
    if (date == null) {
      return;
    }
    const dateToCheck = new Date(date);
    const currentDate = new Date();

    const Time = currentDate.getTime() - dateToCheck.getTime();
    const Days = Time / (1000 * 3600 * 24);

    return Days > 90;
  }

  filterItemsOfCauseSpecific(AnalysisCauseCategory2Id: number) {
    if (this.hseService.AnalysisCauseSpecific != null &&
      this.hseService.AnalysisCauseSpecific.length > 0 &&
      AnalysisCauseCategory2Id != null) {
      this.analysisCauseSpecificationOptions = this.hseService.AnalysisCauseSpecific.filter(x => x.AnalysisCauseFactorId == AnalysisCauseCategory2Id);
    } else {
      this.hseService.AnalysisCauseSpecific$.subscribe((data: IAnalysisCauseSpecific[]) => {
        this.analysisCauseSpecificationOptions = data.filter(x => x.AnalysisCauseFactorId == AnalysisCauseCategory2Id);
      });
    }
  }

  filterItemsOfDiscipline(DisciplineId: number) {
    if (this.hseService.RegistrationType != undefined && DisciplineId != null) {
      return this.hseService.RegistrationType.filter(x => x.DisciplineId == DisciplineId);
    }
  }

  filterItemsOfSeverity(RegistrationTypeId: number) {
    if (this.hseService.HandlingSeverity != undefined && RegistrationTypeId != null) {
      return this.hseService.HandlingSeverity.filter(x => x.RegistrationTypeId == RegistrationTypeId);
    }
  }

  // Date functions
  setCreatedOnDate(date: string) {
    this.Incident.CreatedOnDate = date;
    this.registrationForm.controls.CreatedOnDate.setValue(date);
    this.registrationForm.controls.CreatedOnDate.markAsTouched();
  }

  setRegistrationDate(date: string) {
    this.Incident.RegistrationDate = date;
    this.registrationForm.controls.RegistrationDate.setValue(date);
    this.registrationForm.controls.RegistrationDate.markAsTouched();
  }

  setActionDeadLineDate(date: string) {
    this.Incident.ActionDeadline = date;
    this.handlingForm.controls.ActionDeadLine.setValue(date);
    this.handlingForm.controls.ActionDeadLine.markAsTouched();
  }

  setActionFinishedDate(date: string) {
    this.Incident.AnalysisActionFinished = date;
    this.analysisForm.controls.AnalysisActionFinished.setValue(date);
    this.analysisForm.controls.AnalysisActionFinished.markAsTouched();
  }

  setEffectivenessDate(date: string) {
    this.Incident.EffectiveDate = date;
    this.effectivenessForm.controls.EffectivenessDate.setValue(date);
    this.effectivenessForm.controls.EffectivenessDate.markAsTouched();
  }

  //Validate/Check if at least 1 checkbox is checked.
  validateIncidentTypeValues(): boolean {
    let ids: string[] = [];
    for (const key in this.incidentReportForm.controls) {
      if (this.incidentReportForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }

    return ids.length > 0;
  }

  //Validate/Check if at least 1 checkbox is checked.
  validateInjuryDamageTypeValues(): boolean {
    let ids: string[] = [];
    for (const key in this.injuryDamageTypeForm.controls) {
      if (this.injuryDamageTypeForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }

    return ids.length > 0;
  }

  //Validate/Check if at least 1 checkbox is checked.
  validateInjuryDamageToValues(): boolean {
    let ids: string[] = [];
    for (const key in this.injuryDamageToForm.controls) {
      if (this.injuryDamageToForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }

    return ids.length > 0;
  }

  //Validate/Check if at least 1 checkbox is checked.
  validateInjuryCauseValues(): boolean {
    let ids: string[] = [];
    for (const key in this.injuryCauseForm.controls) {
      if (this.injuryCauseForm.controls[key].value) {
        ids.push(key);
      } else {
        ids = ids.filter(id => id !== key);
      }
    }

    return ids.length > 0;
  }

  validateRegistration(): boolean {
    let valid = true;
    this.registrationFormElement.ngSubmit.emit();

    if (this.registrationForm.invalid) {
      valid = false;
    }

    if (!this.Incident.RegistrationDate){
      valid = false;
      this.showInvalidRegistrationDateText = true;
    } else {
      this.showInvalidRegistrationDateText = false;
    }

    // Only validate when it if the form is displayed. That is when registration source and type are incident report = value 5.
    if (this.registrationForm.controls.RegistrationSource.value == 5 && this.registrationForm.controls.RegistrationType.value == 5) {
      this.peopleInvolvedFormElement.ngSubmit.emit();

      if (!this.validateIncidentTypeValues()) {
        valid = false;
        this.showInvalidInvalidIncidentText = true;
      } else {
        this.showInvalidInvalidIncidentText = false;
      }

      if (!this.validateInjuryDamageTypeValues()) {
        valid = false;
        this.showInvalidDamageTypeText = true;
      } else {
        this.showInvalidDamageTypeText = false;
      }

      if (!this.validateInjuryDamageToValues()) {
        valid = false;
        this.showInvalidDamageToText = true;
      } else {
        this.showInvalidDamageToText = false;
      }

      if (!this.validateInjuryCauseValues()) {
        valid = false;
        this.showInvalidInjuryCauseText = true;
      } else {
        this.showInvalidInjuryCauseText = false;
      }

      if (this.peopleInvolvedForm.invalid) {
        valid = false;
      }
    }

    return valid;
  }

  validateRegistrationNext() {
    let valid = true;
    this.registrationFormElement.ngSubmit.emit();

    if (this.registrationForm.invalid) {
      valid = false;
      this.showInvalidRegistrationDateText = true;
    }

    if (!this.Incident.RegistrationDate){
      valid = false;
      this.showInvalidRegistrationDateText = true;
    } else {
      this.showInvalidRegistrationDateText = false;
    }

    // Only validate when it if the form is displayed. That is when registration source and type are incident report = value 5.
    if (this.registrationForm.controls.RegistrationSource.value == 5 && this.registrationForm.controls.RegistrationType.value == 5) {
      this.peopleInvolvedFormElement.ngSubmit.emit();

      if (!this.validateIncidentTypeValues()) {
        valid = false;
        this.showInvalidInvalidIncidentText = true;
      } else {
        this.showInvalidInvalidIncidentText = false;
      }

      if (!this.validateInjuryDamageTypeValues()) {
        valid = false;
        this.showInvalidDamageTypeText = true;
      } else {
        this.showInvalidDamageTypeText = false;
      }

      if (!this.validateInjuryDamageToValues()) {
        valid = false;
        this.showInvalidDamageToText = true;
      } else {
        this.showInvalidDamageToText = false;
      }

      if (!this.validateInjuryCauseValues()) {
        valid = false;
        this.showInvalidInjuryCauseText = true;
      } else {
        this.showInvalidInjuryCauseText = false;
      }

      if (this.peopleInvolvedForm.invalid) {
        valid = false;
      }
    }

    if (valid) {
      this.handlingTab.nativeElement.click();
    } else {
      this.alertService.error('Please fill all required registration fields.');
    }
  }

  validateActiveTab() {
    this.loading = true;

    switch (this.activeTab) {
      case 'handling':
        return (this.validateHandling() && this.validateRegistration());
      case 'causeinvestigation':
        return this.validateCauseInvestigation() && this.validateHandling() && this.validateRegistration();
      case 'analysis':
        return this.validateAnalysis() && this.validateCauseInvestigation() && this.validateHandling() && this.validateRegistration();
      case 'effectiveness':
        return this.validateEffectiveness() && this.validateAnalysis() && this.validateCauseInvestigation() && this.validateHandling() && this.validateRegistration();
    }
  }

  validateHandling() {
    let valid = true;

    this.handlingFormElement.ngSubmit.emit();

    if (this.handlingForm.invalid) {
      valid = false;
    }

    if (!this.Incident.ActionDeadline){
      valid = false;
      this.showInvalidActionDeadlineText = true;
    } else {
      this.showInvalidActionDeadlineText = false;
    }

    return valid;
  }

  validateCauseInvestigation() {
    if (this.causeInvestigationTabVisible()) {
      this.causeinvestigationFormElement.ngSubmit.emit();
      return !this.causeinvestigationForm.invalid;
    }
    return true;
  }

  validateAnalysis() {
    if (this.analysisTabVisible()) {
      this.analysisFormElement.ngSubmit.emit();
      return !this.analysisForm.invalid;
    }
    return true;
  }

  validateEffectiveness() {
    if (this.effectivenessTabVisible()) {
      this.effectivenessFormElement.ngSubmit.emit();
      return !this.effectivenessForm.invalid;
    }
    return true;
  }

  causeInvestigationTabVisible() {
    return (
      (this.Incident.DisciplineId === 2 || this.Incident.DisciplineId === 3) &&
      (this.Incident.RegistrationTypeId === 5 || this.Incident.RegistrationTypeId === 9) &&
      (this.Incident.RegistrationSourceId === 5) &&
      this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_CauseAndInvestigation)
    );
  }

  analysisTabVisible() {
    return this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_Analysis);
  }

  effectivenessTabVisible() {
    return this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_Effectiveness);
  }

  // Update or create an incident
  saveIncident() {
    const valid = this.validateActiveTab();
    if (!valid) {
      this.alertService.error('Please fill all required fields for all tabs.');
    } else {
      // Save the values to this.Incident
      this.Incident.IncidentLevelId = this.registrationForm.controls.IncidentLevel.value;
      this.Incident.CompanyId = this.registrationForm.controls.Company.value;
      this.Incident.DisciplineId = this.registrationForm.controls.RegistrationDiscipline.value;
      this.Incident.RegistrationTypeId = this.registrationForm.controls.RegistrationType.value;
      this.Incident.RegistrationSourceId = this.registrationForm.controls.RegistrationSource.value;
      this.Incident.RegistrationReportedBy = this.registrationForm.controls.RegistrationReportedBy.value;
      this.Incident.IncidentDescription = this.registrationForm.controls.IncidentDescription.value;
      this.Incident.IncidentProbableCause = this.registrationForm.controls.IncidentProbableCause.value;
      this.Incident.IncidentSuggestedAction = this.registrationForm.controls.IncidentSuggestedAction.value;
      this.Incident.SeverityId = this.handlingForm.controls.Severity.value;
      this.Incident.ActionBy = this.handlingForm.controls.ActionBy.value;
      this.Incident.ActionTaken = this.handlingForm.controls.ActionTaken.value;
      this.Incident.HandlingRemarks = this.handlingForm.controls.HandlingRemarks.value;
      this.Incident.AnalysisStatusId = this.analysisForm.controls.AnalysisStatus.value;
      this.Incident.VictimName = this.peopleInvolvedForm.controls.InvolvedVictimName.value;
      this.Incident.VictimAge = this.peopleInvolvedForm.controls.InvolvedVictimAge.value;
      this.Incident.JobTitle = this.peopleInvolvedForm.controls.InvolvedJobTitle.value;
      this.Incident.JobExperienceYears = this.peopleInvolvedForm.controls.InvolvedJobExperience.value;
      this.Incident.JobTrainingDiplomas = this.peopleInvolvedForm.controls.InvolvedJobTraining.value;
      this.Incident.SafetyTrainingDiplomas = this.peopleInvolvedForm.controls.InvolvedSafetyTraining.value;
      this.Incident.FirstAidBy = this.peopleInvolvedForm.controls.InvolvedFirstAid.value;
      this.Incident.Witnesses = this.peopleInvolvedForm.controls.InvolvedWitness.value;
      this.Incident.NrSickDays = this.peopleInvolvedForm.controls.NrSickDays.value;
      this.Incident.AnalysisApprovedBy = this.analysisForm.controls.AnalysisApprovedBy.value;
      this.Incident.Cost = this.analysisForm.controls.Cost.value;
      this.Incident.Currency3ISO = this.analysisForm.controls.Currency3ISO.value;
      this.Incident.AnalysisCauseCategory1Id = this.analysisForm.controls.AnalysisCauseOccurrence.value;
      this.Incident.AnalysisCauseCategory2Id = this.analysisForm.controls.AnalysisCauseFactor.value;
      this.Incident.AnalysisCauseCategory3Id = this.analysisForm.controls.AnalysisCauseSpecific.value;
      this.Incident.AnalysisActionId = this.analysisForm.controls.AnalysisAction.value;
      this.Incident.MeasureEffective = this.effectivenessForm.controls.MeasureEffective.value;
      this.Incident.MeasureEffectiveElaborate = this.effectivenessForm.controls.MeasureEffectiveElaborate.value;
      this.Incident.GroupMeasure = this.effectivenessForm.controls.GroupMeasure.value;
      this.Incident.GroupMeasureElaborate = this.effectivenessForm.controls.GroupMeasureElaborate.value;
      this.Incident.EffectiveApprovedBy = this.effectivenessForm.controls.EffectivenessApprovedBy.value;
      this.Incident.EffectiveStatus = this.effectivenessForm.controls.EffectivenessStatus.value;
      this.Incident.CauseinvestigationHow = this.causeinvestigationForm.controls.CauseinvestigationHow.value;
      this.Incident.CauseinvestigationWhy = this.causeinvestigationForm.controls.CauseinvestigationWhy.value;
      this.Incident.CauseinvestigationWhat = this.causeinvestigationForm.controls.CauseinvestigationWhat.value;
      this.Incident.CauseinvestigationConclusion = this.causeinvestigationForm.controls.CauseinvestigationConclusion.value;

      this.Incident.IncidentType = this.setIncidentTypeValues();
      this.Incident.InjuryDamageType = this.setIncidentDamageTypeValues();
      this.Incident.InjuryDamageTo = this.setInjuryDamageToValues();
      this.Incident.InjuryCause = this.setInjuryCauseValues();
      this.Incident.IncidentNumber = this.Incident.IncidentNumber != null ? this.Incident.IncidentNumber : 0;

      if (this.Incident.IncidentNumber != null && this.Incident.IncidentNumber != 0){
        this.Incident.LastModifiedByUserId = this.authService.CurrentUser.UserId;
        this.Incident.LastModifiedOnDate = new Date();
      } else {
        this.Incident.CreatedByUserId = this.authService.CurrentUser.UserId;
        // this.Incident.CreatedOnDate = new Date();
      }

      // If the new improvement is filled by a HSEUSER then use in default values for Level and Company
      if (!this.hasLevelPermission) {
        this.Incident.IncidentLevelId = 2; // Member
      }

      if (!this.hasCompanyFilterPermission) {
        this.Incident.CompanyId = this.authService.CurrentUser.User.CompanyId;
        this.Incident.CreatedByUserId = this.authService.CurrentUser.UserId;
      }

      this.hseService.updateIncident(this.Incident).subscribe({
        next: (data: IIncident) => {
          if (data) {
            this.Incident = Object.assign({}, data);
          }
          this.alertService.info('Incident ' + this.Incident.IncidentNumber + ' successfully saved.');
          this.loading = false;
          this.formIsEdited = false;
          this.formIsSaved = true;
        },
        error: () => {
          this.alertService.error('Unable to update data for Incident IncidentNumber: ' + this.Incident.IncidentNumber);
          this.loading = false;
        }
      });
    }
  }

  cancel() {
    this.hasUnsavedChanges(this.registrationForm);
    this.hasUnsavedChanges(this.handlingForm);
    this.hasUnsavedChanges(this.analysisForm);

    if (this.formIsEdited && !this.formIsSaved) {
      const message = 'You have unsaved changes. Are you sure you want to close?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(answer => {
          if (answer) {
            this.activeModal.close(true);
          }
        });
    } else {
      this.activeModal.close(true);
    }
  }

  hasUnsavedChanges(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control.dirty) {
        this.formIsEdited = true;
      } else if (control instanceof UntypedFormGroup) {
        this.hasUnsavedChanges(control);
      }
    });
  }

  changeActiveTab(activeTab) {
    this.activeTab = activeTab;
  }
}
