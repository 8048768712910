import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Subject } from 'rxjs';
import { IFailedRateCause, IFailedRatesByProject } from '../../../services/models/pricing/failed-rate.model';
import { IObjectMapping, IPartnerAgent, IPartnerCity, IPartnerPort } from '../../../services/models/pricing/partner.model';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RetryFailedRatesModalComponent } from './retry-failed-rates-modal/retry-failed-rates-modal.component';
import { AlertService } from '../../../services/alert.service';
import { PricingService } from '../../../services/pricing.service';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { IProject } from '../../../services/models/pricing/rates.model';

@Component({
  selector: 'app-failed-rates',
  templateUrl: './failed-rates.component.html',
  styleUrls: ['./failed-rates.component.scss']
})
export class FailedRatesComponent implements OnInit, OnDestroy {
  // Icons
  faRefresh = faSyncAlt;

  // Component variables
  failedRates: IFailedRatesByProject[] = [];
  activeProject: IProject = null;
  activeCause: IFailedRateCause;
  loading: boolean = false;

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();

  causes: IFailedRateCause[] = [
    { Name: 'Modality', Code: 'MOD' },
    { Name: 'Departure City', Code: 'DPC' },
    { Name: 'Arrival City', Code: 'ARC' },
    { Name: 'Departure Port', Code: 'DPP' },
    { Name: 'Arrival Port', Code: 'ARP' },
    { Name: 'Agent', Code: 'AGT' }
  ];

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public pricingService: PricingService,
              private api: ApiService,
              private modalService: NgbModal,
              private alertService: AlertService) { }

  ngOnInit() {
    // if (this.pricingService.Projects && this.pricingService.Projects.length > 0) {
    //   this.activeProject = this.pricingService.Projects[0];
    // }

    this.projects$.subscribe(data => {
      this.activeProject = data[0];
    })

    this.activeCause = this.causes[0];
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getFailedRates(projectID: number) {
    this.loading = true;
    this.api.get('FailedRates/ByProject/' + projectID).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe((data) => {
        if (data) {
          this.failedRates = Object.assign([] as IFailedRatesByProject[], data);
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  setActiveProject(project: IProject) {
    this.activeProject = project;
    this.getFailedRates(project.Id);
  }

  setActiveCause(cause: any) {
    this.activeCause = cause;
  }

  hasChanges() {
    const changeCount = this.failedRates && this.failedRates.filter((rate) => rate.MapToValue !== null || rate.NoMapping === true).length;
    return changeCount > 0;
  }

  getUpdatedCount() {
    return this.failedRates.filter((item) => item.MapToValue !== null).length;
  }

  refresh() {
    this.getFailedRates(this.activeProject.Id);
  }

  apply() {
    let updatedCount: number = 0;
    let failedCount: number = 0;

    if (this.failedRates && this.failedRates.length > 0) {
      this.failedRates.filter((item) => item.MapToValue !== null || item.NoMapping === true).forEach((rate) => {
        if (rate.MapToValue || rate.NoMapping) {
          let object: any;
          // If ProblemTableID is NOT null, then the existing record in the ProblemTable must be updated.
          // If ProblemTableID is null, then a new record must be inserted into the ProblemTable
          switch (rate.ProblemTable) {
            case 'ObjectMapping':
              object = {
                ID: null,
                DateCreated: null,
                DateModified: null,
                Active: true,
                ObjectName: rate.MapToColumn.replace('.', ''),
                ObjectValue: rate.MapToValue ? rate.MapToValue.toString() : null,
                PartnerObjectName: null,
                PartnerObjectValue: rate.FailedValue,
                PartnerID: this.activeProject.PartnerId,
                TableName: rate.MapToTable,
                NoMapping: rate.NoMapping
              } as IObjectMapping;
              break;
            case 'PartnerCity':
              object = {
                ID: rate.ProblemTableID,
                DateCreated: null,
                DateModified: null,
                Active: true,
                Name: rate.FailedValue,
                PartnerCountryID: null, // TODO: Implement functionality to get PartnerCountryID
                PartnerStateID: null, // TODO: Implement functionality to get PartnerStateID
                CityID: parseInt(String(rate.MapToValue), 10),
                PartnerID: this.activeProject.PartnerId,
                NoMapping: rate.NoMapping,
                Comment: null,
                PartnerReference: null,
                PortID: null,
                ShortName: null
              } as IPartnerCity;
              break;
            case 'PartnerPort':
              object = {
                ID: rate.ProblemTableID,
                DateCreated: null,
                DateModified: null,
                Active: true,
                Name: rate.FailedValue,
                PartnerCountryID: null, // TODO: Implement functionality to get PartnerCountryID
                PartnerStateID: null, // TODO: Implement functionality to get PartnerStateID
                CityFunctionID: parseInt(String(rate.MapToValue), 10),
                PartnerID: this.activeProject.PartnerId,
                NoMapping: rate.NoMapping,
                PartnerReference: null,
                ShortName: null,
                FunctionTypeID: null // TODO: Implement functionality to get FunctionTypeID from selected CityFunction
              } as IPartnerPort;
              break;
            case 'PartnerAgent':
              object = {
                ID: rate.ProblemTableID,
                DateCreated: null,
                DateModified: null,
                Active: true,
                Name: rate.FailedValue,
                RedSkyAgentID: parseInt(String(rate.MapToValue), 10),
                PartnerID: this.activeProject.PartnerId,
                PartnerReference: null,
                NoMapping: rate.NoMapping
              } as IPartnerAgent;
              break;
            default:
              break;
          }

          this.api.post(rate.ProblemTable + '/Update', object).pipe(
            takeUntil(this.unsubscribe),
          ).subscribe((data) => {
              if (data) {
                rate.UpdateSuccess = true;
                updatedCount += 1;
                this.alertService.success('Update Successful: ' + rate.FailedValue, updatedCount.toString());
              }
            },
            () => {
              rate.UpdateSuccess = false;
              failedCount += 1;
              this.alertService.warn('Update Failed: ' + rate.FailedValue, failedCount.toString());
            });
        }
      });
    }
  }

  processRates() {
    const modalRef = this.modalService.open(RetryFailedRatesModalComponent);
  }
}
