import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-log',
  templateUrl: './hub-log.component.html',
  styleUrls: ['./hub-log.component.scss']
})
export class HubLogComponent {

}
