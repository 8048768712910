import { Component, OnDestroy, OnInit } from '@angular/core';
import { faBoxOpen, faEdit, faSortDown, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { HubAdminService } from '../../../services/hub-admin.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { IHubValueMappingOverview, IHubValueMappingSearchParam } from '../../../services/models/hub/hub-mapping.model';
import { IAuthUser } from '../../../services/models/auth.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { takeUntil } from 'rxjs/operators';
import { IHubElement } from '../../../services/models/hub/schema.model';
import { IHubClientSystemOverview } from '../../../services/models/hub/hub.model';
import { CreateValueMappingModalComponent } from './create-value-mapping-modal/create-value-mapping-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-manage-value-mappings',
  templateUrl: './value-mappings.component.html',
  styleUrls: ['./value-mappings.component.scss']
})
export class ValueMappingsComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEmpty = faBoxOpen;
  protected readonly faEditIcon = faEdit;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faDelete = faTrash;
  protected readonly faSortAsc = faSortDown;

  // Component Variables
  searchParam: IHubValueMappingSearchParam;
  paginationData: IPaginationData<IHubValueMappingOverview>;
  orderParam: IOrderParam;
  page: IPage;
  hubSchemaTypes$ = this.hubAdmin.SchemaTypes$.asObservable();
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClient: IHubClientSystemOverview = null;
  sourceSchemaTypeId: number = null;
  sourceElements$: BehaviorSubject<IHubElement[]> = new BehaviorSubject<IHubElement[]>([]);
  targetSchemaTypeId: number = null;
  targetElements$: BehaviorSubject<IHubElement[]> = new BehaviorSubject<IHubElement[]>([]);

  // Permissions
  canViewAll: boolean = false;
  canEditValues: boolean = false;
  ucid: number = null;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;

  constructor(private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.initPage();

    if (this.auth.CurrentUser) {
      this.ucid = this.auth.CurrentUser.User.CompanyId;
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.ucid = user.User.CompanyId;
      this.setPermissions();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage() {
    this.searchParam = {
      ValueListId: null,
      SourceSystemId: null,
      SourceElementId: null,
      SourceValue: null,
      TargetSystemId: null,
      TargetElementId: null,
      TargetValue: null
    } as IHubValueMappingSearchParam;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IHubValueMappingOverview>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'SourceSystemName',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  setPermissions() {
    // Set view permission
    this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);
    this.canEditValues = this.auth.CheckPermissionByCode(PermissionCodes.Hub_EditValues);
    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAll) {
        this.ucid = null;
        this.userClients$.next(data);
        this.searchMappings();
      } else {
        const clients = data.filter(x => x.SourceCompanyId == this.ucid || x.TargetCompanyId == this.ucid);
        this.userClients$.next(clients);
        if (clients.length >= 1) {
          this.selectedClient = clients[0];
          this.setClientData();
        }
      }
    });
  }

  resetSearch() {
    this.initPage();

    this.selectedClient = null;
    this.sourceSchemaTypeId = null;
    this.targetSchemaTypeId = null;
    this.sourceElements$.next([]);
    this.targetElements$.next([]);

    this.searchMappings();
  }

  // Default permission functions
  setClientData() {
    if (this.selectedClient) {
      this.searchParam.SourceSystemId = this.selectedClient.SourceSystemId;
      this.searchParam.SourceClientId = this.selectedClient.SourceClientId;
      this.searchParam.TargetSystemId = this.selectedClient.TargetSystemId;
      this.searchParam.TargetClientId = this.selectedClient.TargetClientId;
      this.sourceSchemaTypeId = null;
      this.targetSchemaTypeId = null;
      this.searchMappings();
    } else {
      this.searchParam.SourceSystemId = null;
      this.searchParam.SourceClientId = null;
      this.searchParam.TargetSystemId = null;
      this.searchParam.TargetClientId = null;
      this.sourceSchemaTypeId = null;
      this.targetSchemaTypeId = null;
      this.sourceElements$.next([]);
      this.targetElements$.next([]);
      this.searchMappings();
    }
  }

  loadSourceElements() {
    this.loading = true;

    this.hubAdmin.GetElementsBySchemaType(this.sourceSchemaTypeId).subscribe({
      next: (data: IHubElement[]) => {
        this.sourceElements$.next(data);
        if (data.length == 1) {
          this.searchParam.SourceElementId = data[0].ElementId;
        }
        this.loading = false;
      },
      error: () => {
        this.sourceElements$.next([]);
        this.loading = false;
      }
    });
  }

  loadTargetElements() {
    this.loading = true;

    this.hubAdmin.GetElementsBySchemaType(this.targetSchemaTypeId).subscribe({
      next: (data: IHubElement[]) => {
        this.targetElements$.next(data);
        if (data.length == 1) {
          this.searchParam.TargetElementId = data[0].ElementId;
        }
        this.loading = false;
      },
      error: () => {
        this.targetElements$.next([]);
        this.loading = false;
      }
    });
  }

  searchMappings() {
    this.loading = true;
    this.searchParam.SourceValue = this.searchParam.SourceValue?.length > 0 ? this.searchParam.SourceValue : null;
    this.searchParam.TargetValue = this.searchParam.TargetValue?.length > 0 ? this.searchParam.TargetValue : null;

    this.hubAdmin.SearchValueMappings(this.searchParam, this.page, this.orderParam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: IPaginationData<IHubValueMappingOverview>) => {
          this.paginationData = Object.assign({}, data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  newValueMapping() {
    // Open modal to create a new value mapping
    const modalRef = this.modalService.open(CreateValueMappingModalComponent, {size: 'lg', backdrop: 'static'});
    // On modal close, read the result and trigger the search
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.searchMappings();
      }
    });
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchMappings();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchMappings();
  }

  editValue(valueListId: number) {
    let promise = this.hubAdmin.EditValue$(valueListId);

    promise.then((result: boolean) => {
      if (result == true) {
        this.searchMappings();
      }
    });
  }
}
