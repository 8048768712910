import { Injectable, OnDestroy } from '@angular/core';
import {
  ITenderCurrency,
  ITenderFreight,
  ITenderModality,
  ITenderQuote,
  ITenderService,
  ITenderStatus,
  ITenderStep,
  ITenderDto,
  ITenderSingleLaneParam,
  ITenderSingleLaneResult,
  ITenderSingleLaneSearchParam,
  ITenderLocationRateGroup,
  ISingleLaneTenderModality
} from './models/pricing/tender.model';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { AdAuthService } from '../core/ad-auth-service/ad-auth.service';
import { ISelectRateGroupParam, IServiceType } from './models/pricing/rates.model';
import { getDayDifference } from '../shared/functions/datetime-functions';
import { ICompany } from './models/member.model';

@Injectable({
  providedIn: 'root'
})
export class TenderService implements OnDestroy {
  // Public Variables
  SelectedCompany: ICompany;
  SelectedCurrency: ITenderCurrency;

  // Lookups
  Steps: ITenderStep[];
  Statuses: ITenderStatus[];
  ServiceTypes: IServiceType[];

  // Tender Variables
  TenderView: ITenderDto;
  MultiLaneQuote: ITenderQuote[];
  TenderFreightUpdateList: ITenderFreight[] = [];
  TenderServiceUpdateList: ITenderService[] = [];

  // Single Lane Variable
  SingleLaneTenderParam: ITenderSingleLaneParam = null;
  SingleLaneTenderResult: ITenderSingleLaneResult = null;

  // General Variables
  loading: boolean = false;
  public TenderLoading: boolean = false;
  public LoadingTenderResults: boolean = false;
  public ExportingRates: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertService,
              private authService: AdAuthService,
              public storage: StorageService,
              public api: ApiService) {
    if (!this.Steps || this.Steps.length <= 0) {
      this.loadSteps();
    }

    if (!this.Statuses || this.Statuses.length <= 0) {
      this.loadStatuses();
    }

    if (!this.ServiceTypes || this.ServiceTypes.length <= 0) {
      this.loadServiceTypes();
    }
  }

  ngOnDestroy() {
    this.RemoveTenderFromStorage();

    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  /////////////////////////
  /// Multi-Lane Tender ///
  /////////////////////////
  public NewTender() {
    this.ResetTenderService();
    const step = this.Steps.find(x => x.Name == 'Configure Tender');
    const status = this.Statuses.find(x => x.Name == 'Active');

    this.TenderView = {
      Id: null,
      DateCreated: new Date(),
      Active: true,
      Name: null,
      CompanyId: this.authService.CurrentUser.User.CompanyId,
      TenderStatusId: status.Id, // First status, default for a new Tender
      TenderStepId: step.Id, // First step, default for a new Tender
      CreatedByUserId: this.authService.CurrentUser.UserId,
      IsProcessed: false,
      DefaultMarginMaximum: null,
      DefaultMarginPercentage: null,
      QuoteAcceptedDate: null,
      DateModified: null,
      Description: null,
      DefaultCurrencyId: null,
      DefaultMarginMinimum: null,
      ModifiedByUserId: null,
      TenderTypeId: 1,
      QuoteAmount: null,
      QuoteAmountCurrencyId: null,
      DateFirstExport: null,
      BilledDate: null,
      ExpiryDate: null,

      TenderStatus: step,
      TenderStep: status,
      TenderType: null,
      TenderLocations: [],
      TenderModalities: [],
      TenderFreights: [],
      TenderServices: []
    } as ITenderDto;

    this.LoadCompany(this.TenderView.CompanyId);
    this.LoadTenderCurrency(this.TenderView.DefaultCurrencyId);

    this.StoreTender();
  }

  public ResetTenderService() {
    this.SelectedCurrency = null;
    // Multi-Lane
    this.TenderView = null;
    this.TenderFreightUpdateList = [];
    this.TenderServiceUpdateList = [];
    this.MultiLaneQuote = null;
    // Single-Lane
    this.SingleLaneTenderParam = null;
    this.SingleLaneTenderResult = null;

    this.RemoveTenderFromStorage();
  }

  public LoadTender(Id: number) {
    this.loading = true;
    this.TenderLoading = true;

    this.api.get('Tender/FullTender/' + Id).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.TenderView = Object.assign({}, data);
          if (this.TenderView.IsProcessed) {
            this.storage.removeSessionItem('tender-process-start');
          }
          this.LoadCompany(this.TenderView.CompanyId);
          this.LoadTenderCurrency(this.TenderView.DefaultCurrencyId);
          this.StoreTender();
        }
        this.TenderLoading = false;
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: LoadTender');
        this.TenderLoading = false;
        this.loading = false;
      }
    });
  }

  public IsQuoteAccepted() {
    if (this.TenderView) {
      return this.TenderView.QuoteAcceptedDate !== null && this.TenderView.IsProcessed === true;
    } else {
      return false;
    }
  }

  public IsExported() {
    if (this.TenderView) {
      return this.TenderView.DateFirstExport !== null;
    } else {
      return false;
    }
  }

  public IsExpired() {
    return getDayDifference(this.TenderView?.ExpiryDate) < 0;
  }

  //////////////////////////
  /// Single-Lane Tender ///
  //////////////////////////
  public NewSingleLaneTender() {
    this.SelectedCurrency = null;
    this.SingleLaneTenderParam = {
      Id: null,
      Name: null,
      Description: null,
      CompanyId: this.authService.CurrentUser?.User?.CompanyId,
      TenderStatusId: 1,
      TenderStepId: 1,
      DefaultCurrencyId: null,
      DefaultMarginPercentage: null,
      DefaultMarginMinimum: null,
      DefaultMarginMaximum: null,
      OriginCityId: null,
      OriginSeaPortId: null,
      OriginAirPortId: null,
      DestinationCityId: null,
      DestinationSeaPortId: null,
      DestinationAirPortId: null,
      ExpiryDate: null,
      QuoteAcceptedDate: null,
      TenderModalities: []
    } as ITenderSingleLaneParam;
    this.SingleLaneTenderResult = null;
  }

  public SearchSingleLaneTenders(param: ITenderSingleLaneSearchParam) {
    return this.api.post('SingleLane/List', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public LoadSingleLaneTenderParam(Id: number) {
    return this.api.get('SingleLane/GetConfig/' + Id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public LoadSingleLaneTender(Id: number) {
    return this.api.get('SingleLane/Get/' + Id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public SaveSingleLaneTender(param: ITenderSingleLaneParam) {
    return this.api.post('SingleLane/Update', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ProcessSingleLaneTender(tenderId: number) {
    return this.api.get('SingleLane/Process/' + tenderId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public IsSingleRateCompleted() {
    if (this.SingleLaneTenderResult != null) {
      return !!(this.SingleLaneTenderResult.Id !== null &&
              this.SingleLaneTenderResult.IsProcessed === true &&
              this.SingleLaneTenderResult.QuoteAcceptedDate !== null);
    } else {
      return false;
    }
  }

  public CanRequestSingleLaneRate() {
    return !!(this.SingleLaneTenderParam &&
      this.SingleLaneTenderParam.DefaultCurrencyId != null && this.SingleLaneTenderParam.DefaultCurrencyId > 0 &&
      this.SingleLaneTenderParam.DefaultMarginPercentage != null && this.SingleLaneTenderParam.DefaultMarginPercentage >= 0 &&
      this.SingleLaneTenderParam.DefaultMarginMinimum != null && this.SingleLaneTenderParam.DefaultMarginMinimum >= 0 &&
      this.SingleLaneTenderParam.DefaultMarginMaximum != null && this.SingleLaneTenderParam.DefaultMarginMaximum >= 0 &&
      this.SingleLaneTenderParam.ExpiryDate != null &&
      this.SingleLaneTenderParam.Name != null && this.SingleLaneTenderParam.Name.length > 0 &&
      ((this.SingleLaneTenderParam.OriginCityId != null &&
      this.SingleLaneTenderParam.OriginAirPortId != null &&
      this.SingleLaneTenderParam.OriginSeaPortId != null) ||
      (this.SingleLaneTenderParam.DestinationCityId != null &&
      this.SingleLaneTenderParam.DestinationAirPortId != null &&
      this.SingleLaneTenderParam.DestinationSeaPortId != null)) &&
      this.SingleLaneTenderParam.TenderModalities != null &&
      this.SingleLaneTenderParam.TenderModalities.length > 0
    );
  }

  public ExportSingleLaneTender(): Observable<any> {
    return this.api.getFile('Tender/ExportTender/' + this.SingleLaneTenderResult.Id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public SetAsExported(tenderId: number): Observable<any> {
    return this.api.get('SingleLane/SetDateExport/' + tenderId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public IsSingleRateExported() {
    if (this.SingleLaneTenderParam) {
      return this.SingleLaneTenderParam.DateFirstExport !== null;
    } else {
      return false;
    }
  }

  public GetSingleLaneModalitiesByType(modalityTypeID: number): ISingleLaneTenderModality[] {
    const modalities = this.SingleLaneTenderResult.TenderModalities.filter((x) => x.ModalityTypeId === modalityTypeID).sort((a, b) => a.Quantity - b.Quantity);

    if (modalities && modalities.length > 0) {
      return modalities;
    } else {
      return [] as ISingleLaneTenderModality[];
    }
  }

  /*** START: Load All Lookups and Selected Objects ***/
  // Load all Steps
  loadSteps() {
    this.loading = true;

    this.api.get('TenderStep/ListActive').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next:(data: ITenderStep[]) => {
        if (data) {
          this.Steps = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadSteps');
        this.loading = false;
      }
    });
  }

  // Load all TenderStatuses
  loadStatuses() {
    this.loading = true;

    this.api.get('TenderStatus/ListActive').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: ITenderStatus[]) => {
        if (data) {
          this.Statuses = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadSteps');
        this.loading = false;
      }
    });
  }

  // Load Company by ID
  public LoadCompany(Id: number) {
    this.loading = true;

    if (Id && Id > 0) {
      // Build URL for request
      const urlPath = 'Company/GetById/' + Id;
      // Call FailedRates controller "Search" endpoint
      this.api.get(urlPath).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: ICompany) => {
          if (data) {
            this.SelectedCompany = Object.assign({}, data);
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: loadCompany');
          this.loading = false;
        }
      });
    } else {
      return of([]);
    }
  }

  // Load Currency by ID
  public LoadTenderCurrency(Id: number) {
    this.loading = true;

    if (Id && Id > 0) {
      // Call TenderCurrency controller "Single" endpoint with Id parameter
      this.api.get('TenderCurrency/SingleByCurrencyID/' + Id).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: ITenderCurrency) => {
          if (data) {
            this.SelectedCurrency = Object.assign({}, data);
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.error('An Error Occurred: TenderCurrency');
          this.loading = false;
        }
      });
    } else {
      return of([]);
    }
  }

  // Load all service types
  loadServiceTypes() {
    this.loading = true;

    this.api.get('ServiceType/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next:(data) => {
        if (data) {
          this.ServiceTypes = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadServiceTypes');
        this.loading = false;
      }
    });
  }

  // Get InputType of TenderService
  GetInputType(tenderService: ITenderService) {
    if (tenderService.InputTypeId) {
      return tenderService.InputTypeId;
    } else {
      return tenderService.ServiceType.InputTypeId;
    }
  }

  public GetInclusionsByType(type: string) {
    let inclusions: ITenderService[] = [];

    switch (type) {
      case 'Origin':
        inclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToOrigin === true);
        break;
      case 'Freight':
        inclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToFreight === true);
        break;
      case 'Destination':
        inclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToDestination === true);
        break;
      default:
        return null;
    }

    return inclusions.filter(
      (thing, i, arr) => arr.findIndex(t => t.ServiceTypeId === thing.ServiceTypeId) === i
    );
  }

  public GetDistinctInclusions() {
    const includedServices: ITenderService[] = this.TenderView.TenderServices.filter((x) =>
      x.ApplyToOrigin === true ||
      x.ApplyToFreight === true ||
      x.ApplyToDestination === true);

    return includedServices.filter(
      (thing, i, arr) => arr.findIndex(t => t.ServiceTypeId === thing.ServiceTypeId) === i
    );
  }

  public GetExclusionsByType(type: string) {
    let exclusions: ITenderService[] = [];
    let serviceType: IServiceType[];

    if (this.ServiceTypes && this.ServiceTypes.length > 0) {
      switch (type) {
        case 'Origin':
          serviceType = this.ServiceTypes.filter((x) => x.IsOrigin === true);
          exclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToOrigin === false && (serviceType.filter((s) => s.Id === x.ServiceTypeId).length > 0));
          break;
        case 'Freight':
          serviceType = this.ServiceTypes.filter((x) => x.IsFreight === true);
          exclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToFreight === false && (serviceType.filter((s) => s.Id === x.ServiceTypeId).length > 0));
          break;
        case 'Destination':
          serviceType = this.ServiceTypes.filter((x) => x.IsOrigin === true);
          exclusions = this.TenderView.TenderServices.filter((x) => x.ApplyToDestination === false && (serviceType.filter((s) => s.Id === x.ServiceTypeId).length > 0));
          break;
        default:
          return null;
      }
    }

    return exclusions.filter(
      (thing, i, arr) => arr.findIndex(t => t.ServiceTypeId === thing.ServiceTypeId) === i
    );
  }

  public GetTenderModalitiesByType(modalityTypeID: number): ITenderModality[] {
    const tenderModalities = this.TenderView.TenderModalities.filter((x) => x.ModalityTypeId === modalityTypeID).sort((a, b) => a.Quantity - b.Quantity);

    if (tenderModalities && tenderModalities.length > 0) {
      return tenderModalities;
    } else {
      return [] as ITenderModality[];
    }
  }

  /*** END: Load All Lookups and Selected Objects ***/


  /*** START: Session Storage handling ***/

  public StoreTender() {
    if (this.TenderView && this.TenderView.Id) {
      try {
        this.storage.storeSessionItem('current-tender', this.TenderView);
      } catch (e) {
        console.log('Cannot store tender in session.');
      }
    }
  }

  public GetTenderFromStorage(): ITenderDto {
    return this.storage.getSessionItem('current-tender');
  }

  public RemoveTenderFromStorage() {
    this.storage.removeSessionItem('current-tender');
  }

  /*** END: Session Storage handling ***/


  /*** START: All save and update methods ***/

  public GetTenderFreights(tenderId: number) {
    return this.api.get('TenderFreight/GetByTenderId/' + tenderId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public MarkTenderFreightForUpdate(tenderFreight: ITenderFreight) {
    if (tenderFreight.Id) {
      const index = this.TenderFreightUpdateList.findIndex((x) => x.Id === tenderFreight.Id);
      // If exists, replace with updated TenderService object
      if (index >= 0) {
        this.TenderFreightUpdateList[index] = tenderFreight;
      } else {
        this.TenderFreightUpdateList.push(tenderFreight);
      }
    } else {
      const index = this.TenderFreightUpdateList.findIndex((x) =>
        x.OriginTenderLocationId === tenderFreight.OriginTenderLocationId &&
        x.DestinationTenderLocationId === tenderFreight.DestinationTenderLocationId &&
        x.ModalityTypeId === tenderFreight.ModalityTypeId
      );
      // If exists here it means the record was added and removed before saving, so remove from list as it should not be saved then
      if (index >= 0) {
        this.TenderFreightUpdateList.splice(index, 1);
      } else {
        this.TenderFreightUpdateList.push(tenderFreight);
      }
    }
  }

  public MarkTenderServiceForUpdate(tenderService: ITenderService) {
    tenderService.ServiceTypeUnitTypeId = +tenderService.ServiceTypeUnitTypeId;
    if (tenderService.Id) {
      const index = this.TenderServiceUpdateList.findIndex((x) => x.Id === tenderService.Id);
      // If exists, replace with updated TenderService object
      if (index >= 0) {
        this.TenderServiceUpdateList[index] = tenderService;
      } else {
        this.TenderServiceUpdateList.push(tenderService);
      }
    } else {
      const index = this.TenderServiceUpdateList.findIndex((x) => x.ServiceTypeId === tenderService.ServiceTypeId && x.TenderModalityId === tenderService.TenderModalityId);
      // If exists here it, replace with updated TenderService object, otherwise, add to update list
      if (index >= 0) {
        this.TenderServiceUpdateList[index] = tenderService;
      } else {
        this.TenderServiceUpdateList.push(tenderService);
      }
    }
  }

  public SaveTender(): Observable<ITenderDto> {
    if (this.TenderView) {
      this.TenderView.DefaultMarginPercentage = +this.TenderView.DefaultMarginPercentage;
      const param = JSON.parse(JSON.stringify(this.TenderView));
      param.TenderLocations = null;
      param.TenderFreights = null;
      param.TenderModalities = null;
      param.TenderServices = null;

      return this.api.post('Tender/Update', param).pipe(
        takeUntil(this.unsubscribe),
        map((data: ITenderDto) => data)
      );
    } else {
      return of({} as ITenderDto);
    }
  }

  public SaveObject(uri: string, object: any): Observable<any> {
    this.loading = true;
    if (this.TenderView) {
      return this.api.post(uri, object).pipe(
        takeUntil(this.unsubscribe),
        map((data) => data)
      );
    } else {
      return of([]);
    }
  }

  public GetQuote(param: any) {
    return this.api.post('TenderQuote/GenerateQuote', param).pipe(
      takeUntil(this.unsubscribe),
      map((data) => data)
    );
  }

  public ProcessTender(tenderId: number, sendNotification: boolean = true): Observable<any> {
    const param = {
      TenderId: tenderId,
      SendNotification: sendNotification
    };

    return this.api.post('Tender/ProcessTender', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ResetTender(tenderId: number) {
    return this.api.get('Tender/Reset/' + tenderId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ExportTender(): Observable<any> {
    if (this.TenderView && this.TenderView.Id && this.TenderView.QuoteAcceptedDate) {
      return this.api.getFile('Tender/ExportTender/' + this.TenderView.Id).pipe(
        takeUntil(this.unsubscribe)
      );
    } else {
      return new Observable<any>(null);
    }
  }

  /*** END: All save and update methods ***/

  /////////////////////
  // General Methods //
  /////////////////////
  public GetPortByCity(cityId: number, isAirport: boolean, maximumPorts: number = 1) {
    const param = {
      CityID: cityId,
      FunctionTypeID: isAirport ? 4 : 1,
      MaximumPorts: maximumPorts
    };

    return this.api.post('TenderLocation/GetPortByCity', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetRateGroupOptions(param: ISelectRateGroupParam) {
    return this.api.post('Tender/LocationRateGroupOptions', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpdateTenderLocationRateGroupRates(locationRateGroup: ITenderLocationRateGroup) {
    return this.api.post(`TenderLocationRateGroup/UpdateRateGroup/${locationRateGroup.Id}/${locationRateGroup.RateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CheckPopupBlocker() {
    const puTest = window.open(null,"","width=100,height=100");
    try { puTest.close(); return false; }
    catch(e) { return true; }
  }
}
