import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../../../../services/company.service';
import { ICompanyDetail } from '../../../../services/models/member.model';

@Component({
  selector: 'app-impact-data-information-modal',
  templateUrl: './impact-data-information-modal.component.html',
  styleUrls: ['./impact-data-information-modal.component.scss']
})
export class ImpactDataInformationModalComponent {

  constructor(private activeModal: NgbActiveModal,
              private companyService: CompanyService) {
  }

  companyId: number;
  hasAccess: boolean = false;
  updateCompanyDetails: ICompanyDetail[] = [];
  close() {
    this.activeModal.close();
  }

  update() {
    if (this.updateCompanyDetails?.length > 0) {
      this.companyService.UpsertCompanyDetailList(this.updateCompanyDetails)
        .subscribe({
          next: () => {
            this.close();
          }
        });
    } else {
      this.close();
    }
  }
}
