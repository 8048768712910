export enum IPortType {
    NotSet = 'NotSet',
    Air = 'Air',
    Sea = 'Sea'
}

export enum IRequestType {
    Ports = 'Ports',
    Agents = 'Agents'
}
