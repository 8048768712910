import { Component, Input, OnInit } from '@angular/core';
import { IDangerousSubstanceSafetyChartResponse } from '../../../../services/models/hse/dangerous-substance-register.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-safety-chart',
  templateUrl: './safety-chart.component.html',
  styleUrls: ['./safety-chart.component.scss']
})
export class SafetyChartComponent implements OnInit {

  @Input() substance: IDangerousSubstanceSafetyChartResponse;

  constructor() {
  }

  imageRoute: string;

  ngOnInit(): void {
    this.substance = {} as IDangerousSubstanceSafetyChartResponse;
    this.imageRoute = `${environment.SiteUrls.AzureStorageBaseURL}dangerous-substance/`;
  }
}
