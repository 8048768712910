import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Output() onchange = new EventEmitter<boolean>();
  // ID must be set
  @Input() id: string;
  // To set the initial value
  @Input() checked: boolean = false;
  @Input() Placeholder: string = 'Toggle';
  @Input() isDisabled: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  sendOutput() {
    this.onchange.emit(!this.checked);
  }

}
