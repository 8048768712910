<!---------------------->
<!----- Header Bar ----->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Single-Lane Rate Calculator
  </h1>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="btn-group" *ngIf="activeTenderId == null">
      <button type="button" class="btn btn-sm harmony-purple-outline-button" (click)="createNew()">+ New Single-Lane Rate</button>
    </div>
    <ng-container *ngIf="tenderService.IsSingleRateCompleted() && getDayDifference(tenderService.SingleLaneTenderParam.ExpiryDate) < 0">
      <span class="harmony-grey-text me-3">
        Tender expired, export disabled
      </span>
    </ng-container>
    <ng-container *ngIf="tenderService.IsSingleRateCompleted() && getDayDifference(tenderService.SingleLaneTenderParam.ExpiryDate) >= 0">
      <span *ngIf="exportingRates" class="harmony-grey-text me-3">
          <app-loader [Width]="25"></app-loader>
      </span>
      <div class="btn-group" *ngIf="activeTenderId != null && tenderService.SingleLaneTenderParam != null">
        <button type="submit"
                [disabled]="exportingRates"
                class="btn btn-sm btn-primary excel-export-btn me-2"
                (click)="exportRates()">
          Export to Excel&nbsp; <fa-icon [icon]="faExcel" size="sm"></fa-icon>
        </button>
      </div>
    </ng-container>
    <div class="btn-group" *ngIf="activeTenderId != null">
      <button type="button" class="btn btn-sm btn-outline-primary harmony-teal-outline-button" (click)="cancelCreateEdit()">Exit</button>
    </div>
  </div>
</div>
<!------------------------------------>
<!------ Single Rate Overview -------->
<!------------------------------------>
<div *ngIf="activeTenderId == null">
  <app-single-rate-overview (SingleRateTenderSelected)="SetSingleRateTender($event)" (CreateNew)="createNew()"></app-single-rate-overview>
</div>
<!------------------------------------>
<!------ Single Rate Calculator ------>
<!------------------------------------>
<div *ngIf="activeTenderId != null">
  <app-rate-calculator [tenderID]="activeTenderId"></app-rate-calculator>
</div>
