import { Component, OnInit } from '@angular/core';
import {
  IEmergencyDrillEvaluationRequestDto,
  IEmergencyDrillEvaluationResponseDto,
} from '../../../../services/models/hse/emergency.model';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import { HseService } from '../../../../services/hse.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../services/api.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-emergency-drill-evaluation-modal',
  templateUrl: './emergency-drill-evaluation-modal.component.html',
  styleUrls: ['./emergency-drill-evaluation-modal.component.scss']
})
export class EmergencyDrillEvaluationModalComponent implements OnInit {

  public drillEvaluation: IEmergencyDrillEvaluationResponseDto;
  public isNew: boolean;
  public companyId: number;
  public branchId: number = null;
  public hasCompanyFilterPermission: false;
  public canEdit = false;
  public userId: number;

  branches: IBranchDto[] = [];
  invalid = false;
  loading = false;

  constructor(private activeModal: NgbActiveModal,
              private hseService: HseService,
              private apiService: ApiService,
              private alertService: AlertService) {

  }

  ngOnInit(): void {
    if (this.isNew) {
      this.drillEvaluation = {
        CompanyId: this.companyId,
        BranchId: this.branchId,
      } as IEmergencyDrillEvaluationResponseDto;
    } else {
      this.drillEvaluation.CompanyId = this.companyId;
      this.drillEvaluation.BranchId = this.branchId;
    }
    this.populateBranch(this.drillEvaluation.CompanyId);
  }

  changeCompanyId(id: number) {
    this.drillEvaluation.CompanyId = id;
    this.populateBranch(id);
  }

  populateBranch(id) {
    if (id) {
      this.getBranches();
    } else {
      this.branches = [];
    }
  }

  getBranches() {
    this.hseService.getBranchesByCompanyId(this.drillEvaluation.CompanyId)
      .subscribe({
        next: value => {
          if (value) {
            this.branches = Object.assign([], value);
          }
        }
      });
  }

  update() {
    this.loading = true;
    if (this.isRequestValid()) {
      this.apiService.put(`Emergency/DrillEvaluation/${this.drillEvaluation.Id}`, this.getRequestBody())
        .subscribe({
          next: () => {
            this.loading = false;
            this.close(true);
          }, error: () => {
            this.loading = false;
            this.alertService.error('Error occurred, please try again later');
          }
        });
    }
  }

  close(changed: boolean) {
    this.activeModal.close(changed);
  }

  add() {
    this.loading = true;
    if (this.isRequestValid()) {
      this.apiService.post(`Emergency/DrillEvaluation`, this.getRequestBody())
        .subscribe({
          next: () => {
            this.loading = false;
            this.close(true);
          }, error: () => {
            this.loading = false;
            this.alertService.error('Error occurred, please try again later');
          }
        });
    }
  }

  isRequestValid() {
    if (this.drillEvaluation.CompanyId > 0 &&
      this.userId > 0 &&
      this.drillEvaluation.ExecutedDate) {
      return true;
    }
    this.alertService.error('Ensure all required fields are set');
    this.invalid = true;
    return false;
  }

  getRequestBody(){
    return {
      IsEnvironment: this.drillEvaluation.IsEnvironment,
      IsHealthAndSafety: this.drillEvaluation.IsHealthAndSafety,
      ExecutedDate: this.drillEvaluation.ExecutedDate,
      Location: this.drillEvaluation.Location,
      Participants: this.drillEvaluation.Participants,
      Situation: this.drillEvaluation.Situation,
      Course: this.drillEvaluation.Course,
      Findings: this.drillEvaluation.Findings,
      Conclusion: this.drillEvaluation.Conclusion,
      CompanyId: this.drillEvaluation.CompanyId,
      BranchId: this.drillEvaluation.BranchId,
      UserId: this.userId
    } as IEmergencyDrillEvaluationRequestDto;
  }

  setEnvironment($event: any) {
    this.drillEvaluation.IsEnvironment = $event.target.checked
  }

  setHealthAndSafety($event: any) {
    this.drillEvaluation.IsHealthAndSafety = $event.target.checked
  }
}
