import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITenderService } from '../../../../../services/models/pricing/tender.model';
import { TenderService } from '../../../../../services/tender.service';
import { IServiceType } from '../../../../../services/models/pricing/rates.model';
import { PricingService } from '../../../../../services/pricing.service';

@Component({
  selector: 'app-add-service-modal',
  templateUrl: './add-service-modal.component.html',
  styleUrls: ['./add-service-modal.component.scss']
})
export class AddServiceModalComponent implements OnInit {
 // Public variables
  public TenderServices: ITenderService[];

  // Component Variables
  types: string[] = ['Origin', 'Freight', 'Destination'];
  selectedType: string = 'Origin';
  result: IServiceType[] = [];

  // General variables
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private pricingService: PricingService,
              private tenderService: TenderService) { }

  ngOnInit() { }

  filterServiceTypes() {
    switch (this.selectedType) {
      case 'Origin':
        return this.pricingService.ServiceTypes$.getValue().filter((x) => x.IsOrigin === true && x.IsOriginDefault === false);
      case 'Freight':
        return this.pricingService.ServiceTypes$.getValue().filter((x) => x.IsFreight === true && x.IsFreightDefault === false);
      case 'Destination':
        return this.pricingService.ServiceTypes$.getValue().filter((x) => x.IsDestination === true && x.IsDestinationDefault === false);
      default:
        return null;
    }
  }

  toggleServiceType(serviceType: IServiceType) {
    const tenderServices = this.TenderServices.filter((x) => x.ServiceTypeId === serviceType.Id);

    if (tenderServices) {
      tenderServices.forEach((ts) => {
        const index = this.TenderServices.findIndex((x) => x.Id === ts.Id);

        switch (this.selectedType) {
          case 'Origin':
            ts.ApplyToOrigin = !ts.ApplyToOrigin;
            ts.ApplyToOriginRate = ts.ApplyToOrigin;
            this.TenderServices[index] = ts;
            // Mark record to be updated
            this.tenderService.MarkTenderServiceForUpdate(ts);
            break;
          case 'Freight':
            ts.ApplyToFreight = !ts.ApplyToFreight;
            ts.ApplyToFreightRate = ts.ApplyToFreight;
            this.TenderServices[index] = ts;
            // Mark record to be updated
            this.tenderService.MarkTenderServiceForUpdate(ts);
            break;
          case 'Destination':
            ts.ApplyToDestination = !ts.ApplyToDestination;
            ts.ApplyToDestinationRate = ts.ApplyToDestination;
            this.TenderServices[index] = ts;
            // Mark record to be updated
            this.tenderService.MarkTenderServiceForUpdate(ts);
            break;
          default:
            return null;
        }
      });
    }
  }

  checkServiceStatus(serviceType: IServiceType) {
      switch (this.selectedType) {
        case 'Origin':
          const originServices = this.TenderServices.filter((x) => x.ServiceTypeId === serviceType.Id && x.ApplyToOrigin === true);
          return originServices.length > 0;
        case 'Freight':
          const freightServices = this.TenderServices.filter((x) => x.ServiceTypeId === serviceType.Id && x.ApplyToFreight === true);
          return freightServices.length > 0;
        case 'Destination':
          const destinationService = this.TenderServices.filter((x) => x.ServiceTypeId === serviceType.Id && x.ApplyToDestination === true);
          return destinationService.length > 0;
        default:
          return false;
      }
  }

  close() {
    const result = {
      TenderServices: this.TenderServices,
      TenderServicesToUpdate: this.tenderService.TenderServiceUpdateList
    };

    this.activeModal.close(result);
  }
}
