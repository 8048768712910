import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-hangfire-dashboard',
  templateUrl: './hangfire-dashboard.component.html',
  styleUrls: ['./hangfire-dashboard.component.scss']
})
export class HangfireDashboardComponent {
  // Component variables
  dashboardUrl: SafeResourceUrl = null;
  header: HttpHeaders;

  // General variables
  env = environment;

  constructor(private sanitizer: DomSanitizer) {
    this.setDashboard();
  }

  setDashboard() {
    this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.hangfire_url);
  }
}
