import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { ICompanyDetail } from '../../../../services/models/member.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-risk-assessment-information-modal',
  templateUrl: './risk-assessment-information-modal.component.html',
  styleUrls: ['./risk-assessment-information-modal.component.scss']
})
export class RiskAssessmentInformationModalComponent implements AfterContentChecked {

  companyId: number;
  hasAccess: boolean = false;
  updateCompanyDetails: ICompanyDetail[] = [];

  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private cdr: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  update() {
    if (this.updateCompanyDetails?.length > 0) {
      this.companyService.UpsertCompanyDetailList(this.updateCompanyDetails)
        .subscribe({
          next: () => {
            this.close();
          }
        });
    } else {
      this.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
