import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { PricingService } from 'src/app/services/pricing.service';
import { CompanyService } from '../../../../services/company.service';
import { IPreferredAgentCreateRequest, IPreferredAgentResponse, IPreferredAgentUpdateRequest } from '../../../../services/models/pricing/agent.model';
import { IAgent } from '../../../../services/models/pricing/partner.model';
import { IRateTypeDto } from '../../../../services/models/pricing/rate-request.model';
import { IActiveCompany } from '../../../../services/models/member.model';
import { ILocationType } from '../../../../services/models/pricing/location.model';

@Component({
  selector: 'app-view-agent-modal',
  templateUrl: './view-agent-modal.component.html',
  styleUrls: ['./view-agent-modal.component.scss']
})
export class ViewAgentModalComponent implements OnInit {

  // Variables
  public preferredAgent: IPreferredAgentResponse = null;
  public isNew: false;
  agentForm: UntypedFormGroup;

  // Observables
  public selectedLocationType: ILocationType = null;
  public selectedRate: IRateTypeDto = null;
  public locationTypes$ = this.pricingService.LocationTypes$.asObservable();
  public rateTypes$ = this.pricingService.RateTypes$.asObservable();
  companies$ = this.companyService.ActiveMembers$.asObservable();
  company: IActiveCompany = null;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private formBuilder: UntypedFormBuilder,
              private companyService: CompanyService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (this.isNew) {
      // Init preferred agent object
      this.preferredAgent = {
        Active: true,
        IgnoreAgent: false,
        AdditionalAgent: false,
        LocationId: null,
        RadiusUnitTypeId: null,
        TenderId: null,
        AgentId: null,
        LocationTypeId: null,
        CountryId: null,
        CityId: null,
        StateId: null,
        RequestedCompanyId: null,
        RateTypeId: null,
        AgentCompanyId: null
      } as IPreferredAgentResponse;
      // Generate empty form for new agents
      this.newForm();
    } else {
      // Generate form with existing record data pre-populated
      this.existingForm()
    }

    this.rateTypes$.subscribe((x) => {
      if (this.preferredAgent.RateTypeId) {
        this.selectedRate = x.find(l => l.Id === this.preferredAgent.RateTypeId);
      }
    });

    this.companies$.subscribe((x) => {
      if (this.preferredAgent.RequestedCompanyId) {
        this.company = x.find(l => l.CompanyId === this.preferredAgent.RequestedCompanyId);
      }
    });

    this.locationTypes$.subscribe((x) => {
      if (this.preferredAgent.LocationTypeId) {
        this.selectedLocationType = x.find(l => l.Id === this.preferredAgent.LocationTypeId);
      } else {
        this.selectedLocationType = x.find(l => l.Name == 'City');
        this.preferredAgent.LocationTypeId = this.selectedLocationType.Id;
        if (this.agentForm) {
          this.agentForm.controls.locationTypeId.setValue(this.selectedLocationType.Id);
        }
      }
    });
  }

  update() {
    if (this.isFormValid()) {
      const agent = {
        AgentId: this.preferredAgent.AgentId,
        Active: this.preferredAgent.Active,
        CityId: this.preferredAgent.CityId,
        StateId: this.preferredAgent.StateId,
        IgnoreAgent: this.preferredAgent.IgnoreAgent,
        AdditionalAgent: this.preferredAgent.AdditionalAgent,
        CountryId: this.preferredAgent.CountryId,
        AgentCompanyId: this.preferredAgent.AgentCompanyId,
        TenderId: this.preferredAgent.TenderId,
        LocationTypeId: this.preferredAgent.LocationTypeId,
        Comment: this.preferredAgent.Comment,
        id: this.preferredAgent.Id,
        LocationId: this.preferredAgent.LocationId,
        StartDate: this.preferredAgent.StartDate,
        EndDate: this.preferredAgent.EndDate,
        RequestedCompanyId: this.preferredAgent.RequestedCompanyId,
        RateTypeId: this.preferredAgent.RateTypeId
      } as IPreferredAgentUpdateRequest;
      this.companyService.updatePreferredAgent(agent)
        .subscribe({
          next: () => {
            this.close(this.preferredAgent);
          },
          error: () => {
            this.alertService.error('Failed to update Agent');
          }
        });
    }
  }

  add() {
    if (this.isFormValid()) {
      const agent = {
        AgentId: this.preferredAgent.AgentId,
        Active: this.preferredAgent.Active,
        CityId: this.preferredAgent.CityId,
        StateId: this.preferredAgent.StateId,
        IgnoreAgent: this.preferredAgent.IgnoreAgent,
        AdditionalAgent: this.preferredAgent.AdditionalAgent,
        CountryId: this.preferredAgent.CountryId,
        AgentCompanyId: this.preferredAgent.AgentCompanyId,
        TenderId: this.preferredAgent.TenderId,
        LocationTypeId: this.preferredAgent.LocationTypeId,
        Comment: this.preferredAgent.Comment,
        LocationId: this.preferredAgent.LocationId,
        StartDate: this.preferredAgent.StartDate,
        EndDate: this.preferredAgent.EndDate,
        RequestedCompanyId: this.preferredAgent.RequestedCompanyId,
        RateTypeId: this.preferredAgent.RateTypeId
      } as IPreferredAgentCreateRequest;
      this.companyService.createPreferredAgent(agent)
        .subscribe({
          next: () => {
            this.close(this.preferredAgent);
          },
          error: () => {
            this.alertService.error('Failed to add Agent');
          }
        });
    }
  }

  setLocationTypeId(event: ILocationType) {
    this.selectedLocationType = event;
    this.preferredAgent.LocationTypeId = this.selectedLocationType?.Id;
    this.preferredAgent.LocationId = null;
    this.preferredAgent.CountryId = null;
    this.preferredAgent.StateId = null;
    this.preferredAgent.CityId = null;
    this.agentForm.controls.locationTypeId.setValue(this.selectedLocationType.Id);
  }

  isFormValid() {
    this.agentForm.markAllAsTouched();
    this.agentForm.markAsTouched();
    if (this.selectedLocationType) {
      if (this.selectedLocationType.Name === 'Country') {
        this.clearCityValidation();
        this.clearStateValidation();
      } else if (this.selectedLocationType.Name === 'State') {
        this.clearCityValidation();
        this.clearCountryValidation();
      } else if (this.selectedLocationType.Name === 'City') {
        this.clearCountryValidation();
        this.clearStateValidation();
      }
    }
    if (this.agentForm.invalid) {
      this.alertService.warn('Form is not valid');
    }
    return this.agentForm.valid;
  }

  clearCountryValidation() {
    this.agentForm.controls.countryId.clearValidators();
    this.agentForm.controls.countryId.setValue(null);
    this.agentForm.controls.countryId.updateValueAndValidity();
  }

  clearStateValidation() {
    this.agentForm.controls.stateId.clearValidators();
    this.agentForm.controls.stateId.setValue(null);
    this.agentForm.controls.stateId.updateValueAndValidity();
  }

  clearCityValidation() {
    this.agentForm.controls.cityId.clearValidators();
    this.agentForm.controls.cityId.setValue(null);
    this.agentForm.controls.cityId.updateValueAndValidity();
  }

  newForm() {
    this.agentForm = this.formBuilder.group({
      agentId: new UntypedFormControl(null, Validators.required),
      tenderId: new UntypedFormControl(null),
      countryId: new UntypedFormControl(null, Validators.required),
      cityId: new UntypedFormControl(null, Validators.required),
      stateId: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(true, Validators.required),
      ignoreAgent: new UntypedFormControl(false, Validators.required),
      additionalAgent: new UntypedFormControl(false, Validators.required),
      locationTypeId: new UntypedFormControl(this.selectedLocationType?.Id, Validators.required),
      rateTypeId: new UntypedFormControl(null),
      requestedCompanyId: new UntypedFormControl(null),
      startDate: new UntypedFormControl(null, Validators.required),
      endDate: new UntypedFormControl(null)
    });
  }

  existingForm() {
    this.agentForm = this.formBuilder.group({
      agentId: new UntypedFormControl(this.preferredAgent.AgentId, Validators.required),
      tenderId: new UntypedFormControl(this.preferredAgent.TenderId),
      countryId: new UntypedFormControl(this.preferredAgent.CountryId, Validators.required),
      cityId: new UntypedFormControl(this.preferredAgent.CityId, Validators.required),
      stateId: new UntypedFormControl(this.preferredAgent.StateId, Validators.required),
      active: new UntypedFormControl(this.preferredAgent.Active, Validators.required),
      ignoreAgent: new UntypedFormControl(this.preferredAgent.IgnoreAgent, Validators.required),
      additionalAgent: new UntypedFormControl(this.preferredAgent.AdditionalAgent, Validators.required),
      locationTypeId: new UntypedFormControl(this.preferredAgent.LocationTypeId, Validators.required),
      rateTypeId: new UntypedFormControl(this.preferredAgent.RateTypeId),
      requestedCompanyId: new UntypedFormControl(this.preferredAgent.RequestedCompanyId),
      startDate: new UntypedFormControl(this.preferredAgent.StartDate, Validators.required),
      endDate: new UntypedFormControl(this.preferredAgent.EndDate)
    });
  }

  close(agent: IPreferredAgentResponse = null) {
    this.activeModal.close(agent);
  }

  setIgnoreAgent(event: any) {
    this.preferredAgent.IgnoreAgent = event.target.checked;
    this.agentForm.controls.ignoreAgent.setValue(event.target.checked);
  }

  setAdditionalAgents(event: any) {
    this.preferredAgent.AdditionalAgent = event.target.checked;
    this.agentForm.controls.additionalAgent.setValue(event.target.checked);
  }

  setActive(event: any) {
    this.preferredAgent.Active = event.target.checked;
    this.agentForm.controls.active.setValue(event.target.checked);
  }

  setRateTypeId(rateTypeId: any) {
    this.preferredAgent.RateTypeId = rateTypeId;
    this.agentForm.controls.rateTypeId.setValue(rateTypeId);
  }

  setCompanyId(companyId: any) {
    this.preferredAgent.RequestedCompanyId = companyId;
    this.agentForm.controls.requestedCompanyId.setValue(companyId);
  }

  // set date values
  setStartDate(date: string) {
    this.preferredAgent.StartDate = date;
    this.agentForm.controls.startDate.setValue(date);
  }

  setEndDate(date: string) {
    this.preferredAgent.EndDate = date;
    this.agentForm.controls.endDate.setValue(date);
  }

  setAgent($event: IAgent) {
    this.preferredAgent.AgentId = $event ? $event.AgentId : null;
    this.agentForm.controls.agentId.setValue($event ? $event.AgentId : null);
    this.preferredAgent.AgentCompanyId = $event ? $event.CompanyId : null;
  }
}
