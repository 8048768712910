<div class="modal-header">
  <h4 class="modal-title" id="rategroup-edit-modal-title">
    {{ rateGroup?.ID == null ? 'Create a new rate group' : 'Manage rate group | ' + rateGroup.ID }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="close()">

  </button>
</div>
<div class="modal-body ps-4 pe-4">
  <app-loader *ngIf="!rateGroup" [Width]="25"></app-loader>
  <div class="row" *ngIf="rateGroup">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-teal-text fw-bold mb-0">
            Details
          </h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row">
        <div class="col-4">
          <b>Modality Type</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="rateGroup.ID">
            {{ rateGroup.ModalityTypeName ?? '-' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(modalityTypes$ | async)"
                    [(ngModel)]="rateGroup.ModalityTypeID">
              <option [ngValue]="null" selected>Select a mode...</option>
              <option *ngFor="let mode of (modalityTypes$ | async)" [ngValue]="mode.Id">{{ mode.Name }}</option>
            </select>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Project</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="rateGroup.ID">
          {{ rateGroup.ProjectName ?? '-' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(projects$ | async)"
                    [ngClass]="{'is-invalid': rateGroup.ProjectID == null && invalid}"
                    *ngIf="isRateAdmin"
                    [(ngModel)]="rateGroup.ProjectID">
              <option [ngValue]="null" selected>Select a project...</option>
              <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(companyProjects$ | async)"
                    [ngClass]="{'is-invalid': rateGroup.ProjectID == null && invalid}"
                    *ngIf="!isRateAdmin"
                    [(ngModel)]="rateGroup.ProjectID">
              <option [ngValue]="null" selected>Select a project...</option>
              <option *ngFor="let project of (companyProjects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Rate Source</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="rateGroup.ID">
            {{ pricingService.GetRateSourceById(rateGroup.RateSourceID).Name }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(rateSources$ | async)"
                    [ngClass]="{'is-invalid': rateGroup.RateSourceID == null && invalid}"
                    [(ngModel)]="rateGroup.RateSourceID">
              <option [ngValue]="null" selected>Select a rate source...</option>
              <option *ngFor="let source of (rateSources$ | async)" [ngValue]="source.Id">{{ source.Name }}</option>
            </select>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="rateGroup.RateRequestID">
        <div class="row mt-1">
          <div class="col-4">
            <b>Rate Request ID</b>
          </div>
          <div class="col-8 overflow-hidden">
            {{ rateGroup.RateRequestID ? rateGroup.RateRequestID : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Rate Request Type</b>
          </div>
          <div class="col-8 overflow-hidden">
            {{ rateGroup.RateRequestTypeName ? rateGroup.RateRequestTypeName : '-' }}
          </div>
        </div>
      </ng-container>
      <div class="row mt-1">
        <div class="col-4">
          <b>Buy/Sell Type</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="rateGroup.ID">
            {{ rateGroup.RateBuySellTypeID == 0 ? 'Buy' : 'Sell' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <select class="form-select form-select-sm pointer"
                    [ngClass]="{'is-invalid': rateGroup.RateBuySellTypeID == null && invalid}"
                    [(ngModel)]="rateGroup.RateBuySellTypeID">
              <option [ngValue]="null" selected>Select a buy/sell type...</option>
              <option [ngValue]="0">Buy</option>
              <option [ngValue]="1">Sell</option>
            </select>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="rateGroup.ID">
        <div class="row mt-1">
          <div class="col-4">
            <b>Submission Date</b>
          </div>
          <div class="col-8 overflow-hidden">
            <small>{{ rateGroup.SubmissionDate ? (rateGroup.SubmissionDate | date: environment.FormattingStandards.LongDateFormat) : '-' }}</small>
          </div>
        </div>
      </ng-container>
      <div class="row mt-1">
        <div class="col-4">
          <b>Valid From</b>
        </div>
        <div class="col-8 overflow-hidden">
          <app-date-picker [identifier]="'fromDate'"
                           (onChange)="changeValidFromDate($event)"
                           [showError]="rateGroup.ValidFrom == null && invalid"
                           [minDate]="today"
                           [value]="rateGroup.ValidFrom"
                           [placeholder]="'Select date valid from'">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Valid To</b>
        </div>
        <div class="col-8 overflow-hidden">
          <app-date-picker [identifier]="'toDate'"
                           (onChange)="rateGroup.ValidTo = $event"
                           [minDate]="minValidTo"
                           [value]="rateGroup.ValidTo"
                           [placeholder]="'Select date valid until'">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Description</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="rateGroup.Description">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 overflow-hidden">
          <h5 class="harmony-teal-text fw-bold mb-0">
            Agent Info
          </h5>
        </div>
        <hr class="my-1">
        <div class="col-4">
          <b>Company Name</b>
        </div>
        <div class="col-8">
          <ng-container *ngIf="rateGroup.ID || !isRateAdmin">
            {{ rateGroup.AgentName ?? '-' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID && isRateAdmin">
            <app-company-search [ShowError]="rateGroup.CompanyID == null && invalid"
                                [SelectedID]="rateGroup.CompanyID"
                                (OnSelectObject)="changeAgent($event)"
                                [MembersOnly]="false"></app-company-search>
          </ng-container>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 overflow-hidden">
          <h5 class="harmony-teal-text fw-bold mb-0">
            Origin
          </h5>
        </div>
        <hr class="my-1">
        <div class="col-4">
          <b>City</b>
        </div>
        <div class="col-8">
          <ng-container *ngIf="rateGroup.ID">
            {{ rateGroup.OriginLocationName ? rateGroup.OriginLocationName : '-' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <app-city-search
              class="d-inline"
              [Identifier]="'OriginLocationCity'"
              [ShowError]="rateGroup.OriginLocationID == null && rateGroup.DestinationLocationID == null && invalid"
              [SelectedID]="rateGroup.OriginLocationID"
              [Disabled]="loading"
              (OnSelect)="rateGroup.OriginLocationID = $event">
            </app-city-search>
          </ng-container>
        </div>
        <div class="col-4 mt-1 align-self-center">
          <b>Port</b>
        </div>
        <div class="col-8 mt-1">
          <ng-container *ngIf="rateGroup.ID">
            <ng-container *ngIf="disableOriginPort">
              {{ rateGroup.DeparturePortID ? rateGroup.DeparturePortCode : '-' }}
            </ng-container>
            <ng-container *ngIf="!disableOriginPort">
              <app-port-search
                class="d-inline"
                [Disabled]="rateGroup.ModalityTypeID === null || rateGroup.ModalityTypeID <= 0 || rateGroup.ModalityTypeID > 3"
                [Identifier]="'DeparturePort'"
                [SelectedID]="rateGroup.DeparturePortID"
                [IsAirport]="rateGroup.ModalityTypeID <= 1"
                [ShowError]="false"
                (OnSelect)="rateGroup.DeparturePortID = $event">
              </app-port-search>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <app-port-search
              class="d-inline"
              [Disabled]="rateGroup.ModalityTypeID === null || rateGroup.ModalityTypeID <= 0 || rateGroup.ModalityTypeID > 3"
              [Identifier]="'DeparturePort'"
              [SelectedID]="rateGroup.DeparturePortID"
              [IsAirport]="rateGroup.ModalityTypeID <= 1"
              [ShowError]="false"
              (OnSelect)="rateGroup.DeparturePortID = $event">
            </app-port-search>
          </ng-container>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h5 class="harmony-teal-text fw-bold mb-0">
            Destination
          </h5>
        </div>
        <hr class="my-1">
        <div class="col-4">
          <b>City</b>
        </div>
        <div class="col-8">
          <ng-container *ngIf="rateGroup.ID">
            {{ rateGroup.DestinationLocationName ? rateGroup.DestinationLocationName : '-' }}
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <app-city-search
              class="d-inline"
              [Identifier]="'DestinationLocationCity'"
              [ShowError]="rateGroup.OriginLocationID == null && rateGroup.DestinationLocationID == null && invalid"
              [SelectedID]="rateGroup.DestinationLocationID"
              [Disabled]="loading"
              (OnSelect)="rateGroup.DestinationLocationID = $event">
            </app-city-search>
          </ng-container>
        </div>
        <div class="col-4 mt-1 align-self-center">
          <b>Port</b>
        </div>
        <div class="col-8 mt-1">
          <ng-container *ngIf="rateGroup.ID">
            <ng-container *ngIf="disableDestinationPort">
              {{ rateGroup.ArrivalPortID ? rateGroup.ArrivalPortCode : '-' }}
            </ng-container>
            <ng-container *ngIf="!disableDestinationPort">
              <app-port-search
                class="d-inline"
                [Disabled]="rateGroup.ModalityTypeID === null || rateGroup.ModalityTypeID <= 0 || rateGroup.ModalityTypeID > 3"
                [Identifier]="'ArrivalPort'"
                [SelectedID]="rateGroup.ArrivalPortID"
                [IsAirport]="rateGroup.ModalityTypeID <= 1"
                [ShowError]="false"
                (OnSelect)="rateGroup.ArrivalPortID = $event">
              </app-port-search>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!rateGroup.ID">
            <app-port-search
              class="d-inline"
              [Disabled]="rateGroup.ModalityTypeID === null || rateGroup.ModalityTypeID <= 0 || rateGroup.ModalityTypeID > 3"
              [Identifier]="'ArrivalPort'"
              [SelectedID]="rateGroup.ArrivalPortID"
              [IsAirport]="rateGroup.ModalityTypeID <= 1"
              [ShowError]="false"
              (OnSelect)="rateGroup.ArrivalPortID = $event">
            </app-port-search>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="rateGroup && isRateAdmin">
        <hr class="my-2">
        <div class="row mt-1">
          <div class="col-4">
            <b>Rate Verdict</b>
          </div>
          <div class="col-8 overflow-hidden">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(rateVerdicts$ | async)"
                    [ngClass]="{'is-invalid': rateGroup.RateVerdictID == null && invalid}"
                    [(ngModel)]="rateGroup.RateVerdictID">
              <option [ngValue]="null">None...</option>
              <option *ngFor="let verdict of (rateVerdicts$ | async)" [ngValue]="verdict.Id">{{ verdict.Name }}</option>
            </select>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row mt-4" *ngIf="rateGroup">
    <div class="col-12">
      <ul class="nav nav-tabs" id="capture-rates" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active"
             id="service-rates-tab"
             data-bs-toggle="tab"
             href="#service-rates"
             role="tab"
             aria-controls="service-rates"
             aria-selected="true">
            Services & Rates
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="inclusions-tab"
             data-bs-toggle="tab"
             href="#inclusions"
             role="tab"
             aria-controls="inclusions"
             aria-selected="false">
            Inclusions & Exclusions
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="comments-tab"
             data-bs-toggle="tab"
             href="#comments"
             role="tab"
             aria-controls="comments"
             aria-selected="false">
            Comments  <fa-icon [icon]="faRequired"
                               class="harmony-yellow-text ms-1"
                               [title]="'Comments added'"
                               *ngIf="rateGroup.Comment && rateGroup.Comment.length > 0"></fa-icon>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="test-rates-tab"
             data-bs-toggle="tab"
             href="#test-rates"
             role="tab"
             aria-controls="test-rates"
             aria-selected="false">
            Test Rates
          </a>
        </li>
      </ul>
      <div class="tab-content mt-2" id="capture-rates-content">
        <!----------------------------------->
        <!------- Services & Rates ---------->
        <!----------------------------------->
        <div class="tab-pane fade show active" id="service-rates" role="tabpanel" aria-labelledby="service-rates-tab">
          <div class="row mt-1 header-row">
            <div class="col-3 header-title border-split-left">
              Service Type
            </div>
            <div class="col-3 header-title">
              Calculation Method
            </div>
            <div class="col-2 header-title">
              Location Type
            </div>
            <div class="col-2 header-title">
              Date Created
            </div>
            <div class="col-2 header-title-small text-end pt-2 pe-3 border-split-right">
              <fa-icon [icon]="faPlus"
                       class="text-white pointer"
                       [title]="'Add new service'"
                       (click)="newRateGroupService()"
                       size="lg"></fa-icon>
            </div>
          </div>
          <div class="row result-row border-bottom" *ngFor='let rgs of rateGroup.RateGroupServices; let i = index'>
            <div class="col-12">
              <div class="row">
                <div class="col-3 overflow-hidden col-compress">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="!(serviceTypes$ | async) || rgs.Active === false"
                          [ngClass]="{'is-invalid': rgs.ServiceID == null && invalid}"
                          style="font-weight: 700"
                          [(ngModel)]="rgs.ServiceID">
                    <option [ngValue]="null">None...</option>
                    <option *ngFor="let serviceType of (serviceTypes$ | async)" [ngValue]="serviceType.Id">{{ serviceType.Name }}</option>
                  </select>
                </div>
                <div class="col-3 overflow-hidden col-compress">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="!(calculationMethods$ | async) || rgs.Active === false"
                          [ngClass]="{'is-invalid': rgs.CalculationMethodID == null && invalid}"
                          [(ngModel)]="rgs.CalculationMethodID">
                    <option [ngValue]="null">None...</option>
                    <option *ngFor="let calcMethod of (calculationMethods$ | async)" [ngValue]="calcMethod.Id">{{ calcMethod.Name }}</option>
                  </select>
                </div>
                <div class="col-2 overflow-hidden col-compress">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="rgs.Active === false"
                          [(ngModel)]="rgs.ServiceLocationID">
                    <option [ngValue]="null">None...</option>
                    <option [ngValue]="1">Origin</option>
                    <option [ngValue]="2">Destination</option>
                  </select>
                </div>
                <div class="col-2 overflow-hidden" style="align-self: center !important;">
                  <small>{{ rgs.DateCreated | date:environment.FormattingStandards.LongDateFormat }}</small>
                </div>
                <div class="col-2 text-end col-compress" style="align-self: center !important;">
                  <fa-icon [icon]="faInfo"
                           size="xl"
                           class="harmony-yellow-text me-2 position-relative pointer"
                           style="top: 2px;"
                           *ngIf="rgs.Comment?.length > 0"
                          [title]="rgs.Comment"></fa-icon>
                  <button class="btn btn-sm btn-primary harmony-blue-grey-outline-button w-50"
                          type="button"
                          data-bs-toggle="collapse"
                          [disabled]="rgs.Active === false"
                          [title]="'RateGroupService ID: ' + rgs.ID"
                          [attr.data-bs-target]="'#RGS_' + rgs.ID"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'RGS_' + rgs.ID">
                    <b>{{ rgs.ServiceRates ? rgs.ServiceRates.length : 0 }}</b> Rates
                  </button>
                  <button type="button"
                          class="btn btn-sm btn-outline-danger ms-1"
                          [title]="'Disable service'"
                          *ngIf="rgs.Active === true"
                          (click)="removeRateGroupService(rgs)">
                    <fa-icon [icon]="faDelete" size="sm"></fa-icon>
                  </button>
                  <button type="button"
                          class="btn btn-sm btn-outline-success ms-1"
                          [title]="'Enable service'"
                          *ngIf="rgs.Active === false"
                          (click)="enableRateGroupService(rgs)">
                    <fa-icon [icon]="faPlus" size="sm"></fa-icon>
                  </button>
                </div>
              </div>
              <!------------------->
              <!-- Service Rates -->
              <!------------------->
              <div class="row">
                <div class="col-12 ps-0 pe-0">
                  <div class="collapse multi-collapse show ps-0 pe-0 mt-1 mb-2" [id]="'RGS_' + rgs.ID">
                    <div class="card card-body p-1">
                      <div class="row m-0 header-row sub-result-row">
                        <div class="col-1 header-title-small border-split-left">
                          <small>Limit Type</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Quantity 1</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Unit Type 1</small>
                        </div>
                        <div class="col-2 header-title-small">
                          <small>Measurement Type 1</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Quantity 2</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Unit Type 2</small>
                        </div>
                        <div class="col-2 header-title-small">
                          <small>Measurement Type 2</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Currency</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Rate</small>
                        </div>
                        <div class="col-1 header-title-small text-end pt-1 pe-3 border-split-right">
                          <fa-icon [icon]="faPlus"
                                   class="text-white pointer"
                                   [title]="'Add new rate'"
                                   (click)="newServiceRate(rgs)"
                                   size="lg"></fa-icon>
                        </div>
                      </div>
                      <div class="row m-0 result-row" *ngFor="let sr of rgs.ServiceRates; let i = index; trackBy: identify">
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(rateLimitTypes$ | async) || sr.Active === false"
                                  [ngClass]="{'is-invalid': sr.RateLimitTypeID == null && invalid}"
                                  [(ngModel)]="sr.RateLimitTypeID">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let limitType of (rateLimitTypes$ | async)" [ngValue]="limitType.Id">{{ limitType.Name }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <input type="text"
                                 class="form-control form-control-sm"
                                 [disabled]="sr.Active === false"
                                 numbersOnly="5.0"
                                 [(ngModel)]="sr.RateQuantity_1">
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(unitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.RateUnitTypeID_1">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (unitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-2 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(unitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.MeasurementUnitTypeID_1">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (unitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <input type="text"
                                 numbersOnly="5.0"
                                 class="form-control form-control-sm"
                                 [disabled]="sr.Active === false"
                                 [(ngModel)]="sr.RateQuantity_2">
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(unitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.RateUnitTypeID_2">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (unitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-2 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(unitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.MeasurementUnitTypeID_2">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (unitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(currencies$ | async) || sr.Active === false"
                                  [ngClass]="{'is-invalid': sr.CurrencyID == null && invalid}"
                                  [(ngModel)]="sr.CurrencyID">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let currency of (currencies$ | async)" [ngValue]="currency.CurrencyId">{{ currency.Currency.Code }}</option>
                          </select>
                        </div>
                        <div class="col-2 col-compress">
                          <div class="row">
                            <div class="col-8">
                              <input type="text"
                                     numbersOnly="6.2"
                                     class="form-control form-control-sm"
                                     [ngClass]="{'is-invalid': sr.Rate == null && invalid}"
                                     [disabled]="sr.Active === false"
                                     [(ngModel)]="sr.Rate">
                            </div>
                            <div class="col-4 text-end" style="align-self: center !important;">
                              <button type="button"
                                      class="btn btn-sm btn-outline-success ms-1"
                                      *ngIf="sr.Active === false"
                                      (click)="activateServiceRate(sr)">
                                <fa-icon [icon]="faPlus" size="sm"></fa-icon>
                              </button>
                              <button type="button"
                                      class="btn btn-sm btn-outline-danger ms-1"
                                      *ngIf="sr.Active === true"
                                      (click)="removeServiceRate(sr)">
                                <fa-icon [icon]="faDelete" size="sm"></fa-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <app-no-result [showNoResults]="rgs.ServiceRates?.length <= 0" [noResultText]="'No rates for \'' + rgs.ServiceName + '\', add rates by clicking on the \'+\''"></app-no-result>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-no-result [showNoResults]="rateGroup.RateGroupServices?.length <= 0" [noResultText]="'No services found, add services by clicking on the \'+\''"></app-no-result>
        </div>
        <!----------------------------------->
        <!----- Inclusions & Exclusions ----->
        <!----------------------------------->
        <div class="tab-pane fade" id="inclusions" role="tabpanel" aria-labelledby="inclusions-tab">
          <div class="row mb-5">
            <div class="col-12">
              <div class="row mt-1 header-row">
                <div class="col-10 header-title border-split-left">
                  Service
                </div>
                <div class="col-2 header-title border-split-right">

                </div>
              </div>
              <app-loader *ngIf="loading" [Width]="15"></app-loader>
              <ng-container *ngIf="rateGroup.RateGroupInclusions">
                <div class="row result-row" *ngFor='let incl of rateGroup.RateGroupInclusions'>
                  <div class="col-10 overflow-hidden" [title]="incl.Description">
                    {{ incl.Description }}
                  </div>
                  <div class="col-2 overflow-hidden text-end">
                    <div class="form-check form-switch float-end form-switch-md">
                      <input class="form-check-input me-1"
                             type="checkbox"
                             role="switch"
                             [id]="'switch-' + incl.Id"
                             [title]="incl.IsIncluded ? 'Exclude' : 'Include'"
                             [(ngModel)]="incl.IsIncluded">
                      <span class="position-relative" style="top: 5px">{{ incl.IsIncluded ? 'Included' : 'Excluded' }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!rateGroup.RateGroupInclusions || rateGroup.RateGroupInclusions.length <= 0">
                <div class="row">
                  <div class="col-12 text-center">
                    There are no inclusions/exclusions for this rate group.
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!----------------------------------->
        <!------------- Comments ------------>
        <!----------------------------------->
        <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
          <div class="row mb-5">
            <div class="col-12">
              <textarea rows="8"
                        class="form-control form-control-sm w-100"
                        [(ngModel)]="rateGroup.Comment"
                        [placeholder]="'Add comments here...'">
              </textarea>
            </div>
          </div>
        </div>
        <!----------------------------------->
        <!----------- Test Rates ------------>
        <!----------------------------------->
        <div class="tab-pane fade" id="test-rates" role="tabpanel" aria-labelledby="test-rates-tab">
          <app-test-rate-group [RateGroup]="rateGroup"></app-test-rate-group>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" [Width]="25"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="cancel()">Close</button>
</div>

