import { Component, Input, OnInit } from '@angular/core';
import { ArticleService } from "../../../services/article.service";
import { IPage, IPaginationData } from "../../../shared/models/pagination-data.model";
import { IArticleOverview, IArticleSearchParam } from '../../../services/models/news/article.model';
import { IOrderParam } from "../../../shared/directives/sort/order.directive";

@Component({
  selector: 'app-dashboard-articles',
  templateUrl: './dashboard-articles.component.html',
  styleUrls: ['./dashboard-articles.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardArticlesComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;
  @Input() categories: string[] = [];

  // Component Variables
  articles: IArticleOverview[] = [];
  page: IPage = null
  order: IOrderParam = null;
  paginationData: IPaginationData<IArticleOverview> = null;

  constructor(public articleService: ArticleService) { }

  ngOnInit() {
    this.page = {
      pageNumber: 1,
      pageSize: 5, // Increase the total features shown here
      batched : false
    }

    this.order = {
      OrderBy: 'DateCreated',
      OrderDirection: 'desc'
    } as IOrderParam;

    this.getFeaturedArticles();
  }

  getFeaturedArticles() {
    const param = {
      SearchText: null,
      Featured: true,
      Website: null,
      ActiveOnly: true,
      Status: 'Published',
      CompanyId: null,
      UserId: null,
      Categories: null
    } as IArticleSearchParam;


    this.articleService.GetArticles(this.page, this.order, param)
      .subscribe((data: IPaginationData<IArticleOverview>) => {
        if (data) {
          this.articles = [...data.DataSet];
        }
      });
  }
}
