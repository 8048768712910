import { Component, OnDestroy, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  IRiskAssessmentList,
  IRiskAssessmentReport
} from '../../../../services/models/hse/hse.model';

@Component({
  selector: 'app-risk-assessment-report-modal',
  templateUrl: './risk-assessment-report-modal.component.html',
  styleUrls: ['./risk-assessment-report-modal.component.scss']
})
export class RiskAssessmentReportModalComponent implements OnInit, OnDestroy {

  // Icons

  // Component Variables
  riskAssessment: IRiskAssessmentList;
  riskReport: IRiskAssessmentReport;
  loading: boolean = false;

  // Shared Components

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private hseService: HseService) {
  }

  ngOnInit() {
    this.createReport()
  }

  ngOnDestroy(): void {
  }

  createReport(){
    // if (this.isValid()) {
    this.loading = true;

    this.hseService.getRiskAssessmentReport(this.riskAssessment.Id)
      .subscribe({
        next: (data) => {
          this.riskReport = Object.assign([], data);
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Failed to get the risk assessment report');
          this.loading = false;
        }
      });
    // }
  }


  close() {
    this.activeModal.close();
  }

}
