import { Component, OnInit } from '@angular/core';
import {
  faDownload,
  faExclamationCircle,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  IDataSource,
  IEmailSchedule,
  IEmailScheduleAttachment,
  IEmailScheduleAttachmentDto,
  IEmailScheduleDto,
  IEmailTemplate,
  IRecurrenceOption,
  IRecurrenceType,
  IRecurrenceTypeOption,
} from '../../../../../services/models/email.model';
import { EmailService } from '../../../../../services/email.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '../../../../../services/file.service';
import { AlertService } from '../../../../../services/alert.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-edit-schedule-modal',
  templateUrl: './edit-schedule-modal.component.html',
  styleUrls: ['./edit-schedule-modal.component.scss'],
  providers: [DatePipe]
})
export class EditScheduleModalComponent implements OnInit {
  // Icons
  faDownloadIcon = faDownload;
  faDelete = faTrash;
  faAdd = faPlus;
  faInfo = faCircleQuestion;
  faImportant = faExclamationCircle;

  // Variables
  public EmailScheduleID: number = null;
  schedule: IEmailScheduleDto;
  scheduleAttachments: IEmailScheduleAttachment[];
  newAttachments: IEmailScheduleAttachmentDto[];
  selectedRecurrence: IRecurrenceType = null;
  selectedDataSource: IDataSource = null;
  selectedEmailTemplate: IEmailTemplate = null;
  scheduleTime: string = '06:00';
  months: IRecurrenceOption[];
  selectedMonths: number[];
  daysOfMonth: IRecurrenceOption[] = [];
  selectedDaysOfMonth: number[];
  emailTemplates$ = this.emailService.emailTemplates$.asObservable();
  recurrenceTypes$ = this.emailService.recurrenceTypes$.asObservable();
  dataSources$ = this.emailService.dataSources$.asObservable();
  validated: boolean = false;
  env = environment;

  // General variables
  loading: boolean = false;

  constructor(private emailService: EmailService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    // Init months
    this.months = [
      {Label: 'January', Value: 1},
      {Label: 'February', Value: 2},
      {Label: 'March', Value: 3},
      {Label: 'April', Value: 4},
      {Label: 'May', Value: 5},
      {Label: 'June', Value: 6},
      {Label: 'July', Value: 7},
      {Label: 'August', Value: 8},
      {Label: 'September', Value: 9},
      {Label: 'October', Value: 10},
      {Label: 'November', Value: 11},
      {Label: 'December', Value: 12}
    ];

    // Init days of months
    for (const x of Array(31).keys()) {
      this.daysOfMonth.push({Label: (x + 1).toString(), Value: (x + 1)});
    }
  }

  public InitEmailSchedule() {
    this.loading = true;

    if (this.EmailScheduleID == null) {
      this.schedule = {
        Id: null,
        Active: true,
        DateCreated: new Date(),
        Name: null,
        TemplateID: null,
        TemplateName: null,
        DataSourceID: null,
        DataSourceName: null,
        StartDate: null,
        EndDate: null,
        Query: null,
        RecurrenceTypeID: null,
        RecurrenceTypeName: null,
        RecurrenceOptions: null,
        ScheduleTime: this.scheduleTime
      } as IEmailScheduleDto;

      this.newAttachments = [] as IEmailScheduleAttachmentDto[];
      this.loading = false;
    } else {
      this.emailService.getEmailSchedule(this.EmailScheduleID).subscribe({
        next: (data: IEmailSchedule) => {
          this.setExistingSchedule(data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
    }
  }

  setExistingSchedule(data: IEmailSchedule) {
    this.schedule = {
      Id: data.Id,
      DateCreated: data.DateCreated,
      Active: data.Active,
      Name: data.Name,
      TemplateID: data.TemplateID,
      TemplateName: data.Template?.DisplayName,
      DataSourceID: data.DataSourceID,
      DataSourceName: data.DataSource?.Name,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      Query: data.Query,
      RecurrenceTypeID: data.RecurrenceTypeID,
      RecurrenceTypeName: data.RecurrenceType?.Name,
      RecurrenceOptions: data.RecurrenceOptions.split(',').map(Number),
      ScheduleTime: data.ScheduleTime,
    } as IEmailScheduleDto;

    this.scheduleTime = data.ScheduleTime;
    this.scheduleAttachments = data.Attachments;

    this.emailTemplates$.subscribe((x) => {
      this.selectedEmailTemplate = x.find(r => r.Id === data.TemplateID);
    });
    this.dataSources$.subscribe((x) => {
      this.selectedDataSource = x.find(r => r.Id === data.DataSourceID);
    });
    this.recurrenceTypes$.subscribe((x) => {
      this.selectedRecurrence = x.find(r => r.Id === data.RecurrenceTypeID);
      const options = this.selectedRecurrence.RecurrenceTypeOptions;

      if (options) {
        this.selectedMonths = this.selectedRecurrence.RecurrenceTypeOptions
          .filter(x => this.schedule.RecurrenceOptions.includes(x.Id))
          .map(m => parseInt(m.Name.split('/')[0], 10));

        this.selectedDaysOfMonth = this.selectedRecurrence.RecurrenceTypeOptions
          .filter(x => this.schedule.RecurrenceOptions.includes(x.Id))
          .map(m => parseInt(m.Name.split('/')[1], 10));
      }
    });
  }

  setDataSource() {
    this.schedule.DataSourceID = this.selectedDataSource.Id;
    this.schedule.DataSourceName = this.selectedDataSource.Name;
  }

  setTemplate() {
    this.schedule.TemplateID = this.selectedEmailTemplate.Id;
    this.schedule.TemplateName = this.selectedEmailTemplate.DisplayName;
  }

  setRecurrenceType() {
    this.schedule.RecurrenceTypeID = this.selectedRecurrence.Id;
    this.schedule.RecurrenceTypeName = this.selectedRecurrence.Name;
    this.schedule.RecurrenceOptions = [];
  }

  setScheduleTime() {
    this.schedule.ScheduleTime = this.scheduleTime.toString();
  }

  setWeeklyOption(option: IRecurrenceTypeOption) {
    const index = this.schedule.RecurrenceOptions.findIndex(x => x === option.Id);

    if (index && index >= 0) {
      this.schedule.RecurrenceOptions.splice(index, 1);
    } else {
      this.schedule.RecurrenceOptions.push(option.Id);
    }
  }

  setMonthlyOptions() {
    const daysOfMonth = this.selectedDaysOfMonth;
    const months = this.selectedMonths;

    const applicableOptions = this.selectedRecurrence.RecurrenceTypeOptions
      .filter(x => months?.includes(parseInt(x.Name.split('/')[0], 10)) &&
        daysOfMonth?.includes(parseInt(x.Name.split('/')[1], 10)));

    this.schedule.RecurrenceOptions = applicableOptions.map(x => x.Id);
  }

  addAttachment(event: any) {
    const file = event.target.files[0];

    if ((file.size / 1024 / 1024) > 10) {
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
    } else {
      this.loading = true;
      if (this.schedule.Id == null) {
        const attachment = {
          Name: file.name,
          File: file
        } as IEmailScheduleAttachmentDto;
        this.newAttachments.push(attachment);
        this.loading = false;
      } else {
        const param = {
          EmailScheduleId: this.schedule.Id,
          Name: file.name,
          File: file
        } as IEmailScheduleAttachmentDto;
        this.storeAttachment(param);
      }
    }
  }

  storeAttachment(scheduleAttachment: IEmailScheduleAttachmentDto) {
    this.loading = true;
    this.emailService.addScheduleAttachment(scheduleAttachment).subscribe({
      next: (data) => {
        if (data) {
          this.scheduleAttachments.push(data);
          this.loading = false;
        }
      },
      error: () => {
        this.alertService.error('Failed to add attachments');
        this.loading = false;
      }
    });
  }

  deleteAttachment(id: number) {
    this.emailService.deleteScheduleAttachment(id).subscribe({
      next: () => {
        const index = this.scheduleAttachments.findIndex(x => x.EmailScheduleId === id);
        this.scheduleAttachments.splice(index, 1);
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  save() {
    if (this.canSave()) {
      this.loading = true;

      if (this.schedule.Id != null) {
        this.emailService.updateEmailSchedule(this.schedule).subscribe({
          next: (data: number) => {
            this.schedule.Id = data;
            this.alertService.success('Email Schedule successfully updated!');
            this.loading = false;
          },
          error: () => {
            this.alertService.error('An error occurred while attempting to update the schedule.');
            this.loading = false;
          }
        });
      } else {
        // Create Schedule
        this.emailService.createEmailSchedule(this.schedule).subscribe({
          next: (data: number) => {
            this.schedule.Id = data;
            // Add Attachments for Schedule
            this.newAttachments.forEach(a => {
              a.EmailScheduleId = data;
              this.storeAttachment(a);
            });
            this.alertService.success('Email Schedule successfully create!');
            this.loading = false;
            // Refresh the schedule
            this.InitEmailSchedule();
          },
          error: () => {
            this.alertService.error('An error occurred while attempting to create a new schedule.');
            this.loading = false;
          }
        });
      }
    }
  }

  canSave() {
    this.validated = true;
    const s = this.schedule;

    return !(s.TemplateID == null || s.DataSourceID == null || s.RecurrenceTypeID == null || !this.isValidQuery() || s.StartDate == null || s.Name == null);
  }

  isValidQuery() {
    if (this.schedule.Query && this.schedule.Query.length > 0) {
      const q = this.schedule.Query.toLowerCase();
      const containsIllegal = (q.includes('drop') || q.includes('alter') || q.includes('update') || q.includes('insert') || q.includes('delete'));
      return !containsIllegal;
    } else {
      return true;
    }
  }

  downloadAttachment(attachment: IEmailScheduleAttachment) {
    this.fileService.GetFile(this.env.ContainerNames.EmailAttachment, `${attachment.Reference}/${attachment.Name}`);
  }

  close() {
    this.activeModal.close();
  }

  removeAttachment(index: number) {
    this.newAttachments.splice(index, 1);
  }
}
