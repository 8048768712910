import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { faEdit, faFileText, faPlus, faTrash, faAdd } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import {
  IRiskAssessmentDto,
  IRiskAssessmentGroup,
  IRiskAssessmentItem
} from '../../../../services/models/hse/hse.model';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { environment } from '../../../../../environments/environment';
import { Router } from "@angular/router";
import { RiskAssessmentItemAdminModalComponent } from "./risk-assessment-item-admin-modal/risk-assessment-item-admin-modal.component";
import { RiskAssessmentGroupAdminModalComponent } from "./risk-assessment-group-admin-modal/risk-assessment-group-admin-modal.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedModule} from "../../../../shared/shared.module";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import {AlertService} from "../../../../services/alert.service";

@Component({
  selector: 'app-risk-assessment-admin',
  templateUrl: './risk-assessment-admin.component.html',
  styleUrls: ['./risk-assessment-admin.component.scss'],
  imports: [
    FontAwesomeModule,
    SharedModule,
    NgClass,
    NgIf,
    NgForOf,
    AsyncPipe
  ],
  standalone: true
})
export class RiskAssessmentAdminComponent implements OnInit {

  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  faPlus = faPlus;

  // Component Variables
  public readonly PermissionCodes = PermissionCodes;
  riskAssessment: IRiskAssessmentDto;
  riskAssessmentGroups: IRiskAssessmentGroup[];
  riskAssessmentGroup: IRiskAssessmentGroup;
  riskAssessmentItems: IRiskAssessmentItem[];
  riskAssessmentItem: IRiskAssessmentItem;
  branch: IBranchDto;

  //Roles/User rights
  isAdmin: boolean = false;

  // Shared Components
  loading: boolean;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  companies$ = this.companyService.ActiveMembers$.asObservable();
  environment = environment;
  searchText: string = null;

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              public authService: AdAuthService,
              private alertService: AlertService,
              public router: Router,
              private hseService: HseService) {
  }

  ngOnInit() {

    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.loadAssessmentGroups();
    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
          this.loadAssessmentGroups();
        });
    }
  }

  setPermissions() {
    this.isAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
  }

  getGroupItems(groupId: number): IRiskAssessmentItem[] {
    let filteredItems = this.riskAssessmentItems.filter(x => x.AssessmentGroupId === groupId);
    return filteredItems;
  }

  loadAssessmentGroups() {
    this.loading = true;

    this.hseService.getRiskAssessmentGroups()
      .subscribe({
        next: (data): void => {
          this.riskAssessmentGroups = Object.assign([], data);

          this.hseService.searchAllRiskAssessmentItems(this.searchText)
            .subscribe({
              next: (data): void => {
                this.riskAssessmentItems = Object.assign([], data);
                this.loading = false;
              },
              error: (): void => {
                this.loading = false;
              }
            });
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  openRiskAssessmentItemModal(assessmentItem: IRiskAssessmentItem) {
    const modalRef = this.modalService.open(RiskAssessmentItemAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.riskAssessmentItem = assessmentItem;
  }

  addItem(group: IRiskAssessmentGroup){

    const modalRef = this.modalService.open(RiskAssessmentItemAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.riskAssessmentItem = {
      Id: 0,
      AssessmentGroupId: group.Id,
      ItemNumber: null,
      Topic: null,
      Risk: null,
      Subject: null,
      Area: null,
      PossibleMeasures: null,
      Active: true,
      IRL: null,
      RRL: null
    } as IRiskAssessmentItem;
    modalRef.componentInstance.riskAssessmentGroup = group;

    // On modal close, read the result and apply logic
    modalRef.result.then((riskAssessmentItem: IRiskAssessmentItem) => {
      if (riskAssessmentItem.Id > 0) {
        this.riskAssessmentItem = riskAssessmentItem;
        this.riskAssessmentItems.push(this.riskAssessmentItem);
      }
    }, () => { });

  }

  addGroup(){
    const modalRef = this.modalService.open(RiskAssessmentGroupAdminModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.riskAssessmentGroup = {
      Id: null,
      Description: null
    } as IRiskAssessmentGroup;

    // On modal close, read the result and apply logic
    modalRef.result.then(() => {
      this.loadAssessmentGroups();
    }, () => { });

  }

  deleteRiskAssessmentItem(riskAssessmentItem: IRiskAssessmentItem) {
    const message = 'Are you sure you want to delete this Risk assessment item?';

    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (riskAssessmentItem != null) {

            this.loading = true;

            riskAssessmentItem.Active = false;

            this.hseService.updateRiskAssessmentItem(riskAssessmentItem)
              .subscribe({
                next: () => {
                  this.alertService.info('Risk assessment item ' + riskAssessmentItem.ItemNumber + ' successfully deleted.');
                  this.loadAssessmentGroups();
                  this.loading = false;
                },
                error: () => { }
              });
          }
        }
      });
  }

  searchItems() {
    this.loadAssessmentGroups();
  }

  resetSearch() {
    this.searchText = null;
    this.loadAssessmentGroups();
  }

  editGroup(group: IRiskAssessmentGroup) {
    const modalRef = this.modalService.open(RiskAssessmentGroupAdminModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.riskAssessmentGroup = group;

    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.loadAssessmentGroups();
      }
    }, () => { });
  }

  deleteGroup(groupId: number) {
    const message = 'Are you sure you want to delete this Risk assessment group ?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (groupId != null) {
            this.loading = true;
            this.hseService.deleteRiskAssessmentGroup(groupId)
              .subscribe({
                next: () => {
                  this.loadAssessmentGroups();
                  this.loading = false;
                },
                error: () => { }
              });
          }
        }
      });
  }
}
