<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ riskAssessmentItem.Id > 0 ? riskAssessmentItem.ItemNumber + '. ' + riskAssessmentItem.Topic : 'New risk assessment item for: ' + riskAssessmentGroup.Description}}
  </h4>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Item number
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text" maxlength="10"
             [(ngModel)]="riskAssessmentItem.ItemNumber"
             [ngClass]="{ 'is-invalid': riskAssessmentItem.ItemNumber == null && isInvalid}">
    </div>
    <div class="col-4 harmony-grey-text">
      <small></small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Topic
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="riskAssessmentItem.Topic"
                [ngClass]="{ 'is-invalid': (riskAssessmentItem.Topic == null  || riskAssessmentItem.Topic.length < 1) && isInvalid}">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small></small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Subject
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="riskAssessmentItem.Subject"
                [ngClass]="{ 'is-invalid': (riskAssessmentItem.Subject == null  || riskAssessmentItem.Subject.length < 1) && isInvalid}">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small>situation/occurrence with possible negative impact</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Risk
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="riskAssessmentItem.Risk"
             [ngClass]="{ 'is-invalid': (riskAssessmentItem.Risk == null  || riskAssessmentItem.Risk.length < 1) && isInvalid}">
    </div>
    <div class="col-4 harmony-grey-text">
      <small>what is the consequence if the unwanted situation occurs</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Area
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="riskAssessmentItem.Area"
             [ngClass]="{ 'is-invalid': (riskAssessmentItem.Area == null  || riskAssessmentItem.Area.length < 1) && isInvalid}">
    </div>
    <div class="col-4 harmony-grey-text">
      <small>all</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Possible measures
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="riskAssessmentItem.PossibleMeasures"
                [ngClass]="{ 'is-invalid': (riskAssessmentItem.PossibleMeasures == null || riskAssessmentItem.PossibleMeasures.length < 1) && isInvalid }">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small>all</small>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
   <button type="button" class="btn btn-primary harmony-purple-button" (click)="updateItem()">Save</button>
   <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(riskAssessmentItem)">Close</button>
</div>

