import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICompanyUserDto } from './models/user.model';
import { IPreferredAgentCreateRequest, IPreferredAgentRequest, IPreferredAgentUpdateRequest } from './models/pricing/agent.model';
import { IPage } from '../shared/models/pagination-data.model';
import {
  IActiveCompany, ICompany, ICompanyDetail,
  ICompanyDetailDefinition,
  ICompanyDetailSource, ICompanyDetailUpdate, ICompanyImagesDto,
  ICompanyType, ICompanyUpdate,
  IDataType
} from './models/member.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements OnDestroy {
  // Service Variables
  ActiveMembers: IActiveCompany[];
  ActiveMembers$: BehaviorSubject<IActiveCompany[]> = new BehaviorSubject<IActiveCompany[]>([]);
  AllMembers$: BehaviorSubject<IActiveCompany[]> = new BehaviorSubject<IActiveCompany[]>([]);
  CompanyDetailDefinitions$: BehaviorSubject<ICompanyDetailDefinition[]> = new BehaviorSubject<ICompanyDetailDefinition[]>([]);
  CompanyDetailSources$: BehaviorSubject<ICompanyDetailSource[]> = new BehaviorSubject<ICompanyDetailSource[]>([]);
  DataTypes$: BehaviorSubject<IDataType[]> = new BehaviorSubject<IDataType[]>([]);
  CompanyTypes$: BehaviorSubject<ICompanyType[]> = new BehaviorSubject<ICompanyType[]>([]);

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService) {
    this.loadActiveMembers();

    // Company Detail Definitions
    this.api.get('CompanyDetailDefinition/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyDetailDefinition[]) => {
      if (data) {
        this.CompanyDetailDefinitions$.next(data.sort((a, b) => a.PropertyName < b.PropertyName ? -1 : 1));
      }
    });

    // Company Detail Sources
    this.api.get('CompanyDetailSource/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyDetailSource[]) => {
      if (data) {
        this.CompanyDetailSources$.next(data);
      }
    });

    // Company Detail Data Types
    this.api.get('DataType/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IDataType[]) => {
      if (data) {
        this.DataTypes$.next(data);
      }
    });

    // Company Types
    this.api.get('CompanyType/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyType[]) => {
      if (data) {
        this.CompanyTypes$.next(data);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public loadActiveMembers() {
    this.ActiveMembers = [];

    this.api.get('Company/ListActive').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe((data: IActiveCompany[]) => {
      if (data) {
        this.ActiveMembers.push(...data.sort((a, b) => a.CompanyName < b.CompanyName ? -1 : 1));
        this.ActiveMembers$.next(this.ActiveMembers);
      }
    });
  }

  // Do not load on init of service, only if required. (e.g. Member Admin)
  // This list includes members that are active but not yet published, i.e. should not be publicly visible yet
  public LoadAllMembers() {
    this.api.get('Company/ListMembers').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe((data: IActiveCompany[]) => {
      if (data) {
        this.AllMembers$.next(data);
      }
    });
  }

  public getCompany(companyId: number): Observable<ICompany> {
    return this.api.get('Company/GetByID/' + companyId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public getCompanyImages(companyId: number): Observable<ICompanyImagesDto> {
    return this.api.get('Company/Images/' + companyId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public getCompanyUsers(companyId: number): Observable<ICompanyUserDto[]> {
    return this.api.get('User/GetByCompanyID/' + companyId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public updateCompany(member: ICompany) {
    return this.api.post('Company/Update', member).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public DeleteAgent(companyId: number) {
    return this.api.get('Company/Delete/' + companyId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public createAgent(member: ICompany) {
    return this.api.post('Company/CreateAgent', member).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public mergeAgents(oldCompanyId: number, newCompanyId: number) {
    const param = {
      OldCompanyId: oldCompanyId,
      NewCompanyId: newCompanyId
    };

    return this.api.post('Company/MergeAgents', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public updateMemberProfile(member: ICompanyUpdate) {
    return this.api.post('Company/UpdateCompanyProfile', member).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public getCompanyDetail(companyId: number): Observable<ICompanyDetail[]> {
    return this.api.get('CompanyDetail/GetByCompany/' + companyId).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public getCompanyDetailByCategory(category: string, companyId: number, branchId: number | null): Observable<ICompanyDetail[]> {

    return this.api.get(`CompanyDetail/GetByCategory/${category}/${companyId}/${branchId ?? ''}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpsertCompanyDetail(companyDetail: ICompanyDetailUpdate): Observable<ICompanyDetail> {
    return this.api.post('CompanyDetail/Upsert', companyDetail).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpsertCompanyDetailList(companyDetail: ICompanyDetailUpdate[]): Observable<ICompanyDetail[]> {
    return this.api.post('CompanyDetail/UpsertList', companyDetail).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public getCompanyDetailDefinitionByCategory(propertyCategory: string) {
    return this.api.get(`CompanyDetailDefinition/PropertyCategory/${propertyCategory}`).pipe(
      takeUntil(this.unsubscribe)
    )
  }

  public getCompanyDetailValue(companyDetails: ICompanyDetail[], propertyName: string): ICompanyDetail {
    const result = companyDetails.find((cd) => cd.CompanyDetailDefinition.PropertyName === propertyName);

    if (result) {
      return result;
    } else {
      return null;
    }
  }

  getPreferredAgents(body: IPreferredAgentRequest, page: IPage) {
    return this.api.post(`Agent/GetPreferred?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  createPreferredAgent(body: IPreferredAgentCreateRequest) {
    return this.api.post('Agent/CreatePreferred', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updatePreferredAgent(body: IPreferredAgentUpdateRequest) {
    return this.api.put('Agent/UpdatePreferred', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
