import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPage } from '../shared/models/pagination-data.model';
import { ApiService } from './api.service';
import { IPortType } from './models/external-data/prism.model';
import { IShipment } from './models/external-data/searates.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalDataService implements OnDestroy{

  private unsubscribe: Subject<any> = new Subject<any>();
  constructor(private api: ApiService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  getIataRates(validTo, validFrom, rateSource, origin, destination, page: IPage, applicableProduct) {

    const RateRequest = {
      ValidTo: validTo,
      ValidFrom: validFrom,
      RateSource: rateSource,
      Origin: origin,
      Destination: destination
    };
    const Filters = {
      ApplicableProduct : applicableProduct
    };
    const body = {RateRequest, Filters};
    return this.api.post(`Iata/Get?pageNumber=${page.pageNumber}`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  addIataRates(rates) {
    return this.api.post(`Iata`, rates).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getPrismPorts(inputDate: string, portType: IPortType, countryCode: string, page: IPage) {
    return this.api.get(`Prism/Ports/${inputDate}/${portType}/${countryCode}?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getPrismAgents(inputDate: string, page: IPage) {
    return this.api.get(`Prism/Agents/${inputDate}?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getFclRates(from: string, to: string, containerTypes: string[], date: string, currency: string, freightOnly: boolean){
    const body = {
      ToCoordinates: to,
      FromCoordinates: from,
      ContainerTypes: containerTypes,
      Currency: currency,
      Date: date,
      FreightOnly: freightOnly
    };
    return this.api.post('SeaRates/Query/FCL', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  importSeaRates(shipments: IShipment[]){
    return this.api.post('SeaRates/', shipments).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
