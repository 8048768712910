import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../services/alert.service';
import {AdAuthService} from '../../../../core/ad-auth-service/ad-auth.service';
import {ApiService} from '../../../../services/api.service';
import {Subject, Subscription} from 'rxjs';
import {IComponent, IComponentParam} from '../../../../services/models/user.model';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-permission-modal',
  templateUrl: './permission-modal.component.html',
  styleUrls: ['./permission-modal.component.scss']
})
export class PermissionModalComponent implements OnInit, OnDestroy {
  // Component Variables
  public ComponentId: number;
  public IsNew: boolean;
  Permission: IComponent;
  modules$ = this.authService.Modules$.asObservable();

  uniqueCodeExists: boolean = false;
  invalid = false;
  private codeChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService) {
  }


  ngOnInit(): void {
   this.subscription = this.codeChanged
      .pipe(
        debounceTime(500),
      )
      .subscribe((data) => {
        this.checkCode(data);
      });

    if (this.IsNew) {
      this.Permission = {
        Id: null,
        Active: true,
        DateCreated: new Date(),
        DateModified: null,
        Name: '',
        Code: null,
        Description: null,
        ModuleId: null
      } as IComponent;
    } else {
      this.loadComponent();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateCode(event) {
    this.codeChanged.next(event.target.value);
  }

  loadComponent() {
    this.loading = true;

    this.api.get(`Component/Single/${this.ComponentId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IComponent) => {
        if (data) {
          this.Permission = Object.assign({}, data);
        }
        this.loading = false;
      }, error: (error) => {
        this.alertService.error('Error occurred retrieving Component data: ' + error);
        this.loading = false;
      }
    });
  }

  checkCode(code: string) {
    this.loading = true;
    this.Permission.Code = code;

    const param = {
      Active: true,
      Code: this.Permission.Code,
      ModuleId: null
    } as IComponentParam;

    this.api.post('Component/List', param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IComponent[]) => {
        if (data && data.length > 0) {
          const exists = data.filter((c) => c.Id !== this.Permission.Id);
          this.uniqueCodeExists = !!(exists && exists.length > 0);
        } else {
          this.uniqueCodeExists = false;
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  saveComponent() {
    if (this.canSave()) {
      this.loading = true;

      this.api.post('Component/Update', this.Permission).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.alertService.success('Component update successful!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: (error) => {
          this.alertService.error('Error occurred attempting to update the Component: ' + error);
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  canSave() {
    return !!(this.Permission.Name.length > 3 &&
      this.Permission.ModuleId != null &&
      this.Permission.Code.length > 0 &&
      !this.uniqueCodeExists);
  }

  close() {
    this.activeModal.close(null);
  }
}
