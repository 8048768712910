import { Component } from '@angular/core';

@Component({
  selector: 'app-services-eurotunnel',
  templateUrl: './services-eurotunnel.component.html',
  styleUrls: ['./services-eurotunnel.component.scss']
})
export class ServicesEurotunnelComponent {

}
