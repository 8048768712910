import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { ICompanyRequestDto } from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';
import { ApiService } from '../../../../services/api.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-review-request-modal',
  templateUrl: './decline-request-modal.component.html',
  styleUrls: ['./decline-request-modal.component.scss']
})
export class DeclineRequestModalComponent implements OnInit, OnDestroy {
  // Icons
  faDateArrow = faArrowRight;

  // Variables
  public CompanyRequest: ICompanyRequestDto;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private api: ApiService,
              private authService: AdAuthService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  decline() {
    this.api.post(`RateCapture/DeclineRequest/${this.authService.CurrentUser.UserId}`, this.CompanyRequest).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data) => {
      if (data) {
        const request = Object.assign({}, data);
        this.activeModal.close(request);
      }
    });

  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
