<fa-icon [icon]="faTypeImage"
         style="color: #ffa602"
         *ngIf="Extension == 'jpg' || Extension == 'jpeg' || Extension == 'png'"
         [size]="'lg'"
         [title]="Extension + ' image file'"></fa-icon>
<fa-icon [icon]="faTypeImageAlt"
         style="color: #ffa602"
         *ngIf="Extension == 'svg' || Extension == 'gif'"
         [size]="'lg'"
         [title]="Extension + ' file'"></fa-icon>
<fa-icon [icon]="faTypePdf"
         [size]="'lg'"
         style="color: #FF0000"
         *ngIf="Extension == 'pdf'"
         [title]="'PDF document'"></fa-icon>
<fa-icon [icon]="faTypeExcel"
         [size]="'lg'"
         style="color: #008000"
         *ngIf="Extension == 'xls' || Extension == 'xlsx'"
         [title]="'Excel spreadsheet'"></fa-icon>
<fa-icon [icon]="faTypeCsv"
         [size]="'lg'"
         style="color: #008000"
         *ngIf="Extension == 'csv'"
         [title]="'CSV File'"></fa-icon>
<fa-icon [icon]="faTypePP"
         [size]="'lg'"
         style="color: #d04423"
         *ngIf="Extension == 'ppt' || Extension == 'pptx'"
         [title]="'Powerpoint'"></fa-icon>
<fa-icon [icon]="faTypeWord"
         [size]="'lg'"
         style="color: #4472C4"
         *ngIf="Extension == 'doc' || Extension == 'docx'"
         [title]="'Word Document'"></fa-icon>
<fa-icon [icon]="faTypeDefault"
         [size]="'lg'"
         style="color: #555555"
         *ngIf="Extension != 'jpg' &&
                Extension != 'jpeg' &&
                Extension != 'png' &&
                Extension != 'svg' &&
                Extension != 'gif' &&
                Extension != 'pdf' &&
                Extension != 'xls' &&
                Extension != 'xlsx' &&
                Extension != 'doc' &&
                Extension != 'docx' &&
                Extension != 'ppt' &&
                Extension != 'pptx' &&
                Extension != 'csv'"
         [title]="Extension"></fa-icon>
