import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { ICompanySearchResult } from '../../../services/models/member.model';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;
  @Input() MembersOnly: boolean = false;
  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadCompanyByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<ICompanySearchResult> = new EventEmitter<ICompanySearchResult>();

  // Component Variables
  selectedId: number;
  public SelectedCompany: ICompanySearchResult = null;
  searching: boolean = false;
  searchFailed: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: ICompanySearchResult) =>
    ((result.CompanyType && result.CompanyType.toLowerCase() != 'agent') ? 'H | ' + result.Name : result.Name) + ` (${result.City}, ${result.CountryIso})`;

  constructor(private api: ApiService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadCompanyByID(this.SelectedID);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && (changes.SelectedID.currentValue == null || changes.SelectedID.currentValue == 0)) {
      this.clear();
    }
  }

  public Reset() {
    this.clear();
  }

  searchCompany = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.searchFailed = false;
      }),
      switchMap(term =>
        this.getCompanies(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  };

  getCompanies(searchText: string): Observable<ICompanySearchResult[]> {
    if (searchText && searchText.length > 2) {
      // Build URL for request
      const param = {
        SearchText: searchText,
        MembersOnly: this.MembersOnly
      };
      // Call City controller "List" endpoint
      return this.api.post('Company/SearchCompanies', param).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
      return of([]);
  }

  loadCompanyByID(Id: number) {
    this.searching = true;
    // Call Agent controller "Single" endpoint
    this.api.get('Company/SearchSingle/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanySearchResult) => {
      if (data) {
        this.SelectedCompany = Object.assign({}, data);
      }
      this.searching = false;
      this.ref.detectChanges();
    });
  }

  onSelect(selectedCompany: ICompanySearchResult) {
    if (selectedCompany && selectedCompany.CompanyId) {
      this.OnSelect.emit(selectedCompany.CompanyId);
      this.OnSelectObject.emit(selectedCompany);
    }
  }

  clear() {
    this.selectedId = null;
    this.SelectedCompany = null;
    this.searching = false;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
    this.ref.detectChanges();
  }
}
