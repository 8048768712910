import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faAngleRight, faCircleDot, faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-services-menu',
  templateUrl: './services-menu.component.html',
  styleUrls: ['./services-menu.component.scss']
})
export class ServicesMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  faServices = faPeopleCarryBox;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  serviceOverview: boolean = false;
  businessIntelligence: boolean = false;
  clientServices: boolean = false;
  compliance: boolean = false;
  corporateSales: boolean = false;
  customerSatisfaction: boolean = false;
  euroTunnel: boolean = false;
  insurance: boolean = false;
  marketing: boolean = false;
  netting: boolean = false;
  integration: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.serviceOverview = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.businessIntelligence = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.clientServices = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.compliance = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.corporateSales = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.customerSatisfaction = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.euroTunnel = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.insurance = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.marketing = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.netting = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
    this.integration = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
  }
}
