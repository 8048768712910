import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { CompanyService } from '../../../../../services/company.service';
import {faEdit, faFileText, faPlus, faTrash, faAdd} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import {IBranchResultDto} from '../../../../../services/models/hse/branch.model';
import { PermissionCodes } from '../../../../../core/constants/permission-codes';
import { environment } from '../../../../../../environments/environment';
import {Router} from "@angular/router";
import {SharedModule} from "../../../../../shared/shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AsyncPipe, DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {AlertService} from "../../../../../services/alert.service";

@Component({
  selector: 'app-branch-admin',
  templateUrl: './branch-admin-modal.component.html',
  styleUrls: ['./branch-admin-modal.component.scss'],
  imports: [
    SharedModule,
    FontAwesomeModule,
    NgClass,
    NgIf,
    NgForOf,
    AsyncPipe,
    DatePipe,
    FormsModule
  ],
  standalone: true
})
export class BranchAdminModalComponent implements OnInit, AfterViewChecked {

  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  faPlus = faPlus;

  // Component Variables
  public readonly PermissionCodes = PermissionCodes;
  public Branch: IBranchResultDto;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  environment = environment;
  isInvalid: boolean = false;

  //Roles/User rights
  isAdmin: boolean = false;

  // Shared Components
  loading: boolean;

  noResult: boolean = false;

  constructor(private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              public authService: AdAuthService,
              public router: Router,
              private hseService: HseService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {

    if (this.authService.CurrentUser) {
      this.setPermissions();
    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
        });
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.isAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
  }

  updateBranch() {

    if(this.isValid()) {
      this.hseService.updateBranch(this.Branch)
        .subscribe({
          next: () => {
            this.activeModal.close(this.Branch);
          },
          error: () => {
            this.alertService.error('Failed to update branch.');
          }
        });
    } else {
      this.alertService.error('Please fill all required fields outlined red.');
    }

  }

  isValid(){
    const c = this.Branch;

    if (c.CompanyId > 0 &&
      (c.Name && c.Name.length > 0) &&
      (c.Address1 && c.Address1.length > 0) &&
      (c.CityId > 0) &&
      (c.CountryId > 0)){
      this.isInvalid = false;
      return true;
    }

    this.isInvalid = true;
    return false;

  }

  close(){
    this.activeModal.close();
  }
}
