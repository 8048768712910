<div id="admin-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark" id="headingAdmin" data-bs-toggle="collapse" data-bs-target="#collapseAdmin" aria-expanded="true" aria-controls="collapseAdmin">
      <h5 class="portal-menu-heading">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faCogs"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-teal-text': router.url.includes('admin/')}">
          Admin
        </span>
      </h5>
    </div>
    <div id="collapseAdmin" class="collapse" aria-labelledby="headingOne" data-bs-parent="#admin-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="permissions">
              <a class="nav-link pointer"
                 [routerLink]="['admin/permission-management']"
                 [ngClass]="{'active': router.url.includes('permission-management')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Permissions
              </a>
            </li>
            <li class="nav-item" *ngIf="users">
              <a class="nav-link pointer"
                 [routerLink]="['admin/user-management']"
                 [ngClass]="{'active': router.url.includes('user-management')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Users
              </a>
            </li>
            <li class="nav-item" *ngIf="members">
              <a class="nav-link pointer"
                 [routerLink]="['admin/manage-members']"
                 [ngClass]="{'active': router.url.includes('manage-members')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Members
              </a>
            </li>
            <li class="nav-item" *ngIf="agents">
              <a class="nav-link pointer"
                 [routerLink]="['admin/manage-agents']"
                 [ngClass]="{'active': router.url.includes('manage-agents')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Agents
              </a>
            </li>
            <li class="nav-item" *ngIf="email">
              <a class="nav-link pointer"
                 [routerLink]="['admin/email']"
                 [ngClass]="{'active': router.url.includes('email')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Emails & Schedules
              </a>
            </li>
            <li class="nav-item" *ngIf="documentManagement || documentCategoryManagement">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="documentManagementSubMenu.hidden = !documentManagementSubMenu.hidden"
                       [ngClass]="{'active': router.url.endsWith('admin/documents') || router.url.endsWith('admin/documents/categories')}">
                      <fa-icon [rotate]="documentManagementSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Document Management
                    </a>
                  </div>
                </div>
                <div #documentManagementSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="documentManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/documents']"
                         [ngClass]="{'active': router.url.endsWith('admin/documents')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Documents
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="documentCategoryManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/documents/categories']"
                         [ngClass]="{'active': router.url.endsWith('admin/documents/categories')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Categories
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="locationManagement">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="locationManagementSubMenu.hidden = !locationManagementSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('locations')}">
                      <fa-icon [rotate]="locationManagementSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Locations
                    </a>
                  </div>
                </div>
                <div #locationManagementSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="locationManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/location-management/city']"
                         [ngClass]="{'active': router.url.endsWith('admin/location-management/city')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> City
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="locationManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/location-management/city-functions']"
                         [ngClass]="{'active': router.url.endsWith('admin/location-management/city-functions')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> City Functions
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="locationManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/location-management/state']"
                         [ngClass]="{'active': router.url.endsWith('admin/location-management/state')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> State
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="locationManagement">
                      <a class="sub-link"
                         [routerLink]="['admin/location-management/country']"
                         [ngClass]="{'active': router.url.endsWith('admin/location-management/country')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Country
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="jobScheduler">
              <a class="nav-link pointer"
                 [routerLink]="['admin/job-scheduler']"
                 [ngClass]="{'active': router.url.includes('job-scheduler')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Job Scheduler
              </a>
            </li>
            <li class="nav-item" *ngIf="log">
              <a class="nav-link pointer"
                 [routerLink]="['admin/application-log']"
                 [ngClass]="{'active': router.url.includes('application-log')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Application Log
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
