import { Component, OnDestroy } from '@angular/core';
import {
  IDocumentCategory,
  IDocumentCategoryUpdateParam
} from '../../../../../services/models/document/document-category.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from '../../../../../services/document.service';
import { AlertService } from '../../../../../services/alert.service';
import { Subject, Subscription } from 'rxjs';
import { AdAuthService } from '../../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../../services/api.service'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-document-category-modal',
  templateUrl: './document-category-modal.component.html',
  styleUrls: ['./document-category-modal.component.scss']
})
export class DocumentCategoryModalComponent implements OnDestroy {

  // Icons
  protected readonly faInfo = faCircleQuestion;

  // Component variables
  public CategoryId: number = null;
  category: IDocumentCategoryUpdateParam;
  private codeChanged$: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  uniqueCodeExists: boolean = false;
  modules$ = this.authService.Modules$.asObservable();
  invalid: boolean = false;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private documentService: DocumentService,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService) { }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public SetDocumentCategory() {
    this.subscription = this.codeChanged$
      .pipe(
        debounceTime(500),
      )
      .subscribe((data) => {
        if (data && data.length == 8) {
          this.checkCode(data);
        }
      });

    if (this.CategoryId && this.CategoryId > 0) {
      this.documentService.getDocumentCategory(this.CategoryId).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IDocumentCategory) => {
          this.category = {
            Id: data.Id,
            Name: data.Name,
            Description: data.Description,
            Code: data.Code,
            ModuleId: data.ModuleId
          } as IDocumentCategoryUpdateParam;
        },
        error: err => {
          this.alertService.error(err.Message);
        }
      });
    } else {
      this.category = {
        Id: null,
        Name: null,
        Description: null,
        Code: null,
        ModuleId: null
      } as IDocumentCategoryUpdateParam;
    }
  }

  updateCode(event: any) {
    this.codeChanged$.next(event.target.value);
  }

  checkCode(code: string) {
    this.loading = true;
    this.category.Code = code;

    this.api.get(`DocumentCategory/Check/${code}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (exists: boolean) => {
        this.uniqueCodeExists = exists;
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  update() {
    if (this.canSave()) {
      this.documentService.upsertDocumentCategory(this.category)
        .subscribe({
          next: (data: IDocumentCategory) => {
            this.alertService.success('Document category has been successfully saved!');
            this.category.Id = data.Id;
            this.activeModal.close(data);
          },
          error: err => {
            this.alertService.warn('An error occurred while saving the document category: ' + err.Message);
          }
        });
    } else {
      this.invalid = true;
    }
  }

  canSave() {
    return !!(this.category.Name?.length > 3 &&
      this.category.Code?.length == 8 &&
      !this.uniqueCodeExists)
  }

  close() {
    this.activeModal.dismiss();
  }
}
