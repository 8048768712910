import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { IAgent } from '../../../services/models/pricing/partner.model';

@Component({
  selector: 'app-agent-search',
  templateUrl: './agent-search.component.html',
  styleUrls: ['./agent-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentSearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() ErrorColor: string = '#cc000f';
  @Input() ShowError: boolean = false;

  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadAgentByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<IAgent> = new EventEmitter<IAgent>();

  // Component Variables
  selectedId: number;
  public SelectedAgent: IAgent = null;
  searching: boolean = false;
  searchFailed: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: IAgent) => result.AgentName;

  constructor(private api: ApiService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadAgentByID(this.SelectedID);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && (changes.SelectedID.currentValue == null || changes.SelectedID.currentValue == 0)) {
      this.clear();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public Reset() {
    this.clear();
  }

  searchAgent = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.searchFailed = false;
      }),
      switchMap(term =>
        this.getAgent(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  };

  getAgent(searchText: string): Observable<IAgent[]> {
    if (searchText && searchText.length > 2) {
      // Build URL for request
      const urlPath = 'Agent/Search/' + searchText;
      // Call City controller "List" endpoint
      return this.api.get(urlPath).pipe(
        map((data) => data)
      );
    } else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadAgentByID(Id: number) {
    this.searching = true;
    // Call Agent controller "Single" endpoint
    this.api.get('Agent/Single/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IAgent) => {
      if (data) {
        this.SelectedAgent = Object.assign({}, data);
      }
      this.searching = false;
      this.ref.detectChanges();
    });
  }

  onSelect(selectedAgent: IAgent) {
    if (selectedAgent && selectedAgent.AgentId) {
      this.OnSelect.emit(selectedAgent.AgentId);
      this.OnSelectObject.emit(selectedAgent);
    }
  }

  clear() {
    this.SelectedAgent = null;
    this.searching = false;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
    this.ref.detectChanges();
  }
}
