import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { TenderWizardComponent } from './tender-wizard/tender-wizard.component';
import { AdminModule } from '../admin/admin.module';
import { RouterModule } from '@angular/router';
import { RateCaptureComponent } from './rate-capture/rate-capture.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StepCreateTenderComponent } from './tender-wizard/step-create-tender/step-create-tender.component';
import { StepLocationsComponent } from './tender-wizard/step-locations/step-locations.component';
import { StepModalityComponent } from './tender-wizard/step-modality/step-modality.component';
import { StepFreightComponent } from './tender-wizard/step-freight/step-freight.component';
import { RateQuoteComponent } from './tender-wizard/rate-quote/rate-quote.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { StepLocationsAddModalComponent } from './tender-wizard/step-locations/step-locations-add-modal/step-locations-add-modal.component';
import { TenderWizardOverviewComponent } from './tender-wizard/tender-wizard-overview/tender-wizard-overview.component';
import { QuoteAcceptComponent } from './tender-wizard/rate-quote/quote-accept/quote-accept.component';
import { AddServiceModalComponent } from './tender-wizard/step-services/add-service-modal/add-service-modal.component';
import { StepRateExportComponent } from './tender-wizard/step-rate-export/step-rate-export.component';
import { StepServicesComponent } from './tender-wizard/step-services/step-services.component';
import { ExportRatesModalComponent } from './tender-wizard/tender-wizard-overview/export-rates-modal/export-rates-modal.component';
import { RateRequestComponent } from './rate-request/rate-request.component';
import { FailedRatesComponent } from './failed-rates/failed-rates.component';
import { RetryFailedRatesModalComponent } from './failed-rates/retry-failed-rates-modal/retry-failed-rates-modal.component';
import { PricingMenuComponent } from './pricing-menu/pricing-menu.component';
import { SingleRateWizardComponent } from './single-rate-wizard/single-rate-wizard.component';
import { RateCalculatorComponent } from './single-rate-wizard/rate-calculator/rate-calculator.component';
import { SingleRateOverviewComponent } from './single-rate-wizard/single-rate-overview/single-rate-overview.component';
import { RateGroupManagerComponent } from './rategroup-manager/rate-group-manager.component';
import { RateGroupEditModalComponent } from './rategroup-manager/rategroup-edit-modal/rate-group-edit-modal.component';
import { ViewActionLogModalComponent } from './rate-request/view-action-log-modal/view-action-log-modal.component';
import { CreateRateRequestModalComponent } from './rate-request/create-rate-request-modal/create-rate-request-modal.component';
import { SendRateRequestComponent } from './rate-request/send-rate-request/send-rate-request.component';
import { AddRequestLocationsModalComponent } from './rate-request/add-locations-modal/add-request-locations-modal.component';
import { PreferredAgentManagementComponent } from './preferred-agent-management/preferred-agent-management.component';
import { ViewAgentModalComponent } from './preferred-agent-management/view-agent-modal/view-agent-modal.component';
import { ViewRateGroupsModalComponent } from './rate-request/view-rategroups-modal/view-rate-groups-modal.component';
import { PricingRoutingModule } from './pricing-routing.module';
import {
  RateGroupCaptureModalComponent
} from './rate-capture/rategroup-capture-modal/rate-group-capture-modal.component';
import { ReviewRequestModalComponent } from './rate-capture/review-request-modal/review-request-modal.component';
import { RequestTemplateManagementComponent } from './rate-request/request-template-management/request-template-management.component';
import { TestRateGroupComponent } from './rategroup-manager/test-rate-group/test-rate-group.component';
import { PricingService } from '../../services/pricing.service';
import { RequestTemplateEditModalComponent } from './rate-request/request-template-management/request-template-edit-modal/request-template-edit-modal.component';
import { ExternalDataManagerComponent } from './external-data-manager/external-data-manager.component';
import { DeclineRequestModalComponent } from './rate-capture/decline-request-modal/decline-request-modal.component';
import { SingleLaneQuoteComponent } from './single-rate-wizard/single-lane-quote/single-lane-quote.component';
import {
  ExportSingleLaneRatesModalComponent
} from './single-rate-wizard/single-rate-overview/export-single-lane-rates-modal/export-single-lane-rates-modal.component';
import { SeaRatesTabComponent } from './external-data-manager/sea-rates-tab/sea-rates-tab.component';
import { PrismTabComponent } from './external-data-manager/prism-tab/prism-tab.component';
import { IataRatesTabComponent } from './external-data-manager/iata-rates-tab/iata-rates-tab.component';
import { PricingAdminComponent } from './pricing-admin/pricing-admin.component';
import { CloneRateGroupModalComponent } from './rate-capture/clone-rate-group-modal/clone-rate-group-modal.component';
import { DashboardContainerPricingComponent } from './pricing-dashboard/dashboard-container-pricing/dashboard-container-pricing.component';
import { DashboardCardRateRequestsComponent } from './pricing-dashboard/dashboard-card-rate-requests/dashboard-card-rate-requests.component';

@NgModule({
  declarations: [
    TenderWizardComponent,
    PricingMenuComponent,
    SingleRateWizardComponent,
    RateCaptureComponent,
    StepCreateTenderComponent,
    StepLocationsComponent,
    StepModalityComponent,
    StepFreightComponent,
    RateQuoteComponent,
    StepLocationsAddModalComponent,
    TenderWizardOverviewComponent,
    QuoteAcceptComponent,
    AddServiceModalComponent,
    StepRateExportComponent,
    StepServicesComponent,
    ExportRatesModalComponent,
    RateRequestComponent,
    FailedRatesComponent,
    RetryFailedRatesModalComponent,
    RateCalculatorComponent,
    SingleRateOverviewComponent,
    RateGroupManagerComponent,
    RateGroupEditModalComponent,
    ViewActionLogModalComponent,
    CreateRateRequestModalComponent,
    SendRateRequestComponent,
    AddRequestLocationsModalComponent,
    PreferredAgentManagementComponent,
    ViewAgentModalComponent,
    ViewRateGroupsModalComponent,
    RateGroupCaptureModalComponent,
    ReviewRequestModalComponent,
    RequestTemplateManagementComponent,
    TestRateGroupComponent,
    RequestTemplateEditModalComponent,
    ExternalDataManagerComponent,
    DeclineRequestModalComponent,
    SingleLaneQuoteComponent,
    ExportSingleLaneRatesModalComponent,
    SeaRatesTabComponent,
    PrismTabComponent,
    IataRatesTabComponent,
    PricingAdminComponent,
    CloneRateGroupModalComponent,
    DashboardContainerPricingComponent,
    DashboardCardRateRequestsComponent
  ],
  exports: [
    PricingMenuComponent,
    SingleRateWizardComponent,
    RateCaptureComponent,
    RateRequestComponent,
    FailedRatesComponent
  ],
  imports: [
    CommonModule,
    AdminModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    SharedModule,
    NgbTooltipModule,
    PricingRoutingModule,
    NgOptimizedImage
  ],
  providers: [
    PricingService,
    DatePipe
  ]
})

export class PricingModule { }
